import { upperFirst } from 'lodash-es';

import { PassFailIcon } from './PassFailIcon';

export function FieldValueRenderer({ value }: { value: string }) {
  return (
    <div className="flex items-center gap-1">
      {(value === 'fail' || value === 'pass') && (
        <PassFailIcon isPass={value === 'pass'} />
      )}
      <span>{upperFirst(value)}</span>
    </div>
  );
}
