import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useOrganizationSettingsConnectMerchantAccountCreateMutation } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';

import { launchApplicationPopup } from './launchApplicationPopup';

type UseConnectMerchantAccount = {
  loading: boolean;
  trigger: () => Promise<boolean>;
};

export function useConnectMerchantAccount(): UseConnectMerchantAccount {
  const [createMerchantAccount] =
    useOrganizationSettingsConnectMerchantAccountCreateMutation();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleCreateMerchantAccount = useCallback(async () => {
    if (loading) {
      return false;
    }

    setLoading(true);
    try {
      const result = await createMerchantAccount({}).unwrap();
      dispatch(
        show({
          title: 'Merchant Application Connected',
          description: 'Opening merchant application.',
          type: 'success',
        })
      );

      if (!result.onboarding_application_url)
        throw new Error('Missing onboarding_application_url');

      // Launch tilled application flow
      launchApplicationPopup(result.onboarding_application_url);
      return true;
    } catch (e) {
      dispatch(
        show({
          title: 'Failed to create Merchant Application',
          description:
            'We were unable to create the merchant application with our partner Tilled. Please try again, if this persists please contact Bambi support.',
          type: 'error',
        })
      );
      return false;
    } finally {
      setLoading(false);
    }
  }, [createMerchantAccount, dispatch, loading]);

  return {
    loading,
    trigger: handleCreateMerchantAccount,
  };
}
