import { useEffect, useMemo, useState } from 'react';

import {
  ColumnDef,
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  PassengerRead,
  PassengersListApiArg,
  usePassengersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { PassengerEmptyState } from './PassengerEmptyState';

interface PassengerDataGridProps {
  columns: ColumnDef<PassengerRead, any>[];
  useDataProvider: typeof usePassengersListQuery;
  searchTermPath?: 'passengerSearchTerm';
}

export function PassengerDataGrid({
  useDataProvider,
  columns,
  searchTermPath = 'passengerSearchTerm',
}: PassengerDataGridProps) {
  const [rowSelection, setRowSelection] = useState({});

  const passengerSearchTerm = useAppSelector(
    (state) => state.passenger[searchTermPath]
  );

  const passengerMergeModalOpen = useAppSelector(
    (state) => state.passenger.passengerMergeModalOpen
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const {
    data: passengers,
    isLoading,
    refetch,
  } = useDataProvider({
    page: pageIndex + 1,
    search: passengerSearchTerm,
    ordering: useSearchParams()[0].get(
      'ordering'
    ) as PassengersListApiArg['ordering'],
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [refetch, passengerSearchTerm]);

  useEffect(() => {
    refetch();
  }, [refetch, passengerMergeModalOpen]);

  return (
    <>
      {isLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div>
            <DataGrid
              tableId="passenger"
              dataTestId="passenger-datagrid"
              columns={columns}
              data={passengers?.results || []}
              pagination={pagination}
              totalCount={passengers?.count || 0}
              handlePaginationChange={handlePaginationChange}
              emptyState={<PassengerEmptyState />}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              getRowId={(row) => row.id}
            />
          </div>
        </>
      )}
    </>
  );
}
