import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import { AddPricingModelButton } from './AddPricingModelButton';

export function PricingModelEmptyState() {
  return (
    <EmptyState
      Icon={<CurrencyDollarIcon />}
      entityName="pricing model"
      description="You don't have any pricing models yet."
      Action={<AddPricingModelButton />}
    />
  );
}
