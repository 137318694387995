import { useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/solid';

import { DeleteModal } from 'src/common/DeleteModal';
import { usePricingPricingSchemesDestroyMutation } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';

export function PricingSchemeDeleteButton({
  pricingSchemeId,
}: {
  pricingSchemeId: string;
}) {
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);

  const [deletePricingScheme] = usePricingPricingSchemesDestroyMutation();

  return (
    <>
      <Button
        onClick={() => {
          setError('');
          setOpen(true);
        }}
        variant="ghost-danger"
        dataTestId="pricing-scheme-delete-button"
      >
        <TrashIcon className="h-5 w-5" />
      </Button>

      <DeleteModal
        open={open}
        setOpen={setOpen}
        onCancel={() => {
          setError('');
          setOpen(false);
        }}
        onConfirm={async () => {
          try {
            if (pricingSchemeId) {
              try {
                await deletePricingScheme({
                  id: pricingSchemeId,
                }).unwrap();
              } catch (error) {
                console.error(error);
              }

              setOpen(false);
            }
          } catch {
            setError(`An error occurred deleting pricing scheme!`);
          }
        }}
        title="Delete this pricing scheme?"
        description="Are you sure you want to delete this pricing scheme? This action cannot be undone."
        error={error}
      />
    </>
  );
}
