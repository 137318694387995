import SecondsToMinutesField from 'src/common/SecondsToMinutesField';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function PortalSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Facility Ride Ordering Portal Settings"
        description="Organization defaults for the ride ordering portal."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SecondsToMinutesField
            fieldPath={`facility_trip_request_lead_time_seconds`}
            label="Lead time required before pickup, in minutes"
          />
        </div>
      </div>
    </div>
  );
}
