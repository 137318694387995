import { SelectOption } from 'src/common/primitives/Select';

import {
  PAYER_SIGNATURE_OPTION_VALUES,
  payerSignatureOptions,
} from './payerSignatureOptions';

export const payerSignatureToPayerSignatureOption = (
  signatureRequired: any
) => {
  let key: PAYER_SIGNATURE_OPTION_VALUES;
  if (signatureRequired === null) {
    key = PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT;
  } else {
    key = signatureRequired
      ? PAYER_SIGNATURE_OPTION_VALUES.YES
      : PAYER_SIGNATURE_OPTION_VALUES.NO;
  }

  return payerSignatureOptions.find(
    (option) => option.value === key
  ) as SelectOption;
};
