import { Recurrence } from '../../../FormValues';
import { daysOfTheWeek } from '../constants/constants';

export function sortRecurrences(recurrences: Recurrence[]) {
  return recurrences.sort(
    (a: Recurrence, b: Recurrence) =>
      daysOfTheWeek.indexOf(a.day_of_the_week) -
      daysOfTheWeek.indexOf(b.day_of_the_week)
  );
}
