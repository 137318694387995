import '@aws-amplify/ui-react-geo/styles.css';

// React Component that renders markers for all provided lockers
import { AssignmentLiveMapList } from './AssignmentLiveMapList';
import { AssignmentLiveMapView } from './AssignmentLiveMapView';

export function LiveMap() {
  return (
    <div className="grid min-h-[90vh] sm:grid-cols-12">
      <div className="col-span-3 p-3">
        <AssignmentLiveMapList />
      </div>
      <div className="col-span-9 h-full">
        <AssignmentLiveMapView />
      </div>
    </div>
  );
}
