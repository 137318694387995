import { useState } from 'react';

import {
  PayerOrganizationRead,
  usePayersListSelectedQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverProps } from 'src/common/primitives/FilterPopover/FilterPopover';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

// Extracts ID string from list
function unwrapPayersList(list: PayerOrganizationRead[]): string[] {
  return list.map((p) => p.id);
}

function createIdentifiedList(list: string[]): PayerOrganizationRead[] {
  return list.map((s) => ({
    id: s,
  })) as PayerOrganizationRead[]; // Not a true conversion, but not needed
}

export default function PayersFilter({
  selected,
  open,
  triggerElement,
  onApply,
  onClose,
}: {
  selected: string[];
  open?: boolean;
  triggerElement?: FilterPopoverProps['triggerElement'];
  onApply: (payers: string[]) => void;
  onClose?: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: payersList, isLoading: payersLoading } =
    usePayersListSelectedQuery({
      search: searchTerm,
      payerFilters: {
        selected_payers: selected.length ? selected : [],
      },
    });

  return (
    <MultiSelectFilterPopover
      open={open}
      value={createIdentifiedList(selected)}
      searchPlaceholder="Search payers"
      filters={payersList?.results ?? []}
      renderLabel={({ payer_name }) => payer_name}
      triggerElement={
        triggerElement ?? (
          <FilterPopoverTrigger
            count={selected.length}
            label={
              payersLoading ? <LoadingIndicator variant="small" /> : 'Payer'
            }
          />
        )
      }
      onChange={(value) => {
        onApply(unwrapPayersList(value));
        onClose?.();
      }}
      onSearch={(term) => {
        setSearchTerm(term);
      }}
      onCancel={onClose}
    />
  );
}
