import React, { InputHTMLAttributes } from 'react';

import { SelectOption } from 'src/common/primitives/Select';

import { FormFieldBorderedCheckbox } from './FormFieldBorderedCheckbox';
import { FormFieldCheckbox } from './FormFieldCheckbox';
import { FormFieldComboBox } from './FormFieldComboBox';
import { FormFieldContainer } from './FormFieldContainer';
import { FormFieldDate } from './FormFieldDate';
import { FormFieldDefault } from './FormFieldDefault';
import { FormFieldGap } from './FormFieldGap';
import { FormFieldLocationTypeahead } from './FormFieldLocationTypeahead';
import { FormFieldSelect } from './FormFieldSelect';
import { SupportedGridSizes } from './FormFieldSet';
import { FormFieldSwitch } from './FormFieldSwitch';
import { FormFieldTextArea } from './FormFieldTextArea';
import { FormFieldTime } from './FormFieldTime';

export type InputType =
  | 'text'
  | 'textarea'
  | 'date'
  | 'number'
  | 'email'
  | 'location-typeahead'
  | 'password'
  | 'combobox'
  | 'bordered-checkbox'
  | 'checkbox'
  | 'select'
  | 'gap'
  | 'switch'
  | 'time'
  | 'color';

export type FormFieldProps<T> = {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  label?: React.ReactNode;
  error?: string;
  helperText?: string;
  helperTextPosition?: string;
  type: InputType;
  options?: SelectOption[];
  colSpan?: SupportedGridSizes;
  value?: T;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  className?: string;
  inline?: boolean;
};

const formFieldComponentMap = {
  'bordered-checkbox': FormFieldBorderedCheckbox,
  combobox: FormFieldComboBox,
  select: FormFieldSelect,
  switch: FormFieldSwitch,
  textarea: FormFieldTextArea,
  'location-typeahead': FormFieldLocationTypeahead,
  default: FormFieldDefault,
  text: FormFieldDefault,
  number: FormFieldDefault,
  email: FormFieldDefault,
  password: FormFieldDefault,
  date: FormFieldDate,
  checkbox: FormFieldCheckbox,
  gap: FormFieldGap,
  time: FormFieldTime,
  color: FormFieldDefault,
};

export const FormField = React.forwardRef(function _FormField<T>(
  {
    inputProps,
    label,
    error,
    value,
    helperText,
    helperTextPosition,
    type,
    options,
    colSpan,
    placeholder,
    disabled,
    onChange,
    inline,
  }: FormFieldProps<T>,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  const FormFieldComponent = formFieldComponentMap[type];

  return (
    <FormFieldContainer colSpan={colSpan}>
      <FormFieldComponent
        inputProps={inputProps}
        label={label ?? ''}
        error={error}
        helperText={helperText}
        helperTextPosition={helperTextPosition}
        type={type}
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        ref={forwardedRef}
        inline={inline}
      />
    </FormFieldContainer>
  );
});
