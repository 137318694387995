import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/app/store';
import { useTagsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { SelectOptionWithColor } from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect';

interface TripTagsContextFieldsProps {
  fieldPath: string;
  label: string;
}

export default function TripTagsContextField({
  fieldPath,
  label,
}: TripTagsContextFieldsProps) {
  const { selectedTrip } = useAppSelector((state) => state.trip);

  const { data: orgTags } = useTagsListQuery({});

  const [options, setOptions] = useState<SelectOptionWithColor[]>([]);

  useEffect(() => {
    if (orgTags?.results.length) {
      if (selectedTrip?.tags?.length) {
        const tagsNotOnTrip = orgTags.results.filter(
          (tag) => !selectedTrip.tags?.find((tripTag) => tripTag.tag === tag.id)
        );

        setOptions(
          tagsNotOnTrip.map((tag) => ({
            label: tag.tag_name,
            value: tag.id,
            color: tag.color_hex || '',
          }))
        );
      } else {
        setOptions(
          orgTags.results.map((tag) => ({
            label: tag.tag_name,
            value: tag.id,
            color: tag.color_hex || '',
          }))
        );
      }
    }
  }, [selectedTrip?.tags, orgTags]);

  return (
    <FormContextField name={fieldPath}>
      {({ field, fieldState }) => {
        const inputProps = {
          id: fieldPath,
          ...field,
        };
        return (
          <FormFieldContainer>
            <DefaultFormFieldLayout
              label={label}
              error={fieldState.error?.message?.toString()}
              inputProps={inputProps}
            >
              <MultiSelect
                options={options}
                selected={field.value}
                onChange={(selected) => {
                  field.onChange(selected);
                }}
              />
            </DefaultFormFieldLayout>
          </FormFieldContainer>
        );
      }}
    </FormContextField>
  );
}
