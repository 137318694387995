export function MapPin({
  isSelected,
  style,
}: {
  isSelected?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="38"
      viewBox="0 0 30 38"
      fill="none"
      style={style}
    >
      <path
        d="M15.0013 35.6663C16.668 27.333 28.3346 26.3635 28.3346 15.6663C28.3346 8.30254 22.3651 2.33301 15.0013 2.33301C7.63751 2.33301 1.66797 8.30254 1.66797 15.6663C1.66797 26.3635 13.3346 27.333 15.0013 35.6663Z"
        fill={isSelected ? 'rgba(38, 204, 198, 0.25)' : '#9E77ED'}
      />
      <path
        d="M15.0013 20.6663C17.7627 20.6663 20.0013 18.4278 20.0013 15.6663C20.0013 12.9049 17.7627 10.6663 15.0013 10.6663C12.2399 10.6663 10.0013 12.9049 10.0013 15.6663C10.0013 18.4278 12.2399 20.6663 15.0013 20.6663Z"
        fill="white"
      />
      <path
        d="M15.0013 35.6663C16.668 27.333 28.3346 26.3635 28.3346 15.6663C28.3346 8.30254 22.3651 2.33301 15.0013 2.33301C7.63751 2.33301 1.66797 8.30254 1.66797 15.6663C1.66797 26.3635 13.3346 27.333 15.0013 35.6663Z"
        stroke={isSelected ? '#0CACA6' : '#53389E'}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0013 20.6663C17.7627 20.6663 20.0013 18.4278 20.0013 15.6663C20.0013 12.9049 17.7627 10.6663 15.0013 10.6663C12.2399 10.6663 10.0013 12.9049 10.0013 15.6663C10.0013 18.4278 12.2399 20.6663 15.0013 20.6663Z"
        stroke={isSelected ? '#0CACA6' : '#53389E'}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
