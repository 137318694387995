// TODO: Need to resolve these skill labels across member types
// I spent a little time trying to type this a Omit<keyof AttendantSkills, 'attendant'>
// But then I hated my life so I decided this needs to be more of a future me problem
// There's an interface somewhere here that will work across member types for skill labels
// Possibly related to intl
export const ATTENDANT_SKILLS_LABEL_MAP: { [k: string]: string } = {
  can_do_wheelchair_service: 'wheelchair',
  can_do_wheelchair_xl_service: 'wheelchair xl',
  can_do_broda_chair_service: 'broda chair',
  can_do_geri_chair_service: 'geri chair',
  can_do_stretcher_service: 'stretcher',
  can_do_ambulatory_service: 'ambulatory',
  can_do_curb_to_curb: 'curb-to-curb',
  can_do_door_to_door: 'door-to-door',
  can_do_door_through_door: 'door-through-door',
  can_do_bariatric: 'bariatric',
  can_do_stairs: 'stairs',
};
