import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import Switch from 'src/common/primitives/Switch';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { useUserRoles } from '../auth/useUserRoles';
import { setSelectedMember, setShowMemberForm } from './member.slice';
import { setShowDeletedMemberships, setTeamSearchTerm } from './team.slice';

export function ManagementRouteActions() {
  const dispatch = useDispatch();
  const { isFacilityUser, isRestrictedSupport } = useUserRoles();
  const { showDeletedMemberships, teamSearchTerm } = useAppSelector(
    (state) => state.team
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setTeamSearchTerm,
    teamSearchTerm
  );

  const handleAddMember = () => {
    dispatch(setSelectedMember(null));
    dispatch(setShowMemberForm(true));
  };

  return (
    <>
      <div className="flex text-sm" style={{ height: '80%' }}>
        {!isFacilityUser && !isRestrictedSupport && (
          <label className="flex items-center gap-2">
            <Switch
              label="Show deleted accounts"
              inputProps={{
                value: showDeletedMemberships,
                onChange: (nextValue: boolean) => {
                  dispatch(setShowDeletedMemberships(nextValue));
                },
              }}
            />
            Include deleted
          </label>
        )}
      </div>
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search team"
        value={inputValue}
      />
      {!isFacilityUser && !isRestrictedSupport && (
        <Button
          onClick={() => handleAddMember()}
          variant="primary"
          dataTestId="add-a-member-action"
        >
          Add a Member
        </Button>
      )}
    </>
  );
}
