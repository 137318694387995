import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { RootState, useAppSelector } from 'src/app/store';

import { assignDefaultFormValues } from './assignDefaultFormValues';
import { getStepTitle } from './getStepTitle';
import { useAttendantFormStepTwoSubmission } from './useAttendantFormStepTwoSubmission';

export function useAttendantProfileFormViewModel() {
  const open = useAppSelector(
    (state: RootState) => state.team.showAttendantProfileModal
  );
  const selectedAttendantProfile = useAppSelector(
    (state: RootState) => state.team.selectedAttendantProfile
  );

  const defaultFormValues = assignDefaultFormValues(selectedAttendantProfile);
  const methods = useForm({
    defaultValues: defaultFormValues,
  });
  const {
    onSubmitStepTwo,
    isSubmitting,
    setStep,
    setServerErrors,
    serverErrors,
    step,
  } = useAttendantFormStepTwoSubmission({ methods });

  const title = getStepTitle(step, selectedAttendantProfile);

  useEffect(() => {
    if (open) {
      methods.reset(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    open,
    setStep,
    setServerErrors,
    step,
    methods,
    title,
    selectedAttendantProfile,
    onSubmitStepTwo,
    isSubmitting,
    serverErrors,
  };
}
