import { useAppDispatch, useAppSelector } from 'src/app/store';

import { setSelectedTags } from '../../trip.slice';
import { TagsFilter } from '../TagsFilter';

interface TagColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function TagColumnFilter({ open, onClose }: TagColumnFilterProps) {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.trip.selectedTags) ?? [];

  return (
    <TagsFilter
      open={open}
      selected={selected ?? []}
      triggerElement={<div />}
      onApply={(tags) => {
        dispatch(setSelectedTags(tags));
      }}
      onClose={onClose}
    />
  );
}
