import { Button } from 'src/common/primitives/Button';
import { TilledLogo } from 'src/common/primitives/logos/TilledLogo';

import { useCanLaunchMerchantApplication } from '../helpers/useCanLaunchMerchantApplication';
import { useConnectMerchantAccount } from '../helpers/useConnectMerchantAccount';

export function ContinueTilledMerchantApplication() {
  const { loading, trigger: handleContinueMerchantApplication } =
    useConnectMerchantAccount();

  const canLaunchApplication = useCanLaunchMerchantApplication();

  return (
    <>
      <div
        data-testid="continue-tilled-merchant-application"
        className="flex flex-grow flex-row items-center gap-2"
      >
        <TilledLogo />
        <div>Continue your merchant account application.</div>
      </div>
      <div>
        <Button
          dataTestId="tilled-connect-button"
          loading={loading}
          onClick={handleContinueMerchantApplication}
          disabled={!canLaunchApplication}
          alt={
            !canLaunchApplication
              ? 'Only Owners and Admins can perform this function'
              : ''
          }
        >
          Continue
        </Button>
      </div>
    </>
  );
}
