import { FormValues } from './FormValues';

export const defaultFormValues: FormValues = {
  id: '',
  role: undefined,
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  status: 'active',
  payer_ids: [],
};
