import { Avatar } from 'src/common/primitives/Avatar';

import { DriverProfile } from './DriverProfile';

export function DriverProfileCell({
  driverProfile,
}: {
  driverProfile: DriverProfile;
}) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-auto flex-shrink-0">
        <Avatar
          firstName={driverProfile.firstName}
          lastName={driverProfile.lastName}
        />
      </div>
      <div className="ml-4">
        <div className="font-medium text-gray-900">
          {driverProfile.firstName} {driverProfile.lastName}
        </div>
      </div>
    </div>
  );
}
