import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import {
  PassengerRead,
  TripSubscriptionRead,
} from 'src/common/external/bambi-api/bambiApi';
import { setStorageValue } from 'src/common/util/useLocalStorage/setStorageValue';

import { payersStorageKey } from '../trip.slice';
import { defaultFormValues } from './trip-subscription-form/defaultFormValues';

export interface ITripSubscriptionState {
  tripSubscriptions: TripSubscriptionRead[];
  selectedTripSubscription: TripSubscriptionRead | undefined;
  tripSubscriptionSearchTerm: string;
  selectedDateRange:
    | { from: string | undefined; to: string | undefined }
    | undefined;
  selectedPayers: string[];
  selectedTripSubscriptions: TripSubscriptionRead[];
  showTripSubscriptionModal: boolean;
  selectedPassenger: PassengerRead | null | undefined;
  isEditingPassenger: boolean;
  error: string;
  openMenuId: string;
}

export const initialState: ITripSubscriptionState = {
  tripSubscriptions: [],
  selectedTripSubscription: undefined,
  tripSubscriptionSearchTerm: '',
  selectedDateRange: undefined,
  selectedPayers: [],
  selectedTripSubscriptions: [],
  showTripSubscriptionModal: false,
  selectedPassenger: undefined,
  isEditingPassenger: false,
  error: '',
  openMenuId: '',
};

export const tripSubscriptionsSlice = createSlice({
  name: 'tripSubscription',
  initialState,
  reducers: {
    setTripSubscriptions: (
      state,
      action: { payload: TripSubscriptionRead[]; type: string }
    ) => {
      state.tripSubscriptions = action.payload;
    },
    setSelectedTripSubscription: (
      state,
      action: {
        payload:
          | {
              tripSubscription: TripSubscriptionRead | undefined;
              passenger: PassengerRead;
            }
          | undefined;
        type: string;
      }
    ) => {
      state.selectedPassenger = action.payload?.passenger;
      state.selectedTripSubscription = action.payload?.tripSubscription;
    },
    setTripSubscriptionSearchTerm: (
      state,
      action: { payload: string; type: string }
    ) => {
      state.tripSubscriptionSearchTerm = action.payload;
    },
    setSelectedDateRange: (
      state,
      action: {
        payload:
          | { from: string | undefined; to: string | undefined }
          | undefined;
        type: string;
      }
    ) => {
      const from = action.payload?.from
        ? DateTime.fromISO(action.payload.from).toFormat('yyyy-MM-dd')
        : undefined;

      const to = action.payload?.to
        ? DateTime.fromISO(action.payload.to).toFormat('yyyy-MM-dd')
        : undefined;

      state.selectedDateRange = { from, to };
    },
    setSelectedPayers: (state, action: { payload: string[]; type: string }) => {
      setStorageValue(payersStorageKey, action.payload);
      state.selectedPayers = action.payload;
    },
    setSelectedTripSubscriptions: (
      state,
      action: { payload: TripSubscriptionRead[]; type: string }
    ) => {
      state.selectedTripSubscriptions = action.payload;
    },
    setShowTripSubscriptionModal: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.showTripSubscriptionModal = action.payload;

      if (!action.payload) {
        state.selectedPassenger = undefined;
        state.selectedTripSubscription = undefined;
      }
    },
    setIsEditingPassenger: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.isEditingPassenger = action.payload;
    },
    selectPassenger: (state, action) => {
      state.selectedPassenger = action.payload;
    },
    selectNoPassenger: (state) => {
      state.selectedPassenger = defaultFormValues.passenger as PassengerRead;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setOpenMenuId: (state, action) => {
      state.openMenuId = action.payload;
    },
  },
});

export const {
  setTripSubscriptions,
  setSelectedTripSubscription,
  setTripSubscriptionSearchTerm,
  setSelectedDateRange,
  setSelectedPayers,
  setSelectedTripSubscriptions,
  setShowTripSubscriptionModal,
  setIsEditingPassenger,
  selectPassenger,
  selectNoPassenger,
  setOpenMenuId,
} = tripSubscriptionsSlice.actions;
