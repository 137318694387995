import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { DefaultChangeValueRenderer } from './DefaultChangeValueRenderer';

export function TripOxygenAmountChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const old = (
    parseInt(String(tripEvent.values_changed?.[valueKey].old_value)) / 1000
  ).toFixed(2);
  const newValue = (
    parseInt(String(tripEvent.values_changed?.[valueKey].new_value)) / 1000
  ).toFixed(2);
  return (
    <div className="flex items-center gap-1">
      <DefaultChangeValueRenderer value={old} />
      <ArrowLongRightIcon className="h-5 w-5" />
      <DefaultChangeValueRenderer value={newValue} />
    </div>
  );
}
