import { DateTime } from 'luxon';

export function makeResultsByDay(rawResult: { [k: string]: any }) {
  return (
    Object.keys(rawResult).reduce(
      (
        acc: { [k: string]: { start: string; end: string } },
        currentKey: string
      ) => {
        // We're only interested in the keys <day>_duration_minutes and <day>_start
        if (currentKey.includes('_start')) {
          const splitKey = currentKey.split('_');
          const day = splitKey[0];
          const start = rawResult[currentKey] || '';
          const duration_minutes = rawResult[`${day}_duration_minutes`] || 0;
          if (start) {
            return {
              ...acc,
              [day]: {
                start: DateTime.fromISO(start).toLocaleString(
                  DateTime.TIME_24_SIMPLE
                ),
                end: DateTime.fromISO(start)
                  .plus({ minutes: duration_minutes })
                  .toLocaleString(DateTime.TIME_24_SIMPLE),
              },
            };
          }
          return acc;
        }
        return acc;
      },
      {}
    ) || []
  );
}
