import { createSlice, current } from '@reduxjs/toolkit';
export interface IChatState {
  unreadMessageCount: number;
  playNotificationSound: boolean;
  sendbirdAccessToken: string;
  chatError: boolean;
}

export const initialState: IChatState = {
  unreadMessageCount: 0,
  playNotificationSound: false,
  sendbirdAccessToken: '',
  chatError: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setUnreadMessageCount: (state, action) => {
      if (action.payload > current(state).unreadMessageCount) {
        state.playNotificationSound = true;
      }
      state.unreadMessageCount = action.payload;
    },
    setPlayNotificationSound: (state, action) => {
      state.playNotificationSound = action.payload;
    },
    setSendbirdAccessToken: (state, action) => {
      state.sendbirdAccessToken = action.payload;
    },
    setChatError: (state, action) => {
      state.chatError = action.payload;
    },
  },
});

export const {
  setUnreadMessageCount,
  setPlayNotificationSound,
  setSendbirdAccessToken,
  setChatError,
} = chatSlice.actions;
