export function PagePillButton({
  pageNumber,
  currentPage,
  setCurrentPage,
}: {
  pageNumber: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) {
  return (
    <button
      key={pageNumber}
      data-testid={`page-button-${pageNumber}`}
      className={`focus:ring-indigo-500 mx-1 inline-flex items-center rounded border px-4 py-3 text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-mint ${
        currentPage === pageNumber
          ? 'CURRENT bg-gray-200 outline-gray-400'
          : 'bg-gray-50 outline-gray-100'
      }`}
      onClick={() => {
        setCurrentPage(pageNumber);
      }}
    >
      {pageNumber}
    </button>
  );
}
