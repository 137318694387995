import { DateTime } from 'luxon';

export function isPickupTimeBeforeAppointmentTime(
  pickupTime: string,
  appointmentTime?: string
) {
  if (!appointmentTime) return true;

  const diff = DateTime.fromISO(pickupTime).diff(
    DateTime.fromISO(appointmentTime)
  );

  return diff.milliseconds < 0 || 'Pickup Time must be before Appointment Time';
}
