import { createSlice } from '@reduxjs/toolkit';

import { TagRead } from 'src/common/external/bambi-api/bambiApi';

export interface SettingState {
  tags: TagRead[];
  selectedTag: TagRead | undefined;
  tagSearchTerm: string;
  showTagsModal: boolean;
  showDeleteTagModal: boolean;
}

export const initialState: SettingState = {
  tags: [],
  selectedTag: undefined,
  tagSearchTerm: '',
  showTagsModal: false,
  showDeleteTagModal: false,
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
    setTagSearchTerm: (state, action) => {
      state.tagSearchTerm = action.payload;
    },
    setShowTagsModal: (state, action) => {
      state.showTagsModal = action.payload;
    },
    setShowDeleteTagModal: (state, action) => {
      state.showDeleteTagModal = action.payload;
    },
  },
});

export const {
  setSelectedTag,
  setTagSearchTerm,
  setShowTagsModal,
  setShowDeleteTagModal,
} = settingSlice.actions;
