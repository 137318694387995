import { Passenger } from 'src/common/external/bambi-api/bambiApi';
import { Avatar } from 'src/common/primitives/Avatar';

export function PassengerProfileCell({ passenger }: { passenger: Passenger }) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-auto flex-shrink-0">
        <Avatar
          firstName={passenger.first_name}
          lastName={passenger.last_name}
        />
      </div>
      <div className="ml-4">
        <div className="font-medium text-gray-900">
          {passenger.first_name} {passenger.last_name}
        </div>
      </div>
    </div>
  );
}
