import { useEffect, useState } from 'react';

import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

import { useFeature } from 'src/app/FeatureToggle';
import { useOrganizationSettingsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { emptyHeaders } from 'src/common/external/bambi-api/bambiBaseQuery';
import { Button } from 'src/common/primitives/Button';
import SecondsToMinutesField from 'src/common/SecondsToMinutesField';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { FormValues } from '../FormValues';
import { AppointmentTimeContextField } from './AppointmentTimeContextField';
import { DispatcherNotes } from './DispatcherNotes';
import { DropoffAddressContextField } from './DropoffAddressContextField';
import { DynamicFieldNameRootContext } from './DynamicFieldNameRootContext';
import { ExternalTripId } from './ExternalTripId';
import {
  AddressDetailsContextField,
  ContactNameContextField,
  ContactPhoneContextField,
  DriverNotesContextField,
  StairEquipmentContextField,
  StairsContextField,
} from './LocationDetailFields';
import { PickupAddressContextField } from './PickupAddressContextField';
import { PickupTimeContextField } from './PickupTimeContextField/PickupTimeContextField';
import { TripDateContextField } from './TripDateContextField/TripDateContextField';
import { TripInputPrice } from './TripInputPrice/TripInputPrice';
import { useTripPriceEstimation } from './TripInputPrice/useTripPriceEstimation';
import { TripInputPriceV1 } from './TripInputPriceV1/TripInputPriceV1';
import TripTagsContextField from './TripTagsContextField';
import { useTripFieldArray } from './useTripFieldArray';
import { WillCallContextField } from './WillCallContextField';

export function TripLeg({
  index,
  field,
}: {
  index: number;
  field: Record<'id', string>;
}) {
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  const { isEnabled: isPricingV1Enabled } = useFeature('pricing version 1');
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  const [showPickupDetails, setShowPickupDetails] = useState(false);
  const [showDropoffDetails, setShowDropoffDetails] = useState(false);
  const form = useFormContext<FormValues>();
  const isWillCall = form.watch(`trips.${index}.timing.is_will_call`);

  const { data: orgSettings, isLoading } =
    useOrganizationSettingsRetrieveQuery(emptyHeaders);
  // TODO: Remove once Pricing v0 is fully deprecated
  const priceEstimationState = useTripPriceEstimation({ tripIndex: index });
  useEffect(() => {
    if (!isLoading && orgSettings?.expand_trip_location_details) {
      setShowPickupDetails(true);
      setShowDropoffDetails(true);
    }
  }, [isLoading, orgSettings?.expand_trip_location_details]);

  return (
    <div key={field.id}>
      <DynamicFieldNameRootContext.Provider value={`trips.${index}`}>
        <li key={field.id} className="relative flex gap-x-4">
          <div
            className={`flex w-full flex-col gap-y-2 rounded-md p-3 shadow-md ring-1 ring-inset transition ease-in-out ${
              isWillCall ? 'bg-[#efd2ff11] ring-[#efd2ff]' : 'ring-mint'
            }`}
          >
            <div className="flex justify-between gap-4">
              <div className="flex grow flex-col gap-y-2">
                <div className="flex grow flex-col gap-y-2">
                  <FieldRow>
                    <div>
                      <WillCallContextField
                        fieldPath={`trips.${index}.timing`}
                      />
                    </div>
                    <div>
                      <TripDateContextField
                        fieldPath={`trips.${index}.timing`}
                      />
                    </div>
                    <DispatcherNotes />
                    {/* Pricing Inputs */}
                    {
                      // TODO: Remove once Pricing v0 is fully deprecated
                      !(
                        shouldShowRideOrderingPortalFeatures ||
                        (isPricingV1Enabled &&
                          orgSettings?.is_pricing_v1_enabled)
                      ) && (
                        <TripInputPrice
                          tripIndex={index}
                          priceEstimationState={priceEstimationState}
                        />
                      )
                    }
                    {
                      // This can be simplified to just !shouldShowRideOrderingPortalFeatures
                      // once Pricing v0 is fully deprecated
                      !shouldShowRideOrderingPortalFeatures &&
                        isPricingV1Enabled &&
                        orgSettings?.is_pricing_v1_enabled && (
                          <TripInputPriceV1 tripIndex={index} />
                        )
                    }
                    <ExternalTripId />
                  </FieldRow>
                </div>
                <div className="flex grow flex-col gap-y-2">
                  <FieldRow>
                    <PickupAddressContextField index={index} />
                    <AddressDetailsContextField
                      fieldPath={`trips.${index}.pickupLocation`}
                    />
                    <PickupTimeContextField
                      fieldPath={`trips.${index}.timing`}
                      index={index}
                    />
                  </FieldRow>
                  {showPickupDetails && (
                    <div>
                      <FieldRow>
                        <div className="grow">
                          <StairsContextField
                            fieldPath={`trips.${index}.pickupLocation`}
                          />
                        </div>
                        <div className="grow">
                          <StairEquipmentContextField
                            fieldPath={`trips.${index}.pickupLocation`}
                          />
                        </div>
                        <div className="grow">
                          <ContactNameContextField
                            fieldPath={`trips.${index}.pickupLocation`}
                          />
                        </div>
                        <div className="grow">
                          <ContactPhoneContextField
                            fieldPath={`trips.${index}.pickupLocation`}
                          />
                        </div>
                        {!shouldShowRideOrderingPortalFeatures && (
                          <div className="grow">
                            <SecondsToMinutesField
                              fieldPath={`trips.${index}.load_time_seconds`}
                              disabled={isSelectedTripInThePast}
                              label="Load Time (Minutes)"
                            />
                          </div>
                        )}
                      </FieldRow>
                      <FieldRow>
                        <div className="grow">
                          <DriverNotesContextField
                            fieldPath={`trips.${index}.pickupLocation`}
                          />
                        </div>
                      </FieldRow>
                    </div>
                  )}
                </div>
                <div className="flex grow flex-col gap-y-2">
                  <FieldRow>
                    <DropoffAddressContextField index={index} />
                    <AddressDetailsContextField
                      fieldPath={`trips.${index}.dropoffLocation`}
                    />
                    <AppointmentTimeContextField
                      fieldPath={`trips.${index}.timing`}
                      index={index}
                    />
                  </FieldRow>
                  {showDropoffDetails && (
                    <div>
                      <FieldRow>
                        <div className="grow">
                          <StairsContextField
                            fieldPath={`trips.${index}.dropoffLocation`}
                          />
                        </div>
                        <div className="grow">
                          <StairEquipmentContextField
                            fieldPath={`trips.${index}.dropoffLocation`}
                          />
                        </div>
                        <div className="grow">
                          <ContactNameContextField
                            fieldPath={`trips.${index}.dropoffLocation`}
                          />
                        </div>
                        <div className="grow">
                          <ContactPhoneContextField
                            fieldPath={`trips.${index}.dropoffLocation`}
                          />
                        </div>
                        {!shouldShowRideOrderingPortalFeatures && (
                          <div className="grow">
                            <SecondsToMinutesField
                              fieldPath={`trips.${index}.unload_time_seconds`}
                              disabled={isSelectedTripInThePast}
                              label="Unload Time (Minutes)"
                            />
                          </div>
                        )}
                      </FieldRow>
                      <FieldRow>
                        <div className="grow">
                          <DriverNotesContextField
                            fieldPath={`trips.${index}.dropoffLocation`}
                          />
                        </div>
                      </FieldRow>
                    </div>
                  )}
                </div>
                {!shouldShowRideOrderingPortalFeatures && (
                  <div className="flex grow flex-col gap-y-2">
                    <TripTagsContextField
                      fieldPath={`trips.${index}.trip_tags`}
                      label="Tags"
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col items-center gap-1 gap-y-2">
                <DeleteTripLegButton index={index} form={form} />
                <ShowLocationDetailsButton
                  title="Pickup Details"
                  showDetails={showPickupDetails}
                  setShowDetails={setShowPickupDetails}
                />
                <ShowLocationDetailsButton
                  title="Dropoff Details"
                  showDetails={showDropoffDetails}
                  setShowDetails={setShowDropoffDetails}
                />
              </div>
            </div>
          </div>
        </li>
      </DynamicFieldNameRootContext.Provider>
    </div>
  );
}

const FieldRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex justify-between gap-4">{children}</div>;
};

interface DeleteTripLegButtonProps {
  index: number;
  form: ReturnType<typeof useFormContext<FormValues>>;
}

const DeleteTripLegButton = ({ index, form }: DeleteTripLegButtonProps) => {
  const { fields, remove } = useTripFieldArray();

  return (
    <Button
      variant="danger"
      className="w-full"
      disabled={index === 0}
      onClick={() => {
        if (index === 0) return;
        remove(index);
        if (index === fields.length - 1) {
          form.setValue('is_round_trip', false);
        }

        if (index === 1) {
          const trips = form.getValues('trips');
          const mutTrips = trips.map((trip) => {
            return {
              ...trip,
              is_wait_and_return_inbound: false,
              is_wait_and_return_outbound: false,
            };
          });
          form.setValue('trips', mutTrips);
        }
      }}
    >
      <TrashIcon className="h-4 w-4" />
    </Button>
  );
};

interface ShowLocationDetailsButtonProps {
  title: string;
  setShowDetails: (show: boolean) => void;
  showDetails: boolean;
}

const ShowLocationDetailsButton = ({
  setShowDetails,
  showDetails,
  title,
}: ShowLocationDetailsButtonProps) => {
  return (
    <Button
      className="flex w-full justify-start gap-2"
      variant="secondary"
      onClick={() => {
        setShowDetails(!showDetails);
      }}
      justifyContent="start"
    >
      {showDetails ? (
        <MinusCircleIcon className="h-5 w-5" />
      ) : (
        <PlusCircleIcon className="h-5 w-5" />
      )}
      <span>{title}</span>
    </Button>
  );
};
