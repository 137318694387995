import {
  AssignedDriverRead,
  DriverRead,
} from 'src/common/external/bambi-api/bambiApi';

export const convertAssignedDriverToDriver = (
  assignedDriver: AssignedDriverRead
): DriverRead => {
  return {
    id: assignedDriver.driver || '',
    first_name: assignedDriver.first_name,
    last_name: assignedDriver.last_name,
    membership: assignedDriver.membership,
  } as DriverRead;
};
