import { useCallback, useState } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { DateRange } from 'react-day-picker';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import DateRangePicker from 'src/common/date/DateRangePicker';
import { formatDateAsTodayOrMonthDay } from 'src/common/date/formatDateAsTodayOrMonthDay';
import { useLazyGetInvoicesExportListAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { MultiSelectPayerField } from 'src/common/MultiSelectPayerContextField';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal, ModalProps } from 'src/common/primitives/Modal';

type InvoiceExportForm = {
  dateRange: DateRange;
  payers: string[];
};

interface DownloadInvoicesProps {
  open: ModalProps['open'];
  setOpen: ModalProps['setOpen'];
  onClose: ModalProps['onClose'];
}

export function DownloadInvoices({
  open,
  setOpen,
  onClose,
}: DownloadInvoicesProps) {
  const form = useForm<Partial<InvoiceExportForm>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [trigger] = useLazyGetInvoicesExportListAsCsvQuery();

  const handleClose = useCallback(() => {
    onClose?.();
    form.reset();
  }, [onClose, form]);

  const handleSubmit = form.handleSubmit(async (data) => {
    if (!data.payers || !data.payers.length) {
      setError('Please select a Payer');
      return;
    }
    if (!data.dateRange?.from) {
      setError('Please select a start date');
      return;
    }
    setLoading(true);

    try {
      setError(undefined);

      await trigger({
        rangeStart: DateTime.fromJSDate(data.dateRange.from).toISODate(),
        rangeEnd: data.dateRange.to
          ? DateTime.fromJSDate(data.dateRange.to).toISODate()
          : undefined,
        tripPayerIn: data.payers,
        format: 'csv',
      }).unwrap();

      handleClose();
    } catch (error) {
      const errMsg = 'There was an error downloading the data.';
      setError(errMsg);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal open={open} setOpen={setOpen} contentClassnames="max-w-6xl">
      <h1 className="text-2xl">Download Invoices</h1>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <FormProvider {...form}>
          <div className="mt-2">
            <div>Select the date range *</div>
            <div className="flex items-center justify-between gap-2 pt-2">
              <Controller
                control={form.control}
                name="dateRange"
                render={({ field }) => {
                  let fromDate = '';
                  let toDate = '';

                  if (field.value?.from) {
                    fromDate = formatDateAsTodayOrMonthDay(
                      DateTime.fromJSDate(field.value.from).toISODate(),
                      true
                    );
                  }

                  if (field.value?.to) {
                    toDate = formatDateAsTodayOrMonthDay(
                      DateTime.fromJSDate(field.value.to).toISODate(),
                      true
                    );
                  }

                  return (
                    <DateRangePicker
                      triggerElement={
                        <Button>
                          <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" />{' '}
                          {field.value
                            ? ` ${fromDate}${toDate ? ' - ' + toDate : ''}`
                            : 'Select Date'}
                        </Button>
                      }
                      onDateRangeChange={(r) => {
                        field.onChange(r);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="py-4">
            <MultiSelectPayerField
              fieldPath="payers"
              rules={{
                required: 'Must select a payer',
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 pt-6">
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              dataTestId="download-csv"
              variant="primary"
              onClick={handleSubmit}
            >
              Export as CSV
            </Button>
          </div>
        </FormProvider>
      )}
    </Modal>
  );
}
