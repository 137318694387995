import { IToastConfig } from 'src/common/primitives/Toast';

export function generateMemberAddSuccessToast(
  id: number,
  memberName: string
): IToastConfig {
  return {
    id,
    title: `Invite sent to ${memberName}`,
    description:
      'Your new member will get an invitation via their email with an instruction to onboard :)',
  };
}
