import { useState } from 'react';

import { FormFieldDefault } from 'src/common/FormField/FormFieldDefault';
import { FormFieldLocationTypeahead } from 'src/common/FormField/FormFieldLocationTypeahead';

import { BillingAddress } from './types';
import { parsePlaceAddress } from './utils';

interface BillingAddressFormProps {
  className?: string;
  onChange: (address: BillingAddress) => void;
}

export function BillingAddressForm({
  className,
  onChange,
}: BillingAddressFormProps) {
  const [address, setAddress] = useState<BillingAddress>({
    city: '',
    country: '',
    postalCode: '',
    state: '',
    streetAddress1: '',
    streetAddress2: '',
  });

  return (
    <div className={className} data-testid="billing-address-form">
      <FormFieldLocationTypeahead
        label="Address"
        inputProps={{
          id: 'billing-address-form-typeahead',
        }}
        onChange={(e) => {
          const address = parsePlaceAddress(e.label);
          setAddress(address);
          onChange(address);
        }}
      />
      <FormFieldDefault
        type="text"
        label="Zip Code *"
        className="mt-2"
        inputProps={{
          id: 'billing-address-form-zip',
          required: true,
          value: address.postalCode,
          onChange: (e) => {
            setAddress({ ...address, postalCode: e.target.value });
            onChange({ ...address, postalCode: e.target.value });
          },
        }}
      />
    </div>
  );
}
