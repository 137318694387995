import { gray } from '@radix-ui/colors';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const itemStyles = {
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 5px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: gray.gray6,
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    backgroundColor: gray.gray4,
  },
};

export const DropdownMenuCheckboxItem = styled(
  DropdownMenu.CheckboxItem,
  itemStyles
);
export const DropdownMenuItem = styled(DropdownMenu.Item, itemStyles);
export const DropdownMenuRadioItem = styled(DropdownMenu.RadioItem, itemStyles);
export const DropdownMenuSubTrigger = styled(DropdownMenu.SubTrigger, {
  '&[data-state="open"]': {
    backgroundColor: gray.gray6,
  },
  ...itemStyles,
});
