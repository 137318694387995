import { useContext, useEffect, useState } from 'react';

import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Alert } from 'src/common/Alert/Alert';
import { CurrencyInput } from 'src/common/CurrencyInput';
import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import {
  PriceSummary,
  usePricingPriceQuoteCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal } from 'src/common/primitives/Modal/Modal';
import { useFormValues } from 'src/common/useFormValues';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { PriceSummaryTable } from 'src/features/pricing/PriceSummaryModal/PriceSummaryTable/PriceSummaryTable';

import { addATrip } from '../../addATrip.slice';
import { FormValues } from '../../FormValues';
import { DynamicFieldNameRootContext } from '../DynamicFieldNameRootContext';

type TripInputPriceV1Props = {
  tripIndex: number;
};

export function TripInputPriceV1({ tripIndex }: TripInputPriceV1Props) {
  const dispatch = useAppDispatch();
  const tripLegsPriceEstimationState = useAppSelector(
    (state) => state.addATrip.tripLegsPriceEstimationState
  );
  const selectedTrip = useAppSelector((state) => state.addATrip.selectedTrip);
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);
  const fieldName = `${fieldNameRoot}.input_price_cents`;
  const [fetchQuote, quoteRequestState] = usePricingPriceQuoteCreateMutation();
  const {
    trips,
    service_details,
    pricing,
    passenger,
    must_provide_wheelchair,
  } = useFormValues<FormValues>();
  const trip = trips[tripIndex];
  const tripLegPriceEstimationState = tripLegsPriceEstimationState[tripIndex];
  const [isQuoteSummaryOpen, setIsQuoteSummaryOpen] = useState(false);
  const [priceQuoteError, setPriceQuoteError] = useState<string | undefined>();
  const [priceSummaryResult, setPriceSummaryResult] = useState<
    PriceSummary | undefined
  >();
  // TODO: Using the generic form <FormValues> doesn't like when I use a dynamic field name
  const { setValue } = useFormContext();

  // We assume mounting is the trip being added and unmounting is the trip being removed
  // In the case of the modal closing, we assume pricing state is no longer needed
  useEffect(() => {
    dispatch(addATrip.actions.onTripLegAdded(tripIndex));
    return () => {
      dispatch(addATrip.actions.onTripLegRemoved(tripIndex));
    };
  }, [dispatch, tripIndex]);

  // And we track these fields to determine if the price estimation is stale
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (tripLegPriceEstimationState?.isEstimated) {
      dispatch(addATrip.actions.onTripLegPriceEstimationStale(tripIndex));
    }
    // We don't want exhaustive deps
    // It may be better to call this inside each field we want to affect staleness
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    service_details.space_type,
    service_details.service_type,
    service_details.num_accompanying_passengers,
    service_details.num_attendants_needed,
    must_provide_wheelchair,
    trip.dropoffLocation.address.value,
    trip.pickupLocation.address.value,
    trip.timing.is_will_call,
    trip.timing.date,
    trip.timing.pickup_time,
    pricing.payer,
    passenger.needs_bariatric_transport,
    service_details.is_oxygen_required,
    trip.pickupLocation.stairs,
    trip.dropoffLocation.stairs,
  ]);
  return (
    <FormContextField name={fieldName}>
      {({ field, fieldState }) => {
        const { onChange, ...remainingProps } = field;
        return (
          <DefaultFormFieldLayout
            label={getFieldLabel(tripLegPriceEstimationState)}
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: fieldName }}
          >
            <div
              className="flex items-center gap-2"
              data-testid={`trip-${tripIndex}-input-price-v1`}
            >
              <CurrencyInput
                id={fieldName}
                {...remainingProps}
                disabled={quoteRequestState.isLoading}
                onValueChange={(values) => {
                  // Passing undefined to onChange will reset the value to the original value
                  // When editing a trip
                  // defaultValue isn't really a choice because we need to update the value
                  // when the user estimates the price
                  onChange(values.floatValue || '');
                }}
                onInput={() => {
                  dispatch(
                    addATrip.actions.onTripLegPriceEstimationManuallyUpdated(
                      tripIndex
                    )
                  );
                }}
              />
              <Button
                variant={
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  tripLegPriceEstimationState?.isEstimated
                    ? 'secondary'
                    : 'primary-outline'
                }
                dataTestId={`trip-${tripIndex}-price-estimation-button-v1`}
                className="whitespace-nowrap"
                onClick={async () => {
                  if (
                    !(
                      typeof pricing.payer === 'string' &&
                      trip.pickupLocation.address.value &&
                      trip.dropoffLocation.address.value &&
                      trip.timing.date &&
                      trip.timing.pickup_time
                    )
                  )
                    return;
                  try {
                    const quoteResponse = await fetchQuote({
                      priceQuote: {
                        is_canceled: selectedTrip?.canceled_at ? true : false,
                        // Don't send empty strings or API will throw complain
                        cancellation_reason:
                          selectedTrip?.canceled_reason || null,
                        canceled_at: selectedTrip?.canceled_at,
                        pickup_at: convertISODateTimePartsToUTCISO({
                          date: trip.timing.date,
                          time: trip.timing.pickup_time,
                        }),
                        pickup_place_id: trip.pickupLocation.address.value,
                        dropoff_place_id: trip.dropoffLocation.address.value,
                        wait_time_minutes:
                          typeof selectedTrip?.pickup_wait_time_seconds ===
                          'number'
                            ? Math.round(
                                selectedTrip.pickup_wait_time_seconds / 60
                              )
                            : 0,
                        steps_pickup: trip.pickupLocation.stairs,
                        steps_dropoff: trip.dropoffLocation.stairs,
                        num_attendants: service_details.num_attendants_needed,
                        num_additional_passengers:
                          service_details.num_accompanying_passengers,
                        is_bariatric: !!passenger.needs_bariatric_transport,
                        is_wheelchair_provided: must_provide_wheelchair,
                        is_oxygen_provided: service_details.is_oxygen_required,
                        space_type: service_details.space_type,
                        payer: pricing.payer,
                      },
                    });
                    if ('error' in quoteResponse) {
                      setPriceQuoteError(
                        formatServerError(quoteResponse.error)
                      );
                      return;
                    }
                    setPriceSummaryResult(quoteResponse.data.price_summary);
                    setIsQuoteSummaryOpen(true);
                  } catch (e) {
                    setPriceQuoteError('An unknown error occurred');
                  }
                }}
                disabled={
                  quoteRequestState.isLoading ||
                  !(
                    typeof pricing.payer === 'string' &&
                    trip.pickupLocation.address.value &&
                    trip.dropoffLocation.address.value &&
                    trip.timing.date &&
                    trip.timing.pickup_time
                  )
                }
              >
                {quoteRequestState.isLoading ? (
                  <LoadingIndicator variant="small" />
                ) : (
                  <div title="Get Quote">
                    <CurrencyDollarIcon className="h-4 w-4" />
                  </div>
                )}
              </Button>
              <Modal
                open={isQuoteSummaryOpen}
                setOpen={() => {}}
                contentClassnames="w-full max-w-[60vw]"
                data-testid="trip-form-price-summary-modal"
              >
                {priceSummaryResult ? (
                  <div className="flex flex-col gap-y-2">
                    <PriceSummaryTable
                      summary={priceSummaryResult as PriceSummary}
                    />
                    <div className="flex justify-end gap-4 pt-4">
                      <Button
                        variant="secondary"
                        onClick={() => setIsQuoteSummaryOpen(false)}
                        dataTestId="price-summary-modal-cancel-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          // total can be null or undefined so we want to make sure
                          // we use 0. Otherwise the input won't update the value
                          setValue(fieldName, priceSummaryResult.total ?? 0);
                          dispatch(
                            addATrip.actions.onTripLegPriceEstimationAccepted(
                              tripIndex
                            )
                          );
                          setIsQuoteSummaryOpen(false);
                        }}
                        dataTestId="price-summary-modal-accept-button"
                      >
                        Accept
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    Unable to generate quote. Check if pricing scheme exists for
                    these trip details.
                  </>
                )}
              </Modal>
              <Modal
                open={Boolean(priceQuoteError)}
                setOpen={() => {}}
                contentClassnames="w-full max-w-[33vw]"
                data-testid="price-summary-error-modal"
              >
                <div className="flex flex-col gap-2">
                  <Alert
                    title="Error fetching price quote"
                    message={priceQuoteError as string}
                    severity="error"
                  />
                  <div className="flex justify-end gap-4 pt-4">
                    <Button
                      variant="primary"
                      onClick={() => setPriceQuoteError(undefined)}
                      dataTestId="price-summary-error-modal-cancel-button"
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}

function getFieldLabel(priceEstimationState?: {
  isEstimated: boolean;
  isStale: boolean;
  isManuallyUpdated: boolean;
}) {
  const baseLabel = 'Input Price';
  if (!priceEstimationState) return baseLabel;
  if (priceEstimationState.isEstimated) {
    return `${baseLabel} (Estimated)`;
  }
  if (priceEstimationState.isStale) {
    return `${baseLabel} (Stale)`;
  }
  if (priceEstimationState.isManuallyUpdated) {
    return `${baseLabel} (Manually Updated)`;
  }
  return baseLabel;
}
