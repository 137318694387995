import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, useAppSelector } from 'src/app/store';
import { DeleteAction } from 'src/common/DeleteAction';
import {
  PatchedTag,
  Tag,
  useTagsCreateMutation,
  useTagsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { WizardHeader } from 'src/common/wizard/WizardHeader';

import {
  setSelectedTag,
  setShowDeleteTagModal,
  setShowTagsModal,
} from '../../setting.slice';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { DeleteTagModal } from './DeleteTagModal';
import { generateCreateSuccessToast } from './generateCreateSuccessToast';
import { generateUpdateSuccessToast } from './generateUpdateSuccessToast';

export function TagForm() {
  const dispatch = useDispatch();

  const { showTagsModal, selectedTag } = useSelector(
    (state: RootState) => state.setting
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const nextToastId = useAppSelector((state: RootState) => state.toast.nextId);

  const [createTag] = useTagsCreateMutation();
  const [updateTag] = useTagsPartialUpdateMutation();

  const defaultFormValues = assignDefaultFormValues(selectedTag);
  const methods = useForm({
    defaultValues: defaultFormValues,
  });

  const onClose = () => {
    dispatch(setShowTagsModal(false));
    dispatch(setSelectedTag(undefined));
    methods.reset();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const values = methods.getValues();
      if (!values.id) {
        await createTag({
          tag: values as Tag,
        }).unwrap();
        dispatch(show(generateCreateSuccessToast(nextToastId)));
      } else {
        await updateTag({
          id: values.id,
          patchedTag: values as PatchedTag,
        }).unwrap();
        dispatch(show(generateUpdateSuccessToast(nextToastId)));
      }

      onClose();
    } catch (error) {
      const msg = formatServerError(error);
      setError(msg);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (showTagsModal) {
      methods.reset(defaultFormValues);
    }
    return () => {
      setError('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTagsModal]);

  return (
    <>
      <Modal
        open={showTagsModal}
        setOpen={(val) => dispatch(setShowTagsModal(val))}
        onClose={() => onClose()}
        data-testid="tags-dialog"
      >
        <div className="w-full md:min-w-[350px]">
          <WizardHeader
            title={selectedTag?.id ? 'Edit Tag' : 'Add a New Tag'}
          />
          {error && (
            <div className="my-2">
              <FormErrorMessage>{error}</FormErrorMessage>
            </div>
          )}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="my-4 flex">
                <FormContextField
                  name="tag_name"
                  rules={{ required: 'Tag name is required' }}
                >
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Tag Name *"
                        error={fieldState.error?.message?.toString()}
                        type="text"
                        inputProps={{
                          id: 'tag_name',
                          ...field,
                          'data-testid': 'tag_name',
                        }}
                        value={field.value}
                      />
                    );
                  }}
                </FormContextField>
              </div>
              {!defaultFormValues.internal_use && (
                <div className="my-4 flex">
                  <FormContextField name="description">
                    {({ field, fieldState }) => {
                      return (
                        <FormField
                          label="Description"
                          error={fieldState.error?.message?.toString()}
                          type="textarea"
                          inputProps={{
                            id: 'tag_name',
                            ...field,
                            'data-testid': 'description',
                          }}
                          value={field.value}
                        />
                      );
                    }}
                  </FormContextField>
                </div>
              )}
              <div className="my-4 flex">
                <FormContextField
                  name="color_hex"
                  rules={{ required: 'Color is required' }}
                >
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Tag Color"
                        error={fieldState.error?.message?.toString()}
                        type="color"
                        inputProps={{
                          id: 'color_hex',
                          ...field,
                          'data-testid': 'color_hex',
                          className: 'w-20 h-10',
                        }}
                        value={field.value}
                      />
                    );
                  }}
                </FormContextField>
              </div>
              {selectedTag ? (
                <DeleteAction
                  onDelete={() => {
                    dispatch(setShowDeleteTagModal(true));
                    dispatch(setShowTagsModal(false));
                  }}
                  label="Delete this tag"
                />
              ) : null}
              <div className="mt-8 grid grid-cols-2 gap-4">
                <Button
                  onClick={() => {
                    onClose();
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="w-full flex-grow"
                  variant="primary"
                  disabled={isSubmitting}
                  dataTestId="save-tag-button"
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
      <DeleteTagModal />
    </>
  );
}
