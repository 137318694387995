import { InpectionOutcome, VehicleInspection } from '../types';
import { isPassFailInspection } from './isPassFailInspection';

export function getInspectionOutcome(
  inspection: VehicleInspection
): InpectionOutcome {
  if (!inspection.length) {
    return 'Empty';
  }

  if (!isPassFailInspection(inspection)) {
    return 'No pass/fail values';
  }
  return inspection.some((item) => item.value === 'fail') ? 'Fail' : 'Pass';
}
