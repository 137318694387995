import { createColumnHelper } from '@tanstack/react-table';

import { TagRead } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

import { RowActions } from './RowActions';

const columnHelper = createColumnHelper<TagRead>();

export const columns = [
  columnHelper.accessor('color_hex', {
    id: 'color',
    header: 'Color',
    cell: ({ getValue }) => colorDisplay(getValue()),
  }),
  columnHelper.accessor('tag_name', {
    id: 'tag_name',
    header: 'Name',
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: 'Description',
    cell: ({ getValue }) => (
      <div className="md:whitespace-pre-wrap">{getValue()}</div>
    ),
  }),
  columnHelper.accessor('internal_use', {
    id: 'internal_use',
    header: '',
    cell: ({ getValue }) => bambiDisplay(getValue()),
  }),
  columnHelper.display({
    id: 'edit',
    header: 'Edit',
    cell: ({ row }) => <RowActions tag={row.original} />,
  }),
];

const colorDisplay = (color: TagRead['color_hex']) => {
  return (
    <div>
      {color ? (
        <div
          className="h-5 w-10 rounded-full"
          style={{ backgroundColor: color }}
        ></div>
      ) : (
        <p>No color selected</p>
      )}
    </div>
  );
};

const bambiDisplay = (internal_use: TagRead['internal_use']) => {
  return <>{internal_use && <Badge label="Bambi" color="mint-inverse" />}</>;
};
