import { PriceSummaryData } from './usePriceSummaryTableData';

export function HolidayFeeDetails({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <td></td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Holiday</div>
        <div>{data?.holiday || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Date</div>
        <div>{data?.date || '--'}</div>
      </td>
    </>
  );
}
