import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import {
  OverrideStatusEnum,
  TripUpdateRead,
} from 'src/common/external/bambi-api/bambiApi';

import { TripStatusBadge } from '../../../management/TripStatusBadge';

export function TripStatusChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  return (
    <div className="flex items-center gap-1">
      <TripStatusBadge
        status={
          tripEvent.values_changed?.[valueKey].old_value as OverrideStatusEnum
        }
      />
      <ArrowLongRightIcon className="h-5 w-5" />
      <TripStatusBadge
        status={
          tripEvent.values_changed?.[valueKey].new_value as OverrideStatusEnum
        }
      />
    </div>
  );
}
