import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  useTripsBulkAssignCreateMutation,
  useTripsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import {
  onBulkAssignRequestedValidTrips,
  onConfirmBulkAssignTrip,
} from 'src/features/dispatch/dispatcher.slice';

export function useBulkAssignTripFlow() {
  const [validationMessages, setValidationMessages] = useState<
    string[] | undefined
  >(undefined);
  const {
    isAssignTripModalOpen,
    tripIds,
    assignmentId,
    validateBulkAssignmentsResponse,
  } = useAppSelector((state) => state.dispatcher.bulkTripAssignmentFlow);

  const selectedUnassignedTripIds = useAppSelector(
    (state) => state.dispatcher.selectedUnassignedTripIds
  );

  const dispatch = useAppDispatch();
  const [bulkAssign] = useTripsBulkAssignCreateMutation();
  const { data: trips } = useTripsListQuery({
    idIn: selectedUnassignedTripIds,
  });

  const onConfirm = async () => {
    if (!trips?.results.length) {
      return;
    }

    if (trips.results.some((x) => x.status === 'requested')) {
      dispatch(onBulkAssignRequestedValidTrips({ assignmentId }));
      dispatch(onConfirmBulkAssignTrip());
      return;
    }

    await bulkAssign({
      bulkTripAssign: {
        trip_ids: tripIds,
        assignment_id: assignmentId,
      },
    }).unwrap();

    dispatch(onConfirmBulkAssignTrip());
  };

  useEffect(() => {
    if (validateBulkAssignmentsResponse?.invalid_trips?.length) {
      const validationMessages: string[] = [];
      validateBulkAssignmentsResponse.invalid_trips.forEach((invalidTrip) => {
        const trip = trips?.results.find((x) => x.id === invalidTrip.trip_id);

        const explanations = invalidTrip.explanation?.join('\n ') ?? '';

        validationMessages.push(
          `${trip?.passenger.full_name} - ${explanations}`
        );
      });

      setValidationMessages(validationMessages);
    }
  }, [trips?.results, validateBulkAssignmentsResponse]);

  return {
    isAssignTripModalOpen,
    validationMessages,
    onConfirm,
  };
}
