import { useCallback, useRef } from 'react';

import { usePaymentMethodCaptureContext } from './PaymentMethodCaptureContext';
import {
  SavePaymentMethodCallback,
  SavePaymentMethodProps,
  TilledACHProps,
  TilledCardProps,
  TilledCombinedFieldsType,
  TilledFieldChangeEvent,
  TilledFieldInstances,
  TilledFieldOptions,
  TilledFormInstance,
  TilledPaymentType,
} from './types';

type CreateTilledFormCallback = (
  config: TilledCardProps | TilledACHProps,
  fieldOptions?: TilledFieldOptions
) => void;
type TeardownTilledCallback = () => void;
type ValidateFormCallback = () => Promise<boolean>;

export function useTilled(type: TilledPaymentType): {
  createForm: CreateTilledFormCallback;
  teardownForm: TeardownTilledCallback;
  savePaymentMethod: SavePaymentMethodCallback;
  validateForm: ValidateFormCallback;
} {
  const { tilledInstance, setFieldState, resetFieldState } =
    usePaymentMethodCaptureContext();
  const formInstance = useRef<TilledFormInstance<typeof type>>();

  const createForm = useCallback(
    async (
      config: TilledCardProps | TilledACHProps,
      fieldOptions?: TilledFieldOptions
    ) => {
      // Teardown to remove any old fields
      if (formInstance.current) {
        formInstance.current.teardown();
      }

      // Generate new form
      formInstance.current = await tilledInstance?.form({
        payment_method_type: type,
      });

      // loop through fields and inject them
      Object.entries(config.fields).forEach((entry) => {
        const [field, fieldEl] = entry;
        setFieldState(field, true);

        if (fieldEl?.childElementCount === 0) {
          const fieldInstance = formInstance.current
            ?.createField(field, fieldOptions ?? {})
            .inject(fieldEl);

          // bubbles events to the container
          if (fieldInstance) {
            fieldInstance.addEventListener('focus', () => {
              fieldEl.dispatchEvent(new Event('focus'));
            });
            fieldInstance.addEventListener('blur', () => {
              fieldEl.dispatchEvent(new Event('blur'));
            });
            fieldInstance.addEventListener('change', (ev) => {
              const { empty } = ev as unknown as TilledFieldChangeEvent;
              setFieldState(field, empty);
            });
          }
        }
      }, {} as TilledFieldInstances<TilledCombinedFieldsType>);

      // Build the form
      formInstance.current?.build();
    },
    [tilledInstance, type, setFieldState]
  );

  const teardownForm = useCallback(() => {
    if (formInstance.current) {
      resetFieldState();
      formInstance.current.teardown(() => {
        formInstance.current = undefined;
      });
    }
  }, [resetFieldState]);

  const savePaymentMethod = useCallback(
    async (props: SavePaymentMethodProps) => {
      if (tilledInstance) {
        return tilledInstance.createPaymentMethod(props);
      }
    },
    [tilledInstance]
  );

  const validateForm = useCallback(async () => {
    const form = await tilledInstance?.form<typeof type>();
    return form?.valid ?? true;
  }, [tilledInstance]);

  return {
    createForm,
    teardownForm,
    savePaymentMethod,
    validateForm,
  };
}
