import { FormValues } from './FormValues';

export const defaultFormValues: FormValues = {
  id: '',
  nickname: null,
  make: '',
  model: '',
  year: null,
  color: '',
  license_plate: '',
  license_plate_state: '',
  vin: '',
  type: '',
  notes: '',
  location: '',
  can_be_scheduled: true,
  category: '',
  storage_location: null,
};
