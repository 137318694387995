import {
  OverrideStatusEnum,
  NewStatusEnum,
} from 'src/common/external/bambi-api/bambiApi';

export const tripStatuses: OverrideStatusEnum[] = [
  'pending',
  'assigned',
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
  'completed',
  'canceled',
  'rejected',
];

export const TRIP_STATUSES: { [x: string]: OverrideStatusEnum } = {
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  EN_ROUTE: 'en route',
  AT_PICKUP: 'at pickup',
  TRANSPORTING: 'transporting',
  AT_DROPOFF: 'at dropoff',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
} as const;

// These are statuses allowed for /trips/{id}/status and /trips/{id}/override-status
export const tripNewStatuses: NewStatusEnum[] = [
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
];

export const NEW_TRIP_STATUSES: { [x: string]: NewStatusEnum } = {
  EN_ROUTE: 'en route',
  AT_PICKUP: 'at pickup',
  TRANSPORTING: 'transporting',
  AT_DROPOFF: 'at dropoff',
} as const;
