import { createSlice } from '@reduxjs/toolkit';

import {
  PassengerRead,
  PayerOrganizationRead,
  TripRead,
  TripWrite,
} from 'src/common/external/bambi-api/bambiApi';

import { defaultFormValues } from './defaultFormValues';

export interface IAddATripState {
  isOpen: boolean;
  isSubmitting: boolean;
  selectedPassenger?: PassengerRead | null;
  selectedPayer?: PayerOrganizationRead | null;
  errors: [];
  selectedTrip: TripRead | undefined;
  isEditingPassenger: boolean;
  tripLegsPriceEstimationState: {
    isEstimated: boolean;
    isStale: boolean;
    isManuallyUpdated: boolean;
  }[];
}

export const initialState: IAddATripState = {
  isOpen: false,
  isSubmitting: false,
  errors: [],
  selectedTrip: undefined,
  isEditingPassenger: false,
  tripLegsPriceEstimationState: [],
};

export const addATrip = createSlice({
  name: 'addATrip',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
      state.errors = [];
    },
    close: (state) => {
      state.errors = [];
      state.isOpen = false;
      state.isSubmitting = false;
      state.selectedTrip = undefined;
    },
    cancel: (state) => {
      state.errors = [];
      state.isOpen = false;
      state.selectedTrip = undefined;
    },
    selectPassenger: (state, action) => {
      state.selectedPassenger = action.payload;
    },
    selectNoPassenger: (state) => {
      state.selectedPassenger = defaultFormValues.passenger;
    },
    selectPayer: (state, action) => {
      state.selectedPayer = action.payload;
    },
    onError: (state, action) => {
      state.errors = action.payload;
      state.isSubmitting = false;
    },
    submit: (state) => {
      state.errors = [];
      state.isSubmitting = true;
    },
    setSelectedTrip: (state, action) => {
      state.selectedPassenger = action.payload?.passenger;
      state.selectedTrip = action.payload;
    },
    setIsEditingPassenger: (state, action) => {
      state.isEditingPassenger = action.payload;
    },
    cloneTrip: (
      state,
      action: { type: string; payload: TripRead | TripWrite }
    ) => {
      const newTrip = {
        ...action.payload,
        id: '',
      };
      state.selectedPassenger = newTrip.passenger as PassengerRead;
      state.selectedTrip = newTrip as TripRead;
      state.isOpen = true;
    },
    onTripLegAdded: (state, action: { payload: number }) => {
      state.tripLegsPriceEstimationState[action.payload] = {
        isEstimated: false,
        isStale: false,
        isManuallyUpdated: false,
      };
    },
    onTripLegRemoved: (state, action: { payload: number }) => {
      state.tripLegsPriceEstimationState.splice(action.payload, 1);
    },
    onTripLegPriceEstimationAccepted: (state, action: { payload: number }) => {
      state.tripLegsPriceEstimationState[action.payload] = {
        isEstimated: true,
        isStale: false,
        isManuallyUpdated: false,
      };
    },
    onTripLegPriceEstimationStale: (state, action: { payload: number }) => {
      state.tripLegsPriceEstimationState[action.payload] = {
        isEstimated: false,
        isStale: true,
        isManuallyUpdated: false,
      };
    },
    onTripLegPriceEstimationManuallyUpdated: (
      state,
      action: { payload: number }
    ) => {
      state.tripLegsPriceEstimationState[action.payload] = {
        isEstimated: false,
        isStale: false,
        isManuallyUpdated: true,
      };
    },
  },
});

export const { open, close, setSelectedTrip } = addATrip.actions;
