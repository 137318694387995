import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { SettingsNav } from './SettingsNav';
import { SettingsRouteActions } from './SettingsRouteActions';

export function SettingsLayout() {
  return (
    <HeaderToolbarAndContentLayout
      actionSection={<SettingsRouteActions />}
      preContent={<SettingsNav />}
      content={<Outlet />}
    />
  );
}
