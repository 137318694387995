import { useEffect, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { RootState, useAppSelector } from 'src/app/store';
import { useOrganizationSettingsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { emptyHeaders } from 'src/common/external/bambi-api/bambiBaseQuery';

import { serviceDetailsNameRoot } from './ServiceDetailsFormBody';

export function useOrgSettingsDefaultValues(
  setValue: ReturnType<typeof useFormContext>['setValue']
) {
  const { selectedTrip } = useAppSelector((state: RootState) => state.addATrip);
  const { data, isLoading } =
    useOrganizationSettingsRetrieveQuery(emptyHeaders);

  const orgDefaultValues = useMemo(
    () => makeOrgSettingDefaultValues(selectedTrip !== undefined, data),
    [selectedTrip, data]
  );

  useEffect(() => {
    if (orgDefaultValues) {
      const keys = Object.keys(orgDefaultValues);
      keys.forEach((key) => {
        const value = orgDefaultValues[key];
        if (value) {
          setValue(key, value);
        }
      });
    }
  }, [orgDefaultValues, setValue]);

  return { isLoading };
}

type StringDict = { [key: string]: string };

export function makeOrgSettingDefaultValues(
  isEditing: boolean,
  settings: any
): StringDict | undefined {
  if (isEditing) {
    return undefined;
  } else {
    const values: StringDict = {};
    const spaceTypeSetting = settings?.default_trip_space_type;
    const serviceTypeSetting = settings?.default_trip_service_type;

    if (spaceTypeSetting) {
      values[`${serviceDetailsNameRoot}.space_type`] = spaceTypeSetting;
    }

    if (serviceTypeSetting) {
      values[`${serviceDetailsNameRoot}.service_type`] = serviceTypeSetting;
    }

    return values;
  }
}
