import * as Accordion from 'src/common/Accordion';
import { TripCreateRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryCreateItemHeader } from './TripSummaryCreateItemHeader';
import { TripSummaryCreateParsedTrip } from './TripSummaryCreateParsedTrip';

export function TripSummaryCreateItem({
  onClick,
  tripEvent,
  // Used in Header
  isSelected,
}: {
  onClick: () => void;
  tripEvent: TripCreateRead;
  isSelected: boolean;
}) {
  return (
    <Accordion.Item value={String(tripEvent.id)} onClick={onClick}>
      <div
        className={`relative rounded-lg border border-solid border-[#eaecf0] hover:cursor-pointer`}
      >
        <style>
          {`
            [data-radix-collection-item][data-state="open"] {
              background-color: F9FAFB;
              border-bottom: 2px solid #EAECF0;
            }
          `}
        </style>
        <Accordion.Trigger>
          <TripSummaryCreateItemHeader
            tripEvent={tripEvent}
            isSelected={isSelected}
          />
        </Accordion.Trigger>

        <Accordion.Content>
          <div className="p-2">
            {tripEvent.parsed_trip && (
              <TripSummaryCreateParsedTrip parsedTrip={tripEvent.parsed_trip} />
            )}
          </div>
        </Accordion.Content>
      </div>
    </Accordion.Item>
  );
}
