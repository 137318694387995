import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { PricingRouteActions } from './PricingRouteActions';

export function PricingLayout() {
  return (
    <HeaderToolbarAndContentLayout
      content={<Outlet />}
      actionSection={<PricingRouteActions />}
    />
  );
}
