import { SelectOption } from 'src/common/primitives/Select';

import { baseOptionComparator } from './baseOptionComparator';

export function baseQuery(
  query: string,
  options: SelectOption[]
): SelectOption[] {
  return options.filter((option) => baseOptionComparator(option, query));
}
