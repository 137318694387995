import { ToggleCalendarEventScrollableButton } from './ToggleCalendarEventScrollableButton';
import ToggleDispatchToolbarButton from './ToggleDispatchToolbarButton';

/* Hack to align the left side of the date to the right side of the time column */
export function DispatchCalendarTimeColumn() {
  return (
    <div className="mbsc-schedule-time-wrapper -mt-5 pt-0">
      <div className="flex">
        <ToggleDispatchToolbarButton />
        <ToggleCalendarEventScrollableButton />
      </div>
    </div>
  );
}
