import { ClockIcon } from '@radix-ui/react-icons';
import { DateTime } from 'luxon';

export default function AssignmentPopupLastDeviceTimestamp({
  lastDeviceTimestamp,
}: {
  lastDeviceTimestamp: string;
}) {
  const lastUpdatedAtFormatted = DateTime.fromISO(
    lastDeviceTimestamp
  ).toLocaleString(DateTime.DATETIME_SHORT);
  return (
    <div className="flex flex-col items-start justify-center gap-[6px]">
      <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-warning-50 py-[3px] px-2">
        <div className="flex flex-row items-center justify-start">
          <div className="relative font-medium">Location Updated At</div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[8px] text-left text-sm text-gray-600">
        <ClockIcon />
        {lastUpdatedAtFormatted}
      </div>
    </div>
  );
}
