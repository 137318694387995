import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { BADGE_COLOR_VARIANTS, Badge } from 'src/common/primitives/Badge';
import { Popover } from 'src/common/primitives/Popover';
type TripImportedBadgeProps = {
  trip: TripRead;
};

export const TripImportedBadge: React.FC<TripImportedBadgeProps> = ({
  trip,
}) => {
  const {
    one_schema_template_id,
    one_schema_template_multi_id,
    external_trip_id,
    intermediary_id,
  } = trip;
  if (
    one_schema_template_id ||
    one_schema_template_multi_id ||
    intermediary_id
  ) {
    const source = trip.external_trip_id ? 'CSV' : 'Kinetik';

    return (
      <Popover
        trigger={
          <div className="cursor-pointer">
            <Badge label="Imported" color={BADGE_COLOR_VARIANTS.GREEN} />
          </div>
        }
        content={
          <div className="m-[-10px] text-sm">
            <div>
              <span className="font-bold">Source:</span> {source}
            </div>
            <div>
              <span className="font-bold">External Trip ID:</span>{' '}
              {external_trip_id || intermediary_id}
            </div>
          </div>
        }
      ></Popover>
    );
  }

  return null;
};
