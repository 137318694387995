import { PlusIcon } from '@radix-ui/react-icons';
import { useFormContext } from 'react-hook-form';

import { Button } from 'src/common/primitives/Button';

import { baseAssignment } from './defaultFormValues';

export function AddAssignmentButton({ day }: { day: string }) {
  const formContext = useFormContext();

  const handleAddAssignment = () => {
    const { assignments } = formContext.getValues();
    const dayAssignments = assignments[day].assignments;
    formContext.setValue(`assignments.${day}.assignments`, [
      ...dayAssignments,
      { ...baseAssignment },
    ]);
  };

  return (
    <Button
      className="flex-grow"
      onClick={handleAddAssignment}
      dataTestId="add-assignment-button"
    >
      <PlusIcon className="h-4 w-4" /> Add Assignment
    </Button>
  );
}
