import { useCallback, useEffect, useState } from 'react';

import {
  InvoiceRefundRead,
  useLazyInvoicesRefundsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import formatServerError from 'src/common/util/serverErrorFormatter';

export function useHydratePaymentRefunds(refunds?: string[]) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<InvoiceRefundRead[]>([]);
  const [fetchRefund] = useLazyInvoicesRefundsRetrieveQuery();

  const handleFetchRefunds = useCallback(
    async (refundsToHydrate: string[]) => {
      try {
        const hydratedRefunds = await Promise.all(
          refundsToHydrate.map((id) =>
            fetchRefund({
              id,
            }).unwrap()
          )
        );
        setData(hydratedRefunds);
      } catch (e) {
        setError(formatServerError(e));
      } finally {
        setIsLoading(false);
      }
    },
    [fetchRefund]
  );

  useEffect(() => {
    if (!refunds || !refunds.length) {
      setIsLoading(false);
      setData([]);
      setError(null);
      return;
    }

    handleFetchRefunds(refunds);
  }, [handleFetchRefunds, refunds]);

  return {
    isLoading,
    data,
    error,
  };
}
