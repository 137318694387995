import { Outlet } from 'react-router-dom';

import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';
import { TripSubscriptionsRouteActions } from 'src/features/trip/trip-subscriptions/TripSubscriptionsRouteActions';
import { TripsNav } from 'src/features/trip/TripsNav';

export function TripSubscriptionsLayout() {
  return (
    <ToolbarAndContentLayout
      preContent={<TripsNav />}
      actionSection={<TripSubscriptionsRouteActions />}
      content={<Outlet />}
    />
  );
}
