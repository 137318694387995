import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import DateRangeBadge from 'src/common/date/DateRangeBadge';
import { Button } from 'src/common/primitives/Button';

import { DriverMembershipFilter } from './DriverMembershipFilter';
import { fleetInspectionSlice } from './fleetInspection.slice';

export function VehicleInspectionFilters() {
  const dispatch = useAppDispatch();
  const inspectionListFilter = useAppSelector(
    (state) => state.fleetInspection.inspectionListFilter
  );

  const isFiltered = useMemo(() => {
    return (
      !!Object.keys(inspectionListFilter).length &&
      !!Object.values(inspectionListFilter).some
    );
  }, [inspectionListFilter]);

  useEffect(() => {
    dispatch(fleetInspectionSlice.actions.initializeFilters());
  }, [dispatch]);

  return (
    <div className="flex flex-grow items-center gap-2">
      {isFiltered && (
        <Button
          onClick={() => dispatch(fleetInspectionSlice.actions.clearFilters())}
          variant="anchor"
          className="whitespace-nowrap"
        >
          clear filters
        </Button>
      )}
      {inspectionListFilter.rangeStart && inspectionListFilter.rangeEnd && (
        <DateRangeBadge
          from={inspectionListFilter.rangeStart}
          to={inspectionListFilter.rangeEnd}
          onClear={() =>
            dispatch(fleetInspectionSlice.actions.onClearDateFilter())
          }
        />
      )}
      <DriverMembershipFilter />
    </div>
  );
}
