import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  AssignmentRead,
  useAssignmentsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { onApplySelectedAssignments } from '../dispatcher.slice';

function unwrapPayersList(list: AssignmentRead[]): string[] {
  return list.map((p) => p.id);
}

function createIdentifiedList(list: string[]): AssignmentRead[] {
  return list.map((s) => ({
    id: s,
  })) as AssignmentRead[];
}

export default function DispatchCalendarAssignmentFilter() {
  const dispatch = useAppDispatch();
  const { selectedDate, selectedAssignments } = useAppSelector(
    (state) => state.dispatcher
  );

  const [searchTerm, setSearchTerm] = useState('');
  const bod = DateTime.fromISO(selectedDate).startOf('day').toISO();
  const eod = DateTime.fromISO(selectedDate).endOf('day').toISO();

  const { data: assignments, isLoading: assignmentsLoading } =
    useAssignmentsListQuery({
      rangeStart: bod,
      rangeEnd: eod,
      search: searchTerm,
    });

  const onApplyFilters = (selected: string[]) => {
    dispatch(onApplySelectedAssignments(selected));
  };

  useEffect(() => {
    dispatch(onApplySelectedAssignments([]));
  }, [dispatch, selectedDate]);

  return (
    <div className="flex items-center align-middle">
      <MultiSelectFilterPopover
        value={createIdentifiedList(selectedAssignments)}
        filters={assignments?.results ?? []}
        renderLabel={({ driver }) => `${driver.first_name} ${driver.last_name}`}
        onChange={(value) => {
          onApplyFilters(unwrapPayersList(value));
        }}
        onSearch={(term) => {
          setSearchTerm(term);
        }}
        searchPlaceholder="Search drivers"
        triggerElement={
          <FilterPopoverTrigger
            count={selectedAssignments.length}
            label={
              assignmentsLoading ? (
                <LoadingIndicator variant="small" />
              ) : (
                'Drivers'
              )
            }
            disabled={assignments?.results.length === 0}
          />
        }
      />
      {!!selectedAssignments.length && (
        <Button
          onClick={() => {
            dispatch(onApplySelectedAssignments([]));
          }}
          variant="anchor"
          dataTestId="clear-assignment-filters-button"
        >
          clear filters
        </Button>
      )}
    </div>
  );
}
