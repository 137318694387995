import { useFeature } from 'src/app/FeatureToggle';

export function useRideOrderMarketplaceRoutes(
  tabs: { name: string; href: string }[]
) {
  const {
    isEnabled: isRideOrderingMarketplaceEnabled,
    isLoading: isRideOrderingMarketplaceFlagLoading,
  } = useFeature('ride ordering marketplace');

  const isAllRequestsInTabs = tabs.some((tab) => tab.name === 'All Requests');

  if (
    isRideOrderingMarketplaceEnabled &&
    !isRideOrderingMarketplaceFlagLoading &&
    !isAllRequestsInTabs
  ) {
    tabs.push({ name: 'All Requests', href: '/trips/requests' });
  }
  return {
    tabs,
  };
}
