import * as RadixPopover from '@radix-ui/react-popover';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  AssignmentRead,
  useTripsBulkAssignCreateMutation,
  useTripsBulkValidateCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { show } from 'src/common/primitives/Toast/toast.slice';
import { createEventsFromTrips } from 'src/features/dispatch/DispatchCalendar/createEventsFromTrips';
import {
  onBulkAssignRequestedValidTrips,
  onBulkTripAssignmentInvalid,
  onPreviewTripAssignmentEnd,
  onPreviewTripAssignmentStart,
  setSelectedUnassignedTripIds,
} from 'src/features/dispatch/dispatcher.slice';
interface BulkAssignTripsPopoverContentProps {
  loading: boolean;
  assignments: AssignmentRead[];
}

export default function BulkAssignTripsPopoverContent({
  loading,
  assignments,
}: BulkAssignTripsPopoverContentProps) {
  const dispatch = useAppDispatch();
  const nextToastId = useAppSelector((state) => state.toast.nextId);
  const selectedUnassignedTripIds = useAppSelector(
    (state) => state.dispatcher.selectedUnassignedTripIds
  );
  const unassignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.unassigned_trips
  );
  const selectedUnassignedTrips = unassignedTrips?.filter((trip) =>
    selectedUnassignedTripIds.includes(trip.id)
  );

  const [bulkAssign] = useTripsBulkAssignCreateMutation();
  const [bulkValidate] = useTripsBulkValidateCreateMutation();

  const onBulkAssignTrips = async (assignmentId: string) => {
    try {
      const validationResults = await bulkValidate({
        bulkTripValidate: {
          trip_ids: selectedUnassignedTripIds,
          assignment_id: assignmentId,
        },
      }).unwrap();

      if (validationResults.invalid_trips?.length) {
        dispatch(
          onBulkTripAssignmentInvalid({
            assignmentId,
            tripIds: selectedUnassignedTripIds,
            validateBulkAssignmentsResponse: validationResults,
          })
        );
        return;
      }

      if (
        selectedUnassignedTrips?.some((trip) => trip.status === 'requested')
      ) {
        dispatch(onBulkAssignRequestedValidTrips({ assignmentId }));
        return;
      }

      await bulkAssign({
        bulkTripAssign: {
          trip_ids: selectedUnassignedTripIds,
          assignment_id: assignmentId,
        },
      }).unwrap();
      dispatch(setSelectedUnassignedTripIds([]));
    } catch (error) {
      dispatch(
        show({
          id: nextToastId,
          title: 'Error assigning trips',
          description: 'There was an error assigning trips. Please try again.',
          type: 'error',
        })
      );
    }
  };

  const onMouseEnter = (assignmentId: string) => {
    if (!selectedUnassignedTrips) return;

    const tempTrips = selectedUnassignedTrips.map((trip) => ({
      ...trip,
      assignment_id: assignmentId,
      id: `${trip.id}-preview`,
    }));

    const previewTrips = createEventsFromTrips(tempTrips);
    dispatch(onPreviewTripAssignmentStart(previewTrips));
  };

  const onMouseLeave = () => {
    dispatch(onPreviewTripAssignmentEnd());
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!assignments.length) {
    return <div className="text-sm">No Assignments Available</div>;
  }

  return (
    <ul className="my-[-15px] mx-[-30px]">
      <li className="mx-4 flex justify-between gap-x-4 rounded-sm py-1.5 px-4 text-xs ">
        Please Select A Driver
      </li>
      <hr className="mx-4 mb-1" />
      <div className="flex flex-col">
        {assignments.map((assignment) => (
          <RadixPopover.Close key={assignment.id}>
            <li
              data-testid={`assignment-${assignment.id}`}
              className="mx-4 flex cursor-pointer justify-between gap-x-4 rounded-sm py-1.5 px-4 text-xs hover:bg-gray-100"
              onClick={() => onBulkAssignTrips(assignment.id)}
              onMouseEnter={() => onMouseEnter(assignment.id)}
              onMouseLeave={onMouseLeave}
            >
              {assignment.driver.first_name} {assignment.driver.last_name}
            </li>
          </RadixPopover.Close>
        ))}
      </div>
    </ul>
  );
}
