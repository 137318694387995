import { sortBy } from 'lodash-es';

import { InvoicePaymentRead } from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';
import { Tag } from 'src/common/Tag/Tag';

import { TagColumnWrapper } from './TagColumnWrapper';
import { useChargeStatusToLabel } from './useStatusToLabel';

export function ChargeStatusCell({
  payments,
}: {
  payments: InvoicePaymentRead[];
}) {
  const sortedPayments = sortBy(payments, 'created_at').reverse();
  // Being explicit on this type because sortedPayments is potentially empty
  const lastPayment: InvoicePaymentRead | undefined = sortedPayments[0];
  const result = useChargeStatusToLabel(lastPayment?.charge_status);

  if (!lastPayment || !result) {
    return null;
  }

  const { color, label } = result;

  return (
    <SimpleTooltip
      content={
        lastPayment.charge_status === 'failed'
          ? lastPayment.failure_message
          : null
      }
      trigger={
        <TagColumnWrapper testId="last-charge-status">
          <Tag color={color} label={label} />
        </TagColumnWrapper>
      }
    />
  );
}
