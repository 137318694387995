import { DateTime } from 'luxon';

import { useAppSelector } from 'src/app/store';

import { useIsEditingTrip } from './useIsEditingTrip';

export function useIsSelectedTripInThePast() {
  const isEditing = useIsEditingTrip();
  const selectedTrip = useAppSelector((state) => state.addATrip.selectedTrip);

  // We don't allow creating trips in the past
  if (!isEditing) return false;

  // If we're editing and somehow don't have a selected trip
  // we've got some problems so we'll limit functionality
  // selectedTrip's existence is checked in useIsEditingTrip
  // so this is mostly for TS
  if (typeof selectedTrip?.scheduled_pickup_at !== 'string') return true;

  const tripDate = DateTime.fromISO(selectedTrip.scheduled_pickup_at);

  // Shouldn't be getting non-ISO from the server
  if (!tripDate.isValid) return true;

  // TODO: Making a big assumption that the current user is in the same timezone as the org hq
  // I don't think this is really a problem for data that the user creates when not in the same timezone
  // Although Max has mentioned some weirdness with the recurring assignments path
  // However, we should test someone creating data in while in the same timezone as the org hq
  // and then ensure that that still shows up as the intended time fort a user not in the same timezone as the hq
  // https://www.thisdot.co/blog/how-to-handle-time-zones-using-datetime-and-luxon may be handy
  return tripDate < DateTime.local().startOf('day');
}
