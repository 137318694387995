import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { getChangedValueKeysForUpdate } from '../getChangedValueKeysForUpdate';
import { TripSummaryUpdateValue } from './TripSummaryUpdateValue';
import { TripSummaryUpdateValueLabel } from './TripSummaryUpdateValueLabel';

export function TripSummaryUpdateItemContent({
  tripEvent,
}: {
  tripEvent: TripUpdateRead;
}) {
  const valueKeysChanged = getChangedValueKeysForUpdate(tripEvent);

  if (valueKeysChanged.length < 1) return null;
  return (
    <div className="flex flex-col gap-y-3 p-3">
      {valueKeysChanged.map((valueKey) => (
        <div key={valueKey}>
          <TripSummaryUpdateValueLabel valueKey={valueKey} />
          <TripSummaryUpdateValue tripEvent={tripEvent} valueKey={valueKey} />
        </div>
      ))}
    </div>
  );
}
