import {
  DispatchTrip,
  RunBambiRunReassignedTrip,
  RunBambiRunTrip,
} from 'src/common/external/bambi-api/bambiApi';

export const isDispatchTrip = (
  trip: DispatchTrip | RunBambiRunTrip | RunBambiRunReassignedTrip
): trip is DispatchTrip => {
  return 'timing' in trip;
};
