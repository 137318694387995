import {
  ChevronDownIcon,
  CloudArrowDownIcon,
} from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { Popover } from 'src/common/primitives/Popover';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { useUserRoles } from '../auth/useUserRoles';
import {
  setAssignmentSearchTerm,
  setSelectedVehicle,
  setShowAssignmentExportModal,
  setShowAssignmentManagementModal,
  setShowMultiAssignmentManagementModal,
} from './assignmentManagement.slice';

export function AssignmentManagementRouteActions() {
  const { isOwner, isBambi, isAdmin, isRestrictedSupport } = useUserRoles();
  const dispatch = useAppDispatch();

  const assignmentSearchTerm = useAppSelector(
    (state) => state.assignmentManagement.assignmentSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setAssignmentSearchTerm,
    assignmentSearchTerm
  );

  const handleAddAssignment = () => {
    dispatch(setSelectedVehicle(undefined));
    dispatch(setShowAssignmentManagementModal(true));
  };

  const handleExportAssignmentClick = () => {
    dispatch(setShowAssignmentExportModal(true));
  };

  return (
    <>
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search assignments"
        value={inputValue}
      />
      {(isOwner || isBambi || isAdmin) && (
        <Button onClick={handleExportAssignmentClick}>
          <CloudArrowDownIcon className="mr-2 h-5 w-5" /> Download
        </Button>
      )}
      {!isRestrictedSupport && (
        <Popover
          trigger={
            <Button
              variant="primary"
              type="button"
              className="flex justify-between gap-2"
              dataTestId="add-assignments-button"
            >
              Add Assignments
              <ChevronDownIcon className="h-4 w-4" />
            </Button>
          }
          content={
            <div className="flex flex-col gap-1">
              <Button
                onClick={() => handleAddAssignment()}
                variant="secondary"
                dataTestId="add-current-week-assignments"
              >
                Current Week Assignments
              </Button>
              <Button
                onClick={() => {
                  dispatch(setSelectedVehicle(undefined));
                  dispatch(setShowMultiAssignmentManagementModal(true));
                }}
                variant="secondary"
                dataTestId="add-multi-assignments"
              >
                Recurring Assignments
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}
