import { DateTime } from 'luxon';

import { isDateTimeSunday } from 'src/common/date/isDateTimeSunday';

export function getFirstDayOfWeek(assignmentDate: DateTime): DateTime {
  // luxon follows ISO 8601, which defines Monday as the first day of the week
  if (isDateTimeSunday(assignmentDate)) {
    return assignmentDate;
  }

  return assignmentDate.startOf('week').minus({ days: 1 });
}
