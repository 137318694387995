import { formatPhoneNumber as _formatPhoneNumber } from 'react-phone-number-input';

// TODO: We should handle non-E.164 numbers more gracefully... I think?
export function formatPhoneNumber(phone: string) {
  try {
    return _formatPhoneNumber(phone);
  } catch (e) {
    console.warn(e);
  }
  return phone;
}
