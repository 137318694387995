import { ReactNode } from 'react';

export function ListCell({
  values,
  renderComponent,
}: {
  values: string[];
  renderComponent: (value: string) => ReactNode;
}) {
  return (
    <div className="flex flex-wrap gap-x-1 gap-y-2">
      {values.map(renderComponent)}
    </div>
  );
}
