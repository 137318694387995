import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from 'src/app/store';
import { useDispatchRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';

import { useUserRoles } from '../../auth/useUserRoles';
import { makeDispatchTimeRange } from './makeDispatchTimeRange';

export const formatDateTime = (time: string, selectedDate: string) => {
  const timeSplit = time.split(':');
  const hour = parseInt(timeSplit[0]);
  const minute = parseInt(timeSplit[1]);
  return DateTime.fromISO(selectedDate).set({ hour, minute }).toUTC().toISO();
};

type DispatchRepositoryOptions = {
  refetchOnMountOrArgChange?: boolean;
  skip?: boolean;
  pollingInterval?: number;
};

export function useDispatchRepository(options: DispatchRepositoryOptions = {}) {
  const { selectedDate } = useParams();
  const today = DateTime.now().toISODate();

  const { unassignedTripsTimeRange } = useSelector(
    (state: RootState) => state.dispatcher
  );

  const { isAssociate, isRideOrderingPortalUser } = useUserRoles();

  if (isAssociate || isRideOrderingPortalUser || !selectedDate) {
    options = {
      ...options,
      skip: true,
    };
  }

  const dispatchTimeRange = makeDispatchTimeRange(selectedDate || today);
  return useDispatchRetrieveQuery(
    {
      rangeStart: dispatchTimeRange.start,
      rangeEnd: dispatchTimeRange.end,
      unassignedRangeStart: unassignedTripsTimeRange.startTime
        ? formatDateTime(
            unassignedTripsTimeRange.startTime,
            selectedDate || today
          )
        : undefined,
      unassignedRangeEnd: unassignedTripsTimeRange.endTime
        ? formatDateTime(
            unassignedTripsTimeRange.endTime,
            selectedDate || today
          )
        : undefined,
    },
    options
  );
}
