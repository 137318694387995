import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { RootState } from 'src/app/store';
import { authService } from 'src/features/auth/authService';
import { BAMBI_API_BASE_URL } from './constants';

const baseQuery = fetchBaseQuery({
  baseUrl: BAMBI_API_BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    const state: RootState = getState() as RootState;
    // TODO: See https://github.com/reduxjs/redux-toolkit/issues/3006
    // and https://github.com/reduxjs/redux-toolkit/issues/2654
    // we can set this header here, but unfortunately the code gen generates
    // 'Organization-ID' as a required param regardless.
    // post-process.js does some shenanigans to make it optional in a super fragile way.

    // I wonder if we could change our spec somehow so that org id is
    // treated like the Authorization header (Not a required param in the generated code)
    // The other path is opening a pr for the code gen to support this
    const currentOrganizationId = state?.auth?.currentOrganizationId;
    if (currentOrganizationId) {
      headers.set('Organization-ID', currentOrganizationId);
    }

    const token = await authService.getAccessToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const bambiBaseQuery: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // Used to validate that 'Organization-ID' is set in the headers here but
  // it seems like prepareHeaders is called after this block is executed (probably within baseQuery)

  // This made us have to set 'Organization-ID' everywhere even though we modify the types to make it optional
  // and we set it in prepareHeaders.

  // Something to keep in mind if we need to validate headers are set in the future.
  return baseQuery(args, api, extraOptions);
};

/**
 * This is a workaround for the code gen not supporting optional headers
 */
export const emptyHeaders = { 'Organization-ID': '-' };
