import { useAppDispatch } from 'src/app/store';
import { formatFullName } from 'src/common/util/formatFullName';

import { liveMapSlice } from '../../liveMap.slice';
import { AssignmentLocationCardProps } from './AssignmentLocationCard';

export function useAssignmentLocationCardViewModel({
  assignmentLocation,
}: AssignmentLocationCardProps) {
  const dispatch = useAppDispatch();
  return {
    onCardClick: () => {
      dispatch(
        liveMapSlice.actions.selectAssignmentLocation(assignmentLocation.id)
      );
    },
    formattedDriverName: formatFullName(assignmentLocation.driver),
  };
}
