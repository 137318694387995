import { map } from 'lodash-es';
import { DateTime } from 'luxon';

import { AssignmentRead } from 'src/common/external/bambi-api/bambiApi';

import { convertAttendantsToAssignedAttendants } from './convertAttendantToAssignedAttendant';
import { convertDriverToAssignedDriver } from './convertDriverToAssignedDriver';
import { convertVehicleToAssignedVehicle } from './convertVehicleToAssignedVehicle';
import { FormValues } from './FormValues';

export const convertFormValuesToAssignment = (data: FormValues) => {
  const arrAssignments: AssignmentRead[] = [];
  map(data.assignments, (day) => {
    map(day.assignments, (assignment) => {
      if (day.isEditing && assignment.driver && data.vehicle) {
        const startDateTime = DateTime.fromISO(assignment.start).set({
          month: day.dateTime.month,
          day: day.dateTime.day,
          year: day.dateTime.year,
        });

        const endDateTime = DateTime.fromISO(assignment.end).set({
          month: day.dateTime.month,
          day: day.dateTime.day,
          year: day.dateTime.year,
        });

        arrAssignments.push({
          start: startDateTime.toUTC().toISO(),
          end: endDateTime.toUTC().toISO(),
          driver: convertDriverToAssignedDriver(assignment.driver),
          driver_call_requests: [],
          attendants: convertAttendantsToAssignedAttendants(
            assignment.attendants
          ),
          vehicle: convertVehicleToAssignedVehicle(data.vehicle),
          // TODO: AssignmentRead is not the right type here
          // Need to refactor to use Assignment for create (POST) and PatchedAssignment for update (PATCH)
          // However, the following fields are ignored by both, so keeping this change small to
          // unblock the Here map link changes
          id: assignment.id,
          vehicle_inspections: [],
          breaks: [],
          trip_count: 0,
          will_call_count: 0,
        });
      }
    });
  });
  return arrAssignments;
};
