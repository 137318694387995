import { useEffect } from 'react';

import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { PaginatedPassengerAddressListRead } from 'src/common/external/bambi-api/bambiApi';

interface useSetPickupAddressProps {
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined;
  setValue: UseFormSetValue<FieldValues>;
}

export default function useSetPickupAddress({
  recentAddressResponse,
  setValue,
}: useSetPickupAddressProps) {
  useEffect(() => {
    if (recentAddressResponse?.results.length) {
      const primaryAddress = recentAddressResponse.results.find(
        (result) => result.is_primary_address
      );

      if (primaryAddress) {
        setValue('pickup.address', {
          label: primaryAddress.address.location?.label,
          value: primaryAddress.address.location?.place_id,
        });
        setValue('pickup.address_details', primaryAddress.address_details);
        setValue('pickup.driver_notes', primaryAddress.address_notes);
      }
    }
  }, [recentAddressResponse, setValue]);
}
