import { FormError } from './FormError';
import { HelperTextPopover } from './FormFieldDefault';
import { FormLabel } from './FormLabel';

export function DefaultFormFieldLayout({
  inputProps,
  label,
  error,
  inline,
  children,
  className,
  helperText,
  helperTextPosition,
}: {
  inputProps: React.InputHTMLAttributes<HTMLInputElement> | undefined;
  label?: string | React.ReactNode;
  error?: string;
  inline?: boolean;
  children: React.ReactNode;
  className?: string;
  helperText?: string;
  helperTextPosition?: string;
}) {
  return (
    <div
      className={`${
        inline ? 'grid grid-cols-6 items-center gap-2' : 'flex flex-col'
      }${className ? ' ' + className : ''}`}
    >
      {inline ? (
        <div className="col-span-2">
          <FormLabel htmlFor={inputProps?.id}>{label}</FormLabel>
        </div>
      ) : (
        <FormLabel htmlFor={inputProps?.id}>{label}</FormLabel>
      )}
      <div className={inline ? 'col-span-4' : undefined}>
        <div className="mt-1">
          <div className="flex items-center justify-between gap-2">
            <div className="flex-grow">{children}</div>
            {helperText && (
              <HelperTextPopover
                text={helperText}
                isStatic
                position={helperTextPosition}
              />
            )}
          </div>
        </div>
        {error && <FormError error={error} />}
      </div>
    </div>
  );
}
