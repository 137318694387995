import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';

export function IsRoundTripContextField({ fieldPath }: { fieldPath: string }) {
  return (
    <FormContextField name={`${fieldPath}.is_round_trip`}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Make round trip"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: `${fieldPath}.is_round_trip`,
              ...field,
              onChange: field.onChange,
            }}
            type="switch"
          />
        );
      }}
    </FormContextField>
  );
}
