import { PencilSquareIcon } from '@heroicons/react/24/outline';

import { SimpleTooltip } from 'src/common/primitives/Tooltip';

export interface TripDriverProps {
  driver_name?: string;
  driver_entered_notes?: string;
}

export const TripDriver = ({
  driver_name,
  driver_entered_notes,
}: TripDriverProps) => {
  if (driver_name) {
    return (
      <div className="flex flex-row items-center justify-between">
        {driver_name}
        {driver_entered_notes && (
          <SimpleTooltip
            trigger={<PencilSquareIcon className="ml-2 w-5" color="gray" />}
            content={driver_entered_notes}
          />
        )}
      </div>
    );
  }
  return null;
};
