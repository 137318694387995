import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { DropdownMenuSeparator } from 'src/common/DropdownMenu/DropdownMenuSeparator';
import { DropdownSubmenu } from 'src/common/DropdownMenu/DropdownSubmenu';
import {
  AssignmentRead,
  DispatchTripRead,
  useAssignmentsListQuery,
} from 'src/common/external/bambi-api/bambiApi';

import { DriverAssignmentMenuAction } from './DriverAssignmentMenuAction';

export function DriverAssignmentMenu({ trip }: { trip: DispatchTripRead }) {
  const { data: assignments, isLoading: assignmentsLoading } =
    useAssignmentsListQuery({
      rangeEnd: trip.scheduled_dropoff_at,
      rangeStart: trip.scheduled_pickup_at,
    });

  const assignmentsWithoutCurrentAssignment =
    assignments?.results.filter(
      (assignment: AssignmentRead) => assignment.id !== trip.assignment_id
    ) || [];

  const isAssigned = !!trip.assignment_id;
  return (
    <DropdownSubmenu triggerText={isAssigned ? 'Reassign' : 'Assign'}>
      <DropdownMenuItem>Please select a driver</DropdownMenuItem>
      <DropdownMenuSeparator />
      {assignmentsLoading && (
        <DropdownMenuItem>Loading Available Drivers...</DropdownMenuItem>
      )}
      {!assignmentsLoading &&
        assignmentsWithoutCurrentAssignment &&
        assignmentsWithoutCurrentAssignment.length === 0 && (
          <div className="max-w-[325px] ">
            <DropdownMenuItem>
              There are no {isAssigned ? 'other ' : ''}available drivers for
              this trip.
            </DropdownMenuItem>
          </div>
        )}
      {assignmentsWithoutCurrentAssignment &&
        assignmentsWithoutCurrentAssignment.map(
          (assignment: AssignmentRead) => (
            <DriverAssignmentMenuAction
              assignment={assignment}
              trip={trip}
              key={assignment.id}
            />
          )
        )}
    </DropdownSubmenu>
  );
}
