import * as RadixPopover from '@radix-ui/react-popover';

import { classNames } from 'src/common/classNames';
import { Popover } from 'src/common/primitives/Popover';

export interface FilterPopoverProps {
  children: React.ReactNode;
  includePrimaryAction?: boolean;
  open?: boolean;
  triggerElement: React.ReactNode;
  preFooterElement?: React.ReactNode;
  postFooterElement?: React.ReactNode;
  onApply?: () => void;
  onCancel?: () => void;
  onOpenChange?: (open: boolean) => void;
}
export function FilterPopover({
  children,
  includePrimaryAction = true,
  open,
  triggerElement,
  preFooterElement,
  postFooterElement,
  onApply,
  onCancel,
  onOpenChange,
}: FilterPopoverProps) {
  return (
    <Popover
      open={open}
      onOpenChange={onOpenChange}
      trigger={triggerElement}
      content={
        <div className="flex max-h-80 w-72 flex-col justify-between px-1">
          <div
            className="h-fit overflow-y-auto"
            data-testid="filter-popover-content"
          >
            {children}
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-2 border-t border-gray-200 pt-4">
            {preFooterElement}
            <RadixPopover.Close
              className={classNames(
                'focus:ring-indigo-500 inline-flex flex-grow items-center justify-center rounded border border-gray-100 bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50',
                !includePrimaryAction && 'col-span-2'
              )}
              onClick={onCancel}
            >
              Cancel
            </RadixPopover.Close>
            {includePrimaryAction ? (
              <RadixPopover.Close
                className="focus:ring-indigo-500 inline-flex flex-grow items-center justify-center rounded border border-mint bg-mint px-3 py-2 text-sm font-medium text-white hover:bg-mint focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50"
                onClick={onApply}
              >
                Apply
              </RadixPopover.Close>
            ) : null}
            {postFooterElement}
          </div>
        </div>
      }
    />
  );
}
