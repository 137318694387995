import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

import { onPriceSummaryModalClose } from '../pricing.slice';
import { PriceSummaryModalContent } from './PriceSummaryModalContent';

export function PriceSummaryModal() {
  const dispatch = useAppDispatch();
  const { selectedTripId, tripPriceSummaryModalOpen } = useAppSelector(
    (state) => state.pricing
  );

  return (
    <Modal
      open={tripPriceSummaryModalOpen}
      setOpen={() => dispatch(onPriceSummaryModalClose())}
      contentClassnames="w-full max-w-[60vw]"
      data-testid="modal"
    >
      <div>
        <PriceSummaryModalContent selectedTripId={selectedTripId} />
        <div>
          <div className="flex justify-end gap-4 pt-4">
            <Button
              className="btn btn-secondary"
              onClick={() => dispatch(onPriceSummaryModalClose())}
              dataTestId="price-summary-modal-close-button"
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
