import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';
import { VehicleInspectionsRoute } from 'src/features/fleet/inspection';

import { VehicleManagementRoute } from './management/VehicleManagementRoute';

export const fleetRoutes: RouteObject[] = [
  {
    index: true,
    element: <VehicleManagementRoute />,
  },
  {
    path: 'inspections',
    element: <VehicleInspectionsRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/fleet" linkText="Go back to fleet" />
    ),
  },
];
