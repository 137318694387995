import { FormProvider } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import { DeleteAction } from 'src/common/DeleteAction';
import { Modal } from 'src/common/primitives/Modal';
import { WizardHeader } from 'src/common/wizard/WizardHeader';
import {
  setShowAttendantProfileModal,
  setShowAttendantDeleteModal,
} from 'src/features/team/team.slice';

import { AttendantProfileFormStep1 } from './AttendantProfileFormStep1/AttendantProfileFormStep1';
import { AttendantProfileFormStep2 } from './AttendantProfileFormStep2/AttendantProfileFormStep2';
import { DeleteAttendantModal } from './DeleteAttendantModal';
import { useAttendantProfileFormViewModel } from './useAttendantProfileFormViewModel';

export function AttendantProfileForm() {
  const dispatch = useAppDispatch();
  const {
    open,
    setStep,
    setServerErrors,
    step,
    methods,
    title,
    selectedAttendantProfile,
    onSubmitStepTwo,
    isSubmitting,
    serverErrors,
  } = useAttendantProfileFormViewModel();

  return (
    <>
      <Modal
        open={open}
        setOpen={(val) => dispatch(setShowAttendantProfileModal(val))}
        onClose={() => {
          setStep(1);
          setServerErrors(null);
        }}
        contentClassnames="sm:min-w-[26rem]"
      >
        <>
          <WizardHeader
            title={title}
            wizardState={{ step, totalSteps: 2 }}
            setStep={setStep}
          />
          <FormProvider {...methods}>
            <form>
              {step === 1 ? (
                <AttendantProfileFormStep1
                  onNextClick={() => setStep(2)}
                  onCancel={() => dispatch(setShowAttendantProfileModal(false))}
                >
                  {selectedAttendantProfile ? (
                    <DeleteAction
                      onDelete={() => {
                        dispatch(setShowAttendantDeleteModal(true));
                        dispatch(setShowAttendantProfileModal(false));
                      }}
                      label="Delete this attendant"
                    />
                  ) : null}
                </AttendantProfileFormStep1>
              ) : null}
              {step === 2 ? (
                <AttendantProfileFormStep2
                  onSubmit={onSubmitStepTwo}
                  onCancel={() => {
                    dispatch(setShowAttendantProfileModal(false));
                    setStep(1);
                  }}
                  serverErrors={serverErrors}
                  isSubmitting={isSubmitting}
                />
              ) : null}
            </form>
          </FormProvider>
        </>
      </Modal>
      <DeleteAttendantModal />
    </>
  );
}
