import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  TripsBulkCreateWrite,
  usePassengersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { useIsRideOrderingPortalEnabledForOrganization } from 'src/features/rideOrderingPortal/useIsRideOrderingPortalEnabledForOrganization';

import { addATrip } from '../addATrip.slice';
import { FormValues } from '../FormValues';
import { adaptFormValuesToTripsBulkCreate } from './adaptFormValuesToTripsBulkCreate';
import { getTripLinkToPayer } from './getTripLinkToPayer';
import { useTripRepository } from './useTripRepository';
import { validateFormInputs } from './validateFormInputs';

export function useAddATripOnSubmit({
  getValues,
}: {
  getValues: () => FormValues;
}) {
  const dispatch = useDispatch();

  const { refetch: refetchPassengers } = usePassengersListQuery({});
  const { createTrip } = useTripRepository();
  const isRideOrderingPortalEnabledForOrganization =
    useIsRideOrderingPortalEnabledForOrganization();
  const { isRideOrderingPortalUser } = useUserRoles();
  const nextToastId = useSelector((state: RootState) => state.toast.nextId);

  // TODO: I can't remember why I didn't use values instead of getValues() here
  // I vaguely remember not getting the most up to date values which doesn't
  // make much sense. We should investigate and see if we can use values or useFormValues
  // to not need to pass in getValues
  return async (values: any) => {
    try {
      dispatch(addATrip.actions.submit());
      const currentValues = getValues();

      const formErrors = validateFormInputs(currentValues);

      if (formErrors.length > 0) {
        dispatch(addATrip.actions.onError(formErrors));
        return;
      }

      const adaptedTripRequest: TripsBulkCreateWrite =
        adaptFormValuesToTripsBulkCreate(currentValues);

      if (isRideOrderingPortalEnabledForOrganization) {
        adaptedTripRequest.trips = adaptedTripRequest.trips.map((trip) => {
          return {
            ...trip,
            link_to_payer: getTripLinkToPayer(
              !!isRideOrderingPortalUser,
              currentValues.should_link_passenger_to_payer
            ),
          };
        });
      }
      const tripResponse: any = await createTrip(adaptedTripRequest);

      if (!tripResponse) {
        if (tripResponse.error.data) {
          dispatch(addATrip.actions.onError(tripResponse.error.data.errors));
          dispatch(
            show({
              id: nextToastId,
              title: 'Error creating trip',
              description: ' Please review trip details',
              type: 'error',
            })
          );
          return;
        } else {
          dispatch(addATrip.actions.onError([]));
          dispatch(
            show({
              id: nextToastId,
              title: 'Unexpected server error',
              description: `Status: ${tripResponse.error.originalStatus}, Error: ${tripResponse.error.error} -- Please contact support`,
              type: 'error',
            })
          );
          return;
        }
      }
      dispatch(addATrip.actions.close());
      dispatch(
        show({
          id: nextToastId,
          title: 'Trip created successfully!',
          type: 'success',
        })
      );
      refetchPassengers();
    } catch (e) {
      // TODO: Send error details to datadog
      const error = formatServerError(e);
      dispatch(
        addATrip.actions.onError([
          {
            attr: 'Form Error',
            code: 'general',
            detail: error,
          },
        ])
      );
    }
  };
}
