import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';

import { isDispatchTrip } from './TripCalendarEvent/isDispatchTrip';

export function getCalendarEventStartAndEndTime(
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead,
  isDispatchArrivalCompletionTimeEnabled: boolean
) {
  const isDispatchTripRead = isDispatchTrip(trip);
  if (isDispatchTripRead) {
    if (!isDispatchArrivalCompletionTimeEnabled) {
      return {
        start: trip.scheduled_pickup_at,
        end: trip.scheduled_dropoff_at,
      };
    }

    return { start: trip.arrival_time, end: trip.completion_time };
  }

  // Type for new_scheduled_pickup_at and new_scheduled_dropoff_at is string | null IRL
  // RunBambiRunReassignedTripRead is incorrect
  if ('new_scheduled_pickup_at' in trip && trip.new_scheduled_pickup_at) {
    return {
      start: trip.new_scheduled_pickup_at,
      end: trip.new_scheduled_dropoff_at,
    };
  }

  return { start: trip.scheduled_pickup_at, end: trip.scheduled_dropoff_at };
}
