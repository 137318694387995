import { Outlet } from 'react-router-dom';

import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';
import { TripsNav } from 'src/features/trip/TripsNav';

import { RomAllRequestsRouteActions } from './RomAllRequestsRouteActions';

export function RomAllRequestsLayout() {
  return (
    <ToolbarAndContentLayout
      preContent={<TripsNav />}
      actionSection={<RomAllRequestsRouteActions />}
      content={<Outlet />}
    />
  );
}
