import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon, DividerHorizontalIcon } from '@radix-ui/react-icons';
import { styled } from '@stitches/react';

export function IndeterminateCheckbox({
  indeterminate,
  checked,
  onChange,
  disabled,
}: {
  indeterminate?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <>
      <CheckboxRoot
        className="focus:ring-indigo-500 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50"
        checked={checked || indeterminate}
        onCheckedChange={onChange}
        disabled={disabled}
      >
        <Checkbox.Indicator>
          {checked === true ? (
            <CheckIcon data-testid="check-icon" />
          ) : indeterminate ? (
            <DividerHorizontalIcon data-testid="divider-horizontal-icon" />
          ) : null}
        </Checkbox.Indicator>
      </CheckboxRoot>
    </>
  );
}

const CheckboxRoot = styled(Checkbox.Root, {
  /* Primary/50 */
  background: '#F9F5FF',
  /* Primary/600 */
  border: '1px solid #7F56D9',
  borderRadius: '4px',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': { backgroundColor: '#F9F5FF' },
});
