import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { useVehiclesListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Select } from 'src/common/primitives/Select';

import { setSelectedVehicle } from '../../assignmentManagement.slice';

export function SchedulableVehicleSelect() {
  const dispatch = useAppDispatch();
  const { selectedAssignment } = useAppSelector(
    (state: RootState) => state.assignmentManagement
  );
  const { data: vehicles } = useVehiclesListQuery({
    canBeScheduled: true,
  });

  const vehicleOptions = vehicles?.results.map((vehicle) => ({
    label: vehicle.nickname || vehicle.id,
    value: vehicle.id,
  }));

  return (
    <FormContextField
      name="vehicle"
      rules={{ required: 'Please choose a vehicle' }}
    >
      {({ field, fieldState }) => {
        return (
          <DefaultFormFieldLayout
            label="Vehicle"
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: 'vehicle' }}
          >
            <Select
              options={vehicleOptions || []}
              onChange={(selected) => {
                const vehicle = vehicles?.results.find(
                  (vehicle) => vehicle.id === selected?.value
                );
                field.onChange(vehicle);
                dispatch(setSelectedVehicle(vehicle));
              }}
              value={field.value?.id}
              disabled={!!selectedAssignment}
              error={fieldState.error?.message?.toString()}
            />
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
