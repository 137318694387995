export function FormLabel({
  htmlFor,
  children,
}: {
  htmlFor: string | undefined;
  children: React.ReactNode;
}) {
  return (
    <>
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-gray-700"
      >
        {children}
      </label>
    </>
  );
}
