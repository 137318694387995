import { FormField, InputType } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';

interface SettingsFormFieldProps {
  label: string;
  fieldId: string;
  type: InputType;
  placeholder?: string;
}

export const SettingsFormField = ({
  label,
  fieldId,
  type,
  placeholder,
}: SettingsFormFieldProps) => {
  return (
    <FormContextField name={fieldId}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label={label}
            error={fieldState.error?.message?.toString()}
            type={type}
            inputProps={{
              id: fieldId,
              ...field,
              placeholder,
              'data-testid': fieldId,
            }}
            onChange={(e) => {
              field.onChange(e);
            }}
            value={field.value}
          />
        );
      }}
    </FormContextField>
  );
};
