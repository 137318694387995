import { useMemo, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

import { useAppSelector } from 'src/app/store';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { IndeterminateCheckbox } from 'src/common/primitives/IndeterminateCheckbox';

import { useSelectAllUnassignedTrips } from './useSelectAllUnassignedTrips';

type UnassignedTripsGroupSelectionSectionProps = {
  searchedUnassignedTrips: DispatchTripRead[];
};

export function UnassignedTripsGroupSelectionSection({
  searchedUnassignedTrips,
}: UnassignedTripsGroupSelectionSectionProps) {
  const { selectedUnassignedTripIds } = useAppSelector(
    (state) => state.dispatcher
  );
  const selectAllUnassignedTrips = useSelectAllUnassignedTrips();
  const [expandedFilters, setExpandedFilters] = useState(false);
  const willCallTrips = useMemo(() => {
    return searchedUnassignedTrips.filter((t) => t.is_non_activated_will_call);
  }, [searchedUnassignedTrips]);

  const requestedTrips = useMemo(() => {
    return searchedUnassignedTrips.filter((t) => t.status === 'requested');
  }, [searchedUnassignedTrips]);

  const otherTrips = useMemo(() => {
    return searchedUnassignedTrips.filter(
      (t) => !t.is_non_activated_will_call && t.status !== 'requested'
    );
  }, [searchedUnassignedTrips]);

  return (
    <div
      className={`flex items-start gap-2 text-left text-sm font-normal text-black ${
        expandedFilters ? 'border-b-2 pb-2' : ''
      }`}
      data-testid="unassigned-trip-group-selection"
    >
      <Button
        variant="ghost-icon"
        onClick={() => setExpandedFilters(!expandedFilters)}
        dataTestId="unassigned-trip-group-selection-toggle-button"
      >
        {expandedFilters ? (
          <ChevronUpIcon className="w-[24px]" />
        ) : (
          <ChevronDownIcon className="w-[24px]" />
        )}
      </Button>
      <div className="flex flex-col gap-2">
        <label className="flex items-center gap-x-2 text-left">
          <IndeterminateCheckbox
            onChange={(val) =>
              selectAllUnassignedTrips(val, searchedUnassignedTrips)
            }
            checked={
              selectedUnassignedTripIds.length ===
              searchedUnassignedTrips.length
            }
            indeterminate={
              selectedUnassignedTripIds.length !==
                searchedUnassignedTrips.length &&
              selectedUnassignedTripIds.length > 0
            }
          />
          <div className=" flex flex-row justify-between">
            <span data-testid="select-all-trips-label-text">Select All </span>
            <span className="text-slate-500">
              &nbsp;({searchedUnassignedTrips.length})
            </span>
          </div>
        </label>
        {expandedFilters ? (
          <>
            <label className=" flex items-center gap-x-2 text-left">
              <IndeterminateCheckbox
                onChange={(update) => {
                  selectAllUnassignedTrips(update, otherTrips);
                }}
                checked={otherTrips.every((t) =>
                  selectedUnassignedTripIds.find((id) => id === t.id)
                )}
                indeterminate={otherTrips.some((t) =>
                  selectedUnassignedTripIds.find((id) => id === t.id)
                )}
                disabled={!otherTrips.length}
              />
              <div className=" flex flex-row justify-between">
                <span>Scheduled Trips</span>
                <span className="text-slate-500">
                  &nbsp;({otherTrips.length})
                </span>
              </div>
            </label>
            <label className="flex items-center  gap-x-2 text-left">
              <IndeterminateCheckbox
                onChange={(update) => {
                  selectAllUnassignedTrips(update, willCallTrips);
                }}
                checked={willCallTrips.every((t) =>
                  selectedUnassignedTripIds.find((id) => id === t.id)
                )}
                indeterminate={willCallTrips.some((t) =>
                  selectedUnassignedTripIds.find((id) => id === t.id)
                )}
                disabled={!willCallTrips.length}
              />
              <div className="flex flex-row justify-between">
                <span>Will-Call Trips</span>
                <span className="text-slate-500">
                  &nbsp;({willCallTrips.length})
                </span>
              </div>
            </label>
            <label className="flex items-center  gap-x-2 text-left">
              <IndeterminateCheckbox
                onChange={(update) => {
                  selectAllUnassignedTrips(update, requestedTrips);
                }}
                checked={requestedTrips.every((t) =>
                  selectedUnassignedTripIds.find((id) => id === t.id)
                )}
                indeterminate={requestedTrips.some((t) =>
                  selectedUnassignedTripIds.find((id) => id === t.id)
                )}
                disabled={!requestedTrips.length}
              />
              <div className="flex flex-row justify-between">
                <span>Requested Trips</span>
                <span className="text-slate-500">
                  &nbsp;({requestedTrips.length})
                </span>
              </div>
            </label>
          </>
        ) : null}
      </div>
    </div>
  );
}
