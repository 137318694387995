import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { styled } from '@stitches/react';

export type RadioGroupOption<T extends string> = {
  label: string;
  value: T;
};

interface RadioGroupProps<T extends string> {
  label: string;
  defaultValue?: string;
  value?: T;
  onChange?: (value: T) => void;
  options: RadioGroupOption<T>[];
}

export function RadioGroup<T extends string>({
  options,
  label,
  value,
  defaultValue,
  onChange,
}: RadioGroupProps<T>) {
  return (
    <RadixRadioGroup.Root
      defaultValue={defaultValue}
      value={value}
      aria-label={label}
      onValueChange={onChange}
      className="mb-2 flex flex-col gap-2"
    >
      <label>{label}</label>
      {options.map((option) => (
        <div
          key={option.value}
          id={value}
          className="flex flex-row items-center gap-2"
        >
          <RadioGroupRootItem
            value={option.value}
            id={option.value}
            data-testid={option.value}
          >
            <RadioGroupIndicator />
          </RadioGroupRootItem>
          <label htmlFor={option.value} className="text-sm">
            {option.label}
          </label>
        </div>
      ))}
    </RadixRadioGroup.Root>
  );
}

const RadioGroupRootItem = styled(RadixRadioGroup.Item, {
  /* Primary/50 */
  background: '#F9F5FF',
  /* Primary/600 */
  border: '1px solid #7F56D9',
  borderRadius: '50%',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': { backgroundColor: '#F9F5FF' },
});

const RadioGroupIndicator = styled(RadixRadioGroup.Indicator, {
  /* Primary/600 */
  backgroundColor: '#7F56D9',
  borderRadius: '50%',
  width: 6,
  height: 6,
});
