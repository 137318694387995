import { useFeature } from 'src/app/FeatureToggle';
import { TabNavigation } from 'src/common/TabNavigation';

const tabs = [
  { name: 'Organization Settings', href: '/settings' },
  { name: 'Tags', href: '/settings/tags' },
  { name: 'Filter Groups', href: '/settings/filter-groups' },
];

const paymentsTab = {
  name: 'Payments',
  href: '/settings/payments',
};

export function SettingsNav() {
  const { isLoading, isEnabled } = useFeature('tilled payment capture');
  const mergedTabs = [...tabs];

  if (!isLoading && isEnabled) {
    mergedTabs.push(paymentsTab);
  }

  return <TabNavigation tabs={mergedTabs} />;
}
