import { RoleEnum } from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

export const rideOrderingPortalUserTypes: RoleEnum[] = [
  'facility admin',
  'facility user',
];

enum Roles {
  Owner = 'owner',
  Admin = 'admin',
  Dispatcher = 'dispatcher',
  Associate = 'associate',
  FacilityAdmin = 'facility admin',
  FacilityUser = 'facility user',
  Bambi = 'bambi',
  RestrictedSupport = 'restricted support',
}

export interface UserRoleInfo {
  role: RoleEnum | undefined;
  isOwner: boolean;
  isAdmin: boolean;
  isDispatcher: boolean;
  isAssociate: boolean;
  isFacilityAdmin: boolean;
  isFacilityUser: boolean;
  isBambi: boolean;
  isRideOrderingPortalUser: boolean | undefined;
  isRestrictedSupport: boolean;
}

export function useUserRoles(): UserRoleInfo {
  const { currentMembership } = useAuth();
  const role = currentMembership?.role;

  const isOwner = role === Roles.Owner;
  const isAdmin = role === Roles.Admin;
  const isDispatcher = role === Roles.Dispatcher;
  const isAssociate = role === Roles.Associate;
  const isFacilityAdmin = role === Roles.FacilityAdmin;
  const isFacilityUser = role === Roles.FacilityUser;
  const isBambi = role === Roles.Bambi;
  const isRideOrderingPortalUser =
    role && rideOrderingPortalUserTypes.includes(role);
  const isRestrictedSupport = role === Roles.RestrictedSupport;

  return {
    role,
    isOwner,
    isAdmin,
    isDispatcher,
    isAssociate,
    isFacilityAdmin,
    isFacilityUser,
    isBambi,
    isRideOrderingPortalUser,
    isRestrictedSupport,
  };
}
