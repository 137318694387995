import { DateTime } from 'luxon';

import { DateControls } from 'src/common/date/DateControls';
import { SelectedDateDisplay } from 'src/common/date/SelectedDateDisplay';
import { WeekDisplay } from 'src/common/date/WeekDisplay';

import { onNext } from './onNext';
import { onPrev } from './onPrev';
import { onToday } from './onToday';

type AssignmentManagementToolbarProps = {
  assignmentDate: DateTime;
  setSelectedDate: (date: DateTime) => void;
};

export function AssignmentManagementToolbar({
  assignmentDate,
  setSelectedDate,
}: AssignmentManagementToolbarProps) {
  return (
    <div className="flex">
      <div className="mr-[20px] flex w-[315px] items-center border-b-2 pb-2">
        <div>
          <DateControls
            current={assignmentDate}
            onPrev={onPrev(setSelectedDate, assignmentDate)}
            onNext={onNext(setSelectedDate, assignmentDate)}
            onToday={onToday(setSelectedDate)}
            onDatePicked={(newDate) => {
              setSelectedDate(newDate || assignmentDate);
            }}
            prevTooltip="Previous Week"
            nextTooltip="Next Week"
          />
        </div>

        <SelectedDateDisplay date={assignmentDate} />
      </div>
      <WeekDisplay selectedDay={assignmentDate} onDayClick={setSelectedDate} />
    </div>
  );
}
