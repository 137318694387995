import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { Passenger } from 'src/common/external/bambi-api/bambiApi';

import {
  selectNoPassenger,
  selectPassenger,
  setIsEditingPassenger,
} from '../trip-subscriptions.slice';

export function usePassengerSelection() {
  const dispatch = useDispatch();
  const { selectedPassenger, isEditingPassenger } = useAppSelector(
    (state) => state.tripSubscription
  );

  const onPassengerSelected = useCallback(
    (passenger: Passenger | undefined) => {
      dispatch(selectPassenger(passenger));
    },
    [dispatch]
  );

  const onPassengerAdd = useCallback(() => {
    dispatch(selectNoPassenger());
    dispatch(setIsEditingPassenger(true));
  }, [dispatch]);

  const onPassengerEdit = useCallback(
    (passenger: Passenger) => {
      dispatch(selectPassenger({ ...passenger }));
      dispatch(setIsEditingPassenger(true));
    },
    [dispatch]
  );

  const handlePassengerAddEdit = useCallback(
    (passenger: Passenger) => {
      dispatch(selectPassenger(passenger));
    },
    [dispatch]
  );

  return {
    selectedPassenger,
    handlePassengerAddEdit,
    isEditingPassenger,
    onPassengerSelected,
    onPassengerAdd,
    onPassengerEdit,
  };
}
