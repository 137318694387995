import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { dispatcherSlice } from '../../dispatcher.slice';

export function RejectRequestedTripsButton() {
  const dispatch = useAppDispatch();
  const selectedUnassignedTripIds = useAppSelector(
    (state) => state.dispatcher.selectedUnassignedTripIds
  );
  const dispatchResponse = useAppSelector(
    (state) => state.dispatcher.dispatchResponse
  );

  const selectedUnassignedRequestedTrips =
    dispatchResponse?.unassigned_trips.filter(
      (trip) =>
        trip.status === 'requested' &&
        selectedUnassignedTripIds.includes(trip.id)
    );
  return (
    <Button
      disabled={
        !selectedUnassignedRequestedTrips ||
        selectedUnassignedRequestedTrips.length === 0
      }
      variant="ghost-danger"
      data-testid="reject-selected-unassigned-trip-requests-button"
      onClick={() => {
        dispatch(
          dispatcherSlice.actions.onRejectUnassignedRequestedTrips({
            tripIdsToReject:
              selectedUnassignedRequestedTrips?.map((trip) => trip.id) || [],
          })
        );
      }}
    >
      <span className="text-xs">Reject Trips</span>
    </Button>
  );
}
