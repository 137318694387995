import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { PassengerManagementRoute } from './management/PassengerManagementRoute';

export const passengerRoutes: RouteObject[] = [
  {
    index: true,
    element: <PassengerManagementRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute
        routePath="/passengers"
        linkText="Go back to passengers"
      />
    ),
  },
];
