import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { PayerOrganizationRead } from 'src/common/external/bambi-api/bambiApi';
import { Popover } from 'src/common/primitives/Popover';

import PayerSettingDetails from './PayerSettingDetails';

export const PayerSettingsPopover = ({
  payer,
}: {
  payer: PayerOrganizationRead;
}) => (
  <Popover
    trigger={
      <div className="flex cursor-pointer items-center">
        <div>{payer.display_name}</div>
        <InformationCircleIcon className="ml-1 h-4 w-4" />
      </div>
    }
    content={<PayerSettingDetails payer={payer} />}
  />
);
