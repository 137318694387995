import { upperFirst } from 'lodash-es';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

import { fleetInspectionSlice } from '../fleetInspection.slice';
import { getHasFailValue } from './getHasFailValue';
import { getSelectedValue } from './getSelectedValue';
import { IdentifiedOutcome } from './IdentifiedOutcome';

const outcomeFilters = ['pass', 'fail'];
const identifiedOutcomes: IdentifiedOutcome[] = outcomeFilters.map((s) => ({
  id: s,
  value: s,
}));

interface OutcomeColumnFilterProps {
  open: boolean;
  onClose: () => void;
}
export function OutcomeColumnFilter({
  open,
  onClose,
}: OutcomeColumnFilterProps) {
  const dispatch = useAppDispatch();
  const inspectionListFilter = useAppSelector(
    (state) => state.fleetInspection.inspectionListFilter
  );
  const selected = getSelectedValue(inspectionListFilter.hasFail);
  return (
    <MultiSelectFilterPopover
      open={open}
      onCancel={onClose}
      filters={identifiedOutcomes}
      value={selected}
      onChange={(value) => {
        const hasFailValue = getHasFailValue(value);
        dispatch(
          fleetInspectionSlice.actions.onPassFailFilterChange(hasFailValue)
        );
        onClose();
      }}
      renderLabel={({ value }) => {
        return upperFirst(value);
      }}
      triggerElement={<div />}
    />
  );
}
