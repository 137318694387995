export function LocationPinIcon() {
  return (
    <svg
      className="relative shrink-0 overflow-visible"
      style={{}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_123_96650)">
        <path
          d="M3.33325 9.52416C2.09901 10.0686 1.33325 10.8274 1.33325 11.6666C1.33325 13.3234 4.31802 14.6666 7.99992 14.6666C11.6818 14.6666 14.6666 13.3234 14.6666 11.6666C14.6666 10.8274 13.9008 10.0686 12.6666 9.52416M11.9999 5.33325C11.9999 8.04239 8.99992 9.33325 7.99992 11.3333C6.99992 9.33325 3.99992 8.04239 3.99992 5.33325C3.99992 3.12411 5.79078 1.33325 7.99992 1.33325C10.2091 1.33325 11.9999 3.12411 11.9999 5.33325ZM8.66659 5.33325C8.66659 5.70144 8.36811 5.99992 7.99992 5.99992C7.63173 5.99992 7.33325 5.70144 7.33325 5.33325C7.33325 4.96506 7.63173 4.66659 7.99992 4.66659C8.36811 4.66659 8.66659 4.96506 8.66659 5.33325Z"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_96650">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
