import { useFormContext } from 'react-hook-form';

import { Button } from 'src/common/primitives/Button';

export function FormSubmitButton({
  className,
  isSubmitting,
  buttonName = 'Next',
}: {
  className?: string | string[];
  isSubmitting?: boolean;
  buttonName?: string;
}) {
  const { formState } = useFormContext();
  return (
    <Button
      className={`${className}`}
      type="submit"
      variant="primary"
      disabled={
        // TODO: Rename isSubmitting to disabled since that's how it's actually used
        isSubmitting ||
        formState.isSubmitting ||
        Object.keys(formState.errors).length > 0
      }
      dataTestId="form-submit-button"
    >
      {isSubmitting ? 'Saving' : buttonName}
    </Button>
  );
}
