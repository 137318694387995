import { useFeature } from 'src/app/FeatureToggle';
import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import {
  MultiSelectFilterPopover,
  MultiSelectFilterPopoverProps,
} from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

type IdentifiedOverrideStatus = {
  id: string;
  value: OverrideStatusEnum;
};

const rawStatuses: OverrideStatusEnum[] = [
  'pending',
  'assigned',
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
  'completed',
  'canceled',
  'rejected',
];

//TODO: need to add available and expired
// Not currently in the API
const romStatuses: OverrideStatusEnum[] = ['requested', 'rejected'];

function createIdentifiedStatusList(
  list: OverrideStatusEnum[]
): IdentifiedOverrideStatus[] {
  return list.map((s) => ({
    id: s,
    value: s,
  }));
}

function unwrapIdentifiedStatusList(
  list: IdentifiedOverrideStatus[]
): OverrideStatusEnum[] {
  return list.map((s) => s.value);
}

interface StatusFilterProps {
  open?: boolean;
  selected: OverrideStatusEnum[];
  triggerElement?: MultiSelectFilterPopoverProps['triggerElement'];
  onApply: (statuses: OverrideStatusEnum[]) => void;
  onClose?: () => void;
  isRom?: boolean;
}

export default function StatusFilter({
  open,
  selected,
  triggerElement,
  onApply,
  onClose,
  isRom = false,
}: StatusFilterProps) {
  const feature = useFeature('ride ordering marketplace');
  const statusesConsideringFeature = [...rawStatuses];
  if (feature.isEnabled) {
    statusesConsideringFeature.push('requested');
  }

  const identifiedStatuses = createIdentifiedStatusList(
    isRom ? romStatuses : statusesConsideringFeature
  );
  const identifiedStatusValues = createIdentifiedStatusList(selected);

  return (
    <MultiSelectFilterPopover
      open={open}
      filters={identifiedStatuses}
      value={identifiedStatusValues}
      onChange={(value) => {
        onApply(unwrapIdentifiedStatusList(value));
        onClose?.();
      }}
      onCancel={onClose}
      renderLabel={({ value }) => {
        return value;
      }}
      triggerElement={
        triggerElement ?? (
          <FilterPopoverTrigger count={selected.length} label="Status" />
        )
      }
    />
  );
}
