import { RouteObject } from 'react-router-dom';

import { DefaultErrorElement } from 'src/app/DefaultErrorElement';
import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';
import { tripSubscriptionsRoutes } from 'src/features/trip/trip-subscriptions/routes';
import { TripSubscriptionsLayout } from 'src/features/trip/trip-subscriptions/TripSubscriptionsLayout';

import { TripManagementRoute } from './management/TripManagementRoute';
import { RomAllRequestsLayout } from './rom/all-requests/RomAllRequestsLayout';
import { romAllRequestsRoutes } from './rom/routes';
import { TripSummaryRoute } from './summary/TripSummaryRoute';

export type TripSummaryRouteParams = {
  tripId: string;
};

export const tripRoutes: RouteObject[] = [
  {
    index: true,
    element: <TripManagementRoute />,
  },
  {
    path: ':tripId/summary',
    element: <TripSummaryRoute />,
  },
  {
    path: 'subscriptions/*',
    element: <TripSubscriptionsLayout />,
    children: tripSubscriptionsRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: 'requests/*',
    element: <RomAllRequestsLayout />,
    children: romAllRequestsRoutes,
    errorElement: <DefaultErrorElement />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/trips" linkText="Go back to trips" />
    ),
  },
];

export const rideOrderingPortalTripRoutes: RouteObject[] = [
  {
    index: true,
    element: <TripManagementRoute />,
  },
  {
    path: ':tripId/summary',
    element: <TripSummaryRoute />,
  },
  {
    path: '*',
    element: <NotFoundChildRoute />,
  },
];
