import { DEFAULT_COL_SPAN, SupportedGridSizes } from './FormFieldSet';

// TODO: Probably more of a generic GridItem component
export function FormFieldContainer({
  children,
  colSpan = DEFAULT_COL_SPAN,
}: {
  children: React.ReactNode;
  colSpan?: SupportedGridSizes;
}) {
  return (
    <div
      data-testid="container"
      className={`flex grow flex-col justify-start sm:col-span-${colSpan}`}
    >
      {children}
    </div>
  );
}
