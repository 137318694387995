import { LOCATION_PLACE_INDEX } from './constants';
import { LatLong } from './LatLong';

export const searchForLocationSuggestions = async (
  searchTerm: string,
  bias?: LatLong
) => {
  return import('aws-amplify').then(({ Geo }) => {
    return Geo.searchForSuggestions(searchTerm, {
      countries: ['USA'], // Alpha-3 country codes
      maxResults: 15,
      biasPosition: bias && [bias.long, bias.lat],
      searchIndexName: LOCATION_PLACE_INDEX,
    });
  });
};
