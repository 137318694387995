import * as Dialog from '@radix-ui/react-dialog';

import { ModalOnPointerDownOutsideHandler } from './ModalOnPointerDownOutsideHandler';

export interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  setOpen: (newVal: boolean) => void;
  onClose?: () => void;
  asChild?: boolean;
  contentClassnames?: string;
  onInteractOutside?: (e: any) => void;
  testId?: string;
}

export function Modal({
  open,
  children,
  setOpen,
  onClose = () => {},
  asChild = false,
  contentClassnames,
  onInteractOutside,
  testId,
}: ModalProps) {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(newValue) => {
        setOpen(newValue);
        if (!newValue) {
          onClose();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          asChild={asChild}
          className="fixed inset-0 z-20 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
          data-testid={testId}
        >
          <div className="flex min-h-full justify-center overflow-y-scroll p-4 text-center sm:items-center sm:p-0">
            <Dialog.Content
              onOpenAutoFocus={disableAutoFocusInTest()}
              className={`${contentClassnames} relative isolate transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6`}
              onPointerDownOutside={ModalOnPointerDownOutsideHandler}
              onInteractOutside={(e) => {
                onInteractOutside?.(e);
                // Defer invocation to next available frame after unmount
                setTimeout(maybeResetPointerEvents, 0);
              }}
            >
              {children}
            </Dialog.Content>
          </div>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

// When multiple portals are stacked its possible that there is a left over
// `pointer-events: none` style attached to the body. This function will test
// for any open dialogs, and if none are found, reset pointer-events.
function maybeResetPointerEvents() {
  const openDialogs = Array.from(document.querySelectorAll('[role=dialog]'));
  const isBlockingPointerEvents = document.body.style.pointerEvents === 'none';

  if (!openDialogs.length && isBlockingPointerEvents) {
    document.body.style.pointerEvents = 'auto';
  }
}

// When testing Select components within modals you can get stuck an infinite
// focus loop where the dialog is trying to retain focus against the open
// popover. This will disable focus functionality while tests are executing.
function disableAutoFocusInTest() {
  if (process.env.NODE_ENV === 'test') {
    return (e: Event) => {
      e.preventDefault();
    };
  }

  return;
}
