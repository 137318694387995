import { ClockIcon } from '@heroicons/react/24/outline';

import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import { AssignmentBreakRead } from 'src/common/external/bambi-api/bambiApi';
import { Popover } from 'src/common/primitives/Popover';

import { BreakCalendarEventMenu } from './BreakCalendarEventMenu';
import CoffeeMugIcon from './CoffeeMugIcon';

export default function BreakCalendarEvent({
  assignmentBreak,
}: {
  assignmentBreak: AssignmentBreakRead;
}) {
  return (
    <Popover
      trigger={
        <div
          className={
            'flex h-full cursor-auto flex-row overflow-hidden border-l-4 border-blue-900 bg-gray-200 px-1 pt-[1px] opacity-70 hover:cursor-pointer'
          }
          style={{
            minHeight: 'calc(1em + 1px)',
            background:
              'repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 5px, rgba(0, 0, 0, 0.3) 5px, rgba(0, 0, 0, 0.3) 10px)',
          }}
          data-breakid={assignmentBreak.id}
        >
          <div className="flex w-full justify-between text-xs font-bold text-gray-500 opacity-100">
            <div>
              <div className="mt-1 flex rounded bg-white p-1">
                <div>
                  <CoffeeMugIcon />
                </div>
                <div className="ml-1">Break</div>
              </div>
            </div>
          </div>
        </div>
      }
      content={
        <div className="relative flex flex-col gap-2 ">
          <div className="absolute right-0 -mr-3 -mt-2 flex items-center justify-end">
            <BreakCalendarEventMenu assignmentBreak={assignmentBreak} />
          </div>
          <div className="text-sm">
            <h2 className="font-bold">Status</h2>
            {!assignmentBreak.started_at && <div>Not Started</div>}
            {typeof assignmentBreak.started_at === 'string' && (
              <div className="flex items-center gap-1 text-xs text-gray-500">
                <ClockIcon data-testid="clock-icon" className="h-4 w-4" />
                <div>
                  Started:{' '}
                  <span data-testid="break-started-at" className="font-bold">
                    {formatTimeFrom24HourTo12Hour(assignmentBreak.started_at)}
                  </span>{' '}
                  (Scheduled:{' '}
                  <span data-testid="break-scheduled-start">
                    {formatTimeFrom24HourTo12Hour(assignmentBreak.start)}
                  </span>
                  )
                </div>
              </div>
            )}
            {typeof assignmentBreak.ended_at === 'string' && (
              <div className="flex items-center gap-1 text-xs text-gray-500">
                <ClockIcon data-testid="clock-icon" className="h-4 w-4" />
                <div>
                  Ended:{' '}
                  <span data-testid="break-ended-at" className="font-bold">
                    {formatTimeFrom24HourTo12Hour(assignmentBreak.ended_at)}
                  </span>{' '}
                  (Scheduled:{' '}
                  <span data-testid="break-scheduled-end">
                    {formatTimeFrom24HourTo12Hour(assignmentBreak.end)}
                  </span>
                  )
                </div>
              </div>
            )}
          </div>
          {assignmentBreak.break_notes && (
            <div className="text-sm">
              <h2 className="font-bold">Notes</h2>
              <p>{assignmentBreak.break_notes}</p>
            </div>
          )}
        </div>
      }
    />
  );
}
