import { useAppDispatch, useAppSelector } from 'src/app/store';

import { InvoiceTotalFilter } from '../../filters/InvoiceTotalFilter';
import { setFilterTotalAmount } from '../../invoice.slice';

interface InvoiceTotalColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function InvoiceTotalColumnFilter({
  open,
  onClose,
}: InvoiceTotalColumnFilterProps) {
  const dispatch = useAppDispatch();
  const totalAmountRange = useAppSelector(
    (state) => state.invoice.filterTotalAmount
  );

  return (
    <InvoiceTotalFilter
      open={open}
      triggerElement={<div />}
      value={totalAmountRange}
      onChange={(range) => {
        dispatch(setFilterTotalAmount(range));
      }}
      onClose={onClose}
    />
  );
}
