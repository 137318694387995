import { CalendarClockIcon } from '../primitives/icon/CalendarClockIcon';
import { formatTimeFrom24HourTo12Hour } from './formatTimeFrom24HourTo12Hour';

export const AppointmentTimeDisplay = ({
  strDate,
  textClass,
  iconClass,
}: {
  strDate: string;
  textClass?: string;
  iconClass?: string;
}) => {
  return (
    <div className="flex items-center">
      <CalendarClockIcon className={iconClass} />
      <div className={textClass}>{formatTimeFrom24HourTo12Hour(strDate)}</div>
    </div>
  );
};
