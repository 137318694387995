import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import { useTripsSuggestedPickupAtCreateMutation } from 'src/common/external/bambi-api/bambiApi';

const useCalculatePickupTime = (index: number) => {
  const form = useFormContext();
  const [calculatePickupTime] = useTripsSuggestedPickupAtCreateMutation();
  const date = form.watch('start_date');
  const appointmentTime = form.watch(`recurrences.${index}.appointment_time`);
  const pickup = form.watch(`pickup.address`);
  const dropoff = form.watch(`dropoff.address`);
  const spaceTypeEnum =
    form.watch(`service_details.space_type`) || 'wheelchair';
  const pickupTime = form.watch(`recurrences.${index}.pickup_time`);
  const pickupTimeDirty =
    form.formState.dirtyFields.recurrences?.[index]?.pickup_time;
  const loadTime = form.watch(`load_time_seconds`);
  const unloadTime = form.watch(`unload_time_seconds`);

  useEffect(() => {
    if (pickupTimeDirty || pickupTime) return;

    async function fetchSuggestedPickupTime() {
      if (date && appointmentTime && pickup?.value && dropoff?.value) {
        const appointmentDateTime = convertISODateTimePartsToUTCISO({
          date,
          time: appointmentTime,
        });

        try {
          const res = await calculatePickupTime({
            tripSuggestedPickupTime: {
              appointment_at: appointmentDateTime,
              pickup_aws_place_id: pickup.value,
              dropoff_aws_place_id: dropoff.value,
              space_type: spaceTypeEnum,
              load_time_seconds: loadTime || undefined,
              unload_time_seconds: unloadTime || undefined,
            },
          }).unwrap();

          if (res && res.pickup_at) {
            const time = DateTime.fromISO(res.pickup_at)
              .toLocal()
              .toFormat('HH:mm');
            form.setValue(`recurrences.${index}.pickup_time`, time);
            form.clearErrors(`recurrences.${index}.pickup_time`);
          }
        } catch (err) {
          // Optionally handle error
        }
      }
    }

    fetchSuggestedPickupTime();
  }, [
    date,
    appointmentTime,
    pickup.value,
    dropoff.value,
    spaceTypeEnum,
    pickupTime,
    loadTime,
    unloadTime,
    calculatePickupTime,
    form,
    index,
    pickupTimeDirty,
  ]);
};

export default useCalculatePickupTime;
