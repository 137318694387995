import { ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';

import {
  PaginatedPassengerAddressListRead,
  PayerOrganizationRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

interface getLocationOptionAdornmentProps {
  option: SelectOption;
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined;
  selectedPayer: PayerOrganizationRead | null | undefined;
}

export default function getLocationOptionAdornment({
  option,
  recentAddressResponse,
  selectedPayer,
}: getLocationOptionAdornmentProps) {
  const recentAddress = recentAddressResponse?.results.find(
    (result) => result.address.location?.place_id === option.value
  );

  const isPayerDefaultAddress = selectedPayer?.default_trip_address?.location
    ?.place_id
    ? selectedPayer.default_trip_address.location.place_id === option.value
    : false;

  if (recentAddress) {
    return (
      <span>
        <ClockIcon data-testid="clock-icon" className="h-4 w-4" />{' '}
      </span>
    );
  }

  if (isPayerDefaultAddress) {
    return (
      <span>
        <MapPinIcon data-testid="map-pin-icon" className="h-4 w-4" />{' '}
      </span>
    );
  }

  return undefined;
}
