import { useState } from 'react';

import { UseFormReturn } from 'react-hook-form';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import {
  AttendantRead,
  useAttendantsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { setShowAttendantProfileModal } from 'src/features/team/team.slice';

import { convertFormValuesToAttendant } from './convertFormValuesToAttendant';
import { FormValues } from './FormValues';
import { generateSuccessToast } from './generateAddSuccessToast';
import { generateEditSuccessToast } from './generateEditSuccessToast';
import { useAttendantProfileRepository } from './useAttendantProfileRepository';

type useAttendantFormStepTwoSubmissionProps = {
  methods: UseFormReturn<FormValues, any>;
};
export function useAttendantFormStepTwoSubmission({
  methods,
}: useAttendantFormStepTwoSubmissionProps) {
  const selectedAttendantProfile = useAppSelector(
    (state: RootState) => state.team.selectedAttendantProfile
  );
  const dispatch = useAppDispatch();
  const { createAttendant, updateAttendant } = useAttendantProfileRepository();
  const attendantProfileSearchTerm = useAppSelector(
    (state: RootState) => state.team.attendantProfileSearchTerm
  );
  const { refetch: refetchAttendants } = useAttendantsListQuery({
    'Organization-ID': '1',
    search: attendantProfileSearchTerm,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const nextToastId = useAppSelector((state: RootState) => state.toast.nextId);

  const [step, setStep] = useState(1);
  const [serverErrors, setServerErrors] = useState<string | null>(null);
  const onSubmitStepTwo = async () => {
    setIsSubmitting(true);

    const patchedAttendant: AttendantRead = convertFormValuesToAttendant(
      methods.getValues(),
      selectedAttendantProfile
    );

    setServerErrors(null);

    try {
      const attendant = await (selectedAttendantProfile?.id
        ? updateAttendant(patchedAttendant)
        : createAttendant(patchedAttendant));

      const showAction = selectedAttendantProfile
        ? generateEditSuccessToast(
            nextToastId,
            attendant.first_name || 'unknown'
          )
        : generateSuccessToast(nextToastId);

      dispatch(show(showAction));
      dispatch(setShowAttendantProfileModal(false));
      // TODO: There seems to be some delay with the creation of the attendant and skills and when
      // the updated info is available from the attendant api. This isn't a client side issue because the
      // response from the api comes back with stale data.
      // Adding this delay fixes this most of the time, but it's not a good solution. Will come back
      // as a future pr before release.
      await new Promise((resolve) => setTimeout(resolve, 400));
      await refetchAttendants();
      setStep(1);
    } catch (e) {
      const formattedErr = formatServerError(e);
      setServerErrors(formattedErr);
    } finally {
      setIsSubmitting(false);
    }
  };
  return {
    onSubmitStepTwo,
    isSubmitting,
    setStep,
    setServerErrors,
    step,
    serverErrors,
  };
}
