import { useAppSelector } from 'src/app/store';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { setVehicleSearchTerm } from 'src/features/fleet/fleet.slice';
import { VehicleForm } from 'src/features/fleet/VehicleForm/VehicleForm';

export function VehicleManagementRouteActions() {
  const vehicleSearchTerm = useAppSelector(
    (state) => state.fleet.vehicleSearchTerm
  );
  const { isRestrictedSupport } = useUserRoles();

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setVehicleSearchTerm,
    vehicleSearchTerm
  );

  return (
    <div className="flex items-center gap-2">
      {!isRestrictedSupport && <VehicleForm />}
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search vehicles"
        value={inputValue}
      />
    </div>
  );
}
