import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';

import { Button } from '../Button';

interface FilterPopoverTriggerProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  count?: number;
  showCountBadge?: boolean;
  showLabel?: boolean;
  label: React.ReactNode;
}

export const FilterPopoverTrigger = forwardRef(function FilterPopoverTrigger(
  {
    count,
    label,
    showCountBadge = true,
    showLabel = true,
    ...rest
  }: FilterPopoverTriggerProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button
      dataTestId="filter-popover-trigger"
      className="relative"
      variant={count ? 'primary-outline' : undefined}
      {...rest}
      ref={ref}
    >
      <AdjustmentsHorizontalIcon
        className={`${showLabel ? 'mr-2' : ''} h-4 w-4 ${
          count ? 'stroke-mint' : ''
        }`.trim()}
      />
      {showLabel ? label : null}
      {count && showCountBadge ? <FilterCountBadge value={count} /> : null}
    </Button>
  );
});

export function FilterCountBadge({
  value,
  positioned = true,
}: {
  value: number;
  positioned?: boolean;
}) {
  if (value === 0) {
    return null;
  }

  return (
    <div
      data-testid="filter-popover-badge"
      className={`rounded-full border-2 border-mint bg-white text-center align-middle ${
        positioned ? 'absolute' : ''
      }`.trim()}
      style={{
        fontSize: '10px',
        lineHeight: '11px',
        width: 15,
        height: 15,
        ...(positioned
          ? {
              right: '-7.5px',
              top: '-7.5px',
            }
          : null),
      }}
    >
      {value <= 9 ? (
        value
      ) : (
        <>
          9
          <span
            className="relative"
            style={{
              fontSize: '7px',
              marginRight: -2,
              bottom: 2,
            }}
          >
            +
          </span>
        </>
      )}
    </div>
  );
}
