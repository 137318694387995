import { AssignmentLocationRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';
import { AssignmentPinConfig } from 'src/features/live-map/LiveMap/AssignmentLiveMapView/AssignmentPinConfig';

export function makeAssignmentPinConfig(
  assignmentLocation: AssignmentLocationRead
): AssignmentPinConfig {
  return {
    heading: assignmentLocation.latest_location.heading,
    latitude: assignmentLocation.latest_location.latitude,
    longitude: assignmentLocation.latest_location.longitude,
    title: formatFullName(assignmentLocation.driver),
    description:
      assignmentLocation.vehicle.nickname ||
      assignmentLocation.vehicle.make + ' ' + assignmentLocation.vehicle.model,
  };
}
