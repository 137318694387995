import { useDriversListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Select } from 'src/common/primitives/Select';
import { formatFullName } from 'src/common/util/formatFullName';
import { useAuth } from 'src/features/auth/useAuth';

export function DriverSelectionField({
  onlySchedulable,
}: {
  onlySchedulable?: boolean;
}) {
  const auth = useAuth();
  const { data: drivers, isFetching } = useDriversListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
    canBeScheduled: onlySchedulable,
  });
  return (
    <div>
      <FormContextField
        name="driver"
        rules={{ required: 'Driver is required' }}
      >
        {({ field, fieldState }) => {
          return (
            <DefaultFormFieldLayout
              label="Driver"
              error={fieldState.error?.message?.toString()}
              inputProps={{ id: 'Driver-id' }}
            >
              <Select
                selected={field.value}
                options={
                  drivers?.results
                    ? drivers.results.map((driver) => {
                        return {
                          label:
                            formatFullName(driver) ||
                            `No name for driver id: ${driver.id}`,
                          value: driver.id,
                        };
                      })
                    : []
                }
                onChange={(selected) => {
                  field.onChange(selected);
                }}
                placeholder={
                  isFetching ? 'Loading drivers...' : 'Select a driver'
                }
                disabled={isFetching}
              />
            </DefaultFormFieldLayout>
          );
        }}
      </FormContextField>
    </div>
  );
}
