import { ReactNode } from 'react';

// TODO: This is probably a generic Grid component
export type SupportedGridSizes =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

export const DEFAULT_COLUMNS: SupportedGridSizes = 12;
export const DEFAULT_COL_SPAN: SupportedGridSizes = 6;

export function FormFieldSet({
  legend,
  Fields,
  columns = DEFAULT_COLUMNS,
}: {
  legend?: string;
  Fields: ReactNode;
  columns?: SupportedGridSizes;
}) {
  return (
    <fieldset>
      <legend className="text-base font-medium text-gray-900">{legend}</legend>
      <div
        className={`mt-2 grid grid-cols-1 gap-x-8 gap-y-3 sm:grid-cols-${columns}`}
      >
        {Fields}
      </div>
    </fieldset>
  );
}
