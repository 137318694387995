import { useCallback, useState } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import {
  VehicleCapacityConfiguration as APIVehicleCapacityConfiguration,
  useVehiclesCreateMutation,
  useVehiclesDestroyMutation,
  useVehiclesPartialUpdateMutation,
  Vehicle,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { useConfirmClose } from 'src/common/modals/RequiresConfirmationModal/RequiresConfirmationModal';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { WizardHeader } from 'src/common/wizard/WizardHeader';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { clearSelectedVehicle, setSelectedVehicle } from '../fleet.slice';
import { generateSuccessToast } from './generateAddSuccessToast';
import { generateDeleteSuccessToast } from './generateDeleteSuccessToast';
import { generateEditSuccessToast } from './generateEditSuccessToast';
import { assignDefaultFormValues } from './VehicleFormStep1/assignDefaultFormValues';
import { FormValues as VehicleFormStep1FormValues } from './VehicleFormStep1/FormValues';
import { resolver } from './VehicleFormStep1/resolver';
import { VehicleFormStep1 } from './VehicleFormStep1/VehicleFormStep1';
import { VehicleFormStep2 } from './VehicleFormStep2/VehicleFormStep2';

function getStepTitle(step: number, vehicle?: Vehicle) {
  switch (step) {
    case 2:
      return 'Add Capacity Configurations';
    default:
      return vehicle ? 'Edit Vehicle' : 'Add a Vehicle';
  }
}
export function VehicleForm({ vehicle }: { vehicle?: VehicleRead }) {
  const [open, setOpenInternal] = useState(false);
  const [step, setStep] = useState(1);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | undefined>(undefined);
  const [serverErrors, setServerErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [createVehicle] = useVehiclesCreateMutation();
  const [updateVehicle] = useVehiclesPartialUpdateMutation();
  const [deleteVehicle] = useVehiclesDestroyMutation();
  const [isFormDirty, setFormDirty] = useState(false);
  const { isRestrictedSupport } = useUserRoles();

  const { reset } = useForm({
    resolver,
    defaultValues: assignDefaultFormValues(vehicle),
  });

  const closeModal = () => {
    dispatch(clearSelectedVehicle());
    setStep(1);
    setError(null);
    setServerErrors(null);
    setOpenInternal(false);
  };

  const shouldConfirmClose = useCallback(() => isFormDirty, [isFormDirty]);

  const [setOpen, onClose] = useConfirmClose({
    setOpen: setOpenInternal,
    shouldConfirmClose,
    onClose: closeModal,
  });

  const nextToastId = useSelector((state: RootState) => state.toast.nextId);

  const { selectedVehicle } = useSelector((state: RootState) => state.fleet);

  const dispatch = useDispatch();

  const onSubmitStepOne = (data: VehicleFormStep1FormValues) => {
    setServerErrors(null);
    setError(null);
    dispatch(setSelectedVehicle(data));
    setStep(2);
  };
  const onSubmitStepTwo = async (data: APIVehicleCapacityConfiguration[]) => {
    try {
      setLoading(true);
      setServerErrors(null);
      setError(null);
      dispatch(setSelectedVehicle({ capacity_configurations: data }));
      const vehicle = {
        ...selectedVehicle,
        capacity_configurations: data,
      };

      await saveChanges(vehicle);

      vehicle
        ? dispatch(
            show(generateEditSuccessToast(nextToastId, vehicle.nickname || ''))
          )
        : dispatch(show(generateSuccessToast(nextToastId)));
      closeModal();
    } catch (error) {
      const formattedErr = formatServerError(error);
      setServerErrors(formattedErr);
    }
    setLoading(false);
  };

  const saveChanges = async (vehicle: Vehicle | VehicleRead) => {
    if ((vehicle as VehicleRead).id) {
      await updateVehicle({
        id: (vehicle as VehicleRead).id,
        patchedVehicle: vehicle as VehicleRead,
      }).unwrap();
    } else {
      await createVehicle({
        vehicle,
      }).unwrap();
    }
  };

  const onDeleteVehicle = async () => {
    try {
      await deleteVehicle({
        id: vehicle?.id || '',
      }).unwrap();
      setIsConfirmingDelete(false);
      reset();
      dispatch(show(generateDeleteSuccessToast(nextToastId)));
    } catch (error) {
      const message = formatServerError(error);
      setDeleteError(message);
    }
  };

  const title = getStepTitle(step, vehicle);
  return (
    <>
      {vehicle ? (
        <Button
          onClick={() => {
            dispatch(setSelectedVehicle(vehicle));
            setOpenInternal(true);
          }}
          variant="ghost"
          disabled={isRestrictedSupport}
          dataTestId={`edit-vehicle-button-${vehicle.id}`}
        >
          <PencilIcon className="h-6 w-6 cursor-pointer hover:text-mint" />
        </Button>
      ) : (
        <Button
          onClick={() => setOpenInternal(true)}
          variant="primary"
          disabled={isRestrictedSupport}
          dataTestId="add-vehicle-button"
        >
          Add a Vehicle
        </Button>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        testId="vehicle-form-modal"
      >
        <>
          <WizardHeader
            title={title}
            wizardState={{ step, totalSteps: 2 }}
            setStep={setStep}
          />
          {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
          {step === 1 && (
            <VehicleFormStep1
              onSubmit={onSubmitStepOne}
              onCancel={() => {
                closeModal();
              }}
              onDelete={() => {
                setIsConfirmingDelete(true);
                setOpenInternal(false);
              }}
              onFormDirtyChange={setFormDirty}
            />
          )}
          {step === 2 && (
            <VehicleFormStep2
              onSubmit={onSubmitStepTwo}
              onCancel={() => {
                closeModal();
              }}
              loading={loading}
              serverErrors={serverErrors}
            />
          )}
        </>
      </Modal>
      <DeleteModal
        open={isConfirmingDelete}
        setOpen={setIsConfirmingDelete}
        onCancel={() => {
          setDeleteError(undefined);
          setOpenInternal(true);
          setIsConfirmingDelete(false);
        }}
        onConfirm={() => onDeleteVehicle()}
        title="Delete this vehicle?"
        description="Are you sure you want to delete this vehicle? This action cannot be undone."
        error={deleteError}
      />
    </>
  );
}
