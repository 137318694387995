import { truncate } from 'lodash-es';

import { removeUnitedStatesFromAddress } from './util/removeUnitedStatesFromAddress';

const BASE_URL = 'https://share.here.com/';

function getHereMapsLatLongParams(latitude: number, longitude: number) {
  return latitude + ',' + longitude;
}

type PATH_TYPE = 'location' | 'route';

const pathTypeMap: Record<PATH_TYPE, string> = {
  location: 'l',
  route: 'r',
};

function createMapsHref(type: PATH_TYPE, addresses: Location[]): string {
  const base = `${BASE_URL}${pathTypeMap[type]}/`;

  return (
    base +
    addresses
      .map(({ latitude, longitude }) =>
        getHereMapsLatLongParams(latitude, longitude)
      )
      .join('/')
  );
}

export function formatHereMapsAddress(address: string) {
  const formattedAddress = removeUnitedStatesFromAddress(address);

  return truncate(formattedAddress, {
    length: 50,
    omission: '...',
  });
}

type Location = {
  latitude: number;
  longitude: number;
};

interface HereMapsAddressLinkProps {
  addresses: Location[];
  className?: string;
  children: React.ReactNode;
}

export function HereMapsAddressLink({
  addresses,
  className,
  children,
}: HereMapsAddressLinkProps) {
  const linkClass = className
    ? className
    : 'font-medium text-gray-500 hover:underline dark:text-gray-400';

  const href = createMapsHref(
    addresses.length === 1 ? 'location' : 'route',
    addresses
  );

  return (
    <a
      href={href}
      className={linkClass}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
