import { useEffect } from 'react';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/app/store';
import { getStorageValue } from 'src/common/util/useLocalStorage/getStorageValue';

type KeyPayloadActionTuple = [
  string,
  ActionCreatorWithPayload<any>,
  (Array<string> | string)?
][];

export function useRestoreFiltersFromStorage(tuples: KeyPayloadActionTuple) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    tuples.forEach((tuple) => {
      const [key, payloadAction, defaultValue] = tuple;
      const value = getStorageValue(key, defaultValue, true);

      if (value && Object.keys(value).length) {
        dispatch(payloadAction(value));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
