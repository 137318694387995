export function searchParamsToJSON(params: URLSearchParams) {
  const result: Record<string, string | string[]> = {};
  for (const pair of params.entries()) {
    const [key, value] = pair;

    if (result[key]) {
      result[key] = [result[key], value].flatMap((n) => n);
    } else {
      result[key] = value;
    }
  }

  return result;
}
