import {
  AttendantRead,
  useAttendantsCreateMutation,
  useAttendantsDestroyMutation,
  useAttendantsPartialUpdateMutation,
  useAttendantsSkillsCreateMutation,
  useAttendantsSkillsDestroyMutation,
  useAttendantsSkillsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

export function useAttendantProfileRepository() {
  const auth = useAuth();
  const [createAttendant] = useAttendantsCreateMutation();
  const [updateAttendant] = useAttendantsPartialUpdateMutation();
  const [destroyAttendant] = useAttendantsDestroyMutation();
  const [createAttendantSkills] = useAttendantsSkillsCreateMutation();
  const [updateAttendantSkills] = useAttendantsSkillsPartialUpdateMutation();
  const [destroyAttendantSkills] = useAttendantsSkillsDestroyMutation();

  return {
    createAttendant: async (attendant: AttendantRead) => {
      const attendantWithNoSkills = await createAttendant({
        'Organization-ID': auth.currentOrganizationId || '',
        attendant,
      }).unwrap();
      const attendantSkills = await createAttendantSkills({
        'Organization-ID': auth.currentOrganizationId || '',
        attendantSkills: {
          ...attendant.skills,
          attendant: attendantWithNoSkills.id,
        },
      }).unwrap();
      return {
        ...attendantWithNoSkills,
        skills: attendantSkills,
      };
    },
    updateAttendant: async (patchedAttendant: AttendantRead) => {
      const attendantWithOutdatedSkills = await updateAttendant({
        'Organization-ID': auth.currentOrganizationId || '',
        id: patchedAttendant.id,
        patchedAttendant,
      }).unwrap();
      const updatedAttendantSkills = await updateAttendantSkills({
        'Organization-ID': auth.currentOrganizationId || '',
        id: attendantWithOutdatedSkills.skills.id || '',
        patchedAttendantSkills: {
          ...patchedAttendant.skills,
        },
      }).unwrap();
      return {
        ...attendantWithOutdatedSkills,
        skills: updatedAttendantSkills,
      };
    },
    destroyAttendant: async (attendant: AttendantRead) => {
      const destroyedAttendantSkills = await destroyAttendantSkills({
        'Organization-ID': auth.currentOrganizationId || '',
        id: attendant.skills.id || '',
      }).unwrap();
      const destroyedAttendant = destroyAttendant({
        'Organization-ID': auth.currentOrganizationId || '',
        id: attendant.id,
      }).unwrap();

      return {
        ...destroyedAttendant,
        skills: destroyedAttendantSkills,
      };
    },
  };
}
