import React from 'react';

import * as RadixAccordion from '@radix-ui/react-accordion';
import { styled } from '@stitches/react';

import { ChevronIcon } from 'src/common/primitives/icon/ChevronIcon';

const StyledHeader = styled(RadixAccordion.Header, {
  display: 'flex',
});

const StyledTrigger = styled(RadixAccordion.Trigger, {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledContent = styled(RadixAccordion.Content, {});

export const Root = styled(RadixAccordion.Root, {
  width: '100%',
});

export const Item = styled(RadixAccordion.Item, {});

export const Trigger = React.forwardRef(
  (
    {
      children,
      ...props
    }: { children: any; props?: RadixAccordion.AccordionTriggerProps },
    forwardedRef: React.Ref<HTMLButtonElement>
  ) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={forwardedRef}>
        {children}
        <div className="absolute right-2">
          <ChevronIcon />
        </div>
      </StyledTrigger>
    </StyledHeader>
  )
);

export const Content = React.forwardRef(
  (
    {
      children,
      ...props
    }: { children: any; props?: RadixAccordion.AccordionContentProps },
    forwardedRef: React.Ref<HTMLDivElement>
  ) => (
    <StyledContent {...props} ref={forwardedRef}>
      {children}
    </StyledContent>
  )
);
