import { DateTime } from 'luxon';

import { SimpleTooltip } from 'src/common/primitives/Tooltip';

type AssignmentEventConfig = {
  content: string;
};

type AssignmentEventTypes = 'clock-in' | 'clock-out';
const variantConfigs: Record<AssignmentEventTypes, AssignmentEventConfig> = {
  'clock-in': {
    content: 'Clock In',
  },
  'clock-out': {
    content: 'Clock Out',
  },
};

type AssignmentEventProps = {
  variant: AssignmentEventTypes;
  time: string;
};

/**
 *
 * Represents some event that happened at a point in time and does not have a start/end
 *
 * Currently roughly maps to the assignment event object in the backend (<django_url>/admin/assignments/assignmentevent/)
 * That might not always be the case, but right now we only have clock in and clock out events
 *
 * Also considered a generic PointInTimeEvent component but this is a good spot to start
 */
export function AssignmentEvent({ variant, time }: AssignmentEventProps) {
  const variantConfig: AssignmentEventConfig = variantConfigs[variant];
  return (
    <SimpleTooltip
      trigger={
        <div
          className="h-full w-full border-t-2 border-t-gray-600 bg-transparent"
          data-testid={`assignment-event-${variant}`}
        />
      }
      content={`${variantConfig.content} - ${DateTime.fromISO(
        time
      ).toLocaleString(DateTime.TIME_SIMPLE)}`}
    />
  );
}
