import { PayerOrganization } from 'src/common/external/bambi-api/bambiApi';

const lowerCasedPayerNames = [
  'modivcare',
  'call the car',
  'welltrans',
  'saferide',
];

export function isPayerEligibleForPayerPortalUploadExport(
  payer: PayerOrganization
) {
  return lowerCasedPayerNames.some((name) =>
    payer.display_name.toLowerCase().includes(name)
  );
}
