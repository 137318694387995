import { IToastConfig } from 'src/common/primitives/Toast';

export function generateEditSuccessToast(
  id: number,
  memberName: string
): IToastConfig {
  return {
    id,
    title: `${memberName} updated successfully`,
  };
}
