import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

import { formatPassengerLabel } from './formatPassengerLabel';

export function buildPassengerSelectOption(
  passenger: PassengerRead
): SelectOption {
  return {
    label: formatPassengerLabel(passenger),
    value: passenger.id,
  };
}
