import { useParams } from 'react-router-dom';

import { PricingModelRoutesParams } from 'src/features/pricingModel/routes';

import { useSelectedPricingModel } from './useSelectedPricingModel';

export function useSelectedPricingType() {
  const pricingModel = useSelectedPricingModel();
  const { selectedPricingTypeId } = useParams<PricingModelRoutesParams>();
  return pricingModel?.pricing_types.find(
    (pricingType) => pricingType.id === selectedPricingTypeId
  );
}
