import { useCallback, useState } from 'react';

import { ReceiptRefundIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  InvoicePaymentRead,
  useInvoicesRefundsCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { resetEditing } from '../../invoice.slice';

export function RefundPaymentCell({
  payment,
}: {
  payment: InvoicePaymentRead;
}) {
  const dispatch = useAppDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const canRefund = payment.amount_total_cents !== 0;
  const [createRefund] = useInvoicesRefundsCreateMutation();

  const handleCreateRefund = useCallback(async () => {
    if (!payment.id) {
      return;
    }

    try {
      await createRefund({
        invoiceRefund: {
          amount_cents: payment.amount_total_cents,
          payment: payment.id,
          payment_intent_id: payment.is_manual
            ? '-1'
            : payment.external_payment_id,
        },
      }).unwrap();
      dispatch(
        show({
          title: 'Refund successful',
          description: 'Refunds may take 24 hours to process',
          type: 'success',
        })
      );
      dispatch(resetEditing());
    } catch (e) {
      dispatch(
        show({
          title: 'Refund failed',
          description: `Failed to create refund ${formatServerError(e)}`,
          type: 'error',
        })
      );
    } finally {
      setShowConfirm(false);
    }
  }, [createRefund, dispatch, payment]);

  return (
    <div className="flex flex-row justify-end print:hidden">
      {canRefund ? (
        <Button variant="icon" onClick={() => setShowConfirm(true)}>
          <ReceiptRefundIcon className="w-6" />
        </Button>
      ) : null}

      <ConfirmModal
        title={`Refund payment of ${defaultCurrencyFormat(
          payment.amount_total_cents
        )}?`}
        description="Are you sure you want to refund this payment? This action is not reversable."
        open={showConfirm}
        icon={<ReceiptRefundIcon className="w-5" />}
        setOpen={setShowConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleCreateRefund}
        mode="destructive"
      />
    </div>
  );
}
