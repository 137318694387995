import {
  AssignmentTripLocationRead,
  LocationLogEntryRead,
} from './external/bambi-api/bambiApi';

const isValidLocation = (
  location: AssignmentTripLocationRead | LocationLogEntryRead | undefined
) => {
  const { longitude, latitude } = location || {};
  if (typeof longitude === 'number' && typeof latitude === 'number') {
    return { long: longitude, lat: latitude };
  }
};

export default isValidLocation;
