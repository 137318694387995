import { useAppDispatch } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { onPricingRuleEditChange } from 'src/features/pricingModel/pricingModel.slice';

export default function PricingRuleDescriptionInput({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  return (
    <div className="flex  items-center">
      <label
        htmlFor="description"
        className="mr-2 block text-sm font-medium leading-6 text-gray-700"
      >
        Description:
      </label>
      <div className=" mt-2 flex flex-grow rounded-md shadow-sm">
        <FormField
          type="text"
          inputProps={{
            name: 'description',
            id: 'pricing-rule-description',
            value: pricingRule.text_description,
            onChange: (e) => {
              const text_description = e.target.value;
              dispatch(
                onPricingRuleEditChange({
                  ...pricingRule,
                  text_description,
                })
              );
            },
          }}
        />
      </div>
    </div>
  );
}
