import { upperFirst } from 'lodash-es';

// Anything that doesn't fit into replacing underscores with spaces and capitalizing each word
const tripFieldToLabelMap: { [key: string]: string } = {
  payer_id: 'Payer',
  is_locked: 'Locked',
  appointment_at: 'Appointment Time',
  input_price_cents: 'Price',
  num_attendants_needed: 'Attendants Needed',
  num_accompanying_passengers: 'Accompanying Passengers',
  final_calculated_price_cents: 'Final Calculated Price',
  initial_calculated_price_cents: 'Initial Calculated Price',
  scheduled_pickup_at: 'Pickup Time',
  pickup_wait_time_seconds: 'Pickup Wait Time',
  oxygen_milliliters_per_min: 'Oxygen Liters Per Minute',
};

function formatTripFieldKeyAsLabel(valueKey: string) {
  return (
    tripFieldToLabelMap[valueKey] ||
    valueKey
      .split('_')
      .map((x) => upperFirst(x))
      .join(' ')
  );
}

export function TripSummaryUpdateValueLabel({
  valueKey,
}: {
  valueKey: string;
}) {
  const formattedTripFieldLabel = formatTripFieldKeyAsLabel(valueKey);
  return <div className="font-medium">{formattedTripFieldLabel}</div>;
}
