import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/app/store';
import { usePayersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

import { DownloadCsvDetails } from '../trip.slice';
import { isPayerEligibleForPayerPortalUploadExport } from './download/isPayerEligibleForPayerPortalUploadExport';

export function usePayerSelectData() {
  const { showDownloadDetailsModal, downloadCsvDetail } = useAppSelector(
    (state) => state.trip
  );

  const [payerOptions, setPayerOptions] = useState<SelectOption[]>([]);
  const [selectedPayers, setSelectedPayers] = useState<SelectOption[]>([]);
  const [payerSearchTerm, setPayerSearchTerm] = useState('');

  const {
    data: payerData,
    isFetching,
    error: fetchError,
  } = usePayersListQuery(
    {
      search: payerSearchTerm,
    },
    { skip: !showDownloadDetailsModal }
  );

  useEffect(() => {
    if (!isFetching && payerData?.results) {
      const downloadPayersList = payerData.results.filter(
        isPayerEligibleForPayerPortalUploadExport
      );

      const payerListToUse =
        downloadCsvDetail === DownloadCsvDetails.PAYER
          ? downloadPayersList
          : payerData.results;

      const payerOptions = payerListToUse.map((payer) => ({
        label: payer.payer_name || '',
        value: payer.id,
      }));

      setPayerOptions(payerOptions);
    }
  }, [payerData, isFetching, fetchError, downloadCsvDetail]);

  const slugifyPayerName = (payerName: string) => {
    return payerName.replace(/\s+/g, '_').toLowerCase();
  };

  return {
    payerOptions,
    payerSearchTerm,
    setPayerSearchTerm,
    selectedPayers,
    setSelectedPayers,
    slugifyPayerName,
    payerData,
  };
}
