import { CalendarIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';

export const datePickerInput = (
  placeholder: string,
  date: DateTime | undefined
) => {
  return (
    <div className="relative flex items-center">
      <input
        placeholder={placeholder}
        value={date?.toFormat('MM/dd/yyyy') || ''}
        className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border border-gray-300 py-2 pl-8 text-center shadow-sm placeholder:text-center sm:text-sm"
        disabled
      />
      <CalendarIcon className="absolute left-2 mr-2 h-6 w-6 text-gray-700 " />
    </div>
  );
};
