import { useState } from 'react';

import { omit } from 'lodash-es';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { Select } from 'src/common/primitives/Select';
import { useFormValues } from 'src/common/useFormValues';
import { formatFullName } from 'src/common/util/formatFullName';

import { onAssignmentCloneConfirm } from '../../assignmentManagement.slice';
import { FormValues } from './FormValues';
import { useWeekDays } from './useWeekDays';

export function AssignmentCloneFlow() {
  const dispatch = useAppDispatch();
  const { assignmentToClone } = useAppSelector(
    (state) => state.assignmentManagement
  );
  const { selectedDay } = useAppSelector((state) => state.assignmentManagement);
  const selectedDayOrToday = selectedDay || DateTime.local().toISODate();
  const [dayToCloneTo, setDayToCloneTo] = useState<DateTime | undefined>();
  const days = useWeekDays(selectedDayOrToday);
  const methods = useFormContext();
  const formValues = useFormValues<FormValues>();
  const options = days
    .filter((day) => {
      // TODO: This should be a util...
      // Also, only reason to filter out the currently selected day while cloning
      // is because cloning into the same day probably has some complexity?
      // Maybe not, but I'm not going to worry about it right now. (Thanks copilot for writing this comment!)
      return day > DateTime.local().startOf('day');
    })
    .map((day) => ({
      label: day.toFormat('ccc (MM/dd)'),
      value: day.toISO(),
    }));
  if (!assignmentToClone) {
    return (
      <div>
        No assignment selected to clone. You probably shouldn't be seeing this.
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-1">
      <h3 className="text-lg">Assignment to Clone</h3>
      <div
        className="rounded-md border border-warning-300 bg-warning-25 p-4 text-warning-600"
        role="alert"
      >
        <div>Vehicle: {assignmentToClone.vehicle.nickname}</div>
        <div>Driver: {formatFullName(assignmentToClone.driver)}</div>
        {assignmentToClone.attendants.length > 0 && (
          <>
            <div>Attendants:</div>
            {assignmentToClone.attendants.map((attendant, index) => (
              <div key={attendant.attendant}>
                Attendant {index + 1}: {formatFullName(attendant)}
              </div>
            ))}
          </>
        )}
        <div>
          Start Time:{' '}
          {DateTime.fromISO(assignmentToClone.start).toLocaleString(
            DateTime.DATETIME_FULL
          )}
        </div>
        <div>
          End Time:{' '}
          {DateTime.fromISO(assignmentToClone.end).toLocaleString(
            DateTime.DATETIME_FULL
          )}
        </div>
      </div>
      <div className="flex gap-1">
        <Select
          placeholder="Select a day to clone to"
          selected={options.find(
            (option) => option.value === dayToCloneTo?.toISO()
          )}
          options={options}
          onChange={(selected) =>
            setDayToCloneTo(
              selected?.value ? DateTime.fromISO(selected.value) : undefined
            )
          }
        />
        <Button
          variant="primary"
          onClick={() => {
            if (!dayToCloneTo) return;
            // Get current assignment values for selected day
            const currentAssignmentValues =
              formValues.assignments[dayToCloneTo.weekdayLong];
            // Push a clone of the assignment to the selected day
            // (remove id when cloning and set start/end times to the selected day)
            const newFormValue = {
              dateTime: currentAssignmentValues.dateTime || dayToCloneTo,
              isEditing: true,
              assignments: [
                ...(currentAssignmentValues.assignments || []),
                {
                  id: undefined,
                  start: DateTime.fromISO(assignmentToClone.start)
                    .set({
                      day: dayToCloneTo.day,
                    })
                    .toFormat('HH:mm'),
                  end: DateTime.fromISO(assignmentToClone.end)
                    .set({
                      day: dayToCloneTo.day,
                    })
                    .toFormat('HH:mm'),
                  driver: {
                    ...omit(assignmentToClone.driver, ['driver']),
                    id: assignmentToClone.driver.driver,
                  },
                  attendants: assignmentToClone.attendants.map((attendant) => ({
                    ...omit(attendant, ['attendant']),
                    id: attendant.attendant,
                  })),
                },
              ],
            };
            methods.setValue(
              `assignments.${dayToCloneTo.weekdayLong}`,
              newFormValue
            );
            // Move from clone mode to add assignment mode
            dispatch(onAssignmentCloneConfirm());
          }}
          disabled={!dayToCloneTo}
        >
          Clone
        </Button>
      </div>
    </div>
  );
}
