import { CheckboxProps } from '@radix-ui/react-checkbox';

import {
  DispatchTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { CheckBox } from 'src/common/primitives/CheckBox';

import { TripCard } from './TripCard/TripCard';

type TripListItemProps = {
  trip: DispatchTripRead | RunBambiRunTripRead;
} & CheckboxProps;

export function TripListItem({
  trip,
  checked,
  onCheckedChange,
}: TripListItemProps) {
  return (
    <div className="flex items-center justify-between gap-x-3">
      <div>
        <CheckBox
          id={`unassigned-trip-checkbox-${trip.id}`}
          checked={checked}
          onCheckedChange={(val) => onCheckedChange?.(val)}
        />
      </div>
      <TripCard trip={trip} />
    </div>
  );
}
