import { ReactNode } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import { keyframes, styled } from '@stitches/react';

export const Popover = ({
  trigger,
  content,
  onOpenChange,
  onInteractOutside,
  open,
}: {
  trigger: ReactNode;
  content: ReactNode;
  onOpenChange?: (open: boolean) => void;
  onInteractOutside?: (e: any) => void;
  open?: boolean;
}) => (
  <RadixPopover.Root onOpenChange={onOpenChange} open={open}>
    <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
    <RadixPopover.Portal>
      <PopoverContent
        sideOffset={5}
        className="isolate z-20"
        onInteractOutside={onInteractOutside}
      >
        {content}
        <PopoverArrow />
      </PopoverContent>
    </RadixPopover.Portal>
  </RadixPopover.Root>
);

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const PopoverContent = styled(RadixPopover.Content, {
  borderRadius: 4,
  padding: 20,
  backgroundColor: 'white',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  '&:focus': {
    //boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${violet.violet7}`,
  },
});

const PopoverArrow = styled(RadixPopover.Arrow, {
  fill: 'white',
});
