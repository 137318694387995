import { DispatchTripTagRead } from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';
import { TripTag } from 'src/common/Tag/Tag';

export default function TripTagDisplay({
  tags,
}: {
  tags: DispatchTripTagRead[];
}) {
  if (tags.length > 3) {
    return (
      <div className="flex gap-1">
        {tags.slice(0, 3).map((tag) => (
          <TripTag key={tag.id} tag={tag} />
        ))}
        <SimpleTooltip
          trigger={
            // TODO: doesn't work without the div
            // Also, should probably render the actual tags instead of just text
            // And we should think about the ux... probably just make it a scrolly list
            <div>
              <TripTag
                key={tags[3].id}
                tag={{
                  ...tags[3],
                  tag_name: `+${tags.length - 3}`,
                  color_hex: '#5a5a5a',
                }}
              />
            </div>
          }
          content={tags
            .slice(3)
            .map((tag) => tag.tag_name)
            .join(', ')}
        />
      </div>
    );
  }

  return (
    <div className="flex gap-1">
      {tags.map((tag) => (
        <TripTag key={tag.id} tag={tag} />
      ))}
    </div>
  );
}
