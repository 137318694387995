const defaultTheme = require('tailwindcss/defaultTheme');

const colors = require('./colors');

const extend = {
  fontFamily: {
    sans: ['Inter var', ...defaultTheme.fontFamily.sans],
  },
  colors,
  screens: {
    print: { raw: 'print' },
    screen: { raw: 'screen' },
  },
};
module.exports = extend;
