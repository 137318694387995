import { useAppSelector } from 'src/app/store';
import { useAssignmentsLocationsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { selectCurrentAssignmentLocationId } from 'src/features/live-map/liveMap.slice';

export function useAssignmentLiveMapListViewModel() {
  const { data, isLoading, isFetching } = useAssignmentsLocationsListQuery({});
  const selectedAssignmentLocationId = useAppSelector(
    selectCurrentAssignmentLocationId
  );

  return {
    assignmentLocations: data?.results,
    isLoading,
    isFetching,
    selectedAssignmentLocationId,
  };
}
