import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
export const FormErrorMessage = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div
      className="rounded-md border border-warning-300 bg-warning-25 p-4 text-warning-600"
      role="alert"
    >
      <div className="flex w-full items-center">
        <div className="mr-2">
          <ExclamationTriangleIcon className="h-6 w-6" />
        </div>
        <div className="flex-grow">{children}</div>
      </div>
    </div>
  );
};
