import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { PassengerInfoFormSection } from 'src/features/add-trip/PassengerInfoFormSection';
import { PassengerFormModal } from 'src/features/passenger/PassengerForm/PassengerForm';

import { addATrip } from './addATrip.slice';
import { AddATripFlowDialog } from './AddATripFlowDialog';
import { AddATripFlowForm } from './AddATripFlowForm';
import { PricingFormSection } from './Pricing';
import { ServiceDetailsFormSection } from './ServiceDetails';
import { TripBookedBySection } from './TripBookedBySection';
import { TripDetailsFormSection } from './TripDetails';

export function AddATripFlow() {
  const dispatch = useAppDispatch();
  const passenger: PassengerRead | null | undefined = useAppSelector(
    (state: RootState) => state.addATrip.selectedPassenger
  );
  const isEditingPassenger = useAppSelector(
    (state: RootState) => state.addATrip.isEditingPassenger
  );

  const onPassengerSelected = (passenger: PassengerRead | undefined) => {
    dispatch(addATrip.actions.selectPassenger(passenger));
  };

  const onPassengerEdit = (passenger: PassengerRead) => {
    // TODO: Need to move passenger crud to be more global to get around this dumb hack.
    // This supports the following use case:
    // 1. Click edit passenger after selecting one
    // 2. Click cancel (causing the form state to reset)
    // 3. Click edit again for whatever reason
    // Wanted to keep changes to the passenger form editing as small as possible
    const temp = { ...passenger };
    dispatch(addATrip.actions.selectNoPassenger());
    dispatch(addATrip.actions.selectPassenger(temp));
    dispatch(addATrip.actions.setIsEditingPassenger(true));
  };

  const handlePassengerAddEdit = (passenger: PassengerRead) => {
    dispatch(addATrip.actions.selectPassenger(passenger));
  };

  return (
    <>
      <Button
        variant="primary-outline"
        onClick={() => dispatch(addATrip.actions.open())}
      >
        Add a trip
      </Button>
      <AddATripFlowDialog>
        <AddATripFlowForm>
          <PassengerInfoFormSection
            selectedPassenger={passenger}
            onPassengerSelected={onPassengerSelected}
            onPassengerEdit={onPassengerEdit}
          />
          <PricingFormSection />
          <ServiceDetailsFormSection />
          <TripBookedBySection />
          <TripDetailsFormSection />
        </AddATripFlowForm>
      </AddATripFlowDialog>
      <PassengerFormModal
        open={isEditingPassenger}
        setOpen={(open) => {
          dispatch(addATrip.actions.setIsEditingPassenger(open));
        }}
        passenger={passenger || undefined}
        handlePassengerAddEdit={(passenger) => {
          handlePassengerAddEdit(passenger);
        }}
      />
    </>
  );
}
