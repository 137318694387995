import * as Accordion from 'src/common/Accordion';
import { PricingType } from 'src/common/external/bambi-api/bambiApi';

import { PricingRuleAddButton } from './PricingRuleAddButton';
import { PricingRuleDetail } from './PricingRuleDetail';
import { PricingRulesEmptyState } from './PricingRulesEmptyState';

export function SelectedPricingTypeRules({
  pricingType,
}: {
  pricingType: PricingType;
}) {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex justify-between gap-x-1">
        <h2 className="text-lg font-medium">Rules</h2>
        <PricingRuleAddButton />
      </div>
      <Accordion.Root type="multiple">
        {pricingType.pricing_rules.length > 0 ? (
          <div className="flex flex-col gap-y-2">
            {pricingType.pricing_rules.map((rule) => (
              <PricingRuleDetail pricingRule={rule} key={rule.id} />
            ))}
          </div>
        ) : (
          <PricingRulesEmptyState />
        )}
      </Accordion.Root>
    </div>
  );
}
