import { DateTime } from 'luxon';
import { DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import { Popover } from 'src/common/primitives/Popover';

import { BrowserJSDateToOrgLuxonDateTime } from './BrowserJSDateToOrgLuxonDateTime';
import { OrgLuxonDateTimeToBrowserJSDate } from './OrgLuxonDateTimeToBrowserJSDate';

export function DatePicker({
  triggerElement,
  value,
  onChange,
  maxDate,
  minDate,
}: {
  triggerElement: React.ReactNode;
  value?: DateTime;
  onChange: (date: DateTime | undefined) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}) {
  let valueAsJSDate;
  let minDateAsJSDate;
  let maxDateAsJSDate;
  if (value) {
    valueAsJSDate = OrgLuxonDateTimeToBrowserJSDate(value);
  }

  if (minDate) {
    minDateAsJSDate = OrgLuxonDateTimeToBrowserJSDate(minDate);
  }

  if (maxDate) {
    maxDateAsJSDate = OrgLuxonDateTimeToBrowserJSDate(maxDate);
  }
  return (
    <>
      <style>{`
      .rdp-day_selected {
        background-color: #0CACA6;
      }
    `}</style>
      <Popover
        trigger={triggerElement}
        content={
          <DayPicker
            mode="single"
            selected={valueAsJSDate}
            onDayClick={(day) => {
              onChange(day ? BrowserJSDateToOrgLuxonDateTime(day) : undefined);
            }}
            fromDate={minDateAsJSDate}
            toDate={maxDateAsJSDate}
            today={OrgLuxonDateTimeToBrowserJSDate(DateTime.local())}
          />
        }
      ></Popover>
    </>
  );
}
