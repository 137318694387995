import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';

import { useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { setShowTagsModal, setTagSearchTerm } from './setting.slice';

export function SettingsRouteActions() {
  return (
    <Routes>
      <Route index element={<></>} />
      <Route path="tags" element={<TagRouteActions />} />
    </Routes>
  );
}

export function TagRouteActions() {
  const dispatch = useDispatch();
  const tagSearchTerm = useAppSelector((state) => state.setting.tagSearchTerm);

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setTagSearchTerm,
    tagSearchTerm
  );

  return (
    <div className="flex items-center gap-4">
      <SearchTypeahead
        dataTestId="tags-search"
        onChange={handleInputChange}
        placeholder="Search tags"
        value={inputValue}
      />
      <Button
        onClick={() => dispatch(setShowTagsModal(true))}
        variant="primary"
      >
        Add a Tag
      </Button>
    </div>
  );
}
