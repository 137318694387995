import { AssignmentTripLocation } from 'src/common/external/bambi-api/bambiApi';

import { mapLocationToMetadata } from './mapLocationToMetadata';

export function FormattedLocation({
  location,
}: {
  location: AssignmentTripLocation;
}) {
  const locationMetadata = mapLocationToMetadata(location);
  return (
    <div className="font-medium">
      {locationMetadata.displayName || locationMetadata.mappingAddress}
    </div>
  );
}
