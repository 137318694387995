import { Marker } from 'react-map-gl';

import { useAppSelector } from 'src/app/store';
import { MapPin } from 'src/common/BambiMapView/MapPin';
import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';

import { selectCurrentTripSummaryId } from './selectors/selectCurrentTripSummaryId';

export function TripEventMarker({
  tripEventMarker,
  onClick,
}: {
  tripEventMarker: TripEventRead;
  onClick?: () => void;
}) {
  const selectedTripEventId = useAppSelector(selectCurrentTripSummaryId);
  return (
    <Marker
      latitude={tripEventMarker.latitude ?? undefined}
      longitude={tripEventMarker.longitude ?? undefined}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        onClick?.();
      }}
    >
      <MapPin isSelected={tripEventMarker.id === selectedTripEventId} />
    </Marker>
  );
}
