import { formatPhoneNumber } from 'src/common/formatPhoneNumber';

export function PhoneNumberLink({
  phoneNumber,
}: {
  phoneNumber?: string | null;
}) {
  if (!phoneNumber) {
    return null;
  }

  return (
    <a
      className="font-medium text-gray-500 hover:underline dark:text-gray-400"
      href={`tel:${phoneNumber}`}
    >
      {formatPhoneNumber(phoneNumber)}
    </a>
  );
}
