export function PersonIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7667 13.4141C14.3982 12.5414 13.8635 11.7486 13.1924 11.0801C12.5234 10.4096 11.7308 9.87501 10.8584 9.50586C10.8506 9.50195 10.8428 9.5 10.835 9.49609C12.0518 8.61719 12.8428 7.18555 12.8428 5.57031C12.8428 2.89453 10.6749 0.726562 7.99907 0.726562C5.32329 0.726562 3.15532 2.89453 3.15532 5.57031C3.15532 7.18555 3.94634 8.61719 5.16314 9.49805C5.15532 9.50195 5.14751 9.50391 5.1397 9.50781C4.2647 9.87695 3.47954 10.4063 2.80571 11.082C2.13522 11.7511 1.60064 12.5437 1.23149 13.416C0.868842 14.27 0.673256 15.1856 0.655322 16.1133C0.654801 16.1341 0.658457 16.1549 0.666076 16.1743C0.673694 16.1937 0.685121 16.2114 0.699682 16.2263C0.714244 16.2412 0.731645 16.2531 0.750862 16.2612C0.770078 16.2693 0.79072 16.2734 0.811572 16.2734H1.98345C2.06938 16.2734 2.13774 16.2051 2.1397 16.1211C2.17876 14.6133 2.78423 13.2012 3.85454 12.1309C4.96196 11.0234 6.43267 10.4141 7.99907 10.4141C9.56548 10.4141 11.0362 11.0234 12.1436 12.1309C13.2139 13.2012 13.8194 14.6133 13.8584 16.1211C13.8604 16.207 13.9288 16.2734 14.0147 16.2734H15.1866C15.2074 16.2734 15.2281 16.2693 15.2473 16.2612C15.2665 16.2531 15.2839 16.2412 15.2985 16.2263C15.313 16.2114 15.3245 16.1937 15.3321 16.1743C15.3397 16.1549 15.3433 16.1341 15.3428 16.1133C15.3233 15.1797 15.1299 14.2715 14.7667 13.4141V13.4141ZM7.99907 8.92969C7.10259 8.92969 6.25884 8.58008 5.62407 7.94531C4.98931 7.31055 4.6397 6.4668 4.6397 5.57031C4.6397 4.67383 4.98931 3.83008 5.62407 3.19531C6.25884 2.56055 7.10259 2.21094 7.99907 2.21094C8.89556 2.21094 9.73931 2.56055 10.3741 3.19531C11.0088 3.83008 11.3584 4.67383 11.3584 5.57031C11.3584 6.4668 11.0088 7.31055 10.3741 7.94531C9.73931 8.58008 8.89556 8.92969 7.99907 8.92969Z"
        fill="#262626"
      />
    </svg>
  );
}
