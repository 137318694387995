import { ConfirmModal } from './modals/ConfirmModal';

export interface DeleteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  description?: string;
  loading?: boolean;
  error?: string;
  confirmText?: string;
  contentClassnames?: string;
  children?: React.ReactNode;
}

export function DeleteModal({
  open,
  setOpen,
  onCancel,
  onConfirm,
  title,
  description,
  loading,
  error,
  confirmText,
  contentClassnames,
  children,
}: DeleteModalProps) {
  return (
    <ConfirmModal
      confirmText={confirmText ?? 'Delete'}
      open={open}
      setOpen={setOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      description={description}
      loading={loading}
      error={error}
      mode="destructive"
      contentClassnames={contentClassnames}
    >
      {children}
    </ConfirmModal>
  );
}
