import { TextInput } from 'src/common/primitives/TextInput';
import { IconTooltip } from 'src/common/primitives/Tooltip';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

export function HelperTextPopover({
  text,
  isStatic,
  position,
}: {
  text: string;
  isStatic?: boolean;
  position?: string;
}) {
  return (
    <div className={isStatic ? '' : 'absolute top-2 right-2'}>
      <IconTooltip position={position}>{text}</IconTooltip>
    </div>
  );
}

export function FormFieldDefault<T>({
  inputProps,
  label,
  error,
  type,
  helperText,
  helperTextPosition,
  className,
  inline,
}: FormFieldProps<T>) {
  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      className={className}
      inline={inline}
      helperText={helperText}
      helperTextPosition={helperTextPosition}
    >
      <div className="relative flex items-center">
        <TextInput
          className={`block w-full rounded-md shadow-sm disabled:cursor-not-allowed disabled:text-gray-400 sm:text-sm ${
            error
              ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
              : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
          }`}
          type={type}
          {...inputProps}
          data-testid={inputProps.id}
        />
      </div>
    </DefaultFormFieldLayout>
  );
}
