import { upperFirst } from 'lodash-es';

import { AssignmentLocationRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

import { MemberPhoneNumberLink } from './MemberPhoneNumberLink';
import { PeopleIcon } from './PeopleIcon';
import { PhoneIcon } from './PhoneIcon';
import { VehicleIcon } from './VehicleIcon';

export function AssignmentPopupDriverAndVehicle({
  assignmentLocation,
}: {
  assignmentLocation: AssignmentLocationRead;
}) {
  return (
    <div className="flex flex-col items-start justify-start gap-[6px] self-stretch text-left text-sm text-gray-600">
      <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-teal-50 py-[3px] px-2 text-center text-xs text-gray-700">
        <div className="flex flex-row items-center justify-start">
          <div className="relative font-medium">Driver & Vehicle</div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start gap-[4px]">
        <div className="flex w-[157px] flex-row items-center justify-center gap-[4px]">
          <PeopleIcon />
          <div className="relative flex-1 font-medium">
            {formatFullName(assignmentLocation.driver)}
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[4px] text-xs">
          <PhoneIcon />
          <MemberPhoneNumberLink
            memberId={assignmentLocation.driver.membership}
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[8px]">
        <VehicleIcon />

        <div className="relative inline-block shrink-0">
          <div className="font-medium">
            {upperFirst(assignmentLocation.vehicle.category)}
          </div>
          <div className="text-slategray">
            {upperFirst(assignmentLocation.vehicle.make)}{' '}
            {assignmentLocation.vehicle.year}
          </div>
        </div>
      </div>
    </div>
  );
}
