import { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import {
  DispatchAssignedDriverRead,
  DriverCallRequestRead,
  useAssignmentsDriverCallRequestsResolvePartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { formatPhoneNumber } from 'src/common/formatPhoneNumber';
import {
  ConfirmModal,
  ConfirmModalProps,
} from 'src/common/modals/ConfirmModal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import { formatFullName } from 'src/common/util/formatFullName';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { PhoneIcon } from 'src/features/live-map/LiveMap/AssignmentLiveMapView/PhoneIcon';

import { useDispatchRepository } from '../common/useDispatchRepository';
import {
  dismissDispatchModal,
  useDispatchModalToShow,
} from '../dispatcher.slice';

export interface CallRequestModalInputs {
  assignmentId: string;
  driver: DispatchAssignedDriverRead;
  callRequest: DriverCallRequestRead;
}

export const CallRequestModal = () => {
  const props = useCallRequestModalProps();
  if (!props) return null;
  return (
    <ConfirmModal {...props}>
      {props.comment && (
        <p className="text-center text-sm text-gray-500">{`Comment: ${props.comment}`}</p>
      )}
      <a
        className="text-center text-xl font-medium text-gray-900 hover:underline"
        href={`tel:${props.phoneNumber}`}
      >
        {formatPhoneNumber(props.phoneNumber)}
      </a>
    </ConfirmModal>
  );
};

interface CallRequestModalProps extends ConfirmModalProps {
  comment?: string;
  phoneNumber: string;
}

export const useCallRequestModalProps = ():
  | CallRequestModalProps
  | undefined => {
  const dispatch = useAppDispatch();
  const modalToShow = useDispatchModalToShow();
  const { refetch } = useDispatchRepository();
  const { showSuccessToast } = useToaster();
  const inputs = useAppSelector(
    (state) => state.dispatcher.modals.callRequestInputs
  );
  const [callRequestError, setCallRequestError] = useState<
    string | undefined
  >();

  const dismissCallRequestModal = useCallback(() => {
    dispatch(dismissDispatchModal());
  }, [dispatch]);

  const [resolveCallRequest] =
    useAssignmentsDriverCallRequestsResolvePartialUpdateMutation();

  const onResolveCallRequest = useCallback(
    async (assignmentId: string, callRequestId: string) => {
      await resolveCallRequest({
        id: assignmentId,
        driverCallRequestId: callRequestId,
        patchedDriverCallRequest: {},
      }).unwrap();

      refetch();

      showSuccessToast();

      dismissCallRequestModal();
    },
    [dismissCallRequestModal, resolveCallRequest, showSuccessToast, refetch]
  );

  const onConfirm = useCallback(async () => {
    try {
      if (!inputs) {
        throw new Error('Call request inputs are missing.');
      }
      await onResolveCallRequest(inputs.assignmentId, inputs.callRequest.id);
    } catch (error) {
      const formattedError = formatServerError(error);
      setCallRequestError(formattedError);
    }
  }, [onResolveCallRequest, inputs]);

  if (!inputs) return undefined;

  return {
    open: modalToShow === 'callRequest',
    setOpen: (isOpen: boolean) => {
      if (!isOpen) dismissCallRequestModal();
    },
    title: 'Call Request',
    description: makeDescription(inputs.driver, inputs.callRequest),
    onConfirm,
    onCancel: dismissCallRequestModal,
    confirmText: 'Mark Completed',
    cancelText: 'Cancel',
    mode: 'neutral',
    error: callRequestError,
    icon: <PhoneIcon />,
    comment: inputs.callRequest.comment,
    phoneNumber: inputs.driver.phone_number,
  };
};

const makeDescription = (
  driver: DispatchAssignedDriverRead,
  callRequest: DriverCallRequestRead
) => {
  const driverName = formatFullName(driver);
  const requestTime = formatTimeFrom24HourTo12Hour(callRequest.created_at);
  return `${driverName} requested a call at ${requestTime}.`;
};

const useToaster = () => {
  const dispatch = useAppDispatch();
  const nextToastId = useAppSelector((state) => state.toast.nextId);

  const showSuccessToast = useCallback(() => {
    dispatch(
      show({
        id: nextToastId,
        title: 'Completed',
        description: 'The call request has been completed.',
        type: 'success',
      })
    );
  }, [dispatch, nextToastId]);

  return { showSuccessToast };
};
