import { useState } from 'react';

import { useFeature } from 'src/app/FeatureToggle';
import {
  useOrganizationSettingsPartialUpdateMutation,
  useOrganizationSettingsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';

export const useV1PricingAlert = () => {
  const [orgSettingsUpdating, setOrgSettingsUpdating] = useState(false);

  const { isEnabled: isPricingV1Enabled, isLoading: isLoadingPricingV1Flag } =
    useFeature('pricing version 1');
  const {
    data: organizationSettings,
    isLoading: isLoadingOrganizationSettings,
  } = useOrganizationSettingsRetrieveQuery({});
  const [updateOrganizationSettings] =
    useOrganizationSettingsPartialUpdateMutation();

  const shouldShowPricingV1Alert =
    !isLoadingOrganizationSettings &&
    !organizationSettings?.is_pricing_v1_enabled &&
    !isLoadingPricingV1Flag &&
    isPricingV1Enabled;

  const handleEnableNewPricing = async () => {
    setOrgSettingsUpdating(true);
    try {
      await updateOrganizationSettings({
        patchedOrganizationSettings: {
          is_pricing_v1_enabled: true,
        },
      });
    } catch (error) {
      console.error('Failed to enable new pricing', error);
    } finally {
      setOrgSettingsUpdating(false);
    }
  };

  return {
    shouldShowPricingV1Alert,
    orgSettingsUpdating,
    handleEnableNewPricing,
  };
};
