import { FormProvider, useForm } from 'react-hook-form';

import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';

import { AttendantSelectionField } from '../AttendantSelectionField';
import { DriverSelectionField } from '../DriverSelectionField';
import { VehicleSelectionField } from '../VehicleSelectionField';
import { defaultFormValues as defaultValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export function MultiAssignmentManagementFormStep1({
  onSubmit,
  onCancel,
  children,
  loading,
  serverErrors,
}: {
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  children?: React.ReactNode;
  loading: boolean;
  serverErrors: string | null;
}) {
  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, getValues } = methods;

  return (
    <FormProvider {...methods}>
      <form
        className="p-4"
        onSubmit={handleSubmit((val) => {
          onSubmit(getValues());
        })}
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <div id="form-errors">
          {!!serverErrors && (
            <FormErrorMessage>{serverErrors}</FormErrorMessage>
          )}
        </div>

        <div className="mt-4 flex max-w-xl flex-col gap-4 sm:min-w-[33em]">
          <VehicleSelectionField onlySchedulable />
          <DriverSelectionField onlySchedulable />
          <AttendantSelectionField onlySchedulable />
        </div>
        {children}
        <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Button
            className="sm:col-span-3"
            onClick={() => {
              onCancel();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            className="sm:col-span-3"
            type="submit"
            variant="primary"
            disabled={loading}
          >
            Next
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
