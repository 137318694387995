import { ReactNode } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

export type FormContextFieldRulesProps = {
  required?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  validate?: (value: any) => string | boolean | Promise<string | boolean>;
};

export function FormContextField({
  name,
  rules,
  children,
}: {
  name: string;
  rules?: FormContextFieldRulesProps;
  children: ({
    field,
    fieldState,
  }: {
    field: any;
    fieldState: any;
  }) => ReactNode;
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return <>{children({ field, fieldState })}</>;
      }}
    />
  );
}
