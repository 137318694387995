import { useCallback, useState } from 'react';

import { TrashIcon } from '@radix-ui/react-icons';

import { useAppDispatch } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import { useInvoicesInvoicesDestroyMutation } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

interface DeleteInvoiceTriggerProps {
  invoiceId: string;
  invoiceNumber: string;
  disabled?: boolean;
}
export function DeleteInvoiceTrigger({
  invoiceId,
  invoiceNumber,
  disabled,
}: DeleteInvoiceTriggerProps) {
  const dispatch = useAppDispatch();
  const [deleteInvoiceTrigger] = useInvoicesInvoicesDestroyMutation();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const handleDeleteInvoice = useCallback(
    async (id: string) => {
      try {
        await deleteInvoiceTrigger({
          id,
        });
        dispatch(
          show({
            type: 'success',
            title: 'Invoice deleted successfully',
          })
        );
        setIsConfirmingDelete(false);
      } catch (error) {
        dispatch(
          show({
            type: 'error',
            title: 'There was an error deleting the invoice.',
            description: formatServerError(error),
          })
        );
      }
    },
    [deleteInvoiceTrigger, dispatch]
  );

  return (
    <>
      <Button
        dataTestId={`delete-invoice-${invoiceId}`}
        variant="icon"
        onClick={() => setIsConfirmingDelete(true)}
        disabled={disabled}
        ariaLabel={`Delete Invoice: ${invoiceNumber}`}
        alt={disabled ? 'Invoices with payments cannot be deleted' : undefined}
      >
        <TrashIcon className="h-[24px] w-[24px]" />
      </Button>
      <DeleteModal
        open={isConfirmingDelete}
        setOpen={setIsConfirmingDelete}
        onCancel={() => setIsConfirmingDelete(false)}
        onConfirm={() => handleDeleteInvoice(invoiceId)}
        title={`Delete invoice number: ${invoiceNumber}`}
        description={`Are you sure you want to delete this invoice? This action cannot be undone.`}
      />
    </>
  );
}
