import { MapRef } from 'react-map-gl';

import { LatLong } from '../external/aws/LatLong';

export function fitBoundsToPoints(map: MapRef, points: LatLong[]) {
  const minLong = Math.min(...points.map((p) => p.long));
  const maxLong = Math.max(...points.map((p) => p.long));
  const minLat = Math.min(...points.map((p) => p.lat));
  const maxLat = Math.max(...points.map((p) => p.lat));
  map.fitBounds(
    [
      [minLong, minLat],
      [maxLong, maxLat],
    ],
    { padding: 200, duration: 1000 }
  );
}
