import { DateTime } from 'luxon';

import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import { TimeRange } from 'src/common/date/TimeRangePicker';

export function formatUnassignedTimeRange(
  timeRange: TimeRange,
  selectedDate: string
) {
  if (!timeRange.startTime && !timeRange.endTime) return '';
  const start = timeRange.startTime
    ? formatTimeFrom24HourTo12Hour(timeRange.startTime)
    : DateTime.fromISO(selectedDate).startOf('day').toFormat('t');
  const end = timeRange.endTime
    ? formatTimeFrom24HourTo12Hour(timeRange.endTime)
    : DateTime.fromISO(selectedDate).endOf('day').toFormat('t');
  return `${start} - ${end}`;
}
