import { RoleEnum } from 'src/common/external/bambi-api/bambiApi';
import { useIsRideOrderingPortalEnabledForOrganization } from 'src/features/rideOrderingPortal/useIsRideOrderingPortalEnabledForOrganization';

import { useUserRoles } from '../auth/useUserRoles';
import { getCreateableUserTypes } from './getCreateableUserTypes';

export function useCreateableUserTypes(roleOfUserBeingEdited?: RoleEnum) {
  const isRideOrderingPortalEnabledForOrganization =
    useIsRideOrderingPortalEnabledForOrganization();
  const { role } = useUserRoles();

  return getCreateableUserTypes(
    isRideOrderingPortalEnabledForOrganization,
    role,
    roleOfUserBeingEdited
  );
}
