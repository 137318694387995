import { useMemo, useState } from 'react';

interface Searchable {
  [key: string]: any;
}

// CAVEATS:
// 1. This hook is not suitable for data sets with
// more than one level of nested objects. See line 24.
// 2. This hook searches over all properties of the object
// and not just the ones that are visible in the UI.
const searchInString = (val: string, searchTerm: string): boolean => {
  const searchTerms = searchTerm
    .split(',')
    .map((term) => term.trim())
    .filter((term) => term);
  return searchTerms.some((term) =>
    val.toLowerCase().includes(term.toLowerCase())
  );
};

const searchInObject = (obj: Searchable, searchTerm: string): boolean => {
  return Object.values(obj).some((val) => {
    if (typeof val === 'string') {
      return searchInString(val, searchTerm);
    }
    if (typeof val === 'object' && val !== null) {
      return searchInObject(val, searchTerm);
    }
    return false;
  });
};

export const useClientSideSearch = <T extends Searchable>(data: T[]) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = useMemo(() => {
    if (!searchTerm.trim()) {
      return data;
    }

    return data.filter((obj) => searchInObject(obj, searchTerm));
  }, [data, searchTerm]);

  const handleSearchTermChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return { data: filteredData, searchTerm, handleSearchTermChange };
};
