import { calculateTimeDurationInMinutes } from './calculateTimeDurationInMinutes';
import { AssignmentFormValue } from './MultiAssignmentManagementFormStep2/FormValues';

export function makeAssignedDays(assignments: {
  [k: string]: AssignmentFormValue;
}) {
  return Object.keys(assignments)
    .map((dayKey) => {
      return {
        day: dayKey,
        start: assignments[dayKey].start,
        duration_minutes: calculateTimeDurationInMinutes(
          assignments[dayKey].start,
          assignments[dayKey].end
        ),
      };
    })
    .filter((t) => t.duration_minutes > 0);
}
