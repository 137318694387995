import { Avatar } from 'src/common/primitives/Avatar';
import { INamedEntity } from 'src/common/util/formatFullName';

export function PersonCell({ person }: { person: INamedEntity }) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-auto flex-shrink-0">
        <Avatar firstName={person.first_name} lastName={person.last_name} />
      </div>
      <div className="ml-4">
        <div className="font-medium text-gray-900">
          {person.first_name} {person.last_name}
        </div>
      </div>
    </div>
  );
}
