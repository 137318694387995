import { useMembersRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { Skeleton } from 'src/common/Skeleton';
import { PhoneNumberLink } from 'src/features/dispatch/DispatchCalendar/TripCalendarEvent/PhoneNumberLink';

export function MemberPhoneNumberLink({ memberId }: { memberId: string }) {
  const { data, isLoading } = useMembersRetrieveQuery({
    'Organization-ID': '1',
    id: memberId,
  });
  if (isLoading) {
    return <Skeleton />;
  }
  return <PhoneNumberLink phoneNumber={data?.phone_number} />;
}
