import {
  AssignedAttendantRead,
  AttendantRead,
} from 'src/common/external/bambi-api/bambiApi';

export const convertAssignedAttendantToAttendant = (
  assignedAttendant: AssignedAttendantRead
): AttendantRead => {
  return {
    id: assignedAttendant.attendant || '',
    first_name: assignedAttendant.first_name,
    last_name: assignedAttendant.last_name,
    membership: assignedAttendant.membership,
  } as AttendantRead;
};
