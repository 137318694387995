import { useAppDispatch } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { onPricingRuleEditChange } from 'src/features/pricingModel/pricingModel.slice';

export default function PricingRulePercentageInput({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  return (
    <div className="flex items-center">
      <label
        htmlFor="percentage"
        className="mr-2 block text-sm font-medium leading-6 text-gray-700"
      >
        Percentage:
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="number"
          name="percentage"
          id="percentage"
          className="focus:ring-indigo-600 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          value={pricingRule.percentage}
          onChange={(e) => {
            const percentage = parseInt(e.target.value);
            dispatch(
              onPricingRuleEditChange({
                ...pricingRule,
                percentage,
              })
            );
          }}
          data-testid="pricing-rule-percentage"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          %
        </div>
      </div>
    </div>
  );
}
