import { Select, SelectOption } from 'src/common/primitives/Select';

import { useRejectionReasonOptions } from './useRejectionReasonOptions';

type RejectionReasonSelectProps = {
  tripId: string;
  onChange: (value: SelectOption | undefined) => void;
};

export function RejectionReasonSelect({
  tripId,
  onChange = () => {},
}: RejectionReasonSelectProps) {
  const options = useRejectionReasonOptions(tripId);
  return (
    <Select
      options={options}
      disabled={options.length === 0}
      placeholder={
        options.length === 0 ? 'Loading options...' : 'Select a reason'
      }
      onChange={onChange}
    />
  );
}
