import { DateTime } from 'luxon';

import { Assignment } from 'src/common/external/bambi-api/bambiApi';

export const vehicleHasPastAssignment = (
  vehicleId: string,
  assignments: Assignment[],
  date: DateTime
) => {
  if (date.startOf('day') >= DateTime.now().startOf('day')) {
    return true;
  }

  const assignment = assignments.find(
    (assignment) => assignment.vehicle.vehicle === vehicleId
  );

  return !!assignment;
};
