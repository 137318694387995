import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import { DataGrid } from 'src/common/DataGrid';
import {
  AssignmentRead,
  DispatchTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { Badge } from 'src/common/primitives/Badge';
import { formatFullName } from 'src/common/util/formatFullName';

// TODO: There's a function isDispatchTrip in the dispatcher module,
// but it doesn't handle TripRead types -- I started to consolidate
// but createEventsFromTripWaitTimes didn't like it and it looks like
// a bigger refactor
// I think I'd rather move to a path where we resolve to a TripRead
// or at the very least just testing for what fields we want to use
function isDispatchTrip(
  trip: DispatchTripRead | TripRead
): trip is DispatchTripRead {
  return 'pickup_address' in trip;
}

const columnHelper = createColumnHelper<TripRead>();
const noAssignmentColumns = [
  columnHelper.accessor('scheduled_pickup_at', {
    header: 'Pickup Time',
    cell: ({ getValue }) => (
      <Badge
        label={DateTime.fromISO(getValue()).toLocaleString(
          DateTime.TIME_SIMPLE
        )}
        color="blue-dark"
      />
    ),
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('passenger.full_name', {
    header: 'Passenger',
    cell: ({ getValue }) => <div className="font-bold">{getValue()}</div>,
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    header: 'Pickup Address',
    cell: ({ row }) => {
      const pickupAddress = isDispatchTrip(row.original)
        ? row.original.pickup_address
        : row.original.pickup.address;

      return <>{formatAddress(pickupAddress)}</>;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    header: 'Dropoff Address',
    cell: ({ row }) => {
      const dropoffAddress = isDispatchTrip(row.original)
        ? row.original.dropoff_address
        : row.original.dropoff.address;

      return <>{formatAddress(dropoffAddress)}</>;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
];

function buildWithAssignmentColumns(assignment: AssignmentRead) {
  return [
    ...noAssignmentColumns,
    columnHelper.display({
      header: 'Assignment',
      cell: () => {
        return <div>{formatFullName(assignment.driver)}</div>;
      },
      meta: {
        enableHeaderMenu: false,
      },
    }),
  ];
}

interface AcceptRequestTripDetailsProps {
  trips: TripRead[];
  assignment?: AssignmentRead;
}

export function AcceptRequestTripDetails({
  trips,
  assignment,
}: AcceptRequestTripDetailsProps) {
  return (
    <div data-testid="request-trip-details">
      <DataGrid
        tableId="request-trip-details"
        containerClassName="max-h-[50vh]"
        data={trips}
        columns={
          assignment
            ? buildWithAssignmentColumns(assignment)
            : noAssignmentColumns
        }
        includePagination={false}
        dataTestId="request-trip-details-table"
        emptyState={<div>No trips found</div>}
      />
    </div>
  );
}
