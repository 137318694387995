import { useEffect, useState } from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DateTime } from 'luxon';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import { DropdownMenuContent } from 'src/common/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import {
  TripSubscriptionRead,
  useLazyPassengersRetrieveQuery,
  useTripsSubscriptionsDestroyMutation,
} from 'src/common/external/bambi-api/bambiApi';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import {
  setOpenMenuId,
  setSelectedTripSubscription,
  setShowTripSubscriptionModal,
} from '../trip-subscriptions.slice';

export const RowActions = ({
  tripSubscription,
}: {
  tripSubscription: TripSubscriptionRead;
}) => {
  const { isAdmin, isOwner } = useUserRoles();
  const canDelete = isOwner || isAdmin;
  const dispatch = useAppDispatch();

  const { isLoading, isEnabled } = useFeature('trip subscription update');

  const openMenuId = useAppSelector(
    (state) => state.tripSubscription.openMenuId
  );

  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [deleteTripSubscription] = useTripsSubscriptionsDestroyMutation();
  const [fetchPassenger] = useLazyPassengersRetrieveQuery();

  const handleViewEditSubscription = async () => {
    if (!tripSubscription.passenger.id) return; // this should not happen

    const passenger = await fetchPassenger({
      id: tripSubscription.passenger.id,
    }).unwrap();

    dispatch(setSelectedTripSubscription({ tripSubscription, passenger }));

    dispatch(setShowTripSubscriptionModal(true));
  };

  const onEndTripSubscription = async (isDelete: boolean) => {
    setIsSubmitting(true);
    setError(undefined);
    try {
      await deleteTripSubscription({
        id: tripSubscription.id,
        delete: isDelete,
      }).unwrap();
      setIsConfirmingCancel(false);
      setIsConfirmingDelete(false);
    } catch (error) {
      const formattedError = formatServerError(error);
      setError(formattedError);
    }
    setIsSubmitting(false);
  };

  const endingStartDate = DateTime.now()
    .plus({ days: 1 })
    .toLocaleString(DateTime.DATE_SHORT);

  useEffect(() => {
    if (openMenuId === tripSubscription.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openMenuId, tripSubscription.id]);

  return (
    <div className="flex justify-end pr-2">
      <DropdownMenu.Root
        open={open}
        onOpenChange={(val) => {
          if (!val) dispatch(setOpenMenuId(''));
        }}
      >
        <DropdownMenu.Trigger
          data-testid="trip-subscription-row-actions-icon"
          asChild
        >
          <EllipsisVerticalIcon
            className="h-4 cursor-pointer"
            onClick={() => dispatch(setOpenMenuId(tripSubscription.id))}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenuContent side="bottom">
            {!isLoading && isEnabled && (
              <DropdownMenuItem
                data-testid="view-edit-trip-subscription-action"
                onClick={handleViewEditSubscription}
              >
                View/Edit Subscription
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              data-testid="trip-subscription-cancel-action"
              onClick={() => {
                setError(undefined);
                setIsConfirmingCancel(true);
              }}
            >
              Cancel Subscription
            </DropdownMenuItem>
            {canDelete && (
              <DropdownMenuItem
                data-testid="trip-subscription-delete-action"
                onClick={() => {
                  setError(undefined);
                  setIsConfirmingDelete(true);
                }}
              >
                Delete Subscription
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <DeleteModal
        open={isConfirmingCancel || isConfirmingDelete}
        setOpen={(val) => {
          setIsConfirmingCancel(val);
          setIsConfirmingDelete(val);
        }}
        title="End Trip Subscription"
        confirmText={isConfirmingDelete ? 'Delete ' : 'Confirm'}
        description={`Are you sure you want to end this trip subscription?  
        Any unassigned trip associated with this subscription and a scheduled pickup
        at ${endingStartDate} or after will be ${
          isConfirmingDelete ? 'deleted' : 'canceled'
        }.`}
        onCancel={() => {
          setIsConfirmingCancel(false);
          setIsConfirmingDelete(false);
        }}
        onConfirm={() => onEndTripSubscription(isConfirmingDelete)}
        error={error}
        loading={isSubmitting}
      ></DeleteModal>
    </div>
  );
};
