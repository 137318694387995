import { PagePillButton } from './PagePillButton';

export function PagePillRange({
  range,
  currentPage,
  setCurrentPage,
}: {
  range: number[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
}) {
  return (
    <>
      {range
        .sort(function (a, b) {
          return a - b;
        })
        .map((pageNumber) => {
          return (
            <PagePillButton
              key={pageNumber}
              pageNumber={pageNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          );
        })}
    </>
  );
}
