import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Popover } from 'src/common/primitives/Popover';
import { useTripPriceEstimation } from 'src/features/add-trip/TripDetails/TripInputPrice/useTripPriceEstimation';

import { PriceEstimationBreakdownRow } from './PriceEstimationBreakdownRow';

export function PriceEstimationBreakdownPopover({
  tripIndex,
}: {
  tripIndex: number;
}) {
  const priceEstimationState = useTripPriceEstimation({ tripIndex });

  const pricing_results = priceEstimationState.result
    ? priceEstimationState.result.pricing_results
    : null;

  return (
    <Popover
      trigger={<CurrencyDollarIcon className="h-8 w-8 hover:cursor-pointer" />}
      content={
        <div>
          {priceEstimationState.isCalculating && <LoadingIndicator />}
          {!priceEstimationState.isCalculating && pricing_results && (
            <div className="max-w-xs">
              <div className="grid grid-cols-4 gap-2 border-b border-gray-200">
                <div className="col-span-2"></div>
                <div className="px-2 py-2 text-sm font-medium text-gray-900">
                  Quantity
                </div>
                <div className="px-2 py-2 text-sm font-medium text-gray-900">
                  Total
                </div>
              </div>

              <PriceEstimationBreakdownRow
                label="Base"
                quantity="1"
                price={defaultCurrencyFormat(
                  pricing_results.effective_base_price
                )}
              />
              <PriceEstimationBreakdownRow
                label="Loaded Mileage"
                quantity={pricing_results.effective_loaded_miles.toFixed(1)}
                price={defaultCurrencyFormat(
                  pricing_results.effective_loaded_mileage_price
                )}
              />
              <PriceEstimationBreakdownRow
                label="Unloaded Mileage"
                quantity={pricing_results.effective_unloaded_miles.toFixed(1)}
                price={defaultCurrencyFormat(
                  pricing_results.effective_unloaded_mileage_price
                )}
              />
              <PriceEstimationBreakdownRow
                label="Total"
                quantity=""
                price={defaultCurrencyFormat(pricing_results.total_price)}
                bold
              />
            </div>
          )}
        </div>
      }
    />
  );
}
