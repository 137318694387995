import { useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import {
  TripTripTagRead,
  useTripTagsDestroyMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { TripTag } from 'src/common/Tag/Tag';
import { useAuth } from 'src/features/auth/useAuth';

interface TripTagsDisplayProps {
  tags?: TripTripTagRead[] | null;
}

export default function TripTagsDisplay({
  tags,
}: TripTagsDisplayProps): JSX.Element | null {
  const auth = useAuth();
  const [deleteTag] = useTripTagsDestroyMutation();

  const [localTags, setLocalTags] = useState<TripTripTagRead[] | null>(null);

  useEffect(() => {
    if (tags) {
      setLocalTags(tags);
    }
  }, [tags]);

  if (!tags) {
    return null;
  }

  const handleDeleteTag = async (tag: TripTripTagRead) => {
    try {
      await deleteTag({
        'Organization-ID': auth.currentOrganizationId,
        id: tag.id,
      });

      const newTags = localTags?.filter((t) => t.id !== tag.id);
      setLocalTags(newTags || null);
    } catch (error) {
      // TODO: handle error
    }
  };

  return (
    <div className="flex flex-wrap">
      {localTags?.map((tag) => {
        return (
          <div className="m-1" key={tag.id}>
            <TripTag
              tag={tag}
              icon={<XMarkIcon className="h-4 w-4 cursor-pointer" />}
              onIconClick={handleDeleteTag}
            />
          </div>
        );
      })}
    </div>
  );
}
