import { uniq } from 'lodash-es';

type PaginationRangeState = {
  leftRange: number[];
  rightRange: number[];
  middleRange: number[];
  shouldShowLeftDots: boolean;
  shouldShowRightDots: boolean;
};

function renderPaginationStateForLeftSideCurrentPage(
  cursorRange: number[],
  totalPages: number,
  endBuffer = 4
): PaginationRangeState {
  const leftRange = [1, 2, 3];
  return {
    leftRange: uniq(leftRange.concat(cursorRange))
      .slice(0, endBuffer)
      .sort(function (a, b) {
        return a - b;
      }),
    middleRange: [],
    rightRange: [totalPages],
    shouldShowLeftDots: false,
    shouldShowRightDots: true,
  };
}

function renderPaginationStateForRightSideCurrentPage(
  cursorRange: number[],
  totalPages: number,
  endBuffer = 4
): PaginationRangeState {
  const rightRange = [totalPages - 2, totalPages - 1, totalPages];
  return {
    leftRange: [1],
    middleRange: [],
    rightRange: uniq(cursorRange.concat(rightRange))
      .slice(-endBuffer)
      .sort(function (a, b) {
        return a - b;
      }),
    shouldShowLeftDots: true,
    shouldShowRightDots: false,
  };
}

function renderPaginationStateForMiddleCurrentPage(
  cursorRange: number[],
  totalPages: number
): PaginationRangeState {
  return {
    leftRange: [1],
    rightRange: [totalPages],
    middleRange: cursorRange,
    shouldShowLeftDots: cursorRange[0] > 2,
    shouldShowRightDots: cursorRange[2] < totalPages - 1,
  };
}

function renderSmallRangeState(totalPages: number): PaginationRangeState {
  return {
    leftRange: Array.from({ length: totalPages }, (_, i) => i + 1),
    middleRange: [],
    rightRange: [],
    shouldShowLeftDots: false,
    shouldShowRightDots: false,
  };
}

function buildCursorRange(currentPage: number, totalPages: number): number[] {
  const safeCurrentPage = Math.max(1, Math.min(currentPage, totalPages));
  return [
    safeCurrentPage > 1 ? safeCurrentPage - 1 : safeCurrentPage,
    safeCurrentPage,
    safeCurrentPage < totalPages ? safeCurrentPage + 1 : safeCurrentPage,
  ];
}

/**
 *
 * If we have "a lot" of pages we want to abbreviate the pagination with ellipses.
 *
 * We want to always show the first page, the last page, and the current page with previous and next pages.
 *
 * If the current page is on the left side, we'll only show dots on the right side of the current page.
 * If the current page is on the right side, we'll only show dots on the left side of the current page.
 * If the current page is in the middle, we'll show dots on both sides of the current page.
 *
 */
export function getPaginationRangeState(
  currentPage: number,
  totalPages: number,
  endBuffer = 4
): PaginationRangeState {
  if (totalPages <= 5) {
    return renderSmallRangeState(totalPages);
  }

  const cursorRange = buildCursorRange(currentPage, totalPages);

  const isLeftSideCurrentPage = currentPage < endBuffer;
  if (isLeftSideCurrentPage) {
    return renderPaginationStateForLeftSideCurrentPage(
      cursorRange,
      totalPages,
      endBuffer
    );
  }

  const isRightSideCurrentPage = currentPage > totalPages - endBuffer + 1;
  if (isRightSideCurrentPage) {
    return renderPaginationStateForRightSideCurrentPage(
      cursorRange,
      totalPages,
      endBuffer
    );
  }

  return renderPaginationStateForMiddleCurrentPage(cursorRange, totalPages);
}
