import { useCallback } from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { DropdownMenuBasic } from 'src/common/DropdownMenu/DropdownMenuBasic';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { DriverCallRequestRead } from 'src/common/external/bambi-api/bambiApi';
import {
  DispatchModalKey,
  setModalAssignmentId,
  showDispatchModal,
} from 'src/features/dispatch/dispatcher.slice';

import {
  useAssignmentInfo,
  useCallRequestHandler,
  useReactivateAssignment,
} from './hooks';

interface AssignmentHeaderMenuProps {
  assignmentId: string;
  pendingCallRequest?: DriverCallRequestRead;
}

export function AssignmentHeaderMenu({
  assignmentId,
  pendingCallRequest,
}: AssignmentHeaderMenuProps) {
  const dispatch = useDispatch();
  const { openCallRequestModal } = useCallRequestHandler();
  const { driverHasTrips } = useAssignmentInfo(assignmentId);
  const { reactivateAssignment } = useReactivateAssignment(assignmentId);

  const onClickMenuItem = useCallback(
    (key: DispatchModalKey) => {
      if (key === 'callRequest' && pendingCallRequest) {
        openCallRequestModal(pendingCallRequest, assignmentId);
      } else {
        dispatch(setModalAssignmentId(assignmentId));
        dispatch(showDispatchModal(key));
      }
    },
    [openCallRequestModal, dispatch, assignmentId, pendingCallRequest]
  );

  return (
    <>
      <DropdownMenuBasic
        trigger={
          <EllipsisVerticalIcon
            className="h-4 w-4"
            data-testid="event-menu-trigger"
          />
        }
      >
        <MenuItem
          label="Handle call request"
          condition={!!pendingCallRequest}
          onClick={() => onClickMenuItem('callRequest')}
        />
        <MenuItem
          label="Add a break"
          onClick={() => onClickMenuItem('assignmentBreak')}
        />
        <MenuItem
          label="Unassign all trips"
          condition={driverHasTrips}
          onClick={() => onClickMenuItem('unassign')}
        />
        <MenuItem
          label="Reactivate assignment"
          condition={!!reactivateAssignment}
          onClick={() => reactivateAssignment?.()}
        />
      </DropdownMenuBasic>
    </>
  );
}

interface MenuItemInputs {
  label: string;
  condition?: boolean;
  onClick: () => void;
}

const MenuItem = ({ label, condition, onClick }: MenuItemInputs) => {
  const shouldRender = condition ?? true;
  const testId =
    label.toLowerCase().replace(new RegExp(' ', 'g'), '-') + '-item';
  return shouldRender ? (
    <DropdownMenuItem data-testid={testId} onClick={onClick}>
      {label}
    </DropdownMenuItem>
  ) : null;
};
