import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';

export interface AlertProps {
  title: string;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
  action?: React.ReactNode;
}

const SEVERITY_STYLES = {
  error: {
    bg: 'bg-red-50',
    border: 'border-red-400',
    icon: 'text-red-400',
    title: 'text-red-800',
    message: 'text-red-700',
    IconComponent: ExclamationCircleIcon,
  },
  info: {
    bg: 'bg-blue-50',
    border: 'border-blue-400',
    icon: 'text-blue-400',
    title: 'text-blue-800',
    message: 'text-blue-700',
    IconComponent: InformationCircleIcon,
  },
  success: {
    bg: 'bg-green-50',
    border: 'border-green-400',
    icon: 'text-green-400',
    title: 'text-green-800',
    message: 'text-green-700',
    IconComponent: CheckCircleIcon,
  },
  warning: {
    bg: 'bg-yellow-50',
    border: 'border-yellow-400',
    icon: 'text-yellow-400',
    title: 'text-yellow-800',
    message: 'text-yellow-700',
    IconComponent: ExclamationTriangleIcon,
  },
};

export function Alert({ title, message, severity, action }: AlertProps) {
  const {
    bg,
    border,
    icon,
    title: titleClass,
    message: messageClass,
    IconComponent,
  } = SEVERITY_STYLES[severity];

  return (
    <div className={`rounded-md ${bg} ${border} border p-4`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <IconComponent aria-hidden="true" className={`h-5 w-5 ${icon}`} />
        </div>
        <div className="ml-3 flex-1">
          <h3 className={`text-sm font-medium ${titleClass}`}>{title}</h3>
          <div className={`mt-2 text-sm ${messageClass}`}>
            <p>{message}</p>
          </div>
        </div>
        {action && (
          <div className="ml-3 flex flex-shrink-0 items-center">{action}</div>
        )}
      </div>
    </div>
  );
}
