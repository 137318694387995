import { TripTripTagRead } from 'src/common/external/bambi-api/bambiApi';

export const convertTripTagsToSelectOptionWithColor = (
  tags: TripTripTagRead[]
) => {
  return tags.map((tag) => ({
    label: tag.tag_name,
    value: tag.tag,
    color: tag.color_hex,
  }));
};
