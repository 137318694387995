import { DateTime } from 'luxon';

import { useAppDispatch } from 'src/app/store';
import { convertClientDateTimeToServerISO } from 'src/common/date/convertClientDateTimeToServerISO';
import DateRangePicker from 'src/common/date/DateRangePicker';

import { fleetInspectionSlice } from '../fleetInspection.slice';

interface InspectionDateColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function InspectionDateColumnFilter({
  open,
  onClose,
}: InspectionDateColumnFilterProps) {
  const dispatch = useAppDispatch();
  return (
    <DateRangePicker
      open={open}
      onClose={onClose}
      triggerElement={<div />}
      onDateRangeChange={(newDateRange) => {
        if (!(newDateRange.from && newDateRange.to)) return;
        const fromDateTime = convertClientDateTimeToServerISO(
          DateTime.fromJSDate(newDateRange.from).startOf('day')
        );
        const toDateTime = convertClientDateTimeToServerISO(
          DateTime.fromJSDate(newDateRange.to).endOf('day')
        );
        dispatch(
          fleetInspectionSlice.actions.onInspectionDateFilterChange({
            rangeStart: fromDateTime,
            rangeEnd: toDateTime,
          })
        );
      }}
    />
  );
}
