import { useEffect, useMemo, useState } from 'react';

import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  InvoicesInvoicesListApiArg,
  useInvoicesInvoicesListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { columns } from './invoiceColumns';
import { InvoiceEmptyState } from './InvoiceEmptyState';

export function InvoiceDataGrid() {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });
  const rangeStart = useAppSelector((state) => state.invoice.filterDateStart);
  const rangeEnd = useAppSelector((state) => state.invoice.filterDateEnd);
  const hasPayments = useAppSelector(
    (state) => state.invoice.filterHasPayments
  );
  const searchTerm = useAppSelector((state) => state.invoice.filterSearchTerm);
  const selectedTripPayers = useAppSelector(
    (state) => state.invoice.filterTripPayers
  );
  const selectedPaymentStatus = useAppSelector(
    (state) => state.invoice.filterPaymentStatus
  );
  const totalAmountRange = useAppSelector(
    (state) => state.invoice.filterTotalAmount
  );
  const totalAmountDueRange = useAppSelector(
    (state) => state.invoice.filterTotalAmountDue
  );

  const {
    data: invoices,
    isLoading,
    refetch,
  } = useInvoicesInvoicesListQuery({
    page: pageIndex + 1,
    dateIssuedStart: rangeStart,
    dateIssuedEnd: rangeEnd,
    minPayments: hasPayments ? 1 : undefined,
    search: searchTerm ?? undefined,
    ordering: useSearchParams()[0].get(
      'ordering'
    ) as InvoicesInvoicesListApiArg['ordering'],
    tripPayerIn: selectedTripPayers,
    overallPaymentStatusIn: selectedPaymentStatus,
    totalAmountMin: isNaN(totalAmountRange?.min ?? NaN)
      ? undefined
      : totalAmountRange?.min ?? 0,
    totalAmountMax: isNaN(totalAmountRange?.max ?? NaN)
      ? undefined
      : totalAmountRange?.max ?? 0,
    dueMin: isNaN(totalAmountDueRange?.min ?? NaN)
      ? undefined
      : totalAmountDueRange?.min ?? 0,
    dueMax: isNaN(totalAmountDueRange?.max ?? NaN)
      ? undefined
      : totalAmountDueRange?.max ?? 0,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [refetch]);

  return (
    <>
      {isLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <DataGrid
            tableId="invoice"
            dataTestId="invoice-data-grid"
            columns={columns}
            data={invoices?.results || []}
            pagination={pagination}
            totalCount={invoices?.count || 0}
            handlePaginationChange={handlePaginationChange}
            emptyState={<InvoiceEmptyState />}
            getRowId={(row) => row.id}
          />
        </>
      )}
    </>
  );
}
