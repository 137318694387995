import { Passenger } from 'src/common/external/bambi-api/bambiApi';
import { formatPhoneNumber } from 'src/common/formatPhoneNumber';

export function formatPassengerLabel(passenger: Passenger) {
  const baseNameComponent = `${passenger.first_name} ${passenger.last_name}`;
  const phoneNumberComponent = passenger.phone_number
    ? ` ${formatPhoneNumber(passenger.phone_number)}`
    : '';
  const dobComponent = passenger.dob ? ` (DOB: ${passenger.dob})` : '';
  const weightComponent = passenger.weight
    ? ` (Weight: ${passenger.weight} lb.)`
    : '';
  const bariatricComponent = passenger.needs_bariatric_transport
    ? ` (Bariatric)`
    : '';

  const textLabel = (
    baseNameComponent +
    phoneNumberComponent +
    dobComponent +
    weightComponent +
    bariatricComponent
  ).trim();

  return textLabel;
}
