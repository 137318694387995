import { useEffect, useMemo, useState } from 'react';

import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';

import { DEFAULT_PAGINATION_PAGE_SIZE } from '../external/bambi-api/constants';

export function usePagination(refetch: (state: PaginationState) => unknown) {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
  }, []);

  useEffect(() => {
    refetch(pagination);
  }, [refetch, pagination]);

  return {
    pagination,
    handlePaginationChange,
  };
}
