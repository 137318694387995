import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';
import RomAllRequestsManagementRoute from 'src/features/trip/rom/all-requests/management/RomAllRequestsManagementRoute';

export const romAllRequestsRoutes: RouteObject[] = [
  {
    index: true,
    element: <RomAllRequestsManagementRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute
        routePath="/trips/requests"
        linkText="Go back to trip requests"
      />
    ),
  },
];
