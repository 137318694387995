import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppSelector } from 'src/app/store';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { setPassengerSearchTerm } from '../passenger.slice';

interface PassengerSearchFormProps {
  searchAction?: ActionCreatorWithPayload<string, string>;
}

export function PassengerSearchForm({
  searchAction = setPassengerSearchTerm,
}: PassengerSearchFormProps) {
  const passengerSearchTerm = useAppSelector(
    (state) => state.passenger.passengerSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    searchAction,
    passengerSearchTerm
  );

  return (
    <SearchTypeahead
      onChange={handleInputChange}
      placeholder="Search passengers"
      value={inputValue}
    />
  );
}
