import React from 'react';

import { TextInput } from 'src/common/primitives/TextInput';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

export const DEFAULT_TIME_FORMAT = 'hh:mm a';
export const LOCALIZED_24H_TIME_FORMAT = 'T';

export const FormFieldTime = React.forwardRef(function _FormFieldTime<T>(
  {
    inputProps,
    onChange,
    label,
    error,
    disabled,
    inline,
    helperText,
  }: FormFieldProps<T>,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      inline={inline}
      helperText={helperText}
    >
      <div className="flex items-center gap-2">
        <TextInput
          {...inputProps}
          onChange={(e) => {
            onChange?.(e.target.value);
          }}
          type="time"
          ref={forwardedRef}
          autoFocus={false}
          placeholder={DEFAULT_TIME_FORMAT}
          // prevent typing in anything not timey wimey
          // https://digitalfortress.tech/tips/top-15-commonly-used-regex/
          pattern="/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/"
          data-testid={inputProps.id || 'time-input'}
          disabled={disabled}
          className={`block w-full rounded-md  shadow-sm disabled:cursor-not-allowed sm:text-sm ${
            error
              ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
              : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
          }`}
        />
      </div>
    </DefaultFormFieldLayout>
  );
});
