import { FormProvider, useForm } from 'react-hook-form';

import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Button } from 'src/common/primitives/Button';

import { Crew } from '../Crew';
import { WeeklyAssignmentEditor } from '../WeeklyAssignmentEditor';
import { defaultFormValues as defaultValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export function MultiAssignmentManagementFormStep2({
  onSubmit,
  onCancel,
  children,
  loading,
  serverErrors,
  crew,
}: {
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  children?: React.ReactNode;
  loading: boolean;
  serverErrors: string | null;
  crew?: Crew;
}) {
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      ...crew,
    },
  });

  const { handleSubmit, formState, getValues } = methods;

  return (
    <FormProvider {...methods}>
      <form
        className="p-4"
        onSubmit={handleSubmit((val) => {
          onSubmit(getValues());
        })}
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <div id="form-errors">
          {!!serverErrors && (
            <FormErrorMessage>{serverErrors}</FormErrorMessage>
          )}
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <WeeklyAssignmentEditor />
          <h3 className="font-bold">
            What dates would you like to apply these assignments to?
          </h3>
          <FormContextField name={`applyDateRange.start`}>
            {({ field }) => {
              return (
                <FormField
                  type="date"
                  label="Range Start"
                  inputProps={{
                    id: `applyDateRange-start-date`,
                    value: field.value,
                  }}
                  onChange={field.onChange}
                />
              );
            }}
          </FormContextField>
          <FormContextField name={`applyDateRange.end`}>
            {({ field }) => {
              return (
                <FormField
                  type="date"
                  label="Range End (Max 2 months from start)"
                  inputProps={{
                    id: `applyDateRange-end-date`,
                    value: field.value,
                  }}
                  onChange={field.onChange}
                />
              );
            }}
          </FormContextField>
        </div>
        {children}
        <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Button
            className="sm:col-span-3"
            onClick={() => {
              onCancel();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            className="sm:col-span-3"
            type="submit"
            variant="primary"
            disabled={!formState.isValid || loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
