import { useAssignmentsBulkDefaultVehicleDriverListQuery } from 'src/common/external/bambi-api/bambiApi';

export function useDefaultAssignmentSchedule({
  driverId = '',
  vehicleId = '',
  attendantIds = [],
}: {
  driverId: string;
  vehicleId: string;
  attendantIds: string[];
}) {
  const response = useAssignmentsBulkDefaultVehicleDriverListQuery(
    {
      'Organization-ID': 'string',
      attendantIds: attendantIds.join(',') || undefined,
      driverId,
      vehicleId,
    },
    {
      skip: !(driverId && vehicleId),
    }
  );
  return response;
}
