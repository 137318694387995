import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  useAssignmentsCreateMutation,
  useAssignmentsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useAuth } from 'src/features/auth/useAuth';

import { assignmentManagementSlice } from '../../assignmentManagement.slice';
import { generateAddSuccessToast } from '../generateAddSuccessToast';
import { generateEditSuccessToast } from '../generateEditSuccessToast';
import { convertFormValuesToAssignment } from './convertFormValuesToAssignment';
import { validateAllAssignmentsOnSubmit } from './validations';

export const useAssignmentFormOnSubmit = ({
  getValues,
}: {
  getValues: () => any;
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const nextToastId = useSelector((state: RootState) => state.toast.nextId);
  const selectedAssignment = useSelector(
    (state: RootState) => state.assignmentManagement.selectedAssignment
  );

  const [createAssignments] = useAssignmentsCreateMutation();
  const [updateAssignment] = useAssignmentsPartialUpdateMutation();
  return async (values: any) => {
    try {
      dispatch(assignmentManagementSlice.actions.submit());
      const currentValues = getValues();
      const formError = validateAllAssignmentsOnSubmit(currentValues);

      if (formError) {
        dispatch(assignmentManagementSlice.actions.onError(formError));
        return;
      }

      // TODO: Need to fix up types here. Create expects Assignment
      // Update expects PatchedAssignment
      // The api ignores the extra fields on AssignmentRead that it currently uses
      // so I'll change this in a followup
      const assignments = convertFormValuesToAssignment(currentValues);

      if (!selectedAssignment?.id) {
        // Since this isn't an upsert, don't send any assignments with an id to avoid error
        // TODO: This is for the cloning flow so that we show where the assignment being cloned is coming from
        // when cloning from the Edit View
        // The assignment add/edit flow is weird because we wanted to simplify the work needed
        // We should revisit the ux as a whole and probably treat it more as a calendar
        // I'd rather not simplify the clone flow to not allow editing of whatever assignments the vehicle has.
        // In fact, the edit view should probably the same days as the add view.
        const newAssignments = assignments.filter((x) => !x.id);
        await createAssignments({
          assignmentBulkCreate: { assignments: newAssignments },
          'Organization-ID': auth.currentOrganizationId || '',
        }).unwrap();

        dispatch(show(generateAddSuccessToast(nextToastId)));
        dispatch(assignmentManagementSlice.actions.onSuccess());
      } else {
        const patchedAssignment = assignments.find(
          (x) => x.id === selectedAssignment.id
        );

        if (!patchedAssignment) {
          //TODO: handle error
          dispatch(
            assignmentManagementSlice.actions.onError('Assignment not found')
          );
          return;
        }

        await updateAssignment({
          id: selectedAssignment.id,
          patchedAssignment: patchedAssignment,
          'Organization-ID': auth.currentOrganizationId || '',
        }).unwrap();

        dispatch(show(generateEditSuccessToast(nextToastId)));
        dispatch(assignmentManagementSlice.actions.onSuccess());
      }
    } catch (error) {
      const strErr = formatServerError(error);
      dispatch(assignmentManagementSlice.actions.onError(strErr));
    }
  };
};
