import {
  PricingModelRead,
  usePricingPricingModelsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';

import { useSelectedPricingModelId } from './useSelectedPricingModelId';

export function useSelectedPricingModel(): PricingModelRead | undefined {
  const selectedPricingModelId = useSelectedPricingModelId();
  const { data } = usePricingPricingModelsRetrieveQuery(
    {
      id: selectedPricingModelId || '',
    },
    { skip: !selectedPricingModelId || selectedPricingModelId.includes('new') }
  );

  return data;
}
