import React from 'react';

export const TextInput = React.forwardRef(
  (
    props: React.InputHTMLAttributes<HTMLInputElement> & { error?: string },
    ref: React.Ref<HTMLInputElement>
  ) => (
    <input
      data-1p-ignore
      className={`block w-full rounded-md shadow-sm disabled:cursor-not-allowed disabled:text-gray-400 sm:text-sm ${
        props.error
          ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
          : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
      }`}
      {...props}
      ref={ref}
    />
  )
);
