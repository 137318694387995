import { capitalize } from 'lodash-es';

import { TripRecurrence } from 'src/common/external/bambi-api/bambiApi';

import { rRulesDays } from '../constants';
import { Recurrence } from './FormValues';

const generateFormRecurrencesFromDbRecurrences = (
  tripRecurrences: TripRecurrence[]
): Recurrence[] => {
  const initialRecurrencesMap = new Map<string, Recurrence>();
  const returnRecurrences: TripRecurrence[] = [];

  tripRecurrences.forEach((dbRecurrence) => {
    const dayOfWeek = capitalize(rRulesDays[dbRecurrence.rrule]);

    if (!dbRecurrence.is_round_trip) {
      const recurrence = {
        day_of_the_week: dayOfWeek,
        pickup_time: dbRecurrence.scheduled_pickup_time,
        appointment_time: dbRecurrence.appointment_time,
        return_time: undefined, // Initially undefined
        is_will_call: dbRecurrence.is_will_call,
        is_round_trip: dbRecurrence.is_round_trip,
        input_price_cents: dbRecurrence.input_price_cents,
        return_input_price_cents: undefined,
      };
      initialRecurrencesMap.set(dayOfWeek, recurrence);
    } else {
      // Temporarily store return trips to be processed later
      returnRecurrences.push(dbRecurrence);
    }
  });

  // Process return trips, now that all initial trips are set
  returnRecurrences.forEach((dbRecurrence) => {
    const dayOfWeek = capitalize(rRulesDays[dbRecurrence.rrule]);
    const initialRecurrence = initialRecurrencesMap.get(dayOfWeek);
    if (initialRecurrence) {
      initialRecurrence.is_round_trip = true;
      initialRecurrence.is_will_call = dbRecurrence.is_will_call;
      initialRecurrence.return_time = dbRecurrence.scheduled_pickup_time;
      initialRecurrence.return_input_price_cents =
        dbRecurrence.input_price_cents;
    }
  });

  return Array.from(initialRecurrencesMap.values());
};

export default generateFormRecurrencesFromDbRecurrences;
