import { RouteObject } from 'react-router-dom';

import { LiveMapRoute } from './LiveMapRoute';

export const liveMapRoutes: RouteObject[] = [
  {
    index: true,
    element: <LiveMapRoute />,
  },
];
