import { Select } from 'src/common/primitives/Select';
import { serviceTypeOptions } from 'src/features/add-trip/ServiceDetails/serviceTypeOptions';

import { DefaultFormFieldLayout } from './FormField/DefaultFormFieldLayout';
import { FormContextField } from './FormField/FormContextField';

export function ServiceTypeField({ fieldName }: { fieldName: string }) {
  return (
    <FormContextField name={fieldName}>
      {({ field }) => {
        return (
          <DefaultFormFieldLayout
            label="Service Type"
            inputProps={{ id: fieldName }}
          >
            <Select
              value={field.value}
              options={serviceTypeOptions}
              testIdSuffix={fieldName}
              onChange={(selected) => {
                field.onChange(selected?.value);
              }}
            />
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
