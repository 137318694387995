import React, { createContext, useCallback, useContext, useState } from 'react';

interface PrintableInvoiceContextState {
  setHasLineItems: (ready: boolean) => void;
  setHasPaymentMethods: (ready: boolean) => void;
  isReadyToPrint: () => boolean;
}

const PrintableInvoiceContext = createContext<PrintableInvoiceContextState>({
  setHasLineItems: () => {},
  setHasPaymentMethods: () => {},
  isReadyToPrint: () => false,
});

export const usePrintableInvoiceContext = () =>
  useContext(PrintableInvoiceContext);

export function PrintableInvoiceContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [hasLineItems, setHasLineItems] = useState<boolean>(false);
  const [hasPaymentMethods, setHasPaymentMethods] = useState<boolean>(false);
  const isReadyToPrint = useCallback(
    () => hasLineItems && hasPaymentMethods,
    [hasLineItems, hasPaymentMethods]
  );

  return (
    <PrintableInvoiceContext.Provider
      value={{
        isReadyToPrint,
        setHasLineItems,
        setHasPaymentMethods,
      }}
    >
      {children}
    </PrintableInvoiceContext.Provider>
  );
}
