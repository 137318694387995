import { useEffect } from 'react';

import {
  FilterGroupRead,
  useLazyFilterGroupsListQuery,
} from '../external/bambi-api/bambiApi';
import { searchParamsToJSON } from '../util/searchParamsToJSON';

/**
 * Intializes a filter group from the URL
 */
export function useInitializeFilterGroup(
  handler: (group: FilterGroupRead) => void
) {
  const [executeFetch] = useLazyFilterGroupsListQuery({});

  useEffect(() => {
    const currentParams = searchParamsToJSON(
      new URLSearchParams(window.location.search)
    );

    if (
      currentParams.filterGroup &&
      typeof currentParams.filterGroup === 'string'
    ) {
      executeFetch({
        search: currentParams.filterGroup,
      })
        .then((resp) => {
          const group = resp.data?.results.find(
            (g) => g.slug === currentParams.filterGroup
          );

          if (group) {
            handler(group);
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
