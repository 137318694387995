import {
  PricingRule,
  PricingType,
} from 'src/common/external/bambi-api/bambiApi';

export function calculateBaseRateInCents(
  pricingType: PricingType,
  pricingRule: PricingRule
) {
  // TODO: Need to confirm this business logic and also... probably not do this on the frontend
  const initialPlusAdditionalBase =
    pricingType.initial_rate + (pricingRule.additional_base || 0);
  const percentageAsDecimal =
    typeof pricingRule.percentage === 'number' && pricingRule.percentage > 0
      ? pricingRule.percentage / 100
      : 1;
  return initialPlusAdditionalBase * percentageAsDecimal;
}
