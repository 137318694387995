import React from 'react';

import { TextInput } from 'src/common/primitives/TextInput';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

export const FormFieldDate = React.forwardRef(function _FormFieldDate<T>(
  { inputProps, onChange, label, error, inline, helperText }: FormFieldProps<T>,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      inline={inline}
      helperText={helperText}
    >
      <div className="flex items-center gap-2">
        <TextInput
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange?.(e);
            onChange?.(e.target.value);
          }}
          type="date"
          ref={forwardedRef}
          data-testid="date-input"
          className={`block w-full rounded-md  shadow-sm disabled:cursor-not-allowed sm:text-sm ${
            error
              ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
              : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
          }`}
        />
      </div>
    </DefaultFormFieldLayout>
  );
});
