import { useEffect, useState } from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/app/store';
import { DropdownMenuContent } from 'src/common/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { useCanCapturePaymentMethod } from 'src/common/forms/PaymentMethodCaptureForm/useCanCapturePaymentMethod';
import { Button } from 'src/common/primitives/Button';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { useCreateEmptyInvoice } from 'src/features/billing/invoices/management/useCreateEmptyInvoice';
import { useEditInvoiceById } from 'src/features/billing/invoices/management/useEditInvoice';
import { CancellationMenu } from 'src/features/dispatch/menus/TripDropdownCancellationMenu';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { useTripMenuActions } from '../../dispatch/DispatchCalendar/useTripMenuActions';
import { setOpenTripDropdownMenuId } from '../../dispatch/dispatcher.slice';
import { DeleteModal } from './DeleteModal';

interface TripGridRowActionsProps {
  trip: TripRead;
  triggerClassName?: string;
}

export const TripGridRowActions = ({
  trip,
  triggerClassName,
}: TripGridRowActionsProps) => {
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  const { isRideOrderingPortalUser } = useUserRoles();
  const dispatch = useAppDispatch();
  const openTripDropdownMenuId = useSelector(
    (state: RootState) => state.dispatcher.openTripDropdownMenuId
  );
  const [open, setOpen] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const menuActions = useTripMenuActions(!!openTripDropdownMenuId === false);
  const {
    isLoading: isPaymentFeatureLoading,
    isEnabled: isPaymentFeatureEnabled,
    onlyInvoicingEnabled,
  } = useCanCapturePaymentMethod();
  const handleCreateEmptyInvoice = useCreateEmptyInvoice();
  const handleEditInvoice = useEditInvoiceById();
  const tripHasInvoice = !!trip.invoice_id;

  useEffect(() => {
    if (openTripDropdownMenuId === trip.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openTripDropdownMenuId, trip.id]);

  return (
    <div className="w-[24px]">
      <DropdownMenu.Root
        open={open}
        onOpenChange={(val) => {
          if (!val) dispatch(setOpenTripDropdownMenuId(null));
        }}
      >
        <DropdownMenu.Trigger asChild>
          <Button
            className={triggerClassName}
            variant="ghost"
            dataTestId="row-actions-trigger"
            onClick={() => dispatch(setOpenTripDropdownMenuId(trip.id))}
          >
            <EllipsisVerticalIcon className="h-6 w-6 cursor-pointer" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenuContent
            side="bottom"
            data-testid="trip-grid-row-actions-menu"
          >
            <DropdownMenuItem onClick={() => menuActions.clone(trip.id)}>
              Clone
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => menuActions.viewEdit(trip.id)}>
              Edit
            </DropdownMenuItem>
            {!shouldShowRideOrderingPortalFeatures && (
              <>
                <CancellationMenu trip={trip} />
                <DropdownMenuItem onClick={() => menuActions.addEditTags(trip)}>
                  Add Tags
                </DropdownMenuItem>

                {!trip.payer.has_kinetik_account && (
                  <DropdownMenuItem onClick={() => setIsConfirmingDelete(true)}>
                    Delete
                  </DropdownMenuItem>
                )}

                <DropdownMenuItem
                  onClick={() => menuActions.goToTripSummary(trip.id)}
                >
                  View Summary
                </DropdownMenuItem>
              </>
            )}
            {!isRideOrderingPortalUser &&
              trip.is_will_call &&
              !trip.is_will_call_activated && (
                // TODO: Resolve the differences between ActivateWillCallItem and this
                // Like... just pass the id instead of the whole trip object
                // and avoid the DispatchTripRead vs TripRead type shenanigans
                <DropdownMenuItem
                  onClick={() =>
                    menuActions.activateWillCall({ tripId: trip.id })
                  }
                >
                  Activate will call
                </DropdownMenuItem>
              )}
            {!isPaymentFeatureLoading &&
              (isPaymentFeatureEnabled || onlyInvoicingEnabled) && (
                <DropdownMenuItem
                  onClick={() => {
                    if (tripHasInvoice && trip.invoice_id) {
                      handleEditInvoice(trip.invoice_id);
                    } else {
                      handleCreateEmptyInvoice({
                        line_items: [
                          {
                            description: `Trip - ${trip.id}`,
                            amount_cents: trip.input_price_cents ?? 0,
                            trip: trip.id,
                            line_item_type: 'trip',
                          },
                        ],
                      });
                    }
                  }}
                >
                  {tripHasInvoice ? 'Edit Invoice' : 'Create Invoice'}
                </DropdownMenuItem>
              )}
          </DropdownMenuContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <DeleteModal
        open={isConfirmingDelete}
        setOpen={setIsConfirmingDelete}
        onCancel={() => {
          setIsConfirmingDelete(false);
        }}
        onConfirm={async () => {
          setIsConfirmingDelete(false);
          menuActions.delete(trip.id);
        }}
      />
    </div>
  );
};
