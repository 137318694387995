import '@aws-amplify/ui-react-geo/styles.css';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { formatRTKQueryError } from 'src/common/util/formatRTKQueryError';

import { TripSummaryItemList } from './TripSummaryItemList';
import { TripSummaryMapView } from './TripSummaryMapView';
import { useTripSummaryRouteViewModel } from './useTripSummaryRouteViewModel';

export function TripSummaryRoute() {
  const { shouldShowLoadingState, errors, tripId } =
    useTripSummaryRouteViewModel();

  return (
    <div className="grid min-h-[90vh] sm:grid-cols-12">
      <div className="col-span-5 h-[90vh] overflow-y-auto overflow-x-hidden lg:col-span-3">
        {errors && errors.length
          ? errors.map((error) => (
              <TripSummaryError error={error} tripId={tripId || ''} />
            ))
          : null}
        {shouldShowLoadingState ? (
          <div className="flex items-center justify-center p-2">
            <LoadingIndicator />
          </div>
        ) : (
          <TripSummaryItemList />
        )}
      </div>
      <div className="col-span-7 h-full lg:col-span-9">
        <TripSummaryMapView />
      </div>
    </div>
  );
}

interface TripSummaryErrorProps {
  error: FetchBaseQueryError | SerializedError;
  tripId: string;
}
function TripSummaryError({ error, tripId }: TripSummaryErrorProps) {
  const renderedError = formatRTKQueryError(error);
  return (
    <div className="p-4" key={renderedError}>
      <h2 className="text-lg font-medium">
        Error loading events for trip id {tripId}
      </h2>
      {renderedError}
    </div>
  );
}
