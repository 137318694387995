import { Attendant } from 'src/common/external/bambi-api/bambiApi';

export function getStepTitle(step: number, AttendantProfile: Attendant | null) {
  switch (step) {
    case 2:
      return 'Add Skills';
    default:
      return AttendantProfile ? 'Edit Attendant' : 'Add Attendant';
  }
}
