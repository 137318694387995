import { useCallback } from 'react';

import { useAppDispatch } from 'src/app/store';
import { usePassengersMergeCreateMutation } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { DuplicatePassenger } from './types';

export function useHandleMerge() {
  const dispatch = useAppDispatch();
  const [executeMerge] = usePassengersMergeCreateMutation({});
  const handleMerge = useCallback(
    async (
      parentPassenger: DuplicatePassenger | null,
      selectedPassengersList: DuplicatePassenger[],
      onMergeSuccess: () => void
    ) => {
      if (!parentPassenger) {
        dispatch(
          show({
            title: 'Something went wrong, unable to find base passenger',
            type: 'error',
          })
        );

        return;
      }

      try {
        const result = await executeMerge({
          passengerMergeRequest: {
            passenger_ids: [
              parentPassenger.id,
              ...selectedPassengersList.map((p) => p.id),
            ],
          },
        }).unwrap();

        if (result.merge_failure.length) {
          dispatch(
            show({
              type: 'error',
              title: `Failed to merge (${result.merge_failure.length}) passengers`,
              description: result.errors
                ?.map((error) => error.error)
                .join('\n'),
            })
          );
          return;
        }

        dispatch(
          show({
            title: `Successfully merged (${selectedPassengersList.length}) passengers`,
            type: 'success',
          })
        );
        onMergeSuccess();
      } catch (e) {
        dispatch(
          show({
            title: 'Failed to merge passengers',
            type: 'error',
            description: formatServerError(e),
          })
        );
      }
    },
    [dispatch, executeMerge]
  );

  return handleMerge;
}
