const FUZZY_SELFPAY = ['private pay', 'self pay', 'selfpay', 'self-pay'];

export function isSelfPay(payerName: string) {
  const safeNameForComparison = payerName.trim().toLowerCase();
  if (FUZZY_SELFPAY.includes(safeNameForComparison)) {
    return true;
  }

  // mediroutes has payers like `PRIVATE PAY $78 (21-25)`
  if (FUZZY_SELFPAY.includes(safeNameForComparison.substring(0, 11))) {
    return true;
  }

  return false;
}
