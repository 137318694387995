import { useFeature } from 'src/app/FeatureToggle';

import { SettingsFormField } from './components/SettingsFormField';
import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function PricingSettings() {
  const { isEnabled: isPricingV1Enabled, isLoading: isLoadingPricingV1Flag } =
    useFeature('pricing version 1');

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Pricing Settings"
        description="Organization defaults for pricing."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SettingsFormField
            label="Late cancellation if canceled within these hours of pickup"
            fieldId="cancellation_fee_window_hours"
            type="number"
          />
        </div>
        {!isLoadingPricingV1Flag && isPricingV1Enabled && (
          <div className="flex items-center align-middle">
            <SettingsFormField
              label="Use Latest Pricing"
              fieldId="is_pricing_v1_enabled"
              type="checkbox"
            />
          </div>
        )}
      </div>
    </div>
  );
}
