import { RefObject, useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import {
  useTripsSubscriptionsCreateMutation,
  useTripsSubscriptionsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { setShowTripSubscriptionModal } from '../trip-subscriptions.slice';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { FormValues } from './FormValues';
import generateTripSubscriptionFromFormValues from './generateTripSubscriptionFromFormValues';
import validateFormData from './validateFormData';

export function useFormManagement(
  setError: (error: string | null) => void,
  errorRef: RefObject<HTMLDivElement>
) {
  const dispatch = useDispatch();
  const selectedTripSubscription = useAppSelector(
    (state) => state.tripSubscription.selectedTripSubscription
  );

  const defaultValues = assignDefaultFormValues(selectedTripSubscription);
  const methods = useForm({ defaultValues });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createSubscription] = useTripsSubscriptionsCreateMutation();
  const [updateSubscription] = useTripsSubscriptionsPartialUpdateMutation();

  const onFormSubmit = useCallback(
    async (values: FormValues) => {
      setIsSubmitting(true);
      try {
        setError(null);
        const error = validateFormData(values);

        if (error) {
          setError(error);
          setIsSubmitting(false);
          errorRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          return;
        }

        if (values.id) {
          await updateSubscription({
            id: values.id,
            patchedTripSubscription:
              generateTripSubscriptionFromFormValues(values),
          }).unwrap();
        } else {
          await createSubscription({
            tripSubscription: generateTripSubscriptionFromFormValues(values),
          }).unwrap();
        }

        dispatch(setShowTripSubscriptionModal(false));
      } catch (error) {
        setError(formatServerError(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [setError, dispatch, errorRef, updateSubscription, createSubscription]
  );

  return { methods, isSubmitting, onFormSubmit };
}
