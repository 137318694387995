import { DriverRead } from 'src/common/external/bambi-api/bambiApi';

import { resolveOtherSkills } from './DriverOtherSkillKeyMap';
import { DriverProfile } from './DriverProfile';
import { resolveServiceSkills } from './DriverServiceSkillKeyMap';
import { resolveVehicleSkills } from './resolveVehicleSkills';

export function convertDriverToDriverProfile(
  driver: DriverRead
): DriverProfile {
  return {
    id: driver.id,
    firstName: driver.first_name || 'unknown',
    lastName: driver.last_name || 'unknown',
    name: `${driver.first_name || 'unknown'} ${driver.last_name || 'unknown'}`,
    memberId: driver.membership,
    organizationId: driver.organization_id,
    status: driver.can_be_scheduled,
    // TODO: usertype here?
    userType: 'associate',
    licenseNumber: driver.license_number || '',
    issuedState: driver.license_state || '',
    expirationDate: driver.license_expiration_date || '',
    dateOfBirth: driver.license_dob || '',
    vehicleSkills: resolveVehicleSkills(driver),
    serviceSkills: resolveServiceSkills(driver),
    otherSkills: resolveOtherSkills(driver),
    is_currently_clocked_in: false,
    appVersion: driver.app_version || '',
  };
}
