import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import {
  setAttendantProfileSearchTerm,
  setSelectedAttendantProfile,
  setShowAttendantProfileModal,
} from 'src/features/team/team.slice';

export function AttendantRouteActions() {
  const dispatch = useAppDispatch();
  const { isRestrictedSupport } = useUserRoles();

  const attendantProfileSearchTerm = useAppSelector(
    (state) => state.team.attendantProfileSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setAttendantProfileSearchTerm,
    attendantProfileSearchTerm
  );

  const handleAddAttendant = () => {
    dispatch(setSelectedAttendantProfile(null));
    dispatch(setShowAttendantProfileModal(true));
  };

  return (
    <>
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search attendants"
        value={inputValue}
      />
      {!isRestrictedSupport && (
        <Button onClick={() => handleAddAttendant()} variant="primary">
          Add Attendant
        </Button>
      )}
    </>
  );
}
