import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';

import { TRIP_STATUSES } from './tripStatuses';

export const ACTIVE_TRIP_STATUSES: OverrideStatusEnum[] = [
  TRIP_STATUSES.EN_ROUTE,
  TRIP_STATUSES.AT_PICKUP,
  TRIP_STATUSES.TRANSPORTING,
  TRIP_STATUSES.AT_DROPOFF,
];
