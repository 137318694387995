import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { PassengerRouteActions } from './PassengerRouteActions';

export function PassengerLayout() {
  return (
    <HeaderToolbarAndContentLayout
      actionSection={<PassengerRouteActions />}
      content={<Outlet />}
    />
  );
}
