import { Cell, flexRender, RowData } from '@tanstack/react-table';

interface DataGridCellProps<T> {
  cell: Cell<T, unknown>;
  cellClassName?: string;
}

export function DataGridCell<T extends RowData>({
  cell,
  cellClassName,
}: DataGridCellProps<T>) {
  const columnClassName = cell.column.columnDef.meta?.columnClassName ?? '';
  return (
    <td
      className={`whitespace-nowrap px-3 py-3 text-sm text-gray-500 ${cellClassName} ${columnClassName}`.trim()}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
}
