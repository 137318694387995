// import { TripSummaryEventPersonName } from './TripSummaryEventPersonName';
import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryEventPersonName } from './TripSummaryEventPersonName';
import { TripSummaryOccuredAt } from './TripSummaryOccuredAt';
import { TripSummaryTitle } from './TripSummaryTitle';

export function TripSummaryUpdateItemHeader({
  tripEvent,
  isSelected,
}: {
  tripEvent: TripUpdateRead;
  isSelected: boolean;
}) {
  return (
    <div
      className={`flex w-full flex-col gap-1  p-2 pr-12 text-left ${
        isSelected ? 'bg-mint-light' : 'bg-gray-50'
      }`}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <TripSummaryTitle title="Updated" />
        {tripEvent.created_at && (
          <TripSummaryOccuredAt occuredAt={tripEvent.created_at} />
        )}
      </div>
      <div className="flex w-full flex-wrap items-center justify-between">
        <div>&nbsp;</div>
        {tripEvent.person_name && (
          <TripSummaryEventPersonName name={tripEvent.person_name} />
        )}
      </div>
    </div>
  );
}
