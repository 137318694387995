import { createSlice } from '@reduxjs/toolkit';

import {
  AssignedVehicleRead,
  AssignmentRead,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';

import { convertVehicleToAssignedVehicle } from './convertVehicleToAssignedVehicle';

export interface IAssignmentManagementState {
  assignments: AssignmentRead[];
  selectedDay?: string;
  assignmentSearchTerm: string;
  selectedAssignment: AssignmentRead | undefined;
  assignmentToClone?: AssignmentRead | null;
  showAssignmentManagementModal: boolean;
  showAssignmentExportModal: boolean;
  selectedVehicle: AssignedVehicleRead | undefined;
  showMultiAssignmentManagementModal: boolean;
  error: string;
  isSubmitting: boolean;
}

export const initialState: IAssignmentManagementState = {
  assignments: [],
  selectedDay: undefined,
  assignmentSearchTerm: '',
  selectedAssignment: undefined,
  showAssignmentManagementModal: false,
  showAssignmentExportModal: false,
  selectedVehicle: undefined,
  showMultiAssignmentManagementModal: false,
  error: '',
  isSubmitting: false,
};

export const assignmentManagementSlice = createSlice({
  name: 'assignmentManagement',
  initialState,
  reducers: {
    setAssignments: (state, action) => {
      state.assignments = action.payload;
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
    setAssignmentSearchTerm: (state, action) => {
      state.assignmentSearchTerm = action.payload;
    },
    setSelectedAssignment: (state, action) => {
      state.selectedAssignment = action.payload;
    },
    setShowAssignmentManagementModal: (state, action) => {
      state.showAssignmentManagementModal = action.payload;
    },
    setShowAssignmentExportModal: (state, action) => {
      state.showAssignmentExportModal = action.payload;
    },
    setShowMultiAssignmentManagementModal: (state, action) => {
      state.showMultiAssignmentManagementModal = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedVehicle: (
      state,
      action: { payload: VehicleRead | undefined; type: string }
    ) => {
      if (action.payload === undefined) {
        state.selectedVehicle = undefined;
        return;
      }

      state.selectedVehicle = convertVehicleToAssignedVehicle(action.payload);
    },
    onError: (state, action) => {
      state.error = action.payload;
      state.isSubmitting = false;
    },
    submit: (state) => {
      state.error = '';
      state.isSubmitting = true;
    },
    onSuccess: (state) => {
      state.error = '';
      state.isSubmitting = false;
      state.selectedAssignment = undefined;
      state.selectedVehicle = undefined;
      state.showAssignmentManagementModal = false;
    },
    onStartAssignmentClone: (state, action: { payload: AssignmentRead }) => {
      // We use a common component for both adding and editing
      // Unselecting takes us out of editing
      state.selectedAssignment = undefined;
      // This is probably unnecessary, but ensure the modal is open
      state.showAssignmentManagementModal = true;

      // This sets the vehicle for the modal
      state.selectedVehicle = action.payload.vehicle;
      state.assignmentToClone = action.payload;
    },
    onAssignmentCloneConfirm: (state) => {
      state.assignmentToClone = null;
    },
    onCloseAssignmentModal: (state) => {
      state.selectedAssignment = undefined;
      state.selectedVehicle = undefined;
      state.showAssignmentManagementModal = false;
      state.assignmentToClone = null;
      state.error = '';
    },
  },
});

export const {
  setSelectedDay,
  setAssignments,
  setAssignmentSearchTerm,
  setSelectedAssignment,
  setShowAssignmentManagementModal,
  setShowAssignmentExportModal,
  setSelectedVehicle,
  setShowMultiAssignmentManagementModal,
  setError,
  onStartAssignmentClone,
  onCloseAssignmentModal,
  onAssignmentCloneConfirm,
} = assignmentManagementSlice.actions;
