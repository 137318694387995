import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { useSelectedPricingType } from 'src/features/pricingModel/management/hooks/useSelectedPricingType';
import { PricingTypeAdditionalBaseBadge } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingTypeAdditionalBaseBadge';
import { PricingTypeBaseRateBadge } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingTypeBaseRateBadge';

import { PricingRuleInitialRateDisplay } from '../PricingRuleInitialRateDisplay';

export function PricingRuleAccordionContentView({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const pricingType = useSelectedPricingType();
  if (!pricingType)
    return (
      <div>No pricing type found. This should never happen in practice.</div>
    );

  return (
    <div className="relative flex shrink-0 flex-row items-start justify-start gap-1 self-stretch">
      <div className="flex flex-grow flex-col gap-2 text-sm text-gray-700">
        <div>
          <div className="text-md font-medium text-gray-900">Description: </div>
          <div>{pricingRule.text_description || 'No description provided'}</div>
        </div>
        <div>
          <div className="text-md font-medium text-gray-900">
            Match Condition:
          </div>
          <div>{pricingRule.match_condition}</div>
        </div>

        {typeof pricingRule.units_covered_range_start === 'number' &&
          typeof pricingRule.units_covered_range_end === 'number' && (
            <div>
              <div className="text-md font-medium text-gray-900">
                Units Covered:
              </div>
              {typeof pricingRule.units_covered_range_start === 'number' && (
                <div>Start: {pricingRule.units_covered_range_start}</div>
              )}
              {typeof pricingRule.units_covered_range_end === 'number' && (
                <div>End: {pricingRule.units_covered_range_end}</div>
              )}
            </div>
          )}
      </div>
      {/* TODO: This is the vertical divider. There's a horizontal one below that we could probably make into a Divider component */}
      <div className="relative flex flex-1  flex-row items-start justify-start gap-2 self-stretch border-r-2 border-solid border-[#efefef] pr-8" />
      <div className="relative flex flex-1 flex-grow flex-col items-start justify-start gap-4 pr-6 pl-3">
        <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch">
          <div className="text-sm font-medium text-gray-700">Base Rate:</div>
          <PricingTypeBaseRateBadge pricingType={pricingType} />
        </div>
        <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch">
          <div className="text-sm font-medium text-gray-700">
            Additional Base:
          </div>
          <PricingTypeAdditionalBaseBadge
            pricingType={pricingType}
            pricingRule={pricingRule}
          />
        </div>
        <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch">
          <div className="text-sm font-medium text-gray-700">
            Percentage Modifier:
          </div>
          {`${
            typeof pricingRule.percentage === 'number'
              ? pricingRule.percentage
              : 100
          }%`}
        </div>
        {/* TODO: Replace this divider with a border or at least make it its own component */}
        <svg
          className="relative shrink-0 self-stretch overflow-visible"
          style={{}}
          width="494"
          height="1"
          viewBox="0 0 494 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M494 1H0.5V0H494V1Z"
            fill="#EAECF0"
          />
        </svg>

        <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch">
          <div className="text-sm font-medium text-gray-700">Initial Rate:</div>
          <PricingRuleInitialRateDisplay
            pricingType={pricingType}
            pricingRule={pricingRule}
          />
        </div>
      </div>
    </div>
  );
}
