import { IdentifiedOutcome } from './IdentifiedOutcome';

export function getSelectedValue(hasFailValue?: boolean): IdentifiedOutcome[] {
  if (hasFailValue) {
    return [{ id: 'fail', value: 'fail' }];
  }

  if (hasFailValue === false) {
    return [{ id: 'pass', value: 'pass' }];
  }

  return [];
}
