import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useRef } from 'react';

import {
  Eventcalendar,
  luxonTimezone,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscResource,
} from '@mobiscroll/react';
import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';
import * as luxon from 'luxon';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/app/store';
import { useAuth } from 'src/features/auth/useAuth';

import { resetSelectedTrip } from '../dispatcher.slice';
import { AssignmentEmptyState } from './AssignmentEmptyState';
import { DispatchCalendarAssignment } from './DispatchCalendarAssignment';
import { DispatchCalendarHeader } from './DispatchCalendarHeader';
import { AssignmentEvent } from './eventRenderers/AssignmentEvent';
import { getMobiscrollSelectedDate } from './getMobiscrollSelectedDate';
import { TripCalendarEvent } from './TripCalendarEvent';
import BreakCalendarEvent from './TripCalendarEvent/BreakCalendarEvent';
import { EventWaitTime } from './TripCalendarEvent/EventWaitTime';
import useDynamicCss from './useDynamicCss';

luxonTimezone.luxon = luxon;
// Important -- prevents "Invalid unit zone" error
// Mobiscroll needs to determine the version I believe
// due to using a custom createObject implementation
// luxon changed the signature of createObject in v2
luxonTimezone.version = 3;

// TODO: Move into dispatcher state as CADSettings - Note hack below
const view: MbscEventcalendarView = {
  schedule: {
    type: 'day',
    allDay: false,
    timeCellStep: 30,
    timeLabelStep: 60,
  },
};

const selectSelectedDate = (state: RootState) => state.dispatcher.selectedDate;

export function DispatchCalendar({
  resources = [],
  events = [],
  invalids = [],
}: {
  resources: MbscResource[];
  events: MbscCalendarEvent[];
  invalids: IValidateProps[];
}) {
  const calendarRef = useRef<any>();
  const dynamicCss = useDynamicCss(calendarRef);
  const dispatch = useAppDispatch();
  const selectedDate = useSelector(selectSelectedDate);
  const { selectedTripId } = useSelector(
    (state: RootState) => state.dispatcher
  );
  const { currentOrganizationHeadQuarters } = useAuth();

  const selectedTripEvent = events.find((event) => event.id === selectedTripId);
  if (selectedTripEvent && calendarRef.current?.navigateToEvent) {
    calendarRef.current?.navigateToEvent(selectedTripEvent);
  }
  if (!selectedTripEvent && selectedTripId) {
    dispatch(resetSelectedTrip());
  }

  if (!view.schedule) throw new Error('No scheduler view configured');

  // Make Mobiscroll only show 1 day
  // When resources exist this isn't necessary, but if no resources exist
  // it falls back to showing m-f
  // if (resources?.length < 1) {
  //   const viewDay = DateTime.fromISO(selectedDate).day;
  //   view.schedule.startDay = viewDay;
  //   view.schedule.endDay = viewDay;
  // }

  return (
    <div
      className="isolate flex h-full max-h-[90vh] max-w-full flex-col gap-2 px-1 pt-3"
      data-testid="dispatch-calendar-root"
    >
      <style>{dynamicCss}</style>
      {resources.length < 1 && <AssignmentEmptyState />}
      <Eventcalendar
        ref={calendarRef}
        theme="ios"
        themeVariant="light"
        clickToCreate={false}
        dragToCreate={false}
        dragToMove={false}
        dragToResize={false}
        eventDelete={false}
        view={view}
        data={events.length > 0 ? events : [{ id: 1, resource: 1 }]}
        resources={resources.length > 0 ? resources : undefined}
        invalid={invalids}
        renderHeader={() => <DispatchCalendarHeader />}
        renderResource={(resource) => (
          <DispatchCalendarAssignment assignment={resource.assignment} />
        )}
        renderScheduleEvent={(event) => (
          <div className="absolute top-0 left-0 right-0 bottom-0 p-0">
            {/* 
              TODO: Move the rest of the renderers to ./eventRenders/ and lift non TripCalendarEvent stuff out
              of that module.

              Also, need to think about typing here. Right now we're just duck typing the event.original
            */}
            {event.original?.assignmentEvent && (
              <AssignmentEvent
                variant={event.original.assignmentEvent.type}
                time={
                  typeof event.original.start === 'string'
                    ? event.original.start
                    : ''
                }
              />
            )}
            {event.original?.trip && (
              <TripCalendarEvent
                trip={event.original.trip}
                type={event.original.type}
              />
            )}
            {event.original?.assignmentBreak && (
              <BreakCalendarEvent
                assignmentBreak={event.original.assignmentBreak}
              />
            )}
            {event.original?.waitTime && <EventWaitTime />}
          </div>
        )}
        width="100%"
        selectedDate={getMobiscrollSelectedDate(selectedDate)}
        refDate={getMobiscrollSelectedDate(selectedDate)}
        dataTimezone="utc"
        timezonePlugin={luxonTimezone}
        displayTimezone={currentOrganizationHeadQuarters?.time_zone_name}
        showEventBuffer
      />
    </div>
  );
}
