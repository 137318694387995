import { useFormContext } from 'react-hook-form';

import {
  BlankEnum,
  NoShowFeeTypeEnum,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import {
  FormContextField,
  FormContextFieldRulesProps,
} from 'src/common/FormField/FormContextField';
import { SelectOption } from 'src/common/primitives/Select';

import { defaultValues } from '../PricingWizardSchemeConfigurationStep';

export function LateCancellationFeeTypeField({
  fieldPath,
  rules,
  disabled,
}: {
  fieldPath: string;
  rules?: FormContextFieldRulesProps;
  disabled?: boolean;
}) {
  const stairFeeTypes: NoShowFeeTypeEnum[] = ['Flat', 'Percentage'];
  const blankOption: BlankEnum = '';
  const stairFeeTypeOptions: SelectOption[] = ['None', ...stairFeeTypes].map(
    (type) => ({
      label: type,
      value: type,
    })
  );

  const { clearErrors, setValue } = useFormContext();

  return (
    <FormContextField name={fieldPath}>
      {({ field }) => {
        const newValue = {
          label: field.value,
          value: field.value === blankOption ? 'None' : field.value,
        };
        return (
          <FormField
            label={'Late Cancellation Fee Type' + (rules?.required ? ' *' : '')}
            type="select"
            inputProps={{
              ...field,
              id: fieldPath,
            }}
            onChange={(option) => {
              const newValue =
                option.value === 'None' ? blankOption : option.value;
              field.onChange(newValue);
              clearErrors('late_cancellation_fee_cents');
              setValue(
                'late_cancellation_fee_cents',
                defaultValues.late_cancellation_fee_cents
              );
              clearErrors('late_cancellation_fee_percentage');
              setValue(
                'late_cancellation_fee_percentage',
                defaultValues.late_cancellation_fee_percentage
              );
            }}
            options={stairFeeTypeOptions}
            value={newValue}
            inline
            disabled={disabled}
            helperText="Choose the type of late cancellation fee, whether it is a flat fee or a percentage of the trip."
          />
        );
      }}
    </FormContextField>
  );
}
