import { useEffect, useMemo, useState } from 'react';

import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { RootState } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  PayersListApiArg,
  usePayersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { PayerEmptyState } from '../PayerEmptyState';
import { PayersDialog } from '../PayerForm/PayersDialog';
import { columns, manageColumn } from './columns';

export function PayerManagementRoute() {
  const payerSearchTerm = useSelector(
    (state: RootState) => state.payer.payerSearchTerm
  );

  const { isRestrictedSupport } = useUserRoles();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const {
    data: payers,
    isLoading,
    refetch,
  } = usePayersListQuery({
    page: pageIndex + 1,
    search: payerSearchTerm,
    ordering: useSearchParams()[0].get(
      'ordering'
    ) as PayersListApiArg['ordering'],
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old: any) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [refetch, payerSearchTerm]);

  return (
    <>
      {isLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div>
            <DataGrid
              tableId="payer-management"
              columns={
                isRestrictedSupport ? columns : [...columns, manageColumn]
              }
              data={payers?.results || []}
              pagination={pagination}
              totalCount={payers?.count || 0}
              handlePaginationChange={handlePaginationChange}
              emptyState={<PayerEmptyState />}
              dataTestId="payer-management-table"
              getRowId={(row) => row.id}
            />
          </div>
        </>
      )}
      <PayersDialog />
    </>
  );
}
