import { useAppDispatch, useAppSelector } from 'src/app/store';

import PaymentStatusFilter from '../../filters/PaymentStatusFilter';
import { setFilterPaymentStatus } from '../../invoice.slice';

interface PaymentStatusColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function PaymentStatusColumnFilter({
  open,
  onClose,
}: PaymentStatusColumnFilterProps) {
  const dispatch = useAppDispatch();
  const selectedPaymentStatus = useAppSelector(
    (state) => state.invoice.filterPaymentStatus
  );

  return (
    <PaymentStatusFilter
      open={open}
      triggerElement={<div />}
      selected={selectedPaymentStatus ?? []}
      onApply={(paymentStatuses) => {
        dispatch(setFilterPaymentStatus(paymentStatuses));
      }}
      onClose={onClose}
    />
  );
}
