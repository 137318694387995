import { useMemo } from 'react';

import { TripMessageRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryOccuredAt } from '../TripSummaryOccuredAt';
import { TripSummaryTitle } from '../TripSummaryTitle';

interface TripSummaryMessageItemHeaderProps {
  tripMessage: TripMessageRead;
  isSelected: boolean;
}
export function TripSummaryMessageItemHeader({
  tripMessage,
  isSelected,
}: TripSummaryMessageItemHeaderProps) {
  const title = useMemo(() => {
    switch (tripMessage.message_type) {
      case 'reminder-day-before':
        return 'Day Before Message';
      case 'reminder-near-pickup':
        return 'Near Pickup Message';
      default:
      case 'post-trip':
        return 'Unknown';
    }
  }, [tripMessage.message_type]);

  return (
    <div
      className={`flex w-full flex-col gap-1  p-2 pr-12 text-left ${
        isSelected ? 'bg-mint-light' : 'bg-gray-50'
      }`}
    >
      <div className="flex w-full items-center justify-between gap-1">
        <div className="flex items-center">
          <TripSummaryTitle title={title} />
        </div>
        <TripSummaryOccuredAt occuredAt={tripMessage.message.created_at} />
      </div>
    </div>
  );
}
