import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  useVehiclesListQuery,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

import { fleetInspectionSlice } from '../fleetInspection.slice';

interface VehicleColumnFilterProps {
  open: boolean;
  onClose: () => void;
}
export function VehicleColumnFilter({
  open,
  onClose,
}: VehicleColumnFilterProps) {
  const inspectionListFilter = useAppSelector(
    (state) => state.fleetInspection.inspectionListFilter
  );
  const dispatch = useAppDispatch();

  const { data } = useVehiclesListQuery({});
  const selectedValues: VehicleRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) =>
      inspectionListFilter.vehicleIn?.includes(v.id)
    );
  }, [data, inspectionListFilter.vehicleIn]);

  return (
    <MultiSelectFilterPopover
      open={open}
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={({ nickname }) => nickname}
      onCancel={onClose}
      onChange={(value) => {
        const vehicleIds: string = value.map((v) => v.id).join(',');
        dispatch(
          fleetInspectionSlice.actions.onVehicleFilterChange(vehicleIds)
        );
        onClose();
      }}
      triggerElement={<div />}
    />
  );
}
