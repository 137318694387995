import { useCallback, useEffect, useState } from 'react';

import {
  InvoiceRead,
  useLazyTripsRetrieveQuery,
  type InvoiceLineItem,
  type Trip,
} from 'src/common/external/bambi-api/bambiApi';

export interface HydratedTripLineItem extends Omit<InvoiceLineItem, 'trip'> {
  trip: Trip;
}

export interface HydratedInvoice extends Omit<InvoiceRead, 'line_items'> {
  line_items: HydratedTripLineItem[];
}

type UseHydratedInvoice = {
  isLoading: boolean;
  data: HydratedInvoice | null;
};

export function useHydratedInvoice(
  invoice: InvoiceRead | null
): UseHydratedInvoice {
  const [isLoading, setLoading] = useState(true);
  const [hydratedListItems, setHydratedListItems] = useState<
    HydratedTripLineItem[]
  >([]);
  const hydrate = useInvoiceHydrator(invoice);

  useEffect(() => {
    if (!invoice) {
      return;
    }

    hydrate()
      .then((result) => {
        setHydratedListItems(result as HydratedTripLineItem[]);
      })
      .catch((_e) => {
        // noop
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setHydratedListItems, hydrate, invoice]);

  if (!invoice) {
    return {
      isLoading: false,
      data: null,
    };
  }

  const hydratedInvoice: HydratedInvoice = {
    ...invoice,
    line_items: hydratedListItems,
  };

  return {
    isLoading,
    data: isLoading ? null : hydratedInvoice,
  };
}

export function useInvoiceHydrator(invoice: InvoiceRead | null) {
  const [fetchTrip] = useLazyTripsRetrieveQuery();

  const hydrate = useCallback(() => {
    if (!invoice) {
      return Promise.resolve();
    }

    return Promise.all(
      invoice.line_items.map(async (item) => {
        if (!item.trip) {
          return item;
        }

        const tripResult = await fetchTrip({ id: item.trip }, true).unwrap();
        return {
          ...item,
          trip: tripResult,
          invoice: invoice.id,
        };
      })
    );
  }, [fetchTrip, invoice]);

  return hydrate;
}
