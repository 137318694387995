import { useAppDispatch } from 'src/app/store';
import { useAssignmentsTripsActiveListQuery } from 'src/common/external/bambi-api/bambiApi';

import { liveMapSlice } from '../../liveMap.slice';
import { AssignmentMarker } from './AssignmentMarker';
import { AssignmentPopup } from './AssignmentPopup';
import DestinationMarker from './DestinationMarker';
import { makeAssignmentPinConfig } from './makeAssignmentPinConfig';
import { useAssignmentMarkersViewModel } from './useAssignmentMarkersViewModel';
import { useSelectedAssignmentRoute } from './useSelectedAssignmentRoute';

export function AssignmentMarkers() {
  const { assignmentLocations, selectedAssignmentLocation } =
    useAssignmentMarkersViewModel();

  const dispatch = useAppDispatch();

  const { activeTrips } = useAssignmentsTripsActiveListQuery(
    {
      id: selectedAssignmentLocation?.id || '',
    },
    {
      selectFromResult: (data) => ({
        activeTrips: data.data?.results,
      }),
      skip: !selectedAssignmentLocation,
    }
  );

  useSelectedAssignmentRoute(selectedAssignmentLocation, activeTrips);

  return (
    <>
      {assignmentLocations
        ?.filter((assignmentLocation) => {
          return (
            assignmentLocation.latest_location.latitude &&
            assignmentLocation.latest_location.longitude
          );
        })
        .map((assignmentLocation, index) => (
          <AssignmentMarker
            key={index}
            assignmentMarker={makeAssignmentPinConfig(assignmentLocation)}
            onClick={() => {
              dispatch(
                liveMapSlice.actions.selectAssignmentLocation(
                  assignmentLocation.id
                )
              );
            }}
            isSelected={
              assignmentLocation.id === selectedAssignmentLocation?.id
            }
          />
        ))}
      {!!selectedAssignmentLocation && (
        <AssignmentPopup
          assignmentLocation={selectedAssignmentLocation}
          onClose={() => {
            dispatch(liveMapSlice.actions.deselectAssignmentLocation());
          }}
        />
      )}
      {activeTrips?.length && selectedAssignmentLocation && (
        <DestinationMarker assignmentTrip={activeTrips[0]} />
      )}
    </>
  );
}
