import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { getChangedValueKeysForUpdate } from './getChangedValueKeysForUpdate';

export function tripUpdateEventHasUnskippedChangedValues(
  tripEvent: TripUpdateRead
): boolean {
  const valueKeysChanged = getChangedValueKeysForUpdate(tripEvent);
  return valueKeysChanged.length > 0;
}
