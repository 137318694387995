import { useAppDispatch } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { onPricingRuleEditCancel } from 'src/features/pricingModel/pricingModel.slice';

export function PricingRuleSaveButton({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  return (
    <Button
      className="flex gap-1"
      onClick={(e) => {
        // This lives in an accordion so let's not toggle that when we're trying to save
        e.stopPropagation();
        // TODO: validate and save
        dispatch(onPricingRuleEditCancel(pricingRule));
      }}
      dataTestId={`pricing-rule-save-button-${pricingRule.id}`}
      variant="primary"
    >
      Save
    </Button>
  );
}
