import { ClipboardIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

export function AddATripErrorMessageForm({
  open,
  setOpen,
  onClose,
  errorDetail,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  errorDetail: string | null;
}) {
  return (
    <Modal open={open} setOpen={setOpen} onClose={onClose}>
      <form>
        <div>
          <div>
            <h3 className="text-[24px] font-medium leading-6 text-gray-900">
              Add a Trip: Error Details
            </h3>
            <p className="mt-2 text-xs text-gray-500">
              Send us a message by copying the error below.
            </p>
          </div>
          <div className="mt-4">
            <div className="">
              <span>{errorDetail}</span>
              <ClipboardIcon
                className="ml-2 inline h-5 w-5 cursor-pointer hover:text-mint"
                onClick={() => {
                  navigator.clipboard.writeText(errorDetail ? errorDetail : '');
                }}
              />
              <div className="mt-4">
                <Button
                  className="sm:col-span-3"
                  variant="primary"
                  onClick={onClose}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
