import { PricingType } from 'src/common/external/bambi-api/bambiApi';

import { SelectedPricingTypeDetailsHeader } from './SelectedPricingTypeDetailsHeader';
import { SelectedPricingTypeRules } from './SelectedPricingTypeRules';

export function SelectedPricingTypeDetails({
  pricingType,
}: {
  pricingType: PricingType;
}) {
  return (
    <div className="flex flex-col gap-y-4">
      <SelectedPricingTypeDetailsHeader pricingType={pricingType} />
      <div className="border-b border-gray-200" />
      <SelectedPricingTypeRules pricingType={pricingType} />
    </div>
  );
}
