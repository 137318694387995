import {
  AttendantRead,
  AttendantSkills,
} from 'src/common/external/bambi-api/bambiApi';

import { FormValues } from './FormValues';

export function convertFormValuesToAttendant(
  values: FormValues,
  selectedAttendantProfile: AttendantRead | null
): AttendantRead {
  return {
    ...(selectedAttendantProfile || {}),
    membership: values.membership,
    can_be_scheduled: values.can_be_scheduled,
    first_name: values.first_name,
    last_name: values.last_name,
    skills: {
      ...(selectedAttendantProfile?.skills || {}),
      can_do_wheelchair_service: values.can_do_wheelchair_service,
      can_do_wheelchair_xl_service: values.can_do_wheelchair_xl_service,
      can_do_broda_chair_service: values.can_do_broda_chair_service,
      can_do_geri_chair_service: values.can_do_geri_chair_service,
      can_do_stretcher_service: values.can_do_stretcher_service,
      can_do_ambulatory_service: values.can_do_ambulatory_service,
      can_do_curb_to_curb: values.can_do_curb_to_curb,
      can_do_door_to_door: values.can_do_door_to_door,
      can_do_door_through_door: values.can_do_door_through_door,
      can_do_bariatric: values.can_do_bariatric,
      can_do_stairs: values.can_do_stairs,
    } as AttendantSkills,
  } as AttendantRead;
}
