import { DateTime } from 'luxon';

import { Trip, FormValues } from '../FormValues';

export const validateFormInputs = (
  formValues: FormValues
): Record<string, any>[] => {
  const formErrors = [];
  const timingErrors = validateTripTiming(formValues.trips);
  if (timingErrors) {
    formErrors.push(timingErrors);
  }

  return formErrors;
};

const validateTripTiming = (trips: Trip[]): Record<string, any> | null => {
  if (trips.length > 1) {
    for (let i = 1; i < trips.length; i++) {
      const previousTripDate = trips[i - 1].timing.date;
      const currentTripDate = trips[i].timing.date;
      const previousTripPickupTime = trips[i - 1].timing.pickup_time;
      const currentTripPickupTime = trips[i].timing.pickup_time;

      const previousTripDateAndTime = DateTime.fromISO(
        `${previousTripDate}T${previousTripPickupTime}`
      );
      const currentTripDateAndTime = DateTime.fromISO(
        `${currentTripDate}T${currentTripPickupTime}`
      );

      if (currentTripDateAndTime < previousTripDateAndTime) {
        return {
          attr: 'Timing',
          detail: `Trip ${i + 1} is scheduled before trip ${i}`,
        };
      }
    }
  }
  return null;
};
