import { useAppDispatch, useAppSelector } from 'src/app/store';
import PayersFilter from 'src/features/trip/management/PayersFilter';

import { setFilterTripPayers } from '../../invoice.slice';

interface PayersColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function PayersColumnFilter({ open, onClose }: PayersColumnFilterProps) {
  const dispatch = useAppDispatch();
  const selected =
    useAppSelector((state) => state.invoice.filterTripPayers) ?? [];

  return (
    <PayersFilter
      open={open}
      selected={selected ?? []}
      triggerElement={<div />}
      onApply={(payers) => {
        dispatch(setFilterTripPayers(payers));
      }}
      onClose={onClose}
    />
  );
}
