import { createContext, useContext, useRef } from 'react';

import { useOrganizationSettingsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';

import { Tilled } from './types';

interface PaymentMethodCaptureContextState {
  tilledInstance?: Tilled;
  areFieldsEmpty: () => boolean;
  fieldState: Record<string, boolean>;
  resetFieldState: () => void;
  setFieldState: (key: string, value: boolean) => void;
}

const PaymentMethodCaptureContext = createContext<
  PaymentMethodCaptureContextState | undefined
>(undefined);

interface PaymentMethodCaptureProviderProps {
  children: React.ReactNode;
}

export function PaymentMethodCaptureProvider({
  children,
}: PaymentMethodCaptureProviderProps) {
  const tilled = useRef<Tilled>();
  const fieldState = useRef<Record<string, boolean>>({});

  const areFieldsEmpty = () =>
    Object.values(fieldState.current).every((e) => !!e);
  const resetFieldState = () => {
    fieldState.current = {};
  };
  const setFieldState = (key: string, value: boolean) => {
    fieldState.current[key] = value;
  };

  const { data: orgSetting, isLoading } = useOrganizationSettingsRetrieveQuery(
    {}
  );
  const accountId = orgSetting?.payment_account_id;

  const TILLED_API_KEY = process.env.REACT_APP_TILLED_PUBLISHABLE_KEY;

  if (!TILLED_API_KEY) {
    // eslint-disable-next-line no-console
    console.warn('TILLED_API_KEY is not set, please set it in .env');
  }

  if (TILLED_API_KEY && !tilled.current && !isLoading && accountId) {
    tilled.current = new window.Tilled(TILLED_API_KEY, accountId, {
      sandbox: process.env.REACT_APP_ENV !== 'prod',
      log_level: 0,
    });
  }

  return (
    <PaymentMethodCaptureContext.Provider
      value={{
        tilledInstance: tilled.current,
        areFieldsEmpty,
        fieldState: fieldState.current,
        resetFieldState,
        setFieldState,
      }}
    >
      {children}
    </PaymentMethodCaptureContext.Provider>
  );
}

export function usePaymentMethodCaptureContext() {
  const context = useContext(PaymentMethodCaptureContext);
  if (!context) {
    throw new Error(
      'usePaymentMethodCaptureContext must be used within a PaymentMethodCaptureProvider'
    );
  }

  return context;
}

export { PaymentMethodCaptureContext };
