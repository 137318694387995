export function FieldDisplay({
  value,
  label,
}: {
  value?: string | null;
  label: string;
}) {
  return (
    <>
      {value && (
        <>
          <h4 className="text-sm font-medium text-gray-500">{label}</h4>
          <p className="text-sm text-gray-500">{value}</p>
        </>
      )}
    </>
  );
}
