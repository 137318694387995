import {
  SelectOption,
  SelectOptionWithColor,
} from 'src/common/primitives/Select';

interface StyledOptionProps {
  active: boolean;
  selected: boolean;
  option: SelectOption | SelectOptionWithColor;
  startAddornment?: React.ReactNode;
}

export function StyledOption({
  active,
  selected,
  option,
  startAddornment,
}: StyledOptionProps) {
  return (
    <span
      data-testid="styled-option"
      className={`flex items-center gap-1 truncate p-2 ${
        active || selected ? 'bg-mint font-medium text-white' : 'font-normal'
      }`}
    >
      {startAddornment && startAddornment}
      {option.label}
    </span>
  );
}
