import { useCallback, useEffect } from 'react';

import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { FormFieldTime } from 'src/common/FormField/FormFieldTime';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';

import {
  dismissDispatchModal,
  showDispatchModal,
  useDispatchModalToShow,
} from '../dispatcher.slice';

type ActivateWillCallFormFields = {
  pickupTime: string;
};

export function ConfirmWillCallActivationModal() {
  const dispatch = useAppDispatch();
  const tripRepository = useTripRepository();
  const open = useDispatchModalToShow() === 'confirmTripActivation';
  const tripId = useAppSelector(
    (state) => state.dispatcher.modals.confirmTripActivationTripId
  );
  const setOpen = useCallback(
    (open: boolean) => {
      if (open) {
        dispatch(showDispatchModal('confirmTripActivation'));
      } else {
        dispatch(dismissDispatchModal());
      }
    },
    [dispatch]
  );

  const { handleSubmit, control, setValue, reset } =
    useForm<ActivateWillCallFormFields>({
      defaultValues: {
        pickupTime: DateTime.now().toFormat('HH:mm'),
      },
    });

  useEffect(() => {
    reset(
      {
        pickupTime: DateTime.now().toFormat('HH:mm'),
      },
      {
        keepDirty: false,
      }
    );
  }, [setValue, reset, open]);

  const handleConfirm = useCallback(
    async (fields: ActivateWillCallFormFields) => {
      try {
        const [hour, minute] = fields.pickupTime.split(':');
        const update = DateTime.now()
          .set({
            hour: parseInt(hour),
            minute: parseInt(minute),
          })
          .toISO();
        await tripRepository.activateWillCall(tripId ?? '', update);
        dispatch(
          show({
            title: 'Will call activated',
            type: 'success',
          })
        );
        setOpen(false);
      } catch (e) {
        dispatch(
          show({
            title: 'Error activating will call',
            description: formatServerError(e),
            type: 'error',
          })
        );
      }
    },
    [dispatch, setOpen, tripId, tripRepository]
  );

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      title="Confirm Will-Call Activation"
      description="Activating will-call changes pickup time to now. You can also set a different time below (defaults to now)."
      onCancel={() => setOpen(false)}
      onConfirm={handleSubmit(handleConfirm)}
      mode="neutral"
    >
      <Controller
        control={control}
        name="pickupTime"
        render={({ field }) => (
          <FormFieldTime
            type="time"
            label="Pickup time"
            inputProps={{
              value: field.value,
            }}
            onChange={field.onChange}
          />
        )}
      />
    </ConfirmModal>
  );
}
