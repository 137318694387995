const americanExpress = '(?:3[47][0-9]{13})';
const dinersClub = '(?:3(?:0[0-5]|[68][0-9])[0-9]{11})';
const discover = '(?:6(?:011|5[0-9]{2})(?:[0-9]{12}))';
const jcb = '(?:(?:2131|1800|35\\d{3})\\d{11})';
const maestro = '(?:(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{8,15})';
const mastercard =
  '(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})';

const visa = '(?:4[0-9]{12})(?:[0-9]{3})?';

type CCName =
  | 'americanExpress'
  | 'dinersClub'
  | 'discover'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'visa';

type MatcherRecord = Record<CCName, RegExp>;
type MatcherResult = Record<CCName, boolean>;

// JS RegExp objects maintain some state through 'test' executions so to get a
// correct test every time you have to regenerate them
function generateMatchers(): MatcherRecord {
  const ccMatchers: MatcherRecord = {
    americanExpress: new RegExp(americanExpress, 'g'),
    dinersClub: new RegExp(dinersClub, 'g'),
    discover: new RegExp(discover, 'g'),
    jcb: new RegExp(jcb, 'g'),
    maestro: new RegExp(maestro, 'g'),
    mastercard: new RegExp(mastercard, 'g'),
    visa: new RegExp(visa, 'g'),
  };

  return ccMatchers;
}

export function match(value: string): MatcherResult {
  const ccMatchers = generateMatchers();
  const ccs = Object.keys(ccMatchers) as (keyof MatcherRecord)[];

  const result: MatcherResult = {
    americanExpress: false,
    dinersClub: false,
    discover: false,
    jcb: false,
    maestro: false,
    mastercard: false,
    visa: false,
  };

  ccs.forEach((key) => {
    const matcher = ccMatchers[key];
    result[key] = matcher.test(value);
  });

  return result;
}

export function validateNoCC(value: string): string | true {
  const trimmedValue = value
    .replace(/-/g, '')
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .trim();

  const ccMatches = match(trimmedValue);

  if (Object.values(ccMatches).some((n) => n)) {
    return 'Credit card numbers are not allowed in this field';
  }

  return true;
}
