import { SelectOption } from 'src/common/primitives/Select';

import { baseQuery } from './baseQuery';

export function baseQueryAsync(
  query: string,
  options: SelectOption[] | undefined
): Promise<SelectOption[]> {
  if (!options) {
    return Promise.resolve([]);
  }
  return Promise.resolve(baseQuery(query, options));
}
