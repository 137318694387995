import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { toggleDispatchSidebar } from '../../dispatcher.slice';

export default function ToggleDispatchToolbarButton() {
  const dispatch = useAppDispatch();
  const isDispatchSidebarOpen = useAppSelector(
    (state: RootState) => state.dispatcher.isDispatchSidebarOpen
  );

  return (
    <Button
      className="mx-1 px-0"
      variant="ghost"
      onClick={() => {
        dispatch(toggleDispatchSidebar());
      }}
      alt={
        isDispatchSidebarOpen
          ? 'Hide Unassigned Trips'
          : 'View Unassigned Trips'
      }
      ariaLabel={
        isDispatchSidebarOpen
          ? 'Hide Unassigned Trips'
          : 'View Unassigned Trips'
      }
    >
      {isDispatchSidebarOpen ? (
        <ChevronDoubleLeftIcon
          className="h-5 w-5"
          data-testid="chevron-double-left-icon"
        />
      ) : (
        <ChevronDoubleRightIcon
          className="h-5 w-5"
          data-testid="chevron-right-left-icon"
        />
      )}
    </Button>
  );
}
