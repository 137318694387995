import { useVehiclesListQuery } from 'src/common/external/bambi-api/bambiApi';

export function useSchedulableVehicle(vehicleId?: string) {
  const { vehicle, isLoading, isFetching } = useVehiclesListQuery(
    {
      canBeScheduled: true,
    },
    {
      selectFromResult: (result) => {
        return {
          vehicle: result.data?.results.find(
            (vehicle) => vehicle.id === vehicleId
          ),
          ...result,
        };
      },
    }
  );

  return { vehicle, isLoading, isFetching };
}
