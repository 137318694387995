import { AssignedAttendantRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

import { AttendantBadge } from './AttendantBadge';

export function AttendantBadgeList({
  attendants,
}: {
  attendants: AssignedAttendantRead[];
}) {
  return (
    <>
      {attendants.map((attendant) => (
        <AttendantBadge
          key={attendant.attendant ?? ''}
          name={formatFullName(attendant)}
        />
      ))}
    </>
  );
}
