import { NavItem } from './TopLevelNavItemContext';

export const nonRideOrderingPortalTopLevelNavItems: NavItem[] = [
  { name: 'Dispatch', href: '/dispatch' },
  { name: 'Map', href: '/live-map' },
  { name: 'Team', href: '/team' },
  { name: 'Fleet', href: '/fleet' },
  { name: 'Passengers', href: '/passengers' },
  { name: 'Trips', href: '/trips' },
  { name: 'Payers', href: '/payers' },
  { name: 'Pricing', href: '/pricing/pricing-models' },
  { name: 'Billing', href: '/billing/invoices' },
];
