import { ForwardedRef, forwardRef } from 'react';

import {
  AdjustmentsHorizontalIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';

import { Button } from '../primitives/Button';

interface FilterGroupTriggerButtonProps {
  enableAddButton?: boolean;
  onAddClick: () => void;
}
export const FilterGroupTriggerButton = forwardRef(
  function FilterGroupTriggerButton(
    {
      enableAddButton = true,
      onAddClick,
      ...rest
    }: FilterGroupTriggerButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) {
    return (
      <div className="flex flex-row">
        <Button
          dataTestId="filter-popover-trigger"
          variant="split-left"
          {...rest}
          ref={ref}
        >
          <div className="flex flex-row items-center gap-2">
            <AdjustmentsHorizontalIcon className="h-4 w-4" />
            Filter Group
          </div>
        </Button>
        <Button
          variant="split-right"
          onClick={onAddClick}
          disabled={!enableAddButton}
        >
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
    );
  }
);
