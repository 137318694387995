import { CurrencyFormContextField } from 'src/common/FormField/CurrencyFormContextField';
import { SimpleContextFormField } from 'src/common/FormField/SimpleContextFormField';
import { useFormValues } from 'src/common/useFormValues';

import { SchemeConfigFormValues } from '../PricingWizardSchemeConfigurationStep';

export function LateCancellationAmountField() {
  const cancelFeeType =
    useFormValues<SchemeConfigFormValues>().late_cancellation_fee_type;
  return cancelFeeType === 'Percentage' ? (
    <SimpleContextFormField
      fieldPath="late_cancellation_fee_percentage"
      label="Late Cancellation Fee (Percentage)"
      inline
      type="number"
      helperText="Enter the percentage of the trip cost that will be charged for late cancellations."
      rules={{
        validate: (value) => {
          if (!Number.isInteger(Number(value))) {
            return 'Percentage must be an integer';
          }
          if (value < 0 || value > 100) {
            return 'Percentage must be between 0 and 100';
          }
          return true;
        },
      }}
    />
  ) : (
    <CurrencyFormContextField
      fieldPath="late_cancellation_fee_cents"
      label="Late Cancellation Fee"
      inline
      helperText="Enter the fee charged for cancellations made after the allowed period."
    />
  );
}
