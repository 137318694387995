import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import { styled } from '@stitches/react';

export const CheckBox = ({
  checked,
  defaultChecked,
  required,
  onCheckedChange,
  disabled,
  ...props
}: RadixCheckbox.CheckboxProps) => (
  <CheckboxRoot
    className={`focus:ring-indigo-500 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50`}
    checked={checked}
    defaultChecked={defaultChecked}
    required={required}
    onCheckedChange={onCheckedChange}
    disabled={disabled}
    data-testid={props.id}
    {...props}
  >
    <CheckboxIndicator>
      <CheckIcon />
    </CheckboxIndicator>
  </CheckboxRoot>
);

const CheckboxRoot = styled(RadixCheckbox.Root, {
  /* Primary/50 */
  background: '#F9F5FF',
  /* Primary/600 */
  border: '1px solid #7F56D9',
  borderRadius: '4px',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': { backgroundColor: '#F9F5FF' },
});

const CheckboxIndicator = styled(RadixCheckbox.Indicator, {
  /* Primary/600 */
  color: '#7F56D9',
});
