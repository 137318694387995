import { isNil } from 'lodash-es';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';
import { Skeleton } from 'src/common/Skeleton';
import { TripImportedBadge } from 'src/features/trip/management/TripImportedBadge';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

import { isDispatchTrip } from '../isDispatchTrip';

interface TripDetailsStatusProps {
  isTripDataLoading: boolean;
  tripData?: TripRead;
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
}

export function TripDetailsStatus({
  isTripDataLoading,
  trip,
  tripData,
}: TripDetailsStatusProps) {
  return (
    <div className="flex flex-col gap-1 py-2">
      <h4 className="text-sm font-bold">Status</h4>
      <div>
        {isTripDataLoading ? (
          <Skeleton />
        ) : (
          <span className="my-1 flex gap-2">
            <TripStatusBadge status={tripData?.status} />

            {isDispatchTrip(trip) && !isNil(trip.timing?.is_on_time) && (
              <Badge
                color={trip.timing?.is_on_time ? 'green' : 'yellow'}
                label={trip.timing?.is_on_time ? 'on time' : 'delayed'}
              />
            )}
            {tripData && <TripImportedBadge trip={tripData} />}
          </span>
        )}
      </div>
    </div>
  );
}
