import { PricingFormBody } from './PricingFormBody';

export function PricingFormSection({
  showPayerLinkSwitch = true,
}: {
  showPayerLinkSwitch?: boolean;
}) {
  return (
    <div className="flex flex-col gap-y-2">
      <PricingFormBody showPayerLinkSwitch={showPayerLinkSwitch} />
    </div>
  );
}
