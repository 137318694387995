import { MapPinIcon } from '@heroicons/react/24/outline';
import { CellContext } from '@tanstack/react-table';

import { TripSubscriptionRead } from 'src/common/external/bambi-api/bambiApi';
import { removeUnitedStatesFromAddress } from 'src/common/util/removeUnitedStatesFromAddress';

export function TripSubscriptionExpandedNameCell(
  props: CellContext<TripSubscriptionRead, any>
) {
  return (
    <div className="my-3 whitespace-normal">
      <div className="mt-1">Pickup Addresses</div>

      <div className="py-3.5 text-sm font-light">Outbound to</div>
      <div className="flex items-center">
        <div>
          <MapPinIcon className="mr-1 h-5 w-5" />
        </div>
        <p>
          {removeUnitedStatesFromAddress(props.row.original.pickup.address)}
        </p>
      </div>

      <div className="clear-left py-3.5 text-sm font-light">Returning from</div>
      <div className="flex items-center">
        <div>
          <MapPinIcon className="mr-1 h-5 w-5" />
        </div>
        <p>
          {removeUnitedStatesFromAddress(props.row.original.dropoff.address)}
        </p>
      </div>
    </div>
  );
}
