import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { FilterGroupsScreen } from './filterGroups/FilterGroupsScreen';
import OrganizationSettingsManagementRoute from './organization-settings-management/OrganizationSettingsManagementRoute';
import { PaymentsConnectScreen } from './payments/PaymentsConnectScreen';
import { TagManagementRoute } from './tag/management/TagManagementRoute';

export const settingsRoutes: RouteObject[] = [
  {
    index: true,
    element: <OrganizationSettingsManagementRoute />,
  },
  {
    path: 'tags',
    element: <TagManagementRoute />,
  },
  {
    path: 'payments',
    element: <PaymentsConnectScreen />,
  },
  {
    path: 'filter-groups',
    element: <FilterGroupsScreen />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute
        routePath="/settings"
        linkText="Go back to settings"
      />
    ),
  },
];
