interface DayButtonProps {
  day: string;
  selected: boolean;
  onClick: () => void;
}
export default function DayButton({ day, selected, onClick }: DayButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`rounded-full border border-gray-300 py-1 px-3 focus:outline-none ${
        selected ? 'bg-mint text-white' : 'text-gray-500 hover:bg-gray-100'
      }`}
      data-testid={`day-button-${day}`}
      data-is-selected={selected}
    >
      {day.slice(0, 1)}
    </button>
  );
}
