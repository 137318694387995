import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';
import { DateTime } from 'luxon';

import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';

export function createInvalidsFromAssignments(
  assignments: DispatchAssignmentRead[]
): IValidateProps[] {
  const invalids: IValidateProps[] = [];

  assignments.forEach((assignment) => {
    const timeBeforeShift = {
      start: DateTime.fromISO(assignment.start)
        .startOf('day')
        .toUTC()
        .toJSDate(),
      end: DateTime.fromISO(assignment.start).toUTC().toJSDate(),
      resource: assignment.id,
    } as IValidateProps;
    invalids.push(timeBeforeShift);

    const timeAfterShift = {
      start: DateTime.fromISO(assignment.end).toUTC().toJSDate(),
      end: DateTime.fromISO(assignment.end).endOf('day').toUTC().toJSDate(),
      resource: assignment.id,
    } as IValidateProps;
    invalids.push(timeAfterShift);
  });

  return invalids;
}
