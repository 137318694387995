import { FormValues } from 'src/features/add-trip/FormValues';

export function sumTripLegPriceEstimateInCents(
  trips: FormValues['trips']
): number {
  return trips.reduce((acc, curr) => {
    if (
      typeof curr.input_price_cents === 'number' &&
      !isNaN(curr.input_price_cents)
    ) {
      return acc + curr.input_price_cents;
    }

    return acc;
  }, 0);
}
