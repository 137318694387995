import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { formatRTKQueryBaseQueryError } from './formatRTKQueryBaseQueryError';
import { formatRTKQuerySerializedError } from './formatRTKQuerySerializedError';

export function formatRTKQueryError(
  error: FetchBaseQueryError | SerializedError
) {
  if ('status' in error) {
    return formatRTKQueryBaseQueryError(error);
  }

  return formatRTKQuerySerializedError(error);
}
