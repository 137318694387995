import { Route, Routes } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { AddPricingSchemeButton } from './AddPricingSchemeButton';
import { onClearFilters, setPricingSearchTerm } from './pricing.slice';

export function PricingRouteActions() {
  const dispatch = useAppDispatch();
  const { pricingSearchTerm, selectedPayers } = useAppSelector(
    (state) => state.pricing
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setPricingSearchTerm,
    pricingSearchTerm
  );

  return (
    <Routes>
      <Route
        index
        element={
          <div className="flex gap-4">
            {!!selectedPayers.length && (
              <Button
                onClick={() => dispatch(onClearFilters())}
                variant="anchor"
                className="whitespace-nowrap"
                dataTestId="clear-filters-button"
              >
                clear filters
              </Button>
            )}
            <SearchTypeahead
              dataTestId="pricing-search"
              onChange={handleInputChange}
              placeholder="Search pricing schemes"
              value={inputValue}
            />
            <AddPricingSchemeButton />
          </div>
        }
      />
    </Routes>
  );
}
