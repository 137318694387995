import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import { TilledPayoutItem } from 'src/common/external/bambi-api/bambiApi';
import { formatPriceCell } from 'src/common/formatPriceCell';

const columnHelper = createColumnHelper<TilledPayoutItem>();

export const columns = [
  columnHelper.accessor('amount', {
    header: 'Amount',
    cell({ row }) {
      return formatPriceCell(row.original.amount);
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('paid_at', {
    header: 'Paid at',
    cell({ getValue }) {
      const value = getValue();
      if (!value) {
        return '-';
      }

      return DateTime.fromISO(value).toFormat('MM/dd/yyyy');
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('failure_code', {
    header: 'Failure Code (if failed)',
    cell({ getValue }) {
      const value = getValue();
      if (!value) {
        return '-';
      }
      return value;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
];
