import { Route, Routes } from 'react-router';

import { useAppSelector } from 'src/app/store';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { setPricingSearchTerm } from './pricingModel.slice';
export function PricingRouteActions() {
  return (
    <Routes>
      <Route index element={<RouteActions />} />
      <Route path=":selectedPricingModelId" element={<RouteActions />} />
      <Route
        path=":selectedPricingModelId/pricing-type/:selectedPricingTypeId"
        element={<RouteActions />}
      />
    </Routes>
  );
}

export function RouteActions() {
  const pricingSearchTerm = useAppSelector(
    (state) => state.pricingModel.pricingSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setPricingSearchTerm,
    pricingSearchTerm
  );

  return (
    <div className="flex items-center gap-4 pb-2">
      <SearchTypeahead
        dataTestId="pricing-search"
        onChange={handleInputChange}
        placeholder="Search pricing models"
        value={inputValue}
      />
    </div>
  );
}
