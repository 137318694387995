import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { PricingType } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

export function PricingTypeBaseRateBadge({
  pricingType,
}: {
  pricingType: PricingType;
}) {
  return (
    <Badge
      color="gray"
      label={`${defaultCurrencyFormat(
        pricingType.initial_rate
      )} per ${pricingType.unit.toLowerCase()}`}
    />
  );
}
