import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Cross1Icon } from '@radix-ui/react-icons';

import {
  SelectOption,
  SelectOptionWithColor,
} from 'src/common/primitives/Select';
import { SearchTypeahead } from 'src/common/SearchTypeahead';

import { Button } from '../Button';
import { StyledOption } from '../ComboBox/StyledOption';
import { isSelectOptionWithColor } from './isSelectOptionWithColor';

interface MultiSelectProps {
  placeholder?: string;
  options: SelectOption[] | SelectOptionWithColor[];
  onChange?: (value: SelectOption[] | SelectOptionWithColor[]) => void;
  selected?: SelectOption[] | SelectOptionWithColor[];
  disabled?: boolean;
  openUpwards?: boolean;
  query?: string;
  setQuery?: (query: string) => void;
  searchable?: boolean;
  onSelectAll?: () => void;
}

export function MultiSelect({
  placeholder,
  options,
  onChange,
  selected = [],
  disabled,
  openUpwards = false,
  query,
  setQuery,
  searchable = false,
  onSelectAll,
}: MultiSelectProps) {
  // TODO: This is... a ridiculous way of handling this
  // There's some research to be done to find a way to easily do
  // this with headlessui listbox or switch to radix-ui or another multiselect
  // Further, this component should be aware if its options will be rendered and
  // manage repositioning the popover itself
  // However, taking on this bit of tech debt because this is super simple
  // and a rare case where the direction is always static (RunBambiRun driver filter)
  const optionPositionClass = openUpwards ? 'bottom-10' : '';

  return (
    <Listbox
      value={selected}
      onChange={onChange}
      multiple
      disabled={disabled}
      by="value"
    >
      <div className="relative w-full">
        <div className="relative w-full cursor-default">
          {onSelectAll && (
            <div className="flex justify-end">
              <Button
                dataTestId="multi-select-select-all"
                onClick={onSelectAll}
                className="mb-1 py-0 text-sm"
                variant="ghost"
                disabled={disabled}
              >
                Select All
              </Button>
            </div>
          )}
          <Listbox.Button
            placeholder={placeholder}
            className="focus:border-indigo-500 focus-visible:border-indigo-500 relative flex w-full max-w-full cursor-default flex-wrap gap-1 overflow-x-auto rounded-md border border-gray-300 bg-white py-2 px-3 pr-[2em] text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 disabled:cursor-not-allowed disabled:text-gray-300 sm:text-sm"
            data-testid="multi-select-button"
          >
            {selected.length > 0 ? (
              selected.map((option) => (
                <div
                  className="flex items-center gap-1 truncate rounded-md border border-gray-300 px-2 py-1 text-sm"
                  key={option.value}
                  onClick={() => {
                    onChange?.(
                      selected.filter((o) => o.value !== option.value)
                    );
                  }}
                  data-testid="multi-select-selected-option"
                >
                  <Cross1Icon
                    data-testid={`multi-select-selected-option-icon-remove-${option.value}`}
                    scale=".7"
                  />{' '}
                  {option.label}{' '}
                  {isSelectOptionWithColor(option) && (
                    <span
                      className="h-3 w-3 rounded-full"
                      style={{ backgroundColor: option.color }}
                    ></span>
                  )}
                </div>
              ))
            ) : (
              <span>{placeholder || 'Select options'}</span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-4 w-4 text-primary-700" />
            </span>
          </Listbox.Button>
        </div>

        <Listbox.Options
          className={`focus:border-indigo-500 focus:ring-indigo-500 absolute border-gray-300 text-left ${optionPositionClass} z-10 mt-1 w-full flex-wrap rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
        >
          {searchable && typeof setQuery === 'function' && (
            <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
              <SearchTypeahead
                value={query}
                onChange={setQuery}
                placeholder="Search..."
                hint="Search for options"
                dataTestId="multi-select-search"
              />
            </div>
          )}
          <div className="max-h-60 overflow-auto">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option}
                className={({ active, selected }) =>
                  `relative cursor-default select-none ${
                    selected || active ? 'bg-mint text-white' : 'text-gray-900'
                  }`
                }
              >
                {({ selected, active }) => (
                  <StyledOption
                    startAddornment={
                      isSelectOptionWithColor(option) && (
                        <span
                          className="h-3 w-3 rounded-full"
                          style={{ backgroundColor: option.color }}
                        ></span>
                      )
                    }
                    selected={selected}
                    active={active}
                    option={option}
                  />
                )}
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
