import { useRef, useState } from 'react';

import { ColumnDef, ExpandedState } from '@tanstack/react-table';

import { getExpandableColumnDef } from 'src/common/DataGrid/useExpandableTable/getExpandableColumnDef';

// Not really the table itself, but the state management and merged columns
export function useExpandableTable<TData extends unknown>(
  configuredColumns: ColumnDef<TData, any>[]
) {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const columnsRef = useRef([
    getExpandableColumnDef<TData>(),
    ...configuredColumns,
  ]);

  return { expanded, setExpanded, columns: columnsRef.current };
}
