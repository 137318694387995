import { CellContext, Table } from '@tanstack/react-table';

import { DuplicatePassenger } from './types';

export const isSameAsBase = (
  key: keyof DuplicatePassenger,
  row: DuplicatePassenger,
  table: Table<DuplicatePassenger>
) => {
  const { rows } = table.getRowModel();
  const parentRow = rows.find(
    (r) => r.original.id === (row.parentId ?? row.id)
  );
  const isSameAsBase = row[key] === parentRow?.original[key];

  return isSameAsBase;
};

interface IsSameCellProps extends CellContext<DuplicatePassenger, any> {
  comparator?: (parentRow: DuplicatePassenger) => boolean;
  children?: React.ReactNode;
}

export function IsSameCell({
  column,
  row,
  table,
  getValue,
  comparator,
  children,
}: IsSameCellProps) {
  const { rows } = table.getRowModel();
  const parentRow = rows.find(
    (r) => r.original.id === (row.original.parentId ?? row.id)
  );

  const isBase = !row.original.parentId;

  const isSame =
    comparator && parentRow
      ? comparator(parentRow.original)
      : isSameAsBase(
          column.id as keyof DuplicatePassenger,
          row.original,
          table
        );

  return (
    <div
      data-testid={`column-${column.id}`}
      className={isBase ? undefined : isSame ? 'text-mint' : 'text-red-800'}
    >
      {children ? children : (getValue() as string)}
    </div>
  );
}
