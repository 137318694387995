import { SelectOption } from './SelectOption';

export function isSelectOption(
  selectOption: any
): selectOption is SelectOption {
  return (
    !!selectOption &&
    typeof selectOption === 'object' &&
    'value' in selectOption &&
    typeof selectOption.value === 'string' &&
    'label' in selectOption &&
    typeof selectOption.label === 'string'
  );
}
