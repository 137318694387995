export interface SectionHeadingProps {
  heading: string;
  subHeading?: string;
  className?: string;
}

export function SectionHeading({
  heading,
  subHeading,
  className,
}: SectionHeadingProps) {
  return (
    <div className={`mb-4 ${className ?? ''}`}>
      <div className="text-2xl">{heading}</div>
      {subHeading && (
        <div className="mt-1 text-xs text-slate-400">{subHeading}</div>
      )}
    </div>
  );
}
