import { ReactNode } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { DropdownMenuArrow } from './DropdownMenuArrow';
import { DropdownMenuContent } from './DropdownMenuContent';

export function DropdownMenuBasic({
  trigger,
  children,
}: {
  trigger: ReactNode;
  children: ReactNode;
}) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenuContent side="bottom" className="z-40">
          {children}
          <DropdownMenuArrow />
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
