import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { useFormValues } from 'src/common/useFormValues';
import { FormValues } from 'src/features/add-trip/FormValues';

import { sumTripLegPriceEstimateInCents } from './sumTripLegPriceEstimateInCents';

export function useTripFormPriceEstimateResult(): string {
  const { trips } = useFormValues<FormValues>();
  const total = sumTripLegPriceEstimateInCents(trips);
  return defaultCurrencyFormat(total.toString());
}
