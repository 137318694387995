import { useMemo } from 'react';

import {
  InvoiceNestedLineItem,
  InvoiceRead,
} from 'src/common/external/bambi-api/bambiApi';

export function useInvoiceTotal(
  invoice?: InvoiceRead | null,
  lineItems?: InvoiceNestedLineItem[]
) {
  const activeLineItems = useMemo(
    () => lineItems ?? invoice?.line_items ?? [],
    [invoice?.line_items, lineItems]
  );

  const invoiceTotal = useMemo(() => {
    return activeLineItems.reduce((acc, item) => {
      return (acc += item.amount_cents);
    }, 0);
  }, [activeLineItems]);

  const invoiceTotalDue = useMemo(() => {
    const paymentsTotal =
      invoice?.payments?.reduce((acc, payment) => {
        return (acc += payment.amount_total_cents);
      }, 0) ?? 0;

    return invoiceTotal - paymentsTotal;
  }, [invoiceTotal, invoice?.payments]);

  return {
    invoiceTotal,
    invoiceTotalDue,
  };
}
