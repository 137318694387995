import {
  AssignedVehicleRead,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';

export const convertVehicleToAssignedVehicle = (
  vehicle: VehicleRead
): AssignedVehicleRead => {
  return {
    vehicle: vehicle.id,
    nickname: vehicle.nickname || '',
    category: vehicle.category,
    color: vehicle.color || '',
    make: vehicle.make || '',
    model: vehicle.model || '',
    year: vehicle.year || 0,
    license_plate: vehicle.license_plate || '',
  };
};
