import { useMemo } from 'react';

import * as Accordion from 'src/common/Accordion';
import { TripMessageRead } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

import { TripSummaryMessageItemHeader } from './TripSummaryMessageItemHeader';

export function TripSummaryMessageItem({
  tripMessage,
  isSelected,
  onClick,
}: {
  tripMessage: TripMessageRead;
  isSelected: boolean;
  onClick: () => void;
}) {
  const badgeColor = useMemo(() => {
    switch (tripMessage.message.status) {
      case 'read':
      case 'delivered':
      case 'received':
      case 'receiving':
        return 'green';
      case 'failed':
      case 'canceled':
      case 'undelivered':
        return 'red';
      case 'queued':
      case 'scheduled':
      case 'sending':
      case 'sent':
        return 'yellow';
      default:
        return 'gray';
    }
  }, [tripMessage.message.status]);

  return (
    <Accordion.Item value={`message-${tripMessage.id}`} onClick={onClick}>
      <div
        className={`relative rounded-lg border border-solid border-[#eaecf0] bg-gray-50 hover:cursor-pointer`}
      >
        <style>
          {`
            [data-radix-collection-item][data-state="open"] {
              background-color: F9FAFB;
              border-bottom: 2px solid #EAECF0;
            }
          `}
        </style>
        <Accordion.Trigger>
          <TripSummaryMessageItemHeader
            tripMessage={tripMessage}
            isSelected={isSelected}
          />
        </Accordion.Trigger>

        <Accordion.Content>
          <div className="p-2 text-sm">
            {tripMessage.message.body}
            <Badge
              label={tripMessage.message.status ?? ''}
              color={badgeColor}
            />
          </div>
        </Accordion.Content>
      </div>
    </Accordion.Item>
  );
}
