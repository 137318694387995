import { DateTime } from 'luxon';

// DispatchCalendar uses Mobiscroll, which is configured to
// display data in the timezone of the current org's headquarters.
// I expected to have to return this value in UTC, but it seems
// like this isn't how Mobiscroll works
export function getMobiscrollSelectedDate(selectedDate: string) {
  const selectedDateISO = DateTime.fromISO(selectedDate).toISODate();
  const today = DateTime.local().toISODate();

  if (selectedDateISO === today) {
    return DateTime.local().toISO();
  }

  if (selectedDateISO < today) {
    return selectedDateISO;
  }

  // setting to 7am scrolls calendar to 7am
  return DateTime.fromISO(selectedDate).set({ hour: 7 }).toISO();
}
