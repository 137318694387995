import { Combobox as HeadlessCombobox } from '@headlessui/react';

import { SelectOption } from 'src/common/primitives/Select';

import { StyledOption } from './StyledOption';

export function RenderOptions({
  options,
  query,
  renderOption,
}: {
  options: SelectOption[];
  query: string;
  renderOption?: (
    option: SelectOption,
    selected: boolean,
    active: boolean
  ) => React.ReactNode;
}) {
  return (
    <>
      {options.length === 0 && query !== '' ? (
        <div className="relative cursor-default select-none py-2 px-2 text-gray-700">
          Nothing found.
        </div>
      ) : (
        options.map((option) => {
          return (
            <HeadlessCombobox.Option
              key={option.value}
              className={({ active, selected, disabled }) =>
                `relative cursor-default select-none ${
                  selected || active ? 'bg-mint text-white' : 'text-gray-900'
                } ${disabled ? 'opacity-50' : ''}`
              }
              value={option}
              disabled={option.disabled}
            >
              {({ selected, active }) => {
                if (renderOption) {
                  return <>{renderOption(option, selected, active)}</>;
                }

                return (
                  <StyledOption
                    key={option.value}
                    option={option}
                    selected={selected}
                    active={active}
                  />
                );
              }}
            </HeadlessCombobox.Option>
          );
        })
      )}
    </>
  );
}
