import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { onPricingModelDetailsEditCancel } from 'src/features/pricingModel/pricingModel.slice';

import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';

export function PricingModelCancelEditButton() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedPricingModel = useSelectedPricingModel();
  return (
    <Button
      onClick={() => {
        dispatch(onPricingModelDetailsEditCancel());
        if (selectedPricingModel) {
          navigate(`/pricing/pricing-models/${selectedPricingModel.id}`);
        } else {
          navigate(`/pricing/pricing-models`);
        }
      }}
    >
      Cancel
    </Button>
  );
}
