export interface INamedEntity {
  first_name?: string;
  last_name?: string;
  full_name?: string;
}

export function formatFullName(entity: INamedEntity) {
  return (
    entity.full_name ||
    `${entity.first_name || ''} ${entity.last_name || ''}`.trim()
  );
}
