import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { DatePicker } from 'src/common/date/DatePicker';
import { PayerOrganizationRead } from 'src/common/external/bambi-api/bambiApi';
import { useLazyGetTripsExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormFieldCheckbox } from 'src/common/FormField/FormFieldCheckbox';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { Select } from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect';
import { useAuth } from 'src/features/auth/useAuth';

import {
  DownloadCsvDetails,
  setShowDownloadDetailsModal,
} from '../../trip.slice';
import { datePickerInput } from '../datePickerInput';
import { usePassengerMultiSelectData } from '../usePassengerMultiSelectData';
import { usePayerSelectData } from '../usePayerSelectData';

export function DownloadDetailsModal() {
  const auth = useAuth();
  const dispatch = useDispatch();

  const open = useSelector(
    (state: RootState) => state.trip.showDownloadDetailsModal
  );

  const downloadCsvDetail = useSelector(
    (state: RootState) => state.trip.downloadCsvDetail
  );

  const [startDate, setStartDate] = useState<DateTime | undefined>(undefined);
  const [endDate, setEndDate] = useState<DateTime | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [trigger] = useLazyGetTripsExportAsCsvQuery();
  const [isMiniDownload, setIsMiniDownload] = useState(false);

  const {
    selectedPassengers,
    setSelectedPassengers,
    passengerSearchTerm,
    setPassengerSearchTerm,
    passengerOptions,
  } = usePassengerMultiSelectData();

  const {
    payerOptions,
    selectedPayers,
    setSelectedPayers,
    payerSearchTerm,
    setPayerSearchTerm,
    slugifyPayerName,
    payerData,
  } = usePayerSelectData();

  const closeModal = () => {
    setSelectedPayers([]);
    setPayerSearchTerm('');
    setError(undefined);
    setLoading(false);
    setStartDate(undefined);
    setEndDate(undefined);
    setSelectedPassengers([]);
    setIsMiniDownload(false);
    dispatch(setShowDownloadDetailsModal(false));
  };

  const handleSubmit = async (downloadType: DownloadCsvDetails) => {
    let outputSet =
      downloadType === DownloadCsvDetails.PAYER && selectedPayers.length
        ? slugifyPayerName(selectedPayers[0].label)
        : '';
    if (downloadType === DownloadCsvDetails.EVERTRANSIT) {
      outputSet = 'evertransit';
    }
    if (downloadType === DownloadCsvDetails.KINETIK) {
      outputSet = 'kinetik';
    }

    try {
      setError(undefined);
      setLoading(true);
      await trigger({
        rangeStart: startDate?.startOf('day').toISO(),
        rangeEnd: endDate?.endOf('day').toISO(),
        payers: selectedPayers.map((payer) => payer.value).join(',') || '',
        passengers:
          selectedPassengers?.map((passenger) => passenger.value).join(',') ||
          '',
        outputSet: isMiniDownload ? 'mini' : outputSet,
        organizationId: auth.currentOrganizationId || '',
        format: 'csv',
      }).unwrap();

      closeModal();
    } catch (error) {
      const errMsg = 'There was an error downloading the data.';
      setError(errMsg);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (downloadCsvDetail !== DownloadCsvDetails.KINETIK) {
      return;
    }

    const kinetikPayers =
      payerData?.results.filter((p) => p.has_kinetik_account) ?? [];

    const kinetikPayersIdMap = kinetikPayers.reduce((acc, p) => {
      return {
        ...acc,
        [p.id]: p,
      };
    }, {} as Record<string, PayerOrganizationRead>);

    const autoSelectOptions = payerOptions.filter(
      (option) => !!kinetikPayersIdMap[option.value]
    );

    setSelectedPayers(autoSelectOptions);
  }, [payerData, payerOptions, setSelectedPayers, downloadCsvDetail]);

  return (
    <Modal open={open} setOpen={closeModal} contentClassnames="max-w-6xl">
      <h1 className="text-2xl">
        {downloadCsvDetail === DownloadCsvDetails.EVERTRANSIT
          ? 'Evertransit details download'
          : 'Select details for this list to download'}
      </h1>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {downloadCsvDetail === DownloadCsvDetails.PAYER && (
        <p className="pb-4 text-gray-600">
          Downloaded fields are determined by payer.
        </p>
      )}

      <div>
        <div>Select the time range</div>
        <div className="flex items-center justify-between gap-2 pt-2">
          <DatePicker
            triggerElement={datePickerInput('Start Date', startDate)}
            onChange={setStartDate}
            value={startDate || DateTime.now()}
          />
          ~
          <DatePicker
            triggerElement={datePickerInput('End Date', endDate)}
            onChange={setEndDate}
            value={endDate || DateTime.now()}
          />
        </div>
      </div>
      {downloadCsvDetail === DownloadCsvDetails.EVERTRANSIT ? null : (
        <div className="py-4">
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Payer
          </label>
          {downloadCsvDetail === DownloadCsvDetails.PAYER ? (
            <Select
              selected={selectedPayers.length ? selectedPayers[0] : undefined}
              placeholder="Select a payer"
              options={payerOptions}
              onChange={(selected) => {
                if (selected) setSelectedPayers([selected]);
              }}
            ></Select>
          ) : (
            <MultiSelect
              placeholder="Select payers or select none to download all"
              searchable
              query={payerSearchTerm}
              setQuery={setPayerSearchTerm}
              selected={selectedPayers}
              options={payerOptions}
              onChange={(selected) => {
                if (selected.length) setSelectedPayers(selected);
              }}
            ></MultiSelect>
          )}
        </div>
      )}
      {downloadCsvDetail === DownloadCsvDetails.EVERTRANSIT ||
      downloadCsvDetail === DownloadCsvDetails.PAYER ? null : (
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Passengers
          </label>
          <MultiSelect
            placeholder="Select passengers or select none to download all"
            searchable
            query={passengerSearchTerm}
            setQuery={setPassengerSearchTerm}
            selected={selectedPassengers}
            options={passengerOptions}
            onChange={(selected) => {
              if (selected) setSelectedPassengers(selected);
            }}
          ></MultiSelect>
        </div>
      )}
      {downloadCsvDetail === DownloadCsvDetails.ALL && (
        // TODO: This modal needs some vertical rhythm using flex flex-col gap-1
        // but... setting a top margin keeps this change small
        <div className="mt-2">
          <FormFieldCheckbox
            label="Only download condensed report"
            type="checkbox"
            value={isMiniDownload}
            inputProps={{
              checked: isMiniDownload,
              id: 'mini-download-checkbox',
            }}
            onChange={(val: boolean) => {
              setIsMiniDownload(val);
            }}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 pt-6">
        <Button disabled={loading} onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          dataTestId="download-csv"
          disabled={loading}
          variant="primary"
          onClick={() => handleSubmit(downloadCsvDetail)}
        >
          {loading ? 'Exporting' : 'Export as csv'}
        </Button>
      </div>
    </Modal>
  );
}
