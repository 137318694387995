import type { VersionNumber } from './parseAppVersion';

export const versionIsOutdated = (
  version: VersionNumber,
  latest: VersionNumber
): boolean => {
  if (latest.major > version.major) {
    return true;
  }
  return latest.minor - version.minor > 1;
};
