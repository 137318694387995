import { SelectOption } from 'src/common/primitives/Select';

import { stringIncludesQuery } from './stringIncludesQuery';

export function baseOptionComparator(
  option: SelectOption,
  query: string
): boolean {
  return stringIncludesQuery(option.label, query);
}
