import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import DateRangeBadge from 'src/common/date/DateRangeBadge';
import { FilterGroupRead } from 'src/common/external/bambi-api/bambiApi';
import { FilterGroup } from 'src/common/FilterGroup/FilterGroup';
import { useInitializeFilterGroup } from 'src/common/FilterGroup/useInitializeFilterGroup';
import { FormFieldCheckbox } from 'src/common/FormField/FormFieldCheckbox';
import { Button } from 'src/common/primitives/Button';
import { hasRangeFilter } from 'src/common/primitives/NumberRangeFilterPopover/NumberRangeFilterPopover';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import {
  clearFilters,
  setFilterDateRange,
  setFilterHasPayments,
  setFilterSearchTerm,
  setSelectedFilterGroup,
} from './invoice.slice';

export function InvoiceGlobalFilters() {
  const dispatch = useAppDispatch();
  const rangeStart = useAppSelector((state) => state.invoice.filterDateStart);
  const rangeEnd = useAppSelector((state) => state.invoice.filterDateEnd);
  const hasPayments = useAppSelector(
    (state) => state.invoice.filterHasPayments
  );
  const selectedTripPayers = useAppSelector(
    (state) => state.invoice.filterTripPayers
  );
  const selectedPaymentStatus = useAppSelector(
    (state) => state.invoice.filterPaymentStatus
  );
  const totalAmountRange = useAppSelector(
    (state) => state.invoice.filterTotalAmount
  );
  const totalAmountDueRange = useAppSelector(
    (state) => state.invoice.filterTotalAmountDue
  );
  const searchTerm =
    useAppSelector((state) => state.invoice.filterSearchTerm) ?? '';

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setFilterSearchTerm,
    searchTerm
  );

  const isFiltered =
    !!rangeStart ||
    !!rangeEnd ||
    !!hasPayments ||
    !!selectedTripPayers?.length ||
    !!selectedPaymentStatus?.length ||
    hasRangeFilter(totalAmountRange) ||
    hasRangeFilter(totalAmountDueRange);

  const handleInitializeFilterGroup = useCallback(
    (group: FilterGroupRead) => {
      dispatch(setSelectedFilterGroup(group));
    },
    [dispatch]
  );

  // Initialize filter groups from URL
  useInitializeFilterGroup(handleInitializeFilterGroup);

  return (
    <div className="flex flex-row items-center gap-2">
      <FilterGroup
        filterset="InvoiceFilter"
        filters={makeFilterGroupFilters({
          rangeStart,
          rangeEnd,
          payers: selectedTripPayers,
          statuses: selectedPaymentStatus,
          totalInvoiceMin: totalAmountRange?.min,
          totalInvoiceMax: totalAmountRange?.max,
          totalInvoiceDueMin: totalAmountDueRange?.min,
          totalInvoiceDueMax: totalAmountDueRange?.max,
          hasPayment: hasPayments,
        })}
        onSelectFilterGroup={(group) => {
          dispatch(clearFilters());
          dispatch(setSelectedFilterGroup(group));
        }}
      />
      <SearchTypeahead
        placeholder="Search Invoices"
        onChange={handleInputChange}
        dataTestId="invoice-search-term-filter"
        value={inputValue}
      />
      <div>
        {rangeStart && rangeEnd ? (
          <DateRangeBadge
            from={rangeStart}
            to={rangeEnd}
            onClear={() => {
              dispatch(
                setFilterDateRange({
                  rangeStart: undefined,
                  rangeEnd: undefined,
                })
              );
            }}
          />
        ) : null}
      </div>
      <FormFieldCheckbox
        label="Has Payment"
        type="checkbox"
        value={hasPayments ?? false}
        inputProps={{
          checked: hasPayments ?? false,
          id: 'invoice-has-payment-filter',
        }}
        onChange={(val: boolean) => {
          dispatch(setFilterHasPayments(val));
        }}
      />
      {isFiltered ? (
        <Button
          onClick={() => {
            dispatch(clearFilters());
          }}
          variant="anchor"
        >
          clear filters
        </Button>
      ) : null}
    </div>
  );
}

interface MakeFilterGroupFiltersProps {
  rangeStart?: string;
  rangeEnd?: string;
  payers?: string[];
  statuses?: string[];
  totalInvoiceMin?: number;
  totalInvoiceMax?: number;
  totalInvoiceDueMin?: number;
  totalInvoiceDueMax?: number;
  hasPayment?: boolean;
}

/**
 * Creates a set of filters which match the API filters
 */
function makeFilterGroupFilters({
  rangeStart,
  rangeEnd,
  payers,
  statuses,
  totalInvoiceMin,
  totalInvoiceMax,
  totalInvoiceDueMin,
  totalInvoiceDueMax,
  hasPayment,
}: MakeFilterGroupFiltersProps) {
  return {
    range_start: rangeStart,
    range_end: rangeEnd,
    trip_payer__in: payers,
    overall_payment_status__in: statuses,
    total_amount_min: totalInvoiceMin,
    total_amount_max: totalInvoiceMax,
    due_min: totalInvoiceDueMin,
    due_max: totalInvoiceDueMax,
    has_payment: hasPayment,
  };
}
