import SecondsToMinutesField from 'src/common/SecondsToMinutesField';

import { SettingsRowHeader } from './components/SettingsRowHeader';
export default function BufferTimeSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Pickup/Appointment Buffer Times"
        description="Organization buffer times in minutes."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SecondsToMinutesField
            fieldPath={`pickup_default_early_seconds`}
            label="Default Pickup Early"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`pickup_default_late_seconds`}
            label="Default Pickup Late"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`pickup_appointment_early_seconds`}
            label="Pickup Appointment Early"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`pickup_appointment_late_seconds`}
            label="Pickup Appointment Late"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`pickup_will_call_early_seconds`}
            label="Pickup Will Call Early"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`pickup_will_call_late_seconds`}
            label="Pickup Will Call Late"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`appointment_early_arrival_window_seconds`}
            label="Appointment Early Arrival Window"
          />
        </div>
      </div>
    </div>
  );
}
