import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryDefaultTextDisplay } from './TripSummaryDefaultTextDisplay';

export function TripSummaryFormattedPickupTime({
  tripEvent,
}: {
  tripEvent: TripEventRead;
}) {
  const newPickupAt = tripEvent.raw_json_data?.pickup_at;
  const formattedPickupAt = newPickupAt
    ? formatTimeFrom24HourTo12Hour(newPickupAt)
    : 'unknown';
  return <TripSummaryDefaultTextDisplay text={formattedPickupAt} />;
}
