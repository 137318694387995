import { useEffect } from 'react';

export function useScrollToFormErrors(
  error: string,
  serverErrors: string | null
) {
  useEffect(() => {
    if (error || serverErrors) {
      document
        .getElementById('form-errors')
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error, serverErrors]);
}
