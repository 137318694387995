import { createColumnHelper } from '@tanstack/react-table';

import { FilterGroupRead } from 'src/common/external/bambi-api/bambiApi';

import { ManageFilterGroupCell } from './ManageFilterGroupCell';

const helper = createColumnHelper<FilterGroupRead>();
export const columns = [
  helper.accessor('group_name', {
    header: 'Name',
  }),
  helper.accessor('description', {
    header: 'Description',
  }),
  helper.accessor('slug', {
    header: 'URL Slug',
  }),
  helper.accessor('filterset', {
    header: 'Set',
  }),
  helper.accessor('filters', {
    header: 'Filters',
    cell({ getValue }) {
      return Object.keys(getValue()).length;
    },
  }),
  helper.display({
    header: 'Manage',
    enableHiding: false,
    cell({ row }) {
      return <ManageFilterGroupCell id={row.original.id} />;
    },
  }),
];
