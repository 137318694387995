import { DateTime } from 'luxon';

import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  TripCreateTripDataRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { GoogleMapsAddressLink } from 'src/common/GoogleMapsAddressLink';
import { Badge } from 'src/common/primitives/Badge';

import { TripImportedBadge } from '../management/TripImportedBadge';
import { TripSpaceTypeBadge } from '../management/TripSpaceTypeBadge';
import { TripEventLineDisplay } from './TripEventLineDisplay';

export function TripSummaryCreateParsedTrip({
  parsedTrip,
}: {
  parsedTrip: TripCreateTripDataRead;
}) {
  return (
    <div className="flex flex-col gap-y-1">
      <TripEventLineDisplay
        label="Passenger"
        value={parsedTrip.passenger_name || "Unknown (Shouldn't happen)"}
      />

      {/*
        TODO sometimes the value is truthy but isn't a proper ISO string and we get an Invalid DateTime
        We should fall back to rendering the raw value for debugging
        This is probably also due to garbage data in my local anyway
      */}
      {parsedTrip.scheduled_pickup_at && (
        <>
          <TripEventLineDisplay
            label="Date"
            value={DateTime.fromISO(parsedTrip.scheduled_pickup_at).toFormat(
              'yyyy-MM-dd'
            )}
          />
          <TripEventLineDisplay
            label="Pickup Time"
            value={
              formatTimeFrom24HourTo12Hour(parsedTrip.scheduled_pickup_at) ||
              parsedTrip.scheduled_pickup_at
            }
          />
        </>
      )}
      {parsedTrip.appointment_at && (
        <TripEventLineDisplay
          label="Appointment Time"
          value={
            formatTimeFrom24HourTo12Hour(parsedTrip.appointment_at) ||
            parsedTrip.appointment_at
          }
        />
      )}

      {/* TODO: Allow value to be a component and use the google link component */}
      {parsedTrip.pickup && (
        <TripEventLineDisplay
          label="Pickup"
          value={
            <GoogleMapsAddressLink
              address={parsedTrip.pickup}
              className="text-sm font-medium text-gray-500 hover:underline dark:text-gray-400"
            />
          }
        />
      )}
      {parsedTrip.dropoff && (
        <TripEventLineDisplay
          label="Dropoff"
          value={
            <GoogleMapsAddressLink
              address={parsedTrip.dropoff}
              className="text-sm font-medium text-gray-500 hover:underline dark:text-gray-400"
            />
          }
        />
      )}

      {/* TODO: Allow value to be a component and use the badge components */}
      {parsedTrip.space_type && (
        <TripEventLineDisplay
          label="Space Type"
          value={TripSpaceTypeBadge({
            spaceType: parsedTrip.space_type,
          })}
        />
      )}
      {parsedTrip.service_type && (
        <TripEventLineDisplay
          label="Service Type"
          value={<Badge label={parsedTrip.service_type} color="blue" />}
        />
      )}

      {typeof parsedTrip.input_price_cents === 'number' && (
        <TripEventLineDisplay
          label="Price"
          value={defaultCurrencyFormat(parsedTrip.input_price_cents)}
        />
      )}

      {parsedTrip.payer_display_name && (
        <TripEventLineDisplay
          label="Payer"
          value={parsedTrip.payer_display_name}
        />
      )}

      {/*
        TODO: Trip imported badge handles whether it should display itself and probably shouldn't
        Making it a simpler presentational component would avoid this casting

        Extra div is important here to reset the flex context. Otherwise the badge will be stretched
      */}
      <div>
        <TripImportedBadge
          trip={
            {
              one_schema_template_id: parsedTrip.one_schema_template_id,
              one_schema_template_multi_id:
                parsedTrip.one_schema_template_multi_id,
              intermediary_id: parsedTrip.intermediary_id,
            } as TripRead
          }
        />
      </div>
    </div>
  );
}
