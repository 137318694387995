import { datadogRum } from '@datadog/browser-rum';
import { useRouteError } from 'react-router-dom';

import { Layout } from 'src/common/primitives/Layout';

// TODO: This needs some work
// Be cool to use something from https://tailwindui.com/components/marketing/feedback/404-pages
// but make it more general
export function DefaultErrorElement() {
  const error = useRouteError();
  datadogRum.addError(error);

  return (
    <Layout>
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-6 lg:px-8">
        <div className="my-auto flex-shrink-0 py-16 sm:py-32">
          <p className="text-base font-bold text-mint">
            Something doesn't look right...
          </p>
          <p className="mt-2 text-base text-gray-500">
            The application experienced an error. Please let us know so we can
            fix it!
          </p>
          <p className="mt-2 text-base text-gray-500">
            You may be able to proceed by refreshing and trying again.
          </p>
        </div>
      </main>
    </Layout>
  );
}
