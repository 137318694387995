import * as Tooltip from '@radix-ui/react-tooltip';

import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

export default function AssignmentAttendantsToolTip({
  assignment,
}: {
  assignment: DispatchAssignmentRead;
}) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div
            data-testid="attendant-tooltip"
            className="relative flex items-center justify-center rounded-md border border-gray-500 bg-[#ffffff] px-1 text-[.65rem] font-medium leading-none text-gray-900"
          >
            +{assignment.attendants.length}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            <div className="rounded border border-gray-200 bg-white py-2 px-4 shadow-lg">
              {assignment.attendants.map((attendant) => (
                <div
                  data-testid="attendant-tooltip-item"
                  key={attendant.id}
                  className="flex flex-row gap-2"
                >
                  <div className="text-light-text-primary relative text-left">
                    {formatFullName(attendant)}
                  </div>
                </div>
              ))}
            </div>
            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
