import { OverallPaymentStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { Tag } from 'src/common/Tag/Tag';

import { TagColumnWrapper } from './TagColumnWrapper';
import { usePaymentStatusToLabel } from './useStatusToLabel';

interface PaymentStatusCellProps {
  status: OverallPaymentStatusEnum;
  anyPaymentsProcessing: boolean;
}
export function PaymentStatusCell({
  status,
  anyPaymentsProcessing,
}: PaymentStatusCellProps) {
  const result = usePaymentStatusToLabel(status);

  let tagContent: JSX.Element | null = <Tag {...result} />;

  if (anyPaymentsProcessing) {
    tagContent = <Tag color="#c1d8ee" label="Processing" />;
  } else if (!status || status === 'unknown') {
    tagContent = null;
  }

  return <TagColumnWrapper>{tagContent}</TagColumnWrapper>;
}
