import { useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import { DeleteModalProps } from 'src/common/DeleteModal';
import { useTripsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useDispatchModalToShow } from 'src/features/dispatch/dispatcher.slice';

import { RejectRequestTripDetails } from './RejectRequestTripDetails';
import { useRejectTripRequestConfirmModal } from './useRejectTripRequestConfirmModal';

export const useRejectTripRequestConfirmModalProps = (): DeleteModalProps => {
  const modalToShow = useDispatchModalToShow();
  const { error, onRejectConfirm, dismissModal } =
    useRejectTripRequestConfirmModal();

  const requestedTripIdsToRejectToReasonCodes = useAppSelector(
    (state) => state.dispatcher.modals.requestedTripIdsToRejectToReasonCodes
  );

  const requestedTripIdsToReject = useMemo(() => {
    return Object.keys(requestedTripIdsToRejectToReasonCodes || {});
  }, [requestedTripIdsToRejectToReasonCodes]);

  const { data: tripData, isFetching: isFetchingTrips } = useTripsListQuery(
    {
      idIn: requestedTripIdsToReject,
    },
    {
      skip: !requestedTripIdsToReject.length,
    }
  );

  return {
    open: modalToShow === 'rejectRequestConfirm',
    setOpen: (isOpen) => {
      if (!isOpen) {
        dismissModal();
      }
    },
    onCancel: () => dismissModal(),
    onConfirm: () => onRejectConfirm(),
    title:
      requestedTripIdsToReject.length > 1
        ? 'Reject these trips?'
        : 'Reject this trip?',
    error,
    confirmText: 'Reject',
    children: isFetchingTrips ? (
      <div className="flex justify-center">
        <LoadingIndicator />
      </div>
    ) : (
      <RejectRequestTripDetails trips={tripData?.results || []} />
    ),
    contentClassnames: 'sm:max-w-full',
  };
};
