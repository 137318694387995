import { useModal } from 'src/common/modals/useModal';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

import {
  PricingWizard,
  PricingWizardMode,
} from './management/PricingWizard/PricingWizard';

export function PricingWizardModal({
  pricingSchemeId,
  open,
  setOpen,
  mode,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  pricingSchemeId?: string;
  mode: PricingWizardMode;
}) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onInteractOutside={(e) => {
        e.preventDefault();
      }}
    >
      <PricingWizard
        pricingSchemeId={pricingSchemeId}
        onCancel={() => {
          setOpen(false);
        }}
        mode={mode}
        onSuccess={() => {
          setOpen(false);
        }}
      />
    </Modal>
  );
}

export function AddPricingSchemeButton() {
  const { open, setOpen, openModal } = useModal();
  return (
    <>
      <Button
        dataTestId="new-pricing-scheme-button"
        onClick={openModal}
        variant="primary"
      >
        Add Pricing Scheme
      </Button>
      <PricingWizardModal open={open} setOpen={setOpen} mode="create" />
    </>
  );
}
