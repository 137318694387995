import { AssignmentTripLocation } from 'src/common/external/bambi-api/bambiApi';

import { LocationMetadata } from './LocationMetadata';

// Shamelessly ripped from bambi-rn
// At some point we should think about a shared package
export const mapLocationToMetadata = (
  location: AssignmentTripLocation
): LocationMetadata => {
  if (!(location.address_number && location.street)) {
    return {
      displayName: '',
      mappingAddress: '',
    };
  }

  const streetDesc = `${location.address_number} ${location.street}`;
  const displayName = `${streetDesc}, ${location.municipality}`;

  //exclude location.detail from mapping address as it may confuse the map search query
  let mappingAddress = streetDesc;
  if (location.municipality) {
    mappingAddress += `, ${location.municipality}`;
  }

  if (location.region) {
    mappingAddress += `, ${location.region}`;
  }

  if (location.postal_code) {
    mappingAddress += ` ${location.postal_code}`;
  }

  return {
    displayName,
    mappingAddress,
  };
};
