import { minutesToSeconds, secondsToMinutes } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { FormContextField } from 'src/common/FormField/FormContextField';

import { FormField } from './FormField';

export default function SecondsToMinutesField({
  fieldPath,
  disabled,
  label,
}: {
  fieldPath: string;
  disabled?: boolean;
  label: string;
}) {
  const form = useFormContext();
  const { setValue } = form;

  return (
    <FormContextField name={fieldPath}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label={label}
            error={fieldState.error?.message?.toString()}
            type="number"
            inputProps={{
              id: fieldPath,
              ...field,
              disabled,
              onChange: (e) => {
                const value = e.target.value;
                setValue(
                  fieldPath,
                  value ? minutesToSeconds(parseInt(value)) : ''
                );
              },
              value: field.value ? secondsToMinutes(field.value) : '',
            }}
          />
        );
      }}
    </FormContextField>
  );
}
