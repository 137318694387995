/**
 * Display component to wrap a section within an inset well
 */
export interface WellProps {
  children: React.ReactNode;
  testId?: string;
}
export function Well({ children, testId }: WellProps) {
  return (
    <>
      <div
        data-testid={testId}
        className="mt-4 flex w-full flex-col gap-2 rounded border border-slate-300 bg-gray-100 p-4 print:hidden"
      >
        {children}
      </div>
      <div className="screen:hidden">{children}</div>
    </>
  );
}
