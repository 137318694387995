import { MbscCalendarEvent } from '@mobiscroll/react';
import { find } from 'lodash-es';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';

import { isDispatchTrip } from './TripCalendarEvent/isDispatchTrip';

export function createEventsFromTripWaitTimes(
  trips:
    | DispatchTripRead[]
    | RunBambiRunTripRead[]
    | RunBambiRunReassignedTripRead[],
  isDispatchArrivalCompletionTimeEnabled = false
): MbscCalendarEvent[] {
  return (
    trips.flatMap(
      (
        trip:
          | DispatchTripRead
          | RunBambiRunTripRead
          | RunBambiRunReassignedTripRead
      ) => {
        const { id, wait_time_start, wait_time_end } = trip;

        if (wait_time_start !== null && wait_time_end !== null) {
          const resource =
            'assignment_id' in trip ? trip.assignment_id : trip.assignment.id;

          const waitTime = { start: wait_time_start, end: wait_time_end };

          if (
            isDispatchTrip(trip) &&
            isDispatchArrivalCompletionTimeEnabled &&
            trip.is_wait_and_return_outbound
          ) {
            waitTime.start = trip.completion_time;

            const inboundTrip = find(
              trips,
              (t: DispatchTripRead) => t.id === trip.inbound_trip?.id
            );

            if (inboundTrip && isDispatchTrip(inboundTrip)) {
              waitTime.end = inboundTrip.arrival_time;
            }
          }

          return [
            {
              id: `${id}-wait`,
              start: waitTime.start,
              end: waitTime.end,
              title: 'Wait',
              resource,
              waitTime,
            },
          ];
        } else {
          return [];
        }
      }
    ) || []
  );
}
