import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { DefaultChangeValueRenderer } from './DefaultChangeValueRenderer';
import { formatTimeChangeData } from './formatTimeChangeData';

export function TripTimeChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const old = formatTimeChangeData(
    String(tripEvent.values_changed?.[valueKey].old_value)
  );
  const newTime = formatTimeChangeData(
    String(tripEvent.values_changed?.[valueKey].new_value)
  );
  return (
    <div className="flex items-center gap-1">
      <DefaultChangeValueRenderer value={old} />
      <ArrowLongRightIcon className="h-5 w-5" />
      <DefaultChangeValueRenderer value={newTime} />
    </div>
  );
}
