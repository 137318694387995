import { useMemo } from 'react';

import { Epg, Layout, useEpg } from '@nessprim/planby-pro';
import { DateTime } from 'luxon';

import {
  AssignmentRead,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';

import { getAssignmentById } from '../getAssignmentById';
import { getVehicleById } from '../getVehicleById';
import { vehicleHasPastAssignment } from '../vehicleHasPastAssignment';
import { AssignmentItem } from './AssignmentItem';
import { buildPlanByConfig } from './buildPlanByConfig';
import { ChannelItem } from './ChannelItem';
import { CustomTimeline } from './Timeline';

export type AssignmentManagementDisplayProps = {
  vehicles: VehicleRead[];
  assignments: AssignmentRead[];
  assignmentDate: DateTime;
};

/**
 *
 * Uses the EPG component from planby to display a list of vehicles with their assignments
 *
 * EPG: Electronic Program Guide (I had to look it up too)
 *
 * Vehicles are displayed as channels and assignments are displayed as programs
 *
 * TODO: Just staff on assignments and avoid assignmentStaff type
 */
export function AssignmentManagementDisplay({
  vehicles,
  assignments,
  assignmentDate,
}: AssignmentManagementDisplayProps) {
  const planByConfig = useMemo(
    () =>
      buildPlanByConfig({
        vehicles,
        assignments,
        assignmentDate,
      }),
    [vehicles, assignments, assignmentDate]
  );
  const { getEpgProps, getLayoutProps } = useEpg(planByConfig);

  return (
    <div className="isolate" style={{ height: '74vh', width: '99vw' }}>
      <Epg {...getEpgProps()}>
        <Layout
          {...getLayoutProps()}
          renderProgram={({ program, ...rest }) => {
            const assignment = getAssignmentById(program.data.id, assignments);
            return (
              <AssignmentItem
                key={program.data.id}
                assignment={assignment}
                program={program}
                {...rest}
              />
            );
          }}
          renderChannel={({ channel }) => (
            <ChannelItem
              key={channel.uuid}
              channel={channel}
              vehicle={getVehicleById(channel.uuid, vehicles)}
              hasAssignment={vehicleHasPastAssignment(
                channel.uuid,
                assignments,
                assignmentDate
              )}
            />
          )}
          renderTimeline={(props) => {
            return <CustomTimeline {...props} />;
          }}
        />
      </Epg>
    </div>
  );
}
