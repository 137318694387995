import { PropsWithChildren } from 'react';

import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { DropdownSubmenu } from 'src/common/DropdownMenu/DropdownSubmenu';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';

import { useTripMenuActions } from '../DispatchCalendar/useTripMenuActions';
import AddEditDispatchNote from './AddEditDispatchNote';
import { AddEditNote } from './AddEditNote';
import { CancellationMenu } from './TripDropdownCancellationMenu';

interface MenuContentProps extends PropsWithChildren {
  trip: DispatchTripRead;
}

/**
 * Any custom menu items for a trip dropdown menu should be passed as children
 */
export function TripDropdownSharedMenu({ children, trip }: MenuContentProps) {
  const actions = useTripMenuActions();
  return (
    <div>
      <ViewEditItem actions={actions} trip={trip} />
      <CancellationMenu trip={trip} />
      {trip.is_non_activated_will_call && (
        <ActivateWillCallItem trip={trip} actions={actions} />
      )}
      {children}
      <DispatcherNotesItem trip={trip} />
      <DriverNotesItem trip={trip} actions={actions} />
      <TripSummaryItem trip={trip} actions={actions} />
      {trip.status === 'requested' && (
        <RequestedTripMenuItems trip={trip} actions={actions} />
      )}
    </div>
  );
}

interface ItemProps {
  trip: DispatchTripRead;
  actions: ReturnType<typeof useTripMenuActions>;
}

function ViewEditItem({ actions, trip }: ItemProps) {
  return (
    <DropdownMenuItem onClick={() => actions.viewEdit(trip.id)}>
      View/Edit
    </DropdownMenuItem>
  );
}

function ActivateWillCallItem({ actions, trip }: ItemProps) {
  return (
    <DropdownMenuItem
      onClick={() => actions.activateWillCall({ tripId: trip.id })}
    >
      Activate will call
    </DropdownMenuItem>
  );
}

function DispatcherNotesItem({ trip }: { trip: DispatchTripRead }) {
  return (
    <DropdownSubmenu triggerText="Add/Edit Dispatcher Notes">
      <AddEditDispatchNote trip={trip} />
    </DropdownSubmenu>
  );
}

function DriverNotesItem({ trip, actions }: ItemProps) {
  return (
    <DropdownSubmenu triggerText="Add/Edit Driver Notes">
      <DropdownSubmenu triggerText="Pick Up Notes">
        <AddEditNote
          label="Pick Up Notes"
          initialNote={trip.pickup_driver_notes}
          onSave={(note) => actions.updateDriverNotes(trip.id, note, 'pickup')}
        />
      </DropdownSubmenu>
      <DropdownSubmenu triggerText="Drop Off Notes">
        <AddEditNote
          label="Drop Off Notes"
          initialNote={trip.dropoff_driver_notes}
          onSave={(note) => actions.updateDriverNotes(trip.id, note, 'dropoff')}
        />
      </DropdownSubmenu>
    </DropdownSubmenu>
  );
}

function TripSummaryItem({ trip, actions }: ItemProps) {
  return (
    <DropdownMenuItem onClick={() => actions.goToTripSummary(trip.id)}>
      View Summary
    </DropdownMenuItem>
  );
}

function RequestedTripMenuItems({ trip, actions }: ItemProps) {
  return (
    <>
      <DropdownMenuItem
        onClick={() => actions.acceptMarketplaceRequest(trip.id)}
      >
        Accept
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => actions.rejectMarketplaceRequest(trip.id)}
      >
        Reject
      </DropdownMenuItem>
    </>
  );
}
