import { PricingType } from 'src/common/external/bambi-api/bambiApi';

import { formatPricingTypeRounding } from './formatPricingTypeRounding';
import { PricingTypeBaseRateBadge } from './PricingTypeBaseRateBadge';

export function SelectedPricingTypeDetailsHeader({
  pricingType,
}: {
  pricingType: PricingType;
}) {
  return (
    <div className="flex items-center gap-x-8">
      <div>
        <span className="text-md font-medium">Initial Rate:</span>
        <PricingTypeBaseRateBadge pricingType={pricingType} />
      </div>
      <div className="text-md">
        <span className="text-md font-medium">Rounding:</span>{' '}
        {formatPricingTypeRounding(pricingType)}
      </div>
    </div>
  );
}
