import { upperFirst } from 'lodash-es';

import { CanceledReasonEnum } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export const CANCEL_REASONS: CanceledReasonEnum[] = [
  'no show',
  'passenger not ready',
  'passenger refused',
  'passenger unable',
  'scheduling conflict',
  'appointment canceled',
  'other',
];

export const CANCEL_REASON: { [key: string]: CanceledReasonEnum } = {
  NO_SHOW: 'no show',
  PASSENGER_NOT_READY: 'passenger not ready',
  PASSENGER_REFUSED: 'passenger refused',
  PASSENGER_UNABLE: 'passenger unable',
  SCHEDULING_CONFLICT: 'scheduling conflict',
  APPOINTMENT_CANCELED: 'appointment canceled',
  OTHER: 'other',
};

export const CANCEL_REASONS_OPTIONS: SelectOption<CanceledReasonEnum>[] =
  CANCEL_REASONS.map((reason) => ({
    value: reason,
    label: upperFirst(reason),
  }));
