import { useOrganizationSettingsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { Card } from 'src/common/primitives/Card/Card';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { MerchantApplicationPhase } from './phase/MerchantApplicationPhase';

export function PaymentsConnectScreen() {
  const { data: organizationSettings, isLoading } =
    useOrganizationSettingsRetrieveQuery({});

  if (isLoading || !organizationSettings) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div>
      <Card>
        <div className="flex-grow flex-row items-center justify-between sm:flex">
          <MerchantApplicationPhase
            payment_account_id={organizationSettings.payment_account_id}
            payment_account_status={organizationSettings.payment_account_status}
          />
        </div>
      </Card>
    </div>
  );
}
