export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL?.replace(
  '\r',
  ''
);
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID?.replace(
  '\r',
  ''
);
export const APP_BASE_URI = process.env.REACT_APP_APP_BASE_URI?.replace(
  '\r',
  ''
);
export const AUTH_USER_POOL_ID =
  process.env.REACT_APP_AUTH_USER_POOL_ID?.replace('\r', '');
export const AUTH_RESPONSE_TYPE =
  process.env.REACT_APP_AUTH_RESPONSE_TYPE?.replace('\r', '');
export const AUTH_REGION = process.env.REACT_APP_AUTH_REGION?.replace('\r', '');
export const AUTH_FLOW_TYPE = process.env.REACT_APP_AUTH_FLOW_TYPE?.replace(
  '\r',
  ''
);
export const AUTH_LOGOUT_URL = process.env.REACT_APP_AUTH_LOGOUT_URL?.replace(
  '\r',
  ''
);
export const BAMBI_LANDING_PAGE_URL =
  process.env.REACT_APP_BAMBI_LANDING_PAGE_URL?.replace('\r', '');
export const AUTH_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AUTH_COGNITO_IDENTITY_POOL_ID?.replace('\r', '') || '';
export const LOCATION_PLACE_INDEX =
  process.env.REACT_APP_LOCATION_PLACE_INDEX?.replace('\r', '') || '';

export const AUTH_SIGN_IN_URL = `${AUTH_BASE_URL}/login?client_id=${AUTH_CLIENT_ID}&response_type=${AUTH_RESPONSE_TYPE}&scope=email+openid&redirect_uri=${APP_BASE_URI}`;
export const AUTH_SIGN_OUT_URL = process.env.REACT_APP_BAMBI_LANDING_PAGE_URL;
export const GEO_MAP_RESOURCE = process.env.REACT_APP_AWS_MAP_RESOURCE || '';
