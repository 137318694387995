import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  PricingRule,
  PricingType,
} from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

export function PricingTypeAdditionalBaseBadge({
  pricingType,
  pricingRule,
}: {
  pricingType: PricingType;
  pricingRule: PricingRule;
}) {
  return (
    <Badge
      color="primary"
      label={`+${defaultCurrencyFormat(
        pricingRule.additional_base || 0
      )} per ${pricingType.unit.toLowerCase()}`}
    />
  );
}
