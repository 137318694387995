import { useCallback, useMemo } from 'react';

import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  Invoice,
  InvoicePayment,
  useBillingBillingSettingsNextInvoiceNumberRetrieveQuery,
  useInvoicesInvoicesCreateMutation,
  useInvoicesInvoicesPartialUpdateMutation,
  useInvoicesPaymentsCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { isCreateMode } from './forms/InvoiceForm';
import {
  editInvoice,
  resetEditing,
  setInvoiceModalOpen,
  setPayModalOpen,
} from './invoice.slice';
import { InvoiceModal } from './modals/InvoiceModal';
import { PaymentModal } from './modals/PaymentModal';

const DUPLICATE_TRIP_ERROR = 'unique_trip_trip_line_item_type';

export default function InvoiceController() {
  const isModalOpen = useAppSelector((state) => state.invoice.showInvoiceModal);
  const showPaymentModal = useAppSelector(
    (state) => state.invoice.showPayModal
  );
  const editingInvoice = useAppSelector(
    (state) => state.invoice.editingInvoice
  );
  const dispatch = useAppDispatch();
  const handleSetModalOpen = useCallback(
    (open: boolean) => {
      dispatch(setInvoiceModalOpen(open));
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    dispatch(resetEditing());
    dispatch(setInvoiceModalOpen(false));
  }, [dispatch]);

  const [createInvoice, { isLoading: isCreatingInvoice }] =
    useInvoicesInvoicesCreateMutation();
  const [updateInvoice, { isLoading: isUpdatingInvoice }] =
    useInvoicesInvoicesPartialUpdateMutation();
  const [capturePayment, { isLoading: isPayingInvoice }] =
    useInvoicesPaymentsCreateMutation();

  const { isLoading: isLoadingNextInvoiceNumber, data: invoiceNumberData } =
    useBillingBillingSettingsNextInvoiceNumberRetrieveQuery({});

  const nextInvoiceNumber = useMemo(() => {
    if (isLoadingNextInvoiceNumber || !invoiceNumberData) {
      return '';
    }

    return `${invoiceNumberData.invoice_prefix}${invoiceNumberData.next_invoice_number}`;
  }, [isLoadingNextInvoiceNumber, invoiceNumberData]);

  const handleCreateInvoice = useCallback(
    async (invoice: Invoice, skipReset?: boolean) => {
      try {
        const createdInvoice = await createInvoice({
          invoice: {
            ...invoice,
            date_issued: DateTime.now().toISODate(),
          },
        }).unwrap();

        dispatch(
          show({
            type: 'success',
            title: `${invoice.number} - Created`,
          })
        );

        dispatch(editInvoice(createdInvoice));

        if (!skipReset) {
          dispatch(resetEditing());
        }
      } catch (e) {
        let formattedError = formatServerError(e, true);
        if (formattedError.includes(DUPLICATE_TRIP_ERROR)) {
          formattedError = 'One or more trips are already invoiced.';
        }

        dispatch(
          show({
            type: 'error',
            title: `Failed to create invoice`,
            description: formattedError,
          })
        );
      }
    },
    [createInvoice, dispatch]
  );

  const handleUpdateInvoice = useCallback(
    async (data: Invoice, skipReset?: boolean) => {
      // Shouldn't happen
      if (!editingInvoice) {
        return;
      }

      try {
        const updatedInvoice = await updateInvoice({
          id: editingInvoice.id,
          patchedInvoice: {
            ...data,
          },
        }).unwrap();

        dispatch(
          show({
            type: 'success',
            title: `${editingInvoice.number} - Updated`,
          })
        );

        dispatch(editInvoice(updatedInvoice));
        if (!skipReset) {
          dispatch(resetEditing());
        }
      } catch (e) {
        let formattedError = formatServerError(e, true);
        if (formattedError.includes(DUPLICATE_TRIP_ERROR)) {
          formattedError = 'One or more trips are already invoiced.';
        }

        dispatch(
          show({
            type: 'error',
            title: `Failed to update invoice`,
            description: formattedError,
          })
        );
      }
    },
    [editingInvoice, updateInvoice, dispatch]
  );

  const handleUpsertInvoice = useCallback(
    async (data: Invoice, skipReset?: boolean) => {
      // Editing invoice is null on create
      if (isCreateMode(editingInvoice)) {
        await handleCreateInvoice(data, skipReset);
      } else {
        await handleUpdateInvoice(data, skipReset);
      }
    },
    [editingInvoice, handleCreateInvoice, handleUpdateInvoice]
  );

  const handleShowPay = useCallback(
    (open: boolean) => {
      dispatch(setPayModalOpen(open));
    },
    [dispatch]
  );

  const handleUpdateAndShowPay = useCallback(
    async (invoice: Invoice) => {
      await handleUpdateInvoice(invoice, true);
      handleShowPay(true);
    },
    [handleShowPay, handleUpdateInvoice]
  );

  const handleCapturePayment = useCallback(
    async (invoicePayment: InvoicePayment) => {
      try {
        await capturePayment({
          invoicePayment,
        }).unwrap();
        dispatch(
          show({
            type: 'info',
            title: 'Payment processing',
            description: `Payment of ${defaultCurrencyFormat(
              invoicePayment.amount_cents
            )} is processing for invoice ${editingInvoice?.number}`,
          })
        );
        handleShowPay(false);
        dispatch(resetEditing());
      } catch (e) {
        dispatch(
          show({
            type: 'error',
            title: 'Payment failed',
            description: `Failed to capture payment: ${formatServerError(e)}`,
          })
        );
      }
    },
    [capturePayment, dispatch, editingInvoice?.number, handleShowPay]
  );

  return (
    <>
      <InvoiceModal
        key={`invoice-${editingInvoice?.id}-${isModalOpen}`}
        open={isModalOpen}
        setOpen={handleSetModalOpen}
        onCancel={handleCancel}
        onUpsert={handleUpsertInvoice}
        onPay={(invoice) => handleUpdateAndShowPay(invoice)}
        confirmText={
          isCreateMode(editingInvoice) ? 'Create Invoice' : 'Update Invoice'
        }
        loading={
          isCreatingInvoice || isUpdatingInvoice || isLoadingNextInvoiceNumber
        }
        nextInvoiceNumber={nextInvoiceNumber}
      />

      <PaymentModal
        key={`payment-${editingInvoice?.id}-${showPaymentModal}`}
        invoice={editingInvoice ?? null}
        open={showPaymentModal}
        setOpen={handleShowPay}
        loading={isPayingInvoice}
        onConfirm={handleCapturePayment}
      />
    </>
  );
}
