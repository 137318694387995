import {
  AssignedDriverRead,
  DriverRead,
} from 'src/common/external/bambi-api/bambiApi';

export const convertDriverToAssignedDriver = (
  driver: DriverRead
): AssignedDriverRead => {
  return {
    driver: driver.id,
    first_name: driver.first_name,
    last_name: driver.last_name,
    membership: driver.membership,
    clocked_in_at: null,
    clocked_out_at: null,
    is_currently_clocked_in: false,
  };
};
