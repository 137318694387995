import { DateTime } from 'luxon';

import { adaptAssignmentsToEpg } from './adaptAssignmentsToEpg';
import { AssignmentManagementDisplayProps } from './AssignmentManagementDisplay';
import { theme } from './theme';

export function buildPlanByConfig({
  vehicles,
  assignments,
  assignmentDate,
}: AssignmentManagementDisplayProps) {
  const epg = adaptAssignmentsToEpg(assignments);
  const startDate = assignmentDate.toFormat('yyyy/MM/dd');
  const endDate = assignmentDate.plus({ day: 1 }).toFormat('yyyy/MM/dd');

  return {
    epg,
    channels: vehicles.map((vehicle) => {
      return {
        logo: '',
        uuid: vehicle.id,
      };
    }),
    isBaseTimeFormat: true,
    theme,
    // Must supply both of these or planby will barf
    startDate,
    endDate,
    itemHeight: 80,
    sidebarWidth: 320,
    dayWidth: 3000,
    isTimeline: true,
    isCurrentTime: false,
    isLine: true,
    timezone: {
      enabled: true,
      zone: DateTime.local().zoneName,
      mode: 'utc',
    },
  };
}
