import { DateTime } from 'luxon';

import { getFirstDayOfWeek } from 'src/common/date/getFirstDayOfWeek';

export function onPrev(
  setSelectedDate: (date: DateTime) => void,
  assignmentDate: DateTime
): () => void {
  return () => {
    setSelectedDate(
      getFirstDayOfWeek(assignmentDate.minus({ weeks: 1 })).startOf('day')
    );
  };
}
