import { usePassengersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { columns, rideOrderingPortalColumns } from './columns';
import { PassengerDataGrid } from './PassengerDataGrid';

export function PassengerManagementRoute() {
  const shouldUseRideOrderingPortalColumns =
    useShouldShowRideOrderingPortalFeatures();

  const tableColumns = shouldUseRideOrderingPortalColumns
    ? rideOrderingPortalColumns
    : columns;

  return (
    <PassengerDataGrid
      columns={tableColumns}
      useDataProvider={usePassengersListQuery}
    />
  );
}
