import { useCallback } from 'react';

import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { resetMergeWizard } from 'src/features/passenger/passenger.slice';

import { useHandleMerge } from '../hooks';

interface ConfirmMergeStepProps {
  prev: () => void;
}

export function ConfirmMergeStep({ prev }: ConfirmMergeStepProps) {
  const parentPassenger = useAppSelector(
    (state) => state.passenger.parentPassenger
  );
  const passengersToMerge = useAppSelector(
    (state) => state.passenger.selectedPassengers
  );
  const handleMerge = useHandleMerge();
  const dispatch = useAppDispatch();
  const onSuccess = useCallback(() => {
    dispatch(resetMergeWizard());
  }, [dispatch]);

  if (!parentPassenger) {
    return (
      <div>
        <div className="text-center text-lg">Unexpected state</div>
        <div>
          The parent passenger was not found, please return to the previous
          step.
        </div>
      </div>
    );
  }

  if (!passengersToMerge.length) {
    return (
      <div>
        <div className="text-center text-lg">Unexpected state</div>
        <div>
          No passengers to merge found, please return to the previous step.
        </div>
      </div>
    );
  }

  return (
    <div className="mt-2 flex flex-col gap-2" style={{ minWidth: 450 }}>
      <div className="flex flex-row items-center justify-center gap-2 text-lg">
        <ExclamationTriangleIcon color="red" className="w-4" />
        Merge Passengers
        <ExclamationTriangleIcon color="red" className="w-4" />
      </div>
      <div data-testid="merge-confirmation-message">
        Merge <strong>{passengersToMerge.length}</strong> record(s) into{' '}
        <strong>{parentPassenger.full_name}</strong> record?
      </div>
      <div>
        <strong>This action is not reversible.</strong>
      </div>
      <div className="flex flex-row justify-evenly gap-2">
        <Button variant="secondary-outline" className="w-full" onClick={prev}>
          Back
        </Button>
        <Button
          variant="primary"
          className="w-full"
          onClick={() => {
            handleMerge(parentPassenger, passengersToMerge, onSuccess);
          }}
        >
          Merge
        </Button>
      </div>
    </div>
  );
}
