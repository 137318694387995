import { useFormContext } from 'react-hook-form';

export function useMakeRoundTrip({ isWillCall }: { isWillCall: boolean }) {
  const { getValues, setValue } = useFormContext();

  return () => {
    const formData = getValues();
    const { is_round_trip, trips } = formData;
    if (is_round_trip) return;

    setValue('is_round_trip', !is_round_trip);
    const firstLeg = trips[0];
    const returnLeg = {
      pickupLocation: {
        ...firstLeg.dropoffLocation,
      },
      dropoffLocation: {
        ...firstLeg.pickupLocation,
      },
      timing: {
        date: firstLeg?.timing.date,
        appointment_time: '',
        pickup_time: isWillCall ? '23:59' : '',
        is_will_call: isWillCall,
      },
      dispatcher_notes: firstLeg.dispatcher_notes,
    };
    setValue('trips', [...trips, returnLeg]);
  };
}
