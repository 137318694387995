import { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DeleteAction } from 'src/common/DeleteAction';
import { DeleteModal } from 'src/common/DeleteModal';
import {
  Assignment,
  AssignmentRead,
  useAssignmentsDestroyMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { WizardHeader } from 'src/common/wizard/WizardHeader';

import {
  onCloseAssignmentModal,
  setSelectedAssignment,
  setSelectedVehicle,
  setShowAssignmentManagementModal,
} from '../assignmentManagement.slice';
import { AssignmentManagementFormStep1 } from './AssignmentManagementFormStep1/AssignmentManagementFormStep1';
import { CloneAssignmentAction } from './CloneAssignmentAction';
import { generateDeleteSuccessToast } from './generateDeleteSuccessToast';

function getStepTitle(step: number, assignment?: Assignment) {
  switch (step) {
    default:
      return assignment ? 'Edit Assignment' : 'Add Assignment';
  }
}

export function AssignmentManagementForm() {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(
    (state) => state.assignmentManagement.showAssignmentManagementModal
  );

  const assignment = useAppSelector(
    (state) => state.assignmentManagement.selectedAssignment
  );

  const [serverErrors, setServerErrors] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [step] = useState(1);
  const nextToastId = useAppSelector((state) => state.toast.nextId);

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [deleteAssignment] = useAssignmentsDestroyMutation();

  const onDeleteAssignment = async (assignment: AssignmentRead | undefined) => {
    setDeleteLoading(true);
    if (!assignment) {
      //TODO: handle error
      setDeleteLoading(false);
      return;
    }

    try {
      await deleteAssignment({
        id: assignment.id,
      }).unwrap();

      setIsConfirmingDelete(false);
      dispatch(show(generateDeleteSuccessToast(nextToastId)));
      dispatch(setSelectedAssignment(undefined));
    } catch (error) {
      const errMsg = formatServerError(error);
      dispatch(
        show({
          id: nextToastId,
          title: 'Error deleting assignment',
          description: errMsg,
          type: 'error',
        })
      );
    }
    setDeleteLoading(false);
  };

  const title = getStepTitle(step, assignment);
  return (
    <>
      <Modal
        open={isOpen}
        setOpen={(val) => {
          dispatch(setShowAssignmentManagementModal(val));

          if (!val) {
            dispatch(setSelectedAssignment(undefined));
            dispatch(setSelectedVehicle(undefined));
          }
        }}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <div className="min-w-[465px]">
          <WizardHeader title={title} />
          {step === 1 ? (
            <AssignmentManagementFormStep1
              onCancel={() => {
                setServerErrors(null);
                dispatch(onCloseAssignmentModal());
              }}
              serverErrors={serverErrors}
            >
              {assignment ? (
                <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <DeleteAction
                    className="sm:col-span-3"
                    onDelete={() => {
                      setIsConfirmingDelete(true);
                      dispatch(setShowAssignmentManagementModal(false));
                    }}
                    label="Delete this assignment"
                  />
                  <CloneAssignmentAction className="sm:col-span-3" />
                </div>
              ) : null}
            </AssignmentManagementFormStep1>
          ) : null}
        </div>
      </Modal>
      <DeleteModal
        open={isConfirmingDelete}
        setOpen={setIsConfirmingDelete}
        onCancel={() => {
          dispatch(setShowAssignmentManagementModal(true));
          setIsConfirmingDelete(false);
        }}
        onConfirm={() => {
          onDeleteAssignment(assignment);
        }}
        title="Delete this assignment?"
        description="Are you sure you want to delete this assignment? This action cannot be undone."
        loading={deleteLoading}
      />
    </>
  );
}
