import { useCallback, useEffect, useMemo, useState } from 'react';

import { PlusIcon } from '@heroicons/react/24/outline';
import { groupBy } from 'lodash-es';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { DeleteModal } from 'src/common/DeleteModal';
import {
  InvoiceNestedLineItem,
  InvoiceNestedLineItemRead,
  InvoiceRead,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { Button } from 'src/common/primitives/Button';
import { TripFinderModal } from 'src/features/trip/finder/TripFinderModal';

import { PrintableInvoiceTrigger } from '../print/PrintableInvoice';
import { invoiceDefaultValuesFactory } from './defaultValues';
import { InvoiceFormEmptyState } from './InvoiceFormEmptyState';
import { InvoiceLineItemContainer } from './InvoiceLineItemContainer';

export function isCreateMode(invoice?: InvoiceRead | null) {
  return !invoice || !invoice.id;
}

// When managing the list of line_items we gain an id from hook form
type InvoiceNestedLineItemWithId = InvoiceNestedLineItem & { __id: string };

interface InvoiceFormProps {
  invoice?: InvoiceRead | null;
}

export function InvoiceForm({ invoice }: InvoiceFormProps) {
  const { reset, control } = useFormContext<InvoiceRead>();

  const {
    fields: lineItems,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    name: 'line_items',
    keyName: '__id',
  });

  const [showTripSelector, setShowTripSelector] = useState(false);

  const handleAddTripLineItem = useCallback(
    (lineItem: InvoiceNestedLineItemWithId) => {
      const hasTrip = lineItems.find((item) => item.trip === lineItem.trip);

      if (hasTrip) {
        return;
      }

      append(lineItem as unknown as InvoiceNestedLineItemRead);
    },
    [append, lineItems]
  );

  // Track any updates to invoice
  useEffect(() => {
    reset(invoiceDefaultValuesFactory(invoice));
  }, [invoice, reset]);

  const lineItemsByTrip = useMemo(
    () => groupBy(lineItems, (lineItem) => lineItem.trip),
    [lineItems]
  );

  // Since we've grouped the line items, we need to find the correct index.
  const findLineItemIndex = useCallback(
    (lineItem: InvoiceNestedLineItemWithId): number => {
      return lineItems.findIndex((item) => item.__id === lineItem.__id);
    },
    [lineItems]
  );

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [removingLineItem, setRemovingLineItem] =
    useState<InvoiceNestedLineItemWithId>();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-4">
          <h3 className="text-[24px] font-medium leading-6 text-gray-900">
            {isCreateMode(invoice) ? 'Create' : 'Edit'} Invoice
          </h3>
          <Controller
            control={control}
            name="number"
            rules={{
              required: 'This field is required',
            }}
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                type="text"
                inputProps={{
                  id: 'invoice-number',
                  placeholder: 'INV-0001',
                  value: field.value,
                  onChange: field.onChange,
                  required: true,
                  'aria-label': 'Invoice Number',
                }}
              />
            )}
          />
        </div>
        <div className="gap-2">
          {invoice ? (
            <PrintableInvoiceTrigger invoice={invoice}>
              Print
            </PrintableInvoiceTrigger>
          ) : null}
          {/* <Button>Send Invoice</Button> */}
        </div>
      </div>
      <div>
        {showTripSelector ? (
          <TripFinderModal
            open={showTripSelector}
            setOpen={setShowTripSelector}
            onConfirm={(trips) => {
              trips.forEach((trip) => {
                const lineItem: InvoiceNestedLineItem = {
                  description: `Trip - ${trip.id}`,
                  amount_cents: trip.input_price_cents ?? 0,
                  trip: trip.id,
                  line_item_type: 'trip',
                };

                handleAddTripLineItem(lineItem as InvoiceNestedLineItemWithId);
              });
              setShowTripSelector(false);
            }}
          />
        ) : (
          <Button onClick={() => setShowTripSelector(true)}>
            <PlusIcon className="w-5" />
            Add Trip(s)
          </Button>
        )}
      </div>
      {lineItems.length === 0 && (
        <InvoiceFormEmptyState onClick={() => setShowTripSelector(true)} />
      )}
      {/* Trips + line items */}
      {/* Group by trip, render this block */}
      {Object.values(lineItemsByTrip).map((group) => {
        return (
          <InvoiceLineItemContainer
            key={group[0].trip}
            lineItems={group}
            onAddLineItem={(item) => append(item as InvoiceNestedLineItemRead)}
            onUpdateLineItem={(_rowIndex, lineItem) => {
              const itemIndex = findLineItemIndex(
                lineItem as InvoiceNestedLineItemWithId
              );
              update(itemIndex, lineItem as InvoiceNestedLineItemRead);
            }}
            onDeleteLineItem={(_rowIndex, lineItem) => {
              const itemIndex = findLineItemIndex(
                lineItem as InvoiceNestedLineItemWithId
              );

              const shouldConfirmClose =
                lineItem.line_item_type === 'trip' &&
                lineItemsByTrip[lineItem.trip ?? ''].length > 1;

              if (!shouldConfirmClose) {
                remove(itemIndex);
              } else {
                setRemovingLineItem(lineItem as InvoiceNestedLineItemWithId);
                setDeleteConfirmationModalOpen(true);
              }
            }}
          />
        );
      })}
      <DeleteModal
        title="Remove trip and related line items?"
        description="Removing this trip will also remove its modifiers, is that okay?"
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        onCancel={() => {
          setDeleteConfirmationModalOpen(false);
          setRemovingLineItem(undefined);
        }}
        onConfirm={() => {
          if (!removingLineItem) {
            setDeleteConfirmationModalOpen(false);
            setRemovingLineItem(undefined);
            return;
          }

          // Find line items related to the trip to remove
          const relatedLineItemIndexes: number[] = [];
          lineItems.forEach((item, index) => {
            if (item.trip === removingLineItem.trip) {
              relatedLineItemIndexes.push(index);
            }
          });

          remove(relatedLineItemIndexes);

          setDeleteConfirmationModalOpen(false);
          setRemovingLineItem(undefined);
        }}
      />
    </div>
  );
}
