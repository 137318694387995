import { Driver } from 'src/common/external/bambi-api/bambiApi';

import { DriverSkillMap } from './DriverSkillMap';
import { resolveDriverSkills } from './resolveDriverSkills';

export const DriverVehicleSkillKeyMap: DriverSkillMap = {
  can_do_wheelchair_service: 'wheelchair',
  can_do_stretcher_service: 'stretcher',
  can_do_ambulatory_service: 'ambulatory',
  can_do_broda_chair_service: 'broda-chair',
  can_do_geri_chair_service: 'geri-chair',
};

export function resolveVehicleSkills(driver: Driver): string[] {
  return resolveDriverSkills(driver, DriverVehicleSkillKeyMap);
}
