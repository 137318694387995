import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import {
  formatHereMapsAddress,
  HereMapsAddressLink,
} from 'src/common/HereMapsAddressLink';
import { Skeleton } from 'src/common/Skeleton';

import { formatSimpleTime } from '../formatSimpleTime';
import { PhoneNumberLink } from '../PhoneNumberLink';

interface TripDetailsDropoffProps {
  isTripDataLoading: boolean;
  tripData?: TripRead;
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
}
export function TripDetailsDropoff({
  isTripDataLoading,
  trip,
  tripData,
}: TripDetailsDropoffProps) {
  return (
    <div className="flex flex-col gap-1 py-2">
      <h4 className="text-sm font-bold">
        Dropoff - {formatSimpleTime(trip.scheduled_dropoff_at)}
      </h4>
      <div>
        <HereMapsAddressLink
          className="text-sm font-medium text-gray-500 hover:underline"
          addresses={[
            {
              latitude: trip.pickup_latitude,
              longitude: trip.pickup_longitude,
            },
            {
              latitude: trip.dropoff_latitude,
              longitude: trip.dropoff_longitude,
            },
          ]}
        >
          {formatHereMapsAddress(trip.dropoff_address)}
        </HereMapsAddressLink>
      </div>
      {isTripDataLoading ? (
        <Skeleton />
      ) : tripData?.dropoff?.contact_name ||
        tripData?.dropoff?.contact_phone_number ? (
        <div className="text-sm">
          <h5 className="text-sm font-medium text-gray-500">Contact</h5>
          <p>
            {tripData.dropoff.contact_name}
            <PhoneNumberLink
              phoneNumber={tripData.dropoff.contact_phone_number}
            />
          </p>
        </div>
      ) : null}
    </div>
  );
}
