import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import React from 'react';

import { MbscStepperOptions, Stepper } from '@mobiscroll/react';

export const NumericStepper = React.forwardRef(
  (props: MbscStepperOptions, ref: React.LegacyRef<Stepper>) => {
    return (
      <>
        <style>
          {`
            .mbsc-ios.mbsc-form-control-wrapper {
                background: none;
                border: none;
                padding: 0;
                position: static;
                display: block;
                flex-grow: 1;
            }

            .mbsc-stepper-control {
                position: static;
                margin: 0;
                height: auto;
                flex-grow: 1;
            }
            .mbsc-ios.mbsc-stepper-input {
                border: none;
                flex-grow: 1;
            }
            .mbsc-ios.mbsc-stepper-input:focus {
                outline: none;
                box-shadow: none;
            }
            .mbsc-ios.mbsc-stepper-button {
                background: white;
                flex-grow: 1;
                color: inherit !important;
            }
            .mbsc-ios.mbsc-stepper-button.mbsc-button.mbsc-font {
                width: 1.5rem;
            }
            .mbsc-ios.mbsc-stepper-inner {
                font-size: 1.5rem;
            }
          `}
        </style>
        <div className="focus:border-indigo-500 focus:ring-indigo-500 bambi-numeric-stepper relative isolate flex w-full overflow-hidden rounded-md border-gray-300 sm:text-sm">
          <Stepper
            themeVariant="light"
            {...props}
            data-lpignore="true"
            data-1p-ignore
            ref={ref}
          />
        </div>
      </>
    );
  }
);
