import { useAppSelector } from 'src/app/store';
import { useAssignmentsLocationsListQuery } from 'src/common/external/bambi-api/bambiApi';

import { selectCurrentAssignmentLocationId } from '../../liveMap.slice';

const FIVE_SECONDS_IN_MS = 5000;

export function useAssignmentMarkersViewModel() {
  const selectedAssignmentLocationId = useAppSelector(
    selectCurrentAssignmentLocationId
  );

  // We don't currently indicate in the ui when this is loading or fetching
  // so using selectFromResult to narrow down the data and lean on rtk-query
  // to prevent unnecessary rerenders
  // https://redux-toolkit.js.org/rtk-query/usage/queries#selecting-data-from-a-query-result
  const { assignmentLocations, selectedAssignmentLocation } =
    useAssignmentsLocationsListQuery(
      {},
      {
        selectFromResult: (data) => {
          const assignmentLocations = data.data?.results;
          const selectedAssignmentLocation = assignmentLocations?.find(
            (assignmentLocation) =>
              assignmentLocation.id === selectedAssignmentLocationId
          );
          return {
            assignmentLocations,
            selectedAssignmentLocation,
          };
        },
        pollingInterval: FIVE_SECONDS_IN_MS,
      }
    );

  return {
    assignmentLocations,
    selectedAssignmentLocation,
  };
}
