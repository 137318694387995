import { useEffect, useState } from 'react';

import { useFeature } from 'src/app/FeatureToggle';
import { nonRideOrderingPortalTopLevelNavItems } from 'src/common/NavBar/nonRideOrderingPortalTopLevelNavItems';
import { rideOrderingPortalTopLevelNavItems } from 'src/common/NavBar/rideOrderingPortalTopLevelNavItems';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { useShouldShowRideOrderingPortalFeatures } from '../../features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';
import { useOrganizationSettingsRetrieveQuery } from '../external/bambi-api/bambiApi';
import { useCanCapturePaymentMethod } from '../forms/PaymentMethodCaptureForm/useCanCapturePaymentMethod';

export const useNavBarItems = () => {
  const shouldShowRideOrderingNavBar =
    useShouldShowRideOrderingPortalFeatures();

  const { isEnabled: isPricingV1Enabled, isLoading: isLoadingPricingV1Flag } =
    useFeature('pricing version 1');

  const { role } = useUserRoles();

  const {
    isEnabled: isCapturePaymentsEnabled,
    isLoading,
    onlyInvoicingEnabled,
  } = useCanCapturePaymentMethod();

  const {
    data: organizationSettings,
    isLoading: isOrganizationSettingsLoading,
    isFetching: isOrganizationSettingsFetching,
  } = useOrganizationSettingsRetrieveQuery({});

  const [navItems, setNavItems] = useState(
    nonRideOrderingPortalTopLevelNavItems
  );

  useEffect(() => {
    const updatedNavItems = [...nonRideOrderingPortalTopLevelNavItems];

    const isPricingV1FlagEnabled =
      !isLoadingPricingV1Flag && isPricingV1Enabled;

    const isPricingV1FlagAngSettingsEnabled =
      isPricingV1FlagEnabled && organizationSettings?.is_pricing_v1_enabled;

    const pricingIndex = updatedNavItems.findIndex(
      (n) => n.href === '/pricing'
    );

    const pricingModelsIndex = updatedNavItems.findIndex(
      (n) => n.href === '/pricing/pricing-models'
    );

    if (
      isPricingV1FlagEnabled &&
      pricingIndex === -1 &&
      pricingModelsIndex !== -1
    ) {
      updatedNavItems.splice(pricingModelsIndex, 1, {
        name: 'Pricing',
        href: '/pricing',
        showAttentionIndicator: !organizationSettings?.is_pricing_v1_enabled,
      });
    }

    if (
      isPricingV1FlagEnabled &&
      !isPricingV1FlagAngSettingsEnabled &&
      pricingIndex !== -1
    ) {
      updatedNavItems[pricingIndex].showAttentionIndicator = true;
    }

    setNavItems(updatedNavItems);
  }, [
    isLoading,
    isCapturePaymentsEnabled,
    onlyInvoicingEnabled,
    isOrganizationSettingsLoading,
    organizationSettings,
    isLoadingPricingV1Flag,
    isPricingV1Enabled,
    isOrganizationSettingsFetching,
  ]);

  const filteredNavItems = navItems.filter((item) => {
    if (role && role === 'restricted support') {
      return !['Dispatch', 'Billing', 'Pricing'].includes(item.name);
    }

    return true;
  });

  const navBarItems = shouldShowRideOrderingNavBar
    ? rideOrderingPortalTopLevelNavItems
    : filteredNavItems;

  return { navBarItems };
};
