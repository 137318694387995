import { createSlice } from '@reduxjs/toolkit';

import { UserProfileRead } from 'src/common/external/bambi-api/bambiApi';
import { getStorageValue } from 'src/common/util/useLocalStorage/getStorageValue';
import { setStorageValue } from 'src/common/util/useLocalStorage/setStorageValue';

// TODO: Probably should isolate this per user id
export const currentOrgStorageKey = 'current-organization-id';

export interface IAuthState {
  profile?: UserProfileRead | null;
  currentMembership?: string;
  currentOrganizationId?: string;
}

const initialState: IAuthState = {
  profile: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.profile = action.payload;
      state.currentOrganizationId =
        getStorageValue(currentOrgStorageKey, '') ||
        state.currentOrganizationId ||
        state.profile?.memberships[0]?.organization.id;
    },
    changeOrganization: (state, action) => {
      state.currentOrganizationId = action.payload;
      setStorageValue(currentOrgStorageKey, state.currentOrganizationId || '');
    },
  },
});
