import React from 'react';

import { InputAttributes, NumericFormat } from 'react-number-format';

import { TextInput } from 'src/common/primitives/TextInput';

interface FloatInputProps {
  customInput?: React.ComponentType<InputAttributes>;
  props: any;
}

export const FloatInput = React.forwardRef(
  (
    { customInput = TextInput, ...props }: FloatInputProps,
    forwardedRef: React.Ref<HTMLInputElement>
  ) => {
    return (
      <NumericFormat
        getInputRef={forwardedRef}
        customInput={customInput}
        allowNegative={false}
        decimalScale={3}
        allowLeadingZeros={false}
        {...props}
      />
    );
  }
);
