import { BillingAddress } from './types';

export type SUPPORTED_COUNTRIES = 'United States' | 'Canada';

// ISO 3166-1 alpha-2 codes
export const isoCountry: Record<SUPPORTED_COUNTRIES, string> = {
  'United States': 'US',
  Canada: 'CA',
};

export function parsePlaceAddress(address: string): BillingAddress {
  const parts = address.split(',');

  const country = (parts.pop()?.trim() ??
    'United States') as SUPPORTED_COUNTRIES;
  const statePostal = parts.pop()?.trim();
  const city = parts.pop()?.trim() ?? '';
  let streetAddress2;

  if (parts.length > 1) {
    streetAddress2 = parts.pop()?.trim();
  }

  const streetAddress1 = parts.pop()?.trim();

  // Post processing
  const [state, postalCode] = statePostal?.split(' ') ?? [];

  return {
    streetAddress1,
    streetAddress2,
    city,
    state,
    postalCode,
    country: isoCountry[country],
  };
}
