import { minutesToSeconds } from 'date-fns';

import { PatchedPayerOrganizationSettings } from 'src/common/external/bambi-api/bambiApi';

import { FormValues } from './FormValues';
import { payerSignatureOptionToPayerSignature } from './payerSignatureOptionToPayerSignature';

export const payerOrgToPatchedPayerOrgSettings = (
  payerOrg: FormValues
): PatchedPayerOrganizationSettings => {
  return {
    _pickup_default_early_seconds: payerOrg.pickup_default_early_seconds
      ? minutesToSeconds(payerOrg.pickup_default_early_seconds)
      : null,
    _pickup_default_late_seconds: payerOrg.pickup_default_late_seconds
      ? minutesToSeconds(payerOrg.pickup_default_late_seconds)
      : null,
    _pickup_appointment_early_seconds: payerOrg.pickup_appointment_early_seconds
      ? minutesToSeconds(payerOrg.pickup_appointment_early_seconds)
      : null,
    _pickup_appointment_late_seconds: payerOrg.pickup_appointment_late_seconds
      ? minutesToSeconds(payerOrg.pickup_appointment_late_seconds)
      : null,
    _pickup_will_call_early_seconds: payerOrg.pickup_will_call_early_seconds
      ? minutesToSeconds(payerOrg.pickup_will_call_early_seconds)
      : null,
    _pickup_will_call_late_seconds: payerOrg.pickup_will_call_late_seconds
      ? minutesToSeconds(payerOrg.pickup_will_call_late_seconds)
      : null,
    _extra_scheduled_pickup_at_seconds:
      payerOrg.extra_scheduled_pickup_at_seconds
        ? minutesToSeconds(payerOrg.extra_scheduled_pickup_at_seconds)
        : null,
    invoice_due_after_days: payerOrg.invoice_due_after_days || null,
    _requires_signature_pickup_passenger: payerSignatureOptionToPayerSignature(
      payerOrg.requires_signature_pickup_passenger
    ),
    _requires_signature_dropoff_passenger: payerSignatureOptionToPayerSignature(
      payerOrg.requires_signature_dropoff_passenger
    ),
    _requires_signature_pickup_driver: payerSignatureOptionToPayerSignature(
      payerOrg.requires_signature_pickup_driver
    ),
    _requires_signature_dropoff_driver: payerSignatureOptionToPayerSignature(
      payerOrg.requires_signature_dropoff_driver
    ),
  };
};
