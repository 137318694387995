import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  MembershipRead,
  useMembersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { formatFullName } from 'src/common/util/formatFullName';

import { fleetInspectionSlice } from './fleetInspection.slice';

export function DriverMembershipFilter() {
  const inspectionListFilter = useAppSelector(
    (state) => state.fleetInspection.inspectionListFilter
  );
  const dispatch = useAppDispatch();

  const { data, isLoading, isFetching } = useMembersListQuery({
    isDriver: true,
    ordering: 'first_name',
  });

  const selectedValues: MembershipRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) =>
      inspectionListFilter.membershipIn?.includes(v.id)
    );
  }, [data, inspectionListFilter.membershipIn]);

  return (
    <MultiSelectFilterPopover
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={(driver) => formatFullName(driver)}
      onChange={(value) => {
        const driverIds: string = value.map((d) => d.id).join(',');
        dispatch(fleetInspectionSlice.actions.onDriverFilterChange(driverIds));
      }}
      triggerElement={
        <FilterPopoverTrigger
          count={selectedValues?.length || 0}
          label={
            isFetching || isLoading ? (
              <LoadingIndicator variant="small" />
            ) : (
              'Driver'
            )
          }
        />
      }
    />
  );
}
