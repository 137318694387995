import { useEffect, useState } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DropdownMenuContent } from 'src/common/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { dispatcherSlice } from 'src/features/dispatch/dispatcher.slice';

import { setOpenTripDropdownMenuId } from '../rom-all-requests.slice';

export function RowActions({ trip }: { trip: TripRead }) {
  const dispatch = useAppDispatch();
  const openTripDropdownMenuId = useAppSelector(
    (state) => state.romAllRequests.openTripDropdownMenuId
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openTripDropdownMenuId === trip.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openTripDropdownMenuId, trip.id]);

  // TODO: See note below on lack of 'accepted' status or
  // other method of determining if a trip is accepted for facility and marketplace trips
  // In addition, while the design calls for showing the 'Accept' and 'Reject' options
  // if the trip is in accepted or rejected states, the api restricts this.
  // Because of this, only showing the actions if the trip is in the 'requested' state
  if (trip.status !== 'requested') return null;

  return (
    <>
      <DropdownMenu.Root
        open={open}
        onOpenChange={(val) => {
          if (!val) dispatch(setOpenTripDropdownMenuId(null));
        }}
      >
        <DropdownMenu.Trigger asChild>
          <Button
            variant="secondary-outline"
            dataTestId="row-actions-trigger"
            onClick={() => dispatch(setOpenTripDropdownMenuId(trip.id))}
          >
            Requested
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenuContent side="bottom">
            <DropdownMenuItem
              data-testid={`accept-trip-${trip.id}`}
              onClick={() => {
                dispatch(
                  dispatcherSlice.actions.onSingleTripRequestAcceptance({
                    tripId: trip.id,
                  })
                );
              }}
            >
              Accept
            </DropdownMenuItem>
            <DropdownMenuItem
              data-testid={`reject-trip-${trip.id}`}
              onClick={() => {
                dispatch(
                  dispatcherSlice.actions.onSingleTripRequestRejection({
                    tripId: trip.id,
                  })
                );
              }}
            >
              Reject
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
}
