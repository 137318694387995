import { useEffect, useState } from 'react';

import {
  useVehiclesListQuery,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Select, SelectOption } from 'src/common/primitives/Select';

export function VehicleSelectionField({
  onlySchedulable,
}: {
  onlySchedulable?: boolean;
}) {
  const { data: vehicles, isFetching } = useVehiclesListQuery({
    canBeScheduled: onlySchedulable,
  });
  const [vehicleOptions, setVehicleOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (vehicles?.results)
      setVehicleOptions(
        vehicles.results.map((vehicle: VehicleRead) => {
          return {
            label: vehicle.nickname || vehicle.id,
            value: vehicle.id,
          };
        })
      );
  }, [vehicles]);
  return (
    <div>
      <FormContextField
        name="vehicle"
        rules={{ required: 'Vehicle is required' }}
      >
        {({ field, fieldState }) => {
          return (
            <DefaultFormFieldLayout
              label="Vehicle"
              error={fieldState.error?.message?.toString()}
              inputProps={{ id: 'vehicle-id' }}
            >
              <Select
                selected={field.value}
                options={vehicleOptions}
                onChange={field.onChange}
                placeholder={
                  isFetching ? 'Loading vehicles...' : 'Select a vehicle'
                }
                disabled={isFetching}
              />
            </DefaultFormFieldLayout>
          );
        }}
      </FormContextField>
    </div>
  );
}
