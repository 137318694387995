import BulkAssignTripsPopover from './BulkAssignTripsPopover/BulkAssignTripsPopover';
import { RejectRequestedTripsButton } from './RejectRequestedTripsButton';
import { UnassignedTripsGroupSelectionSection } from './UnassignedTripsGroupSelectionSection';
import { UnassignedTripsHeader } from './UnassignedTripsHeader';
import { UnassignedTripsListSection } from './UnassignedTripsListSection';
import { UnassignedTripsRunBambiRunSection } from './UnassignedTripsRunBambiRunSection';
import { useSearchedUnassignedTripsFromDispatchResponse } from './useSearchedUnassignedTripsFromDispatchResponse';

export function UnassignedTrips() {
  const searchedUnassignedTrips =
    useSearchedUnassignedTripsFromDispatchResponse();

  return (
    <>
      <UnassignedTripsHeader />
      {searchedUnassignedTrips.length ? (
        <div className="flex items-start justify-between gap-1">
          <UnassignedTripsGroupSelectionSection
            searchedUnassignedTrips={searchedUnassignedTrips}
          />
          <div className="-mt-1.5">
            <BulkAssignTripsPopover />
            <RejectRequestedTripsButton />
          </div>
        </div>
      ) : null}
      <UnassignedTripsListSection
        searchedUnassignedTrips={searchedUnassignedTrips}
      />
      <UnassignedTripsRunBambiRunSection />
    </>
  );
}
