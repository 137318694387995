export const theme = {
  primary: {
    600: 'F6FEFC', // Non-live assignment
    900: 'white', // Component background
  },
  grey: { 300: '#d1d1d1' }, // Unsure what this is for
  white: '#fff',
  green: {
    '200': '#00B4CC',
    '300': '#F04438', // Current time indicator
  },
  teal: { 100: '#00B4CC' },
  grid: {
    item: 'blue',
    divider: 'blue',
    highlight: 'blue',
  },
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  gradient: {
    blue: {
      // Live assignment -- and non-live assignment hover
      300: '#CCFBEF',
      600: '#CCFBEF',
      900: '#CCFBEF',
    },
  },
  text: {
    grey: {
      300: '#344054', // timeline hour labels
      500: '#475467', // program/assignment time text
    },
  },

  timeline: {
    divider: {
      bg: '#D0D5DD', // timeline hour divider
    },
  },
};
