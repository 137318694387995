import { createColumnHelper } from '@tanstack/react-table';
import { truncate, upperFirst } from 'lodash-es';

import { VehicleRead } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { VehicleForm } from '../VehicleForm/VehicleForm';

const columnHelper = createColumnHelper<VehicleRead>();

function RowActions({ vehicle }: { vehicle: VehicleRead }) {
  const { isRestrictedSupport } = useUserRoles();
  return (
    <div className="w-[24px]">
      {/* Should probably just remove this column but felt this was easier */}
      {!isRestrictedSupport && <VehicleForm vehicle={vehicle} />}
    </div>
  );
}

export const columns = [
  columnHelper.accessor('nickname', {
    header: 'Name',
    cell: ({ getValue }) => {
      const truncateName = truncate(getValue(), {
        length: 30,
        omission: '...',
      });
      return <>{truncateName}</>;
    },
  }),
  columnHelper.accessor('can_be_scheduled', {
    header: 'Status',
    cell: ({ getValue }) => (
      <Badge
        label={getValue() ? 'Available' : 'Unavailable' || 'label'}
        color={getValue() ? 'green' : 'red'}
      />
    ),
  }),
  columnHelper.display({
    id: 'vehicleMeta',
    header: 'Make / Model / Year / Color',
    cell: (props) => (
      <div>
        <strong>
          {truncate(`${props.row.original.year} ${props.row.original.make}`, {
            length: 30,
            omission: '...',
          })}{' '}
          {props.row.original.model}
        </strong>
        <div>{props.row.original.color}</div>
      </div>
    ),
  }),
  columnHelper.accessor('license_plate', {
    header: 'License Plate',
  }),
  columnHelper.accessor('license_plate_state', {
    header: 'License State',
  }),
  columnHelper.accessor('vin', {
    header: 'VIN',
  }),
  columnHelper.accessor('category', {
    header: 'Type',
    cell: ({ getValue }) => (
      <Badge label={upperFirst(getValue())} color="blue" />
    ),
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Edit',
    cell: (props) => <RowActions vehicle={props.row.original} />,
  }),
];
