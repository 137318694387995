import { useAppSelector } from 'src/app/store';
import { useSelectedPricingType } from 'src/features/pricingModel/management/hooks/useSelectedPricingType';

import { PricingTypeControls } from './PricingTypeControls';
import { SelectedPricingModelDetailsEditMode } from './SelectedPricingModelDetailsEditMode';
import { SelectedPricingModelDetailsViewMode } from './SelectedPricingModelDetailsViewMode';
import { SelectedPricingTypeDetails } from './SelectedPricingTypeDetails';

export function SelectedPricingModelDetails() {
  const pricingModelDetailsDraft = useAppSelector((state) => {
    return state.pricingModel.pricingModelDetailsDraft;
  });
  const selectedPricingType = useSelectedPricingType();
  return (
    <div className="flex flex-col gap-4 px-4">
      {pricingModelDetailsDraft ? (
        <SelectedPricingModelDetailsEditMode />
      ) : (
        <SelectedPricingModelDetailsViewMode />
      )}
      <PricingTypeControls />
      {selectedPricingType && (
        <SelectedPricingTypeDetails pricingType={selectedPricingType} />
      )}
    </div>
  );
}
