import { useCallback } from 'react';

import { CreditCardIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import { setInvoiceModalOpen } from '../invoice.slice';

export function InvoiceEmptyState() {
  const dispatch = useAppDispatch();
  const handleSetModalOpen = useCallback(
    (open: boolean) => {
      dispatch(setInvoiceModalOpen(open));
    },
    [dispatch]
  );

  return (
    <EmptyState
      Icon={<CreditCardIcon className="w-6" />}
      entityName="Invoice"
      description="No invoices found"
      Action={
        <Button onClick={() => handleSetModalOpen(true)}>Create Invoice</Button>
      }
    />
  );
}
