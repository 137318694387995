import { ServiceTypeField } from 'src/common/ServiceTypeField';
import { SpaceTypeField } from 'src/common/SpaceTypeField';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export function TripSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Trip Settings"
        description="Organization defaults for trips."
      />

      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SpaceTypeField fieldName="default_trip_space_type" />
        </div>

        <div>
          <ServiceTypeField fieldName="default_trip_service_type" />
        </div>
      </div>
    </div>
  );
}
