import { DateTime } from 'luxon';

export function validateTripDateOnEditingTripInThePast(value: any) {
  if (!value) {
    return 'Trip Date is required';
  }

  const date = DateTime.fromISO(value).startOf('day');
  if (date >= DateTime.local().startOf('day')) {
    return 'Trip Date must be in the past';
  }

  return true;
}
