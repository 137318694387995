import { GoogleMapsAddressLink } from 'src/common/GoogleMapsAddressLink';

import { shouldShowNone } from './shouldShowNone';

export function MaybeAddressLinkChangeValue({
  value,
}: {
  value?: string | null;
}) {
  if (shouldShowNone(value)) {
    return <div>None</div>;
  }

  return (
    <GoogleMapsAddressLink
      address={String(value)}
      className="text-sm font-medium text-gray-500 hover:underline dark:text-gray-400"
    />
  );
}
