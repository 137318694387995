import { FormErrorMessage } from 'src/common/FormErrorMessage';

import { useScrollToFormErrors } from './useScrollToFormErrors';

export function FormErrors({
  error,
  serverErrors,
}: {
  error: string;
  serverErrors: string | null;
}) {
  useScrollToFormErrors(error, serverErrors);

  return (
    <div id="form-errors">
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
      {!!serverErrors && <FormErrorMessage>{serverErrors}</FormErrorMessage>}
    </div>
  );
}
