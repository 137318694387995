import { DateTime } from 'luxon';

import { TripSummaryDefaultTextDisplay } from './TripSummaryDefaultTextDisplay';

export function TripSummaryOccuredAt({ occuredAt }: { occuredAt: string }) {
  return (
    <TripSummaryDefaultTextDisplay
      text={DateTime.fromISO(occuredAt).toFormat('LLLL d, h:mm a')}
    />
  );
}
