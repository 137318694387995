import { useAppSelector } from 'src/app/store';

import { PricingModelListItem } from './PricingModelListItem';

export function PricingModelList() {
  const pricingModels = useAppSelector(
    (state) => state.pricingModel.pricingModels
  );

  return (
    // TODO: When add functionality is added we may need to move this container to the parent
    // Eventually we'll want this component to expand to fill up space between the header and the add button
    // This is an external layout concern to this component, so the parent should handle it
    // this component can just be a dumb list
    // For now, we'll just set a max height and auto scroll
    <div
      data-testid="pricing-model-list"
      className="flex max-h-[70vh] w-full max-w-full flex-col gap-2 overflow-y-auto pr-2"
    >
      {pricingModels.map((pricingModel) => {
        return (
          <PricingModelListItem
            pricingModel={pricingModel}
            key={pricingModel.id}
          />
        );
      })}
    </div>
  );
}
