import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppDispatch } from 'src/app/store';
import { useOrganizationSettingsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';

import { onPriceSummaryModalOpen } from '../pricing.slice';

export function PriceSummaryModalButton({ tripId }: { tripId: string }) {
  const dispatch = useAppDispatch();

  const { isEnabled: isPricingV1Enabled, isLoading: isLoadingPricingV1Flag } =
    useFeature('pricing version 1');

  const {
    data: organizationSettings,
    isLoading: isLoadingOrganizationSettings,
  } = useOrganizationSettingsRetrieveQuery({});

  if (
    isLoadingPricingV1Flag ||
    !isPricingV1Enabled ||
    isLoadingOrganizationSettings ||
    !organizationSettings?.is_pricing_v1_enabled
  ) {
    return null;
  }

  return (
    <CurrencyDollarIcon
      data-testid="price-summary-modal-button"
      className="h-6 w-6 hover:cursor-pointer"
      onClick={() => dispatch(onPriceSummaryModalOpen(tripId))}
    />
  );
}
