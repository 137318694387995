import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/app/store';
import DateRangePicker from 'src/common/date/DateRangePicker';

interface DateColumnFilterProps {
  open: boolean;
  onClose: () => void;
  setSelectedAction: ActionCreatorWithPayload<
    { from: string | undefined; to: string | undefined } | undefined,
    string
  >;
}

export function DateColumnFilter({
  open,
  onClose,
  setSelectedAction,
}: DateColumnFilterProps) {
  const dispatch = useAppDispatch();

  return (
    <DateRangePicker
      open={open}
      onClose={onClose}
      triggerElement={<div />}
      onDateRangeChange={(dateRange) => {
        const to = dateRange.to?.toISOString() || undefined;
        const from = dateRange.from?.toISOString() || undefined;
        dispatch(setSelectedAction({ from, to }));
      }}
    />
  );
}
