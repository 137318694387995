import { lazy, PropsWithChildren, Suspense, useState } from 'react';

import { useAuth } from 'src/features/auth/useAuth';

import { LoadingIndicator } from '../primitives/LoadingIndicator';

// Both the Map route and trip summary are rarely the user's first destination.
// Split them out since they're hefty
const MapView = lazy(() =>
  import('@aws-amplify/ui-react-geo').then((module) => ({
    default: module.MapView,
  }))
);

const NavigationControl = lazy(() =>
  import('react-map-gl').then((module) => ({
    default: module.NavigationControl,
  }))
);

export const BAMBI_MAPVIEW_DEFAULT_ZOOM_DURATION = 300;

type BambiMapViewProps = PropsWithChildren & {
  // TODO: This should be MapViewProps from @aws-amplify/ui-react-geo's MapView
  // However, it's not exported, so duplicating what we want here
  // There's probably some TS shenanigans with MapView to get the types exposed here
  mapViewProps?: {
    latitude?: number;
    longitude?: number;
    zoom?: number;
    onStyleData?: ((e: mapboxgl.MapStyleDataEvent) => void) | undefined;
    onLoad?: ((e: mapboxgl.MapboxEvent<undefined>) => void) | undefined;
    fitBoundsOptions?: mapboxgl.FitBoundsOptions;
  };
};

const DEFAULT_ZOOM_LEVEL = 9;

export function BambiMapView({
  children,
  mapViewProps = {
    zoom: DEFAULT_ZOOM_LEVEL,
  },
}: BambiMapViewProps) {
  const auth = useAuth();
  const [currentZoomLevelForTests, setCurrentZoomLevelForTests] =
    useState(DEFAULT_ZOOM_LEVEL);
  return (
    <>
      <style>{
        // TODO: Tried finding a MapView prop and also stitches
        // There is inline styling in the MapView component being set
        // One thing I didn't try is adding a container that's passed to stitches
        // and then using https://stitches.dev/docs/styling#descendant-selector
        // This is simple and works though
        `
        .maplibregl-map.mapboxgl-map {
          height: 100% !important;
          width: 100% !important;
        }
        .mapboxgl-popup-close-button {
          display: none;
        }
    `
      }</style>
      <div
        data-initial-address={auth.currentOrganizationHeadQuarters?.address}
        data-testid="current-zoom-level-for-tests"
        style={{ display: 'none' }}
      >
        {currentZoomLevelForTests}
      </div>
      <Suspense fallback={<LoadingIndicator />}>
        <MapView
          initialViewState={{
            latitude: auth.currentOrganizationLocation?.latLong.lat,
            longitude: auth.currentOrganizationLocation?.latLong.long,
            fitBoundsOptions: {
              duration: BAMBI_MAPVIEW_DEFAULT_ZOOM_DURATION,
            },
            ...mapViewProps,
          }}
          onLoad={(map) => {
            mapViewProps.onLoad?.(map);
          }}
          onStyleData={(map) => {
            mapViewProps.onStyleData?.(map);
          }}
          onZoom={(e) => {
            setCurrentZoomLevelForTests(e.viewState.zoom);
          }}
        >
          {/* See https://visgl.github.io/react-map-gl/docs/api-reference/navigation-control */}
          <NavigationControl
            position="bottom-right"
            showZoom
            showCompass={false}
          />
          {children}
        </MapView>
      </Suspense>
    </>
  );
}
