import { useMemo } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import TimeRangePicker from 'src/common/date/TimeRangePicker';
import { Badge } from 'src/common/primitives/Badge';
import { Button } from 'src/common/primitives/Button';

import { UnAssignedTripSearch } from '../../DispatchCalendar/UnAssignedTripSearch';
import { setUnassignedTripsTimeRange } from '../../dispatcher.slice';
import { formatUnassignedTimeRange } from './formatUnassignedTimeRange';

export function UnassignedTripsHeader() {
  const dispatch = useAppDispatch();
  const timeRange = useAppSelector(
    (state) => state.dispatcher.unassignedTripsTimeRange
  );
  const selectedDate = useAppSelector((state) => state.dispatcher.selectedDate);

  const formattedTimeRange = useMemo(
    () => formatUnassignedTimeRange(timeRange, selectedDate),
    [timeRange, selectedDate]
  );

  return (
    <div className={`relative flex flex-col gap-y-3 bg-white text-left`}>
      <div className="flex w-full items-start justify-between">
        <div>
          <TimeRangePicker
            triggerElement={
              <Button dataTestId="unassigned-trip-time-filter-trigger">
                <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" />
                Time
              </Button>
            }
            onTimeRangeChange={(timeRange) => {
              const { startTime, endTime } = timeRange;
              dispatch(
                setUnassignedTripsTimeRange({
                  startTime: startTime || '00:00',
                  endTime: endTime || '23:59',
                })
              );
            }}
          />
          {formattedTimeRange && (
            <div className="py-2">
              <Badge
                closeable
                onClose={() =>
                  dispatch(
                    setUnassignedTripsTimeRange({
                      from: undefined,
                      to: undefined,
                    })
                  )
                }
                color="primary"
                label={formattedTimeRange}
              ></Badge>
            </div>
          )}
        </div>
        <div className="min-w-[225px]">
          <UnAssignedTripSearch />
        </div>
      </div>
    </div>
  );
}
