import { BADGE_COLOR_VARIANTS, Badge } from 'src/common/primitives/Badge';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';

import { versionIsOutdated } from './appVersion/outdated';
import {
  MobilePlatform,
  VersionNumber,
  parseAppVersion,
} from './appVersion/parseAppVersion';
import {
  LatestAppVersions,
  useLatestAppVersions,
} from './appVersion/useLatestAppVersions';

interface DriverAppVersionCellProps {
  appVersion: string;
}

export function DriverAppVersionCell({
  appVersion,
}: DriverAppVersionCellProps) {
  const latest = useLatestAppVersions();
  const version = makeDisplayableAppVersion(appVersion, latest);
  if (!version) {
    return null;
  }
  const { displayVersion, isOutdated, displayLatestForPlatform } = version;
  const badgeColor = isOutdated
    ? BADGE_COLOR_VARIANTS.RED
    : BADGE_COLOR_VARIANTS.BLUE;

  // No tooltip if the request hasn't returned and we don't know the latest available version
  if (!displayLatestForPlatform) {
    return <Badge label={displayVersion} color={badgeColor} />;
  }

  const tooltipContent = makeTooltipContent(
    displayLatestForPlatform,
    isOutdated
  );

  return (
    <SimpleTooltip
      trigger={
        <div>
          <Badge label={displayVersion} color={badgeColor} />
        </div>
      }
      content={tooltipContent}
    />
  );
}

const makeTooltipContent = (latestVersion: string, isOutdated: boolean) => {
  const latestVersionText = `The latest version available is ${latestVersion}. `;
  if (isOutdated) {
    return (
      `This driver's app is outdated. ` +
      latestVersionText +
      'We recommend turning on automatic app updates on all devices to ensure drivers ' +
      'have the latest features and bug fixes.'
    );
  } else {
    return latestVersionText;
  }
};

interface DisplayableAppVersion {
  displayVersion: string;
  displayLatestForPlatform?: string;
  isOutdated: boolean;
}

export const makeDisplayableAppVersion = (
  appVersion: string,
  latest?: LatestAppVersions
): DisplayableAppVersion | undefined => {
  const parsedVersion = parseAppVersion(appVersion);
  if (!parsedVersion) {
    return undefined;
  }
  const { platform: platformKey, versionNumber } = parsedVersion;
  const formattedVersionNumber = formatVersionNumber(versionNumber);
  const platform = formatPlatform(platformKey);
  const displayVersion = `${platform} ${formattedVersionNumber}`;
  if (!latest) {
    return {
      displayVersion,
      isOutdated: false,
    };
  }

  const latestForPlatform = latest[platformKey];
  const displayLatestForPlatform = formatVersionNumber(
    latestForPlatform,
    false
  );
  const isOutdated = versionIsOutdated(versionNumber, latestForPlatform);

  return {
    displayVersion,
    displayLatestForPlatform,
    isOutdated,
  };
};

const formatPlatform = (platform: MobilePlatform): string => {
  switch (platform) {
    case 'ios':
      return 'iOS';
    case 'android':
      return 'Android';
    default:
      return '';
  }
};

const formatVersionNumber = (version: VersionNumber, withV = true): string => {
  const ota = version.ota ? ` (${version.ota})` : '';
  const prefix = withV ? 'v' : '';
  return `${prefix}${version.major}.${version.minor}.${version.patch}${ota}`;
};
