import React from 'react';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { styled } from '@stitches/react';

import { getDisableIconClasses } from 'src/common/primitives/icon/getDisableIconClasses';

const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
});
export const ChevronIcon = React.forwardRef(
  (
    { disabled, ...props }: { disabled?: boolean; props?: any },
    forwardedRef: React.Ref<SVGSVGElement>
  ) => {
    return (
      <StyledChevron
        className={`${getDisableIconClasses(disabled)} h-5 w-5`}
        ref={forwardedRef}
        {...props}
      />
    );
  }
);
