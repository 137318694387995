import { useState } from 'react';

import { WizardHeader } from 'src/common/wizard/WizardHeader';

export type WizardStep = {
  title: string;
  render: ({
    step,
    setStep,
    next,
    prev,
  }: {
    step: number;
    setStep: (newStep: number) => void;
    next: () => void;
    prev: () => void;
  }) => React.ReactNode;
};

export type WizardProps = {
  hideHeader?: boolean;
  steps: WizardStep[];
  testId?: string;
};

export function Wizard({ hideHeader = false, steps, testId }: WizardProps) {
  const [step, setStep] = useState(1);
  const currentStep = steps[step - 1];
  return (
    <div data-testid={testId}>
      {!hideHeader && (
        <WizardHeader
          title={currentStep.title}
          wizardState={{ step, totalSteps: steps.length }}
          setStep={setStep}
        />
      )}
      {currentStep.render({
        step,
        setStep,
        next: () => setStep(step + 1),
        prev: () => setStep(step - 1),
      })}
    </div>
  );
}
