import { LinkToPayerEnum } from 'src/common/external/bambi-api/bambiApi';

export function getTripLinkToPayer(
  isRideOrderingPortalUser: boolean,
  shouldLinkPassengerToPayer: boolean
): LinkToPayerEnum | null {
  if (isRideOrderingPortalUser) {
    return 'always';
  }

  if (shouldLinkPassengerToPayer) {
    return 'only_with_id';
  }

  return null;
}
