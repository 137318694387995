import { useEffect, useRef } from 'react';

import { FormFieldDefault } from 'src/common/FormField/FormFieldDefault';

import { TilledInputContainer } from './TilledInputContainer';
import { TilledFieldOptions } from './types';
import { useTilled } from './useTilled';

const fieldOptions: TilledFieldOptions = {
  styles: {
    base: {
      fontFamily: `"Inter var", ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      color: '#000',
      fontWeight: '400',
      fontSize: '14px',
    },
    invalid: {
      ':hover': {
        textDecoration: 'underline dotted red',
      },
      color: '#777777',
    },
    valid: {
      color: '#32CD32',
    },
  },
};

export function CreditCardCaptureForm({
  billingName,
  onChangeBillingName,
}: {
  billingName?: string;
  onChangeBillingName: (name: string) => void;
}) {
  const cardNumberContainer = useRef<HTMLDivElement>(null);
  const cardExpiryContainer = useRef<HTMLDivElement>(null);
  const cardSecurityContainer = useRef<HTMLDivElement>(null);

  const { createForm, teardownForm } = useTilled('card');

  useEffect(() => {
    if (cardNumberContainer.current != null) {
      createForm(
        {
          fields: {
            cardNumber: cardNumberContainer.current,
            cardExpiry: cardExpiryContainer.current,
            cardCvv: cardSecurityContainer.current,
          },
        },
        fieldOptions
      );
    }

    return teardownForm;
  }, [createForm, teardownForm]);

  return (
    <div>
      <div className="flex justify-evenly gap-4">
        <FormFieldDefault
          label="Name on Card"
          type="text"
          inputProps={{
            id: 'billing-name',
            value: billingName,
            onChange: (e) => {
              onChangeBillingName(e.target.value);
            },
          }}
          className="grow"
        />
        <TilledInputContainer
          ref={cardNumberContainer}
          label="Card Number"
          name="cardNumber"
        />
      </div>
      <div className="mt-2 flex justify-evenly gap-4">
        <TilledInputContainer
          ref={cardExpiryContainer}
          className="grow"
          label="Expiration Date"
          name="cardExpiry"
        />
        <TilledInputContainer
          ref={cardSecurityContainer}
          label="Security Code"
          name="cardCvv"
        />
      </div>
    </div>
  );
}
