import {
  OrganizationMerchantAccountOnboardingStatusEnum,
  OrganizationSettingsRead,
} from 'src/common/external/bambi-api/bambiApi';

import { ActiveTilledMerchantApplication } from './ActiveTilledMerchantApplication';
import { ContinueTilledMerchantApplication } from './ContinueTilledMerchantApplication';
import { DisabledTilledMerchantApplication } from './DisabledTilledMerchantApplication';
import { PendingTilledMerchantApplication } from './PendingTilledMerchantApplication';
import { RejectedTilledMerchantApplication } from './RejectedTilledMerchantApplication';
import { StartTilledMerchantApplication } from './StartTilledMerchantApplication';

type PartialStatusEnumRecord = {
  [K in OrganizationMerchantAccountOnboardingStatusEnum]?: JSX.Element;
};

type MerchantApplicationPhaseProps = Pick<
  OrganizationSettingsRead,
  'payment_account_id' | 'payment_account_status'
>;

export function MerchantApplicationPhase({
  payment_account_id,
  payment_account_status,
}: MerchantApplicationPhaseProps) {
  const phaseComponentToStatus: PartialStatusEnumRecord = {
    active: <ActiveTilledMerchantApplication />,
    created: <ContinueTilledMerchantApplication />,
    started: <ContinueTilledMerchantApplication />,
    in_review: <PendingTilledMerchantApplication />,
    submitted: <PendingTilledMerchantApplication />,
    rejected: <RejectedTilledMerchantApplication />,
    disabled: <DisabledTilledMerchantApplication />,
  };

  let phaseComponent;

  if (
    (!payment_account_id && payment_account_status === 'disabled') ||
    !payment_account_status
  ) {
    phaseComponent = <StartTilledMerchantApplication />;
  } else {
    phaseComponent =
      phaseComponentToStatus[
        payment_account_status as OrganizationMerchantAccountOnboardingStatusEnum
      ];
  }

  return phaseComponent ?? null;
}
