import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/app/store';
import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';

import StatusFilter from '../StatusFilter';

interface StatusColumnFilterProps {
  open: boolean;
  onClose: () => void;
  selected: OverrideStatusEnum[];
  setSelectedAction: ActionCreatorWithPayload<OverrideStatusEnum[], string>;
  isRom?: boolean;
}

export function StatusColumnFilter({
  open,
  onClose,
  selected,
  setSelectedAction,
  isRom,
}: StatusColumnFilterProps) {
  const dispatch = useAppDispatch();

  return (
    <StatusFilter
      open={open}
      selected={selected ?? []}
      triggerElement={<div />}
      onApply={(statuses) => {
        dispatch(setSelectedAction(statuses));
      }}
      onClose={onClose}
      isRom={isRom}
    />
  );
}
