import { combineReducers } from '@reduxjs/toolkit';

import { enhancedBambiApi } from 'src/common/external/bambi-api/enhancedBambiApi';
import { confirmationSlice } from 'src/common/modals/RequiresConfirmationModal/confirmation.slice';
import { toastSlice } from 'src/common/primitives/Toast/toast.slice';
import { addATrip } from 'src/features/add-trip/addATrip.slice';
import { authSlice } from 'src/features/auth/auth.slice';
import { invoiceSlice } from 'src/features/billing/invoices/invoice.slice';
import { chatSlice } from 'src/features/chat/chat.slice';
import { dispatcherSlice } from 'src/features/dispatch/dispatcher.slice';
import { fleetSlice } from 'src/features/fleet/fleet.slice';
import { fleetInspectionSlice } from 'src/features/fleet/inspection/fleetInspection.slice';
import { liveMapSlice } from 'src/features/live-map/liveMap.slice';
import { passengerSlice } from 'src/features/passenger/passenger.slice';
import { payerSlice } from 'src/features/payer/payers.slice';
import { pricingSlice } from 'src/features/pricing/pricing.slice';
import { pricingModelSlice } from 'src/features/pricingModel/pricingModel.slice';
import { settingSlice } from 'src/features/settings/setting.slice';
import { assignmentManagementSlice } from 'src/features/team/assignmentManagement.slice';
import { memberSlice } from 'src/features/team/member.slice';
import { teamSlice } from 'src/features/team/team.slice';
import { romAllRequestsSlice } from 'src/features/trip/rom/all-requests/rom-all-requests.slice';
import { tripSummarySlice } from 'src/features/trip/summary/tripSummary.slice';
import { tripSubscriptionsSlice } from 'src/features/trip/trip-subscriptions/trip-subscriptions.slice';
import { tripSlice } from 'src/features/trip/trip.slice';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  confirmation: confirmationSlice.reducer,
  toast: toastSlice.reducer,
  team: teamSlice.reducer,
  fleet: fleetSlice.reducer,
  fleetInspection: fleetInspectionSlice.reducer,
  member: memberSlice.reducer,
  assignmentManagement: assignmentManagementSlice.reducer,
  passenger: passengerSlice.reducer,
  addATrip: addATrip.reducer,
  dispatcher: dispatcherSlice.reducer,
  trip: tripSlice.reducer,
  tripSubscription: tripSubscriptionsSlice.reducer,
  payer: payerSlice.reducer,
  pricingModel: pricingModelSlice.reducer,
  chat: chatSlice.reducer,
  liveMap: liveMapSlice.reducer,
  setting: settingSlice.reducer,
  tripSummary: tripSummarySlice.reducer,
  invoice: invoiceSlice.reducer,
  romAllRequests: romAllRequestsSlice.reducer,
  pricing: pricingSlice.reducer,
  [enhancedBambiApi.reducerPath]: enhancedBambiApi.reducer,
});
