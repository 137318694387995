import { TabNavigation } from 'src/common/TabNavigation';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { nonRideOrderingPortalTabs } from './nonRideOrderingPortalTabs';
import { rideOrderingPortalTabs } from './rideOrderingPortalTabs';

export function TeamNav() {
  const shouldShowRideOrderingNavBar =
    useShouldShowRideOrderingPortalFeatures();
  return (
    <TabNavigation
      tabs={
        shouldShowRideOrderingNavBar
          ? rideOrderingPortalTabs
          : nonRideOrderingPortalTabs
      }
    />
  );
}
