import React from 'react';

import { NavBar } from 'src/common/NavBar';

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col gap-1">
      <NavBar />
      {children}
    </div>
  );
}
