import { Suspense, lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

const Chat = lazy(() =>
  import('./Chat').then((module) => ({ default: module.Chat }))
);

export const chatRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <Suspense fallback={<LoadingIndicator />}>
        <Chat />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/chat" linkText="Go back to chat" />
    ),
  },
];
