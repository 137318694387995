import { useAppSelector } from 'src/app/store';

import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';
import { EditPriceModelButton } from './EditPriceModelButton';
import { PricingModelCancelEditButton } from './PricingModelCancelEditButton';
import { PricingModelDeleteButton } from './PricingModelDeleteButton';
import { PricingModelIsDefaultQuickAction } from './PricingModelIsDefaultQuickAction';
import { SavePriceModelButton } from './SavePriceModelButton';

export function PricingModelDetailsActions() {
  const pricingModelDetailsDraft = useAppSelector((state) => {
    return state.pricingModel.pricingModelDetailsDraft;
  });
  const selectedPricingModel = useSelectedPricingModel();

  if (!(pricingModelDetailsDraft || selectedPricingModel)) {
    return (
      <div>
        Didn't find a selected pricing model or draft, which shouldn't happen.
      </div>
    );
  }

  const isEditingOrCreating = Boolean(pricingModelDetailsDraft);
  return (
    <>
      {isEditingOrCreating ? (
        <>
          <PricingModelCancelEditButton />
          <SavePriceModelButton />
        </>
      ) : (
        <>
          <PricingModelDeleteButton />
          <PricingModelIsDefaultQuickAction />
          <EditPriceModelButton />
        </>
      )}
    </>
  );
}
