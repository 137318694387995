export interface CardProps {
  // Extra classNames to assign to the container
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  'data-testid'?: string;
}

export function Card({
  className,
  children,
  onClick,
  'data-testid': testid,
}: CardProps) {
  return (
    <div
      className={`block flex-grow rounded-lg border bg-white px-3 py-2 shadow-sm focus:outline-none ${
        className ?? ''
      }`}
      data-testid={testid}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
