import { useCallback } from 'react';

import { useAppDispatch } from 'src/app/store';
import { useLazyInvoicesInvoicesRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { editInvoice, setInvoiceModalOpen } from '../invoice.slice';

export function useEditInvoiceById() {
  const dispatch = useAppDispatch();
  const [getInvoice] = useLazyInvoicesInvoicesRetrieveQuery();

  return useCallback(
    async (invoiceId: string) => {
      if (typeof invoiceId !== 'string') {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to fetch invoice',
            description: `Invoice ID is an unexpected type: ${typeof invoiceId}`,
          })
        );
        return;
      }

      const { data, error } = await getInvoice({
        id: invoiceId,
      });

      if (error) {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to fetch invoice',
            description: formatServerError(error),
          })
        );
        return;
      }

      if (!data) {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to fetch invoice',
            description: 'Invoice not found',
          })
        );
        return;
      }

      dispatch(editInvoice(data));
      dispatch(setInvoiceModalOpen(true));
    },
    [dispatch, getInvoice]
  );
}
