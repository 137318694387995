import { useAppDispatch, useAppSelector } from 'src/app/store';

import { InvoiceTotalDueFilter } from '../../filters/InvoiceTotalDueFilter';
import { setFilterTotalAmountDue } from '../../invoice.slice';

interface InvoiceTotalDueColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function InvoiceTotalDueColumnFilter({
  open,
  onClose,
}: InvoiceTotalDueColumnFilterProps) {
  const dispatch = useAppDispatch();
  const totalAmountDueRange = useAppSelector(
    (state) => state.invoice.filterTotalAmountDue
  );

  return (
    <InvoiceTotalDueFilter
      open={open}
      triggerElement={<div />}
      value={totalAmountDueRange}
      onChange={(range) => {
        dispatch(setFilterTotalAmountDue(range));
      }}
      onClose={onClose}
    />
  );
}
