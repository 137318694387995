import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { InfoCircledIcon } from '@radix-ui/react-icons';

import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { Button } from 'src/common/primitives/Button';
import { Popover } from 'src/common/primitives/Popover';
import { ExistingPassengerSearch } from 'src/features/add-trip/PassengerInfoFormSection/ExistingPassengerSearch';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { useUpdateFormValuesFromPassenger } from './useUpdateFormValuesFromPassenger';

export interface PassengerInfoFormSectionProps {
  selectedPassenger: PassengerRead | null | undefined;
  onPassengerSelected: (passenger: PassengerRead | undefined) => void;
  onPassengerEdit: (passenger: PassengerRead) => void;
}

function AddPassengerNudgeButton() {
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  return (
    <Popover
      content={
        <div
          data-testid="passenger-add-button-nudge-popover"
          className="flex max-h-[70vh] flex-col gap-2 overflow-y-auto p-2"
        >
          <p>We've made it easier to find and add new passengers:</p>
          <ol>
            <li className="list-inside list-decimal">
              Start typing the passenger's name in the search to the left
            </li>
            <li className="list-inside list-decimal">
              If the passenger exists, click to select
            </li>
            <li className="list-inside list-decimal">
              If the passenger is not found, click the "Add ..." option and fill
              out the passenger form as normal
            </li>
          </ol>
          <img
            className="mx-auto max-w-2xl p-4"
            src={process.env.PUBLIC_URL + '/add-passenger-search.gif'}
            alt="Use the existing passenger search to look for a passenger. If not found, you will be able to add one there."
          />
          <div className="flex justify-end">
            <Button
              variant="primary"
              onClick={() => {
                // close the popover and focus on the search box
                document.body.click();
                document
                  .querySelector<HTMLInputElement>(
                    '[data-testid="existing-passenger-search"]'
                  )
                  ?.focus();
              }}
              dataTestId="passenger-nudge-confirm-button"
            >
              Ok, let's add a passenger
            </Button>
          </div>
        </div>
      }
      trigger={
        <Button
          className="flex gap-2"
          variant="secondary"
          disabled={isSelectedTripInThePast}
          dataTestId="add-passenger-button"
        >
          <InfoCircledIcon className="h-5 w-5" /> How to add a passenger
        </Button>
      }
    />
  );
}

export function PassengerInfoFormSection({
  selectedPassenger,
  onPassengerSelected,
  onPassengerEdit,
}: PassengerInfoFormSectionProps) {
  useUpdateFormValuesFromPassenger({
    passenger: selectedPassenger,
  });
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  return (
    <div>
      <DefaultFormFieldLayout
        label="Passenger *"
        inputProps={{ id: 'passenger' }}
      >
        <div className="flex gap-1">
          <div className="flex grow flex-col">
            <ExistingPassengerSearch
              selectedPassenger={selectedPassenger}
              onPassengerSelected={onPassengerSelected}
              disabled={isSelectedTripInThePast}
            />
          </div>
          {!shouldShowRideOrderingPortalFeatures && (
            <Button
              disabled={!selectedPassenger?.id}
              className="flex gap-2"
              variant="secondary"
              onClick={() => {
                selectedPassenger && onPassengerEdit(selectedPassenger);
              }}
              dataTestId="edit-passenger-button"
            >
              <PencilSquareIcon className="h-5 w-5" /> Edit Selected Passenger
            </Button>
          )}
          <AddPassengerNudgeButton />
        </div>
      </DefaultFormFieldLayout>
    </div>
  );
}
