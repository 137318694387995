import { NewStatusEnum } from 'src/common/external/bambi-api/bambiApi';

export function getRevertedStatusForTrip(status: string): NewStatusEnum {
  switch (status) {
    case 'completed':
      return 'at dropoff';
    case 'at dropoff':
      return 'transporting';
    case 'transporting':
      return 'at pickup';
    case 'at pickup':
      return 'en route';
    default:
      throw new Error(`Cannot revert status ${status}`);
  }
}
