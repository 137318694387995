export function VehicleIcon() {
  return (
    <svg
      className="relative shrink-0 overflow-visible"
      style={{}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6666 10.6667V4.13333C10.6666 3.3866 10.6666 3.01323 10.5213 2.72801C10.3934 2.47713 10.1895 2.27316 9.93857 2.14532C9.65336 2 9.27999 2 8.53325 2H3.46659C2.71985 2 2.34648 2 2.06126 2.14532C1.81038 2.27316 1.60641 2.47713 1.47858 2.72801C1.33325 3.01323 1.33325 3.3866 1.33325 4.13333V8.53333C1.33325 9.28007 1.33325 9.65344 1.47858 9.93865C1.60641 10.1895 1.81038 10.3935 2.06126 10.5213C2.34648 10.6667 2.71985 10.6667 3.46659 10.6667H10.6666ZM10.6666 10.6667H13.5999C13.9733 10.6667 14.16 10.6667 14.3026 10.594C14.428 10.5301 14.53 10.4281 14.5939 10.3027C14.6666 10.1601 14.6666 9.97337 14.6666 9.6V7.77516C14.6666 7.6121 14.6666 7.53057 14.6482 7.45385C14.6318 7.38582 14.6049 7.32079 14.5683 7.26114C14.5271 7.19387 14.4695 7.13622 14.3542 7.02091L12.979 5.64575C12.8637 5.53045 12.8061 5.4728 12.7388 5.43157C12.6791 5.39502 12.6141 5.36808 12.5461 5.35175C12.4693 5.33333 12.3878 5.33333 12.2248 5.33333H10.6666M5.99992 12.3333C5.99992 13.2538 5.25373 14 4.33325 14C3.41278 14 2.66659 13.2538 2.66659 12.3333C2.66659 11.4129 3.41278 10.6667 4.33325 10.6667C5.25373 10.6667 5.99992 11.4129 5.99992 12.3333ZM13.3333 12.3333C13.3333 13.2538 12.5871 14 11.6666 14C10.7461 14 9.99992 13.2538 9.99992 12.3333C9.99992 11.4129 10.7461 10.6667 11.6666 10.6667C12.5871 10.6667 13.3333 11.4129 13.3333 12.3333Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
