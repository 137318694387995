import { PencilIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { onPricingRuleEdit } from 'src/features/pricingModel/pricingModel.slice';

export function PricingRuleEditButton({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  return (
    <Button
      className="flex gap-1"
      onClick={(e) => {
        // This lives in an accordion so let's not toggle that when we're trying to save
        e.stopPropagation();
        dispatch(onPricingRuleEdit(pricingRule));
      }}
      variant="ghost"
      dataTestId={`pricing-rule-edit-button-${pricingRule.id}`}
    >
      <PencilIcon className="h-5 w-5" title="Edit" />
      <span className="sr-only">Edit</span>
    </Button>
  );
}
