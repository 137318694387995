import { useEffect, useState } from 'react';

import { debounce } from 'lodash-es';

import { FormFieldCheckbox } from 'src/common/FormField/FormFieldCheckbox';
import { SearchTypeahead } from 'src/common/SearchTypeahead';

import { FilterPopover } from './FilterPopover';

export type IdentifiedEntity = { id: string | number };

export interface MultiSelectFilterPopoverProps<
  T extends unknown = IdentifiedEntity
> {
  triggerElement: React.ReactNode;
  value?: T[];
  filters: T[];
  open?: boolean;
  searchPlaceholder?: string;
  renderLabel: (value: T) => React.ReactNode;
  onChange: (value: T[]) => void;
  onSearch?: (term: string) => void;
  onCancel?: () => void;
}
export function MultiSelectFilterPopover<T extends IdentifiedEntity>({
  triggerElement,
  value,
  filters,
  open,
  searchPlaceholder = 'Search...',
  renderLabel,
  onChange,
  onSearch,
  onCancel,
}: MultiSelectFilterPopoverProps<T>) {
  const [selectedFilters, setSelected] = useState([] as T[]);

  const handleSearch = (term: string) => {
    onSearch?.(term);
  };

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const debounceSearch = debounce(handleSearch, 300);
  return (
    <FilterPopover
      open={open}
      triggerElement={triggerElement}
      onApply={() => {
        onChange(selectedFilters);
        onSearch?.('');
      }}
      onCancel={() => {
        onSearch?.('');
        setSelected(value ?? []);
        onCancel?.();
      }}
      onOpenChange={() => {
        setSelected(value ?? []);
        onCancel?.();
      }}
    >
      <>
        {onSearch && (
          <SearchTypeahead
            dataTestId="filter-popover-search"
            onChange={debounceSearch}
            placeholder={searchPlaceholder}
          />
        )}
        {filters.map((filter) => (
          <div className="my-2" key={filter.id}>
            <FormFieldCheckbox
              type="checkbox"
              label={renderLabel(filter)}
              value={
                !!selectedFilters.find((selected) => selected.id === filter.id)
              }
              onChange={(selected) => {
                let mutSelected = [...selectedFilters];

                if (selected) {
                  mutSelected.push(filter);
                } else {
                  mutSelected = mutSelected.filter((t) => t.id !== filter.id);
                }
                setSelected(mutSelected);
              }}
              inputProps={{
                id: filter.id + '', // Force string for input
              }}
            />
          </div>
        ))}
      </>
    </FilterPopover>
  );
}
