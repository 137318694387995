import { FormValues } from './FormValues';

export const defaultFormValues: FormValues = {
  id: '',
  membership: '',
  first_name: '',
  last_name: '',
  can_be_scheduled: true,
  can_do_wheelchair_service: true,
  can_do_stretcher_service: true,
  can_do_ambulatory_service: true,
  can_do_curb_to_curb: true,
  can_do_door_to_door: true,
  can_do_door_through_door: true,
  can_do_bariatric: false,
  can_do_stairs: false,
  can_do_wheelchair_xl_service: false,
  can_do_broda_chair_service: true,
  can_do_geri_chair_service: true,
};
