import { useAppSelector } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';

import PricingRuleAdditionalBaseInput from './PricingRuleAdditionalBaseInput';
import PricingRuleDescriptionInput from './PricingRuleDescriptionInput';
import PricingRuleAccordionMatchConditions from './PricingRuleMatchConditions';
import PricingRulePercentageInput from './PricingRulePercentageInput';
import PricingRuleUnitsCovered from './PricingRuleUnitsCovered';

export default function PricingRuleAccordionContentEdit({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const pricingRuleDraft = useAppSelector((state) =>
    state.pricingModel.pricingRuleDrafts?.find(
      (draft) => draft.id === pricingRule.id
    )
  );

  if (!pricingRuleDraft) {
    return (
      <div>
        No pricing rule draft found. This should never happen in practice.
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <PricingRuleAccordionMatchConditions pricingRule={pricingRuleDraft} />
      <PricingRuleAdditionalBaseInput pricingRule={pricingRuleDraft} />
      <PricingRulePercentageInput pricingRule={pricingRuleDraft} />
      <PricingRuleUnitsCovered pricingRule={pricingRuleDraft} />
      <PricingRuleDescriptionInput pricingRule={pricingRuleDraft} />
    </div>
  );
}
