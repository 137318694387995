import * as React from 'react';

import { Row, RowData, Table } from '@tanstack/react-table';

import { DataGridCell } from './DataGridCell';

interface DataGridTableBodyProps<T> {
  table: Table<T>;
  emptyState?: React.ReactNode;
  rowClassName?: (row: Row<T>) => string;
  cellClassName?: string;
}

export function DataGridTableBody<T extends RowData>({
  table,
  emptyState,
  cellClassName,
  rowClassName,
}: DataGridTableBodyProps<T>) {
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {table.getRowModel().rows.map((row) => (
        <tr
          onClick={
            row.getCanExpand() ? row.getToggleExpandedHandler() : () => {}
          }
          key={row.id}
          className={rowClassName && rowClassName(row)}
          data-testid={row.id}
        >
          {row.getVisibleCells().map((cell) => (
            <DataGridCell
              cellClassName={cellClassName}
              key={cell.id}
              cell={cell}
            />
          ))}
        </tr>
      ))}
      {table.getRowModel().rows.length === 0 && (
        <tr>
          <td colSpan={table.getAllColumns().length}>{emptyState}</td>
        </tr>
      )}
    </tbody>
  );
}
