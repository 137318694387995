import { useAppDispatch, useAppSelector } from 'src/app/store';
import { dispatcherSlice } from 'src/features/dispatch/dispatcher.slice';

export function RequestTripDetailsSelectionCell({
  tripId,
}: {
  tripId: string;
}) {
  const dispatch = useAppDispatch();
  const requestActionConfirmModalRowSelection = useAppSelector(
    (state) => state.dispatcher.modals.requestActionConfirmModalRowSelection
  );
  return (
    <input
      data-testid={`row-selection-control-${tripId}`}
      type="checkbox"
      checked={!!requestActionConfirmModalRowSelection[tripId]}
      onChange={(e) => {
        dispatch(
          dispatcherSlice.actions.setRequestActionConfirmModalRowSelection({
            ...requestActionConfirmModalRowSelection,
            [tripId]: e.target.checked,
          })
        );
      }}
    />
  );
}
