import { RootState, useAppSelector } from 'src/app/store';

import { TripCard } from '../../trips/TripCard/TripCard';

export function NotAssignedList() {
  const trips_not_assigned = useAppSelector(
    (state: RootState) => state.dispatcher.runBambiRunResult?.trips_not_assigned
  );
  return (
    <div
      data-testid="not-assiged-tab"
      className="max-h-[60vh] flex-grow overflow-y-auto"
    >
      {trips_not_assigned?.length ? (
        <ul className="flex flex-col gap-y-3">
          {trips_not_assigned.map((not_assigned) => (
            <li key={not_assigned.id}>
              <TripCard trip={not_assigned} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex h-full items-center justify-center text-center text-gray-500">
          All trips assigned!
        </div>
      )}
    </div>
  );
}
