import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  useAssignmentsListQuery,
  useVehiclesListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import AssignmentDownloadModal from '../AssignmentDownloadModal';
import { setAssignments, setSelectedDay } from '../assignmentManagement.slice';
import { AssignmentManagementForm } from '../AssignmentManagementForm/AssignmentManagementForm';
import { MultiAssignmentManagementForm } from '../MultiAssignmentManagementForm/MultiAssignmentManagementForm';
import { AssignmentManagementDisplay } from './AssignmentManagementDisplay';
import { AssignmentManagementToolbar } from './AssignmentManagementToolbar';

export function AssignmentManagementRoute() {
  const dispatch = useDispatch();
  const { selectedDay, assignmentSearchTerm } = useSelector(
    (state: RootState) => state.assignmentManagement
  );
  const selectedDayOrToday = selectedDay || DateTime.local().toISODate();

  const isPastDate =
    DateTime.now().startOf('day') > DateTime.fromISO(selectedDayOrToday);

  const {
    data: assignments,
    error: assignmentsError,
    isLoading: assignmentsLoading,
    refetch: refetchAssignments,
  } = useAssignmentsListQuery({
    rangeStart: DateTime.fromISO(selectedDayOrToday)
      .startOf('day')
      .toUTC()
      .toISO(),
    rangeEnd: DateTime.fromISO(selectedDayOrToday).endOf('day').toUTC().toISO(),
    search: assignmentSearchTerm,
  });

  const {
    data: vehicles,
    error: vehiclesError,
    isLoading: vehiclesLoading,
  } = useVehiclesListQuery({
    canBeScheduled: true,
    deletedObjects: isPastDate ? 'include' : undefined,
  });

  useEffect(() => {
    dispatch(setAssignments(assignments?.results || []));
  }, [dispatch, assignments]);

  useEffect(() => {
    refetchAssignments();
  }, [refetchAssignments, selectedDay, assignmentSearchTerm]);

  // set selected day to today on mount
  // This is set in the initial state in the store,
  // but the org's timezone may not be set yet as Settings.defaultZone
  useEffect(() => {
    if (!selectedDay) {
      dispatch(setSelectedDay(DateTime.local().toISODate()));
    }
  }, [dispatch, selectedDay]);

  const assignmentDate = DateTime.fromISO(selectedDayOrToday);

  // only want to display vehicles that are not deleted
  // or have a deleted_at date that is before the selected day
  const displayVehicles =
    vehicles?.results.filter((vehicle) => {
      if (!vehicle.deleted_at) return true;

      const deletedAt = DateTime.fromISO(vehicle.deleted_at).startOf('day');
      const selectedDateTime =
        DateTime.fromISO(selectedDayOrToday).startOf('day');

      return deletedAt >= selectedDateTime;
    }) || [];

  if (assignmentsError || vehiclesError) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      {assignmentsLoading || vehiclesLoading ? (
        <div className="flex h-[70vh] w-[96vw] items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <AssignmentManagementToolbar
            assignmentDate={assignmentDate}
            setSelectedDate={(date) => {
              dispatch(setSelectedDay(date.toISODate()));
            }}
          />
          <div className="mx-[-2rem]">
            <AssignmentManagementDisplay
              vehicles={displayVehicles || []}
              assignments={assignments?.results || []}
              assignmentDate={assignmentDate}
            />
          </div>
        </>
      )}
      <AssignmentManagementForm />
      <MultiAssignmentManagementForm />
      <AssignmentDownloadModal />
    </>
  );
}
