import { useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import { Button } from '../Button';

export interface UploadLogoFieldProps {
  onChange: (file: File | null) => void;
  value?: string;
}
export function UploadLogoField({ onChange, value }: UploadLogoFieldProps) {
  const [images, setImages] = useState<ImageListType>([]);

  // Respect changes to value on mount
  useEffect(() => {
    if (value && images.length === 0) {
      setImages([
        {
          data: value,
        },
      ]);
    }
  }, [value, images]);

  const handleFileChange = (
    imageList: ImageListType,
    // Including to satisfy the type
    addUpdateIndex?: Array<number>
  ) => {
    setImages(imageList);
    onChange(imageList[0]?.file ?? null);
  };

  return (
    <ImageUploading
      value={images}
      onChange={handleFileChange}
      maxNumber={1}
      dataURLKey="data"
      resolutionType="ratio"
    >
      {({ imageList, onImageUpload, onImageUpdate, onImageRemove }) => {
        const hasImage = !!imageList.length;
        return (
          <div className="flex flex-row">
            <Button
              onClick={hasImage ? () => onImageUpdate(0) : onImageUpload}
              dataTestId="upload-logo-trigger"
            >
              {imageList.length === 0 ? (
                'Click here to upload a logo'
              ) : (
                <img
                  src={imageList[0]['data']}
                  alt="company logo"
                  width="200"
                  style={{ maxHeight: '100px' }}
                />
              )}
            </Button>
            {hasImage ? (
              <Button
                variant="ghost-icon"
                ariaLabel="Reset logo"
                onClick={() => onImageRemove(0)}
              >
                <TrashIcon className="w-5" />
              </Button>
            ) : null}
          </div>
        );
      }}
    </ImageUploading>
  );
}
