import { useTripsPriceSummaryRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { PriceSummaryTable } from './PriceSummaryTable/PriceSummaryTable';

export function PriceSummaryModalContent({
  selectedTripId,
}: {
  selectedTripId?: string;
}) {
  const { data, isLoading, isFetching, error } =
    useTripsPriceSummaryRetrieveQuery(
      { id: selectedTripId || '' },
      { skip: !selectedTripId }
    );

  if (isLoading || isFetching) {
    return (
      <div className="flex min-h-[300px] items-center justify-center text-lg">
        <LoadingIndicator />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex min-h-[300px] items-center justify-center text-lg">
        There was an error fetching the price summary.
      </div>
    );
  }

  if (data?.price_summary) {
    return <PriceSummaryTable summary={data.price_summary} />;
  }

  return (
    <div className="flex min-h-[300px] items-center justify-center text-lg">
      No Price Summary Available for this Trip.
    </div>
  );
}
