import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';

import {
  onCancelAssignTrip,
  onCancelBulkAssignTrip,
} from '../dispatcher.slice';
import { useAssignTripFlow } from './useAssignTripFlow';
import { useBulkAssignTripFlow } from './useBulkAssignTripFlow';

export function AssignTripFlow() {
  const { isAssignTripModalOpen, validateAssignmentResponse, onConfirm } =
    useAssignTripFlow();

  const {
    isAssignTripModalOpen: isBulkAssignTripModalOpen,
    validationMessages,
    onConfirm: onBulkAssignConfirm,
  } = useBulkAssignTripFlow();

  const dispatch = useAppDispatch();

  const title = isBulkAssignTripModalOpen
    ? 'Invalid Trip Assignments'
    : 'Invalid Trip Assignment';

  const description = isBulkAssignTripModalOpen
    ? `Are you sure you want to assign these trips?`
    : 'Are you sure you want to assign this trip?';

  return (
    <ConfirmModal
      open={isAssignTripModalOpen || isBulkAssignTripModalOpen}
      setOpen={(isOpen) => {
        if (!isOpen)
          dispatch(
            isAssignTripModalOpen
              ? onCancelAssignTrip()
              : onCancelBulkAssignTrip()
          );
      }}
      onCancel={() => {
        dispatch(
          isAssignTripModalOpen
            ? onCancelAssignTrip()
            : onCancelBulkAssignTrip()
        );
      }}
      onConfirm={isAssignTripModalOpen ? onConfirm : onBulkAssignConfirm}
      cancelText="Cancel"
      confirmText="Assign"
      title={title}
      description={description}
      error={
        isAssignTripModalOpen
          ? validateAssignmentResponse?.explanation
          : validationMessages
      }
      icon={<ExclamationTriangleIcon className="w-5" />}
      mode="destructive"
    />
  );
}
