import { DateTime } from 'luxon';

import { useAppDispatch } from 'src/app/store';
import DateRangePicker from 'src/common/date/DateRangePicker';

import { setFilterDateRange } from '../../invoice.slice';

interface DateIssuedColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function DateIssuedColumnFilter({
  open,
  onClose,
}: DateIssuedColumnFilterProps) {
  const dispatch = useAppDispatch();
  // TODO selected? Skipping for now because its not currently supported

  return (
    <DateRangePicker
      open={open}
      triggerElement={<div />}
      onDateRangeChange={(dateRange) => {
        const from = dateRange.from
          ? DateTime.fromJSDate(dateRange.from).toISODate()
          : undefined;
        const to = dateRange.to
          ? DateTime.fromJSDate(dateRange.to).toISODate()
          : undefined;
        dispatch(
          setFilterDateRange({
            rangeStart: from,
            rangeEnd: to,
          })
        );
      }}
      onClose={onClose}
    />
  );
}
