import { useFormContext } from 'react-hook-form';

import { Button } from 'src/common/primitives/Button';

export function FormCancelButton({
  className,
  isSubmitting,
  onCancel,
}: {
  className?: string;
  isSubmitting?: boolean;
  onCancel: () => void;
}) {
  const { formState, reset } = useFormContext();

  return (
    <Button
      className={className}
      onClick={() => {
        reset();
        onCancel();
      }}
      disabled={isSubmitting || formState.isSubmitting}
    >
      Cancel
    </Button>
  );
}
