import { useAppDispatch, useAppSelector } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { onPricingRuleEditChange } from 'src/features/pricingModel/pricingModel.slice';

export function PricingRuleNameField({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  const pricingRuleDraft = useAppSelector((state) =>
    state.pricingModel.pricingRuleDrafts?.find(
      (draft) => draft.id === pricingRule.id
    )
  );
  if (!pricingRuleDraft) {
    return (
      <div>
        No pricing rule draft found. This should never happen in practice.
      </div>
    );
  }

  return (
    <FormField
      type="text"
      inputProps={{
        id: 'pricing-rule-name',
        placeholder: 'Pricing Rule Name',
        value: pricingRuleDraft.name,
        onChange: (e) => {
          dispatch(
            onPricingRuleEditChange({
              ...pricingRuleDraft,
              name: e.target.value,
            })
          );
        },
      }}
    />
  );
}
