import { IdentifiedOutcome } from './IdentifiedOutcome';

export function getHasFailValue(
  selected: IdentifiedOutcome[]
): boolean | undefined {
  const hasFail = selected.find((s) => s.id === 'fail');
  const hasPass = selected.find((s) => s.id === 'pass');

  const isNoneOrAllSelected = !selected.length || (hasFail && hasPass);
  if (isNoneOrAllSelected) {
    return undefined;
  }

  return !!hasFail;
}
