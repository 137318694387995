import { IToastConfig } from 'src/common/primitives/Toast';

export function generateUpdateSuccessToast(id: number): IToastConfig {
  return {
    id,
    title: `Success`,
    description: 'The tag has been updated.',
    type: 'success',
  };
}
