import { secondsToMinutes } from 'date-fns';

import { PayerOrganizationRead } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

export default function PayerSettingDetails({
  payer,
}: {
  payer: PayerOrganizationRead;
}) {
  return (
    <div className="flex max-w-[35em] flex-col gap-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex">
            <h3 className=" pb-1 text-xl font-bold leading-6">
              {payer.display_name} Settings
            </h3>
          </div>
          <hr className="my-1" />
          {payer.default_trip_address?.label && (
            <>
              <div className="py-1 font-bold">
                <p className="text-lg">Default Trip Address</p>
                <p className="text-sm text-gray-500">
                  {payer.default_trip_address.label}
                </p>
              </div>
              <hr className="my-1" />
            </>
          )}

          <div className="font-bold">
            <p className="text-lg">Signature Required</p>
            <SignatureRequired
              title="Driver Pickup"
              required={payer.requires_signature_pickup_driver}
            />
            <SignatureRequired
              title="Passenger Pickup"
              required={payer.requires_signature_pickup_passenger}
            />
            <SignatureRequired
              title="Driver Dropoff"
              required={payer.requires_signature_dropoff_driver}
            />
            <SignatureRequired
              title="Passenger Dropoff"
              required={payer.requires_signature_dropoff_passenger}
            />
          </div>
          <hr className="my-1" />
          <div className="font-bold">
            <p className="text-lg">Pickup Buffers</p>
            <PickupBuffer
              title="Pickup"
              bufferEarly={payer.pickup_default_early_seconds}
              bufferLate={payer.pickup_default_late_seconds}
            />
            <PickupBuffer
              title="Pickup Appointment"
              bufferEarly={payer.pickup_appointment_early_seconds}
              bufferLate={payer.pickup_appointment_late_seconds}
            />
            <PickupBuffer
              title="Pickup Will-call"
              bufferEarly={payer.pickup_will_call_early_seconds}
              bufferLate={payer.pickup_will_call_late_seconds}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const SignatureRequired = ({
  required,
  title,
}: {
  required: boolean;
  title: string;
}) => {
  return (
    <div className="grid grid-cols-2 py-1 text-sm text-gray-500">
      <span className="mr-2 text-base text-gray-900">{title}: </span>
      <div>
        <Badge
          color={required ? 'green' : 'red'}
          label={required ? 'Yes' : 'No'}
        />
      </div>
    </div>
  );
};

const PickupBuffer = ({
  bufferEarly,
  bufferLate,
  title,
}: {
  bufferEarly: number | undefined;
  bufferLate: number | undefined;
  title: string;
}) => {
  return (
    <div className="flex flex-col border-b py-1">
      <p className="text-base text-gray-900">{title}</p>
      <div className="flex">
        <p className="text-sm text-gray-500">
          Early:{' '}
          {bufferEarly ? `${secondsToMinutes(bufferEarly)} Minutes` : 'N/A'}
        </p>
        <p className="px-4 text-sm text-gray-500">
          Late: {bufferLate ? `${secondsToMinutes(bufferLate)} Minutes` : 'N/A'}
        </p>
      </div>
    </div>
  );
};
