import { DateTime } from 'luxon';

export const formatDateAsTodayOrMonthDay = (
  selectedDate: string,
  includeYear?: boolean
) => {
  if (!selectedDate) return '';

  const date = DateTime.fromISO(selectedDate);
  if (date.hasSame(DateTime.local(), 'day')) return 'today';

  const format = includeYear ? 'LLLL d, yyyy' : 'LLLL d';

  return DateTime.fromISO(selectedDate).toFormat(format);
};
