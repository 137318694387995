import {
  AssignedAttendantRead,
  AttendantRead,
} from 'src/common/external/bambi-api/bambiApi';

export const convertAttendantsToAssignedAttendants = (
  attendants: AttendantRead[]
): AssignedAttendantRead[] => {
  return attendants.map((x) => ({
    attendant: x.id,
    first_name: x.first_name,
    last_name: x.last_name,
    membership: x.membership,
  }));
};
