import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/app/store';
import {
  useAssignmentsRetrieveQuery,
  useTripsListQuery,
} from 'src/common/external/bambi-api/bambiApi';

export const useAcceptRequestTripDetails = () => {
  const { requestedTripId, assignmentId } = useAppSelector(
    (state) => state.dispatcher.modals
  );

  const [tripIdsToAccept, setTripIdsToAccept] = useState<string[]>([]);
  const {
    runBambiRunResult,
    tripAssignmentsToApplyIds,
    selectedUnassignedTripIds,
    dispatchResponse,
  } = useAppSelector((state) => state.dispatcher);
  const {
    data: tripData,
    isLoading: isLoadingTrips,
    isFetching: isFetchingTrips,
  } = useTripsListQuery(
    {
      idIn: tripIdsToAccept,
    },
    {
      skip: !tripIdsToAccept.length,
    }
  );

  const {
    data: assignmentData,
    isLoading: isLoadingAssignment,
    isFetching: isFetchingAssignment,
  } = useAssignmentsRetrieveQuery(
    {
      id: assignmentId || '',
    },
    {
      skip: !assignmentId,
    }
  );

  useEffect(() => {
    if (runBambiRunResult) {
      // For runBambiRun we only care about the requested trips
      // I then resolve to DispatchTripRead to make DataGrid happy
      // TODO: I need to split out the two use cases of run bambi run multi trip
      // accept, single trip manual accept, and single trip accept with assignment
      const filteredTrips = runBambiRunResult.new_trip_assignments
        .filter(
          (newAssignment) =>
            newAssignment.status === 'requested' &&
            tripAssignmentsToApplyIds[newAssignment.id]
        )
        .map((newAssignment) => {
          return newAssignment.id;
        });

      setTripIdsToAccept(filteredTrips);
    } else if (requestedTripId) {
      setTripIdsToAccept([requestedTripId]);
    } else if (selectedUnassignedTripIds.length) {
      const selectedUnassignedRequestedTripIds =
        dispatchResponse?.unassigned_trips
          .filter(
            (trip) =>
              selectedUnassignedTripIds.includes(trip.id) &&
              trip.status === 'requested'
          )
          .map((trip) => trip.id);
      setTripIdsToAccept(selectedUnassignedRequestedTripIds || []);
    }
  }, [
    requestedTripId,
    runBambiRunResult,
    tripAssignmentsToApplyIds,
    selectedUnassignedTripIds,
    dispatchResponse?.unassigned_trips,
  ]);

  return {
    trips: tripData?.results || [],
    // only return if we have an assignmentId
    // cached data may be returned if we don't have an assignmentId
    assignment: assignmentId ? assignmentData : undefined,
    isLoading:
      isLoadingTrips ||
      isFetchingTrips ||
      isLoadingAssignment ||
      isFetchingAssignment,
  };
};
