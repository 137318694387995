import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';

// Logic from https://bambihealth.atlassian.net/browse/BAM-612
type PresentStatusEnum = Extends<
  OverrideStatusEnum,
  | 'assigned'
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff'
  | 'completed'
  | 'canceled'
>;
export const ALLOWED_PRESENT_TRIP_STATUSES: PresentStatusEnum[] = [
  'assigned',
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
  'completed',
  'canceled',
];

type PastStatusEnum = Extends<OverrideStatusEnum, 'canceled' | 'completed'>;
export const ALLOWED_PAST_TRIP_STATUSES: PastStatusEnum[] = [
  'completed',
  'canceled',
];

type FutureStatusEnum = Extends<OverrideStatusEnum, 'canceled' | 'rejected'>;
export const ALLOWED_FUTURE_TRIP_STATUSES: FutureStatusEnum[] = ['canceled'];

export function useAllowableNextTripStatuses(tripId: string) {
  const [allowableNextTripStatuses, setAllowableNextTripStatuses] = useState<
    PresentStatusEnum[] | PastStatusEnum[] | FutureStatusEnum[]
  >([]);

  const tripRepository = useTripRepository();

  useEffect(() => {
    tripRepository.getTrip(tripId).then((trip) => {
      if (!trip) return;
      const startOfDayDateTime = DateTime.local().startOf('day');
      const endOfDayDateTime = DateTime.local().endOf('day');
      const pickupDateTime = DateTime.fromISO(trip.scheduled_pickup_at);

      if (pickupDateTime < startOfDayDateTime) {
        setAllowableNextTripStatuses(ALLOWED_PAST_TRIP_STATUSES);
        return;
      }
      if (pickupDateTime > endOfDayDateTime) {
        setAllowableNextTripStatuses(ALLOWED_FUTURE_TRIP_STATUSES);
        return;
      }
      setAllowableNextTripStatuses(ALLOWED_PRESENT_TRIP_STATUSES);
    });
  }, [tripRepository, tripId]);

  // Extends above protects us from adding non-allowed statuses
  // Not sure why ts didn't complain about this
  return allowableNextTripStatuses as OverrideStatusEnum[];
}
