import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Select, SelectOption } from 'src/common/primitives/Select';

import { pricingNameRoot } from './pricingNameRoot';

export const paymentMethodOptions: SelectOption[] = [
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Credit Card',
    value: 'credit-card',
  },
  {
    label: 'Invoice',
    value: 'invoice',
  },
];
export function PrivatePayerFields() {
  return (
    <>
      <div className="grow" data-testid="private-payer-fields">
        <FormContextField name={`${pricingNameRoot}.payment_method`}>
          {({ field, fieldState }) => {
            return (
              <DefaultFormFieldLayout
                label="Payment Method"
                error={fieldState.error?.message?.toString()}
                inputProps={{ id: `${pricingNameRoot}.payment_method` }}
              >
                <Select
                  value={field.value}
                  options={paymentMethodOptions}
                  onChange={(selected) => {
                    field.onChange(selected?.value);
                  }}
                />
              </DefaultFormFieldLayout>
            );
          }}
        </FormContextField>
      </div>
    </>
  );
}
