import { useAppDispatch } from 'src/app/store';
import { Modal } from 'src/common/primitives/Modal';
import { Wizard, WizardStep } from 'src/common/wizard/Wizard';

import { resetMergeWizard } from '../../passenger.slice';
import { ConfirmMergeStep } from './steps/ConfirmMergeStep';
import { SearchPassengersStep } from './steps/SearchPassengersStep';
import { SelectDuplicatesStep } from './steps/SelectDuplicatesStep';
import { SelectPassengerMatchFieldsStep } from './steps/SelectPassengerMatchFieldsStep';

interface PassengerMergeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const wizardSteps: WizardStep[] = [
  {
    title: 'Narrow down',
    render: (stepProps) => {
      return <SearchPassengersStep {...stepProps} />;
    },
  },
  {
    title: 'Match fields',
    render: (stepProps) => {
      return <SelectPassengerMatchFieldsStep {...stepProps} />;
    },
  },
  {
    title: 'Select duplicates',
    render: (stepProps) => {
      return <SelectDuplicatesStep {...stepProps} />;
    },
  },
  {
    title: 'Verify selection',
    render: (stepProps) => {
      return <ConfirmMergeStep {...stepProps} />;
    },
  },
];

export function PassengerMergeModal({
  open,
  setOpen,
}: PassengerMergeModalProps) {
  const dispatch = useAppDispatch();

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onClose={() => {
        dispatch(resetMergeWizard());
      }}
    >
      <Wizard testId="passenger-merge-wizard" steps={wizardSteps} />
    </Modal>
  );
}
