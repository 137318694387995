import Switch from 'src/common/primitives/Switch';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

export function FormFieldSwitch<T>({
  inputProps,
  label = '',
  helperText = '',
}: FormFieldProps<T>) {
  return (
    <DefaultFormFieldLayout label={label} inputProps={inputProps}>
      <Switch
        data-testid={inputProps.id}
        label={label}
        inputProps={inputProps}
      />
      {helperText ? (
        <aside className="text-xs text-gray-600">{helperText}</aside>
      ) : null}
    </DefaultFormFieldLayout>
  );
}
