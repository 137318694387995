import { RootState, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { useAttendantsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { AttendantProfileEmptyState } from './AttendantEmptyState';
import { AttendantProfileForm } from './AttendantProfileForm/AttendantProfileForm';
import { columns } from './columns';

export function AttendantRoute() {
  const attendantProfileSearchTerm = useAppSelector(
    (state: RootState) => state.team.attendantProfileSearchTerm
  );
  const {
    data: attendantsData,
    isLoading,
    isFetching,
  } = useAttendantsListQuery({
    search: attendantProfileSearchTerm,
  });
  return (
    <>
      <div>
        {isLoading || isFetching ? (
          <div className="my-72 flex justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <DataGrid
            tableId="attendant"
            columns={columns}
            data={attendantsData?.results || []}
            totalCount={attendantsData?.count}
            emptyState={<AttendantProfileEmptyState />}
            getRowId={(row) => row.id}
          />
        )}
      </div>
      <AttendantProfileForm />
    </>
  );
}
