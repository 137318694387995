import { FieldValues, UseFormReturn } from 'react-hook-form';

import { Recurrence } from '../../../FormValues';
import { addSelectedDay } from './addSelectedDay';
import { removeSelectedDay } from './removeSelectedDay';

export function onDayClick(day: string, form: UseFormReturn<FieldValues, any>) {
  const recurrences: Recurrence[] = form.getValues('recurrences');
  const selectedDays = recurrences.map(
    (recurrence: Recurrence) => recurrence.day_of_the_week
  );

  if (selectedDays.includes(day)) {
    const newRecurrences = removeSelectedDay(day, recurrences);
    form.setValue('recurrences', newRecurrences);
  } else {
    const newRecurrences = addSelectedDay(day, recurrences);
    form.setValue('recurrences', newRecurrences);
  }
}
