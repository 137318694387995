import { VehicleInspection } from '../types';
import { ExpandedInspectionSection } from './ExpandedInspectionSection';

export function ExpandableVehicleCell({
  vehicleName,
  inspection,
  shouldExpand,
}: {
  vehicleName: string;
  inspection: VehicleInspection;
  shouldExpand: boolean;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div>{vehicleName}</div>
      {shouldExpand && <ExpandedInspectionSection inspection={inspection} />}
    </div>
  );
}
