import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';

export function WheelchairRequiredField() {
  return (
    <FormContextField name="must_provide_wheelchair">
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Requires Wheelchair?"
            error={fieldState.error?.message?.toString()}
            type="switch"
            inputProps={{
              id: 'must_provide_wheelchair',
              ...field,
              value: '',
              checked: field.value,
            }}
          />
        );
      }}
    </FormContextField>
  );
}
