import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';

import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';

export const createClockOutEventsFromAssignments = (
  assignments: DispatchAssignmentRead[]
): IValidateProps[] => {
  const clockOutEvents: IValidateProps[] = assignments
    .map((assignment) => {
      if (!assignment.clocked_out_at) {
        return {};
      }
      return {
        id: `clock-out-${assignment.id}`,
        start: assignment.clocked_out_at,
        end: assignment.clocked_out_at,
        title: 'Clock Out',
        resource: assignment.id,
        assignmentEvent: {
          type: 'clock-out',
        },
      };
    })
    .filter((x) => !!x.start);
  return clockOutEvents;
};
