import { useMemo } from 'react';

import { Column, Header, HeaderContext, Table } from '@tanstack/react-table';

import { Popover } from 'src/common/primitives/Popover';

interface ManageColumnsProps<T> {
  open?: boolean;
  columns: Column<T, unknown>[];
  headers: Header<T, unknown>[];
  table: Table<T>;
  onClose: () => void;
}

export function ManageColumns<T>({
  open = false,
  columns,
  headers,
  table,
  onClose,
}: ManageColumnsProps<T>) {
  const headerMap = useMemo(() => {
    const map: Record<string, Header<T, unknown>> = headers.reduce(
      (acc, header) => {
        return {
          ...acc,
          [header.id]: header,
        };
      },
      {}
    );

    return map;
  }, [headers]);

  return (
    <Popover
      content={
        <div className="flex flex-col gap-1">
          {columns
            .filter((c) => c.getCanHide())
            .map((column) => (
              <div
                key={`manage-column-${column.id}`}
                className="flex flex-row items-center gap-1"
              >
                <input
                  type="checkbox"
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />
                <div>
                  {renderHeader(
                    column.columnDef.header,
                    headerMap[column.id],
                    column,
                    table
                  )}
                </div>
              </div>
            ))}
        </div>
      }
      trigger={
        <div />
      } /* Just need something to hang a reference off for positioning */
      open={open}
      onInteractOutside={onClose}
    />
  );
}

function renderHeader<T>(
  header: ((n: HeaderContext<T, unknown>) => any) | string | undefined,
  headerContext: Header<T, unknown>,
  column: Column<T, unknown>,
  table: Table<T>
) {
  if (!header) {
    return '';
  }

  if (typeof header === 'string') {
    return header;
  }

  return header({
    table,
    column,
    header: headerContext,
  });
}
