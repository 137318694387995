export function getInputPriceLabel(
  isEstimated: boolean,
  isCalculating: boolean,
  isManuallyUpdatedPrice: boolean
) {
  const base = `Input Price`;
  if (isManuallyUpdatedPrice) {
    return base;
  }
  if (isCalculating) {
    return `${base} Calculating...`;
  }
  if (isEstimated) {
    return `${base} (Estimated)`;
  }
  return base;
}
