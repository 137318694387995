import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { flexRender, Header, RowData } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { searchParamsToJSON } from 'src/common/util/searchParamsToJSON';
import useOrderParams from 'src/common/util/url';

import { classNames } from '../classNames';
import { ColumnMenu } from './menu/ColumnMenu';

interface DataGridHeaderCellProps<T> {
  header: Header<T, unknown>;
}

export function DataGridHeaderCell<T extends RowData>({
  header,
}: DataGridHeaderCellProps<T>) {
  const orderable = useOrderParams(header);
  const { isActive, isReversed } = orderable ?? {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const filterAppliedCount = header.column.columnDef.meta?.filterCount?.() ?? 0;

  const headerWidth = header.column.columnDef.meta?.headerWidth;

  return (
    <th
      scope="col"
      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
      style={{ width: headerWidth ? `${headerWidth}px` : undefined }}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex select-none flex-row items-center">
          <ColumnMenu header={header} />
          <div
            className={classNames(
              orderable ? 'cursor-pointer' : '',
              'flex flex-row items-center gap-1'
            )}
            onClick={() => {
              if (!orderable) {
                return;
              }

              setSearchParams((current) => ({
                ...searchParamsToJSON(current),
                ordering: isReversed
                  ? header.column.id
                  : `-${header.column.id}`,
              }));
            }}
          >
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
            {filterAppliedCount ? (
              <FunnelIcon className="h-4 w-4 stroke-mint" />
            ) : null}
          </div>
        </div>
        {isActive ? (
          isReversed ? (
            <ArrowLongDownIcon className="h-4 w-4 text-gray-700" />
          ) : (
            <ArrowLongUpIcon className="h-4 w-4 text-gray-700" />
          )
        ) : null}
      </div>
    </th>
  );
}
