import { useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { FormFieldCheckbox } from 'src/common/FormField/FormFieldCheckbox';
import { useFormValues } from 'src/common/useFormValues';
import { DynamicFieldNameRootContext } from 'src/features/add-trip/TripDetails/DynamicFieldNameRootContext';
import { FormValues } from 'src/features/team/AssignmentManagementForm/AssignmentManagementFormStep1/FormValues';

import { AssignmentEditor } from '../AssignmentEditor';
import { AddAssignmentButton } from './AssignmentManagementFormStep1/AddAssignmentButton';
import { FormAssignment } from './AssignmentManagementFormStep1/defaultFormValues';

export function AssignmentDayEditor({ day }: { day: DateTime }) {
  const weekdayLong = day.weekdayLong;
  const { selectedAssignment } = useSelector(
    (state: RootState) => state.assignmentManagement
  );
  const isPastDate = day < DateTime.local().startOf('day');
  const methods = useFormContext();
  const values = useFormValues<FormValues>();
  const [dayMap, setDayMap] = useState<
    | {
        assignments: FormAssignment[];
        isEditing: boolean;
      }
    | undefined
  >(values.assignments[weekdayLong]);
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);

  const watchIsEditing = methods.watch(`${fieldNameRoot}.isEditing`);
  const watchAssignments = methods.watch(`${fieldNameRoot}.assignments`);

  useEffect(() => {
    const dayMap = values.assignments[weekdayLong];
    setDayMap(dayMap);
  }, [watchIsEditing, watchAssignments, values.assignments, weekdayLong]);

  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="whitespace-nowrap">
        <FormContextField name={`${fieldNameRoot}.isEditing`}>
          {({ field, fieldState }) => {
            return (
              <FormFieldCheckbox
                type="checkbox"
                label={day.toFormat('ccc (MM/dd)')}
                value={field.value || !!selectedAssignment?.id}
                inputProps={{ id: `${fieldNameRoot}.isEditing` }}
                disabled={!!selectedAssignment?.id || isPastDate}
                onChange={(newValue) => {
                  field.onChange(newValue);
                  if (!newValue) {
                    // This handles the case where the user unchecks the box
                    // Without this the form would not be able to submit
                    // because of validations that fire on blur (ie, user clicked into start time
                    // and then unchecks the box)
                    methods.setValue(`${fieldNameRoot}.assignments`, [{}]);
                    methods.clearErrors(`${fieldNameRoot}.assignments`);
                  }
                }}
              />
            );
          }}
        </FormContextField>
      </div>
      <div className="col-span-2 flex items-center">
        <div className="flex w-full flex-col">
          {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            dayMap?.assignments?.map((_, index) => {
              return (
                <DynamicFieldNameRootContext.Provider
                  value={`assignments.${day.weekdayLong}.assignments.${index}`}
                  key={index}
                >
                  <AssignmentEditor
                    day={day}
                    index={index}
                    isAvailable={!isPastDate}
                    isEditing={dayMap.isEditing}
                  />
                </DynamicFieldNameRootContext.Provider>
              );
            })
          }
          {dayMap?.isEditing && !selectedAssignment?.id && (
            <div className="flex py-2">
              <AddAssignmentButton day={weekdayLong} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
