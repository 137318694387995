import { createColumnHelper } from '@tanstack/react-table';

import { TripSubscriptionRead } from 'src/common/external/bambi-api/bambiApi';
import { Avatar } from 'src/common/primitives/Avatar';
import { Badge } from 'src/common/primitives/Badge';
import { RowActions } from 'src/features/trip/trip-subscriptions/management/RowActions';

import { TripSubscriptionExpandedNameCell } from './TripSubscriptionExpandedNameCell';
import { TripSubscriptionExpandedSummaryCell } from './TripSubscriptionExpandedSummaryCell';
import { getWeekdays } from './utilities';

const columnHelper = createColumnHelper<TripSubscriptionRead>();

const EmptyExpandedSection = function () {
  return <div></div>;
};

export const columns = [
  columnHelper.accessor('passenger', {
    enableSorting: true,
    id: 'name',
    header: 'Passenger',
    cell: (props) => {
      const { row } = props;
      return (
        <div className="h-fit">
          <div className="flex gap-x-1">
            <div>
              <Avatar
                firstName={row.original.passenger.first_name}
                lastName={row.original.passenger.last_name}
              />
            </div>
            <p className="w-full p-3 font-medium text-gray-900">
              {row.original.passenger.full_name}
            </p>
          </div>

          {row.getCanExpand() && (
            <div>
              {row.getIsExpanded() && (
                <TripSubscriptionExpandedNameCell {...props} />
              )}
            </div>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor('dispatcher_notes', {
    id: 'dispatcher_notes',
    header: 'Summary',
    cell: (props) => {
      const { row } = props;
      const recurrenceDays = getWeekdays(row.original.recurrences).filter(
        (element, index, self) => index === self.indexOf(element)
      );

      const showExpandedVersion = row.getCanExpand() && row.getIsExpanded();
      return (
        <div>
          {showExpandedVersion ? (
            <div className="">
              <p className="mb-3 text-sm text-gray-500">
                {row.original.dispatcher_notes}
              </p>
              <TripSubscriptionExpandedSummaryCell {...props} />
            </div>
          ) : (
            <div className="flex gap-x-4 whitespace-normal">
              <span className="w-[250px] text-ellipsis">
                {row.original.dispatcher_notes}
              </span>
              {recurrenceDays.map((weekday) => (
                <div key={weekday}>
                  <Badge label={weekday} color="gray" />
                </div>
              ))}
            </div>
          )}
        </div>
      );
    },
  }),
  columnHelper.accessor('start_date', {
    enableSorting: true,
    id: 'start_date',
    header: 'Start Date',
    cell: ({ row }) => {
      return (
        <div>
          <div className="text-sm text-gray-500">{row.original.start_date}</div>
          {row.getCanExpand() && <EmptyExpandedSection />}
        </div>
      );
    },
  }),
  columnHelper.accessor('expires_at', {
    enableSorting: true,
    id: 'expires_at',
    header: 'End Date',
    cell: ({ row }) => {
      return (
        <div>
          <div className="text-sm text-gray-500">{row.original.expires_at}</div>
          {row.getCanExpand() && <EmptyExpandedSection />}
        </div>
      );
    },
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Manage',
    enableHiding: false,
    cell: (props) => (
      <div onClick={(e) => e.stopPropagation()}>
        <RowActions tripSubscription={props.row.original} />
      </div>
    ),
  }),
];
