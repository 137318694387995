import { SettingsFormField } from './components/SettingsFormField';
import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function TransportationSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Transportation"
        description="Organization defaults for transportation."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SettingsFormField
            label="Transport Start Time"
            fieldId="standard_transport_time_start"
            type="time"
          />
        </div>
        <div>
          <SettingsFormField
            label="Transport End Time"
            fieldId="standard_transport_time_end"
            type="time"
          />
        </div>
        <div className="flex items-center">
          <SettingsFormField
            label="Avoid Toll Roads"
            fieldId="should_avoid_toll_roads"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  );
}
