import { useMemo } from 'react';

import { LatLong } from 'src/common/external/aws/LatLong';
import {
  AssignmentLocationRead,
  AssignmentTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import isValidLocation from 'src/common/isValidLocation';
import { useMapRoute } from 'src/common/useMapRoute';

export function useSelectedAssignmentRoute(
  selectedAssignmentLocation?: AssignmentLocationRead,
  activeTrips?: AssignmentTripRead[]
) {
  const latestLocation = selectedAssignmentLocation?.latest_location;

  const orderedLocations = useMemo(() => {
    const locations: LatLong[] = [];

    const latestLoc = isValidLocation(latestLocation);
    if (latestLoc) locations.push(latestLoc);

    if (activeTrips?.length) {
      const currentTrip = activeTrips[0];
      const isEnRouteOrAssigned =
        currentTrip.status === 'en route' || currentTrip.status === 'assigned';

      const location = isEnRouteOrAssigned
        ? isValidLocation(currentTrip.pickup.location)
        : isValidLocation(currentTrip.dropoff.location);

      if (location) locations.push(location);
    }

    return locations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTrips?.[0]?.dropoff.location]);

  useMapRoute(
    'selected-assignment-route',
    orderedLocations,
    !!selectedAssignmentLocation?.id && orderedLocations.length === 2
  );
}
