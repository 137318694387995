import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';

import { UploadLogoField, UploadLogoFieldProps } from './UploadLogoField';

interface UploadLogoFormFieldProps extends UploadLogoFieldProps {
  label?: string;
  id?: string;
  helperText?: string;
}
export function UploadLogoFormField({
  label = 'Logo (200x100)',
  id = 'upload_logo',
  helperText = 'Used for Invoices (200x100)',
  value,
  onChange,
}: UploadLogoFormFieldProps) {
  return (
    <DefaultFormFieldLayout
      label={label}
      inputProps={{ id }}
      helperText={helperText}
    >
      <UploadLogoField value={value} onChange={onChange} />
    </DefaultFormFieldLayout>
  );
}
