import { ClockIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';
import { useFormValues } from 'src/common/useFormValues';

import { FormValues } from '../FormValues';
import { useWaitAndReturn } from './useWaitAndReturn';

export function MakeWaitAndReturnButton() {
  const waitAndReturn = useWaitAndReturn();
  const {
    is_wait_and_return_outbound,
    is_wait_and_return_inbound,
    is_round_trip,
    trips,
  } = useFormValues<FormValues>();

  return (
    <Button
      onClick={() => waitAndReturn()}
      className="flex-grow"
      disabled={
        is_wait_and_return_outbound ||
        is_wait_and_return_inbound ||
        is_round_trip ||
        trips.length > 1
      }
      dataTestId="wait-and-return-btn"
    >
      <div className="flex items-center justify-between gap-x-1">
        <ClockIcon className="h-4 w-4" />
        Wait and Return
      </div>
    </Button>
  );
}
