import { useEffect, useState } from 'react';

import { CurrencyInput } from 'src/common/CurrencyInput';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';

import { PriceEstimationBreakdownPopover } from './PriceEstimationBreakdownPopover';
import TripInputPriceLockField from './TripInputPriceLockField';
import { useTripInputPriceViewModel } from './useTripInputPriceViewModel';
import { PriceEstimationState } from './UseTripPriceEstimationReturnValue';

type TripInputPriceProps = {
  tripIndex: number;
  priceEstimationState: PriceEstimationState;
};

export function TripInputPrice({
  tripIndex,
  priceEstimationState,
}: TripInputPriceProps) {
  const [isManuallyUpdatedPrice, setIsManuallyUpdatedPrice] = useState(false);
  const { fieldName, isEstimated, fieldLabelText, inputClassName } =
    useTripInputPriceViewModel({
      priceEstimationState,
      isManuallyUpdatedPrice,
    });

  useEffect(() => {
    if (priceEstimationState.isCalculating) {
      setIsManuallyUpdatedPrice(false);
    }
  }, [priceEstimationState.isCalculating]);

  return (
    <FormContextField name={fieldName}>
      {({ field, fieldState }) => {
        const { onChange, ...remainingProps } = field;
        return (
          <DefaultFormFieldLayout
            label={fieldLabelText}
            error={
              priceEstimationState.error ||
              fieldState.error?.message?.toString()
            }
            inputProps={{ id: fieldName }}
          >
            <div
              className="flex items-center gap-2"
              data-testid={`trip-${tripIndex}-input-price-v0`}
            >
              <CurrencyInput
                id={fieldName}
                {...remainingProps}
                disabled={priceEstimationState.isCalculating}
                onValueChange={(values) => {
                  field.onChange(values.floatValue);
                }}
                className={inputClassName}
                onInput={() => {
                  setIsManuallyUpdatedPrice(true);
                }}
              />
              <TripInputPriceLockField />
              {isEstimated && (
                <PriceEstimationBreakdownPopover tripIndex={tripIndex} />
              )}
            </div>
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
