import { SelectOption } from 'src/common/primitives/Select';

export enum PAYER_SIGNATURE_OPTION_VALUES {
  ORG_DEFAULT = 'org_default',
  YES = 'yes',
  NO = 'no',
}

export const payerSignatureOptions: SelectOption[] = [
  {
    value: PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT,
    label: 'Organization Default',
  },
  { value: PAYER_SIGNATURE_OPTION_VALUES.YES, label: 'Yes' },
  { value: PAYER_SIGNATURE_OPTION_VALUES.NO, label: 'No' },
];
