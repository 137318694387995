import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import colors from './colors';
import 'src/app/app.css';

const theme = createTheme({
  palette: {
    primary: { main: colors.mint, contrastText: '#FFF' },
  },
});

// TODO: Actually provide theme context, etc
// This is technically already provided by tailwinds, but sometimes
// css-in-js is more appropriate/convenient. This should tie the two together
// Typically I prefer tailwinds for layout and css-in-js for component styling
// However, big believer in using tailwinds for most things since it provides
// the theme
export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}
