import { useState } from 'react';

import { PricingModelRead } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';
import { ToggleGroup } from 'src/common/ToggleGroup';

const PRICING_CONDITION_TABS: SelectOption[] = ['None', 'Add Condition'].map(
  (tab) => {
    return {
      label: tab,
      value: tab,
    };
  }
);

interface SelectedPricingModelConditionEditModeProps {
  pricingModelDetailsDraft: PricingModelRead | null | undefined;
}

export default function SelectedPricingModelConditionEditMode({
  pricingModelDetailsDraft,
}: SelectedPricingModelConditionEditModeProps) {
  const [toggleValue, setToggleValue] = useState(
    pricingModelDetailsDraft?.pricing_condition
      ? PRICING_CONDITION_TABS[1].value
      : PRICING_CONDITION_TABS[0].value
  );

  if (!pricingModelDetailsDraft) {
    return (
      <div>Pricing model draft is not available. This should not happen.</div>
    );
  }

  return (
    <div className="grid grid-cols-3">
      <div className="flex items-center gap-1">
        <span className="text-sm"> Pricing Condition: </span>
        <ToggleGroup
          value={toggleValue}
          options={PRICING_CONDITION_TABS}
          onChange={(val) => {
            setToggleValue(val);
          }}
          inactiveClass="data-[state=off]:bg-white data-[state=off]:text-gray-700 border-w-3"
          activeClass="data-[state=on]:bg-mint data-[state=on]:text-white border-w-3"
        />
      </div>

      <div className="col-span-2">
        {toggleValue === PRICING_CONDITION_TABS[1].value && (
          <div>Placeholder for query component</div>
        )}
      </div>
    </div>
  );
}
