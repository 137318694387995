import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { generateWeekDays } from 'src/common/date/generateWeekDays';
import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { FormFieldCheckbox } from 'src/common/FormField/FormFieldCheckbox';
import { Skeleton } from 'src/common/Skeleton';
import { useFormValues } from 'src/common/useFormValues';

import { FormValues } from './MultiAssignmentManagementFormStep1/FormValues';
import { formatDayKey } from './MultiAssignmentManagementFormStep2/formatDayKey';
import { useDefaultAssignmentFormValues } from './useDefaultAssignmentFormValues';

export function WeeklyAssignmentEditor() {
  // Ensure we rerender when an assignment checkbox is toggled
  const { driver, vehicle, attendants, assignments } =
    useFormValues<FormValues>();
  // TODO: This is probably more properly placed where it's being consumed
  const defaultsResponse = useDefaultAssignmentFormValues({
    driverId: driver?.value || '',
    vehicleId: vehicle?.value || '',
    attendantIds: attendants?.map((option) => option.value) || [],
  });
  const selectedDay = useSelector(
    (state: RootState) => state.assignmentManagement.selectedDay
  );

  const selectedDayOrToday = selectedDay || DateTime.local().toISODate();
  const days = generateWeekDays(DateTime.fromISO(selectedDayOrToday));

  if (defaultsResponse.isLoading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <div className="relative flex flex-col gap-y-6">
      {days.map((day) => {
        return (
          <div className="grid grid-cols-3 gap-2" key={formatDayKey(day)}>
            <div className="whitespace-nowrap">
              <FormContextField
                name={`assignments.${formatDayKey(day)}.isEditing`}
              >
                {({ field }) => {
                  return (
                    <FormFieldCheckbox
                      type="checkbox"
                      label={day.weekdayShort}
                      value={field.value}
                      onChange={field.onChange}
                      inputProps={{}}
                    />
                  );
                }}
              </FormContextField>
            </div>
            <div className="col-span-2 flex items-center">
              {assignments[formatDayKey(day)].isEditing ? (
                <div className="flex items-center justify-between gap-x-4 whitespace-nowrap">
                  <FormContextField
                    name={`assignments.${formatDayKey(day)}.start`}
                  >
                    {({ field }) => {
                      return (
                        <FormField
                          type="time"
                          inputProps={{
                            id: `assignment-start-time`,
                            value: field.value,
                          }}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  </FormContextField>
                  <div className="text-xl"> - </div>
                  <FormContextField
                    name={`assignments.${formatDayKey(day)}.end`}
                  >
                    {({ field }) => {
                      return (
                        <FormField
                          type="time"
                          inputProps={{
                            id: `assignment-end-time`,
                            value: field.value,
                          }}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  </FormContextField>
                </div>
              ) : (
                <span className="text-xs">No Schedule</span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
