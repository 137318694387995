import { useCallback, useEffect } from 'react';

import { kebabCase } from 'lodash-es';
import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';

import {
  FilterGroup,
  useFilterGroupsCreateMutation,
} from '../external/bambi-api/bambiApi';
import { FormField } from '../FormField';
import { Button } from '../primitives/Button';
import { Modal, ModalProps } from '../primitives/Modal';
import { show } from '../primitives/Toast/toast.slice';
import formatServerError from '../util/serverErrorFormatter';

interface FilterGroupManageModalProps extends Omit<ModalProps, 'children'> {
  filters: FilterGroup['filters'];
  filterset: FilterGroup['filterset'];
}

export function FilterGroupManageModal({
  filters,
  filterset,
  ...props
}: FilterGroupManageModalProps) {
  const { setOpen } = props;
  const [createFilterGroups] = useFilterGroupsCreateMutation();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, reset } = useForm<FilterGroup>({
    defaultValues: {
      group_name: '',
      description: '',
      slug: '',
      filters,
      filterset,
    },
  });

  useEffect(() => {
    reset({
      group_name: '',
      description: '',
      slug: '',
      filters,
      filterset,
    });
  }, [filters, filterset, reset]);

  const handleSaveFilterGroup = useCallback(
    async ({ group_name, description, filters, filterset }: FilterGroup) => {
      try {
        await createFilterGroups({
          filterGroup: {
            group_name,
            description,
            filters,
            filterset,
            slug: makeFilterGroupSlug(group_name),
          },
        }).unwrap();

        setOpen(false);

        dispatch(
          show({
            type: 'success',
            title: 'Created Filter Group',
          })
        );
      } catch (e) {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to create Filter Group',
            description: formatServerError(e),
          })
        );
      }
    },
    [createFilterGroups, dispatch, setOpen]
  );

  return (
    <Modal {...props}>
      <div className="flex flex-col gap-4">
        <div className="text-md">
          Create a filter group with the selected filters.
        </div>
        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="group_name"
            render={({ field }) => {
              return (
                <FormField
                  type="text"
                  inputProps={{
                    ...field,
                    required: true,
                  }}
                  label="Group Name *"
                />
              );
            }}
          />
          <Controller
            control={control}
            name="description"
            render={({ field }) => {
              return (
                <FormField
                  type="text"
                  inputProps={{
                    ...field,
                  }}
                  label="Description"
                />
              );
            }}
          />
        </div>
        <div className="flex flex-row justify-between">
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit(handleSaveFilterGroup)}
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function makeFilterGroupSlug(name: string) {
  return `${kebabCase(name)}-${Date.now()}`;
}
