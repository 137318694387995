import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/primitives/Button';

import {
  setSelectedAttendantProfile,
  setShowAttendantProfileModal,
} from '../team.slice';

export function AttendantProfileEmptyState() {
  const dispatch = useDispatch();
  const handleAddAttendant = () => {
    dispatch(setSelectedAttendantProfile(null));
    dispatch(setShowAttendantProfileModal(true));
  };
  return (
    <div className="flex justify-center px-[24px] py-12">
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <UserPlusIcon className="mx-auto h-6 w-auto text-gray-700" />
        </div>
        <h4 className="mt-3 text-gray-700">
          No Attendant Profiles have been added
        </h4>
        <p className="text-gray-400">
          Add an attendant profile to an existing member
        </p>
        <div className="mt-3 text-gray-700">
          <Button onClick={() => handleAddAttendant()} variant="primary">
            Add Attendant Profile
          </Button>
        </div>
      </div>
    </div>
  );
}
