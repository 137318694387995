import { SelectOption } from 'src/common/primitives/Select';

export const serviceTypeOptions: SelectOption[] = [
  {
    label: 'Curb to Curb',
    value: 'curb-to-curb',
  },
  {
    label: 'Door to Door',
    value: 'door-to-door',
  },
  {
    label: 'Door Through Door',
    value: 'door-through-door',
  },
];
