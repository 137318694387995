import { Membership } from 'src/common/external/bambi-api/bambiApi';
import { Avatar } from 'src/common/primitives/Avatar';

export function PersonProfileCell({ member }: { member: Membership }) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-auto flex-shrink-0">
        <Avatar firstName={member.first_name} lastName={member.last_name} />
      </div>
      <div className="ml-4">
        <div className="font-medium text-gray-900">
          {member.first_name} {member.last_name}
        </div>
      </div>
    </div>
  );
}
