import { useEffect, useState } from 'react';

import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';

export function usePaginationInfo({
  currentPage,
  totalCount,
}: {
  currentPage: number;
  totalCount?: number;
}): string {
  const [paginationInfo, setPaginationInfo] = useState('');
  useEffect(() => {
    const start = 1 + DEFAULT_PAGINATION_PAGE_SIZE * (currentPage - 1);
    const end = Math.min(
      DEFAULT_PAGINATION_PAGE_SIZE * currentPage,
      totalCount || 0
    );
    setPaginationInfo(`${start} - ${end} of ${totalCount}`);
  }, [currentPage, totalCount]);

  return paginationInfo;
}
