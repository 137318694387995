import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { createColumnHelper } from '@tanstack/react-table';

import { Button } from 'src/common/primitives/Button';

export function getExpandableColumnDef<T>() {
  const columnHelper = createColumnHelper<T>();
  return columnHelper.display({
    id: 'expand',
    enableHiding: false,
    meta: {
      enableHeaderMenu: false,
    },
    header: ({ table }) => (
      <Button onClick={table.getToggleAllRowsExpandedHandler()} variant="ghost">
        {table.getIsAllRowsExpanded() ? (
          <CaretUpIcon className="h-6 w-6" />
        ) : (
          <CaretDownIcon className="h-6 w-6" />
        )}
      </Button>
    ),
    cell: ({ row }) => {
      return (
        <Button variant="ghost">
          {row.getIsExpanded() ? (
            <CaretUpIcon className="h-6 w-6" />
          ) : (
            <CaretDownIcon className="h-6 w-6" />
          )}
        </Button>
      );
    },
  });
}
