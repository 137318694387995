import { Select, SelectOption } from 'src/common/primitives/Select';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';
import { HelperTextPopover } from './FormFieldDefault';

export function FormFieldSelect<T>({
  inputProps,
  label,
  error,
  value,
  options,
  placeholder,
  disabled,
  onChange,
  inline,
  helperText,
  helperTextPosition,
}: FormFieldProps<T>) {
  if (!options) {
    throw new Error('options is required for select type');
  }

  return (
    <DefaultFormFieldLayout
      inline={inline}
      label={label}
      error={error}
      inputProps={inputProps}
    >
      <div className="flex items-center gap-2" data-testid={inputProps.id}>
        <Select
          selected={value as SelectOption}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(newOption?: SelectOption) =>
            typeof onChange === 'function' && onChange(newOption)
          }
          error={error}
        />
        {helperText && (
          <HelperTextPopover
            text={helperText}
            position={helperTextPosition}
            isStatic
          />
        )}
      </div>
    </DefaultFormFieldLayout>
  );
}
