// this allows you to safely merge two objects of different types
// but only if the key exists and types match in the first object
export const safelyMerge = <T extends object>(
  obj1: T,
  obj2: Record<string, any>
) => {
  Object.keys(obj2).forEach(function (key) {
    if (key in obj1 && typeof obj1[key as keyof T] === typeof obj2[key]) {
      obj1[key as keyof T] = obj2[key];
    }
  });

  return obj1;
};
