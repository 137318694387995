import { useAppDispatch, useAppSelector } from 'src/app/store';
import { FormField } from 'src/common/FormField';

import { onPricingModelDetailsEdit } from '../../pricingModel.slice';
import { useSelectedPricingModelId } from '../hooks/useSelectedPricingModelId';
import { emptyDefaultPricingModelDetailsDraft } from './emptyDefaultPricingModelDetailsDraft';
import { PricingModelDetailsActions } from './PricingModelDetailsActions';
import SelectedPricingModelConditionEditMode from './SelectedPricingModelConditionEdit';

export function SelectedPricingModelDetailsEditMode() {
  const dispatch = useAppDispatch();
  const pricingModelDetailsDraft = useAppSelector((state) => {
    return state.pricingModel.pricingModelDetailsDraft;
  });
  const selectedPricingModelId = useSelectedPricingModelId();
  return (
    <>
      <div className="flex items-center justify-between">
        <FormField
          label="Name"
          type="text"
          inputProps={{
            id: `pricing-model-details-edit-name-${selectedPricingModelId}`,
            value: pricingModelDetailsDraft?.name,
            onChange: (e) => {
              dispatch(
                onPricingModelDetailsEdit({
                  ...(pricingModelDetailsDraft ||
                    emptyDefaultPricingModelDetailsDraft),
                  name: e.target.value,
                })
              );
            },
          }}
        />
        <div className="flex flex-grow items-center justify-end gap-2">
          <PricingModelDetailsActions />
        </div>
      </div>

      <FormField
        label="Details"
        type="text"
        inputProps={{
          id: `pricing-model-details-edit-details-${selectedPricingModelId}`,
          value: pricingModelDetailsDraft?.details,
          onChange: (e) => {
            dispatch(
              onPricingModelDetailsEdit({
                ...(pricingModelDetailsDraft ||
                  emptyDefaultPricingModelDetailsDraft),
                details: e.target.value,
              })
            );
          },
        }}
      />

      <SelectedPricingModelConditionEditMode
        pricingModelDetailsDraft={pricingModelDetailsDraft}
      />

      <FormField
        label="Is Default"
        type="checkbox"
        value={pricingModelDetailsDraft?.is_default}
        onChange={(checked) => {
          dispatch(
            onPricingModelDetailsEdit({
              ...(pricingModelDetailsDraft ||
                emptyDefaultPricingModelDetailsDraft),
              is_default: checked,
            })
          );
        }}
        inputProps={{
          id: `pricing-model-details-edit-is-default-${selectedPricingModelId}`,
        }}
      />
    </>
  );
}
