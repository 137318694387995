export function SelectedPricingModelDetailsText({
  details,
}: {
  details: string;
}) {
  return (
    <h3 data-testid="selected-pricing-model-details">
      <span className="text-lg font-medium">Details:</span> {details}
    </h3>
  );
}
