import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  MembershipWrite,
  PatchedMembershipRead,
  useMembersCreateMutation,
  useMembersDestroyMutation,
  useMembersPartialUpdateMutation,
  useMembersUndeleteCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { setSelectedMember, setShowMemberForm } from '../member.slice';
import { AddEditModal } from './AddEditModal';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { FormValues } from './FormValues';
import { generateMemberAddSuccessToast } from './generateMemberAddSuccessToast';
import { generateMemberDeleteSuccessToast } from './generateMemberDeleteSuccessToast';
import { generateMemberEditSuccessToast } from './generateMemberEditSuccessToast';

export function MemberForm() {
  const member = useAppSelector((state) => state.member.selectedMember);

  const defaultValues = assignDefaultFormValues(member);
  const methods = useForm<FormValues>({
    defaultValues,
  });

  const open = useAppSelector((state) => state.member.showMemberForm);
  const nextToastId = useAppSelector((state) => state.toast.nextId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<string | null>('');

  const [createMember] = useMembersCreateMutation();
  const [deleteMember] = useMembersDestroyMutation();
  const [updateMember] = useMembersPartialUpdateMutation();
  const [restoreMember] = useMembersUndeleteCreateMutation();

  const onSubmit = async (data: FormValues) => {
    setLoading(true);

    try {
      if (member) {
        // TODO: Format payers select options to whatever the api needs
        const patchedMembership: PatchedMembershipRead = {
          ...data,
          role: data.role?.value,
        };

        await updateMember({
          id: member.id,
          patchedMembership,
        }).unwrap();

        dispatch(
          show(generateMemberEditSuccessToast(nextToastId, data.first_name))
        );
      } else {
        // TODO: Format payers select options to whatever the api needs
        const membership: MembershipWrite = {
          ...data,
          role: data.role?.value,
          // Further, active_payers is required on the type, which is true for the GET endpoint but not the POST endpoint
          // So, we just cast here
        } as MembershipWrite;

        await createMember({
          membership,
        }).unwrap();

        dispatch(
          show(generateMemberAddSuccessToast(nextToastId, data.first_name))
        );
      }

      dispatch(setSelectedMember(null));
      setServerErrors(null);
      dispatch(setShowMemberForm(false));
      methods.reset();
    } catch (error) {
      const formattedErr = formatServerError(error);
      setServerErrors(formattedErr);
    }
    setLoading(false);
  };

  const handleDeleteMember = async () => {
    if (!member) {
      return;
    }

    try {
      await deleteMember({
        id: member.id,
      }).unwrap();
      dispatch(show(generateMemberDeleteSuccessToast(nextToastId)));
      dispatch(setSelectedMember(null));
      setServerErrors(null);
      dispatch(setShowMemberForm(false));
    } catch (error) {
      const formattedErr = formatServerError(error);
      setServerErrors(formattedErr);
    }
  };

  const handleRestoreMember = useCallback(async () => {
    if (!member) {
      return;
    }

    try {
      await restoreMember({
        id: member.id,
        membership: member,
      });
      dispatch(
        show({
          type: 'success',
          title: 'Restored membership',
          description: `Restored membership for ${member.first_name}`,
        })
      );
      dispatch(setSelectedMember(null));
      setServerErrors(null);
      dispatch(setShowMemberForm(false));
    } catch (error) {
      const formattedErr = formatServerError(error);
      setServerErrors(formattedErr);
    }
  }, [dispatch, member, restoreMember, setServerErrors]);

  useEffect(() => {
    if (open) {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <FormProvider {...methods}>
      <AddEditModal
        open={open}
        setOpen={(val) => dispatch(setShowMemberForm(val))}
        onCancel={() => {
          setServerErrors(null);
          dispatch(setShowMemberForm(false));
          methods.reset();
        }}
        onDelete={handleDeleteMember}
        onRestore={handleRestoreMember}
        onClose={() => {
          setServerErrors(null);
          dispatch(setSelectedMember(null));
          methods.reset();
        }}
        onSubmit={methods.handleSubmit(onSubmit)}
        errors={methods.formState.errors}
        isReadyToSubmit={Object.keys(methods.formState.errors).length < 1}
        loading={loading}
        control={methods.control}
        serverErrors={serverErrors}
      />
    </FormProvider>
  );
}
