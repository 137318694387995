import { useFormContext } from 'react-hook-form';

import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';

import { handleAppointmentTimeValidation } from './handleAppointmentTimeValidation';
export function AppointmentTimeContextField({
  fieldPath,
  index,
}: {
  fieldPath: string;
  index: number;
}) {
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  const form = useFormContext();
  return (
    <FormContextField
      name={`${fieldPath}.appointment_time`}
      rules={{
        validate: (value: string) => {
          // this is only to trigger a re-validation of pickup_time
          const { trips } = form.getValues();
          return handleAppointmentTimeValidation(
            value,
            () => {
              form.clearErrors(`${fieldPath}.pickup_time`);
            },
            trips[index].timing.pickup_time
          );
        },
      }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Appointment Time"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: `${fieldPath}.appointment_time`,
              ...field,
              onChange: field.onChange,
            }}
            {...field}
            type="time"
            disabled={isSelectedTripInThePast}
          />
        );
      }}
    </FormContextField>
  );
}
