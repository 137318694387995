import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';

import { useTripMenuActions } from '../DispatchCalendar/useTripMenuActions';

interface Props {
  trip: {
    id: string;
    status: OverrideStatusEnum;
  };
}

export function CancellationMenu({ trip }: Props) {
  const menuActions = useTripMenuActions();

  return (
    <>
      {trip.status === 'canceled' ? (
        <DropdownMenuItem onClick={() => menuActions.uncancel(trip.id)}>
          Uncancel
        </DropdownMenuItem>
      ) : (
        <DropdownMenuItem onClick={() => menuActions.cancel(trip.id)}>
          Cancel
        </DropdownMenuItem>
      )}
    </>
  );
}
