export function oxygenLitersPerMinFieldValidate(value: string) {
  if (!value) {
    return 'Oxygen Amount is required';
  }
  const parsedValue = parseFloat(value);

  // Shouldn't happen but those are famous last words
  if (isNaN(parsedValue)) {
    return 'Oxygen Amount must be a number';
  }

  // Negative numbers aren't allowed by the input, but this covers 0 as well
  if (!(parsedValue > 0)) {
    return 'Oxygen Amount must be greater than 0';
  }

  // Back end validation is no more than 3 digits before the decimal
  if (!(parsedValue < 1000)) {
    return 'Oxygen Amount must be less than 1000';
  }

  return true;
}
