import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { DeleteModal, DeleteModalProps } from 'src/common/DeleteModal';
import { useAssignmentsBreaksDestroyMutation } from 'src/common/external/bambi-api/bambiApi';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { useDispatchRepository } from '../common/useDispatchRepository';
import {
  dismissDispatchModal,
  useDispatchModalToShow,
} from '../dispatcher.slice';

export const DeleteBreakModal = () => {
  const props = useDeleteBreakModalProps();
  return <DeleteModal {...props} />;
};

// Extracted hook for testability since modals don't render in React root
export const useDeleteBreakModalProps = (): DeleteModalProps => {
  const modalToShow = useDispatchModalToShow();
  const { error, onDeleteAssignmentBreak, dismissModal } =
    useDeleteBreakModal();

  return {
    open: modalToShow === 'deleteBreak',
    setOpen: (isOpen) => {
      if (!isOpen) {
        dismissModal();
      }
    },
    onCancel: () => dismissModal(),
    onConfirm: () => onDeleteAssignmentBreak(),
    title: 'Delete this break?',
    description:
      'Are you sure you want to delete this break? This action cannot be undone.',
    error,
  };
};

const useDeleteBreakModal = () => {
  const [error, setError] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const assignmentBreak = useAppSelector(
    (state) => state.dispatcher.modals.assignmentBreak
  );
  const { refetch } = useDispatchRepository();
  const [deleteBreak] = useAssignmentsBreaksDestroyMutation();

  const dismissModal = () => {
    setError(undefined);
    dispatch(dismissDispatchModal());
  };

  const onDeleteAssignmentBreak = async () => {
    if (!assignmentBreak?.id) {
      throw new Error('Missing assignment break ID');
    }

    try {
      await deleteBreak({
        id: assignmentBreak.assignment_id,
        breakId: assignmentBreak.id,
      }).unwrap();

      refetch();

      dismissModal();
    } catch (error) {
      const formattedError = formatServerError(error);
      setError(formattedError);
    }
  };

  return {
    error,
    onDeleteAssignmentBreak,
    dismissModal,
  };
};
