import { DateTime } from 'luxon';

import { Button } from 'src/common/primitives/Button';

export function DaySelector({
  active,
  date,
  onClick,
}: {
  active?: boolean;
  date: DateTime;
  onClick: (date: DateTime) => void;
}) {
  const dayNumber = date.day;
  const dayName = date.toFormat('ccc');
  return (
    <Button
      className="flex flex-col justify-center gap-2"
      onClick={() => onClick(date)}
      variant="ghost"
    >
      <div
        className={`flex h-10 w-10 items-center justify-center rounded-full ${
          active ? 'bg-mint' : ''
        } text-gray-700`}
        style={{
          boxShadow: `0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)`,
        }}
      >
        {dayNumber}
      </div>
      <div className="text-center text-gray-500">{dayName}</div>
    </Button>
  );
}
