import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import {
  SpaceTypeEnum,
  TripUpdateRead,
} from 'src/common/external/bambi-api/bambiApi';
import { TripSpaceTypeBadge } from 'src/features/trip/management/TripSpaceTypeBadge';

export function TripSpaceTypeChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  // Gonna play fast and lose with types here... chances of not having anything here are slim
  const oldSpaceType = tripEvent.values_changed?.[valueKey]
    .old_value as SpaceTypeEnum;
  const newSpaceType = tripEvent.values_changed?.[valueKey]
    .new_value as SpaceTypeEnum;
  return (
    <div className="flex items-center gap-1">
      <TripSpaceTypeBadge spaceType={oldSpaceType} />
      <ArrowLongRightIcon className="h-5 w-5" />
      <TripSpaceTypeBadge spaceType={newSpaceType} />
    </div>
  );
}
