import * as Accordion from 'src/common/Accordion';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { PricingRuleAccordionContent } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingRuleDetail/PricingRuleAccordionContent/PricingRuleAccordionContent';
import { PricingRuleAccordionHeader } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingRuleDetail/PricingRuleAccordionHeader/PricingRuleAccordionHeader';

export function PricingRuleDetail({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  return (
    // TODO: This is probably a type inconsistency and id is probably never undefined at this point
    // Even when we implement adding a pricing rule we'll probably have something like a temp id or
    // we'll create the rule that's empty, in which case it'll have an actual id anyway
    // However, for now just stringify to cover most cases
    <Accordion.Item value={pricingRule.id || JSON.stringify(pricingRule)}>
      <div
        className="relative rounded-lg border border-solid border-[#eaecf0]"
        style={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}
      >
        <Accordion.Trigger>
          <style>
            {
              // TODO: This isn't great, but I don't want to spend time on fixing up the Accordion component just yet
              // This is because for expand/collapse all the radix accordion component may not be enough as it doesn't support that out of the box and might be better
              // to just use a different accordion component
              // Probably just need to let Trigger take a className prop or add asChild functionality
              `
            [data-radix-collection-item][data-state="open"] .pricing-rule-container {
              background-color: F9FAFB;
              border-bottom: 2px solid #EAECF0;
            }
          `
            }
          </style>
          <PricingRuleAccordionHeader pricingRule={pricingRule} />
        </Accordion.Trigger>
        <Accordion.Content>
          <PricingRuleAccordionContent pricingRule={pricingRule} />
        </Accordion.Content>
      </div>
    </Accordion.Item>
  );
}
