import {
  AssignmentLocationRead,
  useAssignmentsTripsActiveListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { AssignmentPopupActiveTrips } from './AssignmentPopupActiveTrips';
import { AssignmentPopupAttendants } from './AssignmentPopupAttendants';
import { AssignmentPopupDriverAndVehicle } from './AssignmentPopupDriverAndVehicle';
import AssignmentPopupLastDeviceTimestamp from './AssignmentPopupLastDeviceTimestamp';

export function AssignmentPopupContent({
  assignmentLocation,
}: {
  assignmentLocation: AssignmentLocationRead;
}) {
  const { data, isLoading } = useAssignmentsTripsActiveListQuery({
    id: assignmentLocation.id,
  });

  if (isLoading) return <LoadingIndicator />;
  const activeTrips = data?.results;
  return (
    <div
      className="flex flex-col items-start justify-start gap-3 text-center text-xs text-gray-700"
      data-testid={`assignment-popup-${assignmentLocation.id}`}
    >
      <AssignmentPopupDriverAndVehicle
        assignmentLocation={assignmentLocation}
      />
      {assignmentLocation.attendants.length > 0 && (
        <AssignmentPopupAttendants assignmentLocation={assignmentLocation} />
      )}
      {activeTrips && activeTrips.length > 0 && (
        <AssignmentPopupActiveTrips activeTrips={activeTrips} />
      )}
      <AssignmentPopupLastDeviceTimestamp
        lastDeviceTimestamp={assignmentLocation.latest_location.timestamp}
      />
    </div>
  );
}
