import { ReactNode } from 'react';

export function EmptyState({
  Icon,
  entityName,
  description,
  Action,
  Title,
}: {
  Icon: ReactNode;
  entityName: string;
  description: string;
  Action?: ReactNode;
  Title?: ReactNode;
}) {
  return (
    <div className="flex justify-center px-[24px] py-[16px]">
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          {Icon}
        </div>
        {Title ? (
          Title
        ) : (
          <h4 className="mt-3 text-gray-700">Add {entityName}</h4>
        )}
        <p className="text-gray-400">{description}</p>
        {!!Action && <div className="mt-3 text-gray-700">{Action}</div>}
      </div>
    </div>
  );
}
