import { Driver } from 'src/common/external/bambi-api/bambiApi';

import { DriverSkillMap } from './DriverSkillMap';
import { resolveDriverSkills } from './resolveDriverSkills';

export const DriverServiceSkillKeyMap: DriverSkillMap = {
  can_do_curb_to_curb: 'curb-to-curb',
  can_do_door_to_door: 'door-to-door',
  can_do_door_through_door: 'door-through-door',
};

export function resolveServiceSkills(driver: Driver): string[] {
  return resolveDriverSkills(driver, DriverServiceSkillKeyMap);
}
