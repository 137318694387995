import { TilledLogo } from 'src/common/primitives/logos/TilledLogo';

export function PendingTilledMerchantApplication() {
  return (
    <div
      data-testid="pending-tilled-merchant-application"
      className="flex flex-grow flex-row items-center gap-2"
    >
      <TilledLogo />
      <div>
        Your application is pending, please wait for Tilled to review your
        application.
      </div>
    </div>
  );
}
