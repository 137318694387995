import { useCallback } from 'react';

import { useAppDispatch } from 'src/app/store';
import {
  useFilterGroupsCreateExamplesCreateMutation,
  useFilterGroupsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { FilterGroupsDataGrid } from './manage/FilterGroupsDataGrid';

const EXAMPLE_FILTER_GROUPS = [
  'ready-for-invoice',
  'needs-pricing-confirmed',
  'large-outstanding-invoices',
];

export function FilterGroupsScreen() {
  const [trigger, { isLoading }] =
    useFilterGroupsCreateExamplesCreateMutation();
  const dispatch = useAppDispatch();
  const { isLoading: isLoadingFilterGroups, data } = useFilterGroupsListQuery(
    {}
  );

  const hasFilterGroups = !!(
    !isLoadingFilterGroups &&
    data?.results.find((fg) =>
      EXAMPLE_FILTER_GROUPS.find((slug) => fg.slug === slug)
    )
  );

  const handleCreateExamples = useCallback(async () => {
    try {
      await Promise.all([
        trigger({
          filterGroup: {
            filters: {},
            filterset: 'TripFilter',
            group_name: '',
            slug: '',
          },
        }).unwrap(),
        trigger({
          filterGroup: {
            filters: {},
            filterset: 'InvoiceFilter',
            group_name: '',
            slug: '',
          },
        }).unwrap(),
      ]);

      dispatch(
        show({
          type: 'success',
          title: 'Successfully created example Filter Groups',
        })
      );
    } catch (e) {
      dispatch(
        show({
          type: 'error',
          title: 'Unable to create example Filter Groups',
          description: `These examples can only be created once. ${formatServerError(
            e
          )}`,
        })
      );
    }
  }, [dispatch, trigger]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-end">
        <Button
          loading={isLoading}
          disabled={hasFilterGroups}
          onClick={handleCreateExamples}
        >
          Create example filter groups
        </Button>
      </div>
      <FilterGroupsDataGrid />
    </div>
  );
}
