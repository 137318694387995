import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

export function TripServiceTypeChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const oldSpaceType = tripEvent.values_changed?.[valueKey].old_value;
  const newSpaceType = tripEvent.values_changed?.[valueKey].new_value;
  return (
    <div className="flex items-center gap-1">
      <Badge label={String(oldSpaceType)} color="blue" />
      <ArrowLongRightIcon className="h-5 w-5" />
      <Badge label={String(newSpaceType)} color="blue" />
    </div>
  );
}
