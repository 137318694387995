import { RoleEnum } from 'src/common/external/bambi-api/bambiApi';
import { Badge, BADGE_COLOR_VARIANTS } from 'src/common/primitives/Badge';

import { formatUserType } from './formatUserType';

export const userTypeColorMap: { [key in RoleEnum]: string } = {
  admin: BADGE_COLOR_VARIANTS.BLUE_LIGHT,
  associate: BADGE_COLOR_VARIANTS.PRIMARY,
  owner: BADGE_COLOR_VARIANTS.ORANGE,
  dispatcher: BADGE_COLOR_VARIANTS.BLUE,
  bambi: BADGE_COLOR_VARIANTS.MINT_INVERSE,
  integration: BADGE_COLOR_VARIANTS.GREEN,
  'restricted support': BADGE_COLOR_VARIANTS.YELLOW,
  'facility admin': BADGE_COLOR_VARIANTS.RED,
  'facility user': BADGE_COLOR_VARIANTS.PINK,
};

export function UserTypeBadge({ userType }: { userType: RoleEnum }) {
  const color = userTypeColorMap[userType];
  const formattedUserType = formatUserType(userType);
  return <Badge label={formattedUserType} color={color} />;
}
