import {
  NumberRangeFilterPopover,
  NumberRangeFilterPopoverProps,
  NumberRangeFilterPopoverTrigger,
} from 'src/common/primitives/NumberRangeFilterPopover/NumberRangeFilterPopover';

interface InvoiceTotalFilterProps
  extends Omit<
    NumberRangeFilterPopoverProps,
    'triggerElement' | 'entityLabel'
  > {
  triggerElement?: NumberRangeFilterPopoverProps['triggerElement'];
}

export function InvoiceTotalFilter(props: InvoiceTotalFilterProps) {
  const hasValue = !!(
    props.value &&
    ((props.value.min && !isNaN(props.value.min)) ||
      (props.value.max && !isNaN(props.value.max)))
  );

  return (
    <NumberRangeFilterPopover
      type="currency"
      entityLabel="Total"
      triggerElement={
        <NumberRangeFilterPopoverTrigger
          hasValue={hasValue}
          label="Invoice Total"
        />
      }
      {...props}
    />
  );
}
