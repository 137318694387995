import { ReactNode, useState } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import {
  DateRange,
  DayPicker,
  SelectRangeEventHandler,
} from 'react-day-picker';

import { Popover } from 'src/common/primitives/Popover';

interface DateRangePickerProps {
  triggerElement: ReactNode;
  open?: boolean;
  onDateRangeChange: (dateRange: DateRange) => void;
  onClose?: () => void;
}
export default function DateRangePicker({
  triggerElement,
  open,
  onDateRangeChange,
  onClose,
}: DateRangePickerProps) {
  const [dateRange, setDateRange] = useState<DateRange>();

  const handleSelectRange: SelectRangeEventHandler = (range) => {
    setDateRange(range);
  };

  const handleApply = () => {
    if (dateRange) onDateRangeChange(dateRange);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setDateRange({ to: undefined, from: undefined });
      onClose?.();
    }
  };

  return (
    <>
      <style>{`
      .rdp-day_selected {
        background-color: #0CACA6;
      }
    `}</style>
      <Popover
        open={open}
        onOpenChange={onOpenChange}
        trigger={triggerElement}
        content={
          <>
            <DayPicker
              mode="range"
              selected={dateRange}
              onSelect={handleSelectRange}
              captionLayout="dropdown"
              fromYear={2015}
              toYear={2025}
            />
            <div className="grid grid-cols-2 border-t border-gray-200 py-2">
              <RadixPopover.Close className="flex">
                <div className="focus:ring-indigo-500 mr-2 inline-flex flex-grow items-center justify-center rounded border border-gray-100 bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                  Cancel
                </div>
              </RadixPopover.Close>
              <RadixPopover.Close onClick={handleApply} className="flex">
                <div className="focus:ring-indigo-500 inline-flex flex-grow items-center justify-center rounded border border-mint bg-mint px-3 py-2 text-sm font-medium text-white hover:bg-mint focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                  Apply
                </div>
              </RadixPopover.Close>
            </div>
          </>
        }
      ></Popover>
    </>
  );
}
