import { Recurrence } from '../../../FormValues';
import { sortRecurrences } from './sortRecurrences';

export function removeSelectedDay(day: string, recurrences: Recurrence[]) {
  const newRecurrences = recurrences.filter(
    (recurrence: Recurrence) => recurrence.day_of_the_week !== day
  );

  return sortRecurrences(newRecurrences);
}
