import { SelectOption } from 'src/common/primitives/Select';

import { PAYER_SIGNATURE_OPTION_VALUES } from './payerSignatureOptions';

export const payerSignatureOptionToPayerSignature = (option: SelectOption) => {
  if (option.value === PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT) {
    return null;
  }

  return option.value === PAYER_SIGNATURE_OPTION_VALUES.YES;
};
