import { DateTime } from 'luxon';

import {
  AssignmentRead,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';

import { convertAssignedAttendantToAttendant } from './convertAssignedAttendantToAttendant';
import { convertAssignedDriverToDriver } from './convertAssignedDriverToDriver';
import { generateDefaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export function assignDefaultFormValues(
  selectedDate: string,
  vehicle?: VehicleRead,
  assignment?: AssignmentRead
): FormValues {
  if (assignment && vehicle) {
    const dayLong = DateTime.fromISO(assignment.start).weekdayLong;
    const formAssignment = {
      id: assignment.id,
      start: DateTime.fromISO(assignment.start).toFormat('HH:mm'),
      end: DateTime.fromISO(assignment.end).toFormat('HH:mm'),
      driver: convertAssignedDriverToDriver(assignment.driver),
      attendants: assignment.attendants.map((x) =>
        convertAssignedAttendantToAttendant(x)
      ),
      vehicle,
    };

    return {
      vehicle,
      assignments: {
        [dayLong]: {
          dateTime: DateTime.fromISO(assignment.start),
          isEditing: true,
          assignments: [formAssignment],
        },
      },
    };
  }

  return generateDefaultFormValues(selectedDate, vehicle);
}
