import { DateTime } from 'luxon';

import { Badge } from 'src/common/primitives/Badge';

interface DateRangeBadgeProps {
  from: string;
  to: string;
  onClear?: () => void;
}

const DateRangeBadge = ({ from, to, onClear }: DateRangeBadgeProps) => {
  const formatDateRange = () => {
    return `${DateTime.fromISO(from).toFormat(
      'MM/dd/yyyy'
    )} - ${DateTime.fromISO(to).toFormat('MM/dd/yyyy')}`;
  };

  return (
    <Badge
      testId="dateRangeBadge"
      label={formatDateRange()}
      color="green"
      closeable
      onClose={onClear}
    />
  );
};

export default DateRangeBadge;
