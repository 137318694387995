import { NavLink } from 'react-router-dom';

interface NotFoundChildRouteProps {
  routePath?: string;
  linkText?: string;
}

export function NotFoundChildRoute({
  routePath = '/',
  linkText = 'Go back home',
}: NotFoundChildRouteProps) {
  return (
    <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-6 lg:px-8">
      <div className="my-auto flex-shrink-0 py-16 sm:py-32">
        <p className="text-base font-bold text-mint">404</p>
        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-2 text-base text-gray-500">
          Sorry, we couldn't find the page you're looking for.
        </p>
        <div className="mt-6">
          <NavLink
            to={routePath}
            className="font-bold text-mint hover:opacity-[.85]"
          >
            {linkText}
            <span aria-hidden="true"> &rarr;</span>
          </NavLink>
        </div>
      </div>
    </main>
  );
}
