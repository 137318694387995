import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useTripsBulkAssignCreateMutation } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';
import {
  dismissDispatchModal,
  execTripMenuAction,
  onApplyRunBambiRun,
  onCancelAssignTrip,
  setSelectedUnassignedTripIds,
} from 'src/features/dispatch/dispatcher.slice';

export const useAcceptTripRequestConfirmModal = () => {
  const [bulkAssign] = useTripsBulkAssignCreateMutation();
  const [error, setError] = useState<string | undefined>(undefined);
  const { assignmentId, requestedTripId } = useAppSelector(
    (state) => state.dispatcher.modals
  );

  // TODO: When we start allowing users to deselect trips to accept
  // we'll need to use something other than selectedUnassignedTripIds
  const { selectedDate, selectedUnassignedTripIds } = useAppSelector(
    (state) => state.dispatcher
  );
  const { rbrJobId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const tripsRepository = useTripRepository();

  const dismissModal = () => {
    setError(undefined);
    dispatch(dismissDispatchModal());
    dispatch(onCancelAssignTrip());
  };

  const onAcceptConfirm = () => {
    dispatch(
      execTripMenuAction({
        action: async () => {
          if (typeof rbrJobId === 'string') {
            try {
              await dispatch(
                onApplyRunBambiRun({
                  rbrJobId,
                })
              ).unwrap();
              dismissModal();
              navigate(`/dispatch/${selectedDate}`);
            } catch (e) {
              setError(formatServerError(e));
            }
          } else if (requestedTripId) {
            try {
              await tripsRepository.accept(requestedTripId);
              dismissModal();
            } catch (e) {
              setError(formatServerError(e));
              // TODO: this is a dumb way to not have a success toast when an error occurs
              // Need to enhance the execTripMenuAction to allow custom handling
              throw new Error('No response');
            }

            dismissModal();

            if (assignmentId) {
              await tripsRepository.assign(requestedTripId, assignmentId);
            }
          } else if (selectedUnassignedTripIds.length && assignmentId) {
            try {
              await bulkAssign({
                bulkTripAssign: {
                  trip_ids: selectedUnassignedTripIds,
                  assignment_id: assignmentId,
                },
              }).unwrap();
              dismissModal();
              dispatch(setSelectedUnassignedTripIds([]));
            } catch (error) {
              dispatch(
                show({
                  title: 'Error assigning trips',
                  description:
                    'There was an error assigning trips. Please try again.',
                  type: 'error',
                })
              );
            }
          }
        },
        successMessage: assignmentId
          ? 'Trip accepted and assigned'
          : 'Trip accepted',
        errorMessage: `Error accepting trip request`,
      })
    );
  };

  return {
    error,
    onAcceptConfirm,
    dismissModal,
  };
};
