import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { formatDateAsTodayOrMonthDay } from 'src/common/date/formatDateAsTodayOrMonthDay';
import { FormErrorMessage } from 'src/common/FormErrorMessage';

export function AssignmentEmptyState() {
  const selectedDate = useAppSelector((state) => state.dispatcher.selectedDate);

  const now = DateTime.fromISO(DateTime.now().toISODate());
  const isDateInPast = DateTime.fromISO(selectedDate) < now;

  if (isDateInPast) {
    return null;
  }

  return (
    <FormErrorMessage>
      No assignments are found for {formatDateAsTodayOrMonthDay(selectedDate)}
      .&nbsp;
      <NavLink to="/team" className="underline hover:opacity-80">
        Add a new assignment
      </NavLink>
    </FormErrorMessage>
  );
}
