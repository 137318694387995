import { usePaginationInfo } from './usePaginationInfo';

export function PaginationInfo({
  currentPage,
  totalCount,
}: {
  currentPage: number;
  totalCount: number;
}) {
  const paginationInfo = usePaginationInfo({ currentPage, totalCount });
  return (
    <>
      <div className="px-4">|</div>
      <span
        className="text-sm font-semibold text-gray-900"
        data-testid="pagination-info"
      >
        {paginationInfo}
      </span>
    </>
  );
}
