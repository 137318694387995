import { TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';

export function DeleteAction({ onDelete }: { onDelete: () => void }) {
  return (
    <div className="mt-4 grid grid-cols-1 items-center gap-y-2 gap-x-4 sm:grid-cols-6">
      {/* 
          Funny enough we added passenger delete when we first created the passenger form
          and like door close buttons on elevators (Except in Korea apparently), it's just there for show ever since...

          TODO: When we do actually implement this let's use the ConfirmModal or DeleteModal component instead 
          of DeleteModal and DeleteAction within this
      */}
      <Button
        className="sm:col-span-3"
        onClick={onDelete}
        variant="danger"
        disabled
      >
        <TrashIcon className="mr-2 w-5" /> Delete this passenger
      </Button>
      <p className="text-sm sm:col-span-3">
        Please contact us if you need to delete passengers.
      </p>
    </div>
  );
}
