import { RouteObject } from 'react-router-dom';

import { DispatchRoute } from './DispatchRoute/DispatchRoute';
import { RunBambiRunRoute } from './RunBambiRunRoute/RunBambiRunRoute';

export const dispatchRoutes: RouteObject[] = [
  {
    index: true,
    element: <DispatchRoute />,
  },
  {
    path: ':selectedDate',
    element: <DispatchRoute />,
  },
  {
    path: ':selectedDate/:rbrJobId',
    element: <RunBambiRunRoute />,
  },
];
