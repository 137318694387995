import { useCallback, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';

import { isSameDay } from 'src/common/date/isSameDay';
import {
  DispatchAssignmentRead,
  DriverCallRequestRead,
  useAssignmentsAssignedMembershipsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import {
  setModalCallRequestInputs,
  showDispatchModal,
} from 'src/features/dispatch/dispatcher.slice';

import { useDispatchRepository } from '../../common/useDispatchRepository';

export const useCallRequestHandler = () => {
  const dispatch = useDispatch();
  const readState = useDispatchRepository();

  const openCallRequestModal = useCallback(
    (callRequest: DriverCallRequestRead, assignmentId: string) => {
      const driver = readState.data?.assignments.find(
        (assignment) => assignment.id === assignmentId
      )?.driver;
      if (!driver) return;

      dispatch(
        setModalCallRequestInputs({
          assignmentId,
          driver,
          callRequest,
        })
      );
      dispatch(showDispatchModal('callRequest'));
    },
    [dispatch, readState.data?.assignments]
  );

  return { openCallRequestModal };
};

export const useAssignmentInfo = (assignmentId: string) => {
  const readState = useDispatchRepository();
  const driverHasTrips = useMemo(() => {
    if (!readState.data) return false;
    const assignmentTrips = readState.data.assigned_trips.filter(
      (trip) => trip.assignment_id === assignmentId
    );
    return !!assignmentTrips.length;
  }, [readState.data, assignmentId]);

  return {
    driverHasTrips,
  };
};

export const useReactivateAssignment = (assignmentId: string) => {
  const { data, refetch } = useDispatchRepository();
  const dispatch = useDispatch();

  const [patchAssignedMembership] =
    useAssignmentsAssignedMembershipsPartialUpdateMutation();

  const reactivateAssignedMembership = useCallback(
    async (assignedMembershipId: string) =>
      patchAssignedMembership({
        id: assignedMembershipId,
        patchedAssignedMembership: {
          clocked_out_at: null,
        },
      }),
    [patchAssignedMembership]
  );

  const assignment = data?.assignments.find(
    (assignment) => assignment.id === assignmentId
  );

  const onSuccess = useCallback(() => {
    refetch();
    dispatch(
      show({
        title: 'Successfully reactivated assignment',
        type: 'success',
      })
    );
  }, [dispatch, refetch]);

  const onError = useCallback(
    (error: Error) => {
      dispatch(
        show({
          title: 'Failed to reactivate assignment',
          description: error.message,
          type: 'error',
        })
      );
    },
    [dispatch]
  );

  if (
    assignment &&
    assignment.clocked_out_at &&
    assignmentEndsToday(assignment)
  ) {
    return {
      reactivateAssignment: () => {
        reactivateAssignedMembership(assignment.driver.assigned_membership)
          .then(onSuccess)
          .catch(onError);
      },
    };
  } else {
    return {};
  }
};

const assignmentEndsToday = (assignment: DispatchAssignmentRead) => {
  const now = DateTime.now();
  const assignmentEnd = DateTime.fromISO(assignment.end);
  return isSameDay(assignmentEnd, now);
};
