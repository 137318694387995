import { includes } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

import { Recurrence } from '../../FormValues';
import { daysOfTheWeek } from './constants/constants';
import DayButton from './DayButton/DayButton';
import DayDetailsCard from './DayDetailsCard/DayDetailsCard';
import { getDetailsGridClass } from './util/getDetailsGridClass';
import { onDayClick } from './util/onDayClick';

export default function SubscriptionDaySelector() {
  const form = useFormContext();
  const formRecurrences = form.watch('recurrences');

  return (
    <div>
      <label className="block pb-1 text-sm font-medium text-gray-700">
        Occurs On*
      </label>
      <div className="flex gap-x-2">
        {daysOfTheWeek.map((day) => (
          <DayButton
            key={day}
            day={day}
            selected={includes(
              formRecurrences.map((x: Recurrence) => x.day_of_the_week),
              day
            )}
            onClick={() => onDayClick(day, form)}
          />
        ))}
      </div>
      <div
        className={`grid ${getDetailsGridClass(
          formRecurrences.length
        )} gap-2 pt-4`}
        data-testid="day-details-container"
      >
        {formRecurrences.map((recurrence: Recurrence, i: number) => (
          <DayDetailsCard
            day={recurrence.day_of_the_week}
            key={i}
            index={i}
            selectedDaysLength={formRecurrences.length}
          />
        ))}
      </div>
    </div>
  );
}
