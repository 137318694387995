import { useEffect, useState } from 'react';

import { CloudArrowDownIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { initializeFilters } from './invoice.slice';
import InvoiceController from './InvoiceController';
import { InvoiceGlobalFilters } from './InvoiceGlobalFilters';
import { DownloadInvoices } from './management/DownloadInvoices';
import { InvoiceDataGrid } from './management/InvoiceDataGrid';
import { useCreateEmptyInvoice } from './management/useCreateEmptyInvoice';

export default function InvoiceScreen() {
  const handleCreateEmptyInvoice = useCreateEmptyInvoice();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initializeFilters());
  }, [dispatch]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <InvoiceGlobalFilters />
        <div className="mb-4 flex gap-2">
          <Button
            variant="secondary-outline"
            onClick={() => setModalOpen(true)}
          >
            <CloudArrowDownIcon className="mr-2 w-5" /> Download Invoices
          </Button>
          <Button variant="primary" onClick={() => handleCreateEmptyInvoice()}>
            Create an Invoice
          </Button>
        </div>
      </div>
      <DownloadInvoices
        open={modalOpen}
        setOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <InvoiceDataGrid />
      <InvoiceController />
    </div>
  );
}
