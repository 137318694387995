import { MbscCalendarEvent } from '@mobiscroll/react';

import {
  DispatchAssignmentBreaksRead,
  DispatchAssignmentRead,
} from 'src/common/external/bambi-api/bambiApi';

export function createEventsFromBreaks(
  assignments: DispatchAssignmentRead[]
): MbscCalendarEvent[] {
  const breakEvents: MbscCalendarEvent[] = [];
  assignments.forEach((assignment) => {
    if (assignment.breaks) {
      assignment.breaks.forEach((assignmentBreak) => {
        const { start, end } = getStartAndEndTimeForBreak(assignmentBreak);

        breakEvents.push({
          id: assignmentBreak.id,
          start,
          end,
          title: 'Break',
          resource: assignment.id,
          assignmentBreak: { assignment_id: assignment.id, ...assignmentBreak },
        });
      });
    }
  });
  return breakEvents;
}

function getStartAndEndTimeForBreak(
  assignmentBreak: DispatchAssignmentBreaksRead
) {
  const isBreakCompleted =
    assignmentBreak.started_at && assignmentBreak.ended_at;
  const start =
    isBreakCompleted && assignmentBreak.started_at
      ? assignmentBreak.started_at
      : assignmentBreak.start;
  const end =
    isBreakCompleted && assignmentBreak.ended_at
      ? assignmentBreak.ended_at
      : assignmentBreak.end;
  return { start, end };
}
