import React from 'react';

export function TripEventLineDisplay({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) {
  return (
    <div>
      <span className="text-sm font-light">{label}: </span>
      {typeof value === 'string' ? (
        <span className="text-sm font-medium lg:text-sm">{value}</span>
      ) : (
        value
      )}
    </div>
  );
}
