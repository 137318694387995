import {
  PayerOrganizationRead,
  usePayersListSelectedQuery,
} from 'src/common/external/bambi-api/bambiApi';

export function usePayerOrganizationOptions(
  search?: string,
  selectedPayers?: string[],
  selectedPayerId?: string,
  canMultiload?: boolean
) {
  const { data, isLoading, isFetching } = usePayersListSelectedQuery({
    search,
    canMultiload,
    payerFilters: {
      selected_payers: selectedPayers?.length ? selectedPayers : [],
    },
  });
  const payers: PayerOrganizationRead[] = data?.results || [];
  let options = payers.map((payer) => ({
    label: payer.display_name,
    value: payer.id,
  }));

  if (selectedPayerId) {
    options = options.filter((option) => option.value !== selectedPayerId);
  }

  return { options, isLoading, isFetching };
}
