import { CheckIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/solid';

export function PassFailIcon({ isPass }: { isPass: boolean }) {
  return (
    <div className="flex items-center gap-1">
      {isPass ? (
        <div data-testid="pass-icon-container">
          <CheckIcon className="h-7 w-7 font-extrabold text-black" />
        </div>
      ) : null}
      {!isPass ? (
        <div data-testid="fail-icon-container">
          <XCircleIcon className="h-7 w-7 text-warning-600" />
        </div>
      ) : null}
    </div>
  );
}
