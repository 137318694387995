import { RoleEnum } from 'src/common/external/bambi-api/bambiApi';
import { useUserRoles } from 'src/features/auth/useUserRoles';

const allowedRolesForSettings: RoleEnum[] = [
  'admin',
  'owner',
  'bambi',
] as RoleEnum[];

export function useShouldShowOrgSettings() {
  const { role } = useUserRoles();

  return role && !!allowedRolesForSettings.includes(role);
}
