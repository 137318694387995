import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import { DataGrid } from 'src/common/DataGrid';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { Badge } from 'src/common/primitives/Badge';

import { RequestTripDetailsSelectionCell } from '../RequestTripDetailsSelectionCell';
import { RejectionReasonSelectionCell } from './RejectionReasonSelectionCell';

const columnHelper = createColumnHelper<TripRead>();
const columns = [
  columnHelper.accessor('scheduled_pickup_at', {
    header: 'Pickup Time',
    cell: ({ getValue }) => (
      <Badge
        label={DateTime.fromISO(getValue()).toLocaleString(
          DateTime.TIME_SIMPLE
        )}
        color="blue-dark"
      />
    ),
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.accessor('passenger.full_name', {
    header: 'Passenger',
    cell: ({ getValue }) => <div className="font-bold">{getValue()}</div>,
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    header: 'Pickup Address',
    cell: ({ row }) => {
      return <>{formatAddress(row.original.pickup.address)}</>;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    header: 'Dropoff Address',
    cell: ({ row }) => {
      return <>{formatAddress(row.original.dropoff.address)}</>;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    header: 'Rejection Reason',
    cell: ({ row }) => {
      return <RejectionReasonSelectionCell tripId={row.original.id} />;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
];

const rowSelectionColumn = columnHelper.display({
  id: 'selection',
  header: () => <div data-testid="row-selection-column" />,
  enableHiding: false,
  meta: {
    enableHeaderMenu: false,
  },
  cell: ({ row }) => {
    return <RequestTripDetailsSelectionCell tripId={row.original.id} />;
  },
});

export function RejectRequestTripDetails({ trips }: { trips: TripRead[] }) {
  return (
    <div data-testid="request-trip-details">
      <DataGrid
        tableId="request-trip-details"
        containerClassName="max-h-[50vh]"
        data={trips}
        columns={
          trips.length > 1
            ? [
                // TODO: Skipping DataGrid's props for now
                // Attempted using it and ran into a lot of type issues that
                // are more involved than needs to be included in this pr
                rowSelectionColumn,
                ...columns,
              ]
            : columns
        }
        includePagination={false}
        dataTestId="request-trip-details-table"
      />
    </div>
  );
}
