import { useEffect } from 'react';

import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { PaginatedPassengerAddressListRead } from 'src/common/external/bambi-api/bambiApi';

interface useSetPickupAddressProps {
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined;
  index: number;
  setValue: UseFormSetValue<FieldValues>;
  trips: any;
}

export default function useSetPickupAddress({
  recentAddressResponse,
  index,
  setValue,
  trips,
}: useSetPickupAddressProps) {
  useEffect(() => {
    if (recentAddressResponse?.results.length) {
      const primaryAddress = recentAddressResponse.results.find(
        (result) => result.is_primary_address
      );

      if (primaryAddress) {
        setValue(`trips.${index}.pickupLocation.address`, {
          label: primaryAddress.address.location?.label,
          value: primaryAddress.address.location?.place_id,
        });
        setValue(
          `trips.${index}.pickupLocation.address_details`,
          primaryAddress.address_details
        );
        setValue(
          `trips.${index}.pickupLocation.driver_notes`,
          primaryAddress.address_notes
        );
      }
    }
  }, [recentAddressResponse, trips, index, setValue]);
}
