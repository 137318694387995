import { Alert } from 'src/common/Alert/Alert';
import { DataGrid } from 'src/common/DataGrid';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useV1PricingAlert } from 'src/features/pricing/management/useV1PricingAlert';

import { columns } from './columns';
import { PricingEmptyState } from './PricingEmptyState';
import { usePricingManagementViewModel } from './usePricingManagementViewModel';

export function PricingManagementRoute() {
  const {
    pricingSchemes,
    isPricingPricingSchemesLoading,
    pagination,
    handlePaginationChange,
  } = usePricingManagementViewModel();

  const {
    shouldShowPricingV1Alert,
    orgSettingsUpdating,
    handleEnableNewPricing,
  } = useV1PricingAlert();

  return (
    <>
      {isPricingPricingSchemesLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {shouldShowPricingV1Alert && (
            <div className="flex justify-center">
              <div className="w-full pb-6 md:w-2/3">
                <Alert
                  title="New Pricing Scheme Not Activated"
                  message="To apply the new pricing scheme for future trips, please enable it. Click the button on the right to activate the new pricing."
                  severity="warning"
                  action={
                    <Button
                      loading={orgSettingsUpdating}
                      onClick={handleEnableNewPricing}
                    >
                      Enable New Pricing
                    </Button>
                  }
                />
              </div>
            </div>
          )}
          <DataGrid
            tableId="trip-subscription"
            columns={columns}
            data={pricingSchemes?.results || []}
            pagination={pagination}
            totalCount={pricingSchemes?.count || 0}
            handlePaginationChange={handlePaginationChange}
            emptyState={<PricingEmptyState />}
            getRowId={(row) => row.id}
          />
        </>
      )}
    </>
  );
}
