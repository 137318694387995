import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { CurrencyInput } from 'src/common/CurrencyInput';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { isPickupTimeBeforeAppointmentTime } from 'src/features/add-trip/TripDetails/isPickupTimeBeforeAppointmentTime';
import { WillCallContextField } from 'src/features/add-trip/TripDetails/WillCallContextField';

import { IsRoundTripContextField } from '../../IsRoundTripContextField';
import TimeField from '../../TimeField';
import useCalculatePickupTime from './useCalculatePickupTime';

interface DayDetailsCardProps {
  day: string;
  index: number;
  selectedDaysLength: number;
}

export default function DayDetailsCard({
  day,
  index,
  selectedDaysLength,
}: DayDetailsCardProps) {
  const form = useFormContext();
  const isRoundTrip = form.watch(`recurrences.${index}.is_round_trip`);

  useEffect(() => {
    if (!isRoundTrip) {
      form.setValue(`recurrences.${index}.return_time`, '');
      form.clearErrors(`recurrences.${index}.return_time`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoundTrip, index]);

  useCalculatePickupTime(index);

  const gridCols = selectedDaysLength < 6 ? 'grid-cols-2' : 'grid-cols-1';

  return (
    <div
      className="rounded border border-gray-200 bg-white"
      data-testid={`day-detail-${day}`}
    >
      <div className="border-b border-gray-200 px-4 py-2">
        <h3 className="text-base font-semibold text-gray-700">{day}</h3>
      </div>
      <div className="px-2 py-4">
        <div className={`grid ${gridCols} gap-2`}>
          <div>
            <TimeField
              rules={{
                validate: (value: string) => {
                  if (!value) {
                    return 'Pickup Time is required';
                  }

                  const recurrences = form.getValues('recurrences');

                  return isPickupTimeBeforeAppointmentTime(
                    value,
                    recurrences[index].appointment_time
                  );
                },
              }}
              contextName={`recurrences.${index}.pickup_time`}
              label="Pickup Time *"
              inputProps={{ id: `recurrences.${index}.pickup_time` }}
            />
          </div>
          <div>
            <TimeField
              rules={{
                validate: (value: string) => {
                  if (!value) {
                    return true;
                  }

                  const recurrences = form.getValues('recurrences');

                  const isValid = isPickupTimeBeforeAppointmentTime(
                    recurrences[index].pickup_time,
                    value
                  );

                  if (isValid === true) {
                    form.clearErrors(`recurrences.${index}.pickup_time`);
                  }
                },
              }}
              contextName={`recurrences.${index}.appointment_time`}
              label="Appointment Time"
              inputProps={{ id: `recurrences.${index}.appointment_time` }}
            />
          </div>
        </div>
        <div className={`grid ${gridCols} gap-2 pt-2`}>
          <FormContextField name={`recurrences.${index}.input_price_cents`}>
            {({ field, fieldState }) => {
              const { onChange, ...remainingProps } = field;
              return (
                <DefaultFormFieldLayout
                  label="Input Price"
                  error={fieldState.error?.message?.toString()}
                  inputProps={{ id: `recurrences.${index}.input_price_cents` }}
                >
                  <div className="flex items-center gap-2">
                    <CurrencyInput
                      {...remainingProps}
                      onValueChange={(values) => {
                        field.onChange(values.floatValue);
                      }}
                      className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border-gray-300 shadow-sm disabled:cursor-not-allowed sm:text-sm"
                    />
                  </div>
                </DefaultFormFieldLayout>
              );
            }}
          </FormContextField>
        </div>
        {isRoundTrip && (
          <div className="my-4 border-t  border-gray-200">
            <div className="pt-2">Return</div>
            <div className={`grid ${gridCols} gap-4`}>
              <div>
                <TimeField
                  rules={{ required: 'Return Pickup Time is required' }}
                  contextName={`recurrences.${index}.return_time`}
                  label="Pickup Time *"
                  inputProps={{ id: `recurrences.${index}.return_time` }}
                />
              </div>
              <div>
                <FormContextField
                  name={`recurrences.${index}.return_input_price_cents`}
                >
                  {({ field, fieldState }) => {
                    const { onChange, ...remainingProps } = field;
                    return (
                      <DefaultFormFieldLayout
                        label="Input Price"
                        error={fieldState.error?.message?.toString()}
                        inputProps={{
                          id: `recurrences.${index}.return_input_price_cents`,
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <CurrencyInput
                            {...remainingProps}
                            onValueChange={(values) => {
                              field.onChange(values.floatValue);
                            }}
                            className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border-gray-300 shadow-sm disabled:cursor-not-allowed sm:text-sm"
                          />
                        </div>
                      </DefaultFormFieldLayout>
                    );
                  }}
                </FormContextField>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 flex gap-4">
          <WillCallContextField fieldPath={`recurrences.${index}`} />
          <IsRoundTripContextField fieldPath={`recurrences.${index}`} />
        </div>
      </div>
    </div>
  );
}
