import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { errorMessagePretty } from './errorMessagePretty';
import { formatServerError, genericErrorMessage } from './serverErrorFormatter';

export function formatRTKQueryBaseQueryError(error: FetchBaseQueryError) {
  if (
    typeof error.status === 'number' ||
    ('originalStatus' in error && typeof error.originalStatus === 'number')
  ) {
    return formatServerError(error);
  }

  if ('error' in error) {
    return errorMessagePretty.get(error.error) || error.error;
  }

  return genericErrorMessage;
}
