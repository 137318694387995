/* 
  TODO: 
    This actually is the layout that will be used for displaying the pricing model details, so
    will have to be cleaned up and used for that purpose. Once that's done, we can render it here
    and fade it out as in the designs.

    Some of the fields may be dependent on actual data existing, so we'll probably need to create
    a mock pricing model to use for this purpose. I want to defer doing that until
    I get to that task, so here we are.

    There are a few changes to the generated code:
     * select-none and pointer-events-none - Prevent users from selecting text within the faded content
     * max-h-[80vh] and overflow-hidden - Prevent the content from overflowing the viewport
*/

/* Code generated with AutoHTML Plugin for Figma */
export function PricingModelEmptyStateMockContent() {
  return (
    <div
      className="bg-base-white select-nonerelative pointer-events-none flex max-h-[80vh] flex-1 flex-col items-start justify-start gap-5 overflow-hidden"
      style={{ opacity: '0.10000000149011612' }}
      data-testid="pricing-model-empty-state-mock-content"
    >
      <div className="relative flex shrink-0 flex-col items-start justify-start gap-5 self-stretch pt-4 pr-6 pl-6">
        <div className="relative flex shrink-0 flex-col items-start justify-center gap-4 self-stretch">
          <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch">
            <div className="relative flex w-[261px] shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative flex shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
                <div
                  className="relative text-left text-gray-700"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Email address *{' '}
                </div>
                <div
                  className="bg-base-white relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-3.5 pb-2.5 pl-3.5"
                  style={{
                    boxShadow:
                      'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                  }}
                >
                  <div className="relative flex flex-1 flex-row items-center justify-start gap-2">
                    <div
                      className="relative flex-1 text-left text-gray-500"
                      style={{
                        font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                      }}
                    >
                      pricing model name{' '}
                    </div>
                  </div>
                  <div className="relative h-4 w-4 shrink-0">
                    <svg
                      className="absolute left-0 top-0 overflow-visible"
                      style={{}}
                      width="16"
                      height="16"
                    ></svg>
                  </div>
                </div>
              </div>
              <div
                className="relative self-stretch text-left text-gray-500"
                style={{
                  font: "var(--textsm-normal, 400 14px/20px 'Inter', sans-serif)",
                }}
              >
                This is a hint text to help user.{' '}
              </div>
            </div>
            <div className="relative flex shrink-0 flex-row items-center justify-start gap-3">
              <div
                className="bg-base-white relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-4 pb-2.5 pl-4"
                style={{
                  boxShadow:
                    'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                }}
              >
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_401_2592)">
                    <path
                      d="M6.25002 10L8.75002 12.5L13.75 7.5M18.3334 10C18.3334 14.6024 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6024 1.66669 10C1.66669 5.39763 5.39765 1.66667 10 1.66667C14.6024 1.66667 18.3334 5.39763 18.3334 10Z"
                      stroke="#344054"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_401_2592">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div
                  className="relative text-left text-gray-700"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Mark as Default{' '}
                </div>
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                ></svg>
              </div>
              <div
                className="bg-base-white relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-4 pb-2.5 pl-4"
                style={{
                  boxShadow:
                    'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                }}
              >
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="#344054"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div
                  className="relative text-left text-gray-700"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Cancel{' '}
                </div>
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                ></svg>
              </div>
              <div
                className="bg-bambi-secondarymint border-bambi-secondarymint relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid pt-2.5 pr-4 pb-2.5 pl-4"
                style={{
                  boxShadow:
                    'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                }}
              >
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.83333 2.5V5.33333C5.83333 5.80004 5.83333 6.0334 5.92416 6.21166C6.00406 6.36846 6.13154 6.49594 6.28834 6.57584C6.4666 6.66667 6.69996 6.66667 7.16667 6.66667H12.8333C13.3 6.66667 13.5334 6.66667 13.7117 6.57584C13.8685 6.49594 13.9959 6.36846 14.0758 6.21166C14.1667 6.0334 14.1667 5.80004 14.1667 5.33333V3.33333M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div
                  className="text-base-white relative text-left"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Save{' '}
                </div>
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                ></svg>
              </div>
            </div>
          </div>
          <div className="relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch">
            <div
              className="relative text-left text-gray-700"
              style={{
                font: "var(--textmd-medium, 500 16px/24px 'Inter', sans-serif)",
              }}
            >
              Details:{' '}
            </div>
            <div className="relative flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative flex shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
                <div
                  className="relative text-left text-gray-700"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Email address *{' '}
                </div>
                <div
                  className="bg-base-white relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-3.5 pb-2.5 pl-3.5"
                  style={{
                    boxShadow:
                      'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                  }}
                >
                  <div className="relative flex flex-1 flex-row items-center justify-start gap-2">
                    <div
                      className="relative flex-1 text-left text-gray-500"
                      style={{
                        font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                      }}
                    >
                      pricing model details{' '}
                    </div>
                  </div>
                  <div className="relative h-4 w-4 shrink-0">
                    <svg
                      className="absolute left-0 top-0 overflow-visible"
                      style={{}}
                      width="16"
                      height="16"
                    ></svg>
                  </div>
                </div>
              </div>
              <div
                className="relative self-stretch text-left text-gray-500"
                style={{
                  font: "var(--textsm-normal, 400 14px/20px 'Inter', sans-serif)",
                }}
              >
                This is a hint text to help user.{' '}
              </div>
            </div>
          </div>
          <div className="relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch">
            <div
              className="relative text-left text-gray-700"
              style={{
                font: "var(--textmd-medium, 500 16px/24px 'Inter', sans-serif)",
              }}
            >
              Pricing Condition:{' '}
            </div>
            <div
              className="relative flex shrink-0 flex-row items-start justify-start gap-0 overflow-hidden rounded-lg border border-solid border-gray-300"
              style={{
                boxShadow:
                  'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
              }}
            >
              <div className="bg-bambi-secondarymint border-bambi-secondarymint relative flex shrink-0 flex-row items-center justify-center gap-2 border-r border-solid pt-2.5 pr-4 pb-2.5 pl-4">
                <div
                  className="text-base-white relative text-left"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  None{' '}
                </div>
              </div>
              <div className="bg-base-white relative flex shrink-0 flex-row items-center justify-center gap-2 border-r border-solid border-gray-300 pt-2.5 pr-4 pb-2.5 pl-4">
                <div
                  className="relative text-left text-gray-700"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Add Condition{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="relative shrink-0 self-stretch overflow-visible"
          style={{}}
          width="1127"
          height="1"
          viewBox="0 0 1127 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1127 1H0V0H1127V1Z"
            fill="#EAECF0"
          />
        </svg>

        <div className="relative flex shrink-0 flex-row items-start justify-start gap-2 self-stretch border-b border-solid border-gray-200">
          <div className="relative flex shrink-0 flex-col items-start justify-start gap-2">
            <div className="relative flex shrink-0 flex-row items-start justify-start gap-4">
              <div className="relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden pt-px pr-1 pb-[19px] pl-1">
                <div
                  className="relative text-left text-gray-500"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Base Rate{' '}
                </div>
              </div>
              <div className="relative flex shrink-0 flex-row items-center justify-center gap-1.5 overflow-hidden border-b-2 border-solid border-primary-700 pr-1 pb-[18px] pl-1">
                <div
                  className="relative text-left text-primary-700"
                  style={{ font: "500 14px/20px 'Inter', sans-serif" }}
                >
                  Mile Rate{' '}
                </div>
              </div>
              <div className="relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden pt-px pr-1 pb-[19px] pl-1">
                <div
                  className="relative text-left text-gray-500"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Driving Time Rate{' '}
                </div>
              </div>
              <div className="relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden pt-px pr-1 pb-[19px] pl-1">
                <div
                  className="relative text-left text-gray-500"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Wait Time Rate{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex shrink-0 flex-row items-center justify-start gap-2 pt-px pr-px pl-px">
            <div className="bg-base-white relative h-[17px] w-[17px] shrink-0 rounded-[8.5px] border border-dashed border-gray-400">
              <div className="absolute left-0 top-0 flex flex-row items-center justify-center gap-0 overflow-hidden rounded-[8.5px] p-[2.83px]">
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.50001 3.19444V9.80555M3.19446 6.5H9.80557"
                    stroke="#667085"
                    strokeWidth="0.944442"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex shrink-0 flex-row items-start justify-start gap-8 self-stretch">
          <div className="relative flex shrink-0 flex-row items-center justify-start gap-2">
            <div
              className="relative text-left text-gray-700"
              style={{
                font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
              }}
            >
              Initial Rate:{' '}
            </div>
            <div className="relative flex shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative flex w-80 shrink-0 flex-col items-start justify-start gap-1.5">
                <div
                  className="relative text-left text-gray-700"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Email address *{' '}
                </div>
                <div
                  className="bg-base-white relative flex shrink-0 flex-row items-start justify-start gap-0 self-stretch overflow-hidden rounded-lg border border-solid border-gray-300"
                  style={{
                    boxShadow:
                      'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                  }}
                >
                  <div className="relative flex flex-1 flex-row items-center justify-start gap-2 pt-2.5 pb-2.5 pl-3.5">
                    <div className="relative flex flex-1 flex-row items-start justify-start gap-2">
                      <div
                        className="relative text-left text-gray-500"
                        style={{
                          font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                        }}
                      >
                        ${' '}
                      </div>
                      <div
                        className="relative flex-1 text-left text-gray-500"
                        style={{
                          font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                        }}
                      >
                        00.00{' '}
                      </div>
                    </div>
                    <div className="relative h-4 w-4 shrink-0">
                      <svg
                        className="absolute left-0 top-0 overflow-visible"
                        style={{}}
                        width="16"
                        height="16"
                      ></svg>
                    </div>
                  </div>
                  <div className="relative flex shrink-0 flex-row items-center justify-between overflow-hidden pt-2.5 pr-3.5 pb-2.5 pl-3.5">
                    <div
                      className="relative text-left text-gray-900"
                      style={{
                        font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                      }}
                    >
                      per mile{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="relative self-stretch text-left text-gray-500"
                style={{
                  font: "var(--textsm-normal, 400 14px/20px 'Inter', sans-serif)",
                }}
              >
                This is a hint text to help user.{' '}
              </div>
            </div>
          </div>
          <div className="relative flex flex-1 flex-row items-center justify-start gap-2 self-stretch">
            <div
              className="relative text-left text-gray-700"
              style={{
                font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
              }}
            >
              Rounding Strategy:{' '}
            </div>
            <div className="relative flex w-[261px] shrink-0 flex-col items-start justify-start gap-0"></div>
          </div>
        </div>
        <div className="relative flex shrink-0 flex-col items-start justify-start gap-4 self-stretch">
          <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch">
            <div
              className="relative text-left text-gray-900"
              style={{
                font: "var(--textlg-medium, 500 18px/28px 'Inter', sans-serif)",
              }}
            >
              Rules{' '}
            </div>
            <div className="relative flex shrink-0 flex-row items-center justify-start gap-3">
              <div className="relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg pt-2.5 pr-4 pb-2.5 pl-4">
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.83331 12.5L9.99998 16.6667L14.1666 12.5M5.83331 7.50001L9.99998 3.33334L14.1666 7.50001"
                    stroke="#D0D5DD"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div
                  className="relative text-left text-gray-300"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Expand/Collapse All{' '}
                </div>
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                ></svg>
              </div>
              <div
                className="bg-bambi-secondarymint border-bambi-secondarymint relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid pt-2 pr-3.5 pb-2 pl-3.5"
                style={{
                  boxShadow:
                    'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                }}
              >
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4.16666V15.8333M4.16669 9.99999H15.8334"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div
                  className="text-base-white relative text-left"
                  style={{
                    font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                  }}
                >
                  Add Rule{' '}
                </div>
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{}}
                  width="20"
                  height="20"
                ></svg>
              </div>
            </div>
          </div>
          <div className="relative flex shrink-0 flex-col items-start justify-center gap-4 self-stretch pt-1">
            <div
              className="relative flex shrink-0 flex-col items-start justify-start gap-0 self-stretch rounded-lg border border-solid border-[#eaecf0] bg-[#ffffff] pb-4"
              style={{
                boxShadow:
                  '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
              }}
            >
              <div className="relative flex h-[72px] shrink-0 flex-row items-center justify-start gap-0 self-stretch rounded-tl-lg rounded-tr-lg border-t border-r border-l border-solid border-[#eaecf0] bg-gray-50 pt-4 pr-6 pb-4 pl-6">
                <svg
                  className="relative shrink-0 overflow-visible"
                  style={{ transform: 'translate(0px, -24px)' }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6C9.55228 6 10 5.55228 10 5Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 5C17 4.44772 16.5523 4 16 4C15.4477 4 15 4.44772 15 5C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 19C10 18.4477 9.55228 18 9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20C9.55228 20 10 19.5523 10 19Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 19C17 18.4477 16.5523 18 16 18C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20C16.5523 20 17 19.5523 17 19Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="relative flex flex-1 flex-row items-center justify-start gap-1.5 rounded-tl-lg rounded-bl-lg pt-4 pr-3 pb-4 pl-3">
                  <div className="relative flex flex-1 flex-row items-center justify-start gap-6">
                    <div className="relative flex w-[493px] shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
                      <div className="relative flex shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
                        <div
                          className="relative text-left text-gray-700"
                          style={{
                            font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                          }}
                        >
                          Email address *{' '}
                        </div>
                        <div
                          className="bg-base-white relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-3.5 pb-2.5 pl-3.5"
                          style={{
                            boxShadow:
                              'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                          }}
                        >
                          <div className="relative flex flex-1 flex-row items-center justify-start gap-2">
                            <div
                              className="relative flex-1 text-left text-gray-500"
                              style={{
                                font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                              }}
                            >
                              rule name{' '}
                            </div>
                          </div>
                          <div className="relative h-4 w-4 shrink-0">
                            <svg
                              className="absolute left-0 top-0 overflow-visible"
                              style={{}}
                              width="16"
                              height="16"
                            ></svg>
                          </div>
                        </div>
                      </div>
                      <div
                        className="relative self-stretch text-left text-gray-500"
                        style={{
                          font: "var(--textsm-normal, 400 14px/20px 'Inter', sans-serif)",
                        }}
                      >
                        This is a hint text to help user.{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative flex shrink-0 flex-row items-center justify-start gap-3">
                  <div
                    className="bg-base-white relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-4 pb-2.5 pl-4"
                    style={{
                      boxShadow:
                        'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                    }}
                  >
                    <svg
                      className="relative shrink-0 overflow-visible"
                      style={{}}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3333 4.99999V4.33332C13.3333 3.3999 13.3333 2.93319 13.1517 2.57667C12.9919 2.26307 12.7369 2.0081 12.4233 1.84831C12.0668 1.66666 11.6001 1.66666 10.6667 1.66666H9.33333C8.39991 1.66666 7.9332 1.66666 7.57668 1.84831C7.26308 2.0081 7.00811 2.26307 6.84832 2.57667C6.66667 2.93319 6.66667 3.3999 6.66667 4.33332V4.99999M8.33333 9.58332V13.75M11.6667 9.58332V13.75M2.5 4.99999H17.5M15.8333 4.99999V14.3333C15.8333 15.7335 15.8333 16.4335 15.5608 16.9683C15.3212 17.4387 14.9387 17.8212 14.4683 18.0608C13.9335 18.3333 13.2335 18.3333 11.8333 18.3333H8.16667C6.76654 18.3333 6.06647 18.3333 5.53169 18.0608C5.06129 17.8212 4.67883 17.4387 4.43915 16.9683C4.16667 16.4335 4.16667 15.7335 4.16667 14.3333V4.99999"
                        stroke="#344054"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div
                      className="relative text-left text-gray-700"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Delete{' '}
                    </div>
                    <svg
                      className="relative shrink-0 overflow-visible"
                      style={{}}
                      width="20"
                      height="20"
                    ></svg>
                  </div>
                  <div
                    className="bg-base-white relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-4 pb-2.5 pl-4"
                    style={{
                      boxShadow:
                        'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                    }}
                  >
                    <svg
                      className="relative shrink-0 overflow-visible"
                      style={{}}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 5L5 15M5 5L15 15"
                        stroke="#344054"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div
                      className="relative text-left text-gray-700"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Cancel{' '}
                    </div>
                    <svg
                      className="relative shrink-0 overflow-visible"
                      style={{}}
                      width="20"
                      height="20"
                    ></svg>
                  </div>
                  <div
                    className="bg-bambi-secondarymint border-bambi-secondarymint relative flex shrink-0 flex-row items-center justify-center gap-2 overflow-hidden rounded-lg border border-solid pt-2.5 pr-4 pb-2.5 pl-4"
                    style={{
                      boxShadow:
                        'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                    }}
                  >
                    <svg
                      className="relative shrink-0 overflow-visible"
                      style={{}}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.83333 2.5V5.33333C5.83333 5.80004 5.83333 6.0334 5.92416 6.21166C6.00406 6.36846 6.13154 6.49594 6.28834 6.57584C6.4666 6.66667 6.69996 6.66667 7.16667 6.66667H12.8333C13.3 6.66667 13.5334 6.66667 13.7117 6.57584C13.8685 6.49594 13.9959 6.36846 14.0758 6.21166C14.1667 6.0334 14.1667 5.80004 14.1667 5.33333V3.33333M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div
                      className="text-base-white relative text-left"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Save{' '}
                    </div>
                    <svg
                      className="relative shrink-0 overflow-visible"
                      style={{}}
                      width="20"
                      height="20"
                    ></svg>
                  </div>
                </div>
              </div>
              <svg
                className="relative shrink-0 self-stretch overflow-visible"
                style={{}}
                width="1127"
                height="1"
                viewBox="0 0 1127 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1127 1H0V0H1127V1Z"
                  fill="#EAECF0"
                />
              </svg>

              <div className="relative flex shrink-0 flex-col items-start justify-start gap-9 self-stretch pt-4 pr-3 pb-4 pl-9">
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-0 self-stretch">
                  <div className="relative flex shrink-0 flex-row items-start justify-start gap-4 self-stretch">
                    <div className="relative flex shrink-0 flex-col items-center justify-start gap-1 self-stretch pt-2 pb-1">
                      <div
                        className="relative h-8 w-8 shrink-0 overflow-hidden rounded-2xl bg-primary-50"
                        style={{
                          boxShadow:
                            'var(--focusring-4-pxprimary-100-box-shadow, 0px 0px 0px 4px rgba(244, 235, 255, 1.00))',
                        }}
                      >
                        <div className="border-primary-600 absolute right-0 left-0 bottom-0 top-0 rounded-2xl border-2 border-solid bg-primary-50">
                          <div className="bg-primary-600 absolute left-[calc(50%_-_5px)] top-[calc(50%_-_5px)] h-2.5 w-2.5 rounded-[50%]"></div>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex flex-1 flex-col items-center justify-start gap-4 pt-1 pb-1">
                      <div className="relative flex shrink-0 flex-row items-center justify-start gap-4 self-stretch">
                        <div
                          className="relative text-left text-gray-700"
                          style={{
                            font: "var(--textmd-medium, 500 16px/24px 'Inter', sans-serif)",
                          }}
                        >
                          If{' '}
                        </div>
                        <div className="relative flex w-[247px] shrink-0 flex-col items-start justify-start gap-0"></div>
                        <div
                          className="relative text-left text-gray-700"
                          style={{
                            font: "var(--textmd-medium, 500 16px/24px 'Inter', sans-serif)",
                          }}
                        >
                          is{' '}
                        </div>
                        <div className="relative flex w-[215px] shrink-0 flex-col items-start justify-start gap-0"></div>
                        <div className="relative flex shrink-0 flex-col items-start justify-start gap-0"></div>
                        <div className="bg-base-white relative h-6 w-6 shrink-0 rounded-xl border border-dashed border-gray-400">
                          <div className="absolute left-0 top-0 flex flex-row items-center justify-center gap-0 overflow-hidden rounded-xl p-1">
                            <svg
                              className="relative shrink-0 overflow-visible"
                              style={{}}
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.99998 3.33334V12.6667M3.33331 8.00001H12.6666"
                                stroke="#98A2B3"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-6 self-stretch pr-4">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-2">
                    <div
                      className="relative text-left text-gray-700"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Additional Base:{' '}
                    </div>
                    <div className="relative flex shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
                      <div className="relative flex w-80 shrink-0 flex-col items-start justify-start gap-1.5">
                        <div
                          className="relative text-left text-gray-700"
                          style={{
                            font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                          }}
                        >
                          Email address *{' '}
                        </div>
                        <div
                          className="bg-base-white relative flex shrink-0 flex-row items-start justify-start gap-0 self-stretch overflow-hidden rounded-lg border border-solid border-gray-300"
                          style={{
                            boxShadow:
                              'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                          }}
                        >
                          <div className="relative flex shrink-0 flex-row items-center justify-between self-stretch overflow-hidden pt-2.5 pr-3 pb-2.5 pl-3.5">
                            <div
                              className="relative text-left text-gray-900"
                              style={{
                                font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                              }}
                            >
                              Add{' '}
                            </div>
                            <svg
                              className="relative shrink-0 overflow-visible"
                              style={{}}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 7.5L10 12.5L15 7.5"
                                stroke="#667085"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="relative flex flex-1 flex-row items-center justify-start gap-2 pt-2.5 pr-3.5 pb-2.5">
                            <div
                              className="relative flex-1 text-left text-gray-500"
                              style={{
                                font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                              }}
                            >
                              $ 0.00{' '}
                            </div>
                            <div className="relative h-4 w-4 shrink-0">
                              <svg
                                className="absolute left-0 top-0 overflow-visible"
                                style={{}}
                                width="16"
                                height="16"
                              ></svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="relative self-stretch text-left text-gray-500"
                        style={{
                          font: "var(--textsm-normal, 400 14px/20px 'Inter', sans-serif)",
                        }}
                      >
                        This is a hint text to help user.{' '}
                      </div>
                    </div>
                  </div>
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch">
                    <div
                      className="relative text-left text-gray-700"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Percentage:{' '}
                    </div>
                    <div className="relative flex w-[261px] shrink-0 flex-col items-start justify-start gap-0"></div>
                  </div>
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch">
                    <div
                      className="relative text-left text-gray-700"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Unit Covered:{' '}
                    </div>
                    <div className="relative flex shrink-0 flex-col items-start justify-start gap-0"></div>
                  </div>
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch">
                    <div
                      className="relative text-left text-gray-700"
                      style={{
                        font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                      }}
                    >
                      Description:{' '}
                    </div>
                    <div className="relative flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                      <div className="relative flex shrink-0 flex-col items-start justify-start gap-1.5 self-stretch">
                        <div
                          className="relative text-left text-gray-700"
                          style={{
                            font: "var(--textsm-medium, 500 14px/20px 'Inter', sans-serif)",
                          }}
                        >
                          Email{' '}
                        </div>
                        <div
                          className="bg-base-white relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch overflow-hidden rounded-lg border border-solid border-gray-300 pt-2.5 pr-3.5 pb-2.5 pl-3.5"
                          style={{
                            boxShadow:
                              'var(--shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05))',
                          }}
                        >
                          <div className="relative flex flex-1 flex-row items-center justify-start gap-2">
                            <div
                              className="relative flex-1 text-left text-gray-500"
                              style={{
                                font: "var(--textmd-normal, 400 16px/24px 'Inter', sans-serif)",
                              }}
                            >
                              text here{' '}
                            </div>
                          </div>
                          <div className="relative h-4 w-4 shrink-0">
                            <svg
                              className="absolute left-0 top-0 overflow-visible"
                              style={{}}
                              width="16"
                              height="16"
                            ></svg>
                          </div>
                        </div>
                      </div>
                      <div
                        className="relative self-stretch text-left text-gray-500"
                        style={{
                          font: "var(--textsm-normal, 400 14px/20px 'Inter', sans-serif)",
                        }}
                      >
                        This is a hint text to help user.{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
