import { cloneDeep } from 'lodash-es';

import { PayerAndPayerSettings } from '../payers.slice';
import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';
import { pickupBufferSecondsToMinutes } from './payerPickupBufferSecondsToMinutes';
import { payerSignatureToPayerSignatureOption } from './payerSignatureToPayerSignatureOption';

export const assignDefaultFormValues = (
  payerOrg: PayerAndPayerSettings | undefined
): FormValues => {
  if (!payerOrg) return defaultFormValues;

  const payer = cloneDeep(payerOrg);

  return {
    id: payer.id || defaultFormValues.id,
    display_name: payer.display_name || defaultFormValues.display_name,
    alternate_name: payer.alternate_name || defaultFormValues.alternate_name,
    is_active: payer.is_active ?? defaultFormValues.is_active,
    external_id: payer.external_id || defaultFormValues.external_id,
    website: payer.website || defaultFormValues.website,
    ein: payer.ein || defaultFormValues.ein,
    email: payer.email || defaultFormValues.email,
    phone_number: payer.phone_number || defaultFormValues.phone_number,
    billing_address:
      payer.billing_address?.location || defaultFormValues.billing_address,
    billing_name: payer.billing_name || defaultFormValues.billing_name,
    billing_phone_number:
      payer.billing_phone_number || defaultFormValues.billing_phone_number,
    billing_email: payer.billing_email || defaultFormValues.billing_email,
    default_trip_address:
      payer.default_trip_address?.location ||
      defaultFormValues.default_trip_address,
    dispatcher_notes:
      payer.dispatcher_notes || defaultFormValues.dispatcher_notes,
    admin_notes: payer.admin_notes || defaultFormValues.admin_notes,
    pickup_default_early_seconds: pickupBufferSecondsToMinutes(
      payer._pickup_default_early_seconds
    ),
    pickup_default_late_seconds: pickupBufferSecondsToMinutes(
      payer._pickup_default_late_seconds
    ),
    pickup_appointment_early_seconds: pickupBufferSecondsToMinutes(
      payer._pickup_appointment_early_seconds
    ),
    pickup_appointment_late_seconds: pickupBufferSecondsToMinutes(
      payer._pickup_appointment_late_seconds
    ),
    pickup_will_call_early_seconds: pickupBufferSecondsToMinutes(
      payer._pickup_will_call_early_seconds
    ),
    pickup_will_call_late_seconds: pickupBufferSecondsToMinutes(
      payer._pickup_will_call_late_seconds
    ),
    extra_scheduled_pickup_at_seconds: pickupBufferSecondsToMinutes(
      payer._extra_scheduled_pickup_at_seconds
    ),
    invoice_due_after_days:
      payer.invoice_due_after_days ?? defaultFormValues.invoice_due_after_days,
    requires_signature_pickup_passenger: payerSignatureToPayerSignatureOption(
      payer._requires_signature_pickup_passenger
    ),
    requires_signature_dropoff_passenger: payerSignatureToPayerSignatureOption(
      payer._requires_signature_dropoff_passenger
    ),
    requires_signature_pickup_driver: payerSignatureToPayerSignatureOption(
      payer._requires_signature_pickup_driver
    ),
    requires_signature_dropoff_driver: payerSignatureToPayerSignatureOption(
      payer._requires_signature_dropoff_driver
    ),
    type: payer.type || defaultFormValues.type,
    payer: payer.payer ? { value: payer.payer, label: payer.payer } : null,
    can_multiload: payer.can_multiload ?? defaultFormValues.can_multiload,
    multiloading_partners: payer.multiloading_partners || [],
  };
};
