import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { emptyDefaultPricingModelDetailsDraft } from './management/SelectedPricingModelDetails/emptyDefaultPricingModelDetailsDraft';
import { onPricingModelDetailsEdit } from './pricingModel.slice';

export function AddPricingModelButton() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const pricingModelDetailsDraft = useAppSelector((state) => {
  //   return state.pricingModel.pricingModelDetailsDraft;
  // });
  return (
    <Button
      // disabled={!!pricingModelDetailsDraft}
      disabled
      className="flex-grow"
      onClick={() => {
        navigate('/pricing/pricing-models/new');
        dispatch(
          onPricingModelDetailsEdit({ ...emptyDefaultPricingModelDetailsDraft })
        );
      }}
      dataTestId="add-pricing-model-button"
      variant="primary"
    >
      <PlusIcon className="h-4 w-4" /> Add Pricing Model
    </Button>
  );
}
