import { ClockIcon } from '@heroicons/react/24/outline';

import { Badge } from 'src/common/primitives/Badge';
import { CarIcon } from 'src/common/primitives/icon/CarIcon';

import { AssignedDriverRead } from './external/bambi-api/bambiApi';

export function DriverBadge({ driver }: { driver: AssignedDriverRead }) {
  return (
    <Badge
      icon={
        <>
          <CarIcon />
          {driver.is_currently_clocked_in && (
            <ClockIcon data-testid="clock-icon" className="h-5 w-5" />
          )}
        </>
      }
      label={`${driver.first_name} ${driver.last_name}`}
      color="primary-inverse"
    />
  );
}
