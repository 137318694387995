import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';

import { RootState, useAppSelector } from 'src/app/store';
import {
  useAttendantsListQuery,
  useDriversListQuery,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';
import { FormCancelButton } from 'src/common/FormCancelButton';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { assignDefaultFormValues } from './assignDefaultFormValues';
import { AssignmentCloneFlow } from './AssignmentCloneFlow';
import { AssignmentFormStep1CreateOrEdit } from './AssignmentFormStep1CreateOrEdit';
import { FormErrors } from './FormErrors';
import { FormValues } from './FormValues';
import { SchedulableVehicleSelect } from './SchedulableVehicleSelect';
import { useAssignmentFormOnSubmit } from './useAssignmentFormOnSubmit';
import { useSchedulableVehicle } from './useSchedulableVehicle';

export function AssignmentManagementFormStep1({
  onCancel,
  children,
  serverErrors,
}: {
  onCancel: () => void;
  children: React.ReactNode;
  serverErrors: string | null;
}) {
  const {
    selectedVehicle,
    selectedAssignment,
    error,
    isSubmitting,
    assignmentToClone,
  } = useAppSelector((state: RootState) => state.assignmentManagement);

  const { vehicle, isLoading } = useSchedulableVehicle(
    selectedVehicle?.vehicle || selectedAssignment?.vehicle.vehicle
  );

  // Prefetch drivers and attendants and track loading state
  // This makes this load much less janky
  const { isLoading: driversLoading } = useDriversListQuery({
    canBeScheduled: true,
  });
  const { isLoading: attendantsLoading } = useAttendantsListQuery({
    canBeScheduled: true,
  });

  if (driversLoading || attendantsLoading || isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <AssignmentManagementFormStep1FormWrapper resolvedVehicle={vehicle}>
      <FormErrors error={error} serverErrors={serverErrors} />
      <div className="mt-4 flex max-w-xl flex-col gap-4">
        {!!assignmentToClone && <AssignmentCloneFlow />}
        <SchedulableVehicleSelect />

        {vehicle && (
          <div className="relative flex flex-col gap-y-6">
            <AssignmentFormStep1CreateOrEdit />
          </div>
        )}
      </div>
      {children}
      <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <FormCancelButton
          className="sm:col-span-3"
          onCancel={onCancel}
          isSubmitting={isSubmitting}
        />
        <FormSubmitButton className="sm:col-span-3" buttonName="Save" />
      </div>
    </AssignmentManagementFormStep1FormWrapper>
  );
}

function AssignmentManagementFormStep1FormWrapper({
  children,
  resolvedVehicle,
}: {
  children: React.ReactNode;
  resolvedVehicle?: VehicleRead;
}) {
  const { selectedDay, selectedAssignment } = useAppSelector(
    (state: RootState) => state.assignmentManagement
  );

  const selectedDayOrToday = selectedDay || DateTime.local().toISODate();

  const methods = useForm<FormValues>({
    defaultValues: assignDefaultFormValues(
      selectedDayOrToday,
      resolvedVehicle,
      selectedAssignment
    ),
    mode: 'onTouched',
  });

  const submit = useAssignmentFormOnSubmit({
    getValues: methods.getValues,
  });

  return (
    <FormProvider {...methods}>
      <form
        className="p-4"
        onSubmit={methods.handleSubmit(submit)}
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        {children}
      </form>
    </FormProvider>
  );
}
