import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import {
  PayerOrganizationRead,
  TripUpdateRead,
  usePayersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useAuth } from 'src/features/auth/useAuth';

import { DefaultChangeValueRenderer } from './DefaultChangeValueRenderer';
import { DefaultChangeValuesLine } from './DefaultChangeValuesLine';

function pluckPayerNameById(payers: PayerOrganizationRead[], id: string) {
  return payers.find((result) => result.id === id)?.payer_name;
}
export function TripPayerChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const auth = useAuth();
  const { data: payers, isLoading } = usePayersListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
  });
  if (isLoading) return <LoadingIndicator />;
  if (!payers?.results) {
    return (
      <div>
        <div>Couldn't load payer details. The ids changed:</div>
        <DefaultChangeValuesLine tripEvent={tripEvent} valueKey={valueKey} />;
      </div>
    );
  }

  const oldPayerId = tripEvent.values_changed?.[valueKey].old_value || '';
  const oldPayer = pluckPayerNameById(payers.results, oldPayerId);

  const newPayerId = tripEvent.values_changed?.[valueKey].new_value || '';
  const newPayer = pluckPayerNameById(payers.results, newPayerId);
  return (
    <div className="flex items-center gap-1">
      <DefaultChangeValueRenderer
        value={oldPayer || `Unknown payer id: ${oldPayerId}`}
      />
      <ArrowLongRightIcon className="h-5 w-5" />
      <DefaultChangeValueRenderer
        value={newPayer || `Unknown payer id: ${newPayerId}`}
      />
    </div>
  );
}
