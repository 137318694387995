import { PencilIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { Tag } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';

import { setSelectedTag, setShowTagsModal } from '../../setting.slice';

export function RowActions({ tag }: { tag: Tag }) {
  const dispatch = useAppDispatch();

  function handleEditTag() {
    dispatch(setSelectedTag({ ...tag }));
    dispatch(setShowTagsModal(true));
  }

  return (
    <Button
      className="group cursor-pointer"
      variant="ghost"
      onClick={() => handleEditTag()}
      ariaLabel="Edit"
    >
      <PencilIcon className="w-[24px] group-hover:text-mint" />
    </Button>
  );
}
