import { truncate, upperFirst } from 'lodash-es';
import { DateTime } from 'luxon';

import { PatchedTripPassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { Popover } from 'src/common/primitives/Popover';
import { FieldDisplay } from 'src/features/dispatch/DispatchCalendar/TripCalendarEvent/FieldDisplay';
import { PhoneNumberLink } from 'src/features/dispatch/DispatchCalendar/TripCalendarEvent/PhoneNumberLink';
export function PassengerPopover({
  passenger,
}: {
  passenger: PatchedTripPassengerRead;
}) {
  const formatDateOfBirth = (dob: string) => {
    return DateTime.fromISO(dob).toLocaleString(DateTime.DATE_MED);
  };

  return (
    <div className="h-full">
      <Popover
        trigger={
          <div
            data-testid="passenger-popover-trigger"
            className="cursor-pointer"
          >
            {passenger.full_name}
          </div>
        }
        content={
          <div
            data-testid="passenger-popover-content"
            className="flex max-w-[35em] flex-col gap-2"
          >
            <div className="flex flex-col gap-1">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {truncate(passenger.full_name, { length: 30, omission: '...' })}
              </h3>
            </div>
            {passenger.dob && (
              <div>
                <span className="text-sm text-gray-500">
                  <FieldDisplay
                    value={formatDateOfBirth(passenger.dob)}
                    label="Date of Birth"
                  />
                </span>
              </div>
            )}
            {passenger.phone_number && (
              <div>
                <h4 className="text-sm font-medium text-gray-500">Phone</h4>
                <p className="text-sm text-gray-500">
                  <PhoneNumberLink phoneNumber={passenger.phone_number} />
                </p>
              </div>
            )}
            {passenger.email && (
              <div>
                <span className="text-sm text-gray-500">
                  <FieldDisplay value={passenger.email} label="Email" />
                </span>
              </div>
            )}
            {passenger.gender && (
              <div>
                <span className="text-sm text-gray-500">
                  <FieldDisplay
                    value={upperFirst(passenger.gender)}
                    label="Gender"
                  />
                </span>
              </div>
            )}
            {passenger.weight && (
              <div>
                <span className="text-sm text-gray-500">
                  <FieldDisplay
                    value={passenger.weight.toString()}
                    label="Weight"
                  />
                </span>
              </div>
            )}
            {passenger.needs_bariatric_transport !== undefined && (
              <div>
                <span className="text-sm text-gray-500">
                  <FieldDisplay
                    value={passenger.needs_bariatric_transport ? 'Yes' : 'No'}
                    label="Needs Bariatric Transport"
                  />
                </span>
              </div>
            )}

            {passenger.notes && (
              <div>
                <span className="text-sm text-gray-500">
                  <FieldDisplay
                    value={passenger.notes}
                    label="Passenger Notes"
                  />
                </span>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}
