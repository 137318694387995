import { truncate } from 'lodash-es';

import { removeUnitedStatesFromAddress } from './util/removeUnitedStatesFromAddress';

const googleMapsBaseUrl = 'https://www.google.com/maps/search/?api=1&query=';

const googleMapsUrl = (address: string) => {
  return googleMapsBaseUrl + encodeURIComponent(address);
};

function formatGoogleMapsAddressCopy(address: string) {
  const formattedAddress = removeUnitedStatesFromAddress(address);

  return truncate(formattedAddress, {
    length: 50,
    omission: '...',
  });
}

export function GoogleMapsAddressLink({
  address,
  className,
}: {
  address: string;
  className?: string;
}) {
  const truncatedAddress = formatGoogleMapsAddressCopy(address);
  const formattedAddress = removeUnitedStatesFromAddress(address);

  const linkClass = className
    ? className
    : 'font-medium text-gray-500 hover:underline dark:text-gray-400';

  return (
    <a
      href={googleMapsUrl(formattedAddress)}
      className={linkClass}
      target="_blank"
      rel="noopener noreferrer"
    >
      {truncatedAddress}
    </a>
  );
}
