import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { DefaultChangeValuesLine } from './changeValueRenderers/DefaultChangeValuesLine';
import { TripAddressChangeValue } from './changeValueRenderers/TripAddressChangeValue';
import { TripEstimatedDistanceMilesChangeValue } from './changeValueRenderers/TripEstimatedDistanceMilesChangeValue';
import { TripLongTextChangeValue } from './changeValueRenderers/TripLongTextChangeValue';
import { TripOxygenAmountChangeValue } from './changeValueRenderers/TripOxygenAmountChangeValue';
import { TripPayerChangeValue } from './changeValueRenderers/TripPayerChangeValue';
import { TripPhoneChangeValue } from './changeValueRenderers/TripPhoneChangeValue';
import { TripPriceInCentsChangeValue } from './changeValueRenderers/TripPriceInCentsChangeValue';
import { TripSecondsToMinutesChangeValue } from './changeValueRenderers/TripSecondsToMinutesChangeValue';
import { TripServiceTypeChangeValue } from './changeValueRenderers/TripServiceTypeChangeValue';
import { TripSpaceTypeChangeValue } from './changeValueRenderers/TripSpaceTypeChangeValue';
import { TripStatusChangeValue } from './changeValueRenderers/TripStatusChangeValue';
import { TripTimeChangeValue } from './changeValueRenderers/TripTimeChangeValue';

export function TripSummaryUpdateValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  if (valueKey === 'estimated_distance_miles') {
    return (
      <TripEstimatedDistanceMilesChangeValue
        tripEvent={tripEvent}
        valueKey={valueKey}
      />
    );
  }

  if (valueKey.includes('phone')) {
    return <TripPhoneChangeValue tripEvent={tripEvent} valueKey={valueKey} />;
  }

  if (valueKey.includes('address')) {
    return <TripAddressChangeValue tripEvent={tripEvent} valueKey={valueKey} />;
  }

  if (valueKey === 'pickup_wait_time_seconds') {
    return (
      <TripSecondsToMinutesChangeValue
        tripEvent={tripEvent}
        valueKey={valueKey}
      />
    );
  }

  if (valueKey === 'dispatcher_notes') {
    return (
      <TripLongTextChangeValue tripEvent={tripEvent} valueKey={valueKey} />
    );
  }

  if (valueKey === 'oxygen_milliliters_per_min') {
    return (
      <TripOxygenAmountChangeValue tripEvent={tripEvent} valueKey={valueKey} />
    );
  }

  if (
    valueKey === 'input_price_cents' ||
    valueKey === 'final_calculated_price_cents' ||
    valueKey === 'initial_calculated_price_cents'
  ) {
    return (
      <TripPriceInCentsChangeValue tripEvent={tripEvent} valueKey={valueKey} />
    );
  }

  if (
    valueKey === 'appointment_at' ||
    valueKey === 'scheduled_pickup_at' ||
    valueKey.includes('time')
  ) {
    return <TripTimeChangeValue tripEvent={tripEvent} valueKey={valueKey} />;
  }

  if (valueKey === 'service_type') {
    return (
      <TripServiceTypeChangeValue tripEvent={tripEvent} valueKey={valueKey} />
    );
  }

  if (valueKey === 'space_type') {
    return (
      <TripSpaceTypeChangeValue tripEvent={tripEvent} valueKey={valueKey} />
    );
  }

  if (valueKey === 'payer_id') {
    return <TripPayerChangeValue tripEvent={tripEvent} valueKey={valueKey} />;
  }

  if (valueKey === 'status' && tripEvent.values_changed?.[valueKey].new_value) {
    return <TripStatusChangeValue tripEvent={tripEvent} valueKey={valueKey} />;
  }

  return <DefaultChangeValuesLine tripEvent={tripEvent} valueKey={valueKey} />;
}
