import {
  DispatchTripTagRead,
  TripTripTagRead,
} from '../external/bambi-api/bambiApi';
import { getTextColorByBgColor } from '../util/getTextColorByBgColor';

interface TagProps {
  label: string;
  color: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}
export function Tag({ label, color, icon, onClick }: TagProps) {
  const textColor = getTextColorByBgColor(color);
  return (
    <div
      className={`flex rounded-full px-2.5 py-1 text-xs font-medium ${textColor}`}
      style={{ backgroundColor: color }}
      data-testid="tag"
    >
      {label.length > 20 ? `${label.slice(0, 20)}...` : label}
      {icon && (
        <span className="ml-1" onClick={onClick}>
          {icon}
        </span>
      )}
    </div>
  );
}

const isTripTipTag = (
  tag: TripTripTagRead | DispatchTripTagRead
): tag is TripTripTagRead => 'id' in tag;

interface TripTagProps {
  tag: TripTripTagRead | DispatchTripTagRead;
  icon?: React.ReactNode;
  onIconClick?: (tag: TripTripTagRead) => void;
}

export function TripTag({ tag, icon, onIconClick }: TripTagProps) {
  return (
    <Tag
      color={tag.color_hex}
      label={tag.tag_name}
      icon={icon}
      onClick={() => isTripTipTag(tag) && onIconClick?.(tag)}
    />
  );
}
