import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { AssignmentDayEditor } from 'src/features/team/AssignmentManagementForm/AssignmentDayEditor';

import { AssignmentWeekEditor } from './AssignmentWeekEditor';

export function AssignmentFormStep1CreateOrEdit() {
  const { selectedVehicle, selectedAssignment } = useSelector(
    (state: RootState) => state.assignmentManagement
  );

  if (selectedAssignment) {
    return (
      <AssignmentDayEditor day={DateTime.fromISO(selectedAssignment.start)} />
    );
  }

  if (selectedVehicle) {
    return <AssignmentWeekEditor />;
  }

  // Should never see this in the form, but this is here to not make an assumption about the consumer of this component
  return <span>An assignment or vehicle must be selected to edit</span>;
}
