import { PencilIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { onPricingModelDetailsEdit } from 'src/features/pricingModel/pricingModel.slice';

import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';

export function EditPriceModelButton() {
  const dispatch = useAppDispatch();
  const selectedPricingModel = useSelectedPricingModel();
  if (!selectedPricingModel) {
    return (
      <div>Didn't find a selected pricing model, which shouldn't happen.</div>
    );
  }

  return (
    <Button
      className="flex gap-1"
      onClick={() => {
        dispatch(
          onPricingModelDetailsEdit({
            ...selectedPricingModel,
          })
        );
      }}
    >
      <PencilIcon className="h-5 w-5" /> Edit
    </Button>
  );
}
