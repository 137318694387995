import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import { isStringValidDateTime } from 'src/common/isStringValidDateTime';

export function formatTimeChangeData(time: string) {
  if (isStringValidDateTime(time)) {
    return formatTimeFrom24HourTo12Hour(time);
  }

  return 'None';
}
