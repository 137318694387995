import { CheckedState } from '@radix-ui/react-checkbox';
import { useDispatch } from 'react-redux';

import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';

import { setSelectedUnassignedTripIds } from '../../dispatcher.slice';

export function useSelectAllUnassignedTrips() {
  const dispatch = useDispatch();

  return (isChecked: CheckedState, trips: DispatchTripRead[]) => {
    const ids = isChecked ? trips.map((trip) => trip.id) : [];
    dispatch(setSelectedUnassignedTripIds(ids));
  };
}
