import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  PricingModelRead,
  usePricingPricingTypesDestroyMutation,
} from 'src/common/external/bambi-api/bambiApi';

export function useDeletePricingType(
  pricingModel: PricingModelRead | undefined
) {
  const navigate = useNavigate();
  const [deletePricingType] = usePricingPricingTypesDestroyMutation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState('');

  const handleDelete = async () => {
    if (deleteId) {
      try {
        setDeleteError('');
        await deletePricingType({ id: deleteId }).unwrap();

        setDeleteId(null);
        setShowDeleteModal(false);

        if (pricingModel) {
          navigate(`/pricing/pricing-models/${pricingModel.id}`);
        }
      } catch (error) {
        setDeleteError(
          'There was an error deleting this pricing type. Please try again.'
        );
      }
    }
  };

  const resetModal = () => {
    setDeleteId(null);
    setDeleteError('');
    setShowDeleteModal(false);
  };

  return {
    showDeleteModal,
    setShowDeleteModal,
    setDeleteId,
    deleteError,
    handleDelete,
    resetModal,
  };
}
