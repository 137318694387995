import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/app/store';
import { PatchedTrip } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { addATrip } from '../addATrip.slice';
import { adaptFormValuesToUpdate } from './adaptFormValuesToUpdate';
import { getTripLinkToPayer } from './getTripLinkToPayer';
import { useTripRepository } from './useTripRepository';
import { validateFormInputs } from './validateFormInputs';

export function useUpdateATripOnSubmit({
  getValues,
}: {
  getValues: () => any;
}) {
  const { isRideOrderingPortalUser } = useUserRoles();
  const dispatch = useAppDispatch();
  const { updateTrip } = useTripRepository();
  const nextToastId = useSelector((state: RootState) => state.toast.nextId);
  const isSelectedTripInThePast = useIsSelectedTripInThePast();

  return async (values: any) => {
    try {
      dispatch(addATrip.actions.submit());
      const currentValues = getValues();

      const formErrors = validateFormInputs(currentValues);

      if (formErrors.length > 0) {
        dispatch(addATrip.actions.onError(formErrors));
        return;
      }

      // TODO: Need to create the adapter for past trips so we don't send fields we don't want the user to update
      const adaptedUpdateRequest: PatchedTrip = isSelectedTripInThePast
        ? adaptFormValuesToUpdate(currentValues)
        : adaptFormValuesToUpdate(currentValues);

      const tripResponse: any = await updateTrip(currentValues.id, {
        ...adaptedUpdateRequest,
        link_to_payer: getTripLinkToPayer(
          !!isRideOrderingPortalUser,
          currentValues.should_link_passenger_to_payer
        ),
      });

      if (!tripResponse) {
        if (tripResponse.error.data) {
          dispatch(addATrip.actions.onError(tripResponse.error.data.errors));
          dispatch(
            show({
              id: nextToastId,
              title: 'Error updating trip',
              description: ' Please review trip details',
              type: 'error',
            })
          );
          return;
        } else {
          dispatch(addATrip.actions.onError([]));
          dispatch(
            show({
              id: nextToastId,
              title: 'Unexpected server error',
              description: `Status: ${tripResponse.error.originalStatus}, Error: ${tripResponse.error.error} -- Please contact support`,
              type: 'error',
            })
          );
          return;
        }
      }
      dispatch(addATrip.actions.close());

      dispatch(
        show({
          id: nextToastId,
          title: 'Trip updated successfully!',
          type: 'success',
        })
      );
    } catch (e) {
      // TODO: Send error details to datadog
      const error = formatServerError(e);
      dispatch(
        addATrip.actions.onError([
          {
            attr: 'Form Error',
            code: 'general',
            detail: error,
          },
        ])
      );
    }
  };
}
