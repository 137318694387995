import { useState } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import * as RadixPopover from '@radix-ui/react-popover';
import { Link } from 'react-router-dom';

import { useUserRoles } from 'src/features/auth/useUserRoles';

import {
  FilterGroup as FilterGroupType,
  FiltersetEnum,
  useFilterGroupsListQuery,
} from '../external/bambi-api/bambiApi';
import { FilterPopover } from '../primitives/FilterPopover/FilterPopover';
import { FilterCountBadge } from '../primitives/FilterPopover/FilterPopoverTrigger';
import { FilterGroupManageModal } from './FilterGroupManageModal';
import { FilterGroupTriggerButton } from './FilterGroupTriggerButton';

interface FilterGroupProps {
  filterset: FiltersetEnum;
  filters: FilterGroupType['filters'];
  onSelectFilterGroup: (group: FilterGroupType) => void;
}

export function FilterGroup({
  filterset,
  filters,
  onSelectFilterGroup,
}: FilterGroupProps) {
  const { isRideOrderingPortalUser } = useUserRoles();
  const { data } = useFilterGroupsListQuery(
    {},
    {
      skip: isRideOrderingPortalUser,
    }
  );

  const [addModalOpen, setAddModalOpen] = useState(false);

  return (
    <>
      {!isRideOrderingPortalUser && (
        <div className="flex flex-row">
          <FilterPopover
            triggerElement={
              <FilterGroupTriggerButton
                onAddClick={() => setAddModalOpen(true)}
              />
            }
            postFooterElement={
              <Link
                to="/settings/filter-groups"
                className="col-span-2 flex flex-row items-center justify-center text-sm italic text-slate-500"
              >
                <PencilIcon className="h-4 w-4" />
                Manage Filter Groups
              </Link>
            }
            includePrimaryAction={false}
          >
            <div className="flex w-full flex-col gap-2 pb-2">
              {data?.results
                .filter((item) => item.filterset === filterset)
                .map((item) => {
                  return (
                    <RadixPopover.Close
                      key={item.id}
                      className="group cursor-pointer hover:bg-mint-light"
                      onClick={() => {
                        onSelectFilterGroup(item);
                      }}
                    >
                      <div className="flex flex-row justify-between">
                        <div className="text-sm text-gray-800">
                          {item.group_name}
                        </div>
                        <FilterCountBadge
                          value={
                            Object.keys(item.filters).filter(
                              (k) => typeof item.filters[k] !== 'undefined'
                            ).length
                          }
                          positioned={false}
                        />
                      </div>

                      <div className="text-left text-sm text-gray-400 group-hover:text-indigo">
                        {item.description}
                      </div>
                    </RadixPopover.Close>
                  );
                })}
            </div>
          </FilterPopover>
          <FilterGroupManageModal
            open={addModalOpen}
            setOpen={setAddModalOpen}
            filterset={filterset}
            filters={filters}
          />
        </div>
      )}
    </>
  );
}
