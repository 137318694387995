import { AssignmentLocationRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

import { MemberPhoneNumberLink } from './MemberPhoneNumberLink';
import { PeopleIcon } from './PeopleIcon';
import { PhoneIcon } from './PhoneIcon';

export function AssignmentPopupAttendants({
  assignmentLocation,
}: {
  assignmentLocation: AssignmentLocationRead;
}) {
  return (
    <div className="flex flex-col items-start justify-start gap-[6px]">
      <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-teal-50 py-[3px] px-2">
        <div className="flex flex-row items-center justify-start">
          <div className="relative font-medium">Attendants</div>
        </div>
      </div>
      {assignmentLocation.attendants.map((attendant) => (
        <div
          key={attendant.membership}
          className="flex flex-col items-start justify-start gap-[4px] text-left text-sm text-gray-600"
        >
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <PeopleIcon />
            <div className="relative inline-block shrink-0 font-medium">
              {formatFullName(attendant)}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[4px] text-xs">
            <PhoneIcon />
            <MemberPhoneNumberLink memberId={attendant.membership} />
          </div>
        </div>
      ))}
    </div>
  );
}
