import { FlagIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/primitives/Button';
import { addATrip } from 'src/features/add-trip/addATrip.slice';

export function TripEmptyState() {
  const dispatch = useDispatch();
  return (
    <div className="flex justify-center px-[24px] py-12">
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <FlagIcon className="mx-auto h-6 w-auto text-gray-700" />
        </div>
        <h4 className="mt-3 text-gray-700">Add a trip</h4>
        <p className="text-gray-400">
          There is currently no trip to show here.
        </p>
        <div className="mt-3 text-gray-700">
          <Button
            variant="primary"
            onClick={() => dispatch(addATrip.actions.open())}
          >
            Add Trip
          </Button>
        </div>
      </div>
    </div>
  );
}
