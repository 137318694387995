import { useState } from 'react';

import { usePricingPricingSchemesRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Wizard } from 'src/common/wizard/Wizard';

import { PricingWizardSchemeConfigurationStep } from './PricingWizardSchemeConfigurationStep';
import {
  PricingWizardSchemeMetaStep,
  SchemeMetaFormValues,
} from './PricingWizardSchemeMetaStep';

export type PricingWizardMode = 'create' | 'edit';
export function PricingWizard({
  pricingSchemeId,
  onCancel,
  onSuccess,
  mode = 'create',
}: {
  pricingSchemeId?: string;
  onCancel: () => void;
  onSuccess: () => void;
  mode: PricingWizardMode;
}) {
  const [workInProgressScheme, setWorkInProgressScheme] = useState<
    SchemeMetaFormValues | undefined
  >();
  const { data, isLoading } = usePricingPricingSchemesRetrieveQuery(
    {
      id: pricingSchemeId || '',
    },
    {
      skip: mode === 'create' || !pricingSchemeId,
    }
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Wizard
      steps={[
        {
          title: 'Base Configuration',
          render: ({ next }) => (
            <PricingWizardSchemeMetaStep
              originalPricingSchemeValues={data}
              initialValues={workInProgressScheme}
              onCancel={() => {
                onCancel();
              }}
              onSubmit={(values) => {
                setWorkInProgressScheme(values);
                next();
              }}
            />
          ),
        },
        {
          title: `${workInProgressScheme?.name}`,
          render: () => (
            <PricingWizardSchemeConfigurationStep
              originalPricingSchemeValues={data}
              onCancel={() => {
                onCancel();
              }}
              onSubmit={() => {
                onSuccess();
              }}
              workInProgressScheme={
                workInProgressScheme as SchemeMetaFormValues
              }
              mode={mode}
            />
          ),
        },
      ]}
    />
  );
}
