import { BanknotesIcon } from '@heroicons/react/24/outline';

import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

export function PayoutsEmptyState() {
  return (
    <EmptyState
      entityName="Payouts"
      Icon={<BanknotesIcon className="w-[24px]" />}
      description="No payouts found"
      Title={<></>}
    />
  );
}
