import SecondsToMinutesField from 'src/common/SecondsToMinutesField';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function LoadUnloadSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Load/Unload Times"
        description="Organization load/unload times in minutes."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SecondsToMinutesField
            fieldPath={`ambulatory_load_seconds`}
            label="Ambulatory Load"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`ambulatory_unload_seconds`}
            label="Ambulatory Unload"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`broda_chair_load_seconds`}
            label="Broda Chair Load"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`broda_chair_unload_seconds`}
            label="Broda Chair Unload"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`geri_chair_load_seconds`}
            label="Geri Chair Load"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`geri_chair_unload_seconds`}
            label="Geri Chair Unload"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`stretcher_load_seconds`}
            label="Stretcher Load"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`stretcher_unload_seconds`}
            label="Stretcher Unload"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`wheelchair_load_seconds`}
            label="Wheelchair Load"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`wheelchair_unload_seconds`}
            label="Wheelchair Unload"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`wheelchair_xl_load_seconds`}
            label="Wheelchair XL Load"
          />
        </div>
        <div>
          <SecondsToMinutesField
            fieldPath={`wheelchair_xl_unload_seconds`}
            label="Wheelchair XL Unload"
          />
        </div>
      </div>
    </div>
  );
}
