import * as ToastPrimitive from '@radix-ui/react-toast';

import { ToastRenderer } from './ToastRenderer';
import { ToastViewport } from './ToastViewport';

export function ToastConfigProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ToastPrimitive.Provider>
      {children}
      <ToastRenderer />
      <ToastViewport />
    </ToastPrimitive.Provider>
  );
}
