import { useEffect, useState } from 'react';

import { map } from 'lodash-es';

import {
  TripRead,
  useTripsCancelCodesRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';
import {
  CANCEL_REASON,
  CANCEL_REASONS_OPTIONS,
} from 'src/features/dispatch/DispatchCalendar/TripCalendarEvent/EventMenu/cancellationReasons';

export function useCancellationOptions(selectedTrip: TripRead | null) {
  const [cancellationReasonOptions, setCancellationReasonOptions] = useState<
    SelectOption[]
  >([]);
  const [isKineticTrip, setIsKineticTrip] = useState(false);

  const { data } = useTripsCancelCodesRetrieveQuery(
    {
      id: selectedTrip?.id ? selectedTrip.id : '',
    },
    {
      skip: !selectedTrip?.id,
    }
  );

  useEffect(() => {
    if (selectedTrip?.payer.has_kinetik_account && data?.cancel_codes) {
      const noShowOption = CANCEL_REASONS_OPTIONS.find(
        (option) => option.value === CANCEL_REASON.NO_SHOW
      );

      const cancelOptions = [
        ...map(data.cancel_codes, (key, val) => ({
          label: key, // Assuming code itself is the label, adjust as needed
          value: val,
        })),
      ];

      if (noShowOption) {
        cancelOptions.unshift(noShowOption);
      }

      setCancellationReasonOptions(cancelOptions);
      setIsKineticTrip(true);
    } else {
      setCancellationReasonOptions(CANCEL_REASONS_OPTIONS);
      setIsKineticTrip(false);
    }
  }, [data, selectedTrip?.payer.has_kinetik_account]);

  return { cancellationReasonOptions, isKineticTrip };
}
