import { useCallback, useState } from 'react';

import {
  ExclamationTriangleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import {
  TilledPaymentMethodResponse,
  useTilledPaymentMethodDestroyMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';

import { Button } from '../Button';
import { Card } from '../Card/Card';
import { CreditCardBrand } from '../icon/CreditCardBrand/CreditCardBrand';
import { show } from '../Toast/toast.slice';
import { CreditCardPaymentMethod } from './CreditCardPaymentMethod';

export interface PaymentMethodListProps {
  paymentMethods: TilledPaymentMethodResponse[];
  selectedPaymentMethodId?: string;
  onRemovePaymentMethod?: (id: string) => void;
  onSelectPaymentMethod?: (id: string) => void;
}

export function PaymentMethodList({
  paymentMethods,
  selectedPaymentMethodId,
  onRemovePaymentMethod,
  onSelectPaymentMethod,
}: PaymentMethodListProps) {
  const [destroyPaymentMethod] = useTilledPaymentMethodDestroyMutation();
  const dispatch = useDispatch();
  const [isConfirmingDeletePaymentMethod, setIsConfirming] = useState(false);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<string>();
  const [deleteError, setDeleteError] = useState<string>();
  const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);

  const handleDeletePaymentMethod = useCallback(async () => {
    if (!paymentMethodToDelete) {
      return;
    }

    setDeletingPaymentMethod(true);

    try {
      await destroyPaymentMethod({
        paymentMethodId: paymentMethodToDelete,
      }).unwrap();
      onRemovePaymentMethod?.(paymentMethodToDelete);
      dispatch(
        show({
          type: 'success',
          title: 'Payment method removed',
        })
      );
      setIsConfirming(false);
    } catch (e: any) {
      setDeleteError(e.message || e.error);
    } finally {
      setDeletingPaymentMethod(false);
    }
  }, [
    destroyPaymentMethod,
    onRemovePaymentMethod,
    paymentMethodToDelete,
    dispatch,
  ]);

  return (
    <div className="flex flex-col gap-3" data-testid="payment-methods-list">
      {!paymentMethods.length && (
        <div
          data-testid="payment-methods-empty-state"
          className="flex justify-center bg-gray-100 p-3 text-sm"
        >
          No payment methods yet, add one below
        </div>
      )}
      {paymentMethods.map((paymentMethod) => {
        return (
          <Card
            key={paymentMethod.id}
            className={`${
              paymentMethod.id === selectedPaymentMethodId ? 'border-mint' : ''
            }`}
            aria-selected={paymentMethod.id === selectedPaymentMethodId}
            data-testid={
              paymentMethod.id === selectedPaymentMethodId
                ? 'payment-method-selected'
                : 'payment-method'
            }
            onClick={() => {
              if (!onSelectPaymentMethod) {
                return;
              }

              onSelectPaymentMethod(paymentMethod.id);
            }}
          >
            <div className="flex flex-row justify-between gap-2">
              <CreditCardPaymentMethod
                id={paymentMethod.id}
                brand={paymentMethod.card.brand as CreditCardBrand}
                last4={paymentMethod.card.last4}
                expMonth={paymentMethod.card.exp_month}
                expYear={paymentMethod.card.exp_year}
                holderName={paymentMethod.card.holder_name}
              />
              <Button
                variant="ghost"
                className="cursor-pointer hover:text-mint"
                dataTestId="delete-payment-method"
                onClick={(e) => {
                  e.stopPropagation();
                  setPaymentMethodToDelete(paymentMethod.id);
                  setIsConfirming(true);
                }}
              >
                <TrashIcon width={24} />
              </Button>
            </div>
          </Card>
        );
      })}
      <ConfirmModal
        open={isConfirmingDeletePaymentMethod}
        setOpen={(isOpen) => {
          if (!isOpen) setIsConfirming(false);
        }}
        onCancel={() => {
          setIsConfirming(false);
        }}
        onConfirm={() => {
          handleDeletePaymentMethod();
        }}
        cancelText="Cancel"
        confirmText="Delete"
        title="Delete payment method"
        description="Are you sure you want to delete this payment method? This action cannot be undone."
        error={deleteError}
        icon={<ExclamationTriangleIcon className="w-5" />}
        mode="destructive"
        loading={deletingPaymentMethod}
      />
    </div>
  );
}
