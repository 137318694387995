import { createSlice } from '@reduxjs/toolkit';
import { createSearchParams } from 'react-router-dom';

import {
  FilterGroup,
  InvoiceRead,
  OverallPaymentStatusEnum,
} from 'src/common/external/bambi-api/bambiApi';
import { NumberRangeValue } from 'src/common/primitives/NumberRangeFilterPopover/NumberRangeFilterPopover';
import { clearFiltersExtraReducer } from 'src/common/redux/clearFiltersExtraReducer';
import { searchParamsToJSON } from 'src/common/util/searchParamsToJSON';

import type { PayloadAction } from '@reduxjs/toolkit';

interface InvoiceState {
  editingInvoice?: InvoiceRead | null;
  showInvoiceModal: boolean;
  showPayModal: boolean;
  // editingLineItemGroup is the trip id
  editingLineItemGroup?: string;
  filterDateStart?: string;
  filterDateEnd?: string;
  filterHasPayments?: boolean;
  filterSearchTerm?: string;
  filterTripPayers?: string[];
  filterPaymentStatus?: OverallPaymentStatusEnum[];
  filterTotalAmount?: NumberRangeValue;
  filterTotalAmountDue?: NumberRangeValue;
  filterGroup?: FilterGroup;
}

export const initialState: InvoiceState = {
  editingInvoice: null,
  showInvoiceModal: false,
  showPayModal: false,
  editingLineItemGroup: '',
};

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    editInvoice(state, action: PayloadAction<InvoiceRead | null>) {
      state.editingInvoice = action.payload;
    },
    setInvoiceModalOpen(state, action: PayloadAction<boolean>) {
      state.showInvoiceModal = action.payload;
    },
    editLineItems(state, action: PayloadAction<string>) {
      state.editingLineItemGroup = action.payload;
    },
    setPayModalOpen(state, action: PayloadAction<boolean>) {
      state.showPayModal = action.payload;
    },
    resetEditing(state) {
      state.editingLineItemGroup = '';
      state.editingInvoice = null;
      state.showInvoiceModal = false;
    },
    setFilterDateRange(
      state,
      action: PayloadAction<{ rangeStart?: string; rangeEnd?: string }>
    ) {
      state.filterDateStart = action.payload.rangeStart;
      state.filterDateEnd = action.payload.rangeEnd;
    },
    setFilterHasPayments(state, action: PayloadAction<boolean>) {
      state.filterHasPayments = action.payload;
    },
    setFilterSearchTerm(state, action: PayloadAction<string>) {
      state.filterSearchTerm = action.payload;
    },
    setFilterTripPayers(state, action: PayloadAction<string[]>) {
      state.filterTripPayers = action.payload;
    },
    setFilterPaymentStatus(
      state,
      action: PayloadAction<OverallPaymentStatusEnum[]>
    ) {
      state.filterPaymentStatus = action.payload;
    },
    setFilterTotalAmount(state, action: PayloadAction<NumberRangeValue>) {
      state.filterTotalAmount = action.payload;
    },
    setFilterTotalAmountDue(state, action: PayloadAction<NumberRangeValue>) {
      state.filterTotalAmountDue = action.payload;
    },
    clearFilters(state) {
      state.filterDateStart = undefined;
      state.filterDateEnd = undefined;
      state.filterHasPayments = undefined;
      state.filterTripPayers = undefined;
      state.filterPaymentStatus = undefined;
      state.filterTotalAmount = undefined;
      state.filterTotalAmountDue = undefined;
      state.filterGroup = undefined;
    },
    // Translates ?search into state
    initializeFilters(state) {
      const currentParams = searchParamsToJSON(
        new URLSearchParams(window.location.search)
      );

      Object.keys(currentParams).forEach((param) => {
        const rawValue = currentParams[param];
        const value: unknown = (() => {
          switch (param) {
            case 'DateRange': {
              const range = JSON.parse(rawValue as string);

              // Specially handling this value since its a compound one
              state.filterDateStart = range.rangeStart;
              state.filterDateEnd = range.rangeEnd;

              return undefined;
            }
            case 'TotalAmount':
            case 'TotalAmountDue':
              return JSON.parse(rawValue as string);
            case 'TripPayers':
            case 'PaymentStatus':
              return [rawValue].flatMap((n) => n);
            case 'HasPayments':
              return rawValue === '1'
                ? true
                : rawValue === '0'
                ? false
                : undefined;
          }
        })();

        if (!value) {
          return state;
        }

        // There isn't a way to validate this state since it gets
        // generated outside of application
        // @ts-ignore
        state[`filter${param}`] = value;
      });
    },
    setSelectedFilterGroup(state, action: PayloadAction<FilterGroup>) {
      const payload = action.payload;
      state.filterGroup = payload;

      const currentParams = searchParamsToJSON(
        new URLSearchParams(window.location.search)
      );

      const nextParams = createSearchParams({
        ordering: currentParams.ordering ?? '',
        filterGroup: payload.slug ?? '',
      });

      window.history.pushState(null, '', `?${nextParams.toString()}`);

      // Translate filter group into filters
      if (!payload) {
        return;
      }

      Object.keys(payload.filters).forEach((filterName) => {
        // @ts-ignore
        const filterValue = payload.filters[filterName] ?? '';

        switch (filterName) {
          case 'has_payment':
            state.filterHasPayments = filterValue as boolean;
            break;
          case 'range_start':
            state.filterDateStart = filterValue as string;
            break;
          case 'range_end':
            state.filterDateEnd = filterValue as string;
            break;
          case 'trip_payer__in':
            state.filterTripPayers = filterValue as string[];
            break;
          case 'overall_payment_status__in':
            state.filterPaymentStatus =
              filterValue as OverallPaymentStatusEnum[];
            break;
          case 'total_amount_min':
            state.filterTotalAmount = {
              ...(state.filterTotalAmount ?? {}),
              min: filterValue as number,
            };
            break;
          case 'total_amount_max':
            state.filterTotalAmount = {
              ...(state.filterTotalAmount ?? {}),
              max: filterValue as number,
            };
            break;
          case 'due_min':
            state.filterTotalAmountDue = {
              ...(state.filterTotalAmountDue ?? {}),
              min: filterValue as number,
            };
            break;
          case 'due_max':
            state.filterTotalAmountDue = {
              ...(state.filterTotalAmountDue ?? {}),
              max: filterValue as number,
            };
            break;
        }
      });
    },
  },
  extraReducers: (builder) => {
    clearFiltersExtraReducer(builder);

    // Translates setFilterX payload into ?search
    builder.addMatcher(
      (action) => {
        const type: string = action.type;
        return type.includes('setFilter');
      },
      (state, action) => {
        const type: string = action.type;
        const payload: unknown = action.payload;
        const filterParam = type.match(/setFilter([a-z]+)/i)?.[1];

        if (!filterParam) {
          return state;
        }

        const value: string | string[] | undefined = (() => {
          switch (filterParam) {
            case 'DateRange':
            case 'TotalAmount':
            case 'TotalAmountDue':
              return JSON.stringify(payload);
            case 'HasPayments':
              return (payload as boolean) ? '1' : '0';
            case 'TripPayers':
            case 'PaymentStatus':
              return payload as string[];
          }
        })();

        if (value) {
          const currentParams = searchParamsToJSON(
            new URLSearchParams(window.location.search)
          );
          const nextParams = createSearchParams({
            ...currentParams,
            [filterParam]: value,
          });

          window.history.pushState(null, '', `?${nextParams.toString()}`);
        }

        return state;
      }
    );
  },
});

export const {
  editInvoice,
  setInvoiceModalOpen,
  editLineItems,
  resetEditing,
  setPayModalOpen,
  setFilterDateRange,
  setFilterHasPayments,
  setFilterSearchTerm,
  setFilterTripPayers,
  setFilterPaymentStatus,
  setFilterTotalAmount,
  setFilterTotalAmountDue,
  clearFilters,
  initializeFilters,
  setSelectedFilterGroup,
} = invoiceSlice.actions;
