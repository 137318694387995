import { DateTime } from 'luxon';

import { getFirstDayOfWeek } from './getFirstDayOfWeek';

export function isSameWeek(current: DateTime, minDate: DateTime): boolean {
  return (
    // Because luxon uses ISO rules, the first day of the week is Monday. We define the first day of the week as Sunday.
    // We can modify
    getFirstDayOfWeek(current).weekNumber ===
    getFirstDayOfWeek(minDate).weekNumber
  );
}
