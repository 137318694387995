import { AmbulatoryIcon } from './AmbulatoryIcon';
import { SpaceTypeIconMap } from './SpaceTypeIconMap';
import { StretcherIcon } from './StretcherIcon';
import { WheelchairIcon } from './WheelchairIcon';

export const SpaceTypeIcons: SpaceTypeIconMap = {
  'wheelchair xl': WheelchairIcon,
  wheelchair: WheelchairIcon,
  stretcher: StretcherIcon,
  ambulatory: AmbulatoryIcon,
  'broda chair': WheelchairIcon,
  'geri chair': WheelchairIcon,
} as const;
