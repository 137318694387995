import { PricingCondition } from 'src/common/external/bambi-api/bambiApi';

export default function SelectedPricingModelConditionText({
  condition,
}: {
  condition: PricingCondition | undefined;
}) {
  const pricingConditionStr =
    condition && condition.match_condition ? condition.match_condition : 'None';

  return (
    <h3 data-testid="selected-pricing-model-condition">
      <span className="text-lg font-medium">Pricing Condition: </span>
      {pricingConditionStr}
    </h3>
  );
}
