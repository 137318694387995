import { upperFirst } from 'lodash-es';

import { OverallPaymentStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import {
  MultiSelectFilterPopover,
  MultiSelectFilterPopoverProps,
} from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

type IdentifiedPaymentStatus = {
  id: string;
  value: OverallPaymentStatusEnum;
};

const rawStatuses: OverallPaymentStatusEnum[] = [
  'overpaid',
  'paid',
  'partially_paid',
  'unpaid',
];

function createIdentifiedStatusList(
  list: OverallPaymentStatusEnum[]
): IdentifiedPaymentStatus[] {
  return list.map((s) => ({
    id: s,
    value: s,
  }));
}

function unwrapIdentifiedStatusList(
  list: IdentifiedPaymentStatus[]
): OverallPaymentStatusEnum[] {
  return list.map((s) => s.value);
}

export default function PaymentStatusFilter({
  selected,
  open,
  triggerElement,
  onApply,
  onClose,
}: {
  selected: OverallPaymentStatusEnum[];
  open?: boolean;
  triggerElement?: MultiSelectFilterPopoverProps['triggerElement'];
  onApply: (statuses: OverallPaymentStatusEnum[]) => void;
  onClose?: () => void;
}) {
  const identifiedStatuses = createIdentifiedStatusList(rawStatuses);
  const identifiedStatusValues = createIdentifiedStatusList(selected);

  return (
    <MultiSelectFilterPopover
      open={open}
      filters={identifiedStatuses}
      value={identifiedStatusValues}
      renderLabel={({ value }) => upperFirst(value).replaceAll('_', ' ')}
      triggerElement={
        triggerElement ?? (
          <FilterPopoverTrigger
            count={selected.length}
            label="Payment Status"
          />
        )
      }
      onChange={(value) => {
        onApply(unwrapIdentifiedStatusList(value));
        onClose?.();
      }}
      onCancel={onClose}
    />
  );
}
