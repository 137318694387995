import { forwardRef, LegacyRef } from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/common/primitives/Tooltip';

import { classNames } from '../../classNames';
import { LoadingIndicator } from '../LoadingIndicator';
const BASE_CLASS_NAMES =
  'focus:ring-indigo-500 inline-flex items-center rounded border text-sm font-medium focus:outline-mint disabled:opacity-50 disabled:cursor-not-allowed';

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  PRIMARY_DANGER: 'primary-danger',
  PRIMARY_OUTLINE: 'primary-outline',
  SECONDARY: 'secondary',
  SECONDARY_OUTLINE: 'secondary-outline',
  DANGER: 'danger',
  GHOST: 'ghost',
  GHOST_DANGER: 'ghost-danger',
  ANCHOR: 'anchor',
  ICON: 'icon',
  GHOST_ICON: 'ghost-icon', // the ghostiest
  // Split button
  SPLIT_LEFT: 'split-left',
  SPLIT_RIGHT: 'split-right',
  SUCCESS: 'success',
  SUCCESS_OUTLINE: 'success-outline',
} as const;

export type ButtonVariants =
  typeof BUTTON_VARIANTS[keyof typeof BUTTON_VARIANTS];
type IButton_Variant_Class_Names = {
  [index in ButtonVariants]: string;
};

const VARIANT_CLASS_NAMES: IButton_Variant_Class_Names = {
  primary: `border-mint bg-mint text-white hover:bg-mint px-3 py-2`,
  'primary-outline': `border-mint text-gray-700 hover:bg-gray-50 px-3 py-2`,
  secondary: `border-gray-100 bg-gray-100 text-gray-900 hover:bg-gray-300 px-3 py-2`,
  'secondary-outline': `border-gray-300 bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-500 px-3 py-2`,
  danger: `border-error-700 bg-white text-error-700 hover:bg-error-50 hover:text-error-500 px-3 py-2`,
  'primary-danger': `border-error-700 bg-error-700 text-white hover:bg-error-500 px-3 py-2`,
  ghost: `border-transparent bg-transparent text-gray-700 hover:bg-gray-50 px-3 py-2`,
  'ghost-danger': `border-transparent bg-transparent text-error-500 hover:bg-gray-50 px-3 py-2`,
  anchor: `border-none text-xs text-blue-600 underline px-3 py-2`,
  icon: `border-transparent bg-transparent text-gray-700 hover:bg-gray-50 px-1 py-1`,
  'ghost-icon': `border-transparent bg-transparent text-gray-700 hover:bg-gray-50`,
  'split-left':
    'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-500 px-3 py-2 rounded-tr-none rounded-br-none',
  'split-right':
    'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-500 px-2 py-2 border-l-0 rounded-tl-none rounded-bl-none',
  success: `border-success-700 bg-success-700 text-white hover:bg-success-500 px-3 py-2`,
  'success-outline': `border-success-700 bg-white text-success-700 hover:bg-success-50 px-3 py-2`,
};

export type ButtonProps = {
  className?: string;
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariants;
  disabled?: boolean;
  alt?: string;
  dataTestId?: string;
  justifyContent?: 'center' | 'start' | 'end';
  ariaLabel?: string;
  ariaHidden?: boolean;
  loading?: boolean;
  buttonProps?: typeof HTMLButtonElement;
};

export const Button = forwardRef(function _Button(
  {
    className = '',
    children,
    onClick,
    type = 'button',
    variant = 'secondary-outline',
    disabled = false,
    alt = '',
    dataTestId = '',
    justifyContent = 'center',
    ariaLabel = '',
    ariaHidden = false,
    loading = false,
    buttonProps,
  }: ButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  const colorClasses: string = VARIANT_CLASS_NAMES[variant];
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            type={type}
            className={classNames(
              BASE_CLASS_NAMES,
              colorClasses,
              className,
              `justify-${justifyContent}`
            )}
            onClick={(e) => {
              if (loading) {
                return;
              }

              return onClick?.(e);
            }}
            disabled={disabled}
            ref={ref}
            data-testid={dataTestId}
            aria-label={ariaLabel}
            aria-hidden={ariaHidden}
            style={{
              position: 'relative',
              color: loading ? 'transparent' : undefined,
            }}
            {...buttonProps}
          >
            {children}
            {loading ? (
              <div
                style={{
                  position: 'absolute',
                  left: 'calc(50% - 12.5px)',
                  top: 'calc(50% - 10px)',
                }}
              >
                <LoadingIndicator
                  variant={variant === 'primary' ? 'small-invert' : 'small'}
                />
              </div>
            ) : null}
          </button>
        </TooltipTrigger>
        {alt ? (
          <TooltipContent side="bottom" align="start">
            {alt}
          </TooltipContent>
        ) : null}
      </Tooltip>
    </TooltipProvider>
  );
});
