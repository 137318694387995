import { ReactNode, useState } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';

import { Popover } from 'src/common/primitives/Popover';

export type TimeRange = {
  startTime?: string;
  endTime?: string;
};

const timeFields = {
  start_time: {
    label: 'Start Time',
    type: 'time',
  },
  end_time: {
    label: 'End Time',
    type: 'time',
  },
} as const;

export default function TimeRangePicker({
  triggerElement,
  onTimeRangeChange,
}: {
  triggerElement: ReactNode;
  onTimeRangeChange: (TimeRange: TimeRange) => void;
}) {
  const [timeRange, setTimeRange] = useState<TimeRange>();

  const handleChange = (fieldKey: string, value: string) => {
    const startTime = fieldKey === 'start_time' ? value : timeRange?.startTime;
    const endTime = fieldKey === 'end_time' ? value : timeRange?.endTime;
    setTimeRange({ startTime, endTime });
  };

  const handleApply = () => {
    if (timeRange) onTimeRangeChange(timeRange);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) setTimeRange({ startTime: undefined, endTime: undefined });
  };

  return (
    <>
      <style>{`
      .rdp-day_selected {
        background-color: #0CACA6;
      }
    `}</style>
      <Popover
        onOpenChange={onOpenChange}
        trigger={triggerElement}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        content={
          <>
            <div className="grid grid-cols-2 gap-2 pb-4">
              {Object.keys(timeFields).map((fieldKey) => {
                const fieldConfig =
                  timeFields[fieldKey as keyof typeof timeFields];
                return (
                  <div className="flex flex-col" key={fieldKey}>
                    <label htmlFor={fieldKey}>{fieldConfig.label}</label>
                    <input
                      className="block w-full appearance-none rounded-md bg-transparent text-base text-slate-900 placeholder:text-slate-600 focus:outline-none sm:text-sm sm:leading-6"
                      id={fieldKey}
                      type={fieldConfig.type}
                      onChange={(e) => handleChange(fieldKey, e.target.value)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-2 border-t border-gray-200 py-2">
              <RadixPopover.Close className="flex">
                <div className="focus:ring-indigo-500 mr-2 inline-flex flex-grow items-center justify-center rounded border border-gray-100 bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                  Cancel
                </div>
              </RadixPopover.Close>
              <RadixPopover.Close onClick={handleApply} className="flex">
                <div className="focus:ring-indigo-500 inline-flex flex-grow items-center justify-center rounded border border-mint bg-mint px-3 py-2 text-sm font-medium text-white hover:bg-mint focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                  Apply
                </div>
              </RadixPopover.Close>
            </div>
          </>
        }
      ></Popover>
    </>
  );
}
