import { DateTime } from 'luxon';

import { generateWeekDays } from 'src/common/date/generateWeekDays';

import { formatDayKey } from './formatDayKey';
import { FormValues } from './FormValues';

export const defaultFormValues: FormValues = {
  attendants: [],
  driver: undefined,
  vehicle: undefined,
  assignments: generateWeekDays(DateTime.local()).reduce((acc, day) => {
    return {
      ...acc,
      [formatDayKey(day)]: { start: '', end: '', isEditing: false },
    };
  }, {}),
  applyDateRange: {
    start: '',
    end: '',
  },
};
