import { PricingModelDetailsActions } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingModelDetailsActions';

export function SelectedPricingModelDetailsHeader({ name }: { name: string }) {
  return (
    <div className="flex items-center justify-between">
      <h2
        className="text-xl font-medium"
        data-testid="selected-pricing-model-name"
      >
        {name}
      </h2>
      <div className="flex gap-2">
        <PricingModelDetailsActions />
      </div>
    </div>
  );
}
