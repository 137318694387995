import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

function FormFieldTextAreaInner<T>(
  { inputProps, label, error, inline, helperText }: FormFieldProps<T>,
  forwardedRef: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      inline={inline}
      helperText={helperText}
    >
      <div className="flex items-center gap-2">
        <textarea
          ref={forwardedRef}
          className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
          {...(inputProps as DetailedHTMLProps<
            TextareaHTMLAttributes<HTMLTextAreaElement>,
            HTMLTextAreaElement
          >)}
          data-testid={inputProps.id}
        ></textarea>
      </div>
    </DefaultFormFieldLayout>
  );
}

export const FormFieldTextArea = forwardRef(
  FormFieldTextAreaInner
) as typeof FormFieldTextAreaInner;
