import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'src/app/store';
import { hide } from 'src/common/primitives/Toast/toast.slice';

import { Toast } from './Toast';

export function ToastRenderer() {
  const toasts = useSelector((state: RootState) => state.toast.toasts);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (toasts.length > 0) {
        dispatch(hide(toasts[0]));
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [dispatch, toasts]);

  return (
    <>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          title={toast.title}
          description={toast.description}
          type={toast.type}
          onClose={() => {
            dispatch(hide(toast));
          }}
        />
      ))}
    </>
  );
}
