import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { DynamicFieldNameRootContext } from 'src/features/add-trip/TripDetails/DynamicFieldNameRootContext';
import { AssignmentDayEditor } from 'src/features/team/AssignmentManagementForm/AssignmentDayEditor';

import { useWeekDays } from './useWeekDays';

export function AssignmentWeekEditor() {
  const { selectedDay } = useSelector(
    (state: RootState) => state.assignmentManagement
  );
  const selectedDayOrToday = selectedDay || DateTime.local().toISODate();
  const days = useWeekDays(selectedDayOrToday);
  return (
    <>
      {days.map((day, index) => {
        return (
          <DynamicFieldNameRootContext.Provider
            value={`assignments.${day.weekdayLong}`}
            key={index}
          >
            <AssignmentDayEditor key={day.weekdayLong} day={day} />
          </DynamicFieldNameRootContext.Provider>
        );
      })}
    </>
  );
}
