import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { makeResultsByDay } from './makeResultsByDay/makeResultsByDay';
import { useDefaultAssignmentSchedule } from './useDefaultAssignmentSchedule';

export function useDefaultAssignmentFormValues({
  driverId = '',
  vehicleId = '',
  attendantIds = [],
}: {
  driverId: string;
  vehicleId: string;
  attendantIds: string[];
}) {
  const { setValue } = useFormContext();
  const defaultsResponse = useDefaultAssignmentSchedule({
    driverId,
    vehicleId,
    attendantIds,
  });

  useEffect(() => {
    if (defaultsResponse.data?.results.length) {
      // TODO: Assuming the first here because there's no way to select this yet
      // There's a way to type this without the gross cast
      const firstResult = defaultsResponse.data.results[0] as {
        [k: string]: any;
      };
      const resultsByDay = makeResultsByDay(firstResult);
      Object.keys(resultsByDay).forEach((dayKey) => {
        if (resultsByDay[dayKey].end && !!resultsByDay[dayKey].start) {
          setValue(`assignments.${dayKey}.isEditing`, true);
          setValue(`assignments.${dayKey}.start`, resultsByDay[dayKey].start);
          setValue(`assignments.${dayKey}.end`, resultsByDay[dayKey].end);
        }
      });
    }
  }, [defaultsResponse, setValue]);

  return defaultsResponse;
}
