import { DateTime } from 'luxon';

import { generateWeekDays } from 'src/common/date/generateWeekDays';
import {
  AttendantRead,
  DriverRead,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';

import { FormValues } from './FormValues';

export type FormAssignment = {
  id: string;
  start: string;
  end: string;
  vehicle: VehicleRead | undefined;
  driver: DriverRead | undefined;
  attendants: AttendantRead[];
};

export const baseAssignment: FormAssignment = {
  id: '',
  start: '',
  end: '',
  vehicle: undefined,
  driver: undefined,
  attendants: [],
};

export const generateDefaultFormValues = (
  selectedDate: string,
  vehicle?: VehicleRead
): FormValues => {
  return {
    vehicle: vehicle,
    assignments: generateWeekDays(
      DateTime.fromISO(selectedDate).toLocal()
    ).reduce((acc, day) => {
      return {
        ...acc,
        [day.weekdayLong]: {
          dateTime: day,
          assignments: [{ ...baseAssignment }],
          isEditing: false,
        },
      };
    }, {}),
  };
};
