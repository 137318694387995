import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';

import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';

export const createClockInEventsFromAssignments = (
  assignments: DispatchAssignmentRead[]
): IValidateProps[] => {
  const clockInEvents: IValidateProps[] = assignments
    .map((assignment) => {
      if (!assignment.clocked_in_at) {
        return {};
      }
      return {
        id: `clock-in-${assignment.id}`,
        start: assignment.clocked_in_at,
        end: assignment.clocked_in_at,
        title: 'Clock In',
        resource: assignment.id,
        assignmentEvent: {
          type: 'clock-in',
        },
      };
    })
    .filter((x) => !!x.start);
  return clockInEvents;
};
