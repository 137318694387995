import { useState } from 'react';

import {
  TagRead,
  useTagsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import {
  MultiSelectFilterPopover,
  MultiSelectFilterPopoverProps,
} from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useUserRoles } from 'src/features/auth/useUserRoles';

// Extracts ID string from list
function unwrapPayersList(list: TagRead[]): string[] {
  return list.map((p) => p.id);
}

function createIdentifiedList(list: string[]): TagRead[] {
  return list.map((s) => ({
    id: s,
  })) as TagRead[]; // Not a true conversion, but not needed
}

interface TagsFilterProps {
  open?: boolean;
  selected: string[];
  triggerElement?: MultiSelectFilterPopoverProps['triggerElement'];
  onApply: (tags: string[]) => void;
  onClose?: () => void;
}

export function TagsFilter({
  open,
  selected,
  triggerElement,
  onApply,
  onClose,
}: TagsFilterProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { isRideOrderingPortalUser } = useUserRoles();
  const { data: tagsList, isLoading: tagsLoading } = useTagsListQuery(
    {
      search: searchTerm,
    },
    {
      skip: isRideOrderingPortalUser,
    }
  );

  return (
    <MultiSelectFilterPopover
      open={open}
      value={createIdentifiedList(selected)}
      filters={tagsList?.results ?? []}
      renderLabel={({ tag_name }) => tag_name}
      onChange={(value) => {
        onApply(unwrapPayersList(value));
        onClose?.();
      }}
      onSearch={(term) => {
        setSearchTerm(term);
      }}
      onCancel={onClose}
      searchPlaceholder="Search tags"
      triggerElement={
        triggerElement ?? (
          <FilterPopoverTrigger
            count={selected.length}
            label={tagsLoading ? <LoadingIndicator variant="small" /> : 'Tag'}
          />
        )
      }
    />
  );
}
