import { DateTime } from 'luxon';

// Converts a Luxon DateTime (org's timezone due to Settings.defaultZone) to a JS Date (browser's timezone with the same time value)
export function OrgLuxonDateTimeToBrowserJSDate(date: DateTime) {
  return date
    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {
      keepLocalTime: true,
    })
    .toJSDate();
}
