export function ProgressBar({ step, total }: { step: number; total: number }) {
  const progress = Math.round((step / total) * 100);

  return (
    <div className="flex w-full items-center justify-end gap-2">
      <div className="w-1/2">
        <div className="h-1 w-full rounded bg-gray-200">
          <div
            className={`h-full rounded bg-mint`}
            data-testid="progress-bar-step-indicator"
            style={{
              transition: 'width 0.3s ease-in-out',
              width: `${progress}%`,
            }}
          />
        </div>
      </div>
      <div className="whitespace-nowrap text-gray-500">
        Step {step} of {total}
      </div>
    </div>
  );
}
