export function usePricingTypeTemplates() {
  // TODO: this will eventually wrap a call to the API
  // It will need to filter out pricing types that are already in use somehow
  return [
    {
      name: 'Pricing Type 1',
      id: 'pricing-type-1',
    },
    {
      name: 'Pricing Type 2',
      id: 'pricing-type-2',
    },
    {
      name: 'Pricing Type 3',
      id: 'pricing-type-3',
    },
  ];
}
