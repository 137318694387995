export function AdditionalRequirementDetails({
  label,
  property,
}: {
  label: string;
  property?: number | string;
}) {
  return (
    <>
      <td></td>
      <td></td>
      <td className="px-2 py-1 text-xs">
        <div className="text-center">
          <div className="underline">{label}</div>
          <div>{property || '--'}</div>
        </div>
      </td>
    </>
  );
}
