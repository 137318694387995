import { ReactNode } from 'react';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';

import {
  AttendantRead,
  AttendantSkills,
} from 'src/common/external/bambi-api/bambiApi';
import { ListCell } from 'src/common/ListCell';
import { PersonCell } from 'src/common/PersonCell';
import { SkillBadge } from 'src/common/SkillBadge';

import { AttendantRowActions } from './AttendantRowActions';
import { ATTENDANT_SKILLS_LABEL_MAP } from './attendantSkillsLabelMap';

const columnHelper = createColumnHelper<AttendantRead>();

function renderSkillComponent(value: string): ReactNode {
  // TODO: Refactor ListCell to define the key there instead of here
  // This way this doesn't need to know it's being used in a list
  return <SkillBadge key={value} skill={value} />;
}

export const columns = [
  columnHelper.accessor('first_name', {
    id: 'name',
    header: 'Name',
    cell: (props) => <PersonCell person={props.row.original} />,
  }),
  columnHelper.accessor('can_be_scheduled', {
    id: 'can_be_scheduled',
    header: 'Schedulable',
    cell: ({ getValue }) =>
      getValue() ? (
        <CheckIcon className="h-7 w-7 text-green-600" />
      ) : (
        <XMarkIcon className="h-7 w-7 text-red-600" />
      ),
  }),
  columnHelper.accessor('skills', {
    header: 'Skills',
    cell: ({ getValue }) =>
      getValue() && (
        <ListCell
          values={Object.keys(getValue())
            .filter(
              (key) =>
                key !== 'id' &&
                Boolean(getValue()[key as keyof AttendantSkills])
            )
            .map((key) => {
              return ATTENDANT_SKILLS_LABEL_MAP[key];
            })}
          renderComponent={renderSkillComponent}
        />
      ),
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Edit',
    cell: (props) => <AttendantRowActions attendant={props.row.original} />,
  }),
];
