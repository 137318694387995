import { AssignmentRead } from 'src/common/external/bambi-api/bambiApi';

export function adaptAssignmentsToEpg(assignments: AssignmentRead[]) {
  return assignments.map((assignment) => {
    if (!assignment.vehicle.vehicle) {
      throw new Error('Assignment must have a vehicle id');
    }
    return {
      channelUuid: assignment.vehicle.vehicle,
      description: '',
      id: assignment.id,
      image: '',
      since: assignment.start,
      till: assignment.end,
      title: '',
    };
  });
}
