import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { DropdownMenuBasic } from 'src/common/DropdownMenu/DropdownMenuBasic';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';

import { usePricingTypeTemplates } from './usePricingTypeTemplates';

export function AddPricingTypeControl() {
  const pricingTypeTemplates = usePricingTypeTemplates();
  return (
    <DropdownMenuBasic
      trigger={
        // Button component swallows click event somewhere (probably due to the Tooltip component)
        // so using plain button for now
        <button className="flex items-center justify-center gap-x-1">
          <PlusCircleIcon className="h-5 w-5" />{' '}
          <span className="text-xs">Add Pricing Type</span>
        </button>
      }
    >
      {pricingTypeTemplates.map((pricingTypeTemplate) => {
        return (
          <DropdownMenuItem
            key={pricingTypeTemplate.id}
            onClick={() => {
              // TODO: This may actually end up being a call to the backend to create a new pricing type and then reload data
            }}
          >
            {pricingTypeTemplate.name}
          </DropdownMenuItem>
        );
      })}
    </DropdownMenuBasic>
  );
}
