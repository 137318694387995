import * as ToastPrimitive from '@radix-ui/react-toast';
import { styled } from '@stitches/react';

import { VIEWPORT_PADDING } from './constants';

const StyledViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  top: '39px',
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  minWidth: 390,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 2147483647,
  outline: 'none',
});
export const ToastViewport = StyledViewport;
