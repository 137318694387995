import { TabNavigation } from 'src/common/TabNavigation';

export function BillingNav() {
  const tabs = [
    {
      name: 'Invoices',
      href: '/billing/invoices',
    },
    {
      name: 'Payouts',
      href: '/billing/payouts',
    },
    {
      name: 'Settings',
      href: '/billing/settings',
    },
  ];

  return <TabNavigation tabs={tabs} />;
}
