import {
  ArrowPathRoundedSquareIcon,
  ArrowUturnLeftIcon,
} from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';
import { useFormValues } from 'src/common/useFormValues';

import { FormValues } from '../FormValues';
import { useMakeRoundTrip } from './useMakeRoundTrip';

export function MakeRoundTripButton({ isWillCall }: { isWillCall: boolean }) {
  const makeRoundTrip = useMakeRoundTrip({ isWillCall });
  const {
    is_round_trip,
    is_wait_and_return_outbound,
    is_wait_and_return_inbound,
  } = useFormValues<FormValues>();
  return (
    <Button
      onClick={() => makeRoundTrip()}
      className="flex-grow"
      disabled={
        is_round_trip ||
        is_wait_and_return_outbound ||
        is_wait_and_return_inbound
      }
      dataTestId={isWillCall ? 'will-call-return-btn' : 'make-round-trip-btn'}
    >
      {isWillCall ? (
        <div className="flex items-center justify-between gap-x-1">
          <ArrowPathRoundedSquareIcon className="h-4 w-4" />
          Make it a will call return
        </div>
      ) : (
        <div className="flex items-center justify-between gap-x-1">
          <ArrowUturnLeftIcon className="h-4 w-4" />
          Make it a Round trip
        </div>
      )}
    </Button>
  );
}
