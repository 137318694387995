import { getAccessToken } from 'src/common/external/aws/getAccessToken';
import { signOut } from 'src/common/external/aws/signOut';

import { redirectUnauthedUser } from './redirectUnauthedUser';

const authService = {
  signOut: () => {
    signOut();
    redirectUnauthedUser();
  },
  getAccessToken,
};

export { authService };
