import { useState } from 'react';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import { useTagsDestroyMutation } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import {
  setSelectedTag,
  setShowDeleteTagModal,
  setShowTagsModal,
} from '../../setting.slice';
import { generateDeleteSuccessToast } from './generateDeleteSuccessToast';

export function DeleteTagModal() {
  const isConfirmingDelete = useAppSelector(
    (state: RootState) => state.setting.showDeleteTagModal
  );

  const dispatch = useAppDispatch();
  const [deleteTag] = useTagsDestroyMutation();

  const selectedTag = useAppSelector(
    (state: RootState) => state.setting.selectedTag
  );

  const [error, setError] = useState('');

  const nextToastId = useAppSelector((state: RootState) => state.toast.nextId);

  const onClose = () => {
    dispatch(setShowDeleteTagModal(false));
    dispatch(setShowTagsModal(false));
    dispatch(setSelectedTag(undefined));
  };

  const onConfirm = async () => {
    try {
      if (selectedTag?.id) {
        await deleteTag({
          id: selectedTag.id,
        }).unwrap();
        dispatch(show(generateDeleteSuccessToast(nextToastId)));
        onClose();
      }
    } catch (error) {
      const severError = formatServerError(error);
      setError(severError);
    }
  };

  return (
    <DeleteModal
      open={isConfirmingDelete}
      setOpen={(isOpen) => dispatch(setShowDeleteTagModal(isOpen))}
      onCancel={() => {
        onClose();
      }}
      onConfirm={onConfirm}
      title="Delete this tag?"
      description="Are you sure you want to delete this tag? This action cannot be undone."
      error={error}
    />
  );
}
