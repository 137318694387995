import { useState } from 'react';

import { FormField } from 'src/common/FormField';
import { Button } from 'src/common/primitives/Button';

interface AddEditNoteProps {
  label: string;
  initialNote?: string;
  onSave: (note: string) => void;
  testId?: string;
}

export function AddEditNote({
  label,
  testId,
  initialNote,
  onSave,
}: AddEditNoteProps) {
  const [note, setNote] = useState(initialNote || '');

  return (
    <div className="flex flex-col gap-4 p-2">
      <div className="flex items-center gap-4">
        <FormField
          label={label}
          inputProps={{
            id: testId,
            value: note,
            onChange: (e) => setNote(e.target.value),
          }}
          type="textarea"
        />
      </div>

      <Button variant="primary" onClick={() => onSave(note)}>
        Save
      </Button>
    </div>
  );
}
