import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { dispatcherSlice } from '../../dispatcher.slice';

export function UnassignedTripsRunBambiRunSection() {
  const modalToShow = useAppSelector(
    (state) => state.dispatcher.modals.modalToShow
  );
  const dispatch = useAppDispatch();
  const isAssignmentSelectionModalShown =
    modalToShow === 'rbrAssignmentSelection';
  return (
    <div
      className={`flex flex-col items-center justify-center gap-2.5 rounded-[10px] bg-white py-2 px-5 text-center font-normal text-[rgba(51,51,51,1)] drop-shadow-lg`}
    >
      <div className="flex w-full items-center justify-between gap-8">
        <Button
          variant="primary"
          className="w-full"
          dataTestId="run-bambi-run-button"
          onClick={() => {
            dispatch(dispatcherSlice.actions.onRunBambiRunButtonClick());
          }}
          disabled={isAssignmentSelectionModalShown}
        >
          {isAssignmentSelectionModalShown
            ? 'Select Assignments to Continue'
            : 'Run Bambi Run'}
        </Button>
      </div>
    </div>
  );
}
