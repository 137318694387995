import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { usePassengersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export const usePassengerMultiSelectData = () => {
  const open = useSelector(
    (state: RootState) => state.trip.showDownloadDetailsModal
  );

  const [selectedPassengers, setSelectedPassengers] = useState<
    SelectOption[] | undefined
  >(undefined);
  const [passengerSearchTerm, setPassengerSearchTerm] = useState<string>('');
  const { data: passengers, isLoading: isLoadingPassengers } =
    usePassengersListQuery({ search: passengerSearchTerm }, { skip: !open });

  const passengerOptions = passengers?.results
    ? passengers.results.map((passenger) => ({
        label: `${passenger.first_name} ${passenger.last_name}`,
        value: passenger.id,
      }))
    : [];

  useEffect(() => {
    // Reset the state when the modal is closed
    if (!open) {
      setSelectedPassengers(undefined);
      setPassengerSearchTerm('');
    }
  }, [open]);

  return {
    selectedPassengers,
    setSelectedPassengers,
    passengerSearchTerm,
    setPassengerSearchTerm,
    passengerOptions,
    isLoadingPassengers,
  };
};
