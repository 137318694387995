import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { TeamNav } from './TeamNav';
import { TeamRouteActions } from './TeamRouteActions';

export function TeamLayout() {
  return (
    <HeaderToolbarAndContentLayout
      actionSection={<TeamRouteActions />}
      preContent={<TeamNav />}
      content={<Outlet />}
    />
  );
}
