import { invert } from 'lodash-es';

type Weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export const rRules: Record<Weekday, string> = {
  monday: 'RRULE:FREQ=WEEKLY;BYDAY=MO',
  tuesday: 'RRULE:FREQ=WEEKLY;BYDAY=TU',
  wednesday: 'RRULE:FREQ=WEEKLY;BYDAY=WE',
  thursday: 'RRULE:FREQ=WEEKLY;BYDAY=TH',
  friday: 'RRULE:FREQ=WEEKLY;BYDAY=FR',
  saturday: 'RRULE:FREQ=WEEKLY;BYDAY=SA',
  sunday: 'RRULE:FREQ=WEEKLY;BYDAY=SU',
};

export const rRulesDays: Record<string, Weekday> = invert(rRules) as Record<
  string,
  Weekday
>;
