import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { PayerManagementRoute } from './management/PayerManagementRoute';

export const payerRoutes: RouteObject[] = [
  {
    index: true,
    element: <PayerManagementRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/payers" linkText="Go back to payers" />
    ),
  },
];
