import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import InvoiceScreen from './invoices/InvoiceScreen';
import PayoutsScreen from './payouts/PayoutsScreen';
import BillingSettingsScreen from './settings/BillingSettingsScreen';

export const invoiceRoutes: RouteObject[] = [
  {
    path: 'invoices',
    element: <InvoiceScreen />,
  },
  {
    path: 'settings',
    element: <BillingSettingsScreen />,
  },
  {
    path: 'payouts',
    element: <PayoutsScreen />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute
        routePath="/billing/invoices"
        linkText="Go back to invoices"
      />
    ),
  },
];
