import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { MaybeAddressLinkChangeValue } from './MaybeAddressLinkChangeValue';

export function TripAddressChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const oldValue = tripEvent.values_changed?.[valueKey].old_value;
  const newValue = tripEvent.values_changed?.[valueKey].new_value;
  return (
    <div className="flex flex-col gap-1">
      <div className="line-through decoration-gray-500 opacity-75">
        <MaybeAddressLinkChangeValue value={oldValue} />
      </div>
      <MaybeAddressLinkChangeValue value={newValue} />
    </div>
  );
}
