export function getLockActionConfig(
  isLocked: boolean,
  lock: (tripId: string) => void,
  unlock: (tripId: string) => void
) {
  if (isLocked) {
    return {
      onClick: unlock,
      title: 'Unlock',
    };
  } else {
    return {
      onClick: lock,
      title: 'Lock Trip to This Driver',
    };
  }
}
