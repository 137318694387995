import { useCallback, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import { FormFieldDefault } from 'src/common/FormField/FormFieldDefault';
import { Button } from 'src/common/primitives/Button';
import {
  setPassengerMergeModalOpen,
  setSearchPassengerFirst,
  setSearchPassengerLast,
} from 'src/features/passenger/passenger.slice';

type PassengerSearchForm = {
  passengerFirst?: string;
  passengerLast?: string;
};

interface SearchPassengersStepProps {
  next: () => void;
}
export function SearchPassengersStep({ next }: SearchPassengersStepProps) {
  const dispatch = useAppDispatch();

  const methods = useForm<PassengerSearchForm>({
    defaultValues: {
      passengerFirst: '',
      passengerLast: '',
    },
  });

  const [first, last] = methods.watch(['passengerFirst', 'passengerLast']);
  useEffect(() => {
    dispatch(setSearchPassengerFirst(first ?? ''));
    dispatch(setSearchPassengerLast(last ?? ''));
  }, [dispatch, first, last]);

  const handleCancel = useCallback(() => {
    dispatch(setPassengerMergeModalOpen(false));
  }, [dispatch]);

  return (
    <div className="mt-2 flex max-w-md flex-col gap-2">
      <p>
        Start by narrowing down the duplicate passengers you're searching for by
        providing the start of the passenger's first, or last name.
      </p>
      <div className="flex-col gap-2">
        <Controller
          control={methods.control}
          name="passengerFirst"
          render={({ field }) => {
            return (
              <FormFieldDefault
                type="text"
                inputProps={{ ...field, id: 'first_name' }}
                onChange={field.onChange}
                value={field.value}
                label="First Name"
              />
            );
          }}
        />
        <Controller
          control={methods.control}
          name="passengerLast"
          render={({ field }) => {
            return (
              <FormFieldDefault
                type="text"
                inputProps={{ ...field, id: 'last_name' }}
                onChange={field.onChange}
                value={field.value}
                label="Last Name"
              />
            );
          }}
        />
      </div>
      <div className="flex flex-row justify-evenly gap-2">
        <Button
          variant="secondary-outline"
          className="w-full"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button variant="primary" className="w-full" onClick={next}>
          Next
        </Button>
      </div>
    </div>
  );
}
