export const WheelchairIcon = ({ ...props }) => {
  return (
    <svg
      className="relative shrink-0 overflow-visible"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66797 1.33325C7.5653 1.33325 6.66797 2.23059 6.66797 3.33325C6.66797 4.02927 7.0267 4.64156 7.56771 4.99992C7.0267 5.35828 6.66797 5.97057 6.66797 6.66658V9.33325C6.66797 10.0686 7.26597 10.6666 8.0013 10.6666H10.957L12.763 13.677L13.9063 12.9895L11.9063 9.65617C11.7863 9.4555 11.5686 9.33325 11.3346 9.33325H10.668V6.66658C10.668 5.97057 10.3092 5.35828 9.76823 4.99992C10.3092 4.64156 10.668 4.02927 10.668 3.33325C10.668 2.23059 9.77064 1.33325 8.66797 1.33325ZM8.66797 2.66659C9.0353 2.66659 9.33464 2.96592 9.33464 3.33325C9.33464 3.70059 9.0353 3.99992 8.66797 3.99992C8.30064 3.99992 8.0013 3.70059 8.0013 3.33325C8.0013 2.96592 8.30064 2.66659 8.66797 2.66659ZM8.66797 5.99992C9.0353 5.99992 9.33464 6.29925 9.33464 6.66658V9.33325H8.0013V6.66658C8.0013 6.29925 8.30064 5.99992 8.66797 5.99992ZM5.33464 6.89966C3.7833 7.45032 2.66797 8.92858 2.66797 10.6666C2.66797 12.8726 4.46197 14.6666 6.66797 14.6666C8.40597 14.6666 9.88423 13.5513 10.4349 11.9999H8.96354C8.50088 12.7933 7.65064 13.3333 6.66797 13.3333C5.1973 13.3333 4.0013 12.1373 4.0013 10.6666C4.0013 9.68392 4.5413 8.83368 5.33464 8.37101V6.89966Z"
        fill="#333333"
      />
    </svg>
  );
};
