import { Marker } from 'react-map-gl';

import { formatAvatarInitials } from 'src/common/primitives/Avatar/formatAvatarInitials';

import { AssignmentPinConfig } from './AssignmentPinConfig';
import { VehicleMapLocationMarker } from './VehicleMapLocationMarker';

export function AssignmentMarker({
  assignmentMarker,
  onClick,
  isSelected,
}: {
  assignmentMarker: AssignmentPinConfig;
  onClick?: () => void;
  isSelected?: boolean;
}) {
  return (
    <Marker
      latitude={assignmentMarker.latitude}
      longitude={assignmentMarker.longitude}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        onClick?.();
      }}
    >
      <VehicleMapLocationMarker
        driverInitials={formatAvatarInitials(
          ...assignmentMarker.title.split(' ')
        )}
        headingInDegrees={assignmentMarker.heading}
        isSelected={isSelected}
      />
    </Marker>
  );
}
