import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { AssignmentManagementRoute } from './assignment-management/AssignmentManagementRoute';
import { AttendantRoute } from './attendant/AttendantRoute';
import { DriverProfileRoute } from './driver-profile/DriverProfileRoute';
import { TeamManagementRoute } from './management/TeamManagementRoute';

export const teamRoutes: RouteObject[] = [
  {
    path: 'accounts',
    element: <TeamManagementRoute />,
  },
  {
    index: true,
    element: <AssignmentManagementRoute />,
  },
  {
    path: 'driver-profile',
    element: <DriverProfileRoute />,
  },
  {
    path: 'attendant-profile',
    element: <AttendantRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/team" linkText="Go back to team" />
    ),
  },
];

export const rideOrderingPortalTeamRoutes: RouteObject[] = [
  {
    index: true,
    element: <TeamManagementRoute />,
  },
];
