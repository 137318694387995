import { useEffect, useState } from 'react';

import { debounce } from 'lodash-es';

import { usePassengersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

import { useIsSelectedTripInThePast } from '../useIsSelectedTripInThePast';
import { buildPassengerSelectOption } from './buildPassengerSelectOption';
import { formatPassengerLabel } from './formatPassengerLabel';

export const usePassengerOptions = () => {
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  const [query, setQuery] = useState<string | undefined>('');
  const [options, setOptions] = useState<SelectOption[]>([]);

  const queryState = usePassengersListQuery(
    {
      search: query,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleQuery = async (query: string) => {
    setQuery(query);
    return Promise.resolve(
      queryState.data?.results.map((passenger) => {
        return {
          label: formatPassengerLabel(passenger),
          value: passenger.id,
        };
      }) || []
    );
  };

  useEffect(() => {
    const queryResults = queryState.data?.results || [];
    const addPassengerOption = {
      label: `Add "${query}" `,
      value: 'add-passenger',
    };

    if (!query || isSelectedTripInThePast) {
      setOptions(queryResults.map(buildPassengerSelectOption));
      return;
    }

    setOptions([
      ...queryResults.map(buildPassengerSelectOption),
      addPassengerOption,
    ]);
  }, [query, queryState.data?.results, isSelectedTripInThePast]);

  const debounceSearch = debounce(handleQuery, 300);

  return { debounceSearch, options, query, queryState, setQuery };
};
