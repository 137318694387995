import { useEffect, useState } from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DropdownMenuContent } from 'src/common/DropdownMenu/DropdownMenuContent';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';

import { setOpenTripDropdownMenuId } from '../../dispatcher.slice';
import { DriverAssignmentMenu } from '../../menus/DriverAssignmentMenu/DriverAssignmentMenu';
import { TripDropdownSharedMenu } from '../../menus/TripDropdownSharedMenu';

interface TripCardDropdownMenuProps {
  trip: DispatchTripRead;
}

export function TripCardDropdownMenu({ trip }: TripCardDropdownMenuProps) {
  const dispatch = useAppDispatch();
  const openTripDropdownMenuId = useAppSelector(
    (state) => state.dispatcher.openTripDropdownMenuId
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openTripDropdownMenuId === trip.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openTripDropdownMenuId, trip.id]);

  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={(val) => {
        if (!val) dispatch(setOpenTripDropdownMenuId(null));
      }}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setOpenTripDropdownMenuId(trip.id));
          }}
          dataTestId="trip-dropdown-menu-trigger"
        >
          <EllipsisVerticalIcon className="h-4" />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent side="bottom" onClick={(e) => e.stopPropagation()}>
          <TripDropdownSharedMenu trip={trip}>
            <DriverAssignmentMenu trip={trip} />
          </TripDropdownSharedMenu>
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
