import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { DuplicatePassenger, MatchFields } from './management/merge/types';
import { Passenger } from './Passenger';

export interface IPassengerState {
  passengers: Passenger[];
  passengerSearchTerm: string;
  passengerMergeModalOpen: boolean;
  // merge modal
  selectedMatchFields: MatchFields[];
  selectedPassengers: DuplicatePassenger[];
  searchPassengerFirst: string;
  searchPassengerLast: string;
  parentPassenger: DuplicatePassenger | null;
}

export const initialState: IPassengerState = {
  passengers: [],
  passengerSearchTerm: '',
  passengerMergeModalOpen: false,
  selectedMatchFields: [],
  selectedPassengers: [],
  searchPassengerFirst: '',
  searchPassengerLast: '',
  parentPassenger: null,
};

export const passengerSlice = createSlice({
  name: 'passenger',
  initialState,
  reducers: {
    setPassengers: (state, action: { payload: Passenger[]; type: string }) => {
      state.passengers = action.payload;
    },
    setPassengerSearchTerm: (state, action: PayloadAction<string>) => {
      state.passengerSearchTerm = action.payload;
    },
    setPassengerMergeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.passengerMergeModalOpen = action.payload;
    },
    setSearchPassengerFirst: (state, action: PayloadAction<string>) => {
      state.searchPassengerFirst = action.payload;
    },
    setSearchPassengerLast: (state, action: PayloadAction<string>) => {
      state.searchPassengerLast = action.payload;
    },
    setSelectedMatchFields: (state, action: PayloadAction<MatchFields[]>) => {
      state.selectedMatchFields = action.payload;
    },
    setDuplicatePassengers: (
      state,
      action: PayloadAction<DuplicatePassenger[]>
    ) => {
      state.selectedPassengers = action.payload;
    },
    setParentPassenger: (state, action: PayloadAction<DuplicatePassenger>) => {
      state.parentPassenger = action.payload;
    },
    resetMergeWizard: (state) => {
      state.passengerMergeModalOpen = false;
      state.parentPassenger = null;
      state.searchPassengerFirst = '';
      state.searchPassengerLast = '';
      state.selectedPassengers = [];
      state.selectedMatchFields = [];
    },
  },
});

export const {
  setPassengers,
  setPassengerSearchTerm,
  // Passenger merge modal
  setPassengerMergeModalOpen,
  setSearchPassengerFirst,
  setSearchPassengerLast,
  setSelectedMatchFields,
  setDuplicatePassengers,
  setParentPassenger,
  resetMergeWizard,
} = passengerSlice.actions;
