import { PlusIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';

export function PricingRuleAddButton() {
  return (
    <Button variant="primary" className="gap-2">
      <PlusIcon className="h-4 w-4" /> Add Rule
    </Button>
  );
}
