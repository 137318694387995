export function PricingRuleAccordionHeaderCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="relative flex flex-grow items-center gap-12 rounded-tl-lg rounded-bl-lg pt-4 pr-3 pb-4 pl-3">
      {children}
    </div>
  );
}
