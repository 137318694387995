import { PricingRule } from 'src/common/external/bambi-api/bambiApi';

import { PricingRuleAccordionHeaderCard } from './PricingRuleAccordionHeaderCard';
import { PricingRuleCancelButton } from './PricingRuleCancelButton';
import { PricingRuleNameField } from './PricingRuleNameField';
import { PricingRuleSaveButton } from './PricingRuleSaveButton';

export function PricingRuleAccordionHeaderEdit({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  return (
    <PricingRuleAccordionHeaderCard>
      <div
        className="text-md text-gray-900"
        onClick={(e) => {
          // Let's prevent the accordion from toggling when editing fields
          e.stopPropagation();
        }}
      >
        <PricingRuleNameField pricingRule={pricingRule} />
      </div>
      <div
        className="flex flex-grow justify-end gap-3"
        onClick={(e) => {
          // Let's prevent the accordion from toggling when taking actions
          e.stopPropagation();
        }}
      >
        <PricingRuleCancelButton pricingRule={pricingRule} />
        <PricingRuleSaveButton pricingRule={pricingRule} />
      </div>
    </PricingRuleAccordionHeaderCard>
  );
}
