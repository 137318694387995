import { MembershipRead } from 'src/common/external/bambi-api/bambiApi';

import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export function assignDefaultFormValues(member?: MembershipRead): FormValues {
  return {
    id: member?.id || defaultFormValues.id,
    role: member?.role ? { value: member.role, label: member.role } : undefined,
    first_name: member?.first_name || defaultFormValues.first_name,
    last_name: member?.last_name || defaultFormValues.last_name,
    email: member?.email || defaultFormValues.email,
    phone_number: member?.phone_number || defaultFormValues.phone_number,
    status: member?.status || defaultFormValues.status,
    payer_ids: member?.active_payers || [],
    deleted_at: member?.deleted_at ?? null,
  };
}
