/**
 * For features that don't live on a specific route like toasts/confirm modal
 */

import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface RequiresConfirmationState {
  showingConfirm: boolean;
  hasSeenConfirmPrompt: boolean;
  confirmationResult: undefined | true;
}

export const initialState: RequiresConfirmationState = {
  showingConfirm: false,
  hasSeenConfirmPrompt: false,
  confirmationResult: undefined,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setShowConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.showingConfirm = action.payload;
    },
    setHasSeenConfirmPrompt: (state, action: PayloadAction<boolean>) => {
      state.hasSeenConfirmPrompt = action.payload;
    },
    setConfirmationResult: (state, action: PayloadAction<true | undefined>) => {
      state.confirmationResult = action.payload;
    },
  },
});

export const {
  setShowConfirmationModal,
  setHasSeenConfirmPrompt,
  setConfirmationResult,
} = confirmationSlice.actions;
