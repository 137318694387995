import { useState } from 'react';

import { useAppDispatch } from 'src/app/store';
import { CurrencyInput } from 'src/common/CurrencyInput';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { onPricingRuleEditChange } from 'src/features/pricingModel/pricingModel.slice';

export default function PricingRuleAdditionalBaseInput({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  const initialAdditionalBaseModifier = pricingRule.additional_base
    ? pricingRule.additional_base >= 0
      ? 'Add'
      : 'Subtract'
    : undefined;

  const [additionalBaseModifier, setAdditionalBaseModifier] = useState(
    initialAdditionalBaseModifier
  );

  const additionalBaseSelectOptions = [
    { value: 'Add', label: 'Add' },
    { value: 'Subtract', label: 'Subtract' },
  ];

  return (
    <div className="flex items-center">
      <div className="mr-2 block text-sm font-medium leading-6 text-gray-700">
        Additional Base:
      </div>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Base Modifier
          </label>
          <select
            id="base-modifier"
            name="base-modifier"
            className="focus:ring-indigo-600 mr-7 h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset sm:text-sm"
            value={additionalBaseModifier}
            onChange={(e) => setAdditionalBaseModifier(e.target.value)}
            data-testid="pricing-rule-base-modifier"
          >
            {additionalBaseSelectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <CurrencyInput
          value={
            pricingRule.additional_base
              ? Math.abs(pricingRule.additional_base)
              : 0
          }
          onValueChange={(values) => {
            if (!values.floatValue) return;
            let additional_base = values.floatValue;
            if (additionalBaseModifier === 'Subtract') {
              additional_base = -1 * additional_base;
            }
            dispatch(
              onPricingRuleEditChange({
                ...pricingRule,
                additional_base,
              })
            );
          }}
          className="focus:border-indigo-500 focus:ring-indigo-500 block w-full rounded-md border-gray-300 pl-28 shadow-sm disabled:cursor-not-allowed sm:text-sm"
          data-testid="pricing-rule-additional-base-input"
        />
      </div>
    </div>
  );
}
