import {
  TripImportParserErrorRead,
  TripImportResultRead,
  TripImportUploadErrorRead,
} from 'src/common/external/bambi-api/bambiApi';

import { TripsUploadSummary, TripUploadErrorItem } from '../../trip.slice';

function aggregateTripUploadErrors(
  parser_errors: TripImportParserErrorRead[],
  upload_errors: TripImportUploadErrorRead[]
): TripUploadErrorItem[] {
  const errorMap = new Map<string, TripUploadErrorItem>();
  parser_errors.forEach((error) => {
    const existingError = errorMap.get(error.trip);
    if (existingError) {
      existingError.errors.push(error.error);
    } else {
      errorMap.set(error.trip, {
        trip: error.trip,
        errors: [error.error],
      });
    }
  });
  upload_errors.forEach((error) => {
    const existingError = errorMap.get(error.trip);
    const errorDescription = Object.keys(error.errors)
      .map((key) => {
        return `${key}: ${error.errors[key]}`;
      })
      .join(', ');
    if (existingError) {
      existingError.errors.push(errorDescription);
    } else {
      errorMap.set(error.trip, {
        trip: error.trip,
        errors: [errorDescription],
      });
    }
  });
  return Array.from(errorMap.values());
}
export function generateUploadTaskDetails(
  importedTrips?: TripImportResultRead
): TripsUploadSummary {
  // TODO: Not sure this should ever happen, but it does currently. All values are returning null
  if (!importedTrips) {
    return {
      details: 'Error importing trips. Please try again.',
      errors: [],
    };
  }
  const hasErrors =
    importedTrips.parser_errors.length > 0 ||
    importedTrips.upload_errors.length > 0;
  if (hasErrors) {
    const combinedErrors = [
      ...importedTrips.parser_errors,
      ...importedTrips.upload_errors,
    ];
    return {
      details: `Successfully imported ${importedTrips.trip_ids.length} trips. ${
        combinedErrors.length
      } error${combinedErrors.length > 1 ? 's' : ''} occurred.`,
      errors: aggregateTripUploadErrors(
        importedTrips.parser_errors,
        importedTrips.upload_errors
      ),
    };
  } else {
    return {
      details: `Successfully imported ${importedTrips.trip_ids.length} trips.`,
      errors: [],
    };
  }
}

export const generateUploadDetails = (importedTrips: any) => {
  let message = `Successfully imported ${importedTrips.details.trips.length} trips.`;
  if (importedTrips.parse_errors.length === 0) {
    return {
      success: true,
      message,
      successTotal: importedTrips.details.trips.length,
      parseErrors: [],
    };
  }

  message = `Successfully imported ${importedTrips.details.trips.length} trips. ${importedTrips.parse_errors.length} trips failed to import.`;

  return {
    success: false,
    message,
    successTotal: importedTrips.details.trips.length,
    parseErrors: importedTrips.parse_errors,
  };
};
