import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { PaginationNav } from 'src/common/PaginationNav';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { PricingModelEmptyStateMockContent } from 'src/features/pricingModel/management/PricingModelEmptyStateMockContent';
import { PricingModelList } from 'src/features/pricingModel/management/PricingModelList';
import { SelectedPricingModelDetails } from 'src/features/pricingModel/management/SelectedPricingModelDetails';

import { AddPricingModelButton } from '../AddPricingModelButton';
import { PricingModelEmptyState } from '../PricingModelEmptyState';
import { usePricingModelManagementViewModel } from './usePricingModelManagementViewModel';

export function PricingModelManagementRoute() {
  const {
    isPricingModelsLoading,
    isPricingModelChangePending,
    hasPricingModels,
    selectedPricingModelId,
    pricingModelError,
    paginationNavProps,
  } = usePricingModelManagementViewModel();

  if (isPricingModelsLoading || isPricingModelChangePending) {
    return (
      <div className="my-72 flex justify-center">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="grid sm:grid-cols-12">
      <div className="col-span-3 flex flex-col gap-1 sm:border-r-4">
        <h2 className="mt-2 text-xl font-medium">Pricing Models</h2>

        {hasPricingModels ? (
          <div className="flex flex-grow flex-col gap-y-2">
            <PricingModelList />
            <div className="flex flex-grow flex-col gap-y-2 px-2">
              <PaginationNav {...paginationNavProps} isCondensed />
              <div className="flex justify-center">
                <span>
                  <AddPricingModelButton />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-grow items-center justify-center">
            <PricingModelEmptyState />
          </div>
        )}
      </div>
      <div className="col-span-9">
        {typeof pricingModelError === 'string' && (
          <div className="p-3">
            <FormErrorMessage>{pricingModelError}</FormErrorMessage>
          </div>
        )}

        {selectedPricingModelId ? (
          <SelectedPricingModelDetails />
        ) : (
          <PricingModelEmptyStateMockContent />
        )}
      </div>
    </div>
  );
}
