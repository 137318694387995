import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';

import { addATrip } from './addATrip.slice';

export function AddATripFlowDialog({
  children,
}: {
  children?: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.addATrip.isOpen);

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          onClose={async () => {
            dispatch(addATrip.actions.close());
          }}
        >
          <div className="fixed inset-0 z-20 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative flex h-full flex-col bg-white shadow-xl">
                    {children}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
