import {
  ChargeStatusEnum,
  OverallPaymentStatusEnum,
} from 'src/common/external/bambi-api/bambiApi';

type ColorLabel = {
  color: string;
  label: string;
};

const paymentStatusToColorLabel: Record<OverallPaymentStatusEnum, ColorLabel> =
  {
    overpaid: {
      color: '#FF6C5C',
      label: 'Overpaid',
    },
    paid: {
      color: '#B6E2C1',
      label: 'Paid',
    },
    partially_paid: {
      color: '#F8F5C4',
      label: 'Partially paid',
    },
    unknown: {
      color: '#7C7977',
      label: 'Unknown',
    },
    unpaid: {
      color: '#F8D3D0',
      label: 'Unpaid',
    },
  };

export const chargeStatusToColorLabel: Record<ChargeStatusEnum, ColorLabel> = {
  pending: {
    color: '#C1D8EE',
    label: 'Processing',
  },
  succeeded: {
    color: '#6AA546',
    label: 'Successful',
  },
  failed: {
    color: '#CC4123',
    label: 'Failed',
  },
};

export function usePaymentStatusToLabel(status: OverallPaymentStatusEnum) {
  return paymentStatusToColorLabel[status];
}

export function useChargeStatusToLabel(status: ChargeStatusEnum) {
  return chargeStatusToColorLabel[status];
}
