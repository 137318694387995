import { useFormContext } from 'react-hook-form';

import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { PhoneInput } from 'src/common/primitives/PhoneInput';
import { SelectOption } from 'src/common/primitives/Select';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function LocationSettings() {
  const form = useFormContext();

  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 pb-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Location"
        description="Organization defaults for location."
      />

      <div className="grid grid-cols-2 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-3">
        <div className="col-span-1">
          <FormContextField name="headquarters_location">
            {({ field }) => {
              return (
                <>
                  <FormField
                    label="Headquarters Location"
                    type="location-typeahead"
                    inputProps={{
                      id: 'headquarters_location',
                    }}
                    value={
                      field.value
                        ? {
                            label: field.value?.address,
                            value: field.value?.place_id,
                          }
                        : undefined
                    }
                    onChange={(option: SelectOption) => {
                      form.setValue('headquarters_location', {
                        place_id: option.value,
                        address: option.label,
                      });
                    }}
                    options={
                      field.value
                        ? [
                            {
                              label: field.value?.address,
                              value: field.value?.place_id,
                            },
                          ]
                        : []
                    }
                  />
                </>
              );
            }}
          </FormContextField>
        </div>
        <div className="grid grid-cols-2 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-1">
          <div>
            <PhoneNumberField
              label="Dispatch Phone Number"
              fieldId="dispatch_phone_number"
            />
          </div>
          <div>
            <PhoneNumberField
              label="Dispatch SMS Number"
              fieldId="dispatch_text_number"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface PhoneNumberFieldProps {
  label: string;
  fieldId: string;
}

function PhoneNumberField(props: PhoneNumberFieldProps) {
  const { label, fieldId } = props;
  return (
    <FormContextField name={fieldId}>
      {({ field, fieldState }) => {
        return (
          <DefaultFormFieldLayout
            label={label}
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: fieldId }}
          >
            <PhoneInput {...field} dataTestId={fieldId} />
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
