import * as Accordion from 'src/common/Accordion';
import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryUpdateItemContent } from './TripSummaryUpdateItemContent/TripSummaryUpdateItemContent';
import { TripSummaryUpdateItemHeader } from './TripSummaryUpdateItemHeader';

export function TripSummaryUpdateItem({
  onClick,
  tripEvent,
  isSelected,
}: {
  onClick: () => void;
  tripEvent: TripUpdateRead;
  isSelected: boolean;
}) {
  return (
    <Accordion.Item value={String(tripEvent.id)} onClick={onClick}>
      <div
        className={`relative rounded-lg border border-solid border-[#eaecf0] hover:cursor-pointer`}
      >
        <style>
          {`
            [data-radix-collection-item][data-state="open"] {
              background-color: F9FAFB;
              border-bottom: 2px solid #EAECF0;
            }
          `}
        </style>
        <Accordion.Trigger>
          <TripSummaryUpdateItemHeader
            tripEvent={tripEvent}
            isSelected={isSelected}
          />
        </Accordion.Trigger>

        <Accordion.Content>
          <TripSummaryUpdateItemContent tripEvent={tripEvent} />
        </Accordion.Content>
      </div>
    </Accordion.Item>
  );
}
