import { MapPinIcon } from '@heroicons/react/24/outline';

import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { AssignmentLocationCard } from './AssignmentLocationCard';
import { useAssignmentLiveMapListViewModel } from './useAssignmentLiveMapListViewModel';

export function AssignmentLiveMapList() {
  const { assignmentLocations, selectedAssignmentLocationId, isLoading } =
    useAssignmentLiveMapListViewModel();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingIndicator />
      </div>
    );
  }
  const assignmentLocationsWithLatLng = assignmentLocations?.filter(
    (assignmentLocation) => {
      return (
        assignmentLocation.latest_location.latitude &&
        assignmentLocation.latest_location.longitude
      );
    }
  );

  if (!assignmentLocationsWithLatLng?.length) {
    return (
      <div className="flex justify-center px-[24px] py-[16px]">
        <div className="w-full max-w-sm text-center">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
            <MapPinIcon className="text-mint" />
          </div>
          <h4 className="mt-3 text-gray-700">No Assignment Locations Found</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="flex max-h-[90vh] flex-col gap-y-2 overflow-y-scroll">
      {assignmentLocationsWithLatLng.map((assignmentLocation) => {
        const isSelected =
          assignmentLocation.id === selectedAssignmentLocationId;
        return (
          <AssignmentLocationCard
            key={assignmentLocation.id}
            assignmentLocation={assignmentLocation}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
}
