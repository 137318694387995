import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { PhoneInput } from 'src/common/primitives/PhoneInput';

export function TripBookedBySection() {
  return (
    <div className="flex gap-2">
      <div className="grow">
        <FormContextField name={'booked_by_name'}>
          {({ field, fieldState }) => {
            return (
              <FormField
                label="Requested By Name"
                type="text"
                inputProps={{
                  id: 'booked-by-name',
                  placeholder: 'Name of person requesting trip',
                  ...field,
                }}
              />
            );
          }}
        </FormContextField>
      </div>
      <div className="grow">
        <FormContextField name={'booked_by_phone_number'}>
          {({ field, fieldState }) => {
            return (
              <div>
                <DefaultFormFieldLayout
                  label="Requested By Phone Number"
                  inputProps={{ id: 'booked_by_phone_number' }}
                >
                  <PhoneInput
                    {...field}
                    placeholder="(555) 555-5555"
                    dataTestId="phone"
                  />
                </DefaultFormFieldLayout>
              </div>
            );
          }}
        </FormContextField>
      </div>
      <div className="w-72">
        <FormContextField name={'booked_by_phone_extension'}>
          {({ field, fieldState }) => {
            return (
              <FormField
                label="Requested By Phone Extension"
                type="text"
                inputProps={{
                  id: 'booked-by-phone-extension',
                  ...field,
                }}
              />
            );
          }}
        </FormContextField>
      </div>
    </div>
  );
}
