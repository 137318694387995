import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';

export function WillCallContextField({ fieldPath }: { fieldPath: string }) {
  return (
    <FormContextField name={`${fieldPath}.is_will_call`}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label={'Is this leg will call?'}
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: `${fieldPath}.is_will_call`,
              ...field,
              onChange: field.onChange,
            }}
            type="switch"
          />
        );
      }}
    </FormContextField>
  );
}
