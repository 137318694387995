import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';
export const REASSIGNMENT_PREVIEW_ID_PREFIX = 'reassignment-preview-';

export function isReassignmentPreview(
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead
) {
  return trip.id.includes(REASSIGNMENT_PREVIEW_ID_PREFIX);
}

export function isExistingAssignmentBeingReassigned(
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead,
  reassignmentPreviewTripId: string
) {
  return (
    reassignmentPreviewTripId.includes(REASSIGNMENT_PREVIEW_ID_PREFIX) &&
    trip.id ===
      reassignmentPreviewTripId.replace(REASSIGNMENT_PREVIEW_ID_PREFIX, '')
  );
}
