import { MbscCalendarEvent } from '@mobiscroll/react';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';

import { getCalendarEventStartAndEndTime } from './getCalendarEventStartAndEndTime';
import { useTripCalendarTheme } from './TripCalendarEvent/useTripCalendarTheme';

export function createEventsFromTrips(
  trips:
    | DispatchTripRead[]
    | RunBambiRunTripRead[]
    | RunBambiRunReassignedTripRead[],
  isDispatchArrivalCompletionTimeEnabled = false
): MbscCalendarEvent[] {
  return (
    // This is def not an unnecessary condition
    // DispatchTripRead has assignment_id, RunBambiRunTripRead has assignment
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    trips.map((trip) => {
      // I guess it's bad form to use a react hook outside of a react component
      // but... it's fine. It's fine.
      const tripEventTheme = useTripCalendarTheme(trip);
      const resource =
        'assignment_id' in trip ? trip.assignment_id : trip.assignment.id;

      const { start, end } = getCalendarEventStartAndEndTime(
        trip,
        isDispatchArrivalCompletionTimeEnabled
      );

      return {
        id: trip.id,
        start,
        end,
        title: trip.passenger.full_name,
        resource,
        trip,
        bufferBefore:
          'travel_time_minutes' in trip &&
          typeof trip.travel_time_minutes === 'number'
            ? trip.travel_time_minutes
            : undefined,
        // This will be used for buffer color. Because we're using renderScheduleEvent
        // it has no effect on the actual event color, which is handled by the renderScheduleEvent handler
        // Technically, we only show a buffer if the trip is an active status which is always green
        // but just in case we change that in the future, we'll use the theme here
        // If we need different colors that border, let's add a
        // bufferColor to the theme object
        // Mobiscroll sets an opacity on the buffer element, so we may need darker colors
        // The Active border color looks good though
        color: tripEventTheme.border,
      };
    }) || []
  );
}
