import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { AssignmentLocationRead } from 'src/common/external/bambi-api/bambiApi';

import { useAssignmentLocationCardViewModel } from './useAssignmentLocationCardViewModel';

export type AssignmentLocationCardProps = {
  assignmentLocation: AssignmentLocationRead;
  isSelected?: boolean;
};

export const AssignmentLocationCard = ({
  assignmentLocation,
  isSelected,
}: AssignmentLocationCardProps) => {
  const { onCardClick, formattedDriverName } =
    useAssignmentLocationCardViewModel({
      assignmentLocation,
      isSelected,
    });
  return (
    <div
      className={`relative flex flex-1 flex-row items-center justify-between gap-1.5 rounded-sm border border-solid p-4 hover:cursor-pointer ${
        isSelected
          ? ' border-gray-600 bg-[#c9f2f0]'
          : 'border-[#c9f2f0] bg-gray-50'
      }`}
      onClick={onCardClick}
      data-testid={`assignment-location-card-${assignmentLocation.id}`}
    >
      <div className="flex flex-col gap-1">
        <div className="text-sm font-bold">{formattedDriverName}</div>
        <div className="text-xs">{assignmentLocation.vehicle.nickname}</div>
        {isSelected && <div className="sr-only">Selected</div>}
      </div>
      <div className="flex gap-1">
        {/* TODO: This is expensive/annoying to get currently so we'll do this later */}
        {/* <AssignmentStatus /> */}
        <ChevronRightIcon className="w-7 text-gray-400" />
      </div>
    </div>
  );
};
