import {
  CalendarIcon,
  EllipsisVerticalIcon,
  MapPinIcon,
} from '@heroicons/react/24/solid';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { truncate } from 'lodash-es';

import { useAppSelector } from 'src/app/store';
import { ACTIVE_TRIP_STATUSES } from 'src/common/ACTIVE_TRIP_STATUSES';
import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';

import {
  RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES,
  RunBambiRunCalendarEventType,
} from '../../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { isExistingAssignmentBeingReassigned } from '../tripReassignmentUtils';
import { TripDetailsPopover } from './TripDetailsPopover';
import { TripTitle } from './TripTitle';
import { useTripCalendarTheme } from './useTripCalendarTheme';

type TripCalendarEventProps = {
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
  type?: RunBambiRunCalendarEventType;
};

export function TripCalendarEvent({ trip, type }: TripCalendarEventProps) {
  const theme = useTripCalendarTheme(trip, type);
  const selectedTripId = useAppSelector(
    (state) => state.dispatcher.selectedTripId
  );
  const previewEvents = useAppSelector(
    (state) => state.dispatcher.previewEvents
  );
  const opacityClass =
    type === RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.EXISTING ||
    isExistingAssignmentBeingReassigned(trip, String(selectedTripId)) ||
    isWaitTimeTripBeingReassigned(trip, previewEvents)
      ? 'opacity-40'
      : 'opacity-100';
  return (
    <div className="h-full">
      <TripDetailsPopover trip={trip} type={type}>
        <div
          className={`flex h-full flex-row overflow-hidden p-1 text-xs ${opacityClass} 
          ${trip.status === 'en route' && 'border-t-4'}
          ${trip.status === 'at pickup' && 'border-l-4'}
          ${trip.status === 'transporting' && 'border-r-4'}
          ${trip.status === 'at dropoff' && 'border-b-4'}
          ${!ACTIVE_TRIP_STATUSES.includes(trip.status) && 'border-l-4'}
          `}
          style={{
            backgroundColor: theme.bg,
            borderColor: theme.border,
            borderStyle: theme.borderStyle,
            minHeight: '2rem',
          }}
        >
          <div className="flex w-full flex-1 flex-col gap-1">
            <div
              className="flex w-full justify-between gap-1"
              style={{
                color: theme.text,
              }}
            >
              <TripTitle trip={trip} />
            </div>
            <div>
              <div
                className="flex justify-start gap-1 text-xs"
                style={{
                  color: theme.text,
                }}
                title={trip.pickup_address}
              >
                <MapPinIcon className="h-4 w-4" />
                {truncate(trip.pickup_address, {
                  length: 30,
                  omission: '...',
                })}
              </div>
              <span
                style={{
                  color: theme.text,
                }}
                className="flex h-4 w-4 items-center justify-center"
              >
                <EllipsisVerticalIcon className="h-3 w-3" />
              </span>
              <div
                className="relative flex shrink-0 flex-row items-center justify-start self-stretch"
                style={{
                  font: "400 12px/16px 'Inter', sans-serif",
                  color: theme.text,
                }}
              >
                {trip.appointment_at ? (
                  <CalendarIcon className="h-4 w-4" />
                ) : (
                  <MapPinIcon className="h-4 w-4" />
                )}
                <div
                  className={`relative flex items-center justify-start text-left`}
                  style={{
                    font: "400 12px/16px 'Inter', sans-serif",
                    color: theme.text,
                  }}
                >
                  {truncate(trip.dropoff_address, {
                    length: 30,
                    omission: '...',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </TripDetailsPopover>
    </div>
  );
}
function isWaitTimeTripBeingReassigned(
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead,
  previewEvents: MbscCalendarEvent[] | undefined
): boolean {
  const previewEventIds = previewEvents?.map((event) => event.id) || [];
  return previewEventIds.some((id) => id === `${trip.id}-preview`);
}
