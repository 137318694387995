import { useAppSelector } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { DragHandleIcon } from 'src/common/primitives/icon/DragHandleIcon';

import { PricingRuleAccordionHeaderEdit } from './PricingRuleAccordionHeaderEdit';
import { PricingRuleAccordionHeaderView } from './PricingRuleAccordionHeaderView';

export function PricingRuleAccordionHeader({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const pricingRuleDrafts = useAppSelector(
    (state) => state.pricingModel.pricingRuleDrafts
  );
  const isEditingCurrentRule = !!pricingRuleDrafts?.some(
    (draft) => draft.id === pricingRule.id
  );
  return (
    <div
      className="pricing-rule-container flex w-full items-center justify-start pt-2 pr-6 pb-2 pl-6 transition-colors"
      data-testid={`pricing-rule-header-${pricingRule.id}`}
    >
      <DragHandleIcon className="cursor-pointer" />
      {isEditingCurrentRule ? (
        <PricingRuleAccordionHeaderEdit pricingRule={pricingRule} />
      ) : (
        <PricingRuleAccordionHeaderView pricingRule={pricingRule} />
      )}
    </div>
  );
}
