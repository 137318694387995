import { dateTimeToTime } from 'src/common/date/dateTimeToTime';
import { AssignmentBreak } from 'src/common/external/bambi-api/bambiApi';

export const assignDefaultFormValues = (
  assignmentBreak: AssignmentBreak | null
) => {
  if (!assignmentBreak) return defaultFormValues;

  const { id, start, end, break_notes } = assignmentBreak;

  return {
    id,
    startTime: dateTimeToTime(start),
    endTime: dateTimeToTime(end),
    notes: break_notes,
  };
};

const defaultFormValues = { startTime: '', endTime: '', notes: '' };
