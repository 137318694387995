import { PencilIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import {
  Attendant,
  AttendantRead,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import {
  setSelectedAttendantProfile,
  setShowAttendantProfileModal,
} from '../team.slice';

export function AttendantRowActions({ attendant }: { attendant: Attendant }) {
  const dispatch = useAppDispatch();
  const { isRestrictedSupport } = useUserRoles();
  function handleEditAttendantProfile() {
    dispatch(
      setSelectedAttendantProfile({
        ...(attendant as AttendantRead),
      })
    );
    dispatch(setShowAttendantProfileModal(true));
  }
  return (
    <Button
      className="group cursor-pointer"
      variant="ghost"
      onClick={() => handleEditAttendantProfile()}
      ariaLabel="Edit"
      disabled={isRestrictedSupport}
    >
      <PencilIcon className="w-[24px] group-hover:text-mint" />
    </Button>
  );
}
