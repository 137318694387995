export function CarIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="car-icon"
    >
      <path
        d="M18.7299 8.57422L18.267 7.76563C18.2462 7.72998 18.2121 7.70402 18.1722 7.6934C18.1323 7.68279 18.0899 7.68839 18.0541 7.70898L17.0639 8.28711L15.5346 4.06445C15.4538 3.81249 15.2951 3.5927 15.0813 3.43683C14.8675 3.28096 14.6097 3.19707 14.3451 3.19727H5.88224C5.2045 3.19727 4.60294 3.63477 4.39395 4.28125L2.93692 8.28906L1.94474 7.71094C1.90896 7.69035 1.8665 7.68474 1.82661 7.69536C1.78672 7.70597 1.75265 7.73193 1.73185 7.76758L1.26895 8.57422C1.22599 8.64844 1.25138 8.74219 1.3256 8.78516L2.50528 9.47266L2.22208 10.2539C2.19864 10.3164 2.18692 10.3828 2.18692 10.4492V17.25C2.18692 17.5566 2.41739 17.8047 2.7006 17.8047H4.02091C4.26114 17.8047 4.47013 17.623 4.52091 17.3691L4.6713 16.6328H15.3275L15.4779 17.3691C15.5307 17.623 15.7377 17.8047 15.9779 17.8047H17.2983C17.5815 17.8047 17.8119 17.5566 17.8119 17.25V10.4492C17.8119 10.3828 17.8002 10.3164 17.7768 10.2539L17.4936 9.47266L18.6713 8.78516C18.7068 8.76468 18.7328 8.73111 18.7437 8.69167C18.7547 8.65222 18.7497 8.61005 18.7299 8.57422V8.57422ZM5.15567 12.6289C4.72403 12.6289 4.37442 12.2793 4.37442 11.8477C4.37442 11.416 4.72403 11.0664 5.15567 11.0664C5.58731 11.0664 5.93692 11.416 5.93692 11.8477C5.93692 12.2793 5.58731 12.6289 5.15567 12.6289ZM12.7338 14.0938C12.7338 14.1797 12.6635 14.25 12.5775 14.25H7.4213C7.33536 14.25 7.26505 14.1797 7.26505 14.0938V12.4531C7.26505 12.3672 7.33536 12.2969 7.4213 12.2969H8.20255C8.28849 12.2969 8.3588 12.3672 8.3588 12.4531V13.1563H11.64V12.4531C11.64 12.3672 11.7104 12.2969 11.7963 12.2969H12.5775C12.6635 12.2969 12.7338 12.3672 12.7338 12.4531V14.0938ZM14.8432 12.6289C14.4115 12.6289 14.0619 12.2793 14.0619 11.8477C14.0619 11.416 14.4115 11.0664 14.8432 11.0664C15.2748 11.0664 15.6244 11.416 15.6244 11.8477C15.6244 12.2793 15.2748 12.6289 14.8432 12.6289ZM4.2963 8.66406L5.71622 4.75977L5.72599 4.73438L5.7338 4.70898C5.75528 4.64453 5.81388 4.60156 5.88224 4.60156H14.2338L15.7065 8.66406H4.2963Z"
        fill="#FCFCFD"
      />
    </svg>
  );
}
