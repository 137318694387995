import * as RadixToggleGroup from '@radix-ui/react-toggle-group';

import { SelectOption } from 'src/common/primitives/Select';

const base = `justify-center flex-grow relative flex items-center border border-gray-300 px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500`;
const first = 'rounded-l-md';
const last = 'rounded-r-md';

export function ToggleGroup({
  options,
  value,
  onChange,
  activeClass = 'data-[state=on]:bg-white data-[state=on]:text-gray-700 border-w-3',
  inactiveClass = 'data-[state=off]:bg-gray-400 data-[state=off]:text-white',
}: {
  options: SelectOption[];
  value?: string;
  onChange: (newValue: string) => void;
  activeClass?: string;
  inactiveClass?: string;
}) {
  return (
    <RadixToggleGroup.Root
      type="single"
      value={value}
      onValueChange={(value: any) => {
        if (value) onChange(value);
      }}
      className="isolate flex rounded-md bg-gray-400 shadow-sm"
    >
      {options.map((type, index, array) => (
        <RadixToggleGroup.Item
          value={type.value}
          className={`${activeClass} ${inactiveClass} ${base} ${
            index === 0 ? first : ''
          } ${index === array.length - 1 ? last : ''}`}
          key={type.value}
        >
          {type.label}
        </RadixToggleGroup.Item>
      ))}
    </RadixToggleGroup.Root>
  );
}
