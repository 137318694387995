import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';
import { useUserRoles } from 'src/features/auth/useUserRoles';

export function TripStatusBadge({
  status,
}: {
  status: OverrideStatusEnum | undefined;
}) {
  const role = useUserRoles();

  let color = '';

  switch (status) {
    case 'canceled':
    case 'rejected':
      color = 'red';
      break;
    case 'transporting':
      color = 'yellow';
      break;
    case 'pending':
      color = 'orange';
      break;
    case 'completed':
      color = 'green';
      break;
    case 'en route':
    default:
      color = 'primary';
  }

  const tripStatus =
    role.isRideOrderingPortalUser && status === 'pending' ? 'accepted' : status;

  return <Badge label={tripStatus || 'unknown'} color={color} />;
}
