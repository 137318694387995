import { ReactNode } from 'react';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';

import { ListCell } from 'src/common/ListCell';
import { SkillBadge } from 'src/common/SkillBadge';

import { DateCell } from './DateCell';
import { DriverAppVersionCell } from './DriverAppVersionCell';
import { DriverProfile } from './DriverProfile';
import { DriverProfileCell } from './DriverProfileCell';
import { DriverProfileRowActions } from './DriverProfileRowActions';

const columnHelper = createColumnHelper<DriverProfile>();

function renderSkillComponent(value: string): ReactNode {
  // TODO: Refactor ListCell to define the key there instead of here
  // This way this doesn't need to know it's being used in a list
  return <SkillBadge key={value} skill={value} />;
}

export const columns = [
  columnHelper.accessor('name', {
    id: 'first_name',
    enableSorting: true,
    header: 'Name',
    cell: (props) => <DriverProfileCell driverProfile={props.row.original} />,
  }),
  columnHelper.accessor('status', {
    header: 'Active',
    id: 'can_be_scheduled',
    enableSorting: true,
    cell: ({ getValue }) =>
      getValue() ? (
        <CheckIcon className="h-7 w-7 text-green-600" />
      ) : (
        <XMarkIcon className="h-7 w-7 text-red-600" />
      ),
  }),
  columnHelper.accessor('licenseNumber', {
    id: 'license_number',
    enableSorting: true,
    header: 'License Number',
  }),
  columnHelper.accessor('issuedState', {
    id: 'license_state',
    enableSorting: true,
    header: 'Issued State',
  }),
  columnHelper.accessor('expirationDate', {
    id: 'license_expiration_date',
    enableSorting: true,
    header: 'Expiration Date',
    cell: ({ getValue }) => {
      return getValue() ? <DateCell date={getValue()} /> : null;
    },
  }),
  columnHelper.accessor('appVersion', {
    id: 'app_version',
    header: 'App Version',
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? <DriverAppVersionCell appVersion={value} /> : null;
    },
  }),
  columnHelper.accessor('vehicleSkills', {
    header: 'Vehicle Skills',
    cell: ({ getValue }) => (
      <ListCell values={getValue()} renderComponent={renderSkillComponent} />
    ),
  }),
  columnHelper.accessor('serviceSkills', {
    header: 'Service Skills',
    cell: ({ getValue }) => (
      <ListCell values={getValue()} renderComponent={renderSkillComponent} />
    ),
  }),
  columnHelper.accessor('otherSkills', {
    header: 'Other Skills',
    cell: ({ getValue }) => (
      <ListCell values={getValue()} renderComponent={renderSkillComponent} />
    ),
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Edit',
    cell: (props) => (
      <DriverProfileRowActions driverProfile={props.row.original} />
    ),
  }),
];
