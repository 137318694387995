import { Channel, ChannelBox } from '@nessprim/planby-pro';
import { useDispatch } from 'react-redux';

import { VehicleRead } from 'src/common/external/bambi-api/bambiApi';
import {
  setSelectedVehicle,
  setShowAssignmentManagementModal,
} from 'src/features/team/assignmentManagement.slice';

interface ChannelItemProps {
  channel: Channel;
  vehicle: VehicleRead;
  hasAssignment: boolean;
}

export const ChannelItem = ({
  channel,
  vehicle,
  hasAssignment,
}: ChannelItemProps) => {
  const dispatch = useDispatch();
  const { position } = channel;
  const isFirst = position.top === 0;

  const handleVehicleClick = () => {
    dispatch(setSelectedVehicle(vehicle));
    dispatch(setShowAssignmentManagementModal(true));
  };

  return (
    <ChannelBox {...position} className="pr-[10px]">
      <div
        className={`flex h-full w-full cursor-pointer items-center justify-between border 
        border-l-transparent pr-2 hover:bg-gray-200 
        ${!isFirst && 'border-t-transparent'} 
        ${!hasAssignment && 'opacity-50'}`}
        onClick={() => {
          handleVehicleClick();
        }}
      >
        <div>
          <span className="text-gray-900">{vehicle.nickname}</span>
          <p className="text-gray-500">{`${vehicle.make} ${vehicle.model} ${vehicle.year} / ${vehicle.color}`}</p>
        </div>
      </div>
    </ChannelBox>
  );
};
