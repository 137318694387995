import { ObservedHolidaysEnum } from 'src/common/external/bambi-api/bambiApi';

export const observedHolidays: ObservedHolidaysEnum[] = [
  "New Year's Day",
  'Martin Luther King Jr. Day',
  "Washington's Birthday",
  'Memorial Day',
  'Juneteenth',
  'Independence Day',
  'Labor Day',
  'Columbus Day',
  'Veterans Day',
  'Thanksgiving',
  'Christmas',
];
