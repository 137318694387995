import { UserPlusIcon } from '@heroicons/react/24/outline';

import { MemberForm } from '../MemberForm/MemberForm';

export function MemberEmptyState() {
  return (
    <div className="flex justify-center px-[24px] py-12">
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <UserPlusIcon className="mx-auto h-6 w-auto text-gray-700" />
        </div>
        <h4 className="mt-3 text-gray-700">Invite Teammates</h4>
        <p className="text-gray-400">
          You don’t have any mates in your team yet!
        </p>
        <div className="mt-3 text-gray-700">
          <MemberForm />
        </div>
      </div>
    </div>
  );
}
