import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import TripSubscriptionsManagementRoute from './management/TripSubscriptionManagementRoute';

export const tripSubscriptionsRoutes: RouteObject[] = [
  {
    index: true,
    element: <TripSubscriptionsManagementRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute
        routePath="/trips/subscriptions"
        linkText="Go back to trip subscriptions"
      />
    ),
  },
];
