import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';
import { PhoneNumberLink } from 'src/features/dispatch/DispatchCalendar/TripCalendarEvent/PhoneNumberLink';

export function TripPhoneChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const old = tripEvent.values_changed?.[valueKey].old_value;
  const newPhone = tripEvent.values_changed?.[valueKey].new_value;
  return (
    <div className="flex items-center gap-1">
      {old ? <PhoneNumberLink phoneNumber={old} /> : <div>None</div>}
      <ArrowLongRightIcon className="h-5 w-5" />
      {newPhone ? <PhoneNumberLink phoneNumber={newPhone} /> : <div>None</div>}
    </div>
  );
}
