import React from 'react';

import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { TextInput } from 'src/common/primitives/TextInput';

import { defaultCurrencyFormat } from './defaultCurrencyFormat';

export const CurrencyInput = React.forwardRef(
  (
    {
      customInput = TextInput,
      ...props
    }: NumericFormatProps & {
      'data-testid'?: string;
    },
    forwardedRef: React.Ref<HTMLInputElement>
  ) => {
    return (
      <NumericFormat
        getInputRef={forwardedRef}
        prefix="$"
        decimalScale={2}
        fixedDecimalScale
        customInput={customInput}
        placeholder="$0.00"
        thousandSeparator=","
        {...props}
        allowNegative={props.allowNegative || false}
        onValueChange={(values, source) => {
          // values has 3 members: formattedValue (string with prefix: '$0.00'), value: (string without prefix: '0.00), floatValue: (number with decimal: 0.00)
          // Our application erroneously assumes that floatValue is an integer because in a previous implementation we used a formatter instead of decimalScale, etc
          // Using the formatter indeed causes floatValue to be an integer, but ended up causing react-number-format to lose its mind with the cursor position
          // Might be nice to clean up the api of our wrapper to return a properly named value but for now we'll just fix the floatValue
          if (typeof values.floatValue === 'number') {
            values.floatValue = Math.round(values.floatValue * 100);
          }
          props.onValueChange?.(values, source);
        }}
        value={
          props.value
            ? defaultCurrencyFormat(ensureOnlyOneNegativeSign(props.value))
            : undefined
        }
        defaultValue={
          props.defaultValue
            ? defaultCurrencyFormat(
                ensureOnlyOneNegativeSign(props.defaultValue)
              )
            : undefined
        }
        // Not doing this would add the prefix once the user starts typing
        // but this felt better to me. Not a product choice so feel free to remove
        onFocus={(event) => {
          if (event.target.value === '') event.target.value = '$';
        }}
        onBlur={(event) => {
          if (event.target.value === '$') event.target.value = '';
        }}
        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
          // If there's a hyphen, remove non-numeric and check if value is 0
          if (props.allowNegative && event.target.value.includes('-')) {
            event.target.value = String(
              ensureOnlyOneNegativeSign(event.target.value)
            );
          }
          props.onInput?.(event);
        }}
      />
    );
  }
);

// This function ensures that there is only one negative sign in the value
// It maintains previous behavior of assuming shenanigans if more than one negative sign is present
// In this case, it removes negative sign.
function ensureOnlyOneNegativeSign(value: string | number) {
  if (typeof value === 'number') {
    return value;
  }
  if (value.includes('-')) {
    const hyphenCharCount = (value.match(new RegExp('-', 'g')) || []).length;
    const hyphenChar = hyphenCharCount === 0 || hyphenCharCount > 1 ? '' : '-';
    return `${hyphenChar}${value.replace(/-/g, '')}`;
  }
  return value;
}
