import { ConfirmModalProps } from 'src/common/modals/ConfirmModal';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { useDispatchModalToShow } from 'src/features/dispatch/dispatcher.slice';

import { AcceptRequestTripDetails } from './AcceptRequestTripDetails';
import { useAcceptRequestTripDetails } from './useAcceptTripDetails';
import { useAcceptTripRequestConfirmModal } from './useAcceptTripRequestConfirmModal';

export const useAcceptTripRequestConfirmModalProps = (): ConfirmModalProps => {
  const modalToShow = useDispatchModalToShow();
  const { error, onAcceptConfirm, dismissModal } =
    useAcceptTripRequestConfirmModal();

  const { trips, isLoading, assignment } = useAcceptRequestTripDetails();

  return {
    open: modalToShow === 'acceptRequestConfirm',
    setOpen: (isOpen) => {
      if (!isOpen) {
        dismissModal();
      }
    },
    onCancel: () => dismissModal(),
    onConfirm: () => onAcceptConfirm(),
    title: trips.length > 1 ? 'Accept these trips?' : 'Accept this trip?',
    error,
    confirmText: 'Accept',
    mode: 'success',
    children: isLoading ? (
      <div className="flex justify-center">
        <LoadingIndicator />
      </div>
    ) : (
      <AcceptRequestTripDetails trips={trips} assignment={assignment} />
    ),
    contentClassnames: 'sm:max-w-full',
  };
};
