import { useEffect } from 'react';

import { useMap } from 'react-map-gl';

import { LatLong } from '../external/aws/LatLong';
import { fitBoundsToPoints } from './fitBoundsToPoints';

// could break out separate funcs for adding/removing route and all routes
// Mostly left it to keep examples of how to do things near each other
// Also possible to make this more declarative
// https://github.com/visgl/react-map-gl/issues/591 has some good threads on
// options for this, including using deck.gl for more complex route plotting
// if we need it.

// This really only needs more attention if we're going to be plotting multiple routes
// Both of oure current use cases are single routes at a time
export function useMapRoute(
  routeId: string,
  orderLocations: LatLong[],
  shouldPlotRoute: boolean
) {
  const { current: mapRef } = useMap();
  // We want to make sure that we refire this effect when the map loads and when the style loads
  // not just when map is initialized.
  const isMapStyleLoaded = mapRef?.isStyleLoaded();
  const isMapLoaded = mapRef?.loaded();
  useEffect(() => {
    // Wrap in try/catch to prevent the route plotting from crashing the app
    try {
      if (mapRef && isMapLoaded && isMapStyleLoaded) {
        const stringLineSourceId = `${routeId}-source`;
        const stringLineLayerId = `${routeId}-layer`;
        const mapGLMapInstance = mapRef.getMap();
        // We're either rerendering the route or removing it
        // Things get ugly if we try to add duplicate layer/source-ids
        if (mapGLMapInstance.getLayer(stringLineLayerId)) {
          mapGLMapInstance.removeLayer(stringLineLayerId);
        }
        if (mapGLMapInstance.getSource(stringLineSourceId)) {
          mapGLMapInstance.removeSource(stringLineSourceId);
        }

        if (shouldPlotRoute) {
          fitBoundsToPoints(mapRef, orderLocations);
          mapGLMapInstance.addSource(stringLineSourceId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: orderLocations.map((location) => [
                  location.long,
                  location.lat,
                ]),
              },
            },
          });

          mapGLMapInstance.addLayer({
            id: stringLineLayerId,
            type: 'line',
            source: stringLineSourceId,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#0CACA6',
              'line-width': 5,
            },
          });
        }
      }
    } catch (e) {
      console.error('useMapRoute: Error adding route to map', e);
    }
  }, [
    mapRef,
    routeId,
    orderLocations,
    orderLocations.length,
    isMapLoaded,
    isMapStyleLoaded,
    shouldPlotRoute,
  ]);
}
