import { BambiMapView } from 'src/common/BambiMapView/BambiMapView';
import { AssignmentMarkers } from 'src/features/live-map/LiveMap/AssignmentLiveMapView/AssignmentMarkers';

export function AssignmentLiveMapView() {
  return (
    <BambiMapView>
      <AssignmentMarkers />
    </BambiMapView>
  );
}
