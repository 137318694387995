import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { usePricingPricingModelsPartialUpdateMutation } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';

import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';

export function PricingModelIsDefaultQuickAction() {
  const selectedPricingModel = useSelectedPricingModel();
  const [updatePricingModel, updatePricingModelState] =
    usePricingPricingModelsPartialUpdateMutation();
  if (!selectedPricingModel) {
    return null;
  }
  return selectedPricingModel.is_default ? (
    <Button
      className="flex gap-1"
      disabled={updatePricingModelState.isLoading}
      onClick={async () => {
        if (typeof selectedPricingModel.id === 'string') {
          updatePricingModel({
            id: selectedPricingModel.id,
            patchedPricingModel: {
              ...selectedPricingModel,
              is_default: false,
            },
          });
        }
      }}
    >
      <XCircleIcon className="h-5 w-5" /> Undo Default
    </Button>
  ) : (
    <Button
      className="flex gap-1"
      disabled={updatePricingModelState.isLoading}
      onClick={async () => {
        if (typeof selectedPricingModel.id === 'string') {
          updatePricingModel({
            id: selectedPricingModel.id,
            patchedPricingModel: {
              ...selectedPricingModel,
              is_default: true,
            },
          });
        }
      }}
    >
      <CheckCircleIcon className="h-5 w-5" /> Mark as Default
    </Button>
  );
}
