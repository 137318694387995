import { useFormContext } from 'react-hook-form';

export function useAdditionalLeg() {
  const { getValues, setValue } = useFormContext();

  return () => {
    const formData = getValues();
    const { trips } = formData;

    const firstLeg = trips[0];
    const additionalLeg = {
      pickupLocation: {
        ...firstLeg.dropoffLocation,
      },
      dropoffLocation: {
        address: '',
        address2: '',
        stairs: 0,
        contact_name: '',
        contact_phone_number: '',
      },
      timing: {
        date: firstLeg?.timing.date,
        appointment_time: '',
        pickup_time: '',
        is_will_call: false,
      },
      dispatcher_notes: '',
    };
    setValue('trips', [...trips, additionalLeg]);
  };
}
