import { ReactComponent as Image } from 'src/assets/tilled-logo.svg';

export function TilledLogo() {
  return (
    <Image
      style={{ backgroundColor: 'rgb(25,31,46)' }}
      className="p-2"
      width={100}
      height={48}
    />
  );
}
