import { AssignmentTripRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';
import { PhoneNumberLink } from 'src/features/dispatch/DispatchCalendar/TripCalendarEvent/PhoneNumberLink';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

import AssignmentPopupEstimatedTripTiming from './AssignmentPopupEstimatedTripTiming';
import { FormattedLocation } from './FormattedLocation';
import { LocationPinIcon } from './LocationPinIcon';
import { PeopleIcon } from './PeopleIcon';
import { PhoneIcon } from './PhoneIcon';

export function AssignmentPopupActiveTrips({
  activeTrips,
}: {
  activeTrips: AssignmentTripRead[];
}) {
  if (!activeTrips.length || activeTrips.length === 0) {
    // This probably shouldn't happen, but given https://bambihealth.atlassian.net/browse/BAM-1063
    // For some selectedAssignmentLocations, activeTrips[0] is undefined
    return <div>No Active Trips</div>;
  }

  return (
    // TODO: Implement list
    <>
      <div className="flex flex-col items-start justify-start gap-[6px]">
        <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-teal-50 py-[3px] px-2">
          <div className="flex flex-row items-center justify-start">
            <div className="relative font-medium">Passenger</div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[4px] text-left text-sm text-gray-600">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <PeopleIcon />
            <div className="relative inline-block shrink-0 font-medium">
              {formatFullName(activeTrips[0].passenger)}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[8px] text-xs">
            <PhoneIcon />
            <PhoneNumberLink
              phoneNumber={activeTrips[0].passenger.phone_number}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-center gap-[6px]">
        <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-warning-50 py-[3px] px-2">
          <div className="flex flex-row items-center justify-start">
            <div className="relative font-medium">Trip Destination</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[8px] text-left text-sm text-gray-600">
          <LocationPinIcon />
          <FormattedLocation location={activeTrips[0].dropoff.location} />
        </div>
      </div>
      <div className="flex flex-col items-start justify-center gap-[6px]">
        <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-warning-50 py-[3px] px-2">
          <div className="flex flex-row items-center justify-start">
            <div className="relative font-medium">Status</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[8px] text-left text-sm text-gray-600">
          <TripStatusBadge status={activeTrips[0].status} />
        </div>
      </div>
      <AssignmentPopupEstimatedTripTiming trip={activeTrips[0]} />
    </>
  );
}
