import { Logo } from 'src/common/Logo';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { useAuth } from './useAuth';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  if (auth.loading) {
    return (
      <div
        className="flex h-[100vh] w-[100vw] flex-col items-center justify-center gap-2"
        data-testid="loading"
      >
        <Logo />
        <LoadingIndicator />
      </div>
    );
  }

  return auth.user ? children : null;
}
