import {
  MembershipRead,
  useMembersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

export function useNonDriverMembers(): {
  members: MembershipRead[];
  queryState: any;
} {
  const auth = useAuth();
  const queryState = useMembersListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
    isDriver: false,
  });

  return {
    members: queryState.data?.results || [],
    queryState,
  };
}
