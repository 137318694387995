import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramItem,
  ProgramStack,
  ProgramText,
  useProgram,
} from '@nessprim/planby-pro';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { AttendantBadgeList } from 'src/common/AttendantBadgeList';
import { DriverBadge } from 'src/common/DriverBadge';
import { AssignmentRead } from 'src/common/external/bambi-api/bambiApi';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import {
  setSelectedAssignment,
  setShowAssignmentManagementModal,
} from '../../assignmentManagement.slice';

type AssignmentItemProps = ProgramItem & {
  assignment?: AssignmentRead;
};

export const AssignmentItem = ({
  program,
  assignment,
  ...rest
}: AssignmentItemProps) => {
  const dispatch = useAppDispatch();
  const { styles, formatTime, set12HoursTimeFormat, isLive } = useProgram({
    program,
    ...rest,
  });
  const assignments = useAppSelector(
    (state) => state.assignmentManagement.assignments
  );
  const { isRestrictedSupport } = useUserRoles();

  const { data } = program;
  const { since, till } = data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  const isFirst = styles.position.top === 0;

  const handleEditAssignment = () => {
    if (isRestrictedSupport) return;
    const assignment = assignments.find(
      (assignment) => assignment.id === data.id
    );
    dispatch(setSelectedAssignment(assignment));
    dispatch(setShowAssignmentManagementModal(true));
  };

  return (
    <ProgramBox
      width={styles.width}
      style={styles.position}
      className={`rounded border-b ${isFirst ? 'border-t' : ''}`}
      onClick={() => handleEditAssignment()}
    >
      <ProgramContent
        width={styles.width}
        isLive={isLive}
        className="bg-gray-300"
      >
        <ProgramFlex className="w-full">
          <ProgramStack className="flex w-full flex-col justify-between overflow-x-auto">
            <ProgramText>
              <div className="flex justify-between">
                <div>
                  {sinceTime} - {tillTime} | {assignment?.trip_count || 0}{' '}
                  Trip(s){' '}
                </div>
                {!isRestrictedSupport && (
                  <button onClick={() => handleEditAssignment()}>Edit</button>
                )}
              </div>
            </ProgramText>
            <div className="flex gap-2">
              {assignment?.driver && <DriverBadge driver={assignment.driver} />}
              {assignment?.attendants && (
                <AttendantBadgeList attendants={assignment.attendants} />
              )}
            </div>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
