import {
  TagRead,
  TripRead,
  TripTagRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export const selectOptionsToTripTripTags = (
  selectedTags: SelectOption[],
  tags: TagRead[],
  selectedTrip: TripRead | undefined
): TripTagRead[] => {
  return selectedTags.map((selectedTag) => {
    const tripTag = tags.find((t) => t.id === selectedTag.value);
    if (!tripTag) {
      throw new Error(`Tag ${selectedTag.value} not found`);
    }

    if (!selectedTrip) {
      throw new Error('Trip not found');
    }

    return {
      tag_name: tripTag.tag_name,
      color_hex: tripTag.color_hex,
      trip: selectedTrip.id,
      tag: tripTag.id,
    } as TripTagRead;
  });
};
