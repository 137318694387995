import { useFeature } from 'src/app/FeatureToggle';
import { useOrganizationSettingsRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { useUserRoles } from 'src/features/auth/useUserRoles';

export type UseCanCapturePaymentMethodResult = {
  isLoading: boolean;
  isEnabled: boolean;
  onlyInvoicingEnabled: boolean;
};

export function useCanCapturePaymentMethod(): UseCanCapturePaymentMethodResult {
  const { isEnabled, isLoading: isLoadingFlag } = useFeature(
    'tilled payment capture'
  );

  const { data, isLoading: isLoadingSettings } =
    useOrganizationSettingsRetrieveQuery({});

  const { isRideOrderingPortalUser } = useUserRoles();

  if (isRideOrderingPortalUser) {
    return {
      isLoading: false,
      isEnabled: false,
      onlyInvoicingEnabled: false,
    };
  }

  return {
    isLoading: isLoadingFlag || isLoadingSettings,
    isEnabled:
      isEnabled &&
      !!data?.payment_account_id &&
      data.payment_account_status === 'active',
    onlyInvoicingEnabled:
      isEnabled &&
      (!data?.payment_account_id || data.payment_account_status !== 'active'),
  };
}
