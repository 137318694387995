import { useAppDispatch } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { onPricingRuleEditChange } from 'src/features/pricingModel/pricingModel.slice';

import PricingRuleUnitsCoveredSelect from './PricingRuleUnitsCoveredSelect';

export default function PricingRuleUnitsCovered({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const dispatch = useAppDispatch();
  return (
    <div className="flex items-center">
      <div className="mr-2 block text-sm font-medium leading-6 text-gray-700">
        Units Covered:
      </div>
      <div className="flex items-center gap-2">
        <PricingRuleUnitsCoveredSelect
          value={pricingRule.units_covered_range_start}
          onChange={(val) => {
            dispatch(
              onPricingRuleEditChange({
                ...pricingRule,
                units_covered_range_start: val,
              })
            );
          }}
        />
        <div>to</div>
        <PricingRuleUnitsCoveredSelect
          value={pricingRule.units_covered_range_end}
          onChange={(val) => {
            dispatch(
              onPricingRuleEditChange({
                ...pricingRule,
                units_covered_range_end: val,
              })
            );
          }}
        />
      </div>
    </div>
  );
}
