import { ForwardedRef, forwardRef, useState } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import * as RadixPopover from '@radix-ui/react-popover';

import { CurrencyInput } from 'src/common/CurrencyInput';
import { FormField } from 'src/common/FormField';
import { Popover } from 'src/common/primitives/Popover';

import { Button } from '../Button';

export type NumberRangeValue = {
  min?: number;
  max?: number;
};

export function hasRangeFilter(range?: NumberRangeValue) {
  return (
    (range?.min !== undefined && !isNaN(range.min)) ||
    (range?.max !== undefined && !isNaN(range.max))
  );
}

export interface NumberRangeFilterPopoverProps {
  entityLabel: string;
  triggerElement: React.ReactNode;
  value?: NumberRangeValue;
  type?: 'currency' | 'number';
  open?: boolean;
  onChange: (value: NumberRangeValue) => void;
  onClose?: () => void;
}
export function NumberRangeFilterPopover({
  entityLabel,
  triggerElement,
  value,
  type = 'number',
  open,
  onChange,
  onClose,
}: NumberRangeFilterPopoverProps) {
  const [range, setRange] = useState<NumberRangeValue>();

  return (
    <Popover
      open={open}
      trigger={triggerElement}
      onOpenChange={(isOpen) => {
        setRange(value);
        if (!isOpen) {
          onClose?.();
        }
      }}
      content={
        <div className="flex max-h-80 w-72 flex-col justify-between px-1">
          <div
            className="flex h-fit flex-col gap-2 overflow-y-auto"
            data-testid="filter-popover-content"
          >
            {type === 'number' ? (
              <>
                <FormField
                  type="number"
                  label={`Min ${entityLabel}`}
                  inputProps={{
                    id: 'min',
                    min: 0,
                    defaultValue: value?.min,
                    onChange: (e) => {
                      setRange((current) => {
                        const update = parseInt(e.target.value);
                        return {
                          ...current,
                          min: update,
                        };
                      });
                    },
                  }}
                />
                <FormField
                  type="number"
                  label={`Max ${entityLabel}`}
                  inputProps={{
                    id: 'max',
                    min: 0,
                    defaultValue: value?.max,
                    onChange: (e) => {
                      setRange((current) => {
                        const update = parseInt(e.target.value);
                        return {
                          ...current,
                          max: update,
                        };
                      });
                    },
                  }}
                />
              </>
            ) : (
              <>
                <label className="flex flex-col text-sm font-medium text-gray-700">
                  Min {entityLabel}
                  <CurrencyInput
                    data-testid="min"
                    defaultValue={value?.min}
                    onValueChange={({ floatValue }) => {
                      setRange((current) => {
                        return {
                          ...current,
                          min: floatValue,
                        };
                      });
                    }}
                  />
                </label>
                <label className="flex flex-col text-sm font-medium text-gray-700">
                  Max {entityLabel}
                  <CurrencyInput
                    data-testid="max"
                    defaultValue={value?.max}
                    onValueChange={({ floatValue }) => {
                      setRange((current) => {
                        return {
                          ...current,
                          max: floatValue,
                        };
                      });
                    }}
                  />
                </label>
              </>
            )}
          </div>
          <div className="grid grid-cols-2 border-t border-gray-200 pt-4">
            <RadixPopover.Close className="flex">
              <div className="focus:ring-indigo-500 mr-2 inline-flex flex-grow items-center justify-center rounded border border-gray-100 bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50">
                Cancel
              </div>
            </RadixPopover.Close>
            <RadixPopover.Close
              className="focus:ring-indigo-500 inline-flex flex-grow items-center justify-center rounded border border-mint bg-mint px-3 py-2 text-sm font-medium text-white hover:bg-mint focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                onChange(range ?? ({} as NumberRangeValue));
                onClose?.();
              }}
            >
              Apply
            </RadixPopover.Close>
          </div>
        </div>
      }
    />
  );
}

export const NumberRangeFilterPopoverTrigger = forwardRef(
  function NumberRangeFilterPopoverTrigger(
    { label, hasValue, ...rest }: { label: string; hasValue: boolean },
    ref: ForwardedRef<HTMLButtonElement>
  ) {
    return (
      <Button
        dataTestId="number-range-filter-popover-trigger"
        className="relative"
        variant={hasValue ? 'primary-outline' : undefined}
        ref={ref}
        {...rest}
      >
        <AdjustmentsHorizontalIcon
          className={`mr-2 h-4 w-4 ${hasValue ? 'stroke-mint' : ''}`.trim()}
        />
        {label}
      </Button>
    );
  }
);
