import { useCallback, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch } from 'src/app/store';
import {
  BillingSettingsRead,
  useBillingBillingSettingsPartialUpdateMutation,
  useBillingBillingSettingsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import BillingSettingsForm from './BillingSettingsForm';

export default function BillingSettingsScreen() {
  const dispatch = useAppDispatch();
  const form = useForm<BillingSettingsRead>({
    defaultValues: {},
  });

  const { data: currentSettings, isLoading } =
    useBillingBillingSettingsRetrieveQuery({});

  useEffect(() => {
    if (isLoading) {
      return;
    }

    form.reset(currentSettings);
  }, [isLoading, currentSettings, form]);

  const [updateBillingSettings] =
    useBillingBillingSettingsPartialUpdateMutation();

  const handleSubmit = useCallback(
    async (data: BillingSettingsRead) => {
      try {
        await updateBillingSettings({
          patchedBillingSettings: {
            ...data,
          },
        }).unwrap();
        dispatch(
          show({
            type: 'success',
            title: 'Updated billing settings',
          })
        );
      } catch (e) {
        dispatch(
          show({
            type: 'error',
            title: 'Unable to update billing settings',
            description: formatServerError(e),
          })
        );
      }
    },
    [dispatch, updateBillingSettings]
  );

  return (
    <FormProvider {...form}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div>
          <BillingSettingsForm
            minimumInvoiceNumber={currentSettings?.invoice_seed_number ?? 1}
          />
        </div>
      )}
      <div className="flex justify-end pt-4">
        <Button
          type="submit"
          variant="primary"
          onClick={form.handleSubmit(handleSubmit)}
        >
          Save
        </Button>
      </div>
    </FormProvider>
  );
}
