import { UserCircleIcon } from '@heroicons/react/24/outline';

export function PassengerEmptyState() {
  return (
    <div className="flex justify-center px-[24px] py-12">
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <UserCircleIcon />
        </div>
        <h4 className="mt-3 text-gray-700">No Passengers Found</h4>
      </div>
    </div>
  );
}
