import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { PayerRouteActions } from './PayerRouteActions';

export function PayerLayout() {
  return (
    <HeaderToolbarAndContentLayout
      actionSection={<PayerRouteActions />}
      content={<Outlet />}
    />
  );
}
