import { FeatureNameEnum } from 'src/common/external/bambi-api/bambiApi';
import { formatRTKQueryError } from 'src/common/util/formatRTKQueryError';

import { useBambiWebFeatureFlagQuery } from './useBambiWebFeatureFlagQuery';

export type UseFeatureReturnValue = {
  isLoading: boolean;
  isEnabled: boolean;
  error?: string | null;
};

export function useFeature(
  featureName: FeatureNameEnum
): UseFeatureReturnValue {
  const { data, isLoading, error } = useBambiWebFeatureFlagQuery();
  const featureFlag = data?.results.find(
    (result) => result.feature_name === featureName
  );

  // Explicit return for clarity. I managed to write passing tests for the wrong loading state
  // without this. Arguably could have been avoided by mocking the response vs mocking useBambiWebFeatureFlagQuery
  if (isLoading) {
    return {
      isLoading,
      isEnabled: false,
    };
  }

  return {
    isLoading,
    isEnabled: !!featureFlag?.feature_is_on,
    error: error ? formatRTKQueryError(error) : null,
  };
}
