import { DriverRead } from 'src/common/external/bambi-api/bambiApi';

import { convertDriverToDriverProfile } from './convertDriverToDriverProfile';
import { DriverProfile } from './DriverProfile';

export function convertDriversToDriverProfiles(
  drivers: DriverRead[] = []
): DriverProfile[] {
  const profiles: DriverProfile[] = [];

  drivers.forEach(function (item, index) {
    const profile = convertDriverToDriverProfile(item);
    profiles.push(profile);
  });

  return profiles;
}
