import { useCallback } from 'react';

import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';

import { useTripMenuActions } from '../DispatchCalendar/useTripMenuActions';
import { AddEditNote } from './AddEditNote';

interface AddEditTripNoteProps {
  trip: DispatchTripRead;
}

export default function AddEditDispatchNote({ trip }: AddEditTripNoteProps) {
  const menuActions = useTripMenuActions();

  const onSave = useCallback(
    (note: string) => {
      menuActions.updateDispatcherNotes(trip.id, note);
    },
    [menuActions, trip.id]
  );

  return (
    <AddEditNote
      label="Dispatcher Notes (Not visible to drivers)"
      testId="dispatcher-notes"
      initialNote={trip.dispatcher_notes}
      onSave={onSave}
    />
  );
}
