import { getInspectionOutcome } from 'src/features/fleet/inspection/util/getInspectionOutcome';

import { VehicleInspection } from '../../types';
import { PassFailIcon } from '../PassFailIcon';

export function InspectionOutcomeCell({
  inspection,
}: {
  inspection: VehicleInspection;
}) {
  const outcome = getInspectionOutcome(inspection);
  return (
    <div className="flex items-center gap-1">
      <PassFailIcon isPass={outcome === 'Pass'} />
      <span>{outcome}</span>
    </div>
  );
}
