import { SettingsFormField } from './components/SettingsFormField';
import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function SignatureSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Signature Settings"
        description="Organization defaults for signatures."
      />

      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <SettingsFormField
            label="Driver on Pickup"
            fieldId="requires_signature_pickup_driver"
            type="checkbox"
          />
        </div>
        <div>
          <SettingsFormField
            label="Driver on Dropoff"
            fieldId="requires_signature_dropoff_driver"
            type="checkbox"
          />
        </div>
        <div>
          <SettingsFormField
            label="Passenger on Pickup"
            fieldId="requires_signature_pickup_passenger"
            type="checkbox"
          />
        </div>
        <div>
          <SettingsFormField
            label="Passenger on Dropoff"
            fieldId="requires_signature_dropoff_passenger"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  );
}
