import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { useUserRoles } from '../auth/useUserRoles';
import {
  setDriverProfileSearchTerm,
  setSelectedDriverProfile,
  setShowDriverProfileModal,
} from './team.slice';

export function DriverProfileRouteActions() {
  const dispatch = useAppDispatch();

  const driverProfileSearchTerm = useAppSelector(
    (state) => state.team.driverProfileSearchTerm
  );

  const { isRestrictedSupport } = useUserRoles();

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setDriverProfileSearchTerm,
    driverProfileSearchTerm
  );

  function handleAddDriverProfile() {
    dispatch(setSelectedDriverProfile(null));
    dispatch(setShowDriverProfileModal(true));
  }

  return (
    <>
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search drivers"
        value={inputValue}
      />
      {!isRestrictedSupport && (
        <Button onClick={() => handleAddDriverProfile()} variant="primary">
          Add a Driver Profile
        </Button>
      )}
    </>
  );
}
