import { useRef, useState } from 'react';

import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { addATrip } from 'src/features/add-trip/addATrip.slice';
import { PassengerInfoFormSection } from 'src/features/add-trip/PassengerInfoFormSection';
import { PricingFormSection } from 'src/features/add-trip/Pricing';
import { ServiceDetailsFormSection } from 'src/features/add-trip/ServiceDetails';
import { PassengerFormModal } from 'src/features/passenger/PassengerForm/PassengerForm';

import {
  setIsEditingPassenger,
  setShowTripSubscriptionModal,
} from '../trip-subscriptions.slice';
import SubscriptionDetailsSection from './SubscriptionDetailsSection/SubscriptionDetailsSection';
import { useFormManagement } from './useFormManagement';
import { usePassengerSelection } from './usePassengerSelection';

export default function TripSubscriptionForm() {
  const dispatch = useDispatch();
  const errorRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);

  const {
    selectedPassenger,
    isEditingPassenger,
    onPassengerSelected,
    onPassengerEdit,
    handlePassengerAddEdit,
  } = usePassengerSelection();

  const { methods, isSubmitting, onFormSubmit } = useFormManagement(
    setError,
    errorRef
  );

  return (
    <>
      <FormProvider {...methods}>
        <form
          className="h-[100vh] w-[100vw] overflow-scroll"
          onSubmit={methods.handleSubmit(onFormSubmit)}
        >
          <div className="flex h-full flex-col justify-between divide-y divide-gray-200">
            <div className="flex flex-col gap-y-4 px-4 pb-4 pt-2 sm:px-6">
              <h1 className="flex items-center gap-2 text-xl font-bold">
                Add Trip Subscription
              </h1>
              {error && (
                <div ref={errorRef}>
                  <FormErrorMessage>{error}</FormErrorMessage>
                </div>
              )}
              <PassengerInfoFormSection
                selectedPassenger={selectedPassenger}
                onPassengerSelected={onPassengerSelected}
                onPassengerEdit={onPassengerEdit}
              />
              <PricingFormSection showPayerLinkSwitch={false} />
              <ServiceDetailsFormSection />
              <SubscriptionDetailsSection />
            </div>

            <div className="flex flex-shrink-0 justify-between gap-x-1 bg-white px-4 py-4 sm:px-6">
              <div></div>
              <div className="flex flex-shrink-0 items-center justify-end">
                {isSubmitting && <LoadingIndicator />}
                <Button
                  variant="ghost"
                  dataTestId="trip-subscription-modal-cancel-button"
                  onClick={() => {
                    dispatch(addATrip.actions.selectPayer(null));
                    dispatch(setShowTripSubscriptionModal(false));
                  }}
                >
                  Cancel
                </Button>
                <FormSubmitButton
                  isSubmitting={isSubmitting}
                  buttonName="Save"
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <PassengerFormModal
        open={isEditingPassenger}
        setOpen={(open) => {
          dispatch(setIsEditingPassenger(open));
        }}
        passenger={selectedPassenger || undefined}
        handlePassengerAddEdit={(passenger) => {
          handlePassengerAddEdit(passenger);
        }}
      />
    </>
  );
}
