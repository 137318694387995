import { upperFirst } from 'lodash-es';

import { TilledCard } from 'src/common/external/bambi-api/bambiApi';
import { SnakeToCamelCaseNested } from 'src/common/util/types';

import {
  CreditCard,
  CreditCardBrand,
} from '../icon/CreditCardBrand/CreditCardBrand';

interface CreditCardPaymentMethodProps
  extends SnakeToCamelCaseNested<TilledCard> {
  id: string;
  brand: CreditCardBrand;
}

export function CreditCardPaymentMethod({
  brand,
  last4,
  expMonth,
  expYear,
  holderName,
  id,
}: CreditCardPaymentMethodProps) {
  return (
    <div
      className="flex items-start gap-2"
      data-testid={`payment-method-id-${id}`}
    >
      <CreditCard brand={brand} />
      {holderName === REMOVED_CARD ? (
        <div className="text-sm">
          <strong>{holderName}</strong>
        </div>
      ) : (
        <div className="text-sm">
          <div>
            <strong>{holderName}</strong>
            <span>
              ({upperFirst(brand)} ending in {last4})
            </span>
          </div>
          <div>
            Expiry {expMonth}/{expYear}
          </div>
        </div>
      )}
    </div>
  );
}

export const REMOVED_CARD = 'Removed Payment Method';
