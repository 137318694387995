import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { truncate } from 'lodash-es';
import { DateTime } from 'luxon';

import store from 'src/app/store';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { HereMapsAddressLink } from 'src/common/HereMapsAddressLink';
import { Badge } from 'src/common/primitives/Badge';
import { PassengerPopover } from 'src/features/passenger/management/PassengerPopover';
import { DateColumnFilter } from 'src/features/trip/management/filters/DateColumnFilter';
import { PayersColumnFilter } from 'src/features/trip/management/filters/PayerColumnFilter';
import { StatusColumnFilter } from 'src/features/trip/management/filters/StatusColumnFilter';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

import {
  setSelectedDateRange,
  setSelectedPayers,
  setSelectedStatuses,
} from '../rom-all-requests.slice';
import { RowActions } from './RowActions';

const columnHelper = createColumnHelper<TripRead>();

const passengerColumnDef = columnHelper.accessor('passenger', {
  enableSorting: true,
  id: 'name',
  header: 'Passenger',
  cell: (props) => (
    <PassengerPopover passenger={props.row.original.passenger} />
  ),
});

const statusColumnDef = columnHelper.accessor('status', {
  enableSorting: true,
  id: 'status',
  header: 'Status',
  cell: (props) => <TripStatusBadge status={props.row.original.status} />,
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <StatusColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().romAllRequests.selectedStatuses}
          setSelectedAction={setSelectedStatuses}
          isRom
        />
      );
    },
    filterCount() {
      const state = store.getState().romAllRequests;
      return state.selectedStatuses.length ?? 0;
    },
  },
});

// TODO: remaining time column
// This comes from the request expiration time which doesn't exist yet
// We're adding this in the rom application whenever we build that

const scheduledPickupAtColumnDef = columnHelper.accessor(
  'scheduled_pickup_at',
  {
    enableSorting: true,
    id: 'scheduled_pickup_at',
    header: 'Date of Trip',
    cell: (props) =>
      DateTime.fromISO(props.row.original.scheduled_pickup_at).toLocaleString({
        weekday: 'short',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
    meta: {
      filterComponent(column, open, onClose) {
        return (
          <DateColumnFilter
            open={open}
            onClose={onClose}
            setSelectedAction={setSelectedDateRange}
          />
        );
      },
      filterCount() {
        const state = store.getState().romAllRequests;
        return state.selectedDateRange?.from || state.selectedDateRange?.to
          ? 1
          : 0;
      },
    },
  }
);

const pickupTimeColumnDef = columnHelper.display({
  header: 'Pickup Time',
  cell: (props) =>
    DateTime.fromISO(props.row.original.scheduled_pickup_at).toFormat('t'),
});

const pickupAddressColumnDef = columnHelper.accessor('pickup', {
  enableSorting: true,
  id: 'pickup_address',
  header: 'Pick Up Address',
  cell: ({ row }) => (
    <HereMapsAddressLink
      addresses={[
        {
          latitude: row.original.pickup.latitude,
          longitude: row.original.pickup.longitude,
        },
        {
          latitude: row.original.dropoff.latitude,
          longitude: row.original.dropoff.longitude,
        },
      ]}
    >
      {formatAddress(row.original.pickup.address)}
    </HereMapsAddressLink>
  ),
});

const dropoffAddressColumnDef = columnHelper.accessor('dropoff', {
  enableSorting: true,
  id: 'dropoff_address',
  header: 'Drop Off Address',
  cell: ({ row }) => (
    <HereMapsAddressLink
      addresses={[
        {
          latitude: row.original.pickup.latitude,
          longitude: row.original.pickup.longitude,
        },
        {
          latitude: row.original.dropoff.latitude,
          longitude: row.original.dropoff.longitude,
        },
      ]}
    >
      {formatAddress(row.original.dropoff.address)}
    </HereMapsAddressLink>
  ),
});

const spaceTypeColumnDef = columnHelper.accessor('space_type', {
  id: 'space_type',
  header: 'Space Type',
  cell: (props) => <Badge label={props.row.original.space_type} color="blue" />,
});

const payerColumnDef = columnHelper.accessor('payer', {
  enableSorting: true,
  id: 'payer_name',
  header: 'Payer',
  cell: (props) => (
    <>
      {props.row.original.payer
        ? truncate(props.row.original.payer.payer_name || '', {
            length: 30,
            omission: '...',
          })
        : ''}
    </>
  ),
  meta: {
    filterComponent(column, open, onClose) {
      return (
        <PayersColumnFilter
          open={open}
          onClose={onClose}
          selected={store.getState().romAllRequests.selectedPayers}
          setSelectedAction={setSelectedPayers}
        />
      );
    },
    filterCount() {
      const state = store.getState().romAllRequests;
      return state.selectedPayers.length ?? 0;
    },
  },
});

const reasonColumnDef = columnHelper.display({
  header: 'Rejection Reason',
  cell: (props) => props.row.original.rejection_reason,
});

const actionColumnDef = columnHelper.display({
  id: 'actions',
  header: '',
  enableHiding: false,
  meta: {
    enableHeaderMenu: false,
  },
  cell: ({ row }) => {
    return <RowActions trip={row.original} />;
  },
});

export const columns: ColumnDef<TripRead, any>[] = [
  passengerColumnDef,
  statusColumnDef,
  scheduledPickupAtColumnDef,
  pickupTimeColumnDef,
  pickupAddressColumnDef,
  dropoffAddressColumnDef,
  spaceTypeColumnDef,
  payerColumnDef,
  reasonColumnDef,
  actionColumnDef,
];
