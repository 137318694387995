import { DataGrid } from 'src/common/DataGrid';
import {
  InvoicePaymentRead,
  TilledPaymentMethodResponse,
} from 'src/common/external/bambi-api/bambiApi';
import { REMOVED_CARD } from 'src/common/primitives/PaymentMethod/CreditCardPaymentMethod';

import { columns, InvoicePaymentWithPaymentMethod } from './paymentColumns';

interface PaymentsDataGridProps {
  payments?: InvoicePaymentRead[];
  paymentMethods?: TilledPaymentMethodResponse[];
}

export function PaymentsDataGrid({
  payments = [],
  paymentMethods = [],
}: PaymentsDataGridProps) {
  const mergedData: InvoicePaymentWithPaymentMethod[] = payments.map(
    (payment) => {
      const data = {
        ...payment,
        paymentMethod: paymentMethods.find(
          (m) => m.id === payment.external_payment_method_id
        ),
      };

      if (payment.external_payment_method_id && !data.paymentMethod) {
        data.paymentMethod = {
          card: {
            brand: '',
            exp_month: 0,
            exp_year: 0,
            holder_name: REMOVED_CARD,
            last4: '0000',
          },
          chargeable: false,
          created_at: '',
          customer_id: '',
          expires_at: '',
          id: '',
          metadata: {
            bambi_payer_id: '',
            bambi_payer_type: 'passenger',
          },
        };
      }

      return data;
    }
  );

  return (
    <DataGrid
      tableId="payments"
      columns={columns}
      data={mergedData}
      containerClassName="print:max-w-[100vw]"
      includePagination={false}
    />
  );
}
