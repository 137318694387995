import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { updateValuesKeysToSkip } from './TripSummaryUpdateItemContent/updateValuesKeysToSkip';

export function getChangedValueKeysForUpdate(
  tripEvent: TripUpdateRead
): string[] {
  if (!tripEvent.values_changed) return [];

  const updatedKeys = Object.keys(tripEvent.values_changed);
  return updatedKeys.filter((valueKey) => {
    return !updateValuesKeysToSkip.includes(valueKey);
  });
}
