import React from 'react';

import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { styled } from '@stitches/react';

import { formatAvatarInitials } from './formatAvatarInitials';

const StyledAvatar = styled(AvatarPrimitive.Root, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  width: 45,
  height: 45,
  borderRadius: '100%',
});

const StyledImage = styled(AvatarPrimitive.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});

const StyledFallback = styled(AvatarPrimitive.Fallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 15,
  lineHeight: 1,
  fontWeight: 500,
});

type AvatarProps = {
  imgSrc?: string;
  firstName?: string;
  lastName?: string;
};

export function Avatar({
  imgSrc,
  firstName = '',
  lastName = '',
}: AvatarProps): JSX.Element {
  return (
    <StyledAvatar className="bg-gray-500 text-white">
      <StyledImage src={imgSrc} alt={`${firstName} ${lastName}`} />
      <StyledFallback>
        {formatAvatarInitials(firstName, lastName)}
      </StyledFallback>
    </StyledAvatar>
  );
}
