import { useEffect, useState } from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/app/store';
import { DropdownMenuContent } from 'src/common/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import {
  PayerOrganizationRead,
  useLazyPayersSettingsRetrieveQuery,
  usePayersPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';

import {
  setOpenPayersMenuId,
  setSelectedPayer,
  setShowPayersModal,
} from '../payers.slice';

export function RowActions({ payer }: { payer: PayerOrganizationRead }) {
  const [updatePayer] = usePayersPartialUpdateMutation();
  const [getPayerSettings] = useLazyPayersSettingsRetrieveQuery();

  const dispatch = useAppDispatch();
  const openPayersMenuId = useSelector(
    (state: RootState) => state.payer.openPayersMenuId
  );

  const nextToastId = useSelector((state: RootState) => state.toast.nextId);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openPayersMenuId === payer.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openPayersMenuId, payer.id]);

  const toggleIsPayerActive = async () => {
    try {
      const is_active = !payer.is_active;
      await updatePayer({
        id: payer.id,
        patchedPayerOrganization: { is_active },
      }).unwrap();

      dispatch(
        show({
          id: nextToastId,
          title: `${payer.payer_name} has been updated`,
          type: 'success',
        })
      );
    } catch (error) {
      dispatch(
        show({
          id: nextToastId,
          title: 'Error updating payer',
          description: ' Please try again or contact support',
          type: 'error',
        })
      );
    }
  };

  const handleViewEditPayer = async () => {
    // have to get the settings of the payer as its different
    // than what shows in the list (list has org defaults)

    const payerSettings = await getPayerSettings({
      payerOrganizationId: payer.id,
    }).unwrap();

    dispatch(setSelectedPayer({ ...payerSettings, ...payer }));
    dispatch(setShowPayersModal(true));
  };

  return (
    <div className="flex justify-end pr-2">
      <DropdownMenu.Root
        open={open}
        onOpenChange={(val) => {
          if (!val) dispatch(setOpenPayersMenuId(''));
        }}
      >
        <DropdownMenu.Trigger asChild>
          <Button
            variant="ghost"
            onClick={() => dispatch(setOpenPayersMenuId(payer.id))}
          >
            <EllipsisVerticalIcon
              data-testid="payer-row-actions-icon"
              className="h-4 cursor-pointer"
            />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenuContent side="bottom" data-testid="payer-row-actions">
            <DropdownMenuItem
              onClick={() => {
                handleViewEditPayer();
              }}
            >
              Edit/View
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => toggleIsPayerActive()}>
              Mark as {payer.is_active ? 'Inactive' : 'Active'}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
}
