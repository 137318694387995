import { TripEventEventTypeEnum } from 'src/common/external/bambi-api/bambiApi';

import { eventTypeCopyMap } from './eventTypeCopyMap';
import { TripSummaryTitle } from './TripSummaryTitle';

export function TripSummaryEventType({
  eventType,
}: {
  eventType: TripEventEventTypeEnum;
}) {
  return <TripSummaryTitle title={eventTypeCopyMap[eventType] || eventType} />;
}
