import { Marker } from 'react-map-gl';

import { AssignmentTripRead } from 'src/common/external/bambi-api/bambiApi';

export default function DestinationMarker({
  assignmentTrip,
}: {
  assignmentTrip: AssignmentTripRead;
}) {
  const isEnRouteOrAssigned =
    assignmentTrip.status === 'en route' ||
    assignmentTrip.status === 'assigned';

  const latitude = isEnRouteOrAssigned
    ? assignmentTrip.pickup.location.latitude
    : assignmentTrip.dropoff.location.latitude;

  const longitude = isEnRouteOrAssigned
    ? assignmentTrip.pickup.location.longitude
    : assignmentTrip.dropoff.location.longitude;

  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      data-testid="destination-marker"
    />
  );
}
