import React, { useState } from 'react';

import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  EllipsisVerticalIcon,
  EyeSlashIcon,
  FunnelIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { Header } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { DropdownMenuBasic } from 'src/common/DropdownMenu/DropdownMenuBasic';
import { DropdownMenuSeparator } from 'src/common/DropdownMenu/DropdownMenuSeparator';
import { FilterCountBadge } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { searchParamsToJSON } from 'src/common/util/searchParamsToJSON';

import { ManageColumns } from './ManageColumns';

interface ColumnMenuProps<T> {
  header: Header<T, unknown>;
}

export function ColumnMenu<T>({ header }: ColumnMenuProps<T>) {
  let itemGroups: Array<Array<React.ReactNode>> = [];
  const [manageOpen, setManageOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const column = header.column;

  const orderable = header.column.columnDef.enableSorting;
  const hideable = column.getCanHide();
  const filterable =
    column.getCanFilter() && column.columnDef.meta?.filterComponent;
  const filterAppliedCount = column.columnDef.meta?.filterCount?.() ?? 0;
  const hasFiltersApplied = !!filterable && filterAppliedCount;
  const enableColumnMenu = column.columnDef.meta?.enableHeaderMenu ?? true;

  if (!enableColumnMenu) {
    return null;
  }

  if (orderable) {
    itemGroups.push([
      <ColumnMenuItem
        icon={<ArrowLongUpIcon className="h-4 w-4 text-gray-700" />}
        label="Sort Ascending"
        onClick={() => {
          setSearchParams((current) => ({
            ...searchParamsToJSON(current),
            ordering: column.id,
          }));
        }}
      />,
      <ColumnMenuItem
        icon={<ArrowLongDownIcon className="h-4 w-4 text-gray-700" />}
        label="Sort Descending"
        onClick={() => {
          setSearchParams((current) => {
            return {
              ...searchParamsToJSON(current),
              ordering: `-${column.id}`,
            };
          });
        }}
      />,
    ]);
  }

  const manageVisibilityGroup = [];

  if (hideable) {
    manageVisibilityGroup.push(
      <ColumnMenuItem
        icon={<EyeSlashIcon className="h-4 w-4 text-gray-700" />}
        label="Hide Column"
        onClick={column.getToggleVisibilityHandler()}
      />
    );
  }

  // Any column can manage remaining columns visibility
  manageVisibilityGroup.push(
    <ColumnMenuItem
      icon={<ViewColumnsIcon className="h-4 w-4 text-gray-700" />}
      label="Manage Columns"
      onClick={() => setManageOpen(true)}
    />
  );

  itemGroups = [...itemGroups, manageVisibilityGroup];

  if (filterable) {
    itemGroups.push([
      <ColumnMenuItem
        icon={<FunnelIcon className="h-4 w-4 text-gray-700" />}
        label={
          <div className="flex flex-row items-center justify-between gap-1">
            Filters
            <FilterCountBadge value={filterAppliedCount} positioned={false} />
          </div>
        }
        onClick={() => setFiltersOpen(true)}
      />,
    ]);
  }

  if (!itemGroups.length) {
    return null;
  }

  return (
    <>
      <DropdownMenuBasic
        trigger={
          <EllipsisVerticalIcon
            data-testid={`column-menu-trigger-${header.id}`}
            className={`h-4 w-4 cursor-pointer hover:stroke-mint print:hidden ${
              hasFiltersApplied ? ' stroke-mint' : ''
            }`}
            style={{ strokeWidth: hasFiltersApplied ? '2.5px' : undefined }}
          />
        }
      >
        <div className="flex flex-col gap-2 p-2">
          {itemGroups.map((group, index) => {
            const hasNextGroup = !!itemGroups[index + 1];

            return (
              <div key={`menu-group-${index}`}>
                {group.map((item, i) => (
                  <React.Fragment key={`menu-group-${index}-${i}`}>
                    {item}
                  </React.Fragment>
                ))}
                {hasNextGroup ? <DropdownMenuSeparator /> : null}
              </div>
            );
          })}
        </div>
      </DropdownMenuBasic>
      <ManageColumns
        columns={header.getContext().table.getAllColumns()}
        headers={header.getContext().table.getFlatHeaders()}
        table={header.getContext().table}
        open={manageOpen}
        onClose={() => setManageOpen(false)}
      />
      {filterable
        ? column.columnDef.meta?.filterComponent?.(column, filtersOpen, () =>
            setFiltersOpen(false)
          ) ?? null
        : null}
    </>
  );
}

interface ColumnMenuItemProps {
  icon: React.ReactNode;
  label: React.ReactNode;
  onClick?: (event: unknown) => void;
}
function ColumnMenuItem({ icon, label, onClick }: ColumnMenuItemProps) {
  return (
    <DropdownMenuItem
      className="cursor-pointer text-gray-500 hover:text-mint"
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-2 text-sm">
        {icon}
        {label}
      </div>
    </DropdownMenuItem>
  );
}
