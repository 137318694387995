import { ArrowsRightLeftIcon, ClockIcon } from '@heroicons/react/24/outline';
import { CellContext } from '@tanstack/react-table';

import { formatTimeFrom24HourTo12Hour } from 'src/common/date/formatTimeFrom24HourTo12Hour';
import {
  TripRecurrence,
  TripSubscriptionRead,
} from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

import { getWeekdayString, weekdayStringToNumberMap } from './utilities';

export function TripSubscriptionExpandedSummaryCell(
  props: CellContext<TripSubscriptionRead, any>
) {
  const { row } = props;

  let sortedPairedRecurrences: Array<Array<TripRecurrence>> = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  row.original.recurrences.forEach((recurrence) => {
    const weekday = getWeekdayString(recurrence.rrule);
    const weekdayIndex = weekdayStringToNumberMap[weekday];

    if (sortedPairedRecurrences[weekdayIndex].length === 0) {
      sortedPairedRecurrences[weekdayIndex] = [recurrence];
    } else if (sortedPairedRecurrences[weekdayIndex].length === 1) {
      // Need to sort the two recurrences
      const [existing] = sortedPairedRecurrences[weekdayIndex];
      sortedPairedRecurrences[weekdayIndex] = [existing, recurrence].sort();
    }
  });

  sortedPairedRecurrences = sortedPairedRecurrences.filter((a) => a.length);

  return (
    <div>
      <div>Pickup Time</div>
      {sortedPairedRecurrences.map(([first, second]) => (
        <div
          className="my-5 flex items-center"
          key={`${first.rrule}${first.scheduled_pickup_time}`}
        >
          <div className="min-w-[125px]">
            <Badge label={getWeekdayString(first.rrule)} color="gray" />
          </div>

          <div className="flex gap-x-10">
            {first.scheduled_pickup_time && (
              <div>
                <div>Outbound</div>
                <div className="mt-1 flex gap-x-1">
                  <ClockIcon className="h-5 w-5" />
                  {formatTimeFrom24HourTo12Hour(first.scheduled_pickup_time)}
                </div>
              </div>
            )}

            {!!second && second.scheduled_pickup_time && (
              <div className="flex gap-x-10">
                <ArrowsRightLeftIcon className="my-2.5 h-5 w-5" />
                <div className="mr-1.5">
                  <div>Return</div>
                  <div className="mt-1 flex">
                    <ClockIcon className="h-5 w-5" />
                    {formatTimeFrom24HourTo12Hour(second.scheduled_pickup_time)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
