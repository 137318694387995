import { TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';

export function DeleteAction({
  className,
  onDelete,
  label,
}: {
  className?: string;
  onDelete: () => void;
  label: string;
}) {
  return (
    <Button className={`${className}`} onClick={onDelete} variant="danger">
      <TrashIcon className="mr-2 w-5" /> {label}
    </Button>
  );
}
