import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { DropdownMenuBasic } from 'src/common/DropdownMenu/DropdownMenuBasic';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { AssignmentBreakRead } from 'src/common/external/bambi-api/bambiApi';
import {
  setModalAssignmentBreak,
  showDispatchModal,
} from 'src/features/dispatch/dispatcher.slice';

export function BreakCalendarEventMenu({
  assignmentBreak,
}: {
  assignmentBreak: AssignmentBreakRead;
}) {
  const dispatch = useDispatch();
  const handleViewEdit = () => {
    dispatch(setModalAssignmentBreak(assignmentBreak));
    dispatch(showDispatchModal('assignmentBreak'));
  };

  const handleDelete = () => {
    dispatch(setModalAssignmentBreak(assignmentBreak));
    dispatch(showDispatchModal('deleteBreak'));
  };

  return (
    <>
      <DropdownMenuBasic
        trigger={
          <EllipsisVerticalIcon
            className="h-4 w-4 cursor-pointer"
            data-testid="break-calendar-event-menu-trigger"
          />
        }
      >
        <DropdownMenuItem onClick={handleViewEdit}>View/Edit</DropdownMenuItem>
        <DropdownMenuItem onClick={handleDelete}>Delete</DropdownMenuItem>
      </DropdownMenuBasic>
    </>
  );
}
