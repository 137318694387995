const VIEWBOX_HEIGHT_PIXELS = 36;
const VIEWBOX_WIDTH_PIXELS = 37;

type VehicleMapLocationMarkerProps = {
  debug?: boolean;
  driverInitials?: string;
  headingInDegrees?: number;
  isSelected?: boolean;
};

export function VehicleMapLocationMarker({
  debug = false,
  driverInitials = 'OR',
  headingInDegrees = 0,
  isSelected = false,
}: VehicleMapLocationMarkerProps) {
  return (
    <div
      className={`relative h-[${VIEWBOX_HEIGHT_PIXELS}px] w-[${VIEWBOX_WIDTH_PIXELS}px]`}
      style={{
        transformOrigin: 'center',
        transform: `rotate(${headingInDegrees}deg) scale(1.5)`,
        // Adding a border makes debugging easier since you can't inspect elements
        // (seems like the map blocks this somehow)
        border: debug ? '1px solid #1D3557' : 'none',
      }}
      data-testid="vehicle-map-location-marker"
    >
      <svg
        className="overflow-visible rounded-none"
        width={`${VIEWBOX_WIDTH_PIXELS}`}
        height={`${VIEWBOX_HEIGHT_PIXELS}`}
        viewBox={`10 0 ${VIEWBOX_WIDTH_PIXELS} ${VIEWBOX_HEIGHT_PIXELS}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6897 34.5383C12.4842 35.0002 11.8815 35.2312 11.5116 35.1297C11.191 35.0418 10.9397 34.8232 10.8384 34.5441C10.7215 34.2221 10.986 33.697 11.515 32.6467L26.5271 2.83997C27.0047 1.89186 27.2434 1.4178 27.5772 1.2727C27.8671 1.14669 28.2082 1.14669 28.498 1.2727C28.8318 1.4178 29.0706 1.89186 29.5481 2.83998L44.5603 32.6467C45.0893 33.697 45.3538 34.2221 45.2369 34.5441C45.1355 34.8232 44.8842 35.0418 44.5636 35.1297C44.1937 35.2312 43.591 35.0002 42.3855 34.5383L28.7019 29.2952C28.4572 29.2014 28.3349 29.1546 28.2078 29.136C28.0951 29.1196 27.9801 29.1196 27.8675 29.136C27.7404 29.1546 27.6181 29.2014 27.3734 29.2952L13.6897 34.5383Z"
          fill={isSelected ? `#C9F2F0` : `#1D3557`}
          stroke="#1D3557"
          strokeWidth="0.838743"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div
        className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center pt-1.5 ${
          isSelected ? 'text-black' : 'text-white'
        }`}
        style={{ font: "700 8px/12.11px 'Inter', sans-serif" }}
        data-testid="vehicle-map-location-marker-initials"
      >
        {driverInitials}
      </div>
    </div>
  );
}
