import { cloneDeep } from 'lodash-es';

import { dateTimeToTime } from 'src/common/date/dateTimeToTime';
import { TripRead, TripWrite } from 'src/common/external/bambi-api/bambiApi';
import { safelyMerge } from 'src/common/util/safelyMerge';

import { convertTripTagsToSelectOptionWithColor } from './convertTripTagsToSelectOptionWithColor';
import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';
import { getTripDateFormValue } from './getTripDateFormValue';

export function assignDefaultFormValues(
  trip: (TripRead & TripWrite) | undefined
): FormValues {
  if (!trip) return defaultFormValues;

  const defaultFormValuesCopy = cloneDeep(defaultFormValues);
  return {
    id: trip.id || defaultFormValuesCopy.id,
    passenger: {
      ...safelyMerge(defaultFormValuesCopy.passenger, trip.passenger),
      weight: trip.passenger.weight || 0,
    },
    booked_by_name: trip.booked_by_name || defaultFormValuesCopy.booked_by_name,
    booked_by_phone_number:
      trip.booked_by_phone_number ||
      defaultFormValuesCopy.booked_by_phone_number,
    booked_by_phone_extension:
      trip.booked_by_phone_extension ||
      defaultFormValuesCopy.booked_by_phone_extension,
    trips: [
      {
        pickupLocation: {
          ...safelyMerge(
            defaultFormValuesCopy.trips[0].pickupLocation,
            trip.pickup
          ),
          address: {
            label: trip.pickup.address || '',
            value: trip.pickup.place_id || '',
          },
          stairs: trip.pickup.num_stairs || 0,
          stair_equipment: trip.pickup.stair_equipment || '',
        },
        dropoffLocation: {
          ...safelyMerge(
            defaultFormValuesCopy.trips[0].dropoffLocation,
            trip.dropoff
          ),
          address: {
            label: trip.dropoff.address || '',
            value: trip.dropoff.place_id || '',
          },
          stairs: trip.dropoff.num_stairs || 0,
          stair_equipment: trip.dropoff.stair_equipment || '',
        },
        timing: {
          date: trip.scheduled_pickup_at
            ? getTripDateFormValue(trip)
            : defaultFormValuesCopy.trips[0].timing.date,
          appointment_time: trip.appointment_at
            ? dateTimeToTime(trip.appointment_at)
            : defaultFormValuesCopy.trips[0].timing.appointment_time,
          pickup_time: trip.scheduled_pickup_at
            ? dateTimeToTime(trip.scheduled_pickup_at)
            : defaultFormValuesCopy.trips[0].timing.pickup_time,
          is_will_call:
            trip.is_will_call ||
            defaultFormValuesCopy.trips[0].timing.is_will_call,
        },
        dispatcher_notes:
          trip.dispatcher_notes ||
          defaultFormValuesCopy.trips[0].dispatcher_notes,
        external_trip_id:
          trip.external_trip_id ||
          defaultFormValuesCopy.trips[0].external_trip_id,
        input_price_cents:
          trip.input_price_cents ??
          defaultFormValuesCopy.trips[0].input_price_cents,
        is_price_locked:
          trip.is_price_locked ||
          defaultFormValuesCopy.trips[0].is_price_locked,
        load_time_seconds:
          trip.load_time_seconds ||
          defaultFormValuesCopy.trips[0].load_time_seconds,
        unload_time_seconds:
          trip.unload_time_seconds ||
          defaultFormValuesCopy.trips[0].unload_time_seconds,
        is_wait_and_return_outbound:
          trip.is_wait_and_return_outbound ||
          defaultFormValuesCopy.trips[0].is_wait_and_return_outbound,
        is_wait_and_return_inbound:
          trip.is_wait_and_return_inbound ||
          defaultFormValuesCopy.trips[0].is_wait_and_return_inbound,
        trip_tags: trip.tags
          ? convertTripTagsToSelectOptionWithColor(trip.tags)
          : defaultFormValuesCopy.trips[0].trip_tags,
      },
    ],
    is_round_trip: defaultFormValuesCopy.is_round_trip,
    service_details: {
      ...safelyMerge(defaultFormValuesCopy.service_details, trip),
    },
    pricing: {
      ...safelyMerge(defaultFormValuesCopy.pricing, trip),
      payer: trip.payer.id || defaultFormValuesCopy.pricing.payer,
      payer_passenger_external_id:
        trip.payer_passenger_external_id ||
        trip.payer_passenger?.external_id ||
        defaultFormValuesCopy.pricing.payer_passenger_external_id,
      authorization_id: trip.authorization_id,
      insurance_policy_id: trip.insurance_policy_id,
      trip_id: trip.id,
    },
    // This is disabled on edit
    should_link_passenger_to_payer:
      !!trip.payer_passenger ||
      defaultFormValuesCopy.should_link_passenger_to_payer,
    must_provide_wheelchair:
      trip.must_provide_wheelchair ||
      defaultFormValuesCopy.must_provide_wheelchair,
  };
}
