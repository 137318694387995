import { UserPlusIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { Button } from 'src/common/primitives/Button';

import {
  setSelectedDriverProfile,
  setShowDriverProfileModal,
} from '../team.slice';

export function DriverProfileEmptyState() {
  const dispatch = useDispatch();
  function handleAddDriverProfile() {
    dispatch(setSelectedDriverProfile(null));
    dispatch(setShowDriverProfileModal(true));
  }
  return (
    <div className="flex justify-center px-[24px] py-12">
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <UserPlusIcon className="mx-auto h-6 w-auto text-gray-700" />
        </div>
        <h4 className="mt-3 text-gray-700">Add a driver profile to a member</h4>
        <p className="text-gray-400">
          Add a driver profile to an existing member
        </p>
        <div className="mt-3 text-gray-700">
          <Button onClick={() => handleAddDriverProfile()} variant="primary">
            Add a Driver Profile
          </Button>
        </div>
      </div>
    </div>
  );
}
