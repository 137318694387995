import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import {
  PricingModelRead,
  PricingRule,
} from 'src/common/external/bambi-api/bambiApi';
import { enhancedBambiApi } from 'src/common/external/bambi-api/enhancedBambiApi';

export interface PricingModelState {
  pricingModels: PricingModelRead[];
  pricingModelDetailsDraft?: PricingModelRead | null;
  isPricingModelChangePending: boolean;
  isPricingModelsLoading: boolean;
  pricingModelError?: string | null;
  pricingRuleDrafts?: PricingRule[] | null;
  pricingSearchTerm: string;
}

export const initialState: PricingModelState = {
  pricingModels: [],
  isPricingModelChangePending: false,
  isPricingModelsLoading: false,
  pricingSearchTerm: '',
};

const pricingModelChangePendingEndpoints = [
  enhancedBambiApi.endpoints.pricingPricingModelSelectionCreate,
  enhancedBambiApi.endpoints.pricingPricingModelsCreate,
  enhancedBambiApi.endpoints.pricingPricingModelsDestroy,
  enhancedBambiApi.endpoints.pricingPricingModelsPartialUpdate,
];

const pricingModelFetchEndpoints = [
  enhancedBambiApi.endpoints.pricingPricingModelsList,
  enhancedBambiApi.endpoints.pricingPricingModelsRetrieve,
];
export const pricingModelSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPricingModels: (state, action) => {
      state.pricingModels = action.payload;
    },
    onPricingModelDetailsEdit: (
      state,
      action: { payload: PricingModelRead | null; type: string }
    ) => {
      state.pricingModelDetailsDraft = action.payload;
    },
    onPricingModelDetailsEditCancel: (state) => {
      state.pricingModelDetailsDraft = null;
      state.pricingModelError = null;
    },
    onPricingModelError: (
      state,
      action: { payload: string | null; type: string }
    ) => {
      state.pricingModelError = action.payload;
    },
    onPricingRuleEdit: (
      state,
      action: { payload: PricingRule; type: string }
    ) => {
      if (!Array.isArray(state.pricingRuleDrafts)) {
        state.pricingRuleDrafts = [];
      }

      state.pricingRuleDrafts.push({ ...action.payload });
    },
    onPricingRuleEditChange: (
      state,
      action: { payload: PricingRule; type: string }
    ) => {
      if (!Array.isArray(state.pricingRuleDrafts)) {
        return;
      }

      const filteredDrafts = state.pricingRuleDrafts.filter(
        (rule) => rule.id !== action.payload.id
      );
      state.pricingRuleDrafts = [...filteredDrafts, action.payload];
    },
    onPricingRuleEditCancel: (
      state,
      action: { payload: PricingRule; type: string }
    ) => {
      state.pricingRuleDrafts = state.pricingRuleDrafts?.filter(
        (rule) => rule.id !== action.payload.id
      );
    },
    setPricingSearchTerm: (state, action) => {
      state.pricingSearchTerm = action.payload;
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<PricingModelState>) {
    pricingModelChangePendingEndpoints.forEach((endpoint) => {
      builder.addMatcher(endpoint.matchPending, (state) => {
        state.isPricingModelChangePending = true;
        state.pricingModelError = null;
      });
    });

    pricingModelChangePendingEndpoints.forEach((endpoint) => {
      builder.addMatcher(endpoint.matchFulfilled, (state) => {
        state.isPricingModelChangePending = false;
        state.pricingModelError = null;
      });
      builder.addMatcher(endpoint.matchRejected, (state) => {
        state.isPricingModelChangePending = false;
        state.pricingModelError = null;
      });
    });

    pricingModelFetchEndpoints.forEach((endpoint) => {
      builder.addMatcher(endpoint.matchPending, (state) => {
        state.isPricingModelsLoading = true;
      });
    });

    pricingModelFetchEndpoints.forEach((endpoint) => {
      builder.addMatcher(endpoint.matchFulfilled, (state) => {
        state.isPricingModelsLoading = false;
      });
      builder.addMatcher(endpoint.matchRejected, (state) => {
        state.isPricingModelsLoading = false;
      });
    });
  },
});

export const {
  setPricingModels,
  onPricingModelDetailsEdit,
  onPricingModelDetailsEditCancel,
  onPricingModelError,
  onPricingRuleEdit,
  onPricingRuleEditChange,
  onPricingRuleEditCancel,
  setPricingSearchTerm,
} = pricingModelSlice.actions;
