//================================================================================================
// TODO: redo these validations in another ticket at a later date
// ===============================================================================================

import {
  intersection,
  // upperFirst
} from 'lodash-es';
import { DateTime } from 'luxon';

import { Assignment } from 'src/common/external/bambi-api/bambiApi';

import { convertFormValuesToAssignment } from './convertFormValuesToAssignment';
import { FormValues } from './FormValues';
// import { schema } from './schema';

export const validateAllAssignmentsOnSubmit = (formValues: FormValues) => {
  const assignments = convertFormValuesToAssignment(formValues);

  if (!assignments.length) return 'Please Add At Least One Assignment';

  const validateOneAssignmentPerDay =
    driversHaveOneAssignmentPerDay(assignments);

  if (!validateOneAssignmentPerDay)
    return 'Drivers Can Only Have One Assignment Per Day';

  // const objAssignments = Object.keys(formValues.assignments)
  //   .map((key) => {
  //     return { day: key, details: formValues.assignments[key] };
  //   })
  //   .filter((assignment) => assignment.details.isEditing);

  // if (!objAssignments.length) return 'Please Add At Least One Assignment';

  // for (const objAssignment of objAssignments) {
  //   if (!objAssignment.details.assignment)
  //     return `${objAssignment.day}'s assignment has the following issues:
  //     Attendants Required, Driver Required, End Required, Start Required`;

  //   const result = schema.safeParse(objAssignment.details.assignment);
  //   if (!result.success) {
  //     const issues = result.error.issues
  //       .map(
  //         (issue) => `${upperFirst(issue.path[0] as string)} ${issue.message}`
  //       )
  //       .join(', ');

  //     return `${objAssignment.day}'s assignment has the following issues: ${issues}`;
  //   }
  // }

  return null;
};

export const validateAssignmentOnControlChange = (
  assignment: Assignment,
  existingAssignments: Assignment[],
  updateAssignment: Assignment | null
) => {
  const isTimeValid = isAssignmentStartTimeBeforeEndTime(assignment);
  if (!isTimeValid) {
    const assignmentDay = DateTime.fromISO(assignment.start).toFormat(
      'ccc (MM/dd)'
    );
    return `Start Time Must Be Before End Time on ${assignmentDay}`;
  }

  const driverOverlaps = driverOverlapsExistingAssignment(
    assignment,
    existingAssignments,
    updateAssignment
  );

  if (driverOverlaps)
    return `Drivers Assignment Overlaps With Existing Assignment on ${driverOverlaps}`;

  const attendantOverlaps = attendantOverlapsExistingAssignment(
    assignment,
    existingAssignments,
    updateAssignment
  );

  if (attendantOverlaps)
    return 'One Or More Attendants Assignment Overlaps With An Existing Assignment';

  return null;
};

export const isAssignmentStartTimeBeforeEndTime = (assignment: Assignment) => {
  if (!assignment.start || !assignment.end) {
    return true;
  }
  const start = DateTime.fromISO(assignment.start);
  const end = DateTime.fromISO(assignment.end);
  return start < end;
};

const driverOverlapsExistingAssignment = (
  assignment: Assignment,
  existingAssignments: Assignment[],
  updateAssignment: Assignment | null
) => {
  if (!assignment.start || !assignment.end || !assignment.driver.driver) {
    return false;
  }
  const start = DateTime.fromISO(assignment.start);
  const end = DateTime.fromISO(assignment.end);

  const driversAssignments = existingAssignments.filter(
    (existingAssignment) =>
      existingAssignment.driver.driver === assignment.driver.driver &&
      existingAssignment !== updateAssignment
  );

  for (const driverAssignment of driversAssignments) {
    if (!driverAssignment.start || !driverAssignment.end) {
      continue;
    }
    const existingStart = DateTime.fromISO(driverAssignment.start);
    const existingEnd = DateTime.fromISO(driverAssignment.end);
    if (start < existingEnd && end > existingStart) {
      return DateTime.fromISO(driverAssignment.start).toFormat('ccc (MM/dd)');
    }
  }
  return false;
};

const attendantOverlapsExistingAssignment = (
  assignment: Assignment,
  existingAssignments: Assignment[],
  updateAssignment: Assignment | null
) => {
  if (!assignment.start || !assignment.end || !assignment.attendants.length) {
    return false;
  }
  const start = DateTime.fromISO(assignment.start);
  const end = DateTime.fromISO(assignment.end);

  const attendants = assignment.attendants.map(
    (attendant) => attendant.attendant
  );
  const existingAssignedAttendants = existingAssignments
    .map((assignment) =>
      assignment.attendants.map((attendant) => attendant.attendant)
    )
    .flat();

  const intersectingAttendants = intersection(
    attendants,
    existingAssignedAttendants
  );

  for (const attendant of intersectingAttendants) {
    const attendantAssignments = existingAssignments.filter(
      (assignment) =>
        assignment.attendants
          .map((attendant) => attendant.attendant)
          .includes(attendant) && assignment !== updateAssignment
    );

    for (const attendantAssignment of attendantAssignments) {
      if (!attendantAssignment.start || !attendantAssignment.end) {
        continue;
      }
      const existingStart = DateTime.fromISO(attendantAssignment.start);
      const existingEnd = DateTime.fromISO(attendantAssignment.end);
      if (start < existingEnd && end > existingStart) {
        return true;
      }
    }
    return false;
  }
};

const driversHaveOneAssignmentPerDay = (assignments: Assignment[]) => {
  const arrDriversDay = assignments.map((assignment) => {
    return {
      driver: assignment.driver.driver,
      day: DateTime.fromISO(assignment.start).toFormat('yyyy-MM-dd'),
    };
  });

  for (const driverDay of arrDriversDay) {
    const count = arrDriversDay.filter(
      (item) => item.driver === driverDay.driver && item.day === driverDay.day
    ).length;
    if (count > 1) {
      return false;
    }
  }

  return true;
};
