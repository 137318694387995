import { lazy, Suspense } from 'react';

import { useAppSelector } from 'src/app/store';
import { AddATripFlow } from 'src/features/add-trip';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { ProfileDropdown } from './ProfileDropdown';

const ChatBubble = lazy(() => import('./ChatBubble'));
const ChatProvider = lazy(() => import('src/features/chat/ChatProvider'));

export function RightNav() {
  const shouldHideChatBubble = useShouldShowRideOrderingPortalFeatures();
  const { sendbirdAccessToken } = useAppSelector((state) => state.chat);

  return (
    <div className="z-10 flex items-center gap-4">
      <AddATripFlow />
      {/* 
        until we have a notification system in place, we should hide the bell icon
        we may be able to hide this and display notifications on the avatar for mobile
        <button
        type="button"
        className="rounded-full p-1 text-gray-500 hover:text-indigo focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
        data-testid="profile-avatar"
      >
        <span className="sr-only">View notifications</span>
        <BellIcon
          data-testid="bell-icon"
          className="h-6 w-6"
          aria-hidden="true"
        />
      </button> */}
      {!shouldHideChatBubble && (
        <Suspense fallback={<LoadingIndicator />}>
          {/* 
            Double suspense here to make sure ChatProvider requests a sendbirdAccessToken 
            We should probably also check that Sendbird is enabled for the org (Does it have a chat_application_id?)
          */}
          <ChatProvider>
            <>
              {sendbirdAccessToken ? (
                <Suspense fallback={<LoadingIndicator />}>
                  <ChatBubble />
                </Suspense>
              ) : null}
            </>
          </ChatProvider>
        </Suspense>
      )}
      <ProfileDropdown />
    </div>
  );
}
