import { MbscResource } from '@mobiscroll/react';

import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';

export function createResourcesFromAssignments(
  assignments: DispatchAssignmentRead[]
): MbscResource[] {
  return assignments.map((assignment) => {
    return {
      id: assignment.id,
      assignment: assignment,
    };
  });
}
