import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { IndeterminateCheckbox } from 'src/common/primitives/IndeterminateCheckbox';

import { dispatcherSlice } from '../../dispatcher.slice';
import { TripListItem } from '../../trips/TripListItem';

export function NewAssignmentsList() {
  const dispatch = useAppDispatch();
  const new_trip_assignments = useAppSelector(
    (state: RootState) =>
      state.dispatcher.runBambiRunResult?.new_trip_assignments
  );
  const tripAssignmentsToApplyIds = useAppSelector(
    (state: RootState) => state.dispatcher.tripAssignmentsToApplyIds
  );
  const isAllAssigned = Object.values(tripAssignmentsToApplyIds).every(
    (i) => i
  );
  const isSomeAssigned = Object.values(tripAssignmentsToApplyIds).some(
    (i) => i
  );
  return (
    <div
      data-testid="new-assignments-tab"
      className="max-h-[60vh] flex-grow overflow-y-auto"
    >
      {new_trip_assignments?.length ? (
        <>
          <div
            className={`flex items-center justify-between gap-2 text-left font-normal text-black`}
          >
            <div>
              <label className="flex items-center justify-between gap-x-2">
                <IndeterminateCheckbox
                  onChange={(val) =>
                    dispatch(
                      dispatcherSlice.actions.onSelectAllNewAssignments({
                        selectedState: val,
                      })
                    )
                  }
                  checked={isAllAssigned}
                  indeterminate={!isAllAssigned && isSomeAssigned}
                />
                <span>Select All</span>
              </label>
            </div>
          </div>
          <ul className="flex flex-col gap-y-3">
            {new_trip_assignments.map((newTripAssignment) => (
              <li key={newTripAssignment.id}>
                <TripListItem
                  trip={newTripAssignment}
                  checked={!!tripAssignmentsToApplyIds[newTripAssignment.id]}
                  onCheckedChange={(newChecked) => {
                    dispatch(
                      dispatcherSlice.actions.toggleRunBambiRunTripAssignmentSelection(
                        {
                          tripId: newTripAssignment.id,
                          selectedState: !!newChecked,
                        }
                      )
                    );
                  }}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="flex h-full items-center justify-center text-center text-gray-500">
          There were no new trip assignments
        </div>
      )}
    </div>
  );
}
