import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';

import { DatePicker } from 'src/common/date/DatePicker';
import { Button } from 'src/common/primitives/Button';

import { isSameWeek } from './isSameWeek';

type DateControlsProps = {
  current: DateTime;
  onPrev: () => void;
  onNext: () => void;
  onToday: () => void;
  onDatePicked: (date: DateTime | undefined) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  prevTooltip: string;
  nextTooltip: string;
};

export function DateControls({
  current,
  onPrev,
  onNext,
  onToday,
  onDatePicked,
  maxDate,
  minDate,
  prevTooltip,
  nextTooltip,
}: DateControlsProps) {
  return (
    <div className="flex gap-1">
      <Button
        variant="secondary"
        onClick={onPrev}
        disabled={minDate && isSameWeek(current, minDate)}
        className="rounded-r-none"
        alt={prevTooltip}
        dataTestId="set-prev-day"
      >
        <ChevronLeftIcon className="h-2 w-2 text-gray-900" />
      </Button>
      <Button
        variant="secondary"
        className="rounded-none text-gray-900"
        onClick={onToday}
        alt={DateTime.local().toFormat('cccc, LLLL d')}
        dataTestId="set-today"
      >
        Today
      </Button>
      <Button
        variant="secondary"
        onClick={onNext}
        disabled={maxDate && isSameWeek(current, maxDate)}
        className="rounded-l-none"
        alt={nextTooltip}
        dataTestId="set-next-day"
      >
        <ChevronRightIcon className="h-2 w-2 text-gray-900" />
      </Button>
      <DatePicker
        triggerElement={
          <Button variant="ghost" dataTestId="calendar-btn">
            <CalendarIcon className="datepicker-icon h-6 w-6" />
          </Button>
        }
        onChange={onDatePicked}
        maxDate={maxDate}
        minDate={minDate}
        value={current}
      />
    </div>
  );
}
