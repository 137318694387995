import { isNumber } from 'lodash-es';

import { MapPin } from 'src/common/BambiMapView/MapPin';
import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryAdditionalInfo } from './TripSummaryAdditionalInfo';
import { TripSummaryEventPersonName } from './TripSummaryEventPersonName';
import { TripSummaryEventType } from './TripSummaryEventType';
import { TripSummaryOccuredAt } from './TripSummaryOccuredAt';

export function TripSummaryItemHeader({
  tripEvent,
  isSelected,
}: {
  tripEvent: TripEventRead;
  isSelected: boolean;
}) {
  return (
    <div
      className={`relative flex w-full flex-col gap-1  p-2 pr-12 text-left ${
        isSelected ? 'bg-mint-light' : 'bg-gray-50'
      }`}
    >
      {/* 
          TODO: the right positioning here needs to match up with Accordion trigger 
          element positioning used by TripSummaryCreateItem and TripSummaryUpdateItem 
          Probably best to use a common layout for all of these, but that won't get around
          AccordionTrigger absolutely positioning its indicator element.

          At any rate, whatever solution is going to take more time than I'd like to invest
          right now
      */}
      {isNumber(tripEvent.longitude) && isNumber(tripEvent.latitude) && (
        <MapPin
          style={{
            transform: 'scale(.45)',
            position: 'absolute',
            right: '3px',
            top: '2px',
          }}
        />
      )}
      <div className="flex w-full items-center justify-between gap-1">
        <div className="flex items-center">
          <TripSummaryEventType eventType={tripEvent.event_type} />
        </div>
        <TripSummaryOccuredAt occuredAt={tripEvent.occurred_at} />
      </div>
      <div className="flex w-full flex-wrap items-center justify-between">
        <TripSummaryAdditionalInfo tripEvent={tripEvent} />
        <TripSummaryEventPersonName name={tripEvent.person_name} />
      </div>
    </div>
  );
}
