import { useMemo, useState } from 'react';

import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  MembersListApiArg,
  useMembersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { MemberForm } from '../MemberForm/MemberForm';
import { columns, columnsWithDeletedAt } from './columns';
import { MemberEmptyState } from './MemberEmptyState';

export function TeamManagementRoute() {
  const teamSearchTerm = useAppSelector((state) => state.team.teamSearchTerm);
  const includeDeleted = useAppSelector(
    (state) => state.team.showDeletedMemberships
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // TODO: There does not appear to be a way to check for literal membership in string union at runtime
  const orderBy = useSearchParams()[0].get(
    'ordering'
  ) as MembersListApiArg['ordering'];

  const {
    data: members,
    isLoading,
    isFetching,
    refetch,
  } = useMembersListQuery({
    page: pageIndex + 1,
    search: teamSearchTerm,
    ordering: orderBy,
    deletedObjects: includeDeleted ? 'include' : undefined,
  });

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  return (
    <>
      {isLoading || isFetching ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div>
            <DataGrid
              tableId="team-management"
              columns={includeDeleted ? columnsWithDeletedAt : columns}
              data={members?.results || []}
              pagination={pagination}
              totalCount={members?.count || 0}
              handlePaginationChange={handlePaginationChange}
              emptyState={<MemberEmptyState />}
              orderedBy={orderBy as string}
              getRowId={(row) => row.id}
            />
          </div>
        </>
      )}
      <MemberForm />
    </>
  );
}
