import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';

import {
  setConfirmationResult,
  setHasSeenConfirmPrompt,
  setShowConfirmationModal,
} from './confirmation.slice';

export function RequiresConfirmationModal() {
  const dispatch = useAppDispatch();
  const showingConfirm = useAppSelector(
    (state) => state.confirmation.showingConfirm
  );

  return (
    <ConfirmModal
      title="Are you sure?"
      description="Any unsaved information will be lost."
      onCancel={() => {
        dispatch(setShowConfirmationModal(false));
        dispatch(setHasSeenConfirmPrompt(false));
        dispatch(setConfirmationResult(undefined));
      }}
      onConfirm={() => {
        dispatch(setShowConfirmationModal(false));
        dispatch(setConfirmationResult(true));
      }}
      open={showingConfirm}
      setOpen={() => {
        dispatch(setShowConfirmationModal(false));
        dispatch(setHasSeenConfirmPrompt(false));
      }}
    />
  );
}

type OpenHandler = (nextOpen: boolean, skip?: boolean) => void;
type CloseHandler = () => void;

type UseConfirmCloseProps = {
  setOpen: OpenHandler;
  onClose?: CloseHandler;
  shouldConfirmClose: () => boolean;
};

/**
 * Returns a handler that will potentially trigger the confirmation modal to
 * appear, depending on the result of `shouldConfirmClose`.
 */
export function useConfirmClose({
  setOpen,
  onClose,
  shouldConfirmClose,
}: UseConfirmCloseProps): [OpenHandler, CloseHandler] {
  const dispatch = useAppDispatch();
  const hasSeenConfirmPrompt = useAppSelector(
    (state) => state.confirmation.hasSeenConfirmPrompt
  );
  const confirmationResult = useAppSelector(
    (state) => state.confirmation.confirmationResult
  );

  useEffect(() => {
    // User has seen the confirmation prompt, and are okay
    // with losing their data.
    if (hasSeenConfirmPrompt && confirmationResult) {
      dispatch(setHasSeenConfirmPrompt(false));
      dispatch(setConfirmationResult(undefined));
      setOpen(false);
      onClose?.();
    }
  }, [confirmationResult, dispatch, hasSeenConfirmPrompt, setOpen, onClose]);

  const openHandler: OpenHandler = (nextOpen, skip) => {
    if (skip) {
      setOpen(nextOpen);
      if (!nextOpen) {
        onClose?.();
      }
      return;
    }

    if ((!nextOpen && shouldConfirmClose()) || confirmationResult) {
      if (hasSeenConfirmPrompt) {
        setOpen(nextOpen);
        onClose?.();
      } else {
        dispatch(setShowConfirmationModal(true));
        dispatch(setHasSeenConfirmPrompt(true));
      }
    } else {
      dispatch(setHasSeenConfirmPrompt(false));
      setOpen(nextOpen);
      onClose?.();
    }
  };

  const closeHandler: CloseHandler = () => {
    const shouldConfirm = shouldConfirmClose();
    if (!shouldConfirm || (hasSeenConfirmPrompt && confirmationResult)) {
      onClose?.();
    } else {
      dispatch(setShowConfirmationModal(true));
      dispatch(setHasSeenConfirmPrompt(true));
    }
  };

  return [openHandler, closeHandler];
}
