import { FlagIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

interface InvoiceFormEmptyStateProps {
  onClick: () => void;
}

export function InvoiceFormEmptyState({ onClick }: InvoiceFormEmptyStateProps) {
  return (
    <EmptyState
      Icon={<FlagIcon className="w-6" />}
      entityName="Trip(s)"
      description="Start by adding a trip line item"
      Action={
        <Button dataTestId="empty-state-action" onClick={onClick}>
          Add trip(s)
        </Button>
      }
    />
  );
}
