import { PencilIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import {
  setSelectedDriverProfile,
  setShowDriverProfileModal,
} from '../team.slice';
import { DriverProfile } from './DriverProfile';

export function DriverProfileRowActions({
  driverProfile,
}: {
  driverProfile: DriverProfile;
}) {
  const dispatch = useAppDispatch();
  const { isRestrictedSupport } = useUserRoles();

  function handleEditDriverProfile() {
    if (isRestrictedSupport) return;
    dispatch(
      setSelectedDriverProfile({
        ...driverProfile,
      })
    );
    dispatch(setShowDriverProfileModal(true));
  }
  return (
    <div className="w-[24px]">
      <Button
        onClick={() => handleEditDriverProfile()}
        variant="ghost"
        disabled={isRestrictedSupport}
        dataTestId="edit-driver-profile-button"
      >
        <PencilIcon className="h-6 w-6 cursor-pointer hover:text-mint" />
      </Button>
    </div>
  );
}
