import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';

import { TripStatusBadge } from '../management/TripStatusBadge';
import { TripSummaryDefaultTextDisplay } from './TripSummaryDefaultTextDisplay';
import { TripSummaryFormattedPickupTime } from './TripSummaryFormattedPickupTime';

export function TripSummaryAdditionalInfo({
  tripEvent,
}: {
  tripEvent: TripEventRead;
}) {
  if (tripEvent.event_type === 'assign') {
    return <TripSummaryDefaultTextDisplay text={`to ${tripEvent.driver}`} />;
  }

  if (tripEvent.event_type === 'status_at_dropoff') {
    return <TripStatusBadge status={'at dropoff'} />;
  }

  if (tripEvent.event_type === 'status_at_pickup') {
    return <TripStatusBadge status={'at pickup'} />;
  }

  if (tripEvent.event_type === 'status_en_route') {
    return <TripStatusBadge status={'en route'} />;
  }

  if (tripEvent.event_type === 'status_transporting') {
    return <TripStatusBadge status={'transporting'} />;
  }

  if (tripEvent.event_type === 'update_pickup_at') {
    return <TripSummaryFormattedPickupTime tripEvent={tripEvent} />;
  }

  return <div>&nbsp;</div>;
}
