import { RoleEnum } from 'src/common/external/bambi-api/bambiApi';

import { baseUserTypes } from './baseUserTypes';
import { rideOrderingPortalUserTypes } from './rideOrderingPortalUserTypes';

export const nonFacilityRolesThatCanCreateFacilityMemberships: RoleEnum[] = [
  'owner',
  'admin',
  'bambi',
];

export const rolesThatCanCreateRestrictedSupportMemberships: RoleEnum[] = [
  'owner',
  'admin',
  'bambi',
];

// TODO: This is getting pretty hairy
// some maps of roles to createable user types and roles to roles they
// can be changed and then returning the union of the two would be cleaner
// Might also be helpful to separate out creation and editing
// Going to come back after meeting the restricted support role deadline
export function getCreateableUserTypes(
  isRideOrderingPortalEnabledForOrganization: boolean,
  currentRole?: RoleEnum,
  roleOfUserBeingEdited?: RoleEnum
) {
  // This should be hidden from the UI, but just in case
  if (currentRole === 'facility user') {
    return [];
  }

  // Ride ordering portal users being edited can only be edited to be ride ordering portal users
  if (
    roleOfUserBeingEdited &&
    rideOrderingPortalUserTypes.includes(roleOfUserBeingEdited)
  ) {
    return rideOrderingPortalUserTypes;
  }

  if (currentRole === 'facility admin') {
    return rideOrderingPortalUserTypes;
  }

  // Users should email if they want 'restricted support' role
  // changed to something else
  if (roleOfUserBeingEdited === 'restricted support') {
    return ['restricted support'];
  }

  // Not worrying about base user types switching to 'restricted support'
  // Don't think it's a thing anyway and users can email if needed
  if (roleOfUserBeingEdited && baseUserTypes.includes(roleOfUserBeingEdited)) {
    return baseUserTypes;
  }

  const createableUserTypes = [...baseUserTypes];

  if (
    currentRole &&
    isRideOrderingPortalEnabledForOrganization &&
    nonFacilityRolesThatCanCreateFacilityMemberships.includes(currentRole)
  ) {
    createableUserTypes.push(...rideOrderingPortalUserTypes);
  }

  if (
    currentRole &&
    rolesThatCanCreateRestrictedSupportMemberships.includes(currentRole)
  ) {
    createableUserTypes.push('restricted support');
  }

  return createableUserTypes;
}
