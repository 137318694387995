import { FormValues } from './FormValues';

export const defaultFormValues: FormValues = {
  capacityConfigurations: [
    {
      wheelchair_capacity: 0,
      stretcher_capacity: 0,
      ambulatory_capacity: 0,
    },
  ],
};
