import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { RootState } from 'src/app/store';
import {
  DriversListApiArg,
  useDriversListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

import { convertDriversToDriverProfiles } from './convertDriversToDriverProfiles';

export function useCurrentOrganizationDriverProfiles() {
  const auth = useAuth();

  const driverProfileSearchTerm = useSelector(
    (state: RootState) => state.team.driverProfileSearchTerm
  );

  const { data, isLoading, isFetching, refetch } = useDriversListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
    search: driverProfileSearchTerm,
    ordering: (useSearchParams()[0].get('ordering') ||
      '') as DriversListApiArg['ordering'],
  });

  return {
    drivers: convertDriversToDriverProfiles(data?.results),
    isLoading,
    isFetching,
    refetch,
  };
}
