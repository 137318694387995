import { TripRecurrence } from 'src/common/external/bambi-api/bambiApi';

export const getWeekdays = function (
  recurrences: TripRecurrence[]
): Array<string> {
  return recurrences.map((r) => r.rrule).map(getWeekdayString);
};

export const weekdayStringToNumberMap: Record<string, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const getWeekdayString = function (rruleString: string): string {
  if (rruleString.includes('BYDAY=MO')) {
    return 'Monday';
  } else if (rruleString.includes('BYDAY=TU')) {
    return 'Tuesday';
  } else if (rruleString.includes('BYDAY=WE')) {
    return 'Wednesday';
  } else if (rruleString.includes('BYDAY=TH')) {
    return 'Thursday';
  } else if (rruleString.includes('BYDAY=FR')) {
    return 'Friday';
  } else if (rruleString.includes('BYDAY=SA')) {
    return 'Saturday';
  } else {
    return 'Sunday';
  }
};
