import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'src/app/store';

export interface ILiveMapState {
  currentAssignmentLocationId?: string | null;
}

export const initialState: ILiveMapState = {
  currentAssignmentLocationId: '018973c9-d0f1-6ee2-e6b1-415703aa38a5',
};

export const liveMapSlice = createSlice({
  name: 'liveMap',
  initialState,
  reducers: {
    selectAssignmentLocation: (state, action) => {
      state.currentAssignmentLocationId = action.payload;
    },
    deselectAssignmentLocation: (state) => {
      state.currentAssignmentLocationId = null;
    },
  },
});

export function selectCurrentAssignmentLocationId(state: RootState) {
  return state.liveMap.currentAssignmentLocationId;
}
