import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createColumnHelper,
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { debounce } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  TripRead,
  TripsListApiArg,
  useLazyTripsAllListQuery,
  useTripsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal, ModalProps } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import { SearchTypeahead } from 'src/common/SearchTypeahead';

import { tripFinderModalColumns } from '../management/columns';
import { TripManagementFilters } from '../management/TripManagementFilters';
import { InvoicedTripsValue, setTripSearchTerm } from '../trip.slice';

const columnHelper = createColumnHelper<TripRead>();

const modalColumns = [
  columnHelper.display({
    id: 'row-selection',
    header: '',
    cell: ({ row }) =>
      row.getCanSelect() ? (
        <input
          data-testid="select-trip-checkbox"
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ) : null,
  }),
].concat(tripFinderModalColumns);

export interface TripFinderModalProps {
  open: ModalProps['open'];
  setOpen: ModalProps['setOpen'];
  onConfirm: (selectedTrips: TripRead[]) => void;
}

export function TripFinderModal({
  open,
  setOpen,
  onConfirm,
}: TripFinderModalProps) {
  const {
    tripSearchTerm,
    selectedDateRange,
    selectedPayers,
    selectedStatuses,
    selectedTags,
  } = useAppSelector((state) => state.trip);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const [rowSelection, setRowSelection] = useState({});
  const ordering = useSearchParams()[0].get(
    'ordering'
  ) as TripsListApiArg['ordering'];

  const filterInvoicedTrips = useAppSelector(
    (state) => state.trip.filterInvoicedTrips
  );

  const hasInvoiceValue = (() => {
    switch (filterInvoicedTrips) {
      case InvoicedTripsValue.ALL_TRIPS:
        return undefined;
      case InvoicedTripsValue.INVOICED_TRIPS:
        return true;
      case InvoicedTripsValue.NOT_INVOICED_TRIPS:
        return false;
    }
  })();

  const {
    data: trips,
    isLoading,
    refetch,
  } = useTripsListQuery({
    page: pageIndex + 1,
    search: tripSearchTerm,
    rangeStart: selectedDateRange?.from || '',
    rangeEnd: selectedDateRange?.to || '',
    payers: selectedPayers.join(',') || '',
    status: selectedStatuses.join(',') || '',
    tags: selectedTags.join(',') || '',
    hasInvoice: hasInvoiceValue,
    ordering,
  });
  const [fetchTrips] = useLazyTripsAllListQuery();
  const [addingAllTrips, setAddingAllTrips] = useState(false);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  const dispatch = useAppDispatch();

  const handleSearch = (term: string) => {
    dispatch(setTripSearchTerm(term));
  };

  const debounceSearch = debounce(handleSearch, 300);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch().then(() => {
      // Resetting after a search/data change. This assumes a workflow where the
      // user is first finding the trips they want, then selecting them. We
      // could make this more flexible by allowing the user to select, then
      // search, then select...
      setRowSelection({});
    });
  }, [
    tripSearchTerm,
    selectedDateRange,
    selectedPayers,
    selectedTags,
    refetch,
    open,
  ]);

  const selectedTrips: TripRead[] = useMemo(() => {
    return Object.keys(rowSelection).reduce((acc, rowIndex) => {
      const results = trips?.results ?? [];

      const isRowSelected = rowSelection[
        rowIndex as keyof typeof rowSelection
      ] as boolean;
      if (isRowSelected) {
        acc.push(results[rowIndex as unknown as number]);
      }
      return acc;
    }, [] as TripRead[]);
  }, [rowSelection, trips?.results]);

  const handleAddSelected = useCallback(() => {
    onConfirm(selectedTrips);
  }, [onConfirm, selectedTrips]);

  // This endpoint gives us a max of 1000 trips back, so its not quite 'all'
  const handleAddAll = useCallback(async () => {
    setAddingAllTrips(true);

    const { results } = await fetchTrips({
      search: tripSearchTerm,
      rangeStart: selectedDateRange?.from || '',
      rangeEnd: selectedDateRange?.to || '',
      payers: selectedPayers.join(',') || '',
      status: selectedStatuses.join(',') || '',
      tags: selectedTags.join(',') || '',
      hasInvoice: false,
      ordering,
    }).unwrap();

    // Shouldn't happen because the button is disabled when no trips are available
    if (!results || !results.length) {
      setAddingAllTrips(false);
      dispatch(
        show({
          type: 'info',
          title: 'No trips available',
          description: 'No trips available in list, please reduce your filters',
        })
      );
      return;
    }

    setAddingAllTrips(false);
    onConfirm(results);
  }, [
    dispatch,
    fetchTrips,
    onConfirm,
    ordering,
    selectedDateRange?.from,
    selectedDateRange?.to,
    selectedPayers,
    selectedStatuses,
    selectedTags,
    tripSearchTerm,
  ]);

  // Reset search
  useEffect(() => {
    dispatch(setTripSearchTerm(''));
  }, [open, dispatch]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      contentClassnames="w-full max-w-[75vw]"
    >
      <div data-testid="trip-finder-modal">
        <div className="mb-2 flex flex-row items-center justify-between">
          <TripManagementFilters />
          <div className="pt-4">
            <SearchTypeahead
              onChange={debounceSearch}
              placeholder="Search trips"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="my-72 flex justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <DataGrid
            tableId="trip-finder"
            containerClassName="max-h-[50vh]"
            columns={modalColumns}
            data={trips?.results || []}
            pagination={pagination}
            totalCount={trips?.count || 0}
            handlePaginationChange={handlePaginationChange}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            emptyState={<div>No trips found</div>}
            enableRowSelection={(row) => row.original.invoice_numbers === null}
          />
        )}
        <div className="mt-4 flex flex-row justify-between">
          <Button dataTestId="cancelBtn" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <div className="flex flex-row gap-2">
            <Button
              variant="primary-outline"
              disabled={trips?.count === 0}
              onClick={handleAddAll}
              loading={addingAllTrips}
              dataTestId="allAllTrips"
            >
              Add all Invoiceable Trips
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={handleAddSelected}
              disabled={selectedTrips.length === 0}
              dataTestId="confirmBtn"
            >
              Add {selectedTrips.length ? `(${selectedTrips.length})` : ''}{' '}
              Trips
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
