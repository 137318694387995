import { useState } from 'react';

import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useLazyGetAssignmentExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { useAuth } from 'src/features/auth/useAuth';

import { setShowAssignmentExportModal } from './assignmentManagement.slice';

export default function AssignmentDownloadModal() {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [trigger] = useLazyGetAssignmentExportAsCsvQuery();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const open = useAppSelector(
    (state) => state.assignmentManagement.showAssignmentExportModal
  );

  const handleModalClose = () => {
    setStartDate('');
    setEndDate('');
    setError(undefined);
    setLoading(false);
    dispatch(setShowAssignmentExportModal(false));
  };

  const handleDownload = async () => {
    setLoading(true);

    try {
      setError(undefined);
      setLoading(true);

      const start = startDate ? DateTime.fromISO(startDate) : undefined;
      const end = endDate ? DateTime.fromISO(endDate) : undefined;

      if (!start || !end) {
        setError('Start Date and End Date are required.');
        setLoading(false);
        return;
      }

      if (start && end && start > end) {
        setError('Start Date must be before End Date.');
        setLoading(false);
        return;
      }

      await trigger({
        rangeStart: start ? start.startOf('day').toISO() : '',
        rangeEnd: end ? end.endOf('day').toISO() : '',
        organizationId: auth.currentOrganizationId || '',
        format: 'csv',
      }).unwrap();

      handleModalClose();
    } catch (error) {
      setError('There was an error downloading the data.');
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      setOpen={setShowAssignmentExportModal}
      contentClassnames="max-w-2xl md:min-w-[400px]"
      data-testid="assignment-download-modal"
    >
      <h1 className="mb-4 text-2xl">Download Assignments</h1>

      {error && (
        <div className="pb-4">
          <FormErrorMessage>{error}</FormErrorMessage>
        </div>
      )}

      <div className="flex flex-col gap-4">
        <FormField
          label="Start Date"
          type="date"
          inputProps={{
            id: 'date-of-birth',
            placeholder: 'Select start date',
          }}
          value={startDate}
          onChange={(val) => setStartDate(val)}
        />

        <FormField
          label="End Date"
          type="date"
          inputProps={{
            id: 'date-of-birth',
            placeholder: 'Select end date',
          }}
          value={endDate}
          onChange={(val) => setEndDate(val)}
        />
      </div>

      <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <Button
          dataTestId="cancel-assignment-export-btn"
          className="sm:col-span-3"
          disabled={loading}
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button
          className="sm:col-span-3"
          type="submit"
          variant="primary"
          onClick={handleDownload}
          disabled={loading}
          dataTestId="download-assignment-btn"
        >
          {loading ? 'Exporting csv...' : 'Export as csv'}
        </Button>
      </div>
    </Modal>
  );
}
