import React from 'react';

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { mauve, violet } from '@radix-ui/colors';
import * as SelectPrimitive from '@radix-ui/react-select';
import { styled } from '@stitches/react';

import { SelectOption } from 'src/common/primitives/Select';
import { SearchTypeahead } from 'src/common/SearchTypeahead';

import { generateSelectItemTestId } from './generateSelectItemTestId';
import { pluckSelectOptionByValue } from './pluckSelectOptionByValue';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {});

const StyledIcon = styled(SelectPrimitive.SelectIcon, {});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  paddingTop: 2,
  paddingBottom: 2,
});

const StyledPortal = styled(SelectPrimitive.Portal, {
  zIndex: 100,
});

function Content({ children, ...props }: { children: React.ReactNode }) {
  return (
    <StyledPortal data-testid="select-portal">
      <StyledContent {...props}>{children}</StyledContent>
    </StyledPortal>
  );
}

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  lineHeight: 1,
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  padding: '.5rem 2rem .5rem 2rem',
  position: 'relative',
  userSelect: 'none',
});

const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: mauve.mauve11,
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: violet.violet11,
  cursor: 'default',
};

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

// Exports
export const SelectRoot = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = StyledIcon;
export const SelectContent = Content;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

export type SelectProps = SelectPrimitive.SelectProps & {
  options: SelectOption[];
  onChange: (value: SelectOption | undefined) => void;
  selected?: SelectOption;
  placeholder?: string;
  testIdSuffix?: string;
  error?: string;
  query?: string;
  setQuery?: (query: string) => void;
  searchable?: boolean;
};

export const Select = React.forwardRef(function _Select(
  {
    selected,
    options,
    onChange,
    placeholder = 'Select an option',
    testIdSuffix,
    error,
    query,
    setQuery,
    searchable = false,
    ...primitiveProps
  }: SelectProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const baseClass = `whitespace-nowrap relative flex w-full cursor-default items-center justify-between gap-1 rounded-md border
   bg-white py-2 px-3 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
   focus-visible:ring-offset-2 data-[disabled]:cursor-not-allowed data-[disabled]:text-gray-300 sm:text-sm`;

  const errorClass = error
    ? 'focus:border-red-500 focus-visible:border-red-500 border-red-300 focus-visible:ring-offset-red-300 '
    : 'focus:border-indigo-500 focus-visible:border-indigo-500 focus-visible:ring-offset-blue-300 border-gray-300';

  return (
    <SelectRoot
      {...primitiveProps}
      onValueChange={(newValue) => {
        // SelectItem only accepts strings for value, so we adapt to SelectOption here
        const newSelectOption = pluckSelectOptionByValue(newValue, options);
        onChange(newSelectOption);
      }}
      // Again, adapting to SelectOption if provided and falling back to value
      value={selected?.value || primitiveProps.value}
      data-testid="select"
    >
      <SelectTrigger
        aria-label={placeholder}
        className={`${baseClass} ${errorClass}`}
        ref={ref}
      >
        <SelectValue
          placeholder={placeholder}
          data-testid={
            testIdSuffix ? `select-value-${testIdSuffix}` : 'select-value'
          }
        />
        <SelectIcon data-testid="select-trigger">
          <ChevronDownIcon className="h-4 w-4 text-primary-700" />
        </SelectIcon>
      </SelectTrigger>
      <SelectContent data-testid="select-content">
        <SelectScrollUpButton>
          <ChevronUpIcon className="h-4 w-4" />
        </SelectScrollUpButton>
        <SelectViewport>
          {searchable && typeof setQuery === 'function' && (
            <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
              <SearchTypeahead
                value={query}
                onChange={setQuery}
                placeholder="Search..."
                hint="Search for options"
                dataTestId="multi-select-search"
              />
            </div>
          )}
          {options.map((option, index) =>
            option.value === 'select-separator' ? (
              <SelectSeparator />
            ) : (
              <SelectItem
                key={`${option.value}-${index}`}
                value={option.value}
                className="hover:bg-mint hover:text-white data-[highlighted]:bg-mint data-[highlighted]:text-white data-[disabled]:text-gray-300"
              >
                <SelectItemText
                  data-testid={generateSelectItemTestId(option.value)}
                >
                  {option.label}
                </SelectItemText>
                <SelectItemIndicator>
                  <CheckIcon className="h-4 w-4" />
                </SelectItemIndicator>
              </SelectItem>
            )
          )}
        </SelectViewport>
        <SelectScrollDownButton>
          <ChevronDownIcon className="h-4 w-4" />
        </SelectScrollDownButton>
      </SelectContent>
    </SelectRoot>
  );
});
