export const AmbulatoryIcon = ({ ...props }) => {
  return (
    <svg
      className="relative shrink-0 overflow-visible"
      style={{}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66536 1.33325C8.31174 1.33325 7.9726 1.47373 7.72256 1.72378C7.47251 1.97382 7.33203 2.31296 7.33203 2.66659C7.33203 3.02021 7.47251 3.35935 7.72256 3.60939C7.9726 3.85944 8.31174 3.99992 8.66536 3.99992C9.01899 3.99992 9.35813 3.85944 9.60817 3.60939C9.85822 3.35935 9.9987 3.02021 9.9987 2.66659C9.9987 2.31296 9.85822 1.97382 9.60817 1.72378C9.35813 1.47373 9.01899 1.33325 8.66536 1.33325ZM7.17448 4.66659L4.12109 6.19393L3.36719 8.45565L4.63021 8.87752L5.20964 7.14054L6.39323 6.5481L4.67578 14.6666H6.03906L6.95964 10.315C7.27897 10.6944 7.7667 11.3079 7.9987 11.5559V14.6666H9.33203V11.1314L8.54948 9.95825L9.10417 7.62752C9.2935 7.96352 9.52113 8.25347 9.78646 8.4908C10.4025 9.04214 11.1674 9.33325 11.9987 9.33325V7.99992C11.486 7.99992 11.0538 7.83531 10.6758 7.49731C10.3378 7.19531 10.0876 6.72732 9.93359 6.10799C9.64826 4.96732 8.54336 4.66659 7.9987 4.66659H7.17448Z"
        fill="black"
      />
    </svg>
  );
};
