import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useTilled } from 'src/common/forms/PaymentMethodCaptureForm/useTilled';
import { useConfirmClose } from 'src/common/modals/RequiresConfirmationModal/RequiresConfirmationModal';
import { Modal } from 'src/common/primitives/Modal';
import { WizardHeader } from 'src/common/wizard/WizardHeader';

import { setSelectedPayer, setShowPayersModal } from '../payers.slice';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { PayersFormStep1 } from './PayersFormStep1';
import { PayersFormStep2 } from './PayersFormStep2';
import { PayersFormStep3 } from './PayersFormStep3';

export function PayersDialog() {
  const dispatch = useAppDispatch();
  const { showPayersModal, selectedPayer } = useAppSelector(
    (state) => state.payer
  );
  const defaultFormValues = assignDefaultFormValues(selectedPayer);

  const methods = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    methods.reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFormValues.id]);

  const [step, setStep] = useState(1);

  const closeModal = () => {
    dispatch(setShowPayersModal(false));
    dispatch(setSelectedPayer(undefined));
    methods.reset();
    setStep(1);
  };

  const setOpenInternal = (val: boolean) => dispatch(setShowPayersModal(val));

  const { savePaymentMethod, validateForm: validatePaymentForm } =
    useTilled('card');

  const shouldConfirmClose = useCallback(
    () => methods.formState.isDirty,
    [methods.formState.isDirty]
  );

  const [setOpen, onClose] = useConfirmClose({
    setOpen: setOpenInternal,
    shouldConfirmClose,
    onClose: closeModal,
  });

  return (
    <>
      <Modal
        open={showPayersModal}
        setOpen={setOpen}
        onClose={onClose}
        data-testid="payers-dialog"
      >
        <div className="w-full md:min-w-[600px]" data-testid="payers-form">
          <WizardHeader
            title={selectedPayer?.id ? 'Edit Payer' : 'Add a New Payer'}
            wizardState={{ step, totalSteps: 3 }}
            setStep={setStep}
          />
          <FormProvider {...methods}>
            <form>
              <div className={step !== 1 ? 'hidden' : ''}>
                <PayersFormStep1
                  onClose={onClose}
                  setStep={(step) => setStep(step)}
                />
              </div>
              <div className={step !== 2 ? 'hidden' : ''}>
                <PayersFormStep2
                  onClose={onClose}
                  setStep={(step) => setStep(step)}
                />
              </div>
              <div className={step !== 3 ? 'hidden' : ''}>
                <PayersFormStep3
                  savePaymentMethod={savePaymentMethod}
                  validatePaymentForm={validatePaymentForm}
                  onClose={onClose}
                  onCloseAfterSave={closeModal}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
}
