import { createSlice } from '@reduxjs/toolkit';

import { IToastConfig } from './IToastConfig';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ToastState {
  toasts: Array<IToastConfig>;
  nextId: number;
}

const initialState: ToastState = {
  toasts: [],
  nextId: 0,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<IToastConfig>) => {
      if (typeof action.payload.id === 'undefined') {
        action.payload.id = Date.now();
      }

      state.toasts.push(action.payload);
      state.nextId += 1;
    },
    hide: (state, action: PayloadAction<IToastConfig>) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload.id
      );
    },
  },
});

export const { show, hide } = toastSlice.actions;
