import { FormValues } from './FormValues';
import { PAYER_SIGNATURE_OPTION_VALUES } from './payerSignatureOptions';

export const defaultFormValues: FormValues = {
  id: '',
  display_name: '',
  alternate_name: '',
  is_active: true,
  external_id: '',
  website: '',
  ein: '',
  email: '',
  phone_number: '',
  billing_address: null,
  billing_name: '',
  billing_phone_number: '',
  billing_email: '',
  default_trip_address: null,
  dispatcher_notes: '',
  admin_notes: '',
  pickup_default_early_seconds: null,
  pickup_default_late_seconds: null,
  pickup_appointment_early_seconds: null,
  pickup_appointment_late_seconds: null,
  pickup_will_call_early_seconds: null,
  pickup_will_call_late_seconds: null,
  extra_scheduled_pickup_at_seconds: null,
  invoice_due_after_days: null,
  requires_signature_pickup_passenger: {
    value: PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT,
    label: 'Organization Default',
  },
  requires_signature_dropoff_passenger: {
    value: PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT,
    label: 'Organization Default',
  },
  requires_signature_pickup_driver: {
    value: PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT,
    label: 'Organization Default',
  },
  requires_signature_dropoff_driver: {
    value: PAYER_SIGNATURE_OPTION_VALUES.ORG_DEFAULT,
    label: 'Organization Default',
  },
  type: 'broker',
  payer: null,
  can_multiload: false,
  multiloading_partners: [],
};
