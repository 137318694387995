import { sortBy, upperFirst } from 'lodash-es';

import { VehicleInspection } from '../types';
import { FieldValueRenderer } from './FieldValueRenderer';

export function ExpandedInspectionSection({
  inspection,
}: {
  inspection: VehicleInspection;
}) {
  return (
    <div
      className="max-h-[50vh] overflow-y-auto bg-white"
      data-testid="expanded-inspection-section"
    >
      <table
        className="min-w-full divide-y divide-gray-300 border-t border-t-gray-300"
        onClick={(e) => {
          // Allow highlighting of text
          e.stopPropagation();
        }}
      >
        <thead className="sticky top-0 border-b-black bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
            >
              Field
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Value
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Notes
            </th>
          </tr>
        </thead>
        <tbody>
          {
            // Array.sort fails because it sorts in place and does invalid assignments
            // We sort the fails up and everything else stays in place
            sortBy(inspection, [(field) => field.value !== 'fail']).map(
              (field) => (
                <tr key={field.title} className="even:bg-gray-50">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {field.title.split('_').map(upperFirst).join(' ')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                    <FieldValueRenderer value={field.value} />
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {field.comment}
                  </td>
                </tr>
              )
            )
          }
        </tbody>
      </table>
    </div>
  );
}
