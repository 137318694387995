import { DateTime } from 'luxon';

// Assumes converting from running env timezone to UTC
// For ex: In the browser, if the user agent has 'America/New_York' as time zone and this will
// be converted to UTC
// See tests and luxon docs for more info
export function convertISODateTimePartsToUTCISO({
  date,
  time,
}: {
  date: string;
  time: string;
}): string {
  const datePortion = DateTime.fromISO(date);
  const timePortion = DateTime.fromISO(time);
  return datePortion
    .set({
      hour: timePortion.hour,
      minute: timePortion.minute,
    })
    .toUTC()
    .toISO();
}
