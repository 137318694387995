import { upperFirst } from 'lodash-es';

import { SpaceTypeEnum } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';

export function TripSpaceTypeBadge({
  spaceType,
}: {
  spaceType: SpaceTypeEnum;
}) {
  return Badge({
    label: upperFirst(spaceType),
    color: 'primary',
  });
}
