// TODO: We could probably generate these or add the type on the backend and skip all of this
export type RunBambiRunCalendarEventType =
  | 'existing-assignment'
  | 'new-assignment'
  | 'reassignment'
  | 'wait-time';
export const RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES: {
  [k: string]: RunBambiRunCalendarEventType;
} = {
  EXISTING: 'existing-assignment',
  NEW: 'new-assignment',
  REASSIGNMENT: 'reassignment',
  WAIT_TIME: 'wait-time',
};
