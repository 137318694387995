import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { addATrip } from 'src/features/add-trip/addATrip.slice';

import {
  selectNoPassenger,
  setSelectedTripSubscription,
  setShowTripSubscriptionModal,
} from '../trip-subscriptions.slice';
import TripSubscriptionForm from './TripSubscriptionForm';

export default function TripSubscriptionModal() {
  const dispatch = useDispatch();
  const open = useAppSelector(
    (state) => state.tripSubscription.showTripSubscriptionModal
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          onClose={async (val) => {
            dispatch(setShowTripSubscriptionModal(val));
            if (!val) {
              dispatch(setSelectedTripSubscription(undefined));
              dispatch(selectNoPassenger());
              dispatch(addATrip.actions.selectPayer(null));
            }
          }}
        >
          <div className="fixed inset-0 z-20 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative flex h-full flex-col bg-white shadow-xl">
                    <TripSubscriptionForm />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
