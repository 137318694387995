export type MobilePlatform = 'ios' | 'android';

export interface MobileAppVersion {
  platform: MobilePlatform;
  versionNumber: VersionNumber;
}

export interface VersionNumber {
  major: number;
  minor: number;
  patch: number;
  ota?: string; //The OTA version description from CodePush
}

export const parseAppVersion = (
  appVersion: string
): MobileAppVersion | undefined => {
  const usefulInfo = appVersion.replace('Bambi/', '');
  const [rawPlatform, rawVersion] = usefulInfo.split('-v');
  const platform = parsePlatform(rawPlatform);
  const versionNumber = parseVersionNumber(rawVersion);
  if (!platform || !versionNumber) {
    return undefined;
  }
  return {
    platform,
    versionNumber,
  };
};

export const parseVersionNumber = (
  version: string
): VersionNumber | undefined => {
  if (!version || !version.length) {
    return undefined;
  }

  const [majorStr, minorStr, patchStr, ota] = version.split('.');
  const major = parseInt(majorStr);
  const minor = parseInt(minorStr);
  const patch = parseInt(patchStr);

  if (isNaN(major) || isNaN(minor) || isNaN(patch)) {
    return undefined;
  }

  return {
    major,
    minor,
    patch,
    ota,
  };
};

const parsePlatform = (platform: string): MobilePlatform | undefined => {
  const platformLower = platform.toLowerCase();
  switch (platformLower) {
    case 'ios':
      return 'ios';
    case 'android':
      return 'android';
    default:
      return undefined;
  }
};
