import { useCallback } from 'react';

import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  Invoice,
  InvoiceNestedLineItem,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { Well } from 'src/common/primitives/Well/Well';

import { editLineItems } from '../invoice.slice';
import { InvoiceLineItemList } from './InvoiceLineItemList';

interface InvoiceLineItemContainerProps {
  lineItems: Invoice['line_items'];
  onAddLineItem: (item: InvoiceNestedLineItem) => void;
  onUpdateLineItem: (rowIndex: number, update: InvoiceNestedLineItem) => void;
  onDeleteLineItem: (rowIndex: number, row: InvoiceNestedLineItem) => void;
  onDoneLoading?: (tripId: string) => void;
}

export function InvoiceLineItemContainer({
  lineItems,
  onAddLineItem,
  onUpdateLineItem,
  onDeleteLineItem,
  onDoneLoading,
}: InvoiceLineItemContainerProps) {
  const trip = lineItems[0].trip;
  const dispatch = useAppDispatch();
  const editingLineItem = useAppSelector(
    (state) => state.invoice.editingLineItemGroup
  );

  const handleAddTripLineItem = useCallback(() => {
    onAddLineItem({
      trip,
      amount_cents: 0,
      description: '',
      line_item_type: 'modifier',
    });
  }, [trip, onAddLineItem]);

  const handleEditLineItems = useCallback(() => {
    if (editingLineItem === trip) {
      dispatch(editLineItems(''));
    } else {
      if (!trip) {
        console.error('Missing trip from line item');
        return;
      }

      dispatch(editLineItems(trip));
    }
  }, [trip, dispatch, editingLineItem]);

  return (
    <Well testId={`well-for-trip-${trip}`}>
      <div className="flex w-full items-center justify-end gap-2">
        <Button
          dataTestId="line-item-group-edit-button"
          className="flex gap-2 print:hidden"
          onClick={handleEditLineItems}
        >
          <PencilIcon className="w-5" />
          Edit
        </Button>
        <Button
          className="flex gap-2 print:hidden"
          dataTestId="line-item-group-delete-button"
          onClick={() => onDeleteLineItem(0, lineItems[0])}
        >
          <TrashIcon className="w-5" />
          Delete
        </Button>
      </div>
      <InvoiceLineItemList
        lineItems={lineItems}
        onUpdateLineItem={onUpdateLineItem}
        onDeleteLineItem={onDeleteLineItem}
        onDoneLoading={onDoneLoading}
      />
      {editingLineItem === trip ? (
        <div className="mt-2">
          <Button
            dataTestId="line-item-container-add-subitem-button"
            variant="secondary"
            className="flex gap-2 print:hidden"
            onClick={handleAddTripLineItem}
          >
            <PlusCircleIcon className="w-5" />
            Add a subitem
          </Button>
        </div>
      ) : null}
    </Well>
  );
}
