import { useCallback } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from 'src/app/store';
import { useFilterGroupsDestroyMutation } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

interface ManageFilterGroupCellProps {
  id: string;
}

export function ManageFilterGroupCell({ id }: ManageFilterGroupCellProps) {
  const [executeDelete] = useFilterGroupsDestroyMutation({});
  const dispatch = useAppDispatch();

  const handleDelete = useCallback(async () => {
    try {
      await executeDelete({
        id,
      });
      dispatch(
        show({
          type: 'success',
          title: 'Successfully deleted Filter Group',
        })
      );
    } catch (e) {
      dispatch(
        show({
          type: 'error',
          title: 'Unable to delete Filter Group',
          description: formatServerError(e),
        })
      );
    }
  }, [dispatch, executeDelete, id]);

  return (
    <Button
      onClick={handleDelete}
      variant="icon"
      dataTestId={`delete-filter-group-${id}`}
    >
      <TrashIcon className="h-4 w-4" />
    </Button>
  );
}
