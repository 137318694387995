import { Layout } from 'src/common/primitives/Layout';

import { ToolbarAndContentLayout } from './ToolbarAndContentLayout';

export function HeaderToolbarAndContentLayout({
  actionSection,
  preContent,
  content,
  searchbox,
}: {
  actionSection?: React.ReactNode;
  preContent?: React.ReactNode;
  content: React.ReactNode;
  searchbox?: React.ReactNode;
}) {
  return (
    <Layout>
      <ToolbarAndContentLayout
        actionSection={actionSection}
        preContent={preContent}
        content={content}
        searchbox={searchbox}
      />
    </Layout>
  );
}
