import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { PricingModelManagementRoute } from './management/PricingModelManagementRoute';

export type PricingModelRoutesParams = {
  selectedPricingModelId?: string;
  selectedPricingTypeId?: string;
};

export const pricingModelRoutes: RouteObject[] = [
  {
    index: true,
    element: <PricingModelManagementRoute />,
  },
  {
    path: ':selectedPricingModelId',
    element: <PricingModelManagementRoute />,
  },
  {
    path: ':selectedPricingModelId/pricing-type/:selectedPricingTypeId',
    element: <PricingModelManagementRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute
        routePath="/pricing/pricing-models"
        linkText="Go back to pricing"
      />
    ),
  },
];
