import { CSSProperties } from 'react';

import { keyframes, styled } from '@stitches/react';

export const rotation = keyframes({
  from: { transform: `rotate(0deg)` },
  to: { transform: 'rotate(360deg)' },
});

const StyledLoadingIndicator = styled('span', {
  width: `32px`,
  height: `32px`,
  border: `5px solid #0CACA6`,
  borderBottomColor: `transparent`,
  borderRadius: `50%`,
  display: `inline-block`,
  boxSizing: `border-box`,
  animation: `${rotation} 1s linear infinite`,
});

export function LoadingIndicator({
  testId = 'loading-indicator',
  variant,
}: {
  testId?: string;
  variant?: 'small' | 'invert' | 'small-invert';
}) {
  const overrideStyles: CSSProperties = {};
  if (variant === 'small' || variant === 'small-invert') {
    overrideStyles['width'] = '16px';
    overrideStyles['height'] = '16px';
    overrideStyles['margin'] = '2px';
    overrideStyles['borderWidth'] = '3px';
  }

  if (variant === 'invert' || variant === 'small-invert') {
    overrideStyles['borderColor'] = '#fff';
    overrideStyles['borderBottomColor'] = '#0CACA6';
  }

  return <StyledLoadingIndicator data-testid={testId} style={overrideStyles} />;
}
