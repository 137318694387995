import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { RouteActions } from './PricingRouteActions';

export function PricingModelLayout() {
  return (
    <HeaderToolbarAndContentLayout
      content={<Outlet />}
      actionSection={<RouteActions />}
    />
  );
}
