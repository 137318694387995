import { Driver } from 'src/common/external/bambi-api/bambiApi';

import { DriverSkillMap } from './DriverSkillMap';
import { resolveDriverSkills } from './resolveDriverSkills';

const DriverOtherSkillKeyMap: DriverSkillMap = {
  can_do_bariatric: 'bariatric',
  can_do_stairs: 'stairs',
};

export function resolveOtherSkills(driver: Driver): string[] {
  return resolveDriverSkills(driver, DriverOtherSkillKeyMap);
}
