import { MbscCalendarEvent } from '@mobiscroll/react';

import { RunBambiRunResultRead } from 'src/common/external/bambi-api/bambiApi';

import { RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES } from '../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { createEventsFromTrips } from './createEventsFromTrips';
import { createEventsFromTripWaitTimes } from './createEventsFromTripWaitTimes';

export function createEventsFromRunBambiRunResult(
  runBambiRunResult: RunBambiRunResultRead
): MbscCalendarEvent[] {
  const existingEvents = createEventsFromTrips(
    runBambiRunResult.existing_trip_assignments
  ).map((event) => {
    return {
      ...event,
      type: RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.EXISTING,
      id: `${RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.EXISTING}-${event.id}`,
    };
  });

  const newEvents = createEventsFromTrips(
    runBambiRunResult.new_trip_assignments
  ).map((event) => {
    return {
      ...event,
      type: RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.NEW,
      id: `${RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.NEW}-${event.id}`,
    };
  });

  const reassignedEvents = createEventsFromTrips(
    runBambiRunResult.trip_reassignments
  ).map((trip) => {
    return {
      ...trip,
      type: RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.REASSIGNMENT,
      id: `${RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.REASSIGNMENT}-${trip.id}`,
    };
  });

  const assignedWaitTimeEvents = createEventsFromTripWaitTimes(
    runBambiRunResult.new_trip_assignments
  ).map((event) => {
    return {
      ...event,
      type: RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.WAIT_TIME,
      id: `${RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.WAIT_TIME}-${event.id}`,
    };
  });
  return [
    ...existingEvents,
    ...newEvents,
    ...reassignedEvents,
    ...assignedWaitTimeEvents,
  ];
}
