import { CurrencyInput } from 'src/common/CurrencyInput';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import {
  FormContextField,
  FormContextFieldRulesProps,
} from 'src/common/FormField/FormContextField';

export function CurrencyFormContextField({
  fieldPath,
  rules,
  disabled,
  label,
  inline,
  helperText,
}: {
  fieldPath: string;
  rules?: FormContextFieldRulesProps;
  disabled?: boolean;
  label: string;
  inline?: boolean;
  helperText?: string;
}) {
  return (
    <FormContextField name={fieldPath} rules={rules}>
      {({ field, fieldState }) => {
        const { onChange, ...remainingProps } = field;
        return (
          <DefaultFormFieldLayout
            label={rules?.required ? `${label} *` : label}
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: fieldPath }}
            inline={inline}
            helperText={helperText}
          >
            <div className="relative flex items-center gap-2">
              <CurrencyInput
                id={fieldPath}
                {...remainingProps}
                disabled={disabled}
                onValueChange={(values) => {
                  // Variable name floatValue is misleading
                  // This is actually resolved as an cents/integer in form values
                  field.onChange(values.floatValue);
                }}
              />
            </div>
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
