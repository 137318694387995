import { useEffect } from 'react';

import OneSchemaImporter from '@oneschema/react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  TripImportAsyncRequestWrite,
  useTripImportCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useAuth } from 'src/features/auth/useAuth';

import {
  setCsvUploadError,
  setCsvUploadSuccessMessage,
  setShowUploadDetailsModal,
  setShowUploadModal,
  setUploadInProgress,
  setUploadJobId,
} from '../trip.slice';

export function UploadModal() {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const [startImportTripJob] = useTripImportCreateMutation();
  const isOpen = useAppSelector((state) => {
    return state.trip.showUploadModal;
  });
  const nextToastId = useAppSelector((state) => {
    return state.toast.nextId;
  });
  const uploadPayerTemplate = useAppSelector((state) => {
    return state.trip.uploadPayerTemplate;
  });

  const uploadPayerId = useAppSelector((state) => {
    return state.trip.uploadPayerId;
  });

  useEffect(() => {
    return () => dispatch(setCsvUploadError(undefined)) as unknown as void;
  }, [dispatch]);

  if (isOpen && !uploadPayerTemplate?.value && !uploadPayerId?.value) {
    return (
      <div>This shouldn't happen. Payer or template not selected or lost</div>
    );
  }
  return (
    <div className="isolate">
      <OneSchemaImporter
        isOpen={isOpen}
        /* managing state from your application */
        onRequestClose={() => dispatch(setShowUploadModal(false))}
        /* required config values */
        clientId={
          process.env.REACT_APP_ONESCHEMA_CLIENT_ID ??
          '682895e1-3391-4dca-a6a1-2503b28b1db0'
        }
        // TODO: We'll have to resolve this from the backend
        templateKey={uploadPayerTemplate?.value || ''}
        /* optional config values */
        importConfig={{ type: 'local', metadataOnly: false }}
        // TODO: We'll have to generate this on the backend
        userJwt={
          process.env.REACT_APP_ONESCHEMA_JWT ??
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiPFVTRVJfSUQ-IiwiaXNzIjoiNjgyODk1ZTEtMzM5MS00ZGNhLWE2YTEtMjUwM2IyOGIxZGIwIn0.wjRSwDWCC-YSXjyfLXZpYzSjnk0so-KtU6jWbujXbW8'
        }
        devMode={process.env.NODE_ENV !== 'production'}
        className="oneschema-importer isolate z-50"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }}
        onLaunched={() => {
          dispatch(setShowUploadDetailsModal(false));
          dispatch(setUploadInProgress(true));
        }}
        onCancel={() => {
          dispatch(setUploadInProgress(false));
        }}
        onSuccess={async (result) => {
          try {
            dispatch(setCsvUploadError(undefined));
            dispatch(setCsvUploadSuccessMessage(null));
            setUploadJobId(null);

            const tripImportAsyncRequest: TripImportAsyncRequestWrite = {
              payer_id: uploadPayerId?.value || undefined,
              template_key: uploadPayerTemplate?.value || '',
              trips_from_csv: result,
            };
            const importedTrips = await startImportTripJob({
              'Organization-ID': auth.currentOrganizationId || '',
              tripImportAsyncRequest,
            }).unwrap();

            if (importedTrips.id) {
              // Set the id in our store so we start polling the job status
              dispatch(setUploadJobId(importedTrips.id));
            } else {
              // Display errors returned from the server using formatServerError
              dispatch(setCsvUploadError(formatServerError(importedTrips)));
            }
          } catch (e) {
            const error = formatServerError(e);
            dispatch(setCsvUploadError(error));
            dispatch(
              show({
                id: nextToastId,
                title: 'Error uploading trips',
                type: 'error',
              })
            );
          }
        }}
      />
    </div>
  );
}
