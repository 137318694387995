import { useContext } from 'react';

import { get, useFormContext } from 'react-hook-form';

import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { useFormValues } from 'src/common/useFormValues';
import { DynamicFieldNameRootContext } from 'src/features/add-trip/TripDetails/DynamicFieldNameRootContext';

import { isAssignmentStartTimeBeforeEndTime } from '../AssignmentManagementForm/AssignmentManagementFormStep1/validations';

export function AssignmentStartTimeInput() {
  const formContext = useFormContext();
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);
  const currentFormValues = useFormValues();
  return (
    <FormContextField
      name={`${fieldNameRoot}.start`}
      rules={{
        required: 'Start is required',
        validate: () => {
          const assignment = get(currentFormValues, fieldNameRoot);
          if (isAssignmentStartTimeBeforeEndTime(assignment)) {
            formContext.clearErrors(`${fieldNameRoot}.start`);
            formContext.clearErrors(`${fieldNameRoot}.end`);
            return true;
          } else {
            formContext.setError(`${fieldNameRoot}.end`, {
              type: 'manual',
              message: 'Must be after start time',
            });
            return 'Must be before end time';
          }
        },
      }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            type="time"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: `${fieldNameRoot}.start`,
              ...field,
            }}
            value={field.value}
            onChange={(e) => {
              field.onChange(e);
            }}
          />
        );
      }}
    </FormContextField>
  );
}
