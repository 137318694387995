import { useEffect, useState } from 'react';

import { RadioGroup, RadioGroupOption } from '../RadioGroup/RadioGroup';
import { FilterPopover } from './FilterPopover';

interface RadioGroupFilterPopoverProps<T extends string> {
  triggerElement: React.ReactNode;
  label?: string;
  value: T;
  options: RadioGroupOption<T>[];
  onChange: (value: T) => void;
}

export function RadioGroupFilterPopover<T extends string>({
  triggerElement,
  label,
  value,
  options,
  onChange,
}: RadioGroupFilterPopoverProps<T>) {
  const [activeValue, setActiveValue] = useState(value);

  useEffect(() => {
    setActiveValue(value);
  }, [value]);

  return (
    <FilterPopover
      triggerElement={triggerElement}
      onApply={() => {
        onChange(activeValue);
      }}
      onCancel={() => {
        // Reset state
        setActiveValue(value);
      }}
    >
      <RadioGroup
        label={label ?? ''}
        value={activeValue}
        options={options}
        onChange={setActiveValue}
      />
    </FilterPopover>
  );
}
