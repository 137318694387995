import { useEffect, useMemo } from 'react';

import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { InvoicePaymentRead } from 'src/common/external/bambi-api/bambiApi';
import { Well } from 'src/common/primitives/Well/Well';

import { usePrintableInvoiceContext } from '../print/PrintableInvoiceContext';
import { PaymentsDataGrid } from './PaymentsDataGrid';
import {
  PayerInfoPair,
  useHydratePaymentMethods,
} from './useHydratePaymentMethods';

interface PaymentsSectionProps {
  payments: InvoicePaymentRead[];
  invoiceTotalDue: number;
}

export function PaymentsSection({
  payments,
  invoiceTotalDue,
}: PaymentsSectionProps) {
  const payerInfoPairs: PayerInfoPair[] = useMemo(() => {
    return payments.map((p) => ({
      payerId: p.id_of_payer,
      payerType: p.tilled_type_of_payer as PayerInfoPair['payerType'],
    }));
  }, [payments]);

  const { data: paymentMethods, isLoading } =
    useHydratePaymentMethods(payerInfoPairs);
  const { setHasPaymentMethods } = usePrintableInvoiceContext();

  useEffect(() => {
    if (!payments.length) {
      setHasPaymentMethods(true);
      return;
    }
    setHasPaymentMethods(!isLoading);
  }, [isLoading, payments.length, setHasPaymentMethods]);

  return (
    <>
      <Well>
        <strong>Payments</strong>
        <PaymentsDataGrid payments={payments} paymentMethods={paymentMethods} />
      </Well>
      <div className="mt-4 flex flex-row items-center justify-end gap-8">
        <div>Total Due:</div>
        <div className="text-xl">{defaultCurrencyFormat(invoiceTotalDue)}</div>
      </div>
    </>
  );
}
