import { SettingsFormField } from './components/SettingsFormField';
import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function VehicleInspections() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Vehicle Inspections"
        description="Requirements for vehicle inspections."
      />

      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-3">
        <div>
          <SettingsFormField
            label="Require Inspection Before Assignment"
            fieldId="requires_inspection_after_assignment_start"
            type="checkbox"
          />
        </div>
        <div>
          <SettingsFormField
            label="Require Inspection After Assignment"
            fieldId="requires_inspection_before_assignment_end"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  );
}
