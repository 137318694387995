import { XMarkIcon } from '@heroicons/react/24/outline';

import { classNames } from 'src/common/classNames';
const BASE_CLASSES =
  'inline-flex items-center rounded-full px-2.5 py-1 text-xs font-medium';
const GREEN_CLASSES = 'bg-success-50 text-success-700';
const ORANGE_CLASSES = 'bg-orange-50 text-orange-700';
const PINK_CLASSES = 'bg-pink-50 text-pink-700';
const BLUE_CLASSES = 'bg-blue-50 text-blue-700';
const BLUE_LIGHT_CLASSES = 'bg-blue-light-50 text-blue-light-700';
const BLUE_DARK_CLASSES = 'bg-blue-dark-800 text-white';
const PRIMARY_CLASSES = 'bg-primary-50 text-primary-700';
const PRIMARY_INVERSE_CLASSES = 'bg-primary-700 text-white';
const MINT_INVERSE_CLASSES = 'bg-mint text-white';
const RED_CLASSES = 'bg-red-50 text-red-700';
const GRAY_CLASSES = 'bg-gray-50 text-gray-700';
const YELLOW_CLASSES = 'bg-yellow-50 text-yellow-700';

export const BADGE_COLOR_VARIANTS = {
  GREEN: 'green',
  ORANGE: 'orange',
  PINK: 'pink',
  BLUE: 'blue',
  BLUE_LIGHT: 'blue-light',
  BLUE_DARK: 'blue-dark',
  PRIMARY: 'primary',
  PRIMARY_INVERSE: 'primary-inverse',
  MINT_INVERSE: 'mint-inverse',
  RED: 'red',
  GRAY: 'gray',
  YELLOW: 'yellow',
};

const BADGE_COLOR_VARIANT_CLASSES = {
  [BADGE_COLOR_VARIANTS.GREEN]: GREEN_CLASSES,
  [BADGE_COLOR_VARIANTS.ORANGE]: ORANGE_CLASSES,
  [BADGE_COLOR_VARIANTS.PINK]: PINK_CLASSES,
  [BADGE_COLOR_VARIANTS.BLUE]: BLUE_CLASSES,
  [BADGE_COLOR_VARIANTS.BLUE_LIGHT]: BLUE_LIGHT_CLASSES,
  [BADGE_COLOR_VARIANTS.BLUE_DARK]: BLUE_DARK_CLASSES,
  [BADGE_COLOR_VARIANTS.PRIMARY]: PRIMARY_CLASSES,
  [BADGE_COLOR_VARIANTS.PRIMARY_INVERSE]: PRIMARY_INVERSE_CLASSES,
  [BADGE_COLOR_VARIANTS.MINT_INVERSE]: MINT_INVERSE_CLASSES,
  [BADGE_COLOR_VARIANTS.RED]: RED_CLASSES,
  [BADGE_COLOR_VARIANTS.GRAY]: GRAY_CLASSES,
  [BADGE_COLOR_VARIANTS.YELLOW]: YELLOW_CLASSES,
};

type SupportedBadgeColorVariants = keyof typeof BADGE_COLOR_VARIANT_CLASSES;

interface BadgeProps {
  icon?: React.ReactNode;
  label: string;
  color: SupportedBadgeColorVariants;
  closeable?: boolean;
  onClose?: () => void;
  testId?: string;
}

export function Badge({
  icon,
  label,
  color,
  closeable,
  onClose,
  testId,
}: BadgeProps) {
  const colorClasses = BADGE_COLOR_VARIANT_CLASSES[color];
  return (
    <span
      data-testid={testId ?? 'badge'}
      className={classNames(BASE_CLASSES, colorClasses)}
    >
      <div className="flex items-center gap-2 whitespace-nowrap">
        <>
          <div className="flex items-center gap-2">
            {icon && icon}
            {label}
          </div>
          {closeable && typeof onClose === 'function' && (
            <div>
              <XMarkIcon
                className="h-4 w-4 cursor-pointer"
                data-testid="badge-icon"
                onClick={onClose}
              />
            </div>
          )}
        </>
      </div>
    </span>
  );
}
