import {
  PassengerRead,
  usePassengersCreateMutation,
  usePassengersPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

export function usePassengerRepository() {
  const auth = useAuth();
  const [createPassengerImpl] = usePassengersCreateMutation();
  const [updatePassengerImpl] = usePassengersPartialUpdateMutation();

  return {
    createPassenger: async (passenger: PassengerRead) => {
      return await createPassengerImpl({
        'Organization-ID': auth.currentOrganizationId || '',
        passenger,
      });
    },
    updatePassenger: async (patchedPassenger: PassengerRead) => {
      return await updatePassengerImpl({
        'Organization-ID': auth.currentOrganizationId || '',
        id: patchedPassenger.id || '',
        patchedPassenger,
      });
    },
  };
}
