import { useFormContext } from 'react-hook-form';

import { RootState, useAppSelector } from 'src/app/store';
import { usePassengersRecentAddressesListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { LocationTypeahead } from 'src/common/LocationTypeahead';
import { StyledOption } from 'src/common/primitives/ComboBox/StyledOption';
import { SelectOption } from 'src/common/primitives/Select';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import addDefaultTripAddressOption from './addDefaultTripAddressOption';
import getLocationOptionAdornment from './getLocationOptionAdornment';
import getRecentAddressesAsOptions from './getRecentAddressesAsOptions';

export function DropoffAddressContextField({ index }: { index: number }) {
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  const { getValues, setValue } = useFormContext();
  const { selectedPassenger, selectedPayer } = useAppSelector(
    (state: RootState) => state.addATrip
  );
  const { trips } = getValues();
  const { data: recentAddressResponse } = usePassengersRecentAddressesListQuery(
    {
      id: selectedPassenger?.id || '',
    },
    {
      skip: !selectedPassenger?.id || shouldShowRideOrderingPortalFeatures,
    }
  );

  if (!trips[index]) {
    return null;
  }

  const onLocationChange = (location: SelectOption, field: any) => {
    const recentAddress = recentAddressResponse?.results.find(
      (result) => result.address.location?.place_id === location.value
    );
    if (recentAddress) {
      setValue(
        `trips.${index}.dropoffLocation.address_details`,
        recentAddress.address_details
      );
      setValue(
        `trips.${index}.dropoffLocation.driver_notes`,
        recentAddress.address_notes
      );
    }
    field.onChange({
      value: location.value,
      label: location.label,
    });
  };

  const additionalResultsQueryFn = async (
    query: string,
    options: SelectOption[]
  ) => {
    let additionalOptions: SelectOption[] = getRecentAddressesAsOptions(
      recentAddressResponse
    );

    additionalOptions = addDefaultTripAddressOption(
      additionalOptions,
      selectedPayer
    );

    return [
      ...additionalOptions,
      ...options.filter((option) => {
        const recentAddress = recentAddressResponse?.results.find(
          (result) => result.address.location?.place_id === option.value
        );
        return !recentAddress;
      }),
    ];
  };

  const renderOption = (
    option: SelectOption,
    selected: boolean,
    active: boolean
  ) => {
    const adornment = getLocationOptionAdornment({
      option,
      recentAddressResponse,
      selectedPayer,
    });

    return (
      <StyledOption
        key={option.value}
        option={option}
        selected={selected}
        active={active}
        startAddornment={adornment}
      />
    );
  };

  return (
    <FormContextField
      name={`trips.${index}.dropoffLocation.address`}
      rules={{
        validate(location) {
          if (!location || !location.value) {
            return 'A dropoff location from suggestions is required';
          }
          return true;
        },
      }}
    >
      {({ field, fieldState }) => {
        const inputProps = {
          id: `trips.${index}.dropoffLocation.address`,
          ...field,
        };
        return (
          <FormFieldContainer>
            <DefaultFormFieldLayout
              label="Dropoff Address *"
              error={fieldState.error?.message?.toString()}
              inputProps={inputProps}
            >
              <LocationTypeahead
                inputProps={inputProps}
                onChange={(suggestionOption) => {
                  onLocationChange(suggestionOption, field);
                }}
                value={
                  trips.length > 0
                    ? {
                        label: trips[index].dropoffLocation.address.label,
                        value: trips[index].dropoffLocation.address.value,
                      }
                    : undefined
                }
                error={fieldState.error?.message?.toString()}
                additionalResultsQueryFn={additionalResultsQueryFn}
                renderOption={renderOption}
                disabled={isSelectedTripInThePast}
              />
            </DefaultFormFieldLayout>
          </FormFieldContainer>
        );
      }}
    </FormContextField>
  );
}
