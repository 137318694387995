import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { Skeleton } from 'src/common/Skeleton';

interface TripDetailsPayerProps {
  isTripDataLoading: boolean;
  tripData?: TripRead;
}

export function TripDetailsPayer({
  isTripDataLoading,
  tripData,
}: TripDetailsPayerProps) {
  return (
    <div className="flex flex-col gap-1 py-2">
      <h4 className="text-sm font-bold">Payer</h4>
      {isTripDataLoading ? (
        <Skeleton />
      ) : (
        <div className="text-sm">
          <p className="text-sm font-medium text-gray-500">
            {tripData?.payer?.payer_name}
          </p>
        </div>
      )}
    </div>
  );
}
