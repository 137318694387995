import { createSlice } from '@reduxjs/toolkit';

import {
  PayerOrganizationRead,
  PayerOrganizationSettingsRead,
} from 'src/common/external/bambi-api/bambiApi';

export type PayerAndPayerSettings = PayerOrganizationRead &
  PayerOrganizationSettingsRead;

export interface PayerState {
  payers: PayerOrganizationRead[];
  selectedPayer: PayerAndPayerSettings | undefined;
  payerSearchTerm: string;
  openPayersMenuId: string;
  showPayersModal: boolean;
}

export const initialState: PayerState = {
  payers: [],
  selectedPayer: undefined,
  payerSearchTerm: '',
  openPayersMenuId: '',
  showPayersModal: false,
};

export const payerSlice = createSlice({
  name: 'payer',
  initialState,
  reducers: {
    setSelectedPayer: (state, action) => {
      state.selectedPayer = action.payload;
    },
    setPayerSearchTerm: (state, action) => {
      state.payerSearchTerm = action.payload;
    },
    setOpenPayersMenuId: (state, action) => {
      state.openPayersMenuId = action.payload;
    },
    setShowPayersModal: (state, action) => {
      state.showPayersModal = action.payload;
    },
  },
});

export const {
  setSelectedPayer,
  setPayerSearchTerm,
  setOpenPayersMenuId,
  setShowPayersModal,
} = payerSlice.actions;
