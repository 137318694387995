import { makeAssignedDays } from './makeAssignedDays';
import { FormValues as MultiAssignmentManagementFormStep2FormValues } from './MultiAssignmentManagementFormStep2/FormValues';

export function generateAssignmentTimeRangeRequestBody(
  data: MultiAssignmentManagementFormStep2FormValues
) {
  return {
    range_start: data.applyDateRange?.start,
    range_end: data.applyDateRange?.end,
    vehicle: {
      vehicle: data.vehicle?.value,
    },
    driver: {
      driver: data.driver?.value,
    },
    attendants: data.attendants?.map((attendant) => {
      return {
        attendant: attendant.value,
      };
    }),
    // Always true for now
    save_as_default: true,
    assigned_days: makeAssignedDays(data.assignments),
  };
}
