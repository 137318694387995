import { useFormContext } from 'react-hook-form';

import { MakeAdditionalLegButton } from './MakeAdditionalLegButton';
import { MakeRoundTripButton } from './MakeRoundTripButton';
import { MakeWaitAndReturnButton } from './MakeWaitAndReturnButton';
import { TripLeg } from './TripLeg';
import { useTripFieldArray } from './useTripFieldArray';

export function TripDetailsFormBody() {
  const { fields } = useTripFieldArray();
  const { getValues } = useFormContext();
  const { id, trips } = getValues();

  return (
    <div className="flex flex-col gap-y-2">
      {fields.map((field, index) => {
        if (!trips[index]) {
          return null;
        }
        return <TripLeg index={index} field={field} key={field.id} />;
      })}
      {!id && (
        <div className="mt-6 flex items-center justify-between gap-x-4">
          <MakeRoundTripButton isWillCall={false} />
          <MakeRoundTripButton isWillCall />
          <MakeAdditionalLegButton />
          <MakeWaitAndReturnButton />
        </div>
      )}
    </div>
  );
}
