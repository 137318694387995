import { Outlet } from 'react-router-dom';

import { Layout } from 'src/common/primitives/Layout';

export function DefaultRouteLayout() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
