import { PriceSummaryData } from './usePriceSummaryTableData';

export function AfterHoursFeeDetails({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <td className="py-1 px-2 text-center text-xs">
        <div className="underline">Start Time</div>
        <div>{data?.business_hours_start || '--'}</div>
      </td>
      <td className="py-1 px-2 text-center text-xs">
        <div className="underline">End Time</div>
        {data?.business_hours_end || '--'}
      </td>
      <td className="py-1 px-2 text-center text-xs">
        <div className="underline">Pickup Time</div>
        {data?.pickup_time || '--'}
      </td>
    </>
  );
}
