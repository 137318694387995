import { DateTime } from 'luxon';

import { convertClientDateTimeToServerISO } from 'src/common/date/convertClientDateTimeToServerISO';

export function makeDispatchTimeRange(selectedDate: string) {
  const dateTime = DateTime.fromISO(selectedDate);
  const start = convertClientDateTimeToServerISO(dateTime.startOf('day'));
  const end = convertClientDateTimeToServerISO(dateTime.endOf('day'));
  return {
    start,
    end,
  };
}
