import { DateTime } from 'luxon';

export function handleAppointmentTimeValidation(
  value: string,
  clearPickupTimeErrors: () => void,
  pickupTime: string
) {
  if (!value) clearPickupTimeErrors();

  const diff = DateTime.fromISO(pickupTime).diff(DateTime.fromISO(value));

  if (diff.milliseconds < 0) clearPickupTimeErrors();

  return true;
}
