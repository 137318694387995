import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { validateNoCC } from 'src/common/FormField/validators/creditCardValidator';
import { PhoneInput } from 'src/common/primitives/PhoneInput';

import { NumericStepper } from './NumericStepper';
import { stairEquipmentOptions } from './stairEquipmentOptions';

export function AddressDetailsContextField({
  fieldPath,
}: {
  fieldPath: string;
}) {
  return (
    <FormContextField name={`${fieldPath}.address_details`}>
      {({ field, fieldState }) => {
        return (
          <FormField
            error={fieldState.error?.message?.toString()}
            label="Address Details"
            type="text"
            inputProps={{
              id: `${fieldPath}.address_details`,
              placeholder: 'i.e. Facility Name, Wing, or Apt # (optional)',
              ...field,
            }}
          />
        );
      }}
    </FormContextField>
  );
}

export function DriverNotesContextField({ fieldPath }: { fieldPath: string }) {
  return (
    <FormContextField
      name={`${fieldPath}.driver_notes`}
      rules={{
        validate: validateNoCC,
      }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            error={fieldState.error?.message?.toString()}
            type="textarea"
            label="Notes for Driver"
            inputProps={{
              id: `${fieldPath}.driver_notes`,
              ...field,
              placeholder: 'i.e. Accessibility, enter from the rear, etc.',
              rows: '2',
            }}
          />
        );
      }}
    </FormContextField>
  );
}

export function ContactNameContextField({ fieldPath }: { fieldPath: string }) {
  return (
    <FormContextField name={`${fieldPath}.contact_name`}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Contact Name"
            error={fieldState.error?.message?.toString()}
            type="text"
            inputProps={{
              id: `${fieldPath}.contact_name`,
              ...field,
            }}
          />
        );
      }}
    </FormContextField>
  );
}

export function ContactPhoneContextField({ fieldPath }: { fieldPath: string }) {
  return (
    <FormContextField name={`${fieldPath}.contact_phone_number`}>
      {({ field, fieldState }) => {
        return (
          <DefaultFormFieldLayout
            label="Contact Phone Number"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: `${fieldPath}.contact_phone_number`,
            }}
          >
            <PhoneInput {...field} />
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}

export function StairEquipmentContextField({
  fieldPath,
}: {
  fieldPath: string;
}) {
  return (
    <FormContextField name={`${fieldPath}.stair_equipment`}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Stair Equipment"
            error={fieldState.error?.message?.toString()}
            type="select"
            value={
              field.value
                ? stairEquipmentOptions.find((x) => x.value === field.value)
                : ''
            }
            onChange={(value) => field.onChange(value.value)}
            inputProps={{
              id: `${fieldPath}.stair_equipment`,
            }}
            options={stairEquipmentOptions}
            placeholder="No Equipment Required"
          />
        );
      }}
    </FormContextField>
  );
}

export function StairsContextField({ fieldPath }: { fieldPath: string }) {
  return (
    <FormContextField name={`${fieldPath}.stairs`}>
      {({ field, fieldState }) => {
        return (
          <DefaultFormFieldLayout
            label="Stairs"
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: `${fieldPath}.stairs`, min: 0 }}
          >
            <NumericStepper
              {...field}
              onChange={(e: any) => {
                field.onChange(Number(e.target.value));
              }}
            />
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
