import { Outlet } from 'react-router-dom';

import { HeaderToolbarAndContentLayout } from 'src/common/primitives/HeaderToolbarAndContentLayout';

import { FleetNav } from './FleetNav';
import { FleetRouteActions } from './FleetRouteActions';

export function FleetLayout() {
  return (
    <HeaderToolbarAndContentLayout
      preContent={<FleetNav />}
      actionSection={<FleetRouteActions />}
      content={<Outlet />}
    />
  );
}
