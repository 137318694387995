export function ToolbarAndContentLayout({
  actionSection,
  preContent,
  content,
  searchbox,
}: {
  actionSection?: React.ReactNode;
  preContent?: React.ReactNode;
  content: React.ReactNode;
  searchbox?: React.ReactNode;
}) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex">
        <div className="sm:flex-auto">{preContent}</div>
        <div className="flex items-end gap-3 pt-2 sm:mt-0 sm:ml-16 sm:flex-none">
          {searchbox}
          {actionSection}
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-1 align-middle md:px-6 lg:px-8">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}
