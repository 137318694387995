import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField, FormFieldSet } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Button } from 'src/common/primitives/Button';

export function AttendantProfileFormStep2({
  onSubmit,
  onCancel,
  children,
  serverErrors,
  isSubmitting,
}: {
  onSubmit: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
  serverErrors: string | null;
  isSubmitting: boolean;
}) {
  return (
    <>
      <div className="mt-4 flex flex-col gap-4">
        {serverErrors && <FormErrorMessage>{serverErrors}</FormErrorMessage>}
        <FormFieldSet
          legend="Select which vehicles this attendant can handle"
          Fields={
            <>
              <FormContextField name="can_do_wheelchair_service">
                {({ field }) => {
                  return (
                    <FormField
                      label="Wheelchair"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_wheelchair_service',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_stretcher_service">
                {({ field }) => {
                  return (
                    <FormField
                      label="Stretcher"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_stretcher_service',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_ambulatory_service">
                {({ field }) => {
                  return (
                    <FormField
                      label="Ambulatory"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_ambulatory_service',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_broda_chair_service">
                {({ field }) => {
                  return (
                    <FormField
                      label="Broda Chair"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_broda_chair_service',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_geri_chair_service">
                {({ field }) => {
                  return (
                    <FormField
                      label="Geri Chair"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_geri_chair_service',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
            </>
          }
        />
        <FormFieldSet
          legend="Select a service level(s)"
          Fields={
            <>
              <FormContextField name="can_do_curb_to_curb">
                {({ field }) => {
                  return (
                    <FormField
                      label="Curb to Curb"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_curb_to_curb',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_door_to_door">
                {({ field }) => {
                  return (
                    <FormField
                      label="Door to Door"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_door_to_door',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_door_through_door">
                {({ field }) => {
                  return (
                    <FormField
                      label="Door through Door"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_door_through_door',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
            </>
          }
        />
        <FormFieldSet
          legend="Other skills"
          Fields={
            <>
              <FormContextField name="can_do_bariatric">
                {({ field }) => {
                  return (
                    <FormField
                      label="Bariatric"
                      type="bordered-checkbox"
                      helperText="This attendant can handle patients above 300 lbs"
                      inputProps={{
                        id: 'can_do_bariatric',
                        ...field,
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
              <FormContextField name="can_do_stairs">
                {({ field }) => {
                  return (
                    <FormField
                      label="Stairs"
                      type="bordered-checkbox"
                      inputProps={{
                        ...field,
                        id: 'can_do_stairs',
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
            </>
          }
        />
      </div>
      {children}
      <div className="mt-8 grid grid-cols-2 gap-2">
        <Button
          onClick={() => {
            onCancel();
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          className="w-full flex-grow"
          variant="primary"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </>
  );
}
