import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { createSearchParams } from 'react-router-dom';

import { searchParamsToJSON } from '../util/searchParamsToJSON';

export function clearFiltersExtraReducer<T>(
  builder: ActionReducerMapBuilder<T>
) {
  return builder.addMatcher(
    (action) => {
      const type: string = action.type;
      return type.includes('clearFilters');
    },
    (state) => {
      const currentParams = searchParamsToJSON(
        new URLSearchParams(window.location.search)
      );

      if (currentParams['ordering']) {
        const nextParams = createSearchParams({
          ordering: currentParams['ordering'],
        });

        window.history.pushState(null, '', `?${nextParams.toString()}`);
      } else {
        window.history.pushState(null, '', `?`);
      }

      return state;
    }
  );
}
