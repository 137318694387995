import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getStorageValue } from './getStorageValue';
import { setStorageValue } from './setStorageValue';

type UseLocalStorageReturn<T> = [T, Dispatch<SetStateAction<T>>];

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  parse?: boolean
): UseLocalStorageReturn<T> => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue, parse);
  });

  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
