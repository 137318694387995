import {
  ConfirmModal,
  ConfirmModalProps,
} from 'src/common/modals/ConfirmModal';

export const TripAcceptRequestModal = ({
  props,
}: {
  props: ConfirmModalProps;
}) => {
  return <ConfirmModal {...props} />;
};
