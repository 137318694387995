import { useEffect, useState } from 'react';

import { uniqBy } from 'lodash-es';

import {
  TilledPaymentMethodResponse,
  useLazyTilledPaymentMethodRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';

export type PayerInfoPair = {
  payerId: string | null;
  payerType: 'passenger' | 'payer_organization';
};

export function useHydratePaymentMethods(payerInfoPairs: PayerInfoPair[]) {
  const [trigger] = useLazyTilledPaymentMethodRetrieveQuery();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<TilledPaymentMethodResponse[]>();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setLoading(true);

    const uniquePairs = uniqBy(payerInfoPairs, 'payerId');

    Promise.all(
      uniquePairs.map(({ payerId, payerType }) => {
        if (!payerId) {
          return Promise.resolve({
            items: [],
          });
        }

        return trigger({
          payerId,
          payerType,
        }).unwrap();
      })
    )
      .then((allPaymentData) => {
        const tranformedPaymentData = allPaymentData.flatMap(
          (data) => data.items
        );
        setData(tranformedPaymentData);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // Intentionally limiting the deps on this hook to avoid calls after mount unless payers change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerInfoPairs]);

  return {
    isLoading,
    error,
    data,
  };
}
