import { isNumber } from 'lodash-es';

import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';

import { TripSummaryItemHeader } from './TripSummaryItemHeader';

export function TripSummaryItem({
  onClick,
  tripEvent,
  isSelected,
}: {
  onClick: () => void;
  tripEvent: TripEventRead;
  isSelected: boolean;
}) {
  const isClickable =
    isNumber(tripEvent.latitude) && isNumber(tripEvent.longitude);
  return (
    <div
      className={`relative rounded-lg border border-solid border-[#eaecf0] ${
        isClickable ? 'hover:cursor-pointer' : ''
      }
        
        `}
      onClick={() => {
        if (isClickable) {
          onClick();
        }
      }}
    >
      <style>
        {`
            [data-radix-collection-item][data-state="open"] {
              background-color: F9FAFB;
              border-bottom: 2px solid #EAECF0;
            }
          `}
      </style>
      {/* 
            TODO: There's definitely a way to conditionally wrap TripSummaryItemHeader and remove this duplication 
            but I'm blanking on it.
        */}
      <TripSummaryItemHeader tripEvent={tripEvent} isSelected={isSelected} />
    </div>
  );
}
