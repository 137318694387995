import { useCallback, useState } from 'react';

import { PaginationState } from '@tanstack/react-table';

import { DataGrid } from 'src/common/DataGrid';
import { usePagination } from 'src/common/DataGrid/usePagination';
import { useTilledPayoutsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { columns } from './columns';
import { PayoutsEmptyState } from './PayoutsEmptyState';

export function PayoutsDataGrid() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, refetch } = useTilledPayoutsListQuery({
    page: currentPage,
  });

  const handlePaginationUpdate = useCallback(
    (state: PaginationState) => {
      setCurrentPage(state.pageIndex + 1);
      refetch();
    },
    [refetch]
  );

  const { pagination, handlePaginationChange } = usePagination(
    handlePaginationUpdate
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <DataGrid
      tableId="tilled-payouts"
      pagination={pagination}
      handlePaginationChange={handlePaginationChange}
      columns={columns}
      data={data?.results ?? []}
      totalCount={data?.count}
      emptyState={<PayoutsEmptyState />}
    />
  );
}
