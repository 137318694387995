import { useAppSelector } from 'src/app/store';
import { PricingRule } from 'src/common/external/bambi-api/bambiApi';

import PricingRuleAccordionContentEdit from './PricingRuleAccordionContentEdit';
import { PricingRuleAccordionContentView } from './PricingRuleAccordionContentView';

export function PricingRuleAccordionContent({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const pricingRuleDrafts = useAppSelector(
    (state) => state.pricingModel.pricingRuleDrafts
  );

  const isEditingCurrentRule = !!pricingRuleDrafts?.find(
    (draft) => draft.id === pricingRule.id
  );

  return (
    <div
      className="relative flex flex-col items-start justify-start gap-3 self-stretch pt-4 pr-3 pb-4 pl-9"
      data-testid={`pricing-rule-content-${pricingRule.id}`}
    >
      {isEditingCurrentRule ? (
        <PricingRuleAccordionContentEdit pricingRule={pricingRule} />
      ) : (
        <PricingRuleAccordionContentView pricingRule={pricingRule} />
      )}
    </div>
  );
}
