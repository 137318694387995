import { forwardRef, useCallback, useState } from 'react';

type ForwardedRef = { current: HTMLElement | null };

interface TilledInputContainerProps {
  name: string;
  label: string;
  children?: React.ReactNode;
  className?: string;
}

export const TilledInputContainer = forwardRef<
  HTMLDivElement,
  TilledInputContainerProps
>((props, forwardedRef) => {
  const [hasFocus, setFocus] = useState(false);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, [setFocus]);

  // The incoming ref object is treated as though it doesn't have the current
  // wrapper because its an implementation detail of React.
  const ref: ForwardedRef = forwardedRef as unknown as ForwardedRef;

  // We're firing a custom event to bubble focus/blur from Tilled's input
  // iframe, so it isn't catchable via React's standard focus/blur events
  if (ref.current != null) {
    ref.current.addEventListener('focus', () => {
      handleFocus();
    });
    ref.current.addEventListener('blur', () => {
      handleBlur();
    });
  }

  return (
    <div className={`flex flex-col ${props.className ?? ''}`}>
      <label
        htmlFor={props.name}
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div
        id={props.name}
        data-testid={props.name}
        ref={forwardedRef}
        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm sm:text-sm"
        style={{
          height: '38px',
          // notably the border-x classes don't appear to work for non-form fields
          borderColor: hasFocus ? '#2563eb' : '',
        }}
      >
        {props.children}
      </div>
    </div>
  );
});
