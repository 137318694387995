import { Fragment, useEffect, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'src/app/store';
import { authSlice } from 'src/features/auth/auth.slice';
import { useAuth } from 'src/features/auth/useAuth';
import { setSendbirdAccessToken } from 'src/features/chat/chat.slice';

import { enhancedBambiApi } from '../external/bambi-api/enhancedBambiApi';
import { Avatar } from '../primitives/Avatar';
import { Button } from '../primitives/Button';
import { Modal } from '../primitives/Modal';
import { Select, SelectOption } from '../primitives/Select';
import { useShouldShowOrgSettings } from './useShouldShowOrgSettings';

export function ProfileDropdown() {
  const navigate = useNavigate();
  const { signOut, user, currentOrganizationId, currentOrganizationName } =
    useAuth();
  const shouldShowSettings = useShouldShowOrgSettings();

  const dispatch = useAppDispatch();
  const [isOrganizationChangeOpen, setIsOrganizationChangeOpen] =
    useState(false);
  const [newOrganizationSelection, setNewOrganizationSelection] = useState(
    currentOrganizationId
  );
  const [organizationOptions, setOrganizationOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    setOrganizationOptions(
      user?.memberships.map((membership) => {
        return {
          label: membership.organization.name,
          value: membership.organization.id || '',
        };
      }) || []
    );
  }, [user]);

  useEffect(() => {
    setNewOrganizationSelection(currentOrganizationId);
  }, [currentOrganizationId]);

  const onOrganizationChange = () => {
    dispatch(authSlice.actions.changeOrganization(newOrganizationSelection));
    dispatch(setSendbirdAccessToken(''));
    setIsOrganizationChangeOpen(false);

    // All our data is tied to the organization id with few exceptions
    // This invalidates and refetches with the new org id
    dispatch(enhancedBambiApi.util.resetApiState());
  };

  if (!user) {
    throw new Error('User is required for ProfileDropdown');
  }

  const { first_name, last_name } = user;

  return (
    <Menu as="div" className="relative border-l">
      <Menu.Button data-testid="profile-drop-down-btn">
        <div className="hidden items-center gap-4 truncate pl-6 md:flex">
          <div className="flex max-w-[125px] flex-col text-left md:max-w-[175px]">
            <div className="truncate text-sm font-semibold">
              {currentOrganizationName}
            </div>
            <div className="truncate text-xs text-gray-700">
              {first_name} {last_name}
            </div>
          </div>
          <div>
            <ChevronDownIcon className="h-4 w-4 text-black" />
          </div>
        </div>
        <div className="flex items-center gap-4 pl-4 md:hidden">
          <Avatar firstName={first_name} lastName={last_name} />
        </div>

        <span className="sr-only">Open user menu</span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {shouldShowSettings && (
            <ProfileDropdownMenuItem>
              <button
                onClick={() => {
                  navigate('/settings');
                }}
                className={
                  'block w-full px-4 py-2 text-left text-sm text-gray-700'
                }
              >
                Settings
              </button>
            </ProfileDropdownMenuItem>
          )}
          {user.memberships.length > 1 && (
            <ProfileDropdownMenuItem>
              <button
                onClick={() => {
                  setIsOrganizationChangeOpen(true);
                }}
                className={
                  'block w-full px-4 py-2 text-left text-sm text-gray-700'
                }
              >
                Change Organization
              </button>
            </ProfileDropdownMenuItem>
          )}
          <ProfileDropdownMenuItem>
            <button
              onClick={signOut}
              className={
                'block w-full px-4 py-2 text-left text-sm text-gray-700'
              }
            >
              Sign out
            </button>
          </ProfileDropdownMenuItem>
        </Menu.Items>
      </Transition>
      <Modal
        open={isOrganizationChangeOpen}
        setOpen={setIsOrganizationChangeOpen}
      >
        <div className="flex flex-col gap-2">
          <h2 className="text-md font-bold">
            Which organization would you like to use?
          </h2>
          <Select
            selected={organizationOptions.find((opt) => {
              return opt.value === newOrganizationSelection;
            })}
            options={organizationOptions}
            onChange={(newValue) => {
              setNewOrganizationSelection(newValue?.value || '');
            }}
          />
          <Button
            variant="primary"
            onClick={() => {
              onOrganizationChange();
            }}
          >
            Change Current Organization
          </Button>
        </div>
      </Modal>
    </Menu>
  );
}

function ProfileDropdownMenuItem({ children }: { children: React.ReactNode }) {
  return (
    <Menu.Item>
      <div className="w-full text-left hover:bg-mint-light hover:text-indigo">
        {children}
      </div>
    </Menu.Item>
  );
}
