import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';

import { useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { useUserRoles } from '../auth/useUserRoles';
import { setPayerSearchTerm, setShowPayersModal } from './payers.slice';

export function PayerRouteActions() {
  return (
    <Routes>
      <Route index element={<PayerIndexRouteActions />} />
    </Routes>
  );
}

export function PayerIndexRouteActions() {
  const dispatch = useDispatch();
  const { isRestrictedSupport } = useUserRoles();

  const payerSearchTerm = useAppSelector(
    (state) => state.payer.payerSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setPayerSearchTerm,
    payerSearchTerm
  );

  return (
    <div className="flex items-center gap-4">
      <SearchTypeahead
        dataTestId="payers-search"
        onChange={handleInputChange}
        placeholder="Search payers"
        value={inputValue}
      />
      {!isRestrictedSupport && (
        <Button
          onClick={() => dispatch(setShowPayersModal(true))}
          variant="primary"
        >
          Add a Payer
        </Button>
      )}
    </div>
  );
}
