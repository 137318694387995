import { NavLink } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import { PricingModel } from 'src/common/external/bambi-api/bambiApi';
import { Badge } from 'src/common/primitives/Badge';
import { useIsPricingModelSelected } from 'src/features/pricingModel/management/hooks/useIsPricingModelSelected';

export function PricingModelListItem({
  pricingModel,
}: {
  pricingModel: PricingModel;
}) {
  const isPricingModelSelected = useIsPricingModelSelected(pricingModel);
  const pricingModelDetailsDraft = useAppSelector((state) => {
    return state.pricingModel.pricingModelDetailsDraft;
  });
  return (
    <NavLink
      onClick={(e) => {
        if (pricingModelDetailsDraft) {
          e.preventDefault();
        }
      }}
      key={pricingModel.id}
      to={`/pricing/pricing-models/${pricingModel.id}`}
      className={`relative flex justify-between gap-1 rounded-lg border border-solid border-gray-200 pt-4 pr-3 pb-4 pl-3 ${
        isPricingModelSelected && 'bg-gray-200'
      } ${pricingModelDetailsDraft && 'cursor-not-allowed opacity-50'}`}
      data-testid={pricingModel.id}
    >
      <div className="flex max-w-[75%] flex-col gap-1">
        <div className="truncate text-gray-900">
          {pricingModel.name ? pricingModel.name : <>&nbsp;</>}
        </div>
        <div className="truncate text-xs text-gray-500">
          {pricingModel.details ? pricingModel.details : <>&nbsp;</>}
        </div>
      </div>
      {pricingModel.is_default && (
        <div className="flex items-center justify-center">
          <Badge color="green" label="Default" />
        </div>
      )}
    </NavLink>
  );
}
