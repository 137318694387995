import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { setStorageValue } from 'src/common/util/useLocalStorage/setStorageValue';

export interface IRomState {
  searchTerm: string;
  selectedDateRange:
    | { from: string | undefined; to: string | undefined }
    | undefined;
  selectedPayers: string[];
  selectedStatuses: OverrideStatusEnum[];
  openTripDropdownMenuId: string | null;
}

export const initialState: IRomState = {
  searchTerm: '',
  selectedDateRange: undefined,
  selectedPayers: [],
  selectedStatuses: [],
  openTripDropdownMenuId: null,
};

export const payersStorageKey = 'rom-all-requests-trip-payers-filter';
export const statusesStorageKey = 'rom-all-requests-trip-statuses-filter';

export const romAllRequestsSlice = createSlice({
  name: 'rom',
  initialState,
  reducers: {
    setSearchTerm: (state, action: { payload: string; type: string }) => {
      state.searchTerm = action.payload;
    },
    setSelectedPayers: (state, action: { payload: string[]; type: string }) => {
      setStorageValue(payersStorageKey, action.payload);
      state.selectedPayers = action.payload;
    },
    setSelectedDateRange: (
      state,
      action: {
        payload:
          | { from: string | undefined; to: string | undefined }
          | undefined;
        type: string;
      }
    ) => {
      const from = action.payload?.from
        ? DateTime.fromISO(action.payload.from).startOf('day').toISO()
        : undefined;
      let to = action.payload?.to
        ? DateTime.fromISO(action.payload.to).endOf('day').toISO()
        : undefined;

      if (!to && !!from) {
        to = DateTime.fromISO(from).endOf('day').toISO();
      }

      state.selectedDateRange = { from, to };
    },
    setSelectedStatuses: (
      state,
      action: { payload: OverrideStatusEnum[]; type: string }
    ) => {
      setStorageValue(statusesStorageKey, action.payload);
      state.selectedStatuses = action.payload;
    },
    setOpenTripDropdownMenuId: (
      state,
      action: { payload: string | null; type: string }
    ) => {
      state.openTripDropdownMenuId = action.payload;
    },
    clearFilters(state) {
      state.selectedDateRange = undefined;
      state.selectedPayers = [];
      state.selectedStatuses = [];

      // Reset local storage keys
      setStorageValue(payersStorageKey, []);
      setStorageValue(statusesStorageKey, []);
    },
  },
});

export const {
  setSearchTerm,
  setSelectedPayers,
  setSelectedDateRange,
  setSelectedStatuses,
  setOpenTripDropdownMenuId,
  clearFilters,
} = romAllRequestsSlice.actions;
