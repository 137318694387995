import { useFeature } from 'src/app/FeatureToggle';

export function useIsRideOrderingPortalEnabledForOrganization(): boolean {
  const { isEnabled, isLoading, error } = useFeature('ride ordering portal');

  // If there's an error resolving the feature flag, assume the limited feature set
  if (error) {
    return true;
  }

  // While loading, assume the limited feature set
  return isLoading || isEnabled;
}
