import { useAppDispatch, useAppSelector } from 'src/app/store';
import DateRangeBadge from 'src/common/date/DateRangeBadge';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { romAllRequestsSlice } from './rom-all-requests.slice';

export function RomAllRequestsRouteActions() {
  const dispatch = useAppDispatch();
  const { searchTerm, selectedPayers, selectedStatuses, selectedDateRange } =
    useAppSelector((state) => state.romAllRequests);

  const { inputValue, handleInputChange } = useDebouncedSearch(
    romAllRequestsSlice.actions.setSearchTerm,
    searchTerm
  );

  const handleClearClick = () => {
    dispatch(romAllRequestsSlice.actions.clearFilters());
  };

  return (
    <div className="flex gap-4">
      {selectedPayers.length > 0 ||
      selectedStatuses.length > 0 ||
      selectedDateRange?.from ? (
        <Button
          dataTestId="clear-filters-btn"
          onClick={handleClearClick}
          variant="anchor"
        >
          clear filters
        </Button>
      ) : null}
      {selectedDateRange?.from && selectedDateRange.to && (
        <div>
          <DateRangeBadge
            from={selectedDateRange.from}
            to={selectedDateRange.to}
            onClear={() => {
              dispatch(
                romAllRequestsSlice.actions.setSelectedDateRange(undefined)
              );
            }}
          />
        </div>
      )}
      <SearchTypeahead
        dataTestId="rom-all-requests-search-input"
        onChange={handleInputChange}
        placeholder="Search all requests"
        value={inputValue}
      />
    </div>
  );
}
