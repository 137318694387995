import { TruckIcon } from '@heroicons/react/24/outline';

import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import { VehicleForm } from '../VehicleForm/VehicleForm';

export function VehicleEmptyState() {
  return (
    <div className="py-12">
      <EmptyState
        Icon={<TruckIcon className="mx-auto h-6 w-auto text-gray-700" />}
        entityName="Vehicle"
        description="Click below to add another fleet to manage"
        Action={<VehicleForm />}
      />
    </div>
  );
}
