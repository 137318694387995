import { PriceSummaryData } from './usePriceSummaryTableData';

export function MileageDetails({ data }: { data?: PriceSummaryData | null }) {
  return (
    <>
      <td></td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Free Miles</div>
        <div>{data?.free_miles || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Miles</div>
        <div>{data?.miles?.toFixed(2) || '--'}</div>
      </td>
    </>
  );
}
