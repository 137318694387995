import { Combobox } from 'src/common/primitives/ComboBox';
import { SelectOption } from 'src/common/primitives/Select';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

export function FormFieldComboBox<T>({
  inputProps,
  label,
  error,
  options,
  disabled,
  onChange,
  value,
  helperText,
}: FormFieldProps<T>) {
  if (!options) {
    throw new Error('options is required for combobox type');
  }
  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      helperText={helperText}
    >
      <Combobox
        options={options}
        disabled={disabled}
        onChange={onChange}
        value={value as SelectOption}
        data-testid={inputProps.id}
      />
    </DefaultFormFieldLayout>
  );
}
