import { useAppSelector } from 'src/app/store';
import {
  RunBambiRunWrite,
  useRunBambiRunCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';

import { makeDispatchTimeRange } from '../../common/makeDispatchTimeRange';

export function useRunBambiRun() {
  const { selectedDate } = useAppSelector((state) => state.dispatcher);
  const [startImpl] = useRunBambiRunCreateMutation();
  const dispatchTimeRange = makeDispatchTimeRange(selectedDate);

  return ({
    tripIds,
    assignments,
  }: {
    tripIds: string[];
    assignments: string[];
  }) => {
    const runBambiRun: RunBambiRunWrite = {
      range_start: dispatchTimeRange.start,
      range_end: dispatchTimeRange.end,
      trips: tripIds,
      assignments,
    };

    return startImpl({
      runBambiRun,
    });
  };
}
