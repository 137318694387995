import { useEffect, useMemo, useState } from 'react';

import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  OverrideStatusEnum,
  TripsListApiArg,
  useTripsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { getStorageValue } from 'src/common/util/useLocalStorage/getStorageValue';
import { TripAcceptRequestModal } from 'src/features/dispatch/modals/requestedTrips/accept/TripAcceptRequestModal';
import { useAcceptTripRequestConfirmModalProps } from 'src/features/dispatch/modals/requestedTrips/accept/useAcceptTripRequestConfirmModalProps';
import { TripRejectRequestModal } from 'src/features/dispatch/modals/requestedTrips/reject/TripRejectRequestModal';
import { useRejectTripRequestConfirmModalProps } from 'src/features/dispatch/modals/requestedTrips/reject/useRejectTripRequestConfirmModalProps';

import {
  payersStorageKey,
  setSelectedPayers,
  setSelectedStatuses,
  statusesStorageKey,
} from '../rom-all-requests.slice';
import { columns } from './columns';
import { RomAllRequestsEmptyState } from './RomAllRequestsEmptyState';

export default function RomAllRequestsManagementRoute() {
  const dispatch = useAppDispatch();
  const { searchTerm, selectedDateRange, selectedPayers, selectedStatuses } =
    useAppSelector((state) => state.romAllRequests);

  const [rowSelection, setRowSelection] = useState({});

  const tripAcceptRequestModalProps = useAcceptTripRequestConfirmModalProps();
  const tripRejectRequestModalProps = useRejectTripRequestConfirmModalProps();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  const {
    data: allRequestsTrips,
    refetch,
    isFetching: isLoading,
  } = useTripsListQuery({
    page: pageIndex + 1,
    search: searchTerm,
    rangeStart: selectedDateRange?.from || '',
    rangeEnd: selectedDateRange?.to || '',
    payerIn: selectedPayers,
    statusIn: selectedStatuses.length
      ? selectedStatuses
      : ['requested', 'rejected'],
    ordering: useSearchParams()[0].get(
      'ordering'
    ) as TripsListApiArg['ordering'],
  });

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [searchTerm, refetch]);

  useEffect(() => {
    const storedPayersFilter = getStorageValue(
      payersStorageKey,
      [] as string[],
      true
    );
    const storedStatusFilter = getStorageValue(
      statusesStorageKey,
      [] as OverrideStatusEnum[],
      true
    );

    if (storedPayersFilter && storedPayersFilter.length) {
      dispatch(setSelectedPayers(storedPayersFilter));
    }

    if (storedStatusFilter && storedStatusFilter.length) {
      dispatch(setSelectedStatuses(storedStatusFilter));
    }
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <DataGrid
            tableId="trip-subscription"
            columns={columns}
            data={allRequestsTrips?.results || []}
            pagination={pagination}
            totalCount={allRequestsTrips?.count || 0}
            handlePaginationChange={handlePaginationChange}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            emptyState={<RomAllRequestsEmptyState />}
            getRowId={(row) => row.id}
          />
          <TripRejectRequestModal props={tripRejectRequestModalProps} />
          <TripAcceptRequestModal props={tripAcceptRequestModalProps} />
        </>
      )}
    </>
  );
}
