import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { useAppSelector } from 'src/app/store';
import { DropdownMenuBasic } from 'src/common/DropdownMenu/DropdownMenuBasic';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { useFormValues } from 'src/common/useFormValues';

import { FormAssignment } from '../AssignmentManagementForm/AssignmentManagementFormStep1/defaultFormValues';
import { FormValues } from '../AssignmentManagementForm/AssignmentManagementFormStep1/FormValues';
import { useWeekDays } from '../AssignmentManagementForm/AssignmentManagementFormStep1/useWeekDays';

export function CloneAssignmentAction({
  assignmentFormValuePath,
}: {
  assignmentFormValuePath: string;
}) {
  const { selectedDay } = useAppSelector((state) => state.assignmentManagement);
  const selectedDayOrToday = selectedDay || DateTime.local().toISODate();
  const days = useWeekDays(selectedDayOrToday);
  const formContext = useFormContext();
  const formValues = useFormValues<FormValues>();
  return (
    <DropdownMenuBasic
      trigger={
        // TODO: Embarassing -- I hacked Button.tsx to have a tooltip, but that all swallows the click event
        <button
          className="focus:ring-indigo-500 inline-flex items-center rounded border border-transparent bg-transparent px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-mint disabled:cursor-not-allowed disabled:opacity-50"
          data-testid="assignment-clone-trigger"
        >
          <DocumentDuplicateIcon className="h-5 w-5 cursor-pointer text-gray-700" />{' '}
          Clone
        </button>
      }
    >
      {days
        .filter((day) => {
          // Only show days after today
          // This should be a util
          return day > DateTime.local().startOf('day');
        })
        .map((day) => {
          return (
            <DropdownMenuItem
              onClick={() => {
                const assignmentToClone = get(
                  formValues,
                  assignmentFormValuePath
                );
                const currentValuesOfDayToCloneTo = get(
                  formValues,
                  `assignments.${day.weekdayLong}`
                );
                const newFormValue = {
                  dateTime: day,
                  isEditing: true,
                  assignments: [
                    ...(
                      (currentValuesOfDayToCloneTo.assignments ||
                        []) as FormAssignment[]
                    ).filter((assignment) => {
                      return (
                        assignment.start ||
                        assignment.end ||
                        assignment.driver ||
                        assignment.attendants.length > 0
                      );
                    }),
                    {
                      ...assignmentToClone,
                      // Just in case we want to use this for editing later
                      id: undefined,
                    },
                  ],
                };
                formContext.setValue(
                  `assignments.${day.weekdayLong}`,
                  newFormValue
                );
              }}
              key={day.toFormat('ccc (MM/dd)')}
            >
              {day.toFormat('ccc (MM/dd)')}
            </DropdownMenuItem>
          );
        })}
    </DropdownMenuBasic>
  );
}
