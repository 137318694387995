import { PencilIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import { useAppDispatch } from 'src/app/store';
import { MembershipRead } from 'src/common/external/bambi-api/bambiApi';
import { formatPhoneNumber } from 'src/common/formatPhoneNumber';
import { Button } from 'src/common/primitives/Button';
import { UserTypeBadge } from 'src/common/UserTypeBadge/UserTypeBadge';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { setSelectedMember, setShowMemberForm } from '../member.slice';
import { PersonProfileCell } from './PersonProfileCell';

const columnHelper = createColumnHelper<MembershipRead>();

function RowActions({ member }: { member: MembershipRead }) {
  const dispatch = useAppDispatch();
  const { isFacilityUser, isRestrictedSupport } = useUserRoles();
  const handleEditMember = (member: MembershipRead) => {
    dispatch(setSelectedMember(member));
    dispatch(setShowMemberForm(true));
  };

  return (
    <Button
      disabled={isFacilityUser || isRestrictedSupport}
      className="hover:text-mint"
      onClick={() => handleEditMember(member)}
      variant="ghost"
      dataTestId={`member-grid-edit-button-${member.id}`}
    >
      <PencilIcon className="h-6 w-6" />
    </Button>
  );
}

const firstNameColumn = columnHelper.accessor('first_name', {
  id: 'first_name',
  header: 'Name',
  cell: (props) => <PersonProfileCell member={props.row.original} />,
  enableSorting: true,
});

const phoneNumberColumn = columnHelper.accessor('phone_number', {
  id: 'phone_number',
  header: 'Phone Number',
  cell: ({ getValue }) => formatPhoneNumber(getValue()),
  enableSorting: true,
});

const emailColumn = columnHelper.accessor('email', {
  id: 'email',
  header: 'Email Address',
  enableSorting: true,
});

const roleColumn = columnHelper.accessor('role', {
  id: 'role',
  header: 'User Type',
  cell: ({ getValue }) => (
    <UserTypeBadge userType={getValue() || 'associate'} />
  ),
  enableSorting: true,
});

const deletedAtColumn = columnHelper.accessor('deleted_at', {
  header: 'Deleted On',
  cell({ getValue }) {
    const date = getValue();
    if (!date) {
      return null;
    }

    return (
      <div>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)}</div>
    );
  },
  // Enable when supported by API
  // enableSorting: true,
});

const actionsColumn = columnHelper.display({
  id: 'actions',
  header: 'Edit',
  enableHiding: false,
  cell: (props) => <RowActions member={props.row.original} />,
});

export const columns = [
  firstNameColumn,
  phoneNumberColumn,
  emailColumn,
  roleColumn,
  actionsColumn,
];

export const columnsWithDeletedAt = [
  firstNameColumn,
  phoneNumberColumn,
  emailColumn,
  roleColumn,
  deletedAtColumn,
  actionsColumn,
];
