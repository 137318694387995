import { FunnelIcon } from '@heroicons/react/24/outline';

import { DataGrid } from 'src/common/DataGrid';
import { usePagination } from 'src/common/DataGrid/usePagination';
import { useFilterGroupsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { columns } from './columns';

export function FilterGroupsDataGrid() {
  const { data, isLoading, refetch } = useFilterGroupsListQuery({});

  const { pagination, handlePaginationChange } = usePagination(refetch);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <DataGrid
        emptyState={
          <EmptyState
            Icon={<FunnelIcon className="h-6 w-6" />}
            entityName="Filter Group"
            description="Click 'create examples' to get started"
          />
        }
        data={data?.results ?? []}
        pagination={pagination}
        handlePaginationChange={handlePaginationChange}
        totalCount={data?.count}
        columns={columns}
        tableId="filter-groups"
      />
    </div>
  );
}
