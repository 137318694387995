import { TripEventEventTypeEnum } from 'src/common/external/bambi-api/bambiApi';

export const eventTypeCopyMap: Record<TripEventEventTypeEnum, string> = {
  accept: 'Accepted',
  activate_will_call: 'Will Call Activated',
  assign: 'Assigned',
  cancellation: 'Canceled',
  completion: 'Completed',
  enter_dropoff_geofence: 'Approaching Dropoff',
  enter_pickup_geofence: 'Approaching Pickup',
  exit_dropoff_geofence: 'Leaving Dropoff',
  exit_pickup_geofence: 'Leaving Pickup',
  lock: 'Locked',
  push_needs_at_pickup: 'Push Needs At Pickup',
  push_needs_completed: 'Push Needs Completed',
  push_needs_dropoff: 'Push Needs Dropoff',
  push_needs_transporting: 'Push Needs Transporting',
  rejection: 'Rejected',
  status_at_dropoff: 'Status Change',
  status_at_pickup: 'Status Change',
  status_en_route: 'Status Change',
  status_transporting: 'Status Change',
  unassign: 'Unassigned',
  uncancellation: 'Uncanceled',
  uncompletion: 'Uncompleted',
  unlock: 'Unlocked',
  unrejection: 'Unrejected',
  update_pickup_at: 'Pickup Time Updated',
};
