import { useCallback, useEffect, useMemo, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { usePassengersDuplicatesListQuery } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { removeUnitedStatesFromAddress } from 'src/common/util/removeUnitedStatesFromAddress';
import formatServerError from 'src/common/util/serverErrorFormatter';
import {
  setDuplicatePassengers,
  setParentPassenger,
} from 'src/features/passenger/passenger.slice';

import { PassengerEmptyState } from '../../PassengerEmptyState';
import { IsSameCell } from '../IsSameCell';
import { DuplicatePassenger } from '../types';

const columnHelper = createColumnHelper<DuplicatePassenger>();

interface SelectDuplicatesStepProps {
  next: () => void;
  prev: () => void;
  // duplicatePassengerList: DuplicatePassenger[];
  // rowSelection: Record<string, boolean>;
  // setRowSelection: React.Dispatch<
  //   React.SetStateAction<Record<string, boolean>>
  // >;
}

export function SelectDuplicatesStep({
  next,
  prev,
}: SelectDuplicatesStepProps) {
  const dispatch = useAppDispatch();

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const selectedMatchFields = useAppSelector(
    (state) => state.passenger.selectedMatchFields
  );
  const searchPassengerFirst = useAppSelector(
    (state) => state.passenger.searchPassengerFirst
  );
  const searchPassengerLast = useAppSelector(
    (state) => state.passenger.searchPassengerLast
  );

  const {
    isFetching,
    data: duplicatePassengerList,
    error,
  } = usePassengersDuplicatesListQuery({
    matchFields: selectedMatchFields,
    firstNameStartsWith: searchPassengerFirst
      ? searchPassengerFirst
      : undefined,
    lastNameStartsWith: searchPassengerLast ? searchPassengerLast : undefined,
  });

  const flatList: DuplicatePassenger[] = useMemo(() => {
    const list: DuplicatePassenger[] = [];
    (duplicatePassengerList?.results ?? []).forEach((p) => {
      const parentId = p.base_passenger.id;

      list.push(p.base_passenger);
      p.possible_duplicates.forEach((d) => {
        list.push({
          ...d,
          parentId,
        });
      });
    });
    return list;
  }, [duplicatePassengerList]);

  const selectedPassengersList = useMemo(() => {
    return Object.keys(rowSelection).reduce(
      (acc: DuplicatePassenger[], key: keyof typeof rowSelection) => {
        if (!rowSelection[key]) {
          return acc;
        }

        acc.push(flatList[parseInt(key)]);
        return acc;
      },
      [] as DuplicatePassenger[]
    );
  }, [flatList, rowSelection]);

  const parentPassenger = useMemo(() => {
    if (!selectedPassengersList.length) {
      return null;
    }

    return (
      flatList.find(
        (record) => record.id === selectedPassengersList[0].parentId
      ) ?? null
    );
  }, [selectedPassengersList, flatList]);

  // Track select passengers in state
  useEffect(() => {
    dispatch(setDuplicatePassengers(selectedPassengersList));
  }, [dispatch, selectedPassengersList]);

  // Track parent passenger in state
  useEffect(() => {
    if (!parentPassenger) {
      return;
    }

    dispatch(setParentPassenger(parentPassenger));
  }, [dispatch, parentPassenger]);

  const selectedParentId = useMemo(() => {
    const selected = Object.keys(rowSelection).filter((n) => !!rowSelection[n]);

    if (!selected.length) {
      return null;
    }

    return flatList[parseInt(selected[0])]?.parentId;
  }, [flatList, rowSelection]);

  const shouldDisableRow = useCallback(
    (row: DuplicatePassenger) => {
      if (!selectedParentId) {
        return false;
      }

      return row.parentId !== selectedParentId;
    },
    [selectedParentId]
  );

  const mergePassengerColumns = [
    columnHelper.display({
      id: 'selector',
      cell({ row }) {
        const isPossibleDuplicate = row.original.parentId;
        if (!isPossibleDuplicate) {
          return <div>Base</div>;
        }

        const disabled = shouldDisableRow(row.original);

        return (
          <input
            type="checkbox"
            className={disabled ? 'border-slate-100 bg-slate-100' : ''}
            checked={row.getIsSelected()}
            disabled={disabled}
            onChange={row.getToggleSelectedHandler()}
            data-testid={`select-duplicate-control-${row.original.id}`}
          />
        );
      },
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('full_name', {
      cell: IsSameCell,
      header: 'Name',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.display({
      id: 'address',
      header: 'Address',
      cell(props) {
        const { row } = props;
        return (
          <div key={row.original.id}>
            {row.original.passenger_addresses.map((a, i) => {
              const label = a.address.location?.label ?? '';

              return (
                <div key={`${row.original.id}-${a.id}`}>
                  <IsSameCell
                    {...props}
                    comparator={(parentRow) => {
                      return !!parentRow.passenger_addresses.find(
                        (parentAddress) =>
                          parentAddress.address.location?.place_id ===
                          a.address.location?.place_id
                      );
                    }}
                  >
                    {removeUnitedStatesFromAddress(label)}
                  </IsSameCell>
                </div>
              );
            })}
          </div>
        );
      },
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('email', {
      cell: IsSameCell,
      header: 'Email',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('phone_number', {
      cell: IsSameCell,
      header: 'Phone Number',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('weight', {
      cell: IsSameCell,
      header: 'Weight',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('dob', {
      cell: IsSameCell,
      header: 'Date of Birth',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('gender', {
      cell: IsSameCell,
      header: 'Gender',
      meta: {
        enableHeaderMenu: false,
      },
    }),
  ];

  return (
    <div className="mt-2 flex flex-col gap-2" style={{ minWidth: 450 }}>
      <p>Select duplicate passengers from the list below.</p>
      {isFetching ? (
        <div className="flex flex-row justify-center">
          <LoadingIndicator />
        </div>
      ) : null}
      {error ? (
        <FormErrorMessage>{formatServerError(error)}</FormErrorMessage>
      ) : null}
      {!isFetching ? (
        <DataGrid
          tableId="passenger-merge"
          columns={mergePassengerColumns}
          data={flatList}
          totalCount={flatList.length || 0}
          emptyState={<PassengerEmptyState />}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          includePagination={false}
          pagination={{
            pageIndex: 0,
            pageSize: Infinity,
          }}
        />
      ) : null}
      <div className="flex flex-row justify-evenly gap-2">
        <Button variant="secondary-outline" className="w-full" onClick={prev}>
          Back
        </Button>
        <Button
          variant="primary"
          className="w-full"
          disabled={Object.keys(rowSelection).length === 0}
          onClick={next}
        >
          Verify
        </Button>
      </div>
    </div>
  );
}
