import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { usePricingPricingModelsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { PaginationNavProps } from 'src/common/PaginationNav';
import { useSelectedPricingModelId } from 'src/features/pricingModel/management/hooks/useSelectedPricingModelId';

import { setPricingModels } from '../pricingModel.slice';

export function usePricingModelManagementViewModel() {
  const dispatch = useAppDispatch();
  const {
    isPricingModelsLoading,
    isPricingModelChangePending,
    pricingModelError,
    pricingSearchTerm,
  } = useAppSelector((state) => state.pricingModel);

  const selectedPricingModelId = useSelectedPricingModelId();

  const [pageNumber, setPageNumber] = useState(1);
  const { data: pricingModels } = usePricingPricingModelsListQuery({
    page: pageNumber,
    search: pricingSearchTerm,
  });

  useEffect(() => {
    if (pricingModels?.results) {
      dispatch(setPricingModels(pricingModels.results));
    }
  }, [dispatch, pricingModels]);

  const maxPage =
    typeof pricingModels?.count === 'number'
      ? Math.ceil(pricingModels.count / DEFAULT_PAGINATION_PAGE_SIZE)
      : 1;
  const paginationNavProps: PaginationNavProps = {
    currentPage: pageNumber,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
    totalCount: pricingModels?.count || 0,
    previousPageConfig: {
      disabled: pageNumber <= 1,
      onClick: () => setPageNumber((prevPageNumber) => prevPageNumber - 1),
    },
    setCurrentPage: setPageNumber,
    nextPageConfig: {
      disabled: pageNumber >= maxPage,
      onClick: () => setPageNumber((prevPageNumber) => prevPageNumber + 1),
    },
  };

  return {
    isPricingModelsLoading,
    isPricingModelChangePending,
    pricingModels: pricingModels?.results || [],
    hasPricingModels:
      pricingModels?.results.length && pricingModels.results.length > 0,
    selectedPricingModelId,
    pricingModelError,
    paginationNavProps,
  };
}
