import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';

interface DateFieldProps {
  contextName: string;
  rules: any;
  label: string;
  inputProps: any;
}

export default function DateField({
  contextName,
  rules,
  label,
  inputProps,
}: DateFieldProps) {
  return (
    <FormContextField name={contextName} rules={rules}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label={label}
            error={fieldState.error?.message?.toString()}
            inputProps={{ ...inputProps, ...field }}
            type="date"
            value={field.value || ''}
          />
        );
      }}
    </FormContextField>
  );
}
