import { cloneDeep } from 'lodash-es';

import { DriverRead } from 'src/common/external/bambi-api/bambiApi';

export function convertDriverProfileToDriver(
  patchedDriverProfile: any,
  organizationId: string
): DriverRead {
  const profileOnlyKeys = ['appVersion'];
  const filteredProfile = cloneDeep(patchedDriverProfile);
  profileOnlyKeys.forEach((key) => delete filteredProfile[key]);

  const converted = {
    ...filteredProfile,
    // TODO: resolve these
    membership: filteredProfile.member_id,
    organization_id: organizationId,
    created_at: '',
    updated_at: '',
    is_currently_clocked_in: filteredProfile.is_currently_clocked_in,
  };
  return converted;
}
