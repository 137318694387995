import { PriceSummaryData } from './usePriceSummaryTableData';

export function LateCancellationFeeDetails({
  data,
}: {
  data?: PriceSummaryData | null;
}) {
  return (
    <>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Fee Type</div>
        <div>{data?.late_cancellation_fee_type || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Scheduled Pickup At</div>
        <div>{data?.scheduled_pickup_at || '--'}</div>
      </td>
      <td className="px-2 py-1 text-center text-xs">
        <div className="underline">Cancelled At</div>
        <div>{`${data?.canceled_at} (${data?.minutes_before_pickup} minutes before pickup)`}</div>
      </td>
    </>
  );
}
