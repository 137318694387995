import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IPricingState {
  selectedTripId?: string;
  tripPriceSummaryModalOpen: boolean;
  pricingSearchTerm: string;
  selectedPayers: string[];
}

export const initialPricingState: IPricingState = {
  selectedTripId: undefined,
  tripPriceSummaryModalOpen: false,
  pricingSearchTerm: '',
  selectedPayers: [],
};

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState: initialPricingState,
  reducers: {
    onPriceSummaryModalClose: (state) => {
      state.selectedTripId = undefined;
      state.tripPriceSummaryModalOpen = false;
    },
    onPriceSummaryModalOpen: (state, action: PayloadAction<string>) => {
      state.selectedTripId = action.payload;
      state.tripPriceSummaryModalOpen = true;
    },
    onClearFilters: (state) => {
      state.selectedPayers = [];
    },
    setPricingSearchTerm: (state, action: PayloadAction<string>) => {
      state.pricingSearchTerm = action.payload;
    },
    setSelectedPayers: (state, action: PayloadAction<string[]>) => {
      state.selectedPayers = action.payload;
    },
  },
});

export const {
  onPriceSummaryModalClose,
  onPriceSummaryModalOpen,
  onClearFilters,
  setPricingSearchTerm,
  setSelectedPayers,
} = pricingSlice.actions;
