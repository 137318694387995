import { cloneDeep } from 'lodash-es';

import { TagRead } from 'src/common/external/bambi-api/bambiApi';

import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export const assignDefaultFormValues = (
  tag: TagRead | undefined
): FormValues => {
  if (!tag) return defaultFormValues;

  const tagClone = cloneDeep(tag);

  return {
    id: tagClone.id || defaultFormValues.id,
    tag_name: tagClone.tag_name || defaultFormValues.tag_name,
    internal_use: tagClone.internal_use || defaultFormValues.internal_use,
    description: tagClone.description || defaultFormValues.description,
    color_hex: tagClone.color_hex || defaultFormValues.color_hex,
  };
};
