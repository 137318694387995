import { RowData, Table } from '@tanstack/react-table';

import { PaginationNav } from 'src/common/PaginationNav';

interface DataGridPaginationProps<T> {
  table: Table<T>;
  totalCount: number | undefined;
}

export function DataGridPagination<T extends RowData>({
  table,
  totalCount,
}: DataGridPaginationProps<T>): JSX.Element {
  return (
    <>
      {table.getRowModel().rows.length > 0 && (
        <PaginationNav
          currentPage={table.getState().pagination.pageIndex + 1}
          pageSize={table.getState().pagination.pageSize}
          totalCount={totalCount}
          previousPageConfig={{
            disabled: !table.getCanPreviousPage(),
            onClick: () => table.previousPage(),
          }}
          setCurrentPage={(page) => table.setPageIndex(page - 1)}
          nextPageConfig={{
            disabled: !table.getCanNextPage(),
            onClick: () => table.nextPage(),
          }}
        />
      )}
    </>
  );
}
