import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { onStartAssignmentClone } from '../assignmentManagement.slice';

export function CloneAssignmentAction({ className }: { className?: string }) {
  const dispatch = useAppDispatch();
  const assignment = useAppSelector(
    (state) => state.assignmentManagement.selectedAssignment
  );
  return (
    <Button
      className={`${className}`}
      onClick={() => {
        if (!assignment) {
          return;
        }
        dispatch(onStartAssignmentClone(assignment));
      }}
      disabled={!assignment}
      data-testid="edit-assignment-clone-action"
    >
      Clone Assignment
    </Button>
  );
}
