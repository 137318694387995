import { ListBulletIcon } from '@heroicons/react/24/outline';

import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import { PricingRuleAddButton } from './PricingRuleAddButton';

export function PricingRulesEmptyState() {
  return (
    <EmptyState
      Icon={<ListBulletIcon className="mx-auto h-6 w-auto" />}
      entityName="Pricing Rules"
      description="You don't have any pricing rule added yet."
      Action={<PricingRuleAddButton />}
    />
  );
}
