import { TripUpdateRead } from 'src/common/external/bambi-api/bambiApi';

import { DefaultChangeValueRenderer } from './DefaultChangeValueRenderer';

export function TripLongTextChangeValue({
  tripEvent,
  valueKey,
}: {
  tripEvent: TripUpdateRead;
  valueKey: string;
}) {
  const old = String(tripEvent.values_changed?.[valueKey].old_value);
  const newValue = String(tripEvent.values_changed?.[valueKey].new_value);
  return (
    <div className="flex flex-col gap-1 text-sm">
      <div className="line-through opacity-20">{old}</div>
      <div>
        <DefaultChangeValueRenderer value={newValue} />
      </div>
    </div>
  );
}
