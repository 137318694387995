import { useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { MultiSelectContextField } from 'src/common/FormField/MultiSelectContextField';
import { show } from 'src/common/primitives/Toast/toast.slice';
import { usePayerOrganizationOptions } from 'src/features/payer/usePayerOrganizationOptions';

import {
  PaginatedPayerOrganizationListRead,
  useLazyPayersListQuery,
} from './external/bambi-api/bambiApi';
import { FormContextFieldRulesProps } from './FormField/FormContextField';

export function MultiSelectPayerField({
  fieldPath,
  rules,
  inline,
  fieldLabel,
  helperText,
  disabled = false,
  selectedPayerId,
  canMultiload,
}: {
  fieldPath: string;
  rules?: FormContextFieldRulesProps;
  inline?: boolean;
  helperText?: string;
  fieldLabel?: string;
  disabled?: boolean;
  selectedPayerId?: string;
  canMultiload?: boolean;
}) {
  const dispatch = useAppDispatch();
  const nextToastId = useAppSelector((state) => state.toast.nextId);

  const [getPayers] = useLazyPayersListQuery();
  const [query, setQuery] = useState('');
  const { watch, setValue } = useFormContext();
  const selectedPayerIds = watch(fieldPath);
  const { options, isLoading, isFetching } = usePayerOrganizationOptions(
    query,
    selectedPayerIds,
    selectedPayerId,
    canMultiload
  );

  const label = useMemo(() => {
    const label = fieldLabel || 'Payers';
    return rules?.required ? `${label} *` : label;
  }, [fieldLabel, rules?.required]);

  const onSelectAll = async () => {
    try {
      let next: number | null = 1;
      const payerIds: string[] = [];

      while (next) {
        const response: PaginatedPayerOrganizationListRead = await getPayers({
          page: next,
        }).unwrap();
        payerIds.push(...response.results.map((payer) => payer.id));
        next = response.next ? next + 1 : null;
      }

      setValue(fieldPath, payerIds);
    } catch (error) {
      dispatch(
        show({
          id: nextToastId,
          title: 'Error selecting all payers',
          description:
            'There was an error selecting all payers. Please try again.',
          type: 'error',
        })
      );
    }
  };

  return (
    <div>
      <MultiSelectContextField
        options={options}
        fieldPath={fieldPath}
        label={label}
        disabled={isLoading || isFetching || disabled}
        searchable
        query={query}
        setQuery={setQuery}
        rules={rules}
        inline={inline}
        helperText={helperText}
        onSelectAll={onSelectAll}
      />
    </div>
  );
}
