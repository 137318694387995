interface PriceEstimationRowProps {
  label: string;
  quantity: string;
  price: string;
  bold?: boolean;
}

export function PriceEstimationBreakdownRow({
  label,
  quantity,
  price,
  bold = false,
}: PriceEstimationRowProps) {
  const rowClass = `grid grid-cols-4 gap-2 ${
    !bold && 'border-b border-gray-200'
  }`;

  const boldClass = bold
    ? 'px-2 py-2 text-sm font-medium text-gray-900'
    : 'px-2 py-2 text-sm text-gray-500';

  return (
    <div className={rowClass}>
      <div className="col-span-2 px-2 py-2 text-sm font-medium text-gray-900">
        {label}
      </div>
      <div className={boldClass}>{quantity}</div>
      <div className={boldClass}>{price}</div>
    </div>
  );
}
