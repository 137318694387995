import { useFormContext } from 'react-hook-form';

import { useMembersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { Button } from 'src/common/primitives/Button';
import { Select, SelectOption } from 'src/common/primitives/Select';
import { formatFullName } from 'src/common/util/formatFullName';

import { FormValues } from '../FormValues';

export function AttendantProfileFormStep1({
  onNextClick,
  onCancel,
  children,
}: {
  onNextClick: () => void;
  onCancel: () => void;
  children: React.ReactNode;
}) {
  const { data } = useMembersListQuery({
    'Organization-ID': '1',
    isAttendant: false,
  });
  const members = data?.results || [];
  const form = useFormContext<FormValues>();

  const onNavigateToStep2 = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      onNextClick();
    }
  };
  const getSelectedOption = (val: string): SelectOption[] => {
    const member = members.find((member) => member.id === val);
    if (member) {
      return [
        {
          value: member.id,
          label: formatFullName(member),
        },
      ];
    }

    const { membership, first_name, last_name } = form.getValues();
    return [
      {
        value: membership || '',
        label: formatFullName({ first_name, last_name }),
      },
    ];
  };

  return (
    <div className="mt-4 flex flex-col gap-4">
      <FormContextField
        name="membership"
        rules={{ required: 'Member is required' }}
      >
        {({ field, fieldState }) => {
          return (
            <FormFieldContainer colSpan={12}>
              <DefaultFormFieldLayout
                label="Select a member"
                error={fieldState.error?.message?.toString()}
                inputProps={{ id: 'membership' }}
              >
                <Select
                  placeholder="Select a member to add as an attendant"
                  options={
                    field.value
                      ? getSelectedOption(field.value)
                      : members.map((member) => ({
                          value: member.id,
                          label: `${member.first_name} ${member.last_name}`,
                        }))
                  }
                  disabled={!!form.getValues().id}
                  value={field.value ? field.value : undefined}
                  onChange={(value?: SelectOption) => {
                    const member = members.find(
                      (member) => member.id === value?.value
                    );

                    form.setValue('first_name', member?.first_name || '');
                    form.setValue('last_name', member?.last_name || '');
                    field.onChange(value?.value);
                  }}
                  error={fieldState.error?.message?.toString()}
                />
              </DefaultFormFieldLayout>
            </FormFieldContainer>
          );
        }}
      </FormContextField>

      {children}
      <div className="grid grid-cols-2 gap-2">
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full flex-grow"
          variant="primary"
          onClick={onNavigateToStep2}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
