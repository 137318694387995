import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/solid';

import { Button } from 'src/common/primitives/Button';

import { getPaginationRangeState } from './getPaginationRangeState';
import { PagePillRange } from './PagePillRange';
import { PaginationBreakDots } from './PaginationBreakDots';
import { PaginationInfo } from './PaginationInfo';

export type PaginationNavProps = {
  currentPage: number;
  pageSize: number;
  totalCount?: number;
  previousPageConfig?: {
    disabled?: boolean;
    onClick?: () => void;
  };
  nextPageConfig?: {
    disabled?: boolean;
    onClick?: () => void;
  };
  setCurrentPage: (page: number) => void;
  isCondensed?: boolean;
};

export function PaginationNav({
  currentPage,
  pageSize,
  totalCount,
  previousPageConfig,
  nextPageConfig,
  setCurrentPage,
  isCondensed,
}: PaginationNavProps) {
  const paginationState = getPaginationRangeState(
    currentPage,
    Math.ceil((totalCount || 0) / pageSize)
  );
  return (
    <nav className="flex items-center justify-between px-4 sm:px-0">
      <Button
        dataTestId="previous-page-button"
        onClick={previousPageConfig?.onClick}
        disabled={previousPageConfig?.disabled}
        variant="secondary-outline"
      >
        <ArrowLongLeftIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        {!isCondensed && <span> Previous</span>}
      </Button>
      <div className="hidden items-center md:-mt-px md:flex">
        <PagePillRange
          range={paginationState.leftRange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {paginationState.shouldShowLeftDots && (
          <PaginationBreakDots dataTestId="LEFT_DOTS" />
        )}
        <PagePillRange
          range={paginationState.middleRange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {paginationState.shouldShowRightDots && (
          <PaginationBreakDots dataTestId="RIGHT_DOTS" />
        )}
        <PagePillRange
          range={paginationState.rightRange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {!isCondensed && (
          <PaginationInfo
            currentPage={currentPage}
            totalCount={totalCount || 0}
          />
        )}
      </div>
      <Button
        dataTestId="next-page-button"
        onClick={nextPageConfig?.onClick}
        disabled={nextPageConfig?.disabled}
        variant="secondary-outline"
      >
        {!isCondensed && <span>Next </span>}
        <ArrowLongRightIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Button>
    </nav>
  );
}
