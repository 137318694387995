import { Popup } from 'react-map-gl';

import { AssignmentLocationRead } from 'src/common/external/bambi-api/bambiApi';

import { AssignmentPopupContent } from './AssignmentPopupContent';

export function AssignmentPopup({
  assignmentLocation,
  onClose,
}: {
  assignmentLocation: AssignmentLocationRead;
  onClose: ((e: any) => void) | undefined;
}) {
  return (
    <Popup
      latitude={assignmentLocation.latest_location.latitude}
      longitude={assignmentLocation.latest_location.longitude}
      // offset the popup so that it attaches to the top of the pushpin
      offset={[0, -36]}
      closeButton
      closeOnClick
      onClose={onClose}
      anchor="bottom"
      focusAfterOpen
    >
      <AssignmentPopupContent assignmentLocation={assignmentLocation} />
    </Popup>
  );
}
