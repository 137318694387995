import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { ServiceTypeField } from 'src/common/ServiceTypeField';
import { SpaceTypeField } from 'src/common/SpaceTypeField';
import { useFormValues } from 'src/common/useFormValues';

import { defaultFormValues } from '../../defaultFormValues';
import { FormValues } from '../../FormValues';
import { AdditionalPassengersContextField } from './AdditionalPassengersContextField';
import { NumberAttendantsContextField } from './NumberAttendantsContextField';
import { OxygenLitersPerMinField } from './OxygenLitersPerMinField';
import { OxygenRequiredField } from './OxygenRequiredField';
import { useOrgSettingsDefaultValues } from './ServiceDetailsFormBodyHelpers';
import { WheelchairRequiredField } from './WheelchairRequiredField';

export const serviceDetailsNameRoot = 'service_details';

export function ServiceDetailsFormBody() {
  const formContext = useFormContext();
  const { setValue, clearErrors } = formContext;

  // TODO: This would probably be more appropriate at the root form level where we set defaults
  // ex: assignDefaultValues(orgSettings) or something
  // We also don't really have a loading ux for this, which I didn't add here
  // That's not really an issue because these are probably loaded before the form is rendered
  // Except in tests, which is why I added the fake loader
  const { isLoading } = useOrgSettingsDefaultValues(setValue);

  const formValues = useFormValues<FormValues>();

  useEffect(() => {
    if (!formValues.service_details.is_oxygen_required) {
      setValue(
        `${serviceDetailsNameRoot}.oxygen_liters_per_min`,
        defaultFormValues.service_details.oxygen_liters_per_min
      );
      clearErrors(`${serviceDetailsNameRoot}.oxygen_liters_per_min`);
    }
  }, [formValues.service_details.is_oxygen_required, clearErrors, setValue]);

  return (
    <>
      <div className="flex flex-wrap justify-start gap-4">
        <div className="max-w-xs grow">
          {isLoading && (
            <div
              className="hidden"
              data-testid="fake-loader-for-org-settings-in-tests"
            >
              Loading...
            </div>
          )}
          <SpaceTypeField fieldName={`${serviceDetailsNameRoot}.space_type`} />
        </div>
        <div className="max-w-xs grow">
          <ServiceTypeField
            fieldName={`${serviceDetailsNameRoot}.service_type`}
          />
        </div>
        <div>
          <WheelchairRequiredField />
        </div>
        <div>
          <NumberAttendantsContextField />
        </div>
        <div>
          <AdditionalPassengersContextField />
        </div>
        <div>
          <OxygenRequiredField />
        </div>
        {formValues.service_details.is_oxygen_required && (
          <div>
            <OxygenLitersPerMinField />
          </div>
        )}
      </div>
    </>
  );
}
