import { DateTime } from 'luxon';

import { getFirstDayOfWeek } from './getFirstDayOfWeek';

export function generateWeekDays(date: DateTime): DateTime[] {
  const firstDayOfWeek = getFirstDayOfWeek(date);
  return Array.from(Array(7).keys()).map((i) =>
    firstDayOfWeek.plus({ days: i })
  );
}
