import { useAppSelector } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import { TabNavigation } from 'src/common/TabNavigation';

import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';
import { AddPricingTypeControl } from './AddPricingTypeControl';
import { useDeletePricingType } from './useDeletePricingType';

export function PricingTypeControls() {
  const pricingModel = useSelectedPricingModel();

  const {
    showDeleteModal,
    setShowDeleteModal,
    setDeleteId,
    deleteError,
    handleDelete,
    resetModal,
  } = useDeletePricingType(pricingModel);

  const pricingModelDetailsDraft = useAppSelector((state) => {
    return state.pricingModel.pricingModelDetailsDraft;
  });

  if (!pricingModel && pricingModelDetailsDraft) {
    return (
      <div>
        We'll create pricing types after the new pricing model is saved.
      </div>
    );
  }

  if (!pricingModel) {
    return (
      <div>
        No pricing model found in PricingTypeControls. This shouldn't happen.
      </div>
    );
  }

  const tabs = pricingModel.pricing_types.map((pricingType) => {
    return {
      id: pricingType.id,
      name: pricingType.pricing_type,
      href: `/pricing/pricing-models/${pricingModel.id}/pricing-type/${pricingType.id}`,
    };
  });

  return (
    <div className="flex gap-x-1">
      {tabs.length > 0 && (
        <TabNavigation
          tabs={tabs}
          showDeleteIcon
          onDelete={(id) => {
            setDeleteId(id);
            setShowDeleteModal(true);
          }}
        />
      )}
      <AddPricingTypeControl />
      <DeleteModal
        open={showDeleteModal}
        setOpen={(val) => {
          if (!val) {
            resetModal();
          }
          setShowDeleteModal(val);
        }}
        onCancel={resetModal}
        onConfirm={handleDelete}
        title="Delete this pricing type"
        description="Are you sure you want to delete this pricing type? This action cannot be undone."
        error={deleteError}
      />
    </div>
  );
}
