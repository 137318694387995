import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';

export function invoiceDefaultValuesFactory(
  invoice?: InvoiceRead | null
): Partial<InvoiceRead> {
  return {
    line_items: [],
    notes: '',
    number: '',
    payments: [],
    total_amount_cents: 0,
    total_amount_due_cents: 0,
    date_issued: '',
    ...(invoice ?? {}),
  };
}
