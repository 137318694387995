import { TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';

export function DeleteAction({ onDelete }: { onDelete: () => void }) {
  return (
    <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <Button
        className="sm:col-span-3"
        onClick={onDelete}
        variant="danger"
        dataTestId="delete-member-button"
      >
        <TrashIcon className="mr-2 w-5" /> Delete this member
      </Button>
    </div>
  );
}
