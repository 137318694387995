import { TagIcon } from '@heroicons/react/24/outline';

export function TagEmptyState() {
  return (
    <div
      className="flex justify-center px-[24px] py-12"
      data-testid="tag-empty-state"
    >
      <div className="w-full max-w-sm text-center">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
          <TagIcon className="h-7 w-7" />
        </div>
        <h4 className="mt-3 text-gray-700">No Tags Found</h4>
      </div>
    </div>
  );
}
