import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { MembershipRead } from 'src/common/external/bambi-api/bambiApi';

export const memberAdapter = createEntityAdapter<MembershipRead>();

const { addOne, setAll, updateOne } = memberAdapter;

export const initialState = {
  ...memberAdapter.getInitialState(),
  selectedMember: undefined as MembershipRead | undefined,
  showMemberForm: false,
};

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    addMember: addOne,
    setMembers: setAll,
    updateMember: updateOne,
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setShowMemberForm: (state, action) => {
      state.showMemberForm = action.payload;
    },
  },
});

export const {
  addMember,
  setMembers,
  updateMember,
  setSelectedMember,
  setShowMemberForm,
} = memberSlice.actions;
