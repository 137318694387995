import { PlusIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';
import { useFormValues } from 'src/common/useFormValues';

import { FormValues } from '../FormValues';
import { useAdditionalLeg } from './useAdditionalLeg';

export function MakeAdditionalLegButton() {
  const additionalLeg = useAdditionalLeg();
  const { is_wait_and_return_outbound, is_wait_and_return_inbound } =
    useFormValues<FormValues>();
  return (
    <Button
      onClick={() => additionalLeg()}
      dataTestId="make-additional-leg-btn"
      disabled={is_wait_and_return_outbound || is_wait_and_return_inbound}
      className="flex-grow"
    >
      <div className="flex items-center justify-between gap-x-1">
        <PlusIcon className="h-4 w-4" />
        Additional Leg
      </div>
    </Button>
  );
}
