import { range } from 'lodash-es';

import { Select, SelectOption } from 'src/common/primitives/Select';

interface PricingRuleUnitsCoveredSelectProps {
  value: number | null;
  onChange: (val: number | null) => void;
}

export default function PricingRuleUnitsCoveredSelect({
  value,
  onChange,
}: PricingRuleUnitsCoveredSelectProps) {
  const unitsCoveredRange = range(1, 21);

  const unitsCoveredRangeOptions = unitsCoveredRange.map((value) => ({
    value: value.toString(),
    label: value.toString(),
  })) as SelectOption[];

  const unitsCoveredOptions = [
    { value: '', label: 'None' },
    ...unitsCoveredRangeOptions,
  ];

  return (
    <Select
      options={unitsCoveredOptions}
      value={value ? value.toString() : ''}
      onChange={(val) => {
        onChange(val ? parseInt(val.value) : null);
      }}
    />
  );
}
