import { createApi } from '@reduxjs/toolkit/query/react';
import { DateTime } from 'luxon';
import { saveResponseUsingHiddenElement } from 'src/common/util/saveResponseUsingHiddenElement';
import { bambiBaseQuery } from './bambiBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: bambiBaseQuery,
  endpoints: () => ({}),
});

const customTripEndpoints = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // We do not want to cache files in RTKQuery as we could crash the browser
    // The code gen also does not make it possible to parse the response as a blob
    // For more info: https://github.com/reduxjs/redux-toolkit/issues/1522
    getTripsExportAsCsv: builder.query({
      queryFn: async (
        { rangeStart, rangeEnd, payers, passengers, outputSet, organizationId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/trips/export/?range_start=${rangeStart || ''}&range_end=${
            rangeEnd || ''
          }&payers=${payers || ''}&passengers=${passengers || ''}&output_set=${
            outputSet || ''
          }`,
          method: 'GET',
          headers: {
            'organization-id': organizationId,
          },
          responseHandler: (response) => response.blob(),
        });

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `trip_export_${DateTime.now().toFormat(
          'MMddyyyyHHmmss'
        )}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        saveResponseUsingHiddenElement(result, fileName);
        return { data: null };
      },
    }),
    getAssignmentExportAsCsv: builder.query({
      queryFn: async (
        { rangeStart, rangeEnd, outputSet, organizationId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/assignments/export/?range_start=${
            rangeStart || ''
          }&range_end=${rangeEnd || ''}&output_set=${outputSet || ''}`,
          method: 'GET',
          headers: {
            'organization-id': organizationId,
          },
          responseHandler: (response) => response.blob(),
        });

        if (result.error) {
          return {
            error: {
              status:
                typeof result.error.status === 'number'
                  ? result.error.status
                  : 500,
              statusText: 'Error downloading file',
              data: null,
            },
          };
        }

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `assignment_export_${DateTime.now().toFormat(
          'MMddyyyyHHmmss'
        )}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        saveResponseUsingHiddenElement(result, fileName);
        return { data: 'Download started' };
      },
    }),

    getInvoicesExportListAsCsv: builder.query({
      queryFn: async (
        { rangeStart, rangeEnd, tripPayerIn, invoice, organizationId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/invoices/export/?range_start=${rangeStart || ''}&range_end=${
            rangeEnd || ''
          }&trip__payer__in=${tripPayerIn || ''}&invoice=${invoice || ''}`,
          method: 'GET',
          headers: {
            'organization-id': organizationId,
          },
          responseHandler: (response) => response.blob(),
        });

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `invoice_list_export_${DateTime.now().toFormat(
          'MMddyyyyHHmmss'
        )}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        saveResponseUsingHiddenElement(result, fileName);
        return { data: null };
      },
    }),
  }),
});

function getFilename(headers: Headers, fallback: string): string {
  const content = headers?.get('content-disposition');
  if (!content) {
    return fallback;
  }

  const filename = content.match(/filename="(.*)?"/);
  if (!filename || !filename[1]) {
    return fallback;
  }

  return filename[1];
}

export const {
  useLazyGetTripsExportAsCsvQuery,
  useLazyGetAssignmentExportAsCsvQuery,
  useLazyGetInvoicesExportListAsCsvQuery,
} = customTripEndpoints;
