import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  PricingRule,
  PricingType,
} from 'src/common/external/bambi-api/bambiApi';

import { calculateBaseRateInCents } from './calculateBaseRateInCents';

export function PricingRuleInitialRateDisplay({
  pricingType,
  pricingRule,
}: {
  pricingType: PricingType;
  pricingRule: PricingRule;
}) {
  const totalInCents = calculateBaseRateInCents(pricingType, pricingRule);
  const total = defaultCurrencyFormat(totalInCents);
  return (
    <div>
      <span className="text-lg font-medium text-gray-700">{total}</span>
      &nbsp;
      <span className="text-sm text-gray-700">
        {`per ${pricingType.unit.toLowerCase()}`}
      </span>
    </div>
  );
}
