import { useEffect } from 'react';

import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import { setEventCalendarScrollable } from '../../dispatcher.slice';

export function ToggleCalendarEventScrollableButton() {
  const dispatch = useAppDispatch();
  const {
    isEventCalendarScrollable,
    selectedDate,
    showToggleCalendarScrollButton,
  } = useAppSelector((state) => state.dispatcher);

  useEffect(() => {
    dispatch(setEventCalendarScrollable(false));
  }, [dispatch, selectedDate]);

  return (
    <>
      {showToggleCalendarScrollButton && (
        <Button
          className="mx-1 px-0"
          variant="ghost"
          onClick={() => {
            dispatch(setEventCalendarScrollable(!isEventCalendarScrollable));
          }}
          alt={
            isEventCalendarScrollable
              ? 'Collapse Assignments'
              : 'Expand Assignments'
          }
          ariaLabel={
            isEventCalendarScrollable
              ? 'Collapse Assignments'
              : 'Expand Assignments'
          }
        >
          {isEventCalendarScrollable ? (
            <ArrowLeftOnRectangleIcon
              className="h-5 w-5"
              data-testid="arrow-left-box-icon"
            />
          ) : (
            <ArrowRightOnRectangleIcon
              className="h-5 w-5"
              data-testid="arrow-right-box-icon"
            />
          )}
        </Button>
      )}
    </>
  );
}
