import { isNumber } from 'lodash-es';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useMapRoute } from 'src/common/useMapRoute';

import { selectCurrentTripSummaryId } from './selectors/selectCurrentTripSummaryId';
import { selectTripEvents } from './selectors/selectTripEvents';
import { selectTripLocations } from './selectors/selectTripLocations';
import { TripEventMarker } from './TripEventMarker';
import { tripSummarySlice } from './tripSummary.slice';

export function TripEventMarkers() {
  const dispatch = useAppDispatch();
  const tripEvents = useAppSelector(selectTripEvents);
  const tripLocations = useAppSelector(selectTripLocations);
  const selectedTripEventId = useAppSelector(selectCurrentTripSummaryId);
  const isMapStyleLoaded = useAppSelector(
    (state) => state.tripSummary.isMapStyleLoaded
  );
  useMapRoute(
    `trip-summary`,
    tripLocations.map((tripLocation) => ({
      lat: tripLocation.latitude,
      long: tripLocation.longitude,
    })),
    !!selectedTripEventId && tripLocations.length > 2
  );

  return (
    <>
      {tripEvents
        .filter((tripEvent) => {
          return (
            // Forcing a rerender of the markers when the style is loaded
            isMapStyleLoaded &&
            isNumber(tripEvent.latitude) &&
            isNumber(tripEvent.longitude)
          );
        })
        .map((tripEvent) => (
          <TripEventMarker
            key={tripEvent.id}
            tripEventMarker={tripEvent}
            onClick={() => {
              if (selectedTripEventId === tripEvent.id) {
                dispatch(tripSummarySlice.actions.deselectTripSummary());
                return;
              }
              dispatch(
                tripSummarySlice.actions.selectTripSummary(tripEvent.id)
              );
            }}
          />
        ))}
    </>
  );
}
