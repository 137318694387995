import { useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  Assignment,
  AttendantRead,
  DriverRead,
  PaginatedAttendantListRead,
  useAttendantsListQuery,
  useDriversListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';
import { formatFullName } from 'src/common/util/formatFullName';
import { DynamicFieldNameRootContext } from 'src/features/add-trip/TripDetails/DynamicFieldNameRootContext';

type UseAssignmentEditorViewModelInput = {
  day: DateTime;
};

export type UseAssignmentEditorViewModelReturn = {
  isEditingExistingAssignment: boolean;
  handleDeleteAssignment: (index: number) => void;
  attendantOptions: () => SelectOption[];
  attendantsData?: PaginatedAttendantListRead;
  fieldNameRoot: string;
  driverLocked: boolean;
  driverOptions: () => SelectOption[];
  drivers: DriverRead[];
  attendants: AttendantRead[];
  driversLoading: boolean;
  attendantsLoading: boolean;
};

export function useAssignmentEditorViewModel({
  day,
}: UseAssignmentEditorViewModelInput): UseAssignmentEditorViewModelReturn {
  const formContext = useFormContext();
  const [drivers, setDrivers] = useState<DriverRead[]>([]);
  const [driverLocked, setDriverLocked] = useState<boolean>(false);
  const [attendants, setAttendants] = useState<AttendantRead[]>([]);
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);
  const selectedAssignment = useSelector(
    (state: RootState) => state.assignmentManagement.selectedAssignment
  );

  const { data: driversData, isLoading: driversLoading } = useDriversListQuery({
    canBeScheduled: true,
  });

  const { data: attendantsData, isLoading: attendantsLoading } =
    useAttendantsListQuery({
      canBeScheduled: true,
    });

  useEffect(() => {
    if (driversData?.results) {
      if (selectedAssignment?.attendants.length) {
        setDrivers(
          driversData.results.filter(
            (x) =>
              !selectedAssignment.attendants
                .map((x) => x.membership)
                .includes(x.membership)
          )
        );
      } else {
        setDrivers(driversData.results);
      }
    }

    if (attendantsData?.results) {
      if (selectedAssignment?.driver.driver) {
        setAttendants(
          attendantsData.results.filter(
            (x) => selectedAssignment.driver.membership !== x.membership
          )
        );
      } else {
        setAttendants(attendantsData.results);
      }
    }

    setDriverLocked(!!selectedAssignment?.driver.clocked_in_at);
  }, [driversData, attendantsData, selectedAssignment]);

  const driverOptions = () => {
    return drivers.map((driver: DriverRead) => {
      return {
        label: formatFullName(driver),
        value: driver.id,
      };
    });
  };

  const attendantOptions = () => {
    return attendants.map((attendant: AttendantRead) => {
      return {
        label: formatFullName(attendant),
        value: attendant.id,
      };
    });
  };

  const handleDeleteAssignment = (index: number) => {
    const { assignments } = formContext.getValues();

    const dayAssignments = assignments[day.weekdayLong].assignments.filter(
      (_: Assignment, i: number) => i !== index
    );

    formContext.setValue(
      `assignments.${day.weekdayLong}.assignments`,
      dayAssignments
    );
  };

  return {
    isEditingExistingAssignment: !!selectedAssignment?.id,
    handleDeleteAssignment,
    attendantOptions,
    attendantsData,
    fieldNameRoot,
    driverOptions,
    driverLocked,
    drivers,
    attendants,
    driversLoading,
    attendantsLoading,
  };
}
