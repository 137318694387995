import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  PriceSummary,
  TripPriceSummaryRead,
} from 'src/common/external/bambi-api/bambiApi';

import { usePriceSummaryTableData } from './usePriceSummaryTableData';

export function PriceSummaryTable({
  summary,
}: {
  summary: TripPriceSummaryRead['price_summary'] | PriceSummary;
}) {
  const trClass = 'border-b border-gray-300 even:bg-gray-100 odd:bg-white';

  const { feeSummaries, getLabelDetails } = usePriceSummaryTableData(summary);

  return (
    <table
      className="min-w-full divide-y divide-gray-300 text-sm"
      data-testid="price-summary-table"
    >
      <thead>
        <tr className={trClass}>
          <th className="p-2 text-center text-lg" colSpan={4}>
            {summary?.name || 'Pricing Summary'}
          </th>
          <th className="p-2">Rate</th>
          <th className="p-2">Subtotal</th>
        </tr>
      </thead>
      <tbody>
        {feeSummaries
          .filter((fee) => !!fee.data)
          .map((fee, index) =>
            fee.data && fee.subTotal ? (
              <tr key={index} className={trClass}>
                <td className="py-2 px-4">{fee.label}</td>
                {getLabelDetails(fee.label, fee.data)}
                <td className="py-2 px-4 font-mono">
                  {fee.data.rate ? defaultCurrencyFormat(fee.data.rate) : '--'}
                </td>
                <td className="py-2 px-4 font-mono">
                  {defaultCurrencyFormat(fee.data.sub_total)}
                </td>
              </tr>
            ) : null
          )}
      </tbody>
      <tfoot>
        <tr>
          <td className="px-4 pt-4" colSpan={4}></td>
          <td className="pt-4 font-mono text-lg font-bold">Total</td>
          <td className="px-2 pt-4 font-mono text-lg font-bold">
            {defaultCurrencyFormat(summary?.total || 0)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
