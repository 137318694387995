import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { createSearchParams } from 'react-router-dom';

import {
  bambiApi,
  FilterGroup,
  OverrideStatusEnum,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';
import { clearFiltersExtraReducer } from 'src/common/redux/clearFiltersExtraReducer';
import { searchParamsToJSON } from 'src/common/util/searchParamsToJSON';
import { setStorageValue } from 'src/common/util/useLocalStorage/setStorageValue';

import { authSlice } from '../auth/auth.slice';
import { generateUploadTaskDetails } from './management/download/generateUploadDetails';

export enum DownloadCsvDetails {
  ALL = 'ALL',
  PAYER = 'PAYER',
  EVERTRANSIT = 'EVERTRANSIT',
  KINETIK = 'KINETIK',
}

export type TripUploadErrorItem = {
  trip: string;
  errors: string[];
};

export type TripsUploadSummary = {
  details: string;
  errors: TripUploadErrorItem[];
};

export enum InvoicedTripsValue {
  ALL_TRIPS = 'all',
  INVOICED_TRIPS = 'invoiced',
  NOT_INVOICED_TRIPS = 'not_invoiced',
}

export interface ITripState {
  trips: TripRead[];
  tripSearchTerm: string;
  selectedDateRange:
    | { from: string | undefined; to: string | undefined }
    | undefined;
  selectedPayers: string[];
  selectedTags: string[];
  selectedStatuses: OverrideStatusEnum[];
  downloadCsvDetail: DownloadCsvDetails;
  selectedTrip: TripRead | undefined;
  showDownloadModal: boolean;
  showDownloadDetailsModal: boolean;
  showUploadModal: boolean;
  showUploadDetailsModal: boolean;
  showTripTagsModal: boolean;
  uploadPayerTemplate?: SelectOption;
  uploadPayerId?: SelectOption;
  uploadInProgress: boolean;
  uploadJobId?: string | null;
  csvUploadError?: TripsUploadSummary | string;
  csvUploadSuccessMessage?: string | null;
  selectedTripRows: Record<TripRead['id'], TripRead | null>;
  showBulkCancelTripModal?: boolean;
  filterInvoicedTrips?: InvoicedTripsValue;
  selectedFilterGroup?: FilterGroup | null;
}

export const initialState: ITripState = {
  trips: [],
  tripSearchTerm: '',
  selectedDateRange: undefined,
  selectedPayers: [],
  selectedStatuses: [],
  selectedTags: [],
  downloadCsvDetail: DownloadCsvDetails.ALL,
  selectedTrip: undefined,
  showDownloadModal: false,
  showDownloadDetailsModal: false,
  showUploadModal: false,
  showUploadDetailsModal: false,
  showTripTagsModal: false,
  uploadInProgress: false,
  csvUploadError: undefined,
  selectedTripRows: {},
  showBulkCancelTripModal: false,
  filterInvoicedTrips: InvoicedTripsValue.ALL_TRIPS,
  selectedFilterGroup: undefined,
};

export const payersStorageKey = 'trip-payers-filter';
export const statusesStorageKey = 'trip-statuses-filter';
export const tagsStorageKey = 'trip-tags-filter';
export const dateRangeStorageKey = 'trips-daterange-filter';

export const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setTrips: (state, action: { payload: TripRead[]; type: string }) => {
      state.trips = action.payload;
    },
    setTripSearchTerm: (state, action: { payload: string; type: string }) => {
      state.tripSearchTerm = action.payload;
    },
    setSelectedDateRange: (
      state,
      action: {
        payload:
          | { from: string | undefined; to: string | undefined }
          | undefined;
        type: string;
      }
    ) => {
      const from = action.payload?.from
        ? DateTime.fromISO(action.payload.from).startOf('day').toISO()
        : undefined;
      let to = action.payload?.to
        ? DateTime.fromISO(action.payload.to).endOf('day').toISO()
        : undefined;

      if (!to && !!from) {
        to = DateTime.fromISO(from).endOf('day').toISO();
      }

      setStorageValue(dateRangeStorageKey, { from, to });
      state.selectedDateRange = { from, to };
    },
    setSelectedPayers: (state, action: { payload: string[]; type: string }) => {
      setStorageValue(payersStorageKey, action.payload);
      state.selectedPayers = action.payload;
    },
    setSelectedStatuses: (
      state,
      action: { payload: OverrideStatusEnum[]; type: string }
    ) => {
      setStorageValue(statusesStorageKey, action.payload);
      state.selectedStatuses = action.payload;
    },
    setSelectedTags: (state, action: { payload: string[]; type: string }) => {
      setStorageValue(tagsStorageKey, action.payload);
      state.selectedTags = action.payload;
    },
    setSelectedTrip: (state, action: { payload: TripRead; type: string }) => {
      state.selectedTrip = action.payload;
    },
    setShowDownloadModal: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.showDownloadModal = action.payload;
    },
    setShowDownloadDetailsModal: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.showDownloadDetailsModal = action.payload;
    },
    setDownloadCsvDetail: (
      state,
      action: { payload: DownloadCsvDetails; type: string }
    ) => {
      state.downloadCsvDetail = action.payload;
    },
    setShowUploadModal: (state, action: { payload: boolean; type: string }) => {
      state.showUploadModal = action.payload;
    },
    setShowUploadDetailsModal: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.showUploadDetailsModal = action.payload;
    },
    setShowTripTagsModal: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.showTripTagsModal = action.payload;
    },
    selectUploadPayerTemplate: (
      state,
      action: { payload: SelectOption; type: string }
    ) => {
      state.uploadPayerTemplate = action.payload;
    },
    selectUploadPayerId: (
      state,
      action: { payload: SelectOption; type: string }
    ) => {
      state.uploadPayerId = action.payload;
    },
    setUploadInProgress: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      state.uploadInProgress = action.payload;
    },
    setCsvUploadError: (
      state,
      action: {
        payload: TripsUploadSummary | string | undefined;
        type: string;
      }
    ) => {
      state.csvUploadError = action.payload;
    },
    setUploadJobId: (
      state,
      action: { payload: string | null; type: string }
    ) => {
      state.uploadJobId = action.payload;
    },
    setCsvUploadSuccessMessage: (
      state,
      action: { payload: string | null; type: string }
    ) => {
      state.csvUploadSuccessMessage = action.payload;
    },
    setSelectedTripRows: (
      state,
      action: { payload: Record<TripRead['id'], TripRead | null> }
    ) => {
      state.selectedTripRows = action.payload;
    },
    clearSelectedTripRows: (state) => {
      state.selectedTripRows = {};
    },
    removeSelectedTripRow: (state, action: { payload: TripRead['id'] }) => {
      delete state.selectedTripRows[action.payload];
    },
    setShowBulkCancelTripModal: (state, action: { payload: boolean }) => {
      state.showBulkCancelTripModal = action.payload;
    },
    setFilterInvoicedTrips: (
      state,
      action: PayloadAction<InvoicedTripsValue>
    ) => {
      state.filterInvoicedTrips = action.payload;
    },
    // Translates ?search into state
    initializeFilters(state) {
      const currentParams = searchParamsToJSON(
        new URLSearchParams(window.location.search)
      );

      Object.keys(currentParams).forEach((param) => {
        const rawValue = currentParams[param];

        switch (param) {
          case 'DateRange': {
            const range = JSON.parse(rawValue as string);

            state.selectedDateRange = {
              from: range.rangeStart,
              to: range.rangeEnd,
            };

            break;
          }
          case 'Payers':
          case 'Statuses':
          case 'Tags':
            // @ts-ignore
            state[`selected${param}`] = [rawValue].flatMap((n) => n);
            break;
          case 'InvoicedTrips':
            state.filterInvoicedTrips = rawValue as InvoicedTripsValue;
            break;
        }
      });
    },
    setSelectedFilterGroup(state, action: PayloadAction<FilterGroup | null>) {
      const payload = action.payload;
      state.selectedFilterGroup = payload;

      const currentParams = searchParamsToJSON(
        new URLSearchParams(window.location.search)
      );

      const nextParams = createSearchParams({
        ordering: currentParams.ordering ?? '',
        filterGroup: payload?.slug ?? '',
      });

      window.history.pushState(null, '', `?${nextParams.toString()}`);

      // Translate filter group into filters
      if (!payload) {
        return;
      }

      Object.keys(payload.filters).forEach((filterName) => {
        // @ts-ignore
        const filterValue = payload.filters[filterName] ?? '';

        switch (filterName) {
          case 'range_start':
            state.selectedDateRange = {
              ...(state.selectedDateRange ?? {
                to: undefined,
              }),
              from: filterValue,
            };
            break;
          case 'range_end':
            state.selectedDateRange = {
              ...(state.selectedDateRange ?? {
                from: undefined,
              }),
              to: filterValue,
            };
            break;
          case 'has_invoice':
            state.filterInvoicedTrips = filterValue as InvoicedTripsValue;
            break;
          case 'payer__in':
            state.selectedPayers = filterValue as string[];
            break;
          case 'status__in':
            state.selectedStatuses = filterValue as OverrideStatusEnum[];
            break;
          case 'tag__in':
            state.selectedTags = filterValue as string[];
            break;
        }
      });
    },
    clearFilters(state) {
      state.selectedDateRange = undefined;
      state.selectedPayers = [];
      state.selectedStatuses = [];
      state.selectedTags = [];
      state.filterInvoicedTrips = InvoicedTripsValue.ALL_TRIPS;
      state.selectedFilterGroup = undefined;

      // Reset local storage keys
      setStorageValue(payersStorageKey, []);
      setStorageValue(tagsStorageKey, []);
      setStorageValue(statusesStorageKey, []);
      setStorageValue(dateRangeStorageKey, []);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.changeOrganization, (state) => {
      // Reset local storage keys when org changes
      setStorageValue(payersStorageKey, []);
      setStorageValue(tagsStorageKey, []);
      setStorageValue(statusesStorageKey, []);

      // Reset filters when org changes
      state.selectedPayers = [];
      state.selectedTags = [];
      state.selectedStatuses = [];
    });

    builder.addMatcher(
      bambiApi.endpoints.tripImportRetrieve.matchFulfilled,
      (state, action) => {
        if (!action.payload) {
          return;
        }
        switch (action.payload.state) {
          case 'STARTED':
          case 'PENDING':
            return;
        }
        state.uploadInProgress = false;
        state.uploadJobId = null;
        const details = generateUploadTaskDetails(action.payload.result);
        if (details.errors.length === 0) {
          state.csvUploadSuccessMessage = details.details;
        } else {
          // TODO: Let's just make csvUploadError a details object always
          state.csvUploadError = details;
        }
      }
    );

    clearFiltersExtraReducer(builder);

    // Translates filter payload into ?search
    builder.addMatcher(
      (action) => {
        const type: string = action.type;
        return [
          'trip/setSelectedDateRange',
          'trip/setSelectedPayers',
          'trip/setSelectedStatuses',
          'trip/setSelectedTags',
          'trip/setFilterInvoicedTrips',
        ].includes(type);
      },
      (state, action) => {
        const type: string = action.type;
        const payload: unknown = action.payload;
        const filterParam = type.match(/set(Filter|Selected)([a-z]+)/i)?.[2];

        if (!filterParam) {
          return state;
        }

        const value: string | string[] | undefined = (() => {
          switch (filterParam) {
            case 'DateRange':
              return JSON.stringify(payload);
            case 'Payers':
            case 'Statuses':
            case 'Tags':
              return payload as string[];
            case 'InvoicedTrips':
              return payload as string;
          }
        })();

        if (value) {
          const currentParams = searchParamsToJSON(
            new URLSearchParams(window.location.search)
          );
          const nextParams = createSearchParams({
            ...currentParams,
            [filterParam]: value,
          });

          window.history.pushState(null, '', `?${nextParams.toString()}`);
        }

        return state;
      }
    );
  },
});

export const {
  setTrips,
  setTripSearchTerm,
  setSelectedDateRange,
  setSelectedPayers,
  setSelectedStatuses,
  setSelectedTags,
  setSelectedTrip,
  setDownloadCsvDetail,
  setShowDownloadModal,
  setShowDownloadDetailsModal,
  setShowUploadModal,
  setShowTripTagsModal,
  selectUploadPayerTemplate,
  selectUploadPayerId,
  setShowUploadDetailsModal,
  setUploadInProgress,
  setCsvUploadError,
  setUploadJobId,
  setCsvUploadSuccessMessage,
  setSelectedTripRows,
  clearSelectedTripRows,
  removeSelectedTripRow,
  setShowBulkCancelTripModal,
  setFilterInvoicedTrips,
  initializeFilters,
  setSelectedFilterGroup,
  clearFilters,
} = tripSlice.actions;
