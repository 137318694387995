import { Amplify } from 'aws-amplify';

import {
  APP_BASE_URI,
  AUTH_BASE_URL,
  AUTH_CLIENT_ID,
  AUTH_COGNITO_IDENTITY_POOL_ID,
  AUTH_FLOW_TYPE,
  AUTH_REGION,
  AUTH_RESPONSE_TYPE,
  AUTH_SIGN_OUT_URL,
  AUTH_USER_POOL_ID,
  GEO_MAP_RESOURCE,
  LOCATION_PLACE_INDEX,
} from './constants';

export const configureAuth = () => {
  Amplify.configure({
    Auth: {
      region: AUTH_REGION,
      userPoolId: AUTH_USER_POOL_ID,
      userPoolWebClientId: AUTH_CLIENT_ID,
      mandatorySignIn: true,
      authenticationFlowType: AUTH_FLOW_TYPE,
      identityPoolId: AUTH_COGNITO_IDENTITY_POOL_ID,
      oauth: {
        domain: (AUTH_BASE_URL || '').replace('https://', ''),
        scope: ['email', 'openid'],
        // Must exist in Allowed login URLs in Cognito
        // If not, leads to error: "missing param redirect_uri"
        redirectSignIn: APP_BASE_URI,
        // Must exist in Allowed logout URLs in Cognito
        // If not, leads to error: "missing param redirect_uri"
        redirectSignOut: AUTH_SIGN_OUT_URL,
        responseType: AUTH_RESPONSE_TYPE,
      },
    },
    geo: {
      AmazonLocationService: {
        region: AUTH_REGION,
        maps: {
          items: {
            [GEO_MAP_RESOURCE]: {
              // REQUIRED - Amazon Location Service Map resource name
              style: 'VectorEsriStreets', // REQUIRED - String representing the style of map resource
            },
          },
          default: GEO_MAP_RESOURCE, // REQUIRED - Amazon Location Service Map resource name to set as default
        },
        search_indices: {
          items: [LOCATION_PLACE_INDEX], // REQUIRED - Amazon Location Service Place Index name
          default: LOCATION_PLACE_INDEX, // REQUIRED - Amazon Location Service Place Index name to set as default
        },
        // TODO: This doesn't seem to be actually required for the map to work
        // geofenceCollections: {
        //   items: ['demo'], // REQUIRED - Amazon Location Service Geofence Collection name
        //   default: 'demo', // REQUIRED - Amazon Location Service Geofence Collection name to set as default
        // },
      },
    },
  });
};
