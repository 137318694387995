import { FieldValues, useFormContext, useWatch } from 'react-hook-form';

export function useFormValues<T extends FieldValues>() {
  const { getValues } = useFormContext<T>();

  return {
    ...useWatch(),
    ...getValues(), // always merge with latest form values
  };
}
