import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';

import { NumericStepper } from '../../TripDetails/NumericStepper';
import { serviceDetailsNameRoot } from './ServiceDetailsFormBody';

export function AdditionalPassengersContextField() {
  const fieldName = `${serviceDetailsNameRoot}.num_accompanying_passengers`;
  return (
    <FormContextField name={fieldName}>
      {({ field, fieldState }) => {
        return (
          <DefaultFormFieldLayout
            label="Additional passengers"
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: fieldName }}
          >
            <NumericStepper
              {...field}
              onChange={(e: any) => {
                field.onChange(Number(e.target.value));
              }}
              min={0}
            />
          </DefaultFormFieldLayout>
        );
      }}
    </FormContextField>
  );
}
