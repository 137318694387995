import { PaginatedPassengerAddressListRead } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export default function getRecentAddressesAsOptions(
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined
): SelectOption[] {
  return (
    recentAddressResponse?.results.map((result) => ({
      label: result.address.location?.label || '',
      value: result.address.location?.place_id || '',
    })) || []
  );
}
