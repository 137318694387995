import { useContext, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { DynamicFieldNameRootContext } from 'src/features/add-trip/TripDetails/DynamicFieldNameRootContext';

import { getInputPriceLabel } from './getInputPriceLabel';
import { getPricingInputClassName } from './getPricingInputClassName';
import { PriceEstimationState } from './UseTripPriceEstimationReturnValue';

export function useTripInputPriceViewModel({
  priceEstimationState,
  isManuallyUpdatedPrice,
}: {
  priceEstimationState: PriceEstimationState;
  isManuallyUpdatedPrice: boolean;
}) {
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);
  const { setValue, resetField, getFieldState } = useFormContext();

  useEffect(() => {
    if (priceEstimationState.result) {
      // If any fields affecting price estimation change, we use the new price estimation
      // Resetting the field is necessary because we assume that if the user
      // changes the input price they want to override the price estimation
      // because we use fieldState.isDirty to determine if the user has changed the input price
      resetField(`${fieldNameRoot}.input_price_cents`);
      setValue(
        `${fieldNameRoot}.input_price_cents`,
        priceEstimationState.result.pricing_results.total_price
      );
    }
  }, [priceEstimationState.result, setValue, fieldNameRoot, resetField]);

  const fieldName = `${fieldNameRoot}.input_price_cents`;
  const fieldState = getFieldState(fieldName);
  const isEstimated = !!(
    !isManuallyUpdatedPrice &&
    !fieldState.isDirty &&
    priceEstimationState.result
  );
  const fieldLabelText = getInputPriceLabel(
    isEstimated,
    priceEstimationState.isCalculating,
    isManuallyUpdatedPrice
  );
  const inputClassName = getPricingInputClassName({
    hasError: !!fieldState.error?.message?.toString(),
  });
  return {
    fieldName,
    priceEstimationState,
    isEstimated,
    fieldLabelText,
    inputClassName,
  };
}
