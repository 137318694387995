import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useFeature } from 'src/app/FeatureToggle';
import { RootState, useAppSelector } from 'src/app/store';
import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import {
  PricingModelSelectionWrite,
  PricingRead,
  TripRead,
  TripWrite,
  useOrganizationSettingsRetrieveQuery,
  usePricingPriceEstimateCreateMutation,
  usePricingPricingModelSelectionCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { useFormValues } from 'src/common/useFormValues';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { FormValues } from 'src/features/add-trip/FormValues';

import { PriceEstimationState } from './UseTripPriceEstimationReturnValue';

type SelectedTrip = TripWrite & TripRead;

export function useTripPriceEstimation({
  tripIndex,
}: {
  tripIndex: number;
}): PriceEstimationState {
  const selectedTrip = useAppSelector(
    (state: RootState) => state.addATrip.selectedTrip
  );
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState<PricingRead | undefined>();
  const [createPricingModel] = usePricingPricingModelSelectionCreateMutation();
  const [createPricingEstimate] = usePricingPriceEstimateCreateMutation();
  const {
    trips,
    service_details,
    pricing,
    is_round_trip,
    passenger,
    must_provide_wheelchair,
  } = useFormValues<FormValues>();
  const { formState } = useFormContext();
  const { isEnabled: isPricingV1Enabled } = useFeature('pricing version 1');
  const { data: orgSetting } = useOrganizationSettingsRetrieveQuery({});
  const payer = pricing.payer;
  const trip = trips[tripIndex];
  const payer_id = (selectedTrip as SelectedTrip | undefined)?.payer_id;

  useEffect(() => {
    // Pricing V1 values that should keep the estimation from firing
    if (isPricingV1Enabled && orgSetting?.is_pricing_v1_enabled) return;

    // Field and form values where we want to skip the estimation
    if (
      [
        !selectedTrip?.canceled_at,
        formState.isDirty,
        service_details.space_type,
        trip.dropoffLocation.address.value,
        trip.pickupLocation.address.value,
        trip.timing.date,
        trip.timing.pickup_time,
        !trip.is_price_locked,
      ].some((value) => !value)
    )
      return;

    setIsCalculating(true);
    setError('');
    const baseRequest = {
      space_type: service_details.space_type,
      service_type: service_details.service_type,
      is_will_call: trip.timing.is_will_call,
      pickup_aws_place_id: trip.pickupLocation.address.value,
      dropoff_aws_place_id: trip.dropoffLocation.address.value,
      departure_datetime: convertISODateTimePartsToUTCISO({
        date: trip.timing.date,
        time: trip.timing.pickup_time,
      }),
      correlation_id: selectedTrip?.correlation_id,
      payer_id: payer || payer_id,
      number_of_legs: trips.length,
      is_round_trip: is_round_trip,
      is_bariatric: passenger.needs_bariatric_transport,
      is_oxygen_required: service_details.is_oxygen_required,
      must_provide_wheelchair: must_provide_wheelchair,
      num_stairs: trip.pickupLocation.stairs + trip.dropoffLocation.stairs,
    } as PricingModelSelectionWrite;
    createPricingModel({
      pricingModelSelection: baseRequest,
    })
      .unwrap()
      .then((response) => {
        return createPricingEstimate({
          pricing: {
            ...baseRequest,
            // id is sometimes an empty string so ensure
            // we send undefined if it's empty
            trip_id: selectedTrip?.id || undefined,
            pricing_model_id: response.pricing_model.id || '',
          },
        }).unwrap();
      })
      .then((response) => {
        setResult(response);
      })
      .catch((e) => {
        const error = formatServerError(e);
        setError(`Error calculating price estimate: ${error}`);
      })
      .finally(() => {
        setIsCalculating(false);
      });
    // We don't want this to fire when formState.isDirty changes
    // There's a better way to do this, but keeping the change small for now
    // One idea is to not have this control the triggering of the estimation
    // Instead, this hook can supply a simple function that can be called
    // in onChange events of the inputs we care about
    // It can still maintain the state of the calculation, etc.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    service_details.space_type,
    service_details.service_type,
    must_provide_wheelchair,
    trip.dropoffLocation.address.value,
    trip.pickupLocation.address.value,
    trip.timing.is_will_call,
    trip.timing.date,
    trip.timing.pickup_time,
    trip.is_price_locked,
    createPricingModel,
    selectedTrip?.correlation_id,
    createPricingEstimate,
    payer_id,
    payer,
    trips.length,
    is_round_trip,
    passenger.needs_bariatric_transport,
    service_details.is_oxygen_required,
    trip.pickupLocation.stairs,
    trip.dropoffLocation.stairs,
  ]);

  return {
    isCalculating,
    error,
    result,
  };
}
