import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { useAppSelector } from 'src/app/store';
import {
  AssignmentRead,
  useAssignmentsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { Popover } from 'src/common/primitives/Popover/Popover';

import BulkAssignTripsPopoverContent from './BulkAssignTripsPopoverContent';

export default function BulkAssignTripsPopover() {
  const [validAssignments, setValidAssignment] = useState<AssignmentRead[]>([]);

  const { selectedUnassignedTripIds, selectedDate, dispatchResponse } =
    useAppSelector((state) => state.dispatcher);

  const bod = DateTime.fromISO(selectedDate).startOf('day').toISO();
  const eod = DateTime.fromISO(selectedDate).endOf('day').toISO();

  const { data: assignments, isLoading: assignmentsLoading } =
    useAssignmentsListQuery({
      rangeStart: bod,
      rangeEnd: eod,
    });

  useEffect(() => {
    // useEffect filters out any assignments that are outside of the selected trips range
    const selectedTrips = dispatchResponse?.unassigned_trips.filter((trip) =>
      selectedUnassignedTripIds.includes(trip.id)
    );

    const selectedTripPickupTimes = selectedTrips?.map((trip) =>
      DateTime.fromISO(trip.scheduled_pickup_at)
    );

    if (!selectedTripPickupTimes) return;

    const minTripPickupTime = DateTime.min(...selectedTripPickupTimes);
    const maxTripPickupTime = DateTime.max(...selectedTripPickupTimes);

    const availableAssignments = assignments?.results.filter((assignment) => {
      const assignmentStart = DateTime.fromISO(assignment.start);
      const assignmentEnd = DateTime.fromISO(assignment.end);

      return (
        assignmentStart <= minTripPickupTime &&
        assignmentEnd >= maxTripPickupTime
      );
    });

    if (!availableAssignments) return;

    setValidAssignment(availableAssignments);
  }, [
    dispatchResponse?.unassigned_trips,
    selectedUnassignedTripIds,
    assignments,
  ]);

  return (
    <Popover
      trigger={
        <Button
          disabled={!selectedUnassignedTripIds.length}
          variant="ghost"
          dataTestId="bulk-assign-trips-popover-trigger"
        >
          <span className="text-xs"> Assign</span>
        </Button>
      }
      content={
        <BulkAssignTripsPopoverContent
          loading={assignmentsLoading}
          assignments={validAssignments}
        />
      }
    />
  );
}
