import { useEffect, useMemo, useState } from 'react';

import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { RootState } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { useExpandableTable } from 'src/common/DataGrid/useExpandableTable';
import {
  TripSubscriptionRead,
  useTripsSubscriptionsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import TripSubscriptionModal from '../trip-subscription-form/TripSubscriptionModal';
import { columns as configuredColumns } from './columns';
import { TripSubscriptionEmptyState } from './TripSubscriptionEmptyState';

export default function TripSubscriptionsManagementRoute() {
  const { tripSubscriptionSearchTerm, selectedDateRange, selectedPayers } =
    useSelector((state: RootState) => state.tripSubscription);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const [rowSelection, setRowSelection] = useState({});
  const { expanded, setExpanded, columns } =
    useExpandableTable<TripSubscriptionRead>(configuredColumns);

  const {
    data: tripSubscriptions,
    isLoading,
    refetch,
  } = useTripsSubscriptionsListQuery({
    page: pageIndex + 1,
    search: tripSubscriptionSearchTerm,
    payers: selectedPayers.join(','),
    rangeStart: selectedDateRange?.from || '',
    rangeEnd: selectedDateRange?.to || '',
    ordering: useSearchParams()[0].get('ordering') || '',
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [tripSubscriptionSearchTerm, selectedDateRange, selectedPayers, refetch]);

  return (
    <>
      {isLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <DataGrid
            tableId="trip-subscription"
            columns={columns}
            data={tripSubscriptions?.results || []}
            pagination={pagination}
            totalCount={tripSubscriptions?.count || 0}
            handlePaginationChange={handlePaginationChange}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            emptyState={<TripSubscriptionEmptyState />}
            onExpandedChange={setExpanded}
            expandedState={expanded}
            getRowId={(row) => row.id}
          />
          <TripSubscriptionModal />
        </>
      )}
    </>
  );
}
