import * as z from 'zod';

export const schema = z.object({
  id: z.string().optional(),
  nickname: z.string().nonempty('Vehicle name is required'),
  can_be_scheduled: z.boolean().optional(),
  make: z.string().optional(),
  model: z.string().optional(),
  year: z.preprocess(
    (val) => (isNaN(Number(val)) ? null : val),
    z.number().nullable()
  ),
  color: z.string().optional(),
  license_plate: z.string().optional(),
  license_plate_state: z.string().optional(),
  location: z.string().optional(),
  vin: z.string().min(17).max(17),
  type: z.string().optional(),
  notes: z.string().optional(),
  category: z.string().optional(),
  storage_location: z
    .object({
      label: z.string().min(1),
      place_id: z.string().min(1),
    })
    .required(),
});
