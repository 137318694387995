import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { ManagePaymentMethods } from 'src/common/forms/PaymentMethodCaptureForm/ManagePaymentMethods';
import { useCanCapturePaymentMethod } from 'src/common/forms/PaymentMethodCaptureForm/useCanCapturePaymentMethod';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { PhoneInput } from 'src/common/primitives/PhoneInput';
import { SelectOption } from 'src/common/primitives/Select';

import { FormValues } from './FormValues';

export function PayersFormStep2({
  onClose,
  setStep,
}: {
  onClose: () => void;
  setStep: (step: number) => void;
}) {
  const { selectedPayer } = useSelector((state: RootState) => state.payer);
  const { clearErrors, getValues } = useFormContext<FormValues>();

  const {
    isEnabled: hasPaymentCaptureFeature,
    isLoading: isLoadingPaymentCaptureFeature,
  } = useCanCapturePaymentMethod();

  return (
    <>
      <div className="flex flex-col gap-3 py-4">
        <div>
          <h2 className="text-xl">Billing Information</h2>
          <div className="py-2">
            <FormContextField name="billing_name">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Billing Contact Name"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'billing_name',
                      ...field,
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormContextField>
          </div>
          <div className="pt-2 pb-4">
            <FormContextField name="billing_address">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Billing Address"
                    type="location-typeahead"
                    inputProps={{
                      id: 'storage-location',
                    }}
                    value={field.value}
                    onChange={(option: SelectOption) => {
                      // setValue doesn't trigger revalidation for some reason
                      clearErrors('billing_address');
                      field.onChange({
                        location: { place_id: option.value },
                        label: option.label,
                      });
                    }}
                    options={
                      selectedPayer?.billing_address
                        ? [
                            {
                              label:
                                selectedPayer.billing_address.location?.label ||
                                '',
                              value:
                                selectedPayer.billing_address.location
                                  ?.place_id || '',
                            },
                          ]
                        : []
                    }
                    error={
                      field.errors?.billing_address?.place_id?.message &&
                      !field.value?.place_id
                        ? 'Please select a valid location'
                        : undefined
                    }
                  />
                );
              }}
            </FormContextField>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <FormContextField name={'billing_phone_number'}>
                {({ field, fieldState }) => {
                  return (
                    <div>
                      <DefaultFormFieldLayout
                        label="Billing Phone Number"
                        inputProps={{ id: 'billing_phone_number' }}
                      >
                        <PhoneInput
                          {...field}
                          placeholder="(555) 555-5555"
                          dataTestId="phone"
                        />
                      </DefaultFormFieldLayout>
                    </div>
                  );
                }}
              </FormContextField>
            </div>
            <div>
              <FormContextField name="billing_email">
                {({ field, fieldState }) => {
                  return (
                    <FormField
                      label="Billing Email"
                      error={fieldState.error?.message?.toString()}
                      type="email"
                      inputProps={{
                        id: 'billing_email',
                        ...field,
                      }}
                      value={field.value}
                    />
                  );
                }}
              </FormContextField>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <FormContextField name={'invoice_due_after_days'}>
            {({ field, fieldState }) => {
              return (
                <FormField
                  label="Invoice Due After (Days)"
                  error={fieldState.error?.message?.toString()}
                  type="number"
                  inputProps={{
                    id: 'invoice_due_after_days',
                    ...field,
                  }}
                  value={field.value}
                />
              );
            }}
          </FormContextField>
        </div>
      </div>
      {isLoadingPaymentCaptureFeature ? (
        <LoadingIndicator testId="loading-payment-capture" />
      ) : (
        hasPaymentCaptureFeature && (
          <div className="mt-2">
            <FormContextField name="billing_details">
              {({ field, fieldState }) => {
                return (
                  <ManagePaymentMethods
                    payerId={getValues().id}
                    payerType="payer_organization"
                    billingName={field.value?.billingName}
                    onChangeBillingDetails={field.onChange}
                  />
                );
              }}
            </FormContextField>
          </div>
        )
      )}
      <div className="mt-8 grid grid-cols-2 gap-4">
        <Button
          dataTestId="step2-cancel"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setStep(3)}
          className="w-full flex-grow"
          variant="primary"
          dataTestId="step2-next"
        >
          Next
        </Button>
      </div>
    </>
  );
}
