export const defaultCurrencyFormat = (
  amountAsCents: string | number,
  showDecimals = true
) => {
  if (amountAsCents === '') return '';

  const floatValue = parseFloat(String(amountAsCents));

  return Intl.NumberFormat('en-US', {
    currency: 'USD',
    currencyDisplay: 'symbol',
    currencySign: 'standard',
    style: 'currency',
    minimumFractionDigits: showDecimals ? 2 : 0,
    maximumFractionDigits: showDecimals ? 2 : 0,
    minimumIntegerDigits: 1,
  }).format(floatValue / 100);
};
