import { useCallback, useEffect, useMemo, useState } from 'react';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { debounce } from 'lodash-es';
import { useDispatch } from 'react-redux';

export function useDebouncedSearch(
  actionCreator: ActionCreatorWithPayload<string, string>,
  defaultValue?: string,
  debounceDelay = 300
) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const dispatch = useDispatch();

  const memoizedActionCreator = useCallback(actionCreator, [actionCreator]);

  const debouncedDispatch = useMemo(
    () =>
      debounce((value) => {
        dispatch(memoizedActionCreator(value));
      }, debounceDelay),
    [dispatch, memoizedActionCreator, debounceDelay]
  );

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debouncedDispatch(value);
  };

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return { inputValue, handleInputChange };
}
