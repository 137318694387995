import { ClockIcon } from '@radix-ui/react-icons';
import { DateTime } from 'luxon';

import { AssignmentTripRead } from 'src/common/external/bambi-api/bambiApi';

export default function AssignmentPopupEstimatedTripTiming({
  trip,
}: {
  trip: AssignmentTripRead | null;
}) {
  if (!trip?.timing?.estimated_completion_time) {
    return null;
  }

  const estimatedCompletionTime = DateTime.fromISO(
    trip.timing.estimated_completion_time
  ).toLocaleString(DateTime.DATETIME_SHORT);

  const estimatedPickupTime =
    trip.timing.estimated_pickup_arrival_time && trip.status === 'en route'
      ? DateTime.fromISO(
          trip.timing.estimated_pickup_arrival_time
        ).toLocaleString(DateTime.DATETIME_SHORT)
      : '';

  const estimatedTripTimes = [
    { label: 'Estimated Pickup', time: estimatedPickupTime },
    { label: 'Estimated Completion', time: estimatedCompletionTime },
  ];

  return (
    <>
      {estimatedTripTimes
        .filter((et) => !!et.time)
        .map(({ label, time }) => (
          <div
            key={time}
            className="flex flex-col items-start justify-center gap-[6px]"
          >
            <div className="box-border flex h-4 flex-row items-center justify-center rounded-md border-[1px] border-solid border-gray-300 bg-warning-50 py-[3px] px-2">
              <div className="flex flex-row items-center justify-start">
                <div className="relative font-medium">{label}</div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[8px] text-left text-sm text-gray-600">
              <ClockIcon />
              {time}
            </div>
          </div>
        ))}
    </>
  );
}
