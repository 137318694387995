import React from 'react';

import {
  LocationTypeahead,
  LocationTypeaheadProps,
} from '../LocationTypeahead/LocationTypeahead';
import { FormError } from './FormError';

export const FormFieldLocationTypeahead = React.forwardRef(
  function _FormFieldLocationTypeahead(
    {
      inputProps,
      label,
      error,
      helperText,
      onChange,
      value,
      placeholder,
    }: {
      inputProps?: LocationTypeaheadProps['inputProps'];
      label: React.ReactNode;
      error?: LocationTypeaheadProps['error'];
      helperText?: string;
      onChange?: LocationTypeaheadProps['onChange'];
      value?: LocationTypeaheadProps['value'];
      placeholder?: LocationTypeaheadProps['placeholder'];
    },
    forwardedRef: React.Ref<HTMLInputElement>
  ) {
    return (
      <>
        <label
          htmlFor={inputProps?.id}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">
          <LocationTypeahead
            inputProps={inputProps}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            ref={forwardedRef}
            error={error}
          />
        </div>
        {error && <FormError error={error} />}
      </>
    );
  }
);
