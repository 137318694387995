import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import store from 'src/app/store';
import { VehicleInspectionReportDetailsRead } from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

import { ExpandableVehicleCell } from './components/ExpandableVehicleCell';
import { InspectionOutcomeCell } from './components/InspectionOutcomeCell/InspectionOutcomeCell';
import { getSelectedValue } from './filters/getSelectedValue';
import { InspectionDateColumnFilter } from './filters/InspectionDateColumnFilter';
import { OutcomeColumnFilter } from './filters/OutcomeColumnFilter';
import { VehicleColumnFilter } from './filters/VehicleColumnFilter';
import { VehicleInspection } from './types';

const columnHelper = createColumnHelper<VehicleInspectionReportDetailsRead>();
const emptyInspection: VehicleInspection = [];

export const columns = [
  columnHelper.accessor('report_json', {
    header: 'Outcome',
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <OutcomeColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        const { hasFail } =
          store.getState().fleetInspection.inspectionListFilter;

        const selected = getSelectedValue(hasFail);
        return selected.length;
      },
    },
    cell: ({ getValue }) => {
      const reportValue = getValue();
      const inspection: VehicleInspection = Array.isArray(reportValue)
        ? reportValue
        : emptyInspection;

      return <InspectionOutcomeCell inspection={inspection} />;
    },
  }),

  // TODO: This should be added to the backend
  columnHelper.accessor('vehicle', {
    header: 'Vehicle',
    cell: ({ getValue, row }) => {
      const inspection: VehicleInspection = Array.isArray(
        row.original.report_json
      )
        ? row.original.report_json
        : emptyInspection;
      const shouldExpand = row.getCanExpand() && row.getIsExpanded();

      // BE type not complete -- see VehicleInspectionReportDetailsRead
      // @ts-ignore
      const vehicleName: string = getValue().nickname;
      return (
        <ExpandableVehicleCell
          vehicleName={vehicleName}
          inspection={inspection}
          shouldExpand={shouldExpand}
        />
      );
    },
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <VehicleColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        const { vehicleIn } =
          store.getState().fleetInspection.inspectionListFilter;

        return vehicleIn?.length ?? 0;
      },
    },
  }),

  columnHelper.accessor('membership', {
    header: 'Inspected By',
    meta: {
      enableHeaderMenu: false,
    },
    // BE type not complete -- see VehicleInspectionReportDetailsRead
    // @ts-ignore
    cell: ({ getValue }) => formatFullName(getValue()),
  }),

  // TODO: Need to format these
  columnHelper.accessor('created_at', {
    header: 'Date/Time',
    enableColumnFilter: true,
    meta: {
      filterComponent(column, open, onClose) {
        return <InspectionDateColumnFilter open={open} onClose={onClose} />;
      },
      filterCount() {
        const { rangeStart, rangeEnd } =
          store.getState().fleetInspection.inspectionListFilter;

        return rangeStart || rangeEnd ? 1 : 0;
      },
    },
    cell: ({ getValue }) =>
      DateTime.fromISO(getValue()).toLocaleString(DateTime.DATETIME_MED),
  }),
];
