export function formatChangeData(value?: string | null): string {
  const stringifiedValue = String(value);
  if (
    !stringifiedValue ||
    stringifiedValue === 'null' ||
    stringifiedValue === 'undefined'
  ) {
    return 'None';
  }

  return stringifiedValue;
}
