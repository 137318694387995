import AmexLogo from './logos/amex.png';
import DiscoverLogo from './logos/discover.png';
import MastercardLogo from './logos/mastercard.png';
import VisaLogo from './logos/visa.png';

export type CreditCardBrand = 'visa' | 'mastercard' | 'amex' | 'discover';

interface CreditCardProps {
  brand: CreditCardBrand;
}

export function CreditCard({ brand }: CreditCardProps) {
  const dimensions = {
    width: 48,
    height: 32,
  };

  switch (brand) {
    case 'visa':
      return <img src={VisaLogo} alt="Visa Card Logo" {...dimensions} />;
    case 'mastercard':
      return <img src={MastercardLogo} alt="Mastercard Logo" {...dimensions} />;
    case 'amex':
      return <img src={AmexLogo} alt="American Express Logo" {...dimensions} />;
    case 'discover':
      return (
        <img src={DiscoverLogo} alt="Discover Card Logo" {...dimensions} />
      );
    default:
      return null;
  }
}
