import { DateTime, Duration } from 'luxon';

import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';

import { isPickupTimeBeforeAppointmentTime } from '../isPickupTimeBeforeAppointmentTime';

// Yo dawg, I heard you like parameters...
// Probably more proper to split out the validation paths or compose normal vs ROP user paths but here we are
export function validatePickupTimeField(
  value: any,
  isRideOrderingPortalUser: boolean,
  date: string,
  appointmentTime?: string,
  facility_trip_request_lead_time_seconds?: number,
  now = DateTime.now()
): string | boolean | Promise<string | boolean> {
  if (!value) {
    return 'Trip Pickup Time is required';
  }

  const newValueDate = convertISODateTimePartsToUTCISO({
    date,
    time: value,
  });

  if (isRideOrderingPortalUser && facility_trip_request_lead_time_seconds) {
    const isPickupTimeFarEnoughInFuture =
      DateTime.fromISO(newValueDate).diff(now).as('seconds') <
      facility_trip_request_lead_time_seconds;

    if (isPickupTimeFarEnoughInFuture) {
      const duration = Duration.fromObject({
        second: facility_trip_request_lead_time_seconds,
      }).rescale();
      return `Trip pickup time must be at least ${duration.toHuman()} from now.`;
    }
  }

  return isPickupTimeBeforeAppointmentTime(value, appointmentTime);
}
