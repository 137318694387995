import { useMemo } from 'react';

import {
  ExclamationTriangleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { isString } from 'lodash-es';

import { Button, ButtonVariants } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

import { BulletList } from '../BulletList';
import { FormErrorMessage } from '../FormErrorMessage';

type ConfirmModalMode = 'destructive' | 'neutral' | 'success';

export type ConfirmModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCancel: () => void;
  cancelText?: string;
  onConfirm: () => void;
  confirmText?: string;
  title: string;
  description?: React.ReactNode;
  loading?: boolean;
  error?: string | string[];
  icon?: React.ReactNode;
  testId?: string;
  mode?: ConfirmModalMode;
  children?: React.ReactNode;
  contentClassnames?: string;
};

export function ConfirmModal({
  open,
  setOpen,
  onCancel,
  cancelText = 'Cancel',
  onConfirm,
  confirmText = 'Confirm',
  title,
  description,
  loading,
  error,
  icon,
  testId = 'confirm-modal',
  mode = 'destructive',
  children,
  contentClassnames = 'sm:max-w-[25rem]',
}: ConfirmModalProps) {
  const modeConfig = useMemo(() => ConfirmModalModeToConfigMap[mode], [mode]);

  return (
    <Modal open={open} setOpen={setOpen} contentClassnames={contentClassnames}>
      <div className="flex flex-col gap-3">
        <div className="flex justify-center" data-testid={testId}>
          <div
            className={`${modeConfig.iconContainerClasses} flex h-[48px] w-[48px] items-center justify-center rounded-full border-8`}
          >
            {icon || modeConfig.icon}
          </div>
        </div>
        <h4 className="text-center text-lg font-medium text-gray-900">
          {title}
        </h4>
        {description && (
          <p className="text-center text-sm text-gray-500">{description}</p>
        )}

        {error && (
          <div data-testid="confirm-modal-error-container">
            {isString(error) ? (
              <FormErrorMessage>{error}</FormErrorMessage>
            ) : (
              <div
                className="rounded-md border border-warning-300 bg-warning-25 py-4 pr-2 pl-6 text-warning-600"
                role="alert"
              >
                <BulletList items={error} />
              </div>
            )}
          </div>
        )}

        {children}

        <div className=" grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Button
            dataTestId="cancelBtn"
            className="sm:col-span-3"
            disabled={loading}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            className="sm:col-span-3"
            type="submit"
            variant={modeConfig.confirmButtonVariant}
            onClick={onConfirm}
            disabled={loading}
            dataTestId="confirmBtn"
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

type ConfirmModalConfig = {
  icon: React.ReactNode;
  iconContainerClasses?: string;
  confirmButtonVariant?: ButtonVariants;
};

const ConfirmModalModeToConfigMap: Record<
  ConfirmModalMode,
  ConfirmModalConfig
> = {
  destructive: {
    icon: <TrashIcon className="w-5" data-testid="confirm-modal-trash-icon" />,
    iconContainerClasses: 'bg-error-100 text-error-700 border-error-50',
    confirmButtonVariant: 'primary-danger',
  },
  neutral: {
    icon: (
      <ExclamationTriangleIcon
        className="w-5"
        data-testid="confirm-modal-neutral-icon"
      />
    ),
    iconContainerClasses: 'bg-amber-100 text-yellow-900 border-warning-50',
    confirmButtonVariant: 'primary',
  },
  success: {
    icon: (
      <CheckIcon className="w-5" data-testid="confirm-modal-success-icon" />
    ),
    iconContainerClasses: 'bg-success-50 text-mint border-success-200',
    confirmButtonVariant: 'primary',
  },
};
