export const StretcherIcon = ({ ...props }) => {
  return (
    <svg
      className="relative overflow-visible"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.38932 2.83341H0.832031V2.00008V1.16675H2.4987V2.00008V2.50008H2.9987H13.1654V2.83341H10.6081H10.401L10.2545 2.97986L7.58785 5.64653L7.2343 6.00008L7.58785 6.35363L8.54619 7.31197L8.81034 7.57612L9.13855 7.3977C9.40709 7.25171 9.69616 7.16675 9.9987 7.16675C11.0033 7.16675 11.832 7.99546 11.832 9.00008C11.832 10.0047 11.0033 10.8334 9.9987 10.8334C8.99407 10.8334 8.16536 10.0047 8.16536 9.00008C8.16536 8.69754 8.25032 8.40848 8.39631 8.13993L8.57474 7.81172L8.31058 7.54757L7.35225 6.58924L6.9987 6.23568L6.64514 6.58924L5.68681 7.54757L5.42266 7.81172L5.60108 8.13993C5.74707 8.40848 5.83203 8.69754 5.83203 9.00008C5.83203 10.0047 5.00332 10.8334 3.9987 10.8334C2.99407 10.8334 2.16536 10.0047 2.16536 9.00008C2.16536 7.99546 2.99407 7.16675 3.9987 7.16675C4.30124 7.16675 4.5903 7.25171 4.85885 7.3977L5.18705 7.57612L5.45121 7.31197L6.40954 6.35363L6.7631 6.00008L6.40954 5.64653L3.74288 2.97986L3.59643 2.83341H3.38932ZM5.27474 2.83341H4.06763L4.92119 3.68697L6.64514 5.41093L6.9987 5.76448L7.35225 5.41093L9.07621 3.68697L9.92976 2.83341H8.72266H5.27474ZM5.4987 9.00008C5.4987 8.16376 4.83502 7.50008 3.9987 7.50008C3.16237 7.50008 2.4987 8.16376 2.4987 9.00008C2.4987 9.83641 3.16237 10.5001 3.9987 10.5001C4.83502 10.5001 5.4987 9.83641 5.4987 9.00008ZM11.4987 9.00008C11.4987 8.16376 10.835 7.50008 9.9987 7.50008C9.16237 7.50008 8.4987 8.16376 8.4987 9.00008C8.4987 9.83641 9.16237 10.5001 9.9987 10.5001C10.835 10.5001 11.4987 9.83641 11.4987 9.00008Z"
        fill="#333333"
        stroke="#333333"
      />
    </svg>
  );
};
