import { DateTime } from 'luxon';

import { DaySelector } from './DaySelector';
import { generateWeekDays } from './generateWeekDays';

export function WeekDisplay({
  selectedDay,
  onDayClick,
}: {
  selectedDay: DateTime;
  onDayClick: (date: DateTime) => void;
}) {
  const days = generateWeekDays(selectedDay);
  return (
    <div className="flex grow justify-around border-b-2">
      {days.map((day) => {
        const formattedDay = day.toFormat('yyyy/MM/dd');
        const isActive = formattedDay === selectedDay.toFormat('yyyy/MM/dd');
        return (
          <DaySelector
            key={formattedDay}
            date={day}
            active={isActive}
            onClick={onDayClick}
          />
        );
      })}
    </div>
  );
}
