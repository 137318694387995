import { useFeature } from 'src/app/FeatureToggle';

export function useIsPassengerMergeEnabledForOrganization(): boolean {
  const { isEnabled, isLoading, error } = useFeature('passenger merge');

  if (error) {
    return true;
  }

  return isLoading || isEnabled;
}
