import { useRef } from 'react';

import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';

import { AssignmentHeader } from './AssignmentHeader';
import { VehicleHeader } from './VehicleHeader';

export function DispatchCalendarAssignment({
  assignment,
}: {
  assignment: DispatchAssignmentRead;
}) {
  const ASSIGNMENT_COLUMN_WIDTH_SMALL = 200;
  const assignmentHeaderRef = useRef<any>();
  const assignmentHeaderWidth = assignmentHeaderRef.current?.clientWidth;

  const isWidthSmall =
    assignmentHeaderWidth &&
    assignmentHeaderWidth <= ASSIGNMENT_COLUMN_WIDTH_SMALL;

  return (
    <div className="flex flex-col gap-2 text-left" ref={assignmentHeaderRef}>
      <VehicleHeader
        type={assignment.vehicle.category}
        name={assignment.vehicle.nickname}
        isWidthSmall={isWidthSmall}
      />
      <AssignmentHeader assignment={assignment} isWidthSmall={isWidthSmall} />
    </div>
  );
}
