import { LockClosedIcon, PencilIcon } from '@heroicons/react/24/solid';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';

import { formatSimpleTime } from './formatSimpleTime';
import { isDispatchTrip } from './isDispatchTrip';

export function TripTitle({
  trip,
}: {
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
}) {
  return (
    <div className="flex gap-1">
      <div className="break-word text-xs font-bold">
        {trip.passenger.full_name}
      </div>
      <div className="flex min-w-[100px] text-[.63rem]">
        {formatSimpleTime(trip.scheduled_pickup_at)} -{' '}
        {formatSimpleTime(trip.scheduled_dropoff_at)}
        {trip.appointment_at && ` (${formatSimpleTime(trip.appointment_at)})`}
      </div>
      {isDispatchTrip(trip) && trip.missing_any_required_signature && (
        <SimpleTooltip
          content="Driver missed collecting signature and needs to be alerted"
          trigger={
            <PencilIcon
              className="h-4 w-4 text-red-600"
              data-testid={`event-missed-signature-icon-${trip.id}`}
            />
          }
        />
      )}

      {trip.is_locked && <LockClosedIcon className="h-4 w-4" />}
    </div>
  );
}
