import { Recurrence } from 'src/features/trip/trip-subscriptions/trip-subscription-form/FormValues';

export const createDefaultRecurrence = (day: string): Recurrence => {
  return {
    day_of_the_week: day,
    pickup_time: '',
    appointment_time: '',
    is_round_trip: false,
    is_will_call: false,
    return_time: '',
    input_price_cents: 0,
    return_input_price_cents: null,
  };
};
