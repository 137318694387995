import React, { ReactNode } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronRightIcon } from '@radix-ui/react-icons';

import { DropdownMenuSubContent } from './DropdownMenuContent';
import { DropdownMenuSubTrigger } from './DropdownMenuItem';
import { RightSlot } from './RightSlot';

export function DropdownSubmenu({
  triggerText,
  children,
}: {
  triggerText: string;
  children: ReactNode;
}) {
  return (
    <DropdownMenu.Sub>
      <DropdownMenuSubTrigger>
        {triggerText}
        <RightSlot>
          <ChevronRightIcon />
        </RightSlot>
      </DropdownMenuSubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenuSubContent sideOffset={2} alignOffset={-5}>
          {children}
        </DropdownMenuSubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
}
