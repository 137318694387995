import { useState } from 'react';

import {
  TilledPaymentMethodResponse,
  useTilledPaymentMethodRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { PaymentMethodList } from 'src/common/primitives/PaymentMethod/PaymentMethodList';
import { SectionHeading } from 'src/common/primitives/Section/SectionHeading';

import { usePaymentMethodCaptureContext } from './PaymentMethodCaptureContext';
import {
  PaymentMethodCaptureForm,
  PaymentMethodCaptureFormProps,
} from './PaymentMethodCaptureForm';
import { useCanCapturePaymentMethod } from './useCanCapturePaymentMethod';

interface ManagePaymentMethodsProps {
  payerId: string;
  payerType: 'passenger' | 'payer_organization';
  billingName?: string;
  selectedPaymentMethodId?: string;
  onChangeBillingDetails?: PaymentMethodCaptureFormProps['onChange'];
  onRemovePaymentMethod?: (id: string) => void;
  onSelectPaymentMethod?: (id: string) => void;
}

export function ManagePaymentMethods({
  payerId,
  payerType,
  billingName,
  selectedPaymentMethodId,
  onChangeBillingDetails,
  onRemovePaymentMethod,
  onSelectPaymentMethod,
}: ManagePaymentMethodsProps) {
  let paymentMethodListItems: TilledPaymentMethodResponse[] = [];
  const { resetFieldState } = usePaymentMethodCaptureContext();
  const { onlyInvoicingEnabled } = useCanCapturePaymentMethod();

  const {
    isLoading,
    data: paymentMethodListResponse,
    isError,
  } = useTilledPaymentMethodRetrieveQuery(
    {
      payerId,
      payerType,
    },
    { refetchOnMountOrArgChange: true, skip: !payerId }
  );

  if (paymentMethodListResponse && !isError) {
    paymentMethodListItems = paymentMethodListResponse.items;
  } else {
    paymentMethodListItems = [];
  }

  const [showAddNewPaymentMethod, setShowAddNewPaymentMethod] = useState(false);

  if (onlyInvoicingEnabled) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <SectionHeading
        heading="Payment Methods"
        subHeading="Manage payment methods"
      />

      {isLoading ? (
        <LoadingIndicator testId="loading-payment-method-list" />
      ) : (
        <PaymentMethodList
          paymentMethods={paymentMethodListItems ?? []}
          selectedPaymentMethodId={selectedPaymentMethodId}
          onRemovePaymentMethod={onRemovePaymentMethod}
          onSelectPaymentMethod={onSelectPaymentMethod}
        />
      )}
      <FormField
        label="Add new credit card"
        type="switch"
        inputProps={{
          id: 'add-new-credit-card',
          checked: showAddNewPaymentMethod,
          onChange: (value) => {
            setShowAddNewPaymentMethod(value as unknown as boolean);
            if (!value) {
              resetFieldState();
            }
          },
        }}
        value={showAddNewPaymentMethod}
      />
      {showAddNewPaymentMethod ? (
        <PaymentMethodCaptureForm
          type="card"
          billingName={billingName}
          onChange={onChangeBillingDetails}
        />
      ) : null}
    </div>
  );
}
