import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';
import SelectedPricingModelConditionText from './SelectedPricingModelConditionText';
import { SelectedPricingModelDetailsHeader } from './SelectedPricingModelDetailsHeader';
import { SelectedPricingModelDetailsText } from './SelectedPricingModelDetailsText';

export function SelectedPricingModelDetailsViewMode() {
  const pricingModel = useSelectedPricingModel();
  return (
    <>
      <SelectedPricingModelDetailsHeader
        name={pricingModel?.name || 'No name provided'}
      />
      {pricingModel?.details && (
        <SelectedPricingModelDetailsText details={pricingModel.details} />
      )}
      <SelectedPricingModelConditionText
        condition={pricingModel?.pricing_condition}
      />
    </>
  );
}
