import { cloneDeep, isNil } from 'lodash-es';

import { AttendantRead } from 'src/common/external/bambi-api/bambiApi';

import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';

function resolveSkillInitialFormValue(defaultValue: boolean, value?: boolean) {
  return isNil(value) ? defaultValue : value;
}

export function assignDefaultFormValues(
  attendant: AttendantRead | null
): FormValues {
  const attendantClone = cloneDeep(attendant);
  return {
    id: attendantClone?.id ?? '',
    membership: attendantClone?.membership || defaultFormValues.membership,
    first_name: attendantClone?.first_name || defaultFormValues.first_name,
    last_name: attendantClone?.last_name || defaultFormValues.last_name,
    can_be_scheduled:
      attendantClone?.can_be_scheduled ?? defaultFormValues.can_be_scheduled,
    can_do_wheelchair_service: resolveSkillInitialFormValue(
      defaultFormValues.can_do_wheelchair_service,
      attendantClone?.skills.can_do_wheelchair_service
    ),
    can_do_stretcher_service: resolveSkillInitialFormValue(
      defaultFormValues.can_do_stretcher_service,
      attendantClone?.skills.can_do_stretcher_service
    ),
    can_do_ambulatory_service: resolveSkillInitialFormValue(
      defaultFormValues.can_do_ambulatory_service,
      attendantClone?.skills.can_do_ambulatory_service
    ),
    can_do_curb_to_curb: resolveSkillInitialFormValue(
      defaultFormValues.can_do_curb_to_curb,
      attendantClone?.skills.can_do_curb_to_curb
    ),
    can_do_door_to_door: resolveSkillInitialFormValue(
      defaultFormValues.can_do_door_to_door,
      attendantClone?.skills.can_do_door_to_door
    ),
    can_do_door_through_door: resolveSkillInitialFormValue(
      defaultFormValues.can_do_door_through_door,
      attendantClone?.skills.can_do_door_through_door
    ),
    can_do_bariatric: resolveSkillInitialFormValue(
      defaultFormValues.can_do_bariatric,
      attendantClone?.skills.can_do_bariatric
    ),
    can_do_stairs: resolveSkillInitialFormValue(
      defaultFormValues.can_do_stairs,
      attendantClone?.skills.can_do_stairs
    ),
    can_do_wheelchair_xl_service: resolveSkillInitialFormValue(
      defaultFormValues.can_do_wheelchair_xl_service,
      attendantClone?.skills.can_do_wheelchair_xl_service
    ),
    can_do_broda_chair_service: resolveSkillInitialFormValue(
      defaultFormValues.can_do_broda_chair_service,
      attendantClone?.skills.can_do_broda_chair_service
    ),
    can_do_geri_chair_service: resolveSkillInitialFormValue(
      defaultFormValues.can_do_geri_chair_service,
      attendantClone?.skills.can_do_geri_chair_service
    ),
  };
}
