import { RootState, useAppSelector, useAppDispatch } from 'src/app/store';
import { DeleteModal } from 'src/common/DeleteModal';
import { useAttendantsListQuery } from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import {
  setShowAttendantProfileModal,
  setShowAttendantDeleteModal,
} from 'src/features/team/team.slice';

import { generateDeleteSuccessToast } from './generateDeleteSuccessToast';
import { useAttendantProfileRepository } from './useAttendantProfileRepository';

export function DeleteAttendantModal() {
  const isConfirmingDelete = useAppSelector(
    (state: RootState) => state.team.showAttendantDeleteModal
  );
  const dispatch = useAppDispatch();
  const { destroyAttendant } = useAttendantProfileRepository();
  const selectedAttendantProfile = useAppSelector(
    (state: RootState) => state.team.selectedAttendantProfile
  );
  const nextToastId = useAppSelector((state: RootState) => state.toast.nextId);
  const attendantProfileSearchTerm = useAppSelector(
    (state: RootState) => state.team.attendantProfileSearchTerm
  );
  const { refetch: refetchAttendants } = useAttendantsListQuery({
    'Organization-ID': '1',
    search: attendantProfileSearchTerm,
  });
  return (
    <DeleteModal
      open={isConfirmingDelete}
      setOpen={(isOpen) => dispatch(setShowAttendantDeleteModal(isOpen))}
      onCancel={() => {
        dispatch(setShowAttendantProfileModal(false));
        dispatch(setShowAttendantDeleteModal(false));
      }}
      onConfirm={async () => {
        try {
          if (selectedAttendantProfile?.id) {
            await destroyAttendant(selectedAttendantProfile);
            dispatch(show(generateDeleteSuccessToast(nextToastId)));
            dispatch(setShowAttendantDeleteModal(false));
          }
        } catch (error) {
          console.error(error);
        } finally {
          refetchAttendants();
        }
      }}
      title="Delete this attendant?"
      description="Are you sure you want to delete this attendant? This action cannot be undone."
    />
  );
}
