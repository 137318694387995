import { useContext } from 'react';

import { Disclosure } from '@headlessui/react';
import { matchPath, useLocation } from 'react-router-dom';

import { classNames } from 'src/common/classNames';

import { TopLevelNavItemContext } from './TopLevelNavItemContext';

export function MobileNav({ open }: { open: boolean }) {
  const navigation = useContext(TopLevelNavItemContext);
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      <Disclosure.Panel className="w-full bg-white lg:hidden">
        <div className="space-y-1 px-2 pt-2 pb-3">
          {navigation.map((item) => (
            <Disclosure.Button
              key={item.name}
              as="a"
              href={item.href}
              className={classNames(
                matchPath({ path: item.href, end: false }, currentPath)
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-500 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium'
              )}
              aria-current={
                matchPath({ path: item.href, end: false }, currentPath)
                  ? 'page'
                  : undefined
              }
            >
              {item.name}
            </Disclosure.Button>
          ))}
        </div>
      </Disclosure.Panel>
    </>
  );
}
