import { useFormContext } from 'react-hook-form';

import { defaultFormValues } from '../defaultFormValues';
import { FormValues, Trip } from '../FormValues';

export function useWaitAndReturn() {
  const { getValues, setValue } = useFormContext<FormValues>();

  return () => {
    const formData = getValues();
    const { trips } = formData;

    if (trips.length > 2) {
      throw new Error(
        'Wait and return is not supported for more than 2 trips currently'
      );
    }
    if (
      trips.some(
        (trip) =>
          trip.is_wait_and_return_inbound || trip.is_wait_and_return_outbound
      )
    )
      return;

    const firstLeg = { ...trips[0], is_wait_and_return_outbound: true };
    const returnLeg: Trip = {
      ...defaultFormValues.trips[0],
      pickupLocation: {
        ...firstLeg.dropoffLocation,
      },
      dropoffLocation: {
        ...firstLeg.pickupLocation,
      },
      timing: {
        date: firstLeg.timing.date,
        appointment_time: '',
        pickup_time: '',
        is_will_call: false,
      },
      dispatcher_notes: firstLeg.dispatcher_notes,
      is_wait_and_return_outbound: false,
      is_wait_and_return_inbound: true,
    };
    setValue('trips', [firstLeg, returnLeg]);
  };
}
