import { TilledLogo } from 'src/common/primitives/logos/TilledLogo';

export function DisabledTilledMerchantApplication() {
  return (
    <>
      <div
        data-testid="disabled-tilled-merchant-application"
        className="flex flex-grow flex-row items-center gap-2"
      >
        <TilledLogo />
        <div>
          Your account has been disabled by Tilled, please contact them for more
          information.
        </div>
      </div>
    </>
  );
}
