import { uniqBy } from 'lodash-es';

import { Recurrence } from 'src/features/trip/trip-subscriptions/trip-subscription-form/FormValues';

import { createDefaultRecurrence } from './createDefaultRecurrence';
import { sortRecurrences } from './sortRecurrences';

export function addSelectedDay(day: string, recurrences: Recurrence[]) {
  if (recurrences.some((recurrence) => recurrence.day_of_the_week === day)) {
    return recurrences;
  }

  const newRecurrence = createDefaultRecurrence(day);
  const newRecurrences = [...recurrences, newRecurrence];
  return sortRecurrences(uniqBy(newRecurrences, 'day_of_the_week'));
}
