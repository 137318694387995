import { RootState, useAppSelector } from 'src/app/store';

import { TripCard } from '../../trips/TripCard/TripCard';

export function ReassignmentList() {
  const trip_reassignments = useAppSelector(
    (state: RootState) => state.dispatcher.runBambiRunResult?.trip_reassignments
  );
  return (
    <div
      data-testid="reassignments-tab"
      className="max-h-[60vh] flex-grow overflow-y-auto"
    >
      {trip_reassignments?.length ? (
        <ul className="flex flex-col gap-y-3">
          {trip_reassignments.map((reassignment) => (
            <li key={reassignment.id}>
              <TripCard trip={reassignment} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="flex h-full items-center justify-center text-center text-gray-500">
          There were no trip reassignments
        </div>
      )}
    </div>
  );
}
