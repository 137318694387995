import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { styled } from '@stitches/react';

import { slideIn, slideOut, swipeOut } from './animations';
import { IToastConfig } from './IToastConfig';

const StyledToast = styled(ToastPrimitive.Root, {
  display: 'grid',
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: 'auto max-content',
  columnGap: 15,
  alignItems: 'center',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${slideOut} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
});

export function Toast({
  type,
  title,
  description,
  onClose = () => {},
}: IToastConfig & {
  onClose: () => void;
}) {
  return (
    <StyledToast asChild onOpenChange={(isOpen) => !isOpen && onClose()}>
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {!type ||
                (type === 'success' && (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                ))}
              {type === 'error' && (
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-error-500"
                  aria-hidden="true"
                />
              )}
              {type === 'warning' && (
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-warning-300"
                  aria-hidden="true"
                />
              )}
              {type === 'info' && (
                <InformationCircleIcon
                  className="h-6 w-6 text-sky-600"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title ? (
                <p className="text-m font-medium text-gray-900">{title}</p>
              ) : null}
              {description ? (
                <p className="mt-1 text-xs text-gray-500">{description}</p>
              ) : null}
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="focus:ring-indigo-500 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-mint"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </StyledToast>
  );
}
