export const getStorageValue = <T>(
  key: string,
  defaultValue: T,
  parse?: boolean
): T => {
  const saved = localStorage.getItem(key);
  try {
    const initial = parse ? JSON.parse(saved || '') : saved;
    return (initial || defaultValue) as T;
  } catch (e) {
    // TODO: Mock localStorage for all tests by default so that
    // this can call console.warn instead of being empty
  }

  return defaultValue;
};
