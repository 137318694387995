import { useAppSelector } from 'src/app/store';

import { useTripFormPriceEstimateResult } from './useTripFormPriceEstimateResult';

export function TripPriceEstimateResult() {
  const formatted = useTripFormPriceEstimateResult();
  const tripLegsPriceEstimationState = useAppSelector(
    (state) => state.addATrip.tripLegsPriceEstimationState
  );
  const isRevenueEstimateStale = tripLegsPriceEstimationState.some(
    (legState) => legState.isStale
  );
  return (
    <div className="flex flex-col" data-testid="trip-price-estimate">
      <span className="whitespace-nowrap text-xs font-bold uppercase">
        Estimated Revenue {isRevenueEstimateStale ? '(Stale)' : ''}
      </span>
      <span className="text-3xl font-bold">{formatted}</span>
    </div>
  );
}
