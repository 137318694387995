import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';
import { ConfirmWillCallActivationModal } from 'src/features/dispatch/modals/ConfirmWillCallActivationModal';

import { TripIndexRouteActions } from '../TripIndexRouteActions';
import TripIndexPreContent from './TripIndexPreContent';
import { TripManagementRouteContent } from './TripManagementRouteContent';

export function TripManagementRoute() {
  return (
    <>
      <ToolbarAndContentLayout
        preContent={<TripIndexPreContent />}
        actionSection={<TripIndexRouteActions />}
        content={<TripManagementRouteContent />}
      />
      {/* TODO: These modals and useTripMenuActions for trip actions probably belong more in the trips module
          Or wherever... but these should be actions we can fire on any route, so resolving to TripRead 
          makes sense
      */}
      <ConfirmWillCallActivationModal />
    </>
  );
}
