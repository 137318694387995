import { Badge } from 'src/common/primitives/Badge';

import HourGlassIcon from './HourGlassIcon';

export function EventWaitTime() {
  return (
    <>
      <style>{`
                .wait-container { 
                    background-color: #FFF;
                    opacity: 1;
                    background: repeating-linear-gradient( -45deg, #E1E4E6, #E1E4E6 6px, #FFF 6px, #FFF 16px );
                }
        `}</style>
      <div className="wait-container absolute top-0 left-0 right-0 bottom-0 flex shrink-0 flex-row items-start justify-start gap-0 self-stretch overflow-hidden pt-0 pr-0.5 pb-0 pl-0">
        <div className="relative w-[3px] shrink-0 self-stretch bg-[#E1E4E6]"></div>
        <div className="relative flex flex-1 flex-col items-start justify-start gap-1 rounded-[0px_4px_4px_4px] p-2">
          <div className="relative flex shrink-0 flex-col items-start justify-start gap-1 self-stretch">
            <div className="relative flex shrink-0 flex-col items-start justify-start gap-0 self-stretch">
              <div className="relative flex items-center  justify-start text-left font-bold shadow-sm">
                <Badge label="Wait" color="gray" icon={<HourGlassIcon />} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
