import { Duration } from 'luxon';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import {
  formatHereMapsAddress,
  HereMapsAddressLink,
} from 'src/common/HereMapsAddressLink';
import { Badge } from 'src/common/primitives/Badge';
import { Skeleton } from 'src/common/Skeleton';

import { formatSimpleTime } from '../formatSimpleTime';
import { PhoneNumberLink } from '../PhoneNumberLink';

interface TripDetailsPickupProps {
  isTripDataLoading: boolean;
  tripData?: TripRead;
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead;
}

export function TripDetailsPickup({
  isTripDataLoading,
  trip,
  tripData,
}: TripDetailsPickupProps) {
  return (
    <div className="flex flex-col gap-1 py-2">
      <h4 className="text-sm font-bold">
        Pickup - {formatSimpleTime(trip.scheduled_pickup_at)}
        {tripData?.pickup_wait_time_seconds &&
          tripData.pickup_wait_time_seconds > 0 && (
            <Badge
              color="yellow"
              label={Duration.fromObject({
                seconds: tripData.pickup_wait_time_seconds,
              }).toFormat("m'm'")}
            />
          )}
      </h4>
      <div>
        <HereMapsAddressLink
          className="text-sm font-medium text-gray-500 hover:underline"
          addresses={[
            {
              latitude: trip.pickup_latitude,
              longitude: trip.pickup_longitude,
            },
            ...(trip.dropoff_latitude
              ? [
                  {
                    latitude: trip.dropoff_latitude,
                    longitude: trip.dropoff_longitude,
                  },
                ]
              : []),
          ]}
        >
          {formatHereMapsAddress(trip.pickup_address)}
        </HereMapsAddressLink>
      </div>
      {isTripDataLoading ? (
        <Skeleton />
      ) : tripData?.pickup?.contact_name ||
        tripData?.pickup?.contact_phone_number ? (
        <div className="text-sm">
          <h5 className="text-sm font-medium text-gray-500">Contact</h5>
          <p>
            {tripData.pickup.contact_name}
            <PhoneNumberLink
              phoneNumber={tripData.pickup.contact_phone_number}
            />
          </p>
        </div>
      ) : null}
    </div>
  );
}
