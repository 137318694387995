import { PricingRule } from 'src/common/external/bambi-api/bambiApi';

// TODO: This is just to stub out the component
// more discussion/work is needed before we implement it
export default function PricingRuleMatchConditions({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  return (
    <div>
      Placeholder for match conditions for pricing rule {pricingRule.id}
    </div>
  );
}
