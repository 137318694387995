import { useTripsRejectCodesRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';

export function useRejectionReasonOptions(tripId: string) {
  const { data, isFetching, isLoading } = useTripsRejectCodesRetrieveQuery(
    {
      id: tripId,
    },
    {
      skip: !tripId,
    }
  );

  if (isFetching || isLoading) {
    return [];
  }

  return Object.entries(data?.rejection_reasons || {}).map(([key, value]) => ({
    label: key,
    value: value,
  }));
}
