import { useEffect, useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';
import { useClientSideSearch } from 'src/common/util/useClientSideSearch';

export function useSearchedUnassignedTripsFromDispatchResponse(): DispatchTripRead[] {
  const { dispatchResponse, unassignedTripsSearchTerm } = useAppSelector(
    (state) => state.dispatcher
  );

  const initialData = useMemo(
    () => dispatchResponse?.unassigned_trips || [],
    [dispatchResponse?.unassigned_trips]
  );

  const { data: searchedUnassignedTrips, handleSearchTermChange } =
    useClientSideSearch<DispatchTripRead>(initialData);

  useEffect(() => {
    handleSearchTermChange(unassignedTripsSearchTerm);
  }, [unassignedTripsSearchTerm, handleSearchTermChange]);

  return searchedUnassignedTrips;
}
