// Things that TripEvent covers should go here except for things like pickup time
// Pickup time can be updated in the trip form and doesn't generate a TripEvent through
// that path
export const updateValuesKeysToSkip = [
  'assignment_id',
  'id',
  'created_at',
  'updated_at',
  'updated_by',
  'previous_status',
  'time_range',
  'passenger_id',
];
