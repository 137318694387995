import { useAppDispatch } from 'src/app/store';

import { onRejectReasonChange } from '../../../dispatcher.slice';
import { RejectionReasonSelect } from './RejectionReasonSelect';

export function RejectionReasonSelectionCell({ tripId }: { tripId: string }) {
  const dispatch = useAppDispatch();
  return (
    <RejectionReasonSelect
      tripId={tripId}
      onChange={(selected) => {
        dispatch(
          onRejectReasonChange({
            tripId,
            reason: selected?.value || null,
          })
        );
      }}
    />
  );
}
