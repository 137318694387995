import { forwardRef } from 'react';

interface TagColumnWrapperProps {
  children: React.ReactNode;
  testId?: string;
}

export const TagColumnWrapper = forwardRef<
  HTMLDivElement,
  TagColumnWrapperProps
>(({ children, testId, ...rest }, ref) => {
  return (
    <div
      style={{ width: 'fit-content' }}
      data-testid={testId}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});
