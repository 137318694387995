import { useAppDispatch } from 'src/app/store';
import { Invoice, InvoiceRead } from 'src/common/external/bambi-api/bambiApi';

import {
  editInvoice,
  resetEditing,
  setInvoiceModalOpen,
} from '../invoice.slice';

export function useCreateEmptyInvoice() {
  const dispatch = useAppDispatch();

  return (invoice?: Partial<Invoice>) => {
    dispatch(resetEditing());
    dispatch(editInvoice((invoice as InvoiceRead) ?? null));
    dispatch(setInvoiceModalOpen(true));
  };
}
