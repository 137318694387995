import { DateTime } from 'luxon';

export function SelectedDateDisplay({ date }: { date: DateTime }) {
  const dateName = date.toFormat('cccc');
  const dateLong = date.toFormat('MMMM dd');
  return (
    <div className="pl-2">
      <div className="text-[14px] text-gray-500">{dateName}</div>
      <div className="text-[16px] font-bold text-gray-500">{dateLong}</div>
    </div>
  );
}
