import { isNumber } from 'lodash-es';

export interface ErrorWithStatus {
  status: number;
}

export interface ErrorWithOriginalStatus {
  originalStatus: number;
}

// TODO: Refine this to closer to a resolved server error
export interface ServerErrorLike {
  data: any;
}

// This might be too overloaded as it tries to resolve a numeric status from
// rtk-query error as well as resolved Django errors
export function resolveErrorStatus(
  error: ErrorWithStatus | ErrorWithOriginalStatus | ServerErrorLike
) {
  if ('status' in error && isNumber(error.status)) {
    return error.status;
  }

  if ('originalStatus' in error && isNumber(error.originalStatus)) {
    return error.originalStatus;
  }

  return 500;
}
