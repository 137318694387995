import { useEffect, useMemo, useState } from 'react';

import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';

import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { useExpandableTable } from 'src/common/DataGrid/useExpandableTable';
import {
  useVehiclesInspectionsListQuery,
  VehicleInspectionReportDetailsRead,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { columns as configuredColumns } from './columns';

export function VehicleInspectionsRoute() {
  // TODO: This pagination stuff can be abstracted away into a hook
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const { expanded, setExpanded, columns } =
    useExpandableTable<VehicleInspectionReportDetailsRead>(configuredColumns);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const inspectionListFilter = useAppSelector(
    (state) => state.fleetInspection.inspectionListFilter
  );
  const {
    data: vehicles,
    isLoading,
    isFetching,
    refetch,
  } = useVehiclesInspectionsListQuery({
    ...inspectionListFilter,
    // Defer page to the pagination state
    page: pageIndex + 1,
  });

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [refetch, inspectionListFilter]);

  return (
    <>
      {isLoading || isFetching ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <DataGrid
          tableId="vehicle-inspection"
          // TODO: z-index fun to keep report details under this header
          // but keep this table under the nav bar and profile menu
          containerClassName="isolate"
          headerClassName="z-10"
          columns={columns}
          data={vehicles?.results || []}
          pagination={pagination}
          totalCount={vehicles?.count || 0}
          handlePaginationChange={handlePaginationChange}
          emptyState={
            <EmptyState
              Icon={
                <ClipboardDocumentListIcon className="mx-auto h-6 w-auto text-gray-700" />
              }
              entityName="Vehicle Inspections"
              description="These will appear as drivers submit their completed inspections."
            />
          }
          onExpandedChange={setExpanded}
          expandedState={expanded}
          getRowId={(row) => row.id}
        />
      )}
    </>
  );
}
