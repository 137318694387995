import { PricingRule } from 'src/common/external/bambi-api/bambiApi';
import { useSelectedPricingType } from 'src/features/pricingModel/management/hooks/useSelectedPricingType';
import { PricingTypeAdditionalBaseBadge } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingTypeAdditionalBaseBadge';
import { PricingTypeBaseRateBadge } from 'src/features/pricingModel/management/SelectedPricingModelDetails/PricingTypeBaseRateBadge';

import { PricingRuleAccordionHeaderCard } from './PricingRuleAccordionHeaderCard';
import { PricingRuleEditButton } from './PricingRuleEditButton';

export function PricingRuleAccordionHeaderView({
  pricingRule,
}: {
  pricingRule: PricingRule;
}) {
  const pricingType = useSelectedPricingType();
  if (!pricingType) {
    return (
      <div>No pricing type found. This should never happen in practice.</div>
    );
  }

  return (
    <PricingRuleAccordionHeaderCard>
      <div className="text-md text-gray-900">{pricingRule.name}</div>
      <div className="flex items-center gap-x-3">
        <PricingTypeBaseRateBadge pricingType={pricingType} />
        <PricingTypeAdditionalBaseBadge
          pricingType={pricingType}
          pricingRule={pricingRule}
        />
      </div>
      <div className="text-xs text-gray-500">
        {pricingRule.text_description || ' '}
      </div>
      <div
        className="flex flex-grow justify-end"
        onClick={(e) => {
          // Let's prevent the accordion from toggling when taking actions
          e.stopPropagation();
        }}
      >
        <PricingRuleEditButton pricingRule={pricingRule} />
      </div>
    </PricingRuleAccordionHeaderCard>
  );
}
