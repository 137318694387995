import { RouteObject } from 'react-router-dom';

import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';

import { PricingManagementRoute } from './management/PricingManagementRoute';

export const pricingRoutes: RouteObject[] = [
  {
    index: true,
    element: <PricingManagementRoute />,
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/pricing/" linkText="Go back to pricing" />
    ),
  },
];
