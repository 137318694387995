import { TilledLogo } from 'src/common/primitives/logos/TilledLogo';

export function ActiveTilledMerchantApplication() {
  return (
    <>
      <div className="flex flex-grow flex-row items-center gap-2">
        <TilledLogo />
        <div>
          Your merchant account is active, and you are ready to start accepting
          payments!
        </div>
      </div>
    </>
  );
}
