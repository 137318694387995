import { useEffect, useState } from 'react';

import { ClockIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { Combobox } from 'src/common/primitives/ComboBox';
import { StyledOption } from 'src/common/primitives/ComboBox/StyledOption';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { useIsRideOrderingPortalEnabledForOrganization } from 'src/features/rideOrderingPortal/useIsRideOrderingPortalEnabledForOrganization';

import { addATrip } from '../addATrip.slice';
import { useIsEditingTrip } from '../useIsEditingTrip';
import { BrokerPayerFields } from './BrokerPayerFields';
import { InsurancePayerFields } from './InsurancePayerFields';
import { PassengerPayerLinkSwitch } from './PassengerPayerLinkSwitch';
import { pricingNameRoot } from './pricingNameRoot';
import { PrivatePayerFields } from './PrivatePayerFields';
import { usePayers } from './usePayers';

export const payerCategories = {
  broker: 'broker',
  private: 'private',
  insurance: 'insurance',
};

export function PricingFormBody({
  showPayerLinkSwitch = true,
}: {
  showPayerLinkSwitch?: boolean;
}) {
  const { watch } = useFormContext();
  const [payerCategory, setPayerCategory] = useState<string | undefined>();
  const isRideOrderingPortalEnabledForOrganization =
    useIsRideOrderingPortalEnabledForOrganization();
  const { isRideOrderingPortalUser } = useUserRoles();
  const shouldShowPayerLinkSwitch =
    isRideOrderingPortalEnabledForOrganization &&
    !isRideOrderingPortalUser &&
    showPayerLinkSwitch;

  const dispatch = useAppDispatch();
  const { selectedPassenger } = useAppSelector((state) => state.addATrip);
  const isEditingTrip = useIsEditingTrip();
  const {
    payerOptions,
    setPassengerSearchTerm,
    debounceSearch,
    payers,
    recentPayers,
  } = usePayers(selectedPassenger?.id);

  const payer = watch(`${pricingNameRoot}.payer`);

  useEffect(() => {
    const payerCategory = payers?.results.find(
      (result) => result.id === payer
    )?.payer_type;
    setPayerCategory(payerCategory);
  }, [payer, payers?.results]);

  return (
    <div className="flex flex-wrap justify-between gap-3">
      <div className="grow">
        <FormContextField
          name={`${pricingNameRoot}.payer`}
          rules={{ required: 'Please choose a payment method' }}
        >
          {({ field, fieldState }) => {
            return (
              <>
                <DefaultFormFieldLayout
                  label="Payer *"
                  error={fieldState.error?.message?.toString()}
                  inputProps={{ id: `${pricingNameRoot}.payer` }}
                >
                  <Combobox
                    value={
                      field.value
                        ? payerOptions.find(
                            (option) => option.value === field.value
                          )
                        : undefined
                    }
                    placeholder="Select a payer"
                    options={payerOptions}
                    onChange={(selected) => {
                      const selectedPayer = payers?.results.find(
                        (x) => x.id === selected.value
                      );
                      dispatch(addATrip.actions.selectPayer(selectedPayer));
                      field.onChange(selected.value);
                    }}
                    dataTestId="payer-combobox"
                    onQueryChange={debounceSearch}
                    onButtonClick={() => setPassengerSearchTerm('')}
                    renderOption={(option, selected, active) => {
                      const recentAddress = recentPayers?.results.find(
                        (payer) => payer.payer_organization.id === option.value
                      );

                      return (
                        <StyledOption
                          key={option.value}
                          option={option}
                          selected={selected}
                          active={active}
                          startAddornment={
                            !!recentAddress && (
                              <span>
                                <ClockIcon className="h-4 w-4" />{' '}
                              </span>
                            )
                          }
                        />
                      );
                    }}
                    error={fieldState.error?.message?.toString()}
                  />
                </DefaultFormFieldLayout>
              </>
            );
          }}
        </FormContextField>
      </div>
      {payerCategory === 'broker' ? <BrokerPayerFields /> : null}
      {payerCategory === 'private' && !isRideOrderingPortalUser ? (
        <PrivatePayerFields />
      ) : null}
      {payerCategory === 'insurance' ? <InsurancePayerFields /> : null}
      {shouldShowPayerLinkSwitch && (
        <PassengerPayerLinkSwitch disabled={isEditingTrip} />
      )}
    </div>
  );
}
