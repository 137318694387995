import { CheckedState } from '@radix-ui/react-checkbox';

import { FormFieldProps } from './FormField';
import { FormFieldCheckbox } from './FormFieldCheckbox';

export function FormFieldBorderedCheckbox<T>({
  inputProps,
  label,
  helperText,
  value,
  onChange = (checked: CheckedState) => {},
}: FormFieldProps<T>) {
  return (
    <FormFieldCheckbox
      inputProps={inputProps}
      label={label}
      helperText={helperText}
      value={value}
      onChange={onChange}
      className="rounded-md border border-gray-300 p-2"
      type="checkbox"
      data-testid={inputProps.id}
    />
  );
}
