import { IToastConfig } from 'src/common/primitives/Toast';

export function generateErrorToast(id: number): IToastConfig {
  return {
    id,
    title: `Error`,
    description:
      'There was an error updating the organization settings. Please try again.',
    type: 'error',
  };
}
