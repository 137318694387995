import { PencilIcon } from '@heroicons/react/24/outline';

import { useModal } from 'src/common/modals/useModal';
import { Button } from 'src/common/primitives/Button';

import { PricingWizardModal } from '../AddPricingSchemeButton';

export function RowActions({ pricingSchemeId }: { pricingSchemeId: string }) {
  const { open, setOpen, openModal } = useModal();
  return (
    <>
      <Button
        dataTestId="pricing-management-edit-button"
        onClick={openModal}
        variant="ghost"
      >
        <PencilIcon className="h-6 w-6 cursor-pointer hover:text-mint" />
      </Button>
      <PricingWizardModal
        open={open}
        setOpen={setOpen}
        pricingSchemeId={pricingSchemeId}
        mode="edit"
      />
    </>
  );
}
