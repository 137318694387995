import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { DispatchTripSummary } from 'src/common/external/bambi-api/bambiApi';

const dispatchTripSummaryMessageIndices: DispatchTripSummary = {
  completed: 0,
  will_call: 1,
  canceled: 2,
  on_time: 3,
  delayed: 4,
  assigned: 5,
};

const aggregatedTripStatuses = [
  { status: 'Completed' },
  { status: 'Will Call' },
  { status: 'Canceled' },
  { status: 'On-time' },
  { status: 'Delayed' },
  { status: 'Assigned' },
];

function getStatusBulletColorClass(status: string) {
  switch (status) {
    case 'On-time':
      return 'bg-[rgba(12,172,166,1)]';
    case 'Delayed':
      return 'bg-[rgba(255,199,0,1)]';
    case 'Canceled':
      return 'bg-[rgba(188,10,10,1)]';
    case 'No-Show':
      return 'bg-[rgba(102,112,133,1)]';
    case 'Will Call':
      return `bg-[#4C0020]`;
    case 'Assigned':
      return `bg-[#047ABC]`;
    default:
      return 'bg-[rgba(102,112,133,1)]';
  }
}

export default function TripStatusAggregation() {
  const summary = useSelector(
    (state: RootState) => state.dispatcher.dispatchResponse?.summary
  );

  if (!summary) return <div>Error loading summaries</div>;
  return (
    <div className="grid grid-cols-3 gap-2">
      {Object.keys(summary).map((statusKey) => {
        // TODO: I'm sorry? Refactor this to not be dumb
        // Probably should just use StatusEnum list and if it exists on the summary display it
        // Each status should have a label and a color anyway
        const status =
          aggregatedTripStatuses[
            dispatchTripSummaryMessageIndices[
              statusKey as keyof DispatchTripSummary
            ]
          ];
        const count = summary[statusKey as keyof DispatchTripSummary];
        return (
          <div
            className="flex items-center gap-1 self-stretch"
            key={status.status}
          >
            <div
              className={`h-2 w-2 rounded-full ${getStatusBulletColorClass(
                status.status
              )}`}
            />
            <p className="m-0 text-xs leading-3 tracking-[-0.32px]">
              {`${count} ${status.status}`}
            </p>
          </div>
        );
      })}
    </div>
  );
}
