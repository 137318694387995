import { RowData, Table } from '@tanstack/react-table';

import { DataGridHeaderCell } from './DataGridHeaderCell';

interface DataGridTableHeaderProps<T> {
  table: Table<T>;
  className?: string;
}

export function DataGridTableHeader<T extends RowData>({
  table,
  className = '',
}: DataGridTableHeaderProps<T>): JSX.Element {
  return (
    <thead
      className={`sticky top-0 z-10 border-b-black bg-gray-50 ${className}`.trim()}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(
            (header): JSX.Element => (
              <DataGridHeaderCell key={header.id} header={header} />
            )
          )}
        </tr>
      ))}
    </thead>
  );
}
