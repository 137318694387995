import { CurrencyDollarIcon } from '@heroicons/react/24/outline';

import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import { AddPricingSchemeButton } from '../AddPricingSchemeButton';

export function PricingEmptyState() {
  return (
    <EmptyState
      Icon={<CurrencyDollarIcon />}
      entityName="pricing scheme"
      description="You don't have any pricing schemes yet."
      Action={<AddPricingSchemeButton />}
    />
  );
}
